module.exports = {
    en: {
        msg: 'your message...',
        assistance: 'I need your help here please.',
        stickers: 'We\'re running out of stickers, can you please send some?',
        targets: 'We need to renew our targets, can you please send new targets over?',
        error_network: 'Opps. Something went wrong... Is your network ok?',
        me: 'Me',
    },
    he: {
        msg: 'הודעה...',
        error_network: 'אופס. שליחה לא הצליחה. יש אולי בעיית תקשורת?',
        me: 'אני',
    },
};
