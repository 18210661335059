import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Snackbar from '../components/Snackbar';

function StorageManager(props) {
    const {
        t, setTitle, setSaveButtonCallback, setSaveButtonLabel, setExtraButtons, setBackButtonVisible,
    } = props;

    const [deleteItem, setDeleteItem] = useState(null);

    useEffect(() => {
        setTitle(t('title'));
        setSaveButtonLabel(null);
        setSaveButtonCallback(null);
        setExtraButtons(null);
        setBackButtonVisible(true);
    }, []);

    const items = Object.keys(window.localStorage);

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {t('name')}
                        </TableCell>
                        <TableCell>
                            {t('description')}
                        </TableCell>
                        <TableCell>
                            {t('size')}
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item) => (
                        <TableRow key={item} style={{ display: window.localStorage.getItem(item).length > 10 * 1024 ? '' : 'none' }}>
                            <TableCell>
                                {item}
                            </TableCell>
                            <TableCell>
                                {getDescription({ t, item })}
                            </TableCell>
                            <TableCell>
                                {Math.floor(window.localStorage.getItem(item).length / 1024)}
                                KB
                            </TableCell>
                            <TableCell>
                                <IconButton color='secondary' onClick={() => setDeleteItem(item)}>
                                    <i className='fas fa-trash-alt' />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Snackbar
                open={!!deleteItem}
                onClose={() => setDeleteItem(null)}
                variant='error'
                message={(
                    <div>
                        <div>
                            {t('areyousure', { item: deleteItem })}
                        </div>
                        <Button onClick={() => { window.localStorage.removeItem(deleteItem); setDeleteItem(null); }} style={{ color: 'inherit' }}>{t('generic:yes')}</Button>
                        <Button onClick={() => setDeleteItem(null)} style={{ color: 'inherit' }}>{t('generic:no')}</Button>
                    </div>
                )}
            />
        </div>
    );
}

StorageManager.propTypes = {
    setTitle: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func.isRequired,
    setSaveButtonLabel: PropTypes.func.isRequired,
    setBackButtonVisible: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const styles = () => ({});

export default withStyles(styles, { withTheme: true })(withTranslation('storagemanager')(StorageManager));


function getDescription({ t, item }) {
    return t(item);
}
