/* eslint-disable no-await-in-loop */
/* eslint-disable no-constant-condition */
/* eslint-disable no-self-assign */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import IconCheck from '@material-ui/icons/Check';
import api from '../Api';

let iframe = null;

function Upgrade(props) {
    const {
        t, classes, match, auth, history,
        setTitle, setExtraButtons, setSaveButtonLabel, setSaveButtonCallback, setBackButtonVisible,
    } = props;

    const [packages, setPackages] = useState(null);
    const [clicked, setClicked] = useState(null);
    const [paymentUrl, setPaymentUrl] = useState(null);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        setTitle(t('title', { name: match.title }));
        setExtraButtons(null);
        setSaveButtonLabel(null);
        setBackButtonVisible(true);
        setSaveButtonCallback(null);

        (async () => {
            const $packages = await api.getPackages({ matchId: match.id, auth });
            setPackages($packages);
        })();
    }, []);

    useEffect(() => {
        const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
        const eventer = window[eventMethod];
        const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

        eventer('resize', onResize, false);
        eventer(messageEvent, onMessage, false);

        return () => {
            const uneventMethod = window.addEventListener ? 'removeEventListener' : 'detachEvent';
            const uneventer = window[uneventMethod];

            uneventer('resize', onResize);
            uneventer(messageEvent, onMessage);
        };
    }, []);

    function onResize() {
        const screenWidth = window.innerWidth;
        setWidth(screenWidth);
    }

    async function onMessage(e) {
        // Small delay
        await new Promise((res) => setTimeout(res, 500));

        if (!iframe) return;
        if (iframe.contentWindow !== e.source) return;

        do {
            try {
                const { data: newMatch } = await api.getMatch({ matchId: match.id });
                if (newMatch.package !== match.package) break;
                await new Promise((res) => setTimeout(res, 500));
            } catch (e2) {
                console.error(e2);
            }
        } while (true);

        window.location.href = window.location.href;
    }

    async function onClick(id) {
        await setClicked(id);
        try {
            const { url, reload } = await api.getPackage({ matchId: match.id, packageId: id, auth });
            if (reload) {
                window.location.href = window.location.href;
                return;
            }
            setPaymentUrl(url);
        } catch (e) {
            console.error(e);
        }
        await setClicked(null);
    }

    if (paymentUrl) {
        const $width = Math.min(720, Math.floor(0.9 * width));

        return (
            <div className={classes.wrapper}>
                <iframe
                    src={paymentUrl}
                    title='payment'
                    style={{ width: `${0.9 * $width}px`, minHeight: '422px', border: 'none' }}
                    ref={(node) => { if (node) { iframe = node; } }}
                />
            </div>
        );
    }

    if (!packages) {
        return (
            <div className={classes.wrapper}>
                <CircularProgress size={48} />
            </div>
        );
    }

    if (packages.length === 0) {
        return (
            <div className={classes.wrapper}>
                <Typography variant='h6'>
                    {t('fullyupgraded')}
                </Typography>
                <Button variant='contained' color='primary' style={{ marginTop: '12px' }} onClick={() => history.goBack()}>{t('generic:back')}</Button>
            </div>
        );
    }

    const colors = [red[300], green[300], blue[300], grey[300]];

    return (
        <div className={classes.wrapper}>
            <Grid
                container
                spacing={24}
                classes={{ container: classes.grid }}
            >
                {packages.map((pack, i) => (
                    <Grid key={pack.id} item xs={12} md={4} className='home-pricinggrid'>
                        <div style={{ height: '100%' }}>
                            <Card classes={{ root: 'home-pricingcard' }}>
                                <CardHeader
                                    title={pack.title}
                                    subheader={(
                                        <div>
                                            <div>{pack.description}</div>
                                            <Typography variant='caption' className={classes.whiteText}>{pack.subdescription}</Typography>
                                        </div>
    )}
                                    classes={{ title: classes.whiteText, subheader: classes.whiteText }}
                                    style={{ background: colors[i] }}
                                />
                                <CardContent style={{ height: 'calc(100% - 200px)' }}>
                                    {pack.features.map((feature) => (
                                        <div
                                            key={feature}
                                            style={{
                                                display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '12px',
                                            }}
                                        >
                                            <IconCheck />
                                            <div style={{ flex: 1, margin: '0px 12px' }}>
                                                {feature}
                                            </div>
                                        </div>
                                    ))}
                                </CardContent>
                                <CardActions>
                                    <Button
                                        fullWidth
                                        variant='contained'
                                        color='primary'
                                        disabled={!!clicked}
                                        onClick={() => onClick(pack.id)}
                                    >
                                        {clicked === pack.id && <CircularProgress size={24} />}
                                        {clicked !== pack.id && t('upgrade')}
                                    </Button>
                                </CardActions>
                            </Card>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}

Upgrade.propTypes = {
    setTitle: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func.isRequired,
    setSaveButtonLabel: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    setBackButtonVisible: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        payment: PropTypes.string.isRequired,
        squadSelection: PropTypes.string.isRequired,
        package: PropTypes.string,
        squads: PropTypes.array,
        registrationExtraFields: PropTypes.array,
    }).isRequired,
    auth: PropTypes.string.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
};

const styles = () => ({
    wrapper: {
        width: '90%',
        textAlign: 'center',
        margin: 'auto',
    },
    grid: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
    },
    whiteText: {
        color: 'white',
    },
});

export default withStyles(styles, { withTheme: true })(withTranslation('upgrade')(Upgrade));
/* eslint-enable no-self-assign */
/* eslint-enable no-await-in-loop */
/* eslint-enable no-constant-condition */
