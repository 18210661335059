import React, { useState } from 'react';
import Profile from './Profile';

function ProfilePage(props) {
    const [name, setName] = useState(null);
    const [publicId, setPublicId] = useState(null);
    const [images, setImages] = useState(null);
    const [registrations, setRegistrations] = useState(null);
    const [staff, setStaff] = useState(null);
    const [matches, setMatches] = useState(null);
    const [stages, setStages] = useState(null);
    const [shooters, setShooters] = useState(null);
    const [scores, setScores] = useState(null);

    return (
        <Profile
            name={name}
            setName={setName}
            publicId={publicId}
            setPublicId={setPublicId}
            images={images}
            setImages={setImages}
            registrations={registrations}
            setRegistrations={setRegistrations}
            staff={staff}
            setStaff={setStaff}
            matches={matches}
            setMatches={setMatches}
            stages={stages}
            setStages={setStages}
            shooters={shooters}
            setShooters={setShooters}
            scores={scores}
            setScores={setScores}
            {...props}
        />
    );
}

export default ProfilePage;
