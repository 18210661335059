/* eslint-disable react/no-unescaped-entities */

import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Button from '@material-ui/core/Button';

function $EquipmentBelt(props) {
    const {
        t, classes, edit, onChange, onSetEdit, equipmentObj,
    } = props;
    return (
        <div>
            <div style={{ marginTop: '24px' }}>
                <Typography variant='h6'>{t('equipment')}</Typography>
                <div className={classes.equipmentbelt} style={{ direction: 'ltr' }}>
                    {_.range(24).map((r) => {
                        let text = '';
                        if (r.toString() === (equipmentObj.holster || '').toString()) {
                            text = 'H';
                        }
                        if ((equipmentObj.magnets || []).includes(r)) {
                            text += 'M';
                        }
                        if ((equipmentObj.pouches || []).includes(r)) {
                            text += 'P';
                        }
                        if ((edit) && (text.length === 0)) {
                            text = (r + 1).toString();
                        } else if (edit) {
                            text = <span style={{ fontWeight: '800' }}>{text}</span>;
                        }
                        return (
                            <div key={r} className={classnames([classes.equipmentslot, classes[`equipmentslot-${r}`]])}>
                                <div>{text}</div>
                            </div>
                        );
                    })}
                </div>
                <div style={{ textAlign: 'center', marginTop: '30px', marginBottom: '12px' }}>
                    <Typography variant='caption'>
                        {t('equipmentforward')}
                    </Typography>
                </div>
            </div>
            {edit && (
                <div>
                    <Typography variant='h7'>
                        {t('equipment-holster').split('###')[0]}
                        <b>
                            {t('equipment-holster').split('###')[1]}
                        </b>
                        {t('equipment-holster').split('###')[2]}
                    </Typography>
                    <RadioGroup
                        aria-label='Holster Position'
                        name='equipment-holster'
                        value={equipmentObj.holster ? equipmentObj.holster.toString() : equipmentObj.holster}
                        onChange={(e) => onChange({ field: 'holster', value: e.target.value })}
                        row
                    >
                        {_.range(24).map((r) => (
                            <FormControlLabel key={r} value={r.toString()} control={<Radio />} label={r + 1} />
                        ))}
                        />
                    </RadioGroup>
                    <Typography variant='h7'>
                        {t('equipment-pouches').split('###')[0]}
                        <b>
                            {t('equipment-pouches').split('###')[1]}
                        </b>
                        {t('equipment-pouches').split('###')[2]}
                    </Typography>
                    {_.range(24).map((r) => (
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    disabled={(equipmentObj.holster || '').toString() === r.toString()}
                                    checked={(equipmentObj.pouches || []).includes(r)}
                                    onChange={() => onChange({ field: 'pouch', value: r })}
                                />
                            )}
                            label={r + 1}
                        />
                    ))}
                    <Typography variant='h7'>
                        {t('equipment-magnets').split('###')[0]}
                        <b>
                            {t('equipment-magnets').split('###')[1]}
                        </b>
                        {t('equipment-magnets').split('###')[2]}
                    </Typography>
                    {_.range(24).map((r) => (
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    disabled={(equipmentObj.holster || '').toString() === r.toString()}
                                    checked={(equipmentObj.magnets || []).includes(r)}
                                    onChange={() => onChange({ field: 'magnet', value: r })}
                                />
                            )}
                            label={r + 1}
                        />
                    ))}
                </div>
            )}
            {onSetEdit && (
                <Button
                    style={{ textTransform: 'none', padding: '6px 0px' }}
                    onClick={() => onSetEdit(!edit)}
                    color='primary'
                >
                    {edit ? t('equipment-close') : t('equipment-edit')}
                </Button>
            )}
        </div>
    );
}

$EquipmentBelt.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    edit: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    onSetEdit: PropTypes.func,
    equipmentObj: PropTypes.shape({
        holster: PropTypes.string,
        pouches: PropTypes.array,
        magnets: PropTypes.array,
    }).isRequired,
};

$EquipmentBelt.defaultProps = {
    onChange: null,
    onSetEdit: null,
};

const styles = () => {
    const base = {
        equipmentbelt: {
            marginTop: '24px',
            border: '4px solid black',
            borderRadius: '50%',
            width: '150px',
            height: '150px',
            margin: 'auto',
        },
        equipmentslot: {
            position: 'absolute',
            transformOrigin: 'center',
            width: '150px',
            height: '150px',
            '& > div': {
                display: 'inline-block',
            },
        },
    };

    for (let i = 0; i < 24; i++) {
        base[`equipmentslot-${i}`] = {
            transform: `rotate(${-45 + 15 * i}deg)`,
            '& > div': {
                transform: `rotate(${45 + (-15 * i)}deg)`,
            },
        };
    }

    return base;
};


export default withStyles(styles, { withTheme: true })(withTranslation('registration')($EquipmentBelt));

/* eslint-enable react/no-unescaped-entities */
