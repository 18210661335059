import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Paper from '@material-ui/core/Paper';
import app from '../AppCommunication';

function HomeAppQuickScoreOnlineHeader(props) {
    const {
        classes, data, matchIndex,
    } = props;
    const [linkCopied, setLinkCopied] = useState(false);
    const matchData = data[matchIndex].match;
    return (
        <Paper classes={{ root: classes.matchitemwrapper }} style={{ marginTop: '12px' }}>
            <ButtonBase
                classes={{ root: classes.matchitem }}
                onClick={() => {
                    app({ cmd: { src: 'share-url', params: { url: `https://www.endofscoring.com/${matchData.alias}` } } });
                    setLinkCopied(true);
                    setTimeout(() => setLinkCopied(false), 2000);
                }}
                style={{ flexDirection: 'column' }}
            >
                <div>
                    <Typography variant='subtitle2'>
                        Your Quick-Match Has a Website
                    </Typography>
                </div>
                <div>
                    <Typography variant='body2' style={{ textAlign: 'center' }}>
                        Share it, see statistics, and get full match scores at https://www.endofscoring.com/
                        {matchData.alias}
                    </Typography>
                </div>
                <div>
                    {!linkCopied && (
                        <Typography variant='caption' color='primary'>
                            (click here to copy link)
                        </Typography>
                    )}
                    {linkCopied && (
                        <Typography variant='caption'>
                            Copied!
                        </Typography>
                    )}
                </div>
            </ButtonBase>
        </Paper>
    );
}

const styles = () => ({
    matchitemwrapper: {
        margin: '12px',
        marginTop: '6px',
    },
    matchitem: {
        padding: '12px',
        textAlign: 'start',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
});

export default withStyles(styles, { withTheme: true, flip: false })(withTranslation('homeappquickscore')(HomeAppQuickScoreOnlineHeader));

HomeAppQuickScoreOnlineHeader.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
        match: PropTypes.shape({
            title: PropTypes.string,
            alias: PropTypes.string,
            stages: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    })).isRequired,
    matchIndex: PropTypes.number.isRequired,
};
