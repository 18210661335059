import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import grey from '@material-ui/core/colors/grey';

function HomeAppShouldResume(props) {
    const { t, classes, history } = props;

    function resume() {
        history.goBack();
    }

    function backToMain() {
        history.go(-1 * history.length);
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.topbar}>
                <Typography variant='body2' style={{ textAlign: 'center', flex: 1 }}>Aria</Typography>
            </div>
            <Typography variant='h4' style={{ color: grey[800] }}>
                {t('welcomeback')}
            </Typography>
            <Typography variant='body2' style={{ color: grey[800] }}>
                {t('question')}
            </Typography>
            <Button variant='contained' color='primary' fullWidth style={{ marginTop: '24px' }} onClick={resume}>
                {t('resume')}
            </Button>
            <Button variant='text' color='secondary' fullWidth style={{ marginTop: '24px' }} onClick={backToMain}>
                {t('startnew')}
            </Button>
        </div>
    );
}

const styles = () => ({
    wrapper: {
        height: '100vh',
        width: '80vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '10%',
    },
    topbar: {
        direction: 'ltr',
        position: 'fixed',
        zIndex: 10,
        top: '0px',
        left: '0px',
        width: '100%',
        height: '45px',
        display: 'flex',
        alignItems: 'center',
        background: 'white',
    },
});

HomeAppShouldResume.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    t: PropTypes.func.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
};

export default withStyles(styles, { withTheme: true, flip: false })(withTranslation('homeappshouldresume')(HomeAppShouldResume));
