/* eslint-disable class-methods-use-this */
export default class LocalStorage {
    async set(key, value) {
        try {
            localStorage.setItem(key, value);
        } catch (e) {
            return null;
        }
        return Promise.resolve(value);
    }

    async get(key) {
        return Promise.resolve(localStorage.getItem(key));
    }
}
/* eslint-enable class-methods-use-this */
