import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import GoogleSignIn from './GoogleSignIn';
import FacebookSignIn from './FacebookSignIn';
import api from '../Api';

class Authentication extends React.Component {
    constructor(props) {
        const {
            match, club, region,
        } = props;

        super(props);
        this.state = {
            shouldAuthenticate: false,
            value: (match || club || region).alias.startsWith('demo-') ? 'password' : '',
        };
    }

    async UNSAFE_componentWillMount() {
        const {
            match, club, region, onAuth, accessToken: propAccessToken,
        } = this.props;
        const auth = sessionStorage.getItem('auth');
        const meJson = sessionStorage.getItem('me');
        const me = meJson ? JSON.parse(meJson) : null;

        if (!auth || !me) {
            await new Promise((res) => this.setState({ shouldAuthenticate: true }, res));
            if (propAccessToken) {
                const [ns, _accessToken] = propAccessToken.split('|');
                if (ns === 'com.google') {
                    this.googleLogin({ authResponse: { id_token: _accessToken } });
                } else if (ns === 'com.facebook') {
                    this.facebookLogin({ accessToken: _accessToken });
                }
            }
            return;
        }

        try {
            const accessToken = sessionStorage.getItem('accessToken');
            onAuth(accessToken, me);
            const $me = await api.getAuth({
                accessToken,
                matchId: (match || {}).id,
                clubId: (club || {}).id,
                regionId: (region || {}).id,
            });

            if (!_.isEqual($me, me)) {
                onAuth(accessToken, me);
            }
        } catch (e) {
            sessionStorage.removeItem('accessToken');
            sessionStorage.removeItem('auth');
            sessionStorage.removeItem('me');
            onAuth(null, null);
            this.setState({ shouldAuthenticate: true });
        }
    }

    handleOnClick = async () => {
        const {
            match, club, region, onAuth,
        } = this.props;
        const { value } = this.state;

        if (!value) {
            this.setState({ errorEmpty: true });
            return;
        }

        const auth = `com.endofscoring|${(match || club || region).id}|${value}`;

        await new Promise((res) => this.setState({ checking: true }, res));

        try {
            let login = null;
            try {
                login = await api.getLogin({ accessToken: auth });
            } catch (e) {
                if (e.response.status === 404) {
                    login = await api.postLogin({ accessToken: auth });
                } else {
                    throw e;
                }
            }

            const { accessToken } = login;

            const me = await api.getAuth({
                accessToken,
                matchId: (match || {}).id,
                clubId: (club || {}).id,
                regionId: (region || {}).id,
            });

            this.setState({ value: '', checking: false, shouldAuthenticate: false }, () => {
                sessionStorage.setItem('accessToken', accessToken);
                sessionStorage.setItem('auth', auth);
                sessionStorage.setItem('me', JSON.stringify(me));
                onAuth(accessToken, me);
            });
        } catch (e) {
            if (e.response.status === 401) {
                this.setState({ checking: false, errorInvalid: true });
            }
        }
    }

    handleClose = () => {
        const { onCancel = () => {} } = this.props;
        onCancel();
    }

    googleLogin = async (ev) => {
        const { match, club, region } = this.props;
        const auth = `com.google|${ev.authResponse.id_token}`;
        let me = null;
        let accessToken = null;
        try {
            let login = null;
            try {
                login = await api.getLogin({ accessToken: auth });
            } catch (e) {
                if (e.response.status === 404) {
                    login = await api.postLogin({ accessToken: auth });
                } else {
                    throw e;
                }
            }
            ({ accessToken } = login);

            me = await api.getAuth({
                accessToken,
                matchId: (match || {}).id,
                clubId: (club || {}).id,
                regionId: (region || {}).id,
            });
        } catch (err) {
            console.error(err);
            this.setState({ googleUserError: true });
            return;
        }

        await new Promise((res) => this.setState({ googleAuth: auth, googleMe: me, googleAccessToken: accessToken }, res));

        this.googleUseAccount();
    }

    googleLogout = () => {
        this.setState({ googleUserError: false });
    }

    facebookLogin = async (ev) => {
        const { match, club, region } = this.props;
        const auth = `com.facebook|${ev.accessToken}`;
        let me = null;
        let accessToken = null;
        try {
            let login = null;
            try {
                login = await api.getLogin({ accessToken: auth });
            } catch (e) {
                if (e.response.status === 404) {
                    login = await api.postLogin({ accessToken: auth });
                } else {
                    throw e;
                }
            }
            ({ accessToken } = login);

            me = await api.getAuth({
                accessToken,
                matchId: (match || {}).id,
                clubId: (club || {}).id,
                regionId: (region || {}).id,
            });
        } catch (err) {
            this.setState({ facebookUserError: true });
            return;
        }

        await new Promise((res) => this.setState({ facebookAuth: auth, facebookMe: me, facebookAccessToken: accessToken }, res));

        this.facebookUseAccount();
    }

    facebookLogout = () => {
        this.setState({ facebookUserError: false });
    }

    googleUseAccount = () => {
        const { googleAuth: auth, googleMe: me, googleAccessToken: accessToken } = this.state;
        const { onAuth } = this.props;
        sessionStorage.setItem('accessToken', accessToken);
        sessionStorage.setItem('auth', auth);
        sessionStorage.setItem('me', JSON.stringify(me));
        onAuth(accessToken, me);
    }

    facebookUseAccount = () => {
        const { facebookAuth: auth, facebookMe: me, facebookAccessToken: accessToken } = this.state;
        const { onAuth } = this.props;
        sessionStorage.setItem('accessToken', accessToken);
        sessionStorage.setItem('auth', auth);
        sessionStorage.setItem('me', JSON.stringify(me));
        onAuth(accessToken, me);
    }

    render() {
        const {
            shouldAuthenticate, checking, value, googleUserError, facebookUserError,
            errorEmpty, errorInvalid,
        } = this.state;
        const {
            authenticate, classes, t, match, club, region, mode, src,
        } = this.props;
        if ((!shouldAuthenticate) || (!authenticate)) return <div />;

        return (
            <div className={classes.wrapper}>
                <div className={classes.background} />
                <div className={classes.cover} />
                <Grid
                    container
                    style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '24px',
                    }}
                >
                    <Grid item xs={12} sm={9} md={6}>
                        <Paper classes={{ root: classes.paper }}>
                            <Typography variant='h5' id='modal-title' className={classes.colorwhite}>
                                {match ? t('title-match') : club ? t('title-club') : region.id ? t('title-region') : ''}
                            </Typography>
                            <Typography variant='subtitle1' id='simple-modal-description' className={classes.colorwhite} style={{ marginBottom: '18px' }}>
                                {match ? t('description-match') : club ? t('description-club') : region.id ? t('description-region') : ''}
                            </Typography>
                            <TextField
                                autoFocus
                                label={t('password')}
                                type='password'
                                variant='filled'
                                InputLabelProps={{
                                    classes: { root: classes.colorwhite, shrink: classes.colorwhite },
                                    shrink: true,
                                }}
                                InputProps={{
                                    classes: { input: classes.colorwhite },
                                }}
                                value={value}
                                onChange={(e) => this.setState({ value: e.target.value, errorEmpty: false, errorInvalid: false })}
                                onKeyDown={(e) => { if (e.keyCode === 13) this.handleOnClick(); }}
                                error={errorEmpty || errorInvalid}
                                helperText={errorInvalid ? <span style={{ display: 'flex', width: '100%', textAlign: 'right' }}>{t('error')}</span> : null}
                            />
                            {(match || club || region).alias.startsWith('demo-') && (
                                <Typography variant='body2' className={classes.demomessage}>
                                    {t('demomessage')}
                                </Typography>
                            )}
                            <Button
                                variant='contained'
                                color='primary'
                                style={{ width: '60%', marginTop: '24px' }}
                                onClick={this.handleOnClick}
                                disabled={checking}
                            >
                                {checking ? <CircularProgress size={18} /> : t('generic:ok')}
                            </Button>
                            <div style={{ margin: '12px 0px', borderTop: '1px solid rgba(255,255,255,0.2)', width: '100%' }} />
                            <div className={classnames([classes.colorwhite, classes.textalignstart, classes.centercolumnflex])}>
                                <GoogleSignIn noAuto mode={mode} onLogin={this.googleLogin} onLogout={this.googleLogout} />
                                {googleUserError && (
                                <Typography variant='caption' className={classnames([classes.colorwhite, classes.textaligncenter])}>
                                    {t('google-error')}
                                </Typography>
                                )}
                            </div>
                            <div style={{ margin: '12px 0px', borderTop: '1px solid rgba(255,255,255,0.2)', width: '100%' }} />
                            <div className={classnames([classes.colorwhite, classes.textalignstart, classes.centercolumnflex])}>
                                <FacebookSignIn mode={mode} system='manage' matchId={match.id} noAuto={src !== 'facebook'} onLogin={this.facebookLogin} onLogout={this.facebookLogout} />
                                {facebookUserError && (
                                <Typography variant='caption' className={classnames([classes.colorwhite, classes.textaligncenter])}>
                                    {t('facebook-error')}
                                </Typography>
                                )}
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const styles = () => ({
    wrapper: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    background: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        background: 'url("/loginbg.jpg")',
        backgroundSize: 'cover',
        zIndex: -2,
    },
    cover: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        background: 'rgba(0,0,0,0.8)',
        zIndex: -1,
    },
    paper: {
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: 'rgba(255,255,255,0.2)',
        boxShadow: '0px 0px 2px white',
        maxWidth: '450px',
        margin: 'auto',
        textAlign: 'center',
    },
    colorwhite: {
        color: 'white !important',
    },
    demomessage: {
        color: 'white !important',
        marginTop: '12px',
        width: '100%',
        textAlign: 'center',
    },
    textalignstart: {
        textAlign: 'left',
    },
    textaligncenter: {
        textAlign: 'center',
    },
    centercolumnflex: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    centerrowflex: {
        display: 'flex',
        alignItems: 'center',
    },
});

Authentication.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    t: PropTypes.func.isRequired,
    accessToken: PropTypes.string,
    mode: PropTypes.string,
    src: PropTypes.string,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
    club: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
    region: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
    authenticate: PropTypes.bool,
    onAuth: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
};

Authentication.defaultProps = {
    accessToken: null,
    mode: null,
    src: null,
    match: null,
    club: null,
    region: null,
    authenticate: true,
    onCancel: null,
};

export default withStyles(styles, { withTheme: true })(withTranslation('authentication')(Authentication));
