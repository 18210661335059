import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withTranslation } from 'react-i18next';

export default function GenericI18nThemeWrapper(props) {
    const { match, children, onUpdateThemeLocale } = props;

    if (!match) {
        return (
            <div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '100vh',
            }}
            >
                <CircularProgress size={75} />
            </div>
        );
    }

    return (
        <Inner match={match} onUpdateThemeLocale={onUpdateThemeLocale}>
            {children}
        </Inner>
    );
}

GenericI18nThemeWrapper.propTypes = {
    match: PropTypes.shape({
        locale: PropTypes.string.isRequired,
    }),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    onUpdateThemeLocale: PropTypes.func.isRequired,
};

GenericI18nThemeWrapper.defaultProps = {
    match: null,
};

class Inner_ extends Component {
    componentDidMount() {
        this.fixLocale();
    }

    componentDidUpdate(prevProps) {
        const { match } = this.props;
        if ((prevProps.match || {}).locale !== (match || {}).locale) {
            this.fixLocale();
        }
    }

    fixLocale() {
        const { match, i18n, onUpdateThemeLocale } = this.props;
        if ((!match) || (!onUpdateThemeLocale)) return;
        const { locale } = match;
        i18n.changeLanguage(locale, () => {
            onUpdateThemeLocale(locale);
        });
    }

    render() {
        const { children } = this.props;
        return children;
    }
}

Inner_.propTypes = {
    i18n: PropTypes.shape({
        changeLanguage: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        locale: PropTypes.string.isRequired,
    }).isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    onUpdateThemeLocale: PropTypes.func.isRequired,
};

const Inner = withTranslation()(Inner_);
