module.exports = {
    en: {

        subject: 'New Score for {{name}} in {{- title}} for Stage {{stageIdx}}',
        'subject-dq': '{{name}} DQ\'ed from {{- title}} during Stage {{stageIdx}}',
        title: 'Hey there {{firstName}},',
        message: 'We hope you enjoyed the stage. Here\'s your result:',
        'message-dq': 'We\'re sorry to hear about your DQ. We hope you have better luck next time. DQ reason: {{dq}}. Time: {{- time}}.',
        footer: 'If you have received this email by mistake, or if you have any questions or comments, feel free to visit us at {{link}}',
        dontsee: 'Email doesn\'t look quite right? click ###here###.',
    },
    he: {
        subject: 'תוצאה חדשה עבור {{name}} ב-{{- title}}, תרגיל {{stageIdx}}',
        'subject-dq': '{{name}} פסילה מ-{{- title}} במהלך תרגיל {{stageIdx}}',
        title: 'שלום {{firstName}}',
        message: 'אנו מקווים שנהנת מהתרגיל. להלן תוצאותיך:',
        'message-dq': 'אנחנו מצטערים לשמוע על הפסילה שלך. מקווים שיהיה לך יותר הצלחה בתחרות הבאה! סיבת הפסילה: {{dq}}. שעה: {{- time}}.',
        footer: 'אם קיבלת מייל זה בטעות, או אם יש לך שאלות או תיקונים, אנחנו נמצאים ב-{{link}}',
        dontsee: 'המייל לא נראה טוב? לחצו ###כאן###.',
    },
};
