const lang = {
    en: {
        title: 'Excel (*.xlsx)',
        'title-import': 'Import from Excel',
        'title-export': 'Export from Excel',
        'intro-1': 'Use the button below to select your excel file.',
        'intro-2': 'The excel should have the following columns:',
        'intro-3': 'Note: The first row of the excel is disregarded!',
        loadfile: 'Upload Excel File',
        optional: 'Optional',
        'error-no-id': 'missing',
        'error-no-name': 'missing',
        'error-no-email': 'missing',
        'error-no-club': 'missing',
        'error-no-division': 'missing',
        'error-bad-email': 'Bad email format ({{email}})',
        'error-bad-division': 'No such division ({{division}})',
        'error-bad-category': 'No such category ({{category}})',
        'error-bad-power-factor': 'Only minor/major allowed ({{powerfactor}})',
        'error-bad-paid': 'Unknown ({{paid}})',
        'error-bad-squad': 'No such squad ({{squad}})',
        'error-bad-team': 'No such team ({{team}})',
        'error-bad-ics': 'No such ICS Classification ({{ics}})',
        updatenew: 'New/Update',
        new: 'New shooter',
        update: 'Update shooter',
        nochange: 'Shooter exists',
        importselected: 'Import Selected',
        reset: 'Reset',
        export: 'Export',
        'export-cancelled': 'Include shooters that have cancelled participation',
        'cancelled-participation': 'cancelled',
    },
    he: {
        title: 'מאקסל (*.xlsx)',
        'title-import': 'ייבוא מאקסל',
        'title-export': 'ייצוא לאקסל',
        'intro-1': 'לחצו על הכפתור למטה לייבוא קובץ יורים מאקסל.',
        'intro-2': 'על הקובץ להכיל את העמודות הבאות (בסדר הרשום):',
        'intro-3': 'שימו לב: אנחנו מתעלמים מהשורה הראשונה - אנחנו מניחים שזו שורת כותרת',
        loadfile: 'ייבא מאקסל',
        optional: 'לא חובה',
        'error-no-name': 'חסר',
        'error-no-email': 'חסר',
        'error-no-club': 'חסר',
        'error-no-division': 'חסר',
        'error-bad-email': 'אימייל לא תקין ({{email}})',
        'error-bad-division': 'אין מחלקה כזו ({{division}})',
        'error-bad-category': 'אין קטגוריה כזו ({{category}})',
        'error-bad-power-factor': 'יש לכתוב רק מייג׳ור או מיינור ({{powerfactor}})',
        'error-bad-paid': 'יש לכתוב רק כן או לא ({{paid}})',
        'error-bad-squad': 'אין סקווד כזה ({{squad}})',
        'error-bad-team': 'אין קבוצה כזו ({{team}})',
        'error-bad-ics': 'אין ICS כזו ({{ics}})',
        updatenew: 'יורה חדש/קיים',
        new: 'יורה חדשה',
        update: 'עדכון יורה',
        nochange: 'יורה קיים',
        importselected: 'ייבוא יורים',
        reset: 'אפס',
        export: 'ייצא',
        'export-cancelled': 'ייצא גם יורים שביטלו את השתתפותם',
        'cancelled-participation': 'ביטלו השתתפות',
    },
};

lang.iw = {
    ...lang.he,
    ...{
        'error-bad-squad': 'אין מקצה כזה ({{squad}})',
        'intro-1': 'לחצו על הכפתור למטה לייבוא קובץ לוחמים מאקסל.',
        updatenew: 'לוחם חדש/קיים',
        new: 'לוחם\\ת חדשה',
        update: 'עדכון לוחם\\ת',
        nochange: 'לוחם\\ת קיים',
        importselected: 'ייבוא לוחמים',
        'export-cancelled': 'ייצא גם לוחמים שביטלו את השתתפותם',
    },
};

export default lang;
