import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import grey from '@material-ui/core/colors/grey';
import lime from '@material-ui/core/colors/lime';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Refresh from '@material-ui/icons/Refresh';
import Wrapper from './HomeI18nWrapper';
import HomeAppUserDashboardMatch from './HomeApp.userdashboard.match';
import PushNotification from '../matches/PushNotification';
import { getScoresWithPoints, pad } from '../matches/Utils';
import AppStorage from '../components/AppStorage';
import LocalStorage from '../components/LocalStorage';
import api from '../Api';
import app from '../AppCommunication';

class HomeAppUserDashboard extends Component {
    constructor(props) {
        super(props);

        this.storage = props.mode === 'app' ? new AppStorage() : new LocalStorage();

        this.state = {
            loading: '',
            page: 1,
            feedList: null,
            showpushnotifications: false,
        };
    }

    async componentDidMount() {
        const { classes, mode, accessToken } = this.props;

        document.body.classList.add(classes.body);
        document.documentElement.classList.add(classes.body);

        const initialState = await this.storage.get('__homeapp_userdashboard_state');
        if (initialState) {
            await new Promise((res) => this.setState(JSON.parse(initialState), res));
        }

        await this.refresh();

        if (!initialState) {
            window.scrollTo(0, 0);
        }

        if (mode === 'app') {
            app({
                cmd: { src: 'app', type: 'did-mount', params: { accessToken } },
                callbackName: 'showPushNotifications',
                callback: () => {
                    this.setState({ showpushnotifications: true });
                },
            });
        }
    }

    componentDidUpdate() {
        const { mode, i18n, onUpdateThemeLocale } = this.props;
        const { loading } = this.state;

        if (i18n.language !== 'en-US') {
            i18n.changeLanguage('en-US', () => {
                onUpdateThemeLocale('en-US');
            });
        }

        if ((loading === null) && (mode === 'app')) {
            this.storage.set('__homeapp_userdashboard_state', JSON.stringify(this.state));
        }
    }

    componentWillUnmount() {
        const { classes } = this.props;
        document.documentElement.classList.remove(classes.body);
        document.body.classList.remove(classes.body);
    }

    refresh = async () => {
        const { t, accessToken } = this.props;
        const { registrations, staff } = await api.getMatches({ mode: 'as-shooter-or-staff', accessToken });
        staff.forEach((staffMatchId) => {
            const registration = registrations.find((r) => r.matchId.toString() === staffMatchId.toString());
            if (registration) {
                registration.staff = true;
            } else {
                registrations.push({ matchId: staffMatchId, staff: true });
            }
        });
        await new Promise((res) => this.setState({ loading: t('loading-matches') }, res));
        const matches = await Promise.all(registrations.map((r) => api.getMatch({ matchId: r.matchId })));
        await new Promise((res) => this.setState({ loading: t('loading-stages') }, res));
        const allStages = await (async () => {
            const stageIds = _.flatten(matches.map((m) => m.stages));
            return api.getStages({ stages: stageIds });
        })();
        await new Promise((res) => this.setState({ loading: t('loading-scores') }, res));
        const allScores = (await Promise.all(matches.map((m) => (m.scoresPublished ? api.getScores({ matchId: m.id }) : Promise.resolve([])))));
        await new Promise((res) => this.setState({ loading: t('loading-shooters') }, res));
        const allShooters = await Promise.all(matches.map((m) => (m.scoresPublished ? api.getShooters({ matchId: m.id }) : Promise.resolve([]))));
        await new Promise((res) => this.setState({ loading: t('loading-feed') }, res));

        const feedList = _(registrations)
            .map((r) => {
                const index = matches.findIndex((m) => m.id.toString() === r.matchId.toString());
                const match = matches[index];
                const stages = match.stages.map((s) => allStages.find((ss) => ss.id.toString() === s.toString()));
                const scores = allScores[index];
                const shooters = allShooters[index];
                const { scoresOverall, dqs } = _.isEmpty(scores) ? { scoresOverall: [], dqs: [] } : getScoresWithPoints({ scores, shooters, stages });
                return {
                    match,
                    stages,
                    scoresOverall,
                    dqs,
                    registration: r,
                };
            })
            .sortBy((a) => -1 * (a.match.startDate.day + a.match.startDate.month * 31 + a.match.startDate.year * 365))
            .value();

        this.setState({ feedList, loading: null });
    }

    openMatch(matchId) {
        const {
            history, mode, accessToken, name,
        } = this.props;
        const query = queryString.stringify({
            mode,
            accessToken,
            name,
        });
        // A little delay for the click animation
        setTimeout(() => history.push(`/app/user-dashboard/match/${matchId}?${query}`), 500);
    }

    renderPast() {
        const { t, classes } = this.props;
        const { feedList } = this.state;

        const filtered = feedList.filter(({ match }) => {
            const time = new Date(match.startDate.year, match.startDate.month - 1, match.startDate.day, 12, 0, 0, 0);
            const now = new Date();
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 12, 0, 0, 0);
            return (time.getTime() - today.getTime() < (-7 * 24 * 60 * 60 * 1000));
        });

        if (feedList.length === 0) {
            return (
                <div className={classes.feedempty}>
                    <Typography variant='body1'>
                        {t('pastnomatchesatall')}
                    </Typography>
                </div>
            );
        }

        if (filtered.length === 0) {
            return (
                <div className={classes.feedempty}>
                    <Typography variant='body1'>
                        {t('pastnomatches')}
                    </Typography>
                </div>
            );
        }

        return (
            <div className={classes.feed}>
                {filtered.map((f) => this.renderFeedItem(f))}
            </div>
        );
    }

    renderMatches() {
        const { classes, t } = this.props;
        const { feedList } = this.state;

        const filtered = feedList
            .filter(({ match }) => {
                const time = new Date(match.startDate.year, match.startDate.month - 1, match.startDate.day, 12, 0, 0, 0);
                const now = new Date();
                const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 12, 0, 0, 0);
                return (time.getTime() - today.getTime() >= (-7 * 24 * 60 * 60 * 1000));
            })
            .reverse();

        if (feedList.length === 0) {
            return (
                <div className={classes.feedempty}>
                    <Typography variant='body1'>
                        {t('lastweeknomatchesatall')}
                    </Typography>
                </div>
            );
        }

        if (filtered.length === 0) {
            return (
                <div className={classes.feedempty}>
                    <i style={{ fontSize: '72px', paddingBottom: '12px' }} className='fas fa-bed' />
                    <Typography variant='body1'>
                        {t('lastweeknomatches')}
                    </Typography>
                </div>
            );
        }

        return (
            <div className={classes.feed}>
                {filtered.map((f) => this.renderFeedItem(f))}
            </div>
        );
    }

    renderFeedItem(feed) {
        const { t, classes } = this.props;
        const {
            registration, match, stages, scoresOverall, dqs,
        } = feed;

        const rounds = _.reduce(stages, (sum, s) => sum + (s.rounds || 0), 0);
        let result = null;
        let avatar = null;

        if (!_.isEmpty(scoresOverall)) {
            const scoresDivision = scoresOverall.filter((s) => s.division === registration.division);
            const meIndex = scoresDivision.findIndex((s) => s.shooterId === registration.id);
            if (meIndex > -1) {
                const me = scoresDivision[meIndex];
                const scoresCategory = scoresDivision.filter((s) => s.category === registration.category);
                const meCatIndex = registration.category === 'regular' ? -1 : scoresCategory.findIndex((s) => s.shooterId === registration.id);

                result = (() => {
                    if (dqs.find((dq) => registration.id === dq.shooterId || registration.id === `${dq.matchId}_${dq.shooterId}`)) {
                        return (
                            <Typography variant='body2'>
                                {t('result-dq')}
                            </Typography>
                        );
                    }
                    if (registration.category !== 'regular') {
                        return (
                            <Typography variant='body2'>
                                {t('result-category', {
                                    pos: t((meIndex + 1).toString()),
                                    all: scoresDivision.length,
                                    points: pad(me.score, 4),
                                    percentage: parseInt(me.percentage * 10000, 10) / 100,
                                    catPos: t((meCatIndex + 1).toString()),
                                    catPercentage: parseInt((me.percentage / scoresCategory[0].percentage) * 10000, 10) / 100,
                                })}
                            </Typography>
                        );
                    }

                    return (
                        <Typography variant='body2'>
                            {t('result', {
                                pos: t((meIndex + 1).toString()),
                                all: scoresDivision.length,
                                points: pad(me.score, 4),
                                percentage: parseInt(me.percentage * 10000, 10) / 100,
                            })}
                        </Typography>
                    );
                })();

                avatar = (() => {
                    if (dqs.find((dq) => registration.id === dq.shooterId || registration.id === `${dq.matchId}_${dq.shooterId}`)) {
                        return (
                            <div
                                style={{
                                    background: red[500], width: '36px', height: '36px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', fontSize: '18px', borderRadius: '50%',
                                }}
                            >
                                DQ
                            </div>
                        );
                    }
                    if ((meIndex === 0) || (meCatIndex === 0)) {
                        return <i style={{ color: yellow[600], fontSize: '36px' }} className='fas fa-trophy' />;
                    }
                    if ((meIndex === 1) || (meCatIndex === 1)) {
                        return <i style={{ color: grey[500], fontSize: '36px' }} className='fas fa-medal' />;
                    }
                    if ((meIndex === 2) || (meCatIndex === 2)) {
                        return <i style={{ color: lime[900], fontSize: '36px' }} className='fas fa-medal' />;
                    }
                    return null;
                })();
            }
        }

        const date = moment([match.startDate.year, match.startDate.month - 1, match.startDate.day, 12, 0, 0, 0])
            .format('LL');

        return (
            <Card key={match.id} classes={{ root: classes.feeditem }} style={{ backgroundImage: `url(${match.cover})` }}>
                <CardActionArea onClick={() => this.openMatch(match.id)}>
                    {registration.staff && (
                        <div className={classes.staff}>
                            {t('staff')}
                        </div>
                    )}
                    <CardHeader
                        avatar={avatar}
                        title={<Typography variant='h5'>{match.title}</Typography>}
                        subheader={`${match.stages.length} ${t('matchhome:shooters-stages-title')} | ${rounds} ${t('matchhome:rounds')} | ${date}`}
                    />
                    <CardContent>
                        <div style={{ marginBottom: '30px' }}>
                            {result}
                        </div>
                        {registration.division && (
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }}>
                                    <Typography variant='caption' style={{ fontWeight: 100, display: 'inline-block' }}>
                                        {t('generic:shooter_division')}
                                        &nbsp;
                                    </Typography>
                                    <Typography variant='caption' style={{ fontWeight: 800, display: 'inline-block' }}>
                                        {t(`generic:division-${registration.division}`)}
                                    </Typography>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <Typography variant='caption' style={{ fontWeight: 100, display: 'inline-block' }}>
                                        {t('generic:shooter_category')}
                                        &nbsp;
                                    </Typography>
                                    <Typography variant='caption' style={{ fontWeight: 800, display: 'inline-block' }}>
                                        {t(`registration:category-${registration.category}`)}
                                    </Typography>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <Typography variant='caption' style={{ fontWeight: 100, display: 'inline-block' }}>
                                        {t('generic:shooter_power_factor')}
                                        &nbsp;
                                    </Typography>
                                    <Typography variant='caption' style={{ fontWeight: 800, display: 'inline-block' }}>
                                        {t(`registration:${registration['power-factor']}`)}
                                    </Typography>
                                </div>
                            </div>
                        )}
                    </CardContent>
                </CardActionArea>
            </Card>
        );
    }

    render() {
        const {
            t, classes, name, accessToken, match: routeMatch, history,
        } = this.props;
        const { matchId } = routeMatch.params;

        const {
            page, loading, feedList, showpushnotifications,
        } = this.state;

        if (matchId) {
            return (
                <HomeAppUserDashboardMatch accessToken={accessToken} match={routeMatch} history={history} name={name} />
            );
        }

        if (loading === '') {
            return null;
        }

        return (
            <div className={classes.wrapper}>

                <div className={classes.topbar}>
                    <Button style={{ visibility: 'hidden' }}><ChevronLeft /></Button>
                    <div style={{ textAlign: 'center', flex: '1', color: 'white' }}>
                        <Typography variant='caption' style={{ color: 'inherit', fontWeight: '400' }}>
                            StartSignal&apos;s Aria
                            {' - '}
                            {name}
                        </Typography>
                    </div>
                    <Button style={{ color: 'white' }} onClick={this.refresh}><Refresh /></Button>
                </div>

                <div
                    className={classes.refreshing}
                    style={{ maxHeight: loading && !!feedList ? '76px' : '0px', height: '40px' }}
                >
                    <CircularProgress size={18} color='inherit' />
                    <Typography variant='caption' style={{ color: 'white' }}>
                        &nbsp;
                        &nbsp;
                        {t('refreshing')}
                    </Typography>
                </div>
                <div className={classes.header}>

                    <Tabs
                        variant='fullWidth'
                        value={page}
                        classes={{ indicator: classes.indicator }}
                        onChange={(e, v) => this.setState({ page: v }, () => window.scrollTo(0, 0))}
                    >
                        <Tab label={<div style={{ width: '100px' }}>{t('past')}</div>} />
                        <Tab label={<div style={{ width: '100px' }}>{t('matches')}</div>} />
                    </Tabs>
                </div>
                {loading && !feedList && (
                    <div
                        style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '102px',
                        }}
                    >
                        <CircularProgress size={72} />
                        <Typography variant='subtitle1' style={{ paddingTop: '12px' }}>
                            {loading}
                        </Typography>
                    </div>
                )}
                {!!feedList && page === 0 && this.renderPast()}
                {!!feedList && page === 1 && this.renderMatches()}
                {showpushnotifications && <PushNotification messaging='app-scoring' accessToken={accessToken} onDone={() => this.setState({ showpushnotifications: false })} />}
            </div>
        );
    }
}

const styles = () => ({
    body: {
        background: grey[200],
    },
    topbar: {
        position: 'fixed',
        zIndex: 10,
        top: '0px',
        left: '0px',
        width: '100%',
        color: 'white',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        background: grey[900],
    },
    header: {
        position: 'fixed',
        zIndex: 10,
        top: '30px',
        left: '0px',
        width: '100%',
        background: grey[900],
        padding: '3px 0px',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    refreshing: {
        position: 'fixed',
        zIndex: 2,
        top: '94px',
        left: '0px',
        width: '100%',
        textAlign: 'center',
        color: 'white',
        background: grey[900],
        opacity: 1,
        overflow: 'hidden',
        transition: 'max-height 1s',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headericon: {
        fontSize: '12px',
        paddingBottom: '12px',
    },
    indicator: {
        background: 'white',
    },
    feed: {
        marginTop: '92px',
        background: grey[200],
        paddingBottom: '24px',
    },
    feedempty: {
        width: '90%',
        margin: 'auto',
        background: grey[200],
        textAlign: 'center',
        paddingTop: '12px',
    },
    feeditem: {
        position: 'relative',
        margin: '12px auto',
        maxWidth: '500px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        '&:before': {
            content: '""',
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.5)',
        },
        '& *': {
            color: 'white !important',
        },
    },
    staff: {
        background: red[400],
        color: 'white',
        display: 'block',
        textAlign: 'center',
        padding: '6px 0px',
        transform: 'rotateZ(-45deg)',
        transformOrigin: '-40px',
        position: 'absolute',
        left: '0px',
        top: '120px',
        width: '160px',
        fontWeight: '800',
    },
});

HomeAppUserDashboard.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string,
        changeLanguage: PropTypes.func,
    }).isRequired,
    name: PropTypes.string.isRequired,
    mode: PropTypes.string,
    accessToken: PropTypes.string.isRequired,
    onUpdateThemeLocale: PropTypes.func.isRequired,
};

HomeAppUserDashboard.defaultProps = {
    mode: null,
};

export default Wrapper(withStyles(styles, { withTheme: true, flip: false })(withTranslation('homeappuserdashboard')(HomeAppUserDashboard)));
