module.exports = {
    en: {
        matchnotusingaria: 'Competing in a match without ARIA?',
        usefortraining: 'or perhaps you just need us for training?',
        description1: 'Quickly enter scores, calculate points, and see factors.',
        createnewmatch: 'Lets Start',
        stagedetails: 'Stage Details',
        stagedetailsname: 'Stage Name',
        stagedetailsdescription: 'How many targets does this stage have?',
        stagescores: 'Stage Scores',
        stagesnoscorestitle: 'No Scores in Stages',
        stagesnoscores: 'Enter your first score for this stage',
        numstages: '{{count}} stage',
        numstages_plural: '{{count}} stages',
        matches: 'Matches',
        newmatch: 'New Match',
        stages: 'Match stages',
        newstage: 'New Stage',
        newscore: 'New Score',
        areyousurescore: 'Are you sure you want to remove this score?',
        areyousurestage: 'Are you sure you want to remove this stage?',
        areyousurematch: 'Are you sure you want to remove this match?',
        shootertitle: 'Shooter name / Score title',
        shooterscore: 'Score',
    },
    he: {
    },
};
