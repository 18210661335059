import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import classnames from 'classnames';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import Header from './Header';
import Pricing from './Pricing';
import ContactUs from './ContactUs';
import Wrapper from './HomeI18nWrapper';
import api from '../Api';

class Aria extends Component {
    constructor(props) {
        super(props);
        this.state = {
            demo: 0,
            loggedIn: false,
        };
    }

    async componentDidMount() {
        if (typeof (window) !== 'undefined') {
            window.scrollTo(0, 0);
        }
        const matches = await api.getMatches();
        const lastMatches = _(matches)
            .values()
            .filter((m) => {
                const startDate = new Date(m.startDate.year, m.startDate.month - 1, m.startDate.day, 12, 0, 0);

                if (startDate.getTime() > new Date().getTime()) return false;
                if (!m.package) return false;
                return true;
            })
            .sortBy([(m) => {
                const startDate = new Date(m.startDate.year, m.startDate.month - 1, m.startDate.day, 12, 0, 0);
                return -1 * startDate.getTime();
            }])
            .value()
            .slice(0, 3);
        this.setState({ matches: lastMatches });
    }

    onDemo = () => {
        const { demo } = this.state;
        console.log('@@@@@@@@@@@@@@@@@@@@@@1');
        this.setState({ demo: demo + 1 });
    }

    render() {
        const { classes, mode, history } = this.props;
        const { demo, loggedIn, matches } = this.state;

        return (
            <Grid classes={{ container: classes.wrapper }} container>
                <Header mode={mode} demo={demo} onLoggedIn={() => this.setState({ loggedIn: true })} onLoggedOut={() => this.setState({ loggedIn: false })} />
                <Grid item classes={{ item: classes.top }} style={mode === 'app' ? { paddingTop: '24px' } : {}} xs={12}>
                    <Typography variant='h2'>&quot;Aria&quot;</Typography>
                    <Typography variant='h6' style={{ fontWeight: '200' }}>IPSC Smart Squad Management and Scoring System</Typography>
                    <Typography variant='h6' style={{ fontWeight: '200' }}>Easy. Fast. Reliable. Fun.</Typography>
                    <img alt='cover' className={classes.headimg} src='/scoring-hand.png' />
                </Grid>
                <Hidden mdUp>
                    <Grid item xs={12} style={{ paddingTop: '24px' }}>
                        {!loggedIn && (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button variant='contained' color='primary' style={{ margin: '0px 12px', height: '40px', width: '42%' }} component={(props) => <Link to='/new' {...props} />}>Sign Up!</Button>
                            <Button variant='contained' color='secondary' style={{ margin: '0px 12px', height: '40px', width: '42%' }} onClick={this.onDemo}>Demo</Button>
                        </div>
                        )}
                        {loggedIn && (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button variant='contained' color='primary' style={{ margin: '0px 12px', height: '40px', width: '70%' }} component={(props) => <Link to='/new' {...props} />}>+ New Match</Button>
                            </div>
                        )}
                    </Grid>
                </Hidden>
                <Grid item xs={12}>
                    <Typography variant='h4' className={classes.howdoesitwork}>How does it work?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={{ margin: '0px 12px' }}>
                        <Grid container classes={{ container: classes.step }}>
                            <Grid item xs={12} md={6}>
                                <img alt='pick stage screen' className={classes.headimg} src='/scoring-stage.png' />
                            </Grid>
                            <Grid item xs={12} md={6} classes={{ item: classes.innerpaper }}>
                                <Hidden mdUp>
                                    <Typography variant='h4'>it all starts by picking a stage</Typography>
                                </Hidden>
                                <Hidden smDown>
                                    <Typography variant='h2'>it all starts by picking a stage</Typography>
                                </Hidden>
                                <Typography variant='body1' style={{ marginTop: '12px' }}>Stages are setup and configured easily in a our dashboard app</Typography>
                                <Typography variant='body1'>No need for paper - briefings can be given from the app.</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Grid container classes={{ container: classes.step }}>
                        <Grid item xs={12} md={6}>
                            <Paper style={{ margin: '12px', maxHeight: '490px', overflow: 'hidden' }}>
                                <Grid container>
                                    <Grid item xs={12} classes={{ item: classes.innerpaper }}>
                                        <Typography variant='h4'>then, you pick a squad</Typography>
                                        <Typography variant='body1' style={{ marginTop: '12px' }}>Shooters pick squads during registration, or you can pick for them. Need to quickly find a shooter? Just search for them.</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <img alt='Squad selection screen' className={classes.headimg} src='/scoring-squad.png' />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper style={{ margin: '12px', maxHeight: '490px', overflow: 'hidden' }}>
                                <Grid container>
                                    <Grid item xs={12} classes={{ item: classes.innerpaper }}>
                                        <Typography variant='h4'>everyone&apos;s here?</Typography>
                                        <Typography variant='body1' style={{ marginTop: '12px' }}>Read attendance, check equipment, set shooting order, and even time the walkthroughs - everything your RO needs to manage the stage is here.</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <img alt='Screen containing list of shooters for squad' className={classes.headimg} src='/scoring-squad-shooters.png' />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={{ margin: '0px 12px' }}>
                        <Grid container classes={{ container: classnames([classes.step, classes.step4]) }}>
                            <Grid item xs={12} md={6} classes={{ item: classes.innerpaper }}>
                                <Typography variant='body1'>pick the next shooter, and ...</Typography>
                                <Typography variant='h2'>Score</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <img alt='Scoring screen' className={classes.headimg} src='/scoring-hand.png' />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Grid container classes={{ container: classes.step }}>
                        <Grid item xs={12} md={6}>
                            <Paper style={{ margin: '12px', maxHeight: '490px', overflow: 'hidden' }}>
                                <Grid container>
                                    <Grid item xs={12} classes={{ item: classes.innerpaper }}>
                                        <Typography variant='h4'>sign, seal, and deliver</Typography>
                                        <Typography variant='body1' style={{ marginTop: '12px' }}>Shooters receive scores, including factors, to their emails as soon as they finish shooting. Immediate verification, and increased competition.</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <img alt='Three screens showing the signing form in the app' className={classes.headimg} src='/scoring-sign.png' />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper style={{ margin: '12px', maxHeight: '490px', overflow: 'hidden' }}>
                                <Grid container classes={{ container: classes.step }}>
                                    <Grid item xs={12} classes={{ item: classes.innerpaper }}>
                                        <Typography variant='h4'>increase engagement</Typography>
                                        <Typography variant='body1' style={{ marginTop: '12px' }}>Live scores are posted to your &quot;Start Signal&quot; website with zero delay. Shooters can check their competition constantly - even on their day off.</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <img alt='People watching scores at their hotel' className={classes.headimg} src='/scoring-live.png' />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Hidden mdUp>
                    <Grid item xs={12} style={{ paddingTop: '6px' }}>
                        {!loggedIn && (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button variant='contained' color='primary' style={{ margin: '0px 12px', height: '40px', width: '42%' }} component={(props) => <Link to='/new' {...props} />}>Sign Up!</Button>
                            <Button variant='contained' color='secondary' style={{ margin: '0px 12px', height: '40px', width: '42%' }} onClick={this.onDemo}>Demo</Button>
                        </div>
                        )}
                        {loggedIn && (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button variant='contained' color='primary' style={{ margin: '0px 12px', height: '40px', width: '70%' }} component={(props) => <Link to='/new' {...props} />}>+ New Match</Button>
                            </div>
                        )}
                    </Grid>
                </Hidden>
                <Grid item xs={12}>
                    <Typography variant='h4' className={classes.howdoesitwork}>What else?</Typography>
                    <div style={{ display: 'flex', padding: '0px 12px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
                            <Typography variant='body2' style={{ fontSize: '18px', fontWeight: '200', margin: '3px' }}>Complete management of the match from one place</Typography>
                            <Typography variant='body2' style={{ fontSize: '18px', fontWeight: '200', margin: '3px' }}>Supports all DQs, penalties (inc. special penalties), bonus targets, and more</Typography>
                            <Typography variant='body2' style={{ fontSize: '18px', fontWeight: '200', margin: '3px' }}>Range Master - Allow reshoots, transfer scores, and more</Typography>
                            <Typography variant='body2' style={{ fontSize: '18px', fontWeight: '200', margin: '3px' }}>Quick messaging to RM and QM</Typography>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
                            <Typography variant='body2' style={{ fontSize: '18px', fontWeight: '200', margin: '3px' }}>Supports all devices that run Chrome</Typography>
                            <Typography variant='body2' style={{ fontSize: '18px', fontWeight: '200', margin: '3px' }}>Export/Import to/from Excel</Typography>
                            <Typography variant='body2' style={{ fontSize: '18px', fontWeight: '200', margin: '3px' }}>Export to WinMss</Typography>
                            <Typography variant='body2' style={{ fontSize: '18px', fontWeight: '200', margin: '3px' }}>Battle tested in small, medium, and large matches</Typography>
                        </div>
                    </div>
                </Grid>
                <Pricing />
                <Hidden mdUp>
                    <Grid item xs={12} style={{ paddingTop: '24px' }}>
                        {!loggedIn && (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button variant='contained' color='primary' style={{ margin: '0px 12px', height: '40px', width: '42%' }} component={(props) => <Link to='/new' {...props} />}>Sign Up!</Button>
                            <Button variant='contained' color='secondary' style={{ margin: '0px 12px', height: '40px', width: '42%' }} onClick={this.onDemo}>Demo</Button>
                        </div>
                        )}
                        {loggedIn && (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button variant='contained' color='primary' style={{ margin: '0px 12px', height: '40px', width: '70%' }} component={(props) => <Link to='/new' {...props} />}>+ New Match</Button>
                            </div>
                        )}
                    </Grid>
                </Hidden>
                <ContactUs />
                <div className={classnames([classes.footer, matches ? classes.footeranimated : null])} style={{ position: 'static', visibility: 'hidden' }}>
                    <Typography variant='subtitle2'>
                        Shooters? Latest matches to use Aria:
                    </Typography>
                    {(matches || []).map((m) => (
                        <Button key={m.title} onClick={() => history.push(`/${m.alias}`)}>
                            <Typography variant='body2'>
                                {m.title}
                            </Typography>
                        </Button>
                    ))}
                    <Button color='primary' onClick={() => history.push('/map')}>
                        <Typography variant='body2' style={{ color: 'inherit', textTransform: 'none' }}>
                            full map
                        </Typography>
                    </Button>
                </div>
                <div className={classnames([classes.footer, matches ? classes.footeranimated : null])}>
                    <Typography variant='subtitle2'>
                        Shooters? Latest matches to use Aria:
                    </Typography>
                    {(matches || []).map((m) => (
                        <Button key={m.title} onClick={() => history.push(`/${m.alias}`)}>
                            <Typography variant='body2'>
                                {m.title}
                            </Typography>
                        </Button>
                    ))}
                    <Button color='primary' onClick={() => history.push('/map')}>
                        <Typography variant='body2' style={{ color: 'inherit', textTransform: 'none' }}>
                            full map
                        </Typography>
                    </Button>
                </div>
            </Grid>
        );
    }
}

const styles = {
    wrapper: {
        textAlign: 'center',
    },
    top: {
        background: 'white',
        paddingTop: '92px',
        position: 'relative',
    },
    headimg: {
        width: '100%',
    },
    howdoesitwork: {
        margin: '24px 0px',
    },
    innerpaper: {
        display: 'flex',
        padding: '12px',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        maxWidth: '66%',
        margin: 'auto',
    },
    step4: {
        background: 'linear-gradient(to bottom, #f44336 0%,#661c17 100%)',
        '& *': {
            color: 'white',
        },
    },
    footer: {
        position: 'fixed',
        bottom: '-100px',
        left: '0px',
        width: 'calc(100% - 24px)',
        background: 'white',
        boxShadow: '1px 1px 4px rgba(0,0,0,0.5)',
        padding: '12px',
        display: 'flex',
        alignItems: 'center',
        transition: 'all 0.3s cubic-bezier(0.34, 0.61, 0.59, 1.11) 0.4s',
    },
    footeranimated: {
        bottom: '0px',
    },
};

Aria.propTypes = {
    mode: PropTypes.string,
    classes: PropTypes.shape({}).isRequired,
    history: ReactRouterPropTypes.history.isRequired,
};

Aria.defaultProps = {
    mode: null,
};

export default Wrapper(withStyles(styles, { withTheme: true, flip: false })(withTranslation('home')(Aria)));
