import React, { useState, useCallback } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Paper from '@material-ui/core/Paper';
import cyan from '@material-ui/core/colors/cyan';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import HomeAppQuickScoreOnlineHeader from './HomeApp.quickscore.onlineheader';

function HomeAppQuickScoreMatch(props) {
    const {
        classes, t, history, data, matchIndex, setData,
    } = props;

    const [showDelete, setShowDelete] = useState(false);

    const matchData = data[matchIndex].match;

    async function onNewStage() {
        const index = (matchData.stages || []).length;
        /*
        await setData(($data) => {
            const newData = JSON.parse(JSON.stringify($data || []));
            newData[matchIndex].stages.push({
                title: `Stage ${index + 1}`,
                scores: [],
            });
            return newData;
        });
        */
        history.push(`/app/2/${matchIndex}/${index}`);
    }

    async function onOpen(index) {
        await new Promise((res) => setTimeout(res, 200)); // For button effect
        history.push(`/app/2/${matchIndex}/${index}`);
    }

    const onDelete = useCallback(async () => {
        await setData(($data) => {
            const $$data = JSON.parse(JSON.stringify($data));
            _.pullAt($$data, matchIndex);
            return $$data;
        });
        history.goBack();
    }, []);

    return (
        <div style={{ paddingBottom: '70px' }}>
            <div className={classes.topbar}>
                <div style={{ flex: 1 }}>
                    <Button variant='text' color='primary'>
                        <Typography variant='body2' style={{ color: grey[500] }} onClick={() => history.goBack()}>
                            {t('homeapp:quickscore')}
                        </Typography>
                    </Button>
                    <i className='fas fa-chevron-right' style={{ fontSize: '12px', opacity: 0.5 }} />
                    <Button variant='text' color='primary' disabled>
                        <Typography variant='body2' style={{ color: cyan[500] }}>
                            {matchData.title}
                        </Typography>
                    </Button>
                </div>
                <IconButton color='secondary' onClick={() => setShowDelete(true)}>
                    <DeleteIcon />
                </IconButton>
            </div>
            {showDelete && (
                <Paper classes={{ root: classes.removequestion }}>
                    <Typography variant='subtitle2' style={{ color: 'inherit' }}>
                        {t('areyousurematch')}
                    </Typography>
                    <div>
                        <Button variant='text' onClick={() => onDelete()}>
                            <Typography variant='body2' style={{ color: 'white' }}>
                                {t('generic:yes')}
                            </Typography>
                        </Button>
                        <Button variant='text' onClick={() => setShowDelete(false)}>
                            <Typography variant='body2' style={{ color: 'white' }}>
                                {t('generic:no')}
                            </Typography>
                        </Button>
                    </div>
                </Paper>
            )}
            <HomeAppQuickScoreOnlineHeader {...props} />
            <Typography variant='body2' style={{ color: grey[500], margin: '0px 12px', marginTop: '12px' }}>{t('stages')}</Typography>
            {matchData.stages.map((stage, index) => (
                <Paper key={stage.created} classes={{ root: classes.matchitemwrapper }}>
                    <ButtonBase
                        classes={{ root: classes.matchitem }}
                        disabled={stage.inactive}
                        onClick={() => onOpen(index)}
                        style={{ opacity: stage.inactive ? 0.5 : 1 }}
                    >
                        <div style={{ flex: 1 }}>
                            <Typography variant='subtitle2'>
                                {stage.title}
                            </Typography>
                            <Typography variant='caption'>
                                {stage.inactive ? 'DELETED' : `${t('score:targets')}: ${stage.targets.paper || '0'} | ${t('generic:poppers')}: ${stage.targets.poppers || '0'}`}
                            </Typography>
                        </div>
                        <div>
                            {!stage.inactive && <i className='fas fa-chevron-right' style={{ fontSize: '18px' }} />}
                        </div>
                    </ButtonBase>
                </Paper>
            ))}
            <div className={classes.newmatch}>
                <Fab color='primary' onClick={() => onNewStage()}>
                    <AddIcon />
                </Fab>
                <Typography variant='caption' style={{ color: blue[500], marginTop: '6px' }}>
                    {t('newstage')}
                </Typography>
            </div>
        </div>
    );
}

const styles = () => ({
    topbar: {
        height: '45px',
        background: 'white',
        display: 'flex',
        alignItems: 'center',
    },
    matchitemwrapper: {
        margin: '12px',
        marginTop: '6px',
    },
    matchitem: {
        padding: '12px',
        textAlign: 'start',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    newmatch: {
        textAlign: 'center',
        margin: '12px',
        padding: '12px',
        background: 'rgba(255, 255, 255, 0.5)',
    },
    removequestion: {
        textAlign: 'center',
        margin: '12px',
        padding: '12px',
        backgroundColor: red[500],
        color: 'white',
    },
});

export default withStyles(styles, { withTheme: true, flip: false })(withTranslation('homeappquickscore')(HomeAppQuickScoreMatch));

HomeAppQuickScoreMatch.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    t: PropTypes.func.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
        match: PropTypes.shape({
            title: PropTypes.string,
            alias: PropTypes.string,
            stages: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    })).isRequired,
    setData: PropTypes.func.isRequired,
    matchIndex: PropTypes.number.isRequired,
};
