module.exports = {
    en: {
        title: 'Welcome to Aria!',
        subject: '{{title}} on Aria',
        description: 'We\'ve got "{{title}}" all set up for you. Here are all the links you\'ll need:',
        'shooters-link-title': 'Your Website',
        'shooters-link-description': 'Send this link to your shooters to see match information, register, and follow live results on the days of the match.',
        'managers-link-title': 'Your Management Site',
        'managers-link-description': 'You can use this link to manage the entire "behind the scenes" of your match. From setting up your match information, to chronograph tests, we have it all. Send this to your staff after setting up their permissions.',
        'scoring-link-title': 'Scoring Site',
        'scoring-link-description': 'On the day of the match (or even a few days before), send this link to your ROs to score shooters. Don\'t forget to set up their accounts in the management site (under staff).',
        app: 'Of course, you can do it all in our mobile app as well! Just download it from Google Play or the App Store',
        footer: 'If you have received this email by mistake, or if you have any questions or comments, feel free to visit us at {{- link}}',
    },
    he: {
        title: 'ברוכים הבאים ל"אריה"!',
        description: 'מזל טוב! התחרות "{{title}}" מוכנה וזמינה לכם באריה. הנה רשימת הלינקים בשבילכם:',
        subject: 'ברוכים הבאים לאריה ({{title}})',
        'shooters-link-title': 'אתר התחרות',
        'shooters-link-description': 'שלחו את הלינק הזה ליורים. באתר הם יוכלו לראות מידע על התחרות, להרשם, ולעקוב אחרי התוצאות ביום התחרות.',
        'managers-link-title': 'אתר הניהול',
        'managers-link-description': 'באתר הניהול אתם יכולים לנהל את כל ״מאחורי הקלעים״ של התחרות שלכם. מהגדרת התחרות, עד לכרונוגרף, יש לנו את כל מה שאתם צריכים!',
        'scoring-link-title': 'אתר הניקוד',
        'scoring-link-description': 'ביום התחרות (או קצת לפני), שילחו את הלינק הזה לשופטי הקו כך שיוכלו לנקד את היורים. אל תשכחו לסדר להם הרשאות באתר הניהול.',
        app: 'כמובן, הכל גם זמין לכם באפליקציה שלנו!',
        footer: 'אם קיבלת מייל זה בטעות, או אם יש לך שאלות או תיקונים, אנחנו נמצאים ב-{{- link}}',
    },
};
