import i18next from 'i18next';
import matchhome from '../client/matches/MatchHome.i18n';
import generic from './generic';
import home from '../client/home/Home.i18n';
import homenew from '../client/home/Home.new.i18n';
import homeapp from '../client/home/HomeApp.i18n';
import homeappuserdashboard from '../client/home/HomeApp.userdashboard.i18n';
import homeappme from '../client/home/HomeApp.me.i18n';
import homeappquickscore from '../client/home/HomeApp.quickscore.i18n';
import homeappshouldresume from '../client/home/HomeApp.shouldresume.i18n';
import stages from '../client/matches/Stages.i18n';
import squads from '../client/matches/Squads.i18n';
import staff from '../client/matches/Staff.i18n';
import printsquads from '../client/matches/PrintSquadsScoreSheet.i18n';
import scoretable from '../client/matches/ScoreTable.i18n';
import viewerresults from '../client/matches/ViewerResults.i18n';
import registration from '../client/matches/Registration.i18n';
import registrationverify from '../client/matches/RegistrationVerify.i18n';
import managewrapper from '../client/matches/ManageWrapper.i18n';
import regionmanagewrapper from '../client/regions/ManageWrapper.i18n';
import managehome from '../client/matches/ManageHome.i18n';
import settings from '../client/matches/Settings.i18n';
import shooterslist from '../client/matches/ShootersList.i18n';
import shooterslistimport from '../client/matches/ShootersListImport.i18n';
import viewerregistration from '../client/matches/ViewerRegistration.i18n';
import score from '../client/matches/Score.i18n';
import upgrade from '../client/matches/Upgrade.i18n';
import authentication from '../client/components/Authentication.i18n';
import addressinput from '../client/components/AddressInput.i18n';
import premium from '../client/components/Premium.i18n';
import RegistrationEmail from '../server/emails/Registration.i18n';
import MatchCreatedEmail from '../server/emails/MatchCreated.i18n';
import ScoreEmail from '../server/emails/Score.i18n';
import AnnouncementsEditor from '../client/matches/AnnouncementsEditor.i18n';
import pushnotification from '../client/matches/PushNotification.i18n';
import chat from '../client/matches/Chat.i18n';
import extracthistory from '../client/matches/ExtractHistory.i18n';
import chrono from '../client/matches/Chrono.i18n';
import exporttowinmss from '../client/matches/ExportToWinMss.i18n';
import smartreports from '../client/matches/SmartReports.i18n';
import compareshooters from '../client/matches/CompareShooters.i18n';
import teamsmanage from '../client/matches/TeamsManage.i18n';
import offlinemanager from '../client/matches/OfflineManager.i18n';
import storagemanager from '../client/matches/StorageManager.i18n';
import managecomments from '../client/matches/ManageComments.i18n';
import newclub from '../client/regions/NewClub.i18n';
import regionshome from '../client/regions/Home.i18n';
import regionsclub from '../client/regions/Club.i18n';
import leaguemain from '../client/leagues/Main.i18n';

console.log('[init] Loading up i18n data');

console.log('[init] Setting up i18n');

i18next.init({
    fallbackLng: 'en',
    resources: {
        en: {
            generic: generic.en,
            home: home.en,
            homenew: homenew.en,
            matchhome: matchhome.en,
            stages: stages.en,
            squads: squads.en,
            staff: staff.en,
            printsquads: printsquads.en,
            scoretable: scoretable.en,
            viewerresults: viewerresults.en,
            registration: registration.en,
            registrationverify: registrationverify.en,
            managewrapper: managewrapper.en,
            regionmanagewrapper: regionmanagewrapper.en,
            managehome: managehome.en,
            settings: settings.en,
            shooterslist: shooterslist.en,
            shooterslistimport: shooterslistimport.en,
            viewerregistration: viewerregistration.en,
            score: score.en,
            authentication: authentication.en,
            addressinput: addressinput.en,
            premium: premium.en,
            upgrade: upgrade.en,
            'registration-email': RegistrationEmail.en,
            'match-created': MatchCreatedEmail.en,
            'score-email': ScoreEmail.en,
            announcements: AnnouncementsEditor.en,
            newclub: newclub.en,
            regionsclub: regionsclub.en,
            regionshome: regionshome.en,
            chat: chat.en,
            extracthistory: extracthistory.en,
            chrono: chrono.en,
            pushnotification: pushnotification.en,
            exporttowinmss: exporttowinmss.en,
            homeapp: homeapp.en,
            homeappuserdashboard: homeappuserdashboard.en,
            homeappme: homeappme.en,
            homeappquickscore: homeappquickscore.en,
            homeappshouldresume: homeappshouldresume.en,
            smartreports: smartreports.en,
            compareshooters: compareshooters.en,
            teamsmanage: teamsmanage.en,
            offlinemanager: offlinemanager.en,
            storagemanager: storagemanager.en,
            managecomments: managecomments.en,
            leaguemain: leaguemain.en,
        },
        he: {
            generic: generic.he,
            home: home.he,
            homenew: homenew.he,
            matchhome: matchhome.he,
            stages: stages.he,
            squads: squads.he,
            staff: staff.he,
            printsquads: printsquads.he,
            scoretable: scoretable.he,
            viewerresults: viewerresults.he,
            registration: registration.he,
            registrationverify: registrationverify.he,
            managewrapper: managewrapper.he,
            managehome: managehome.he,
            settings: settings.he,
            regionmanagewrapper: regionmanagewrapper.he,
            shooterslist: shooterslist.he,
            shooterslistimport: shooterslistimport.he,
            viewerregistration: viewerregistration.he,
            score: score.he,
            authentication: authentication.he,
            addressinput: addressinput.he,
            premium: premium.he,
            upgrade: upgrade.he,
            'registration-email': RegistrationEmail.he,
            'match-created': MatchCreatedEmail.he,
            'score-email': ScoreEmail.he,
            announcements: AnnouncementsEditor.he,
            newclub: newclub.he,
            regionsclub: regionsclub.he,
            regionshome: regionshome.he,
            chat: chat.he,
            extracthistory: extracthistory.he,
            chrono: chrono.he,
            pushnotification: pushnotification.he,
            exporttowinmss: exporttowinmss.he,
            homeapp: homeapp.he,
            homeappuserdashboard: homeappuserdashboard.he,
            homeappme: homeappme.he,
            homeappquickscore: homeappquickscore.he,
            homeappshouldresume: homeappshouldresume.he,
            smartreports: smartreports.he,
            compareshooters: compareshooters.he,
            teamsmanage: teamsmanage.he,
            offlinemanager: offlinemanager.he,
            storagemanager: storagemanager.he,
            managecomments: managecomments.he,
            leaguemain: leaguemain.he,
        },
        iw: {
            generic: generic.iw || generic.he,
            home: home.iw || home.he,
            homenew: homenew.iw || homenew.he,
            matchhome: matchhome.iw,
            stages: stages.iw || stages.he,
            squads: squads.iw || squads.he,
            staff: staff.iw || staff.he,
            printsquads: printsquads.iw || printsquads.he,
            scoretable: scoretable.iw || scoretable.he,
            viewerresults: viewerresults.iw || viewerresults.he,
            registration: registration.iw || registration.he,
            registrationverify: registrationverify.iw || registrationverify.he,
            managewrapper: managewrapper.iw || managewrapper.he,
            managehome: managehome.iw || managehome.he,
            settings: settings.iw || settings.he,
            regionmanagewrapper: regionmanagewrapper.iw || regionmanagewrapper.he,
            shooterslist: shooterslist.iw || shooterslist.he,
            shooterslistimport: shooterslistimport.iw || shooterslistimport.he,
            viewerregistration: viewerregistration.iw || viewerregistration.he,
            score: score.iw || score.he,
            authentication: authentication.iw || authentication.he,
            addressinput: addressinput.iw || addressinput.he,
            premium: premium.iw || premium.he,
            upgrade: upgrade.iw || upgrade.he,
            'registration-email': RegistrationEmail.iw || RegistrationEmail.he,
            'match-created': MatchCreatedEmail.iw || MatchCreatedEmail.he,
            'score-email': ScoreEmail.iw || ScoreEmail.he,
            announcements: AnnouncementsEditor.iw || AnnouncementsEditor.he,
            newclub: newclub.iw || newclub.he,
            regionsclub: regionsclub.iw || regionsclub.he,
            regionshome: regionshome.iw || regionshome.he,
            chat: chat.iw || chat.he,
            extracthistory: extracthistory.iw || extracthistory.he,
            chrono: chrono.iw || chrono.he,
            pushnotification: pushnotification.iw || pushnotification.he,
            exporttowinmss: exporttowinmss.iw || exporttowinmss.he,
            homeapp: homeapp.iw || homeapp.he,
            homeappuserdashboard: homeappuserdashboard.iw || homeappuserdashboard.he,
            homeappme: homeappme.iw || homeappme.he,
            homeappquickscore: homeappquickscore.iw || homeappquickscore.he,
            homeappshouldresume: homeappshouldresume.iw || homeappshouldresume.he,
            smartreports: smartreports.iw || smartreports.he,
            compareshooters: compareshooters.iw || compareshooters.he,
            teamsmanage: teamsmanage.iw || teamsmanage.he,
            offlinemanager: offlinemanager.iw || offlinemanager.he,
            storagemanager: storagemanager.iw || storagemanager.he,
            managecomments: managecomments.iw || managecomments.he,
            leaguemain: leaguemain.iw || leaguemain.he,
        },
    },
});

console.log('[init] Setting up i18n - Done.');
export default i18next;
