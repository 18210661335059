export default function ({ cmd, callbackName, callback }) {
    if (window && window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(JSON.stringify(cmd));
    } else if (window && window.Android && window.Android.sendAppMessage) {
        window.Android.sendAppMessage(JSON.stringify(cmd));
    } else if (window && window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.ipscAppMessageHandler) {
        window.webkit.messageHandlers.ipscAppMessageHandler.postMessage(JSON.stringify(cmd));
    } else {
        window.postMessage(JSON.stringify(cmd), '*');
    }
    if (callback) {
        window[callbackName] = callback;
    }
}
