const lang = {
    en: {
        liveresults: 'Live Results',
        liveverify: 'Live Results (Verify Only)',
        shooterstats: 'Shooter Stats & What If\'s',
        compareshooters: 'Compare Shooters',
        flagspodium: 'Flags Podium',
    },
    he: {
        liveresults: 'תוצאות חיות',
        liveverify: 'תוצאות חיות (ווריפי בלבד)',
        shooterstats: 'סטטיסטיקות של יורה ו״מה אם״',
        compareshooters: 'השווה יורים',
        flagspodium: 'פודיום דגלים',
    },
};

lang.iw = {
    ...lang.he,
    ...{
        shooterstats: 'סטטיסטיקות של לוחם\\ת ו״מה אם״',
        compareshooters: 'השווה לוחמים',
    },
};

export default lang;
