import React, { Component } from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

class SettingsPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currencies: {},
        };
    }

    async componentDidMount() {
        const currencies = (await axios('https://openexchangerates.org/api/currencies.json')).data;
        this.setState({ currencies });
    }

    shouldComponentUpdate(nextProps, nextState) {
        const propFields = ['locale', 'payment', 'paymentParams', 'paymentOfflineDescription', 'paymentAmount', 'paymentCurrency'];
        const stateFields = ['currencies'];

        if (!_.isEqual(_.pick(nextProps, propFields), _.pick(this.props, propFields))) return true;
        if (!_.isEqual(_.pick(nextState, stateFields), _.pick(this.state, stateFields))) return true;
        return false;
    }

    onChangePayment = (e, value) => {
        const { onChange, paymentParams } = this.props;
        const newParams = JSON.parse(JSON.stringify(paymentParams));

        if (value === 'online') {
            newParams.gateway = newParams.gateway || 'paypal';
        }

        onChange({ payment: value, paymentParams: newParams });
    }

    onChangeOfflineDescription = ({ paymentOfflineDescription }) => {
        const { onChange } = this.props;
        onChange({ paymentOfflineDescription });
    }

    onChangePaymentParams = ({ id, value }) => {
        const { onChange, paymentParams } = this.props;
        const newParams = JSON.parse(JSON.stringify(paymentParams));
        _.set(newParams, id, value);
        onChange({ paymentParams: newParams });
    }

    onChangePaymentAmount = ({ paymentAmount }) => {
        const { onChange } = this.props;
        onChange({ paymentAmount });
    }

    onChangePaymentCurrency = ({ paymentCurrency }) => {
        const { onChange } = this.props;
        onChange({ paymentCurrency });
    }

    render() {
        const {
            classes, t, locale, payment, paymentParams, paymentOfflineDescription, paymentAmount, paymentCurrency,
        } = this.props;

        const { currencies = {} } = this.state;
        return (
            <Card>
                <CardHeader
                    classes={{ title: classes.cardtitle }}
                    title={t('payment')}
                    subheader={t('payment-subtitle')}
                />
                <CardContent style={{ paddingTop: '0px' }}>
                    <form className={classes.form}>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <FormControl component='fieldset' style={{ width: '100%' }}>
                                    <RadioGroup
                                        style={{ padding: '0px 15px', width: '100%' }}
                                        value={payment || 'none'}
                                        onChange={this.onChangePayment}
                                    >
                                        <FormControlLabel value='none' control={<Radio />} label={t('payment-none')} />
                                        <FormControlLabel value='offline' control={<Radio />} label={t('payment-offline')} />
                                    </RadioGroup>
                                    {(payment === 'offline') && (
                                        <Paper style={{ padding: '12px', margin: '0px 48px' }}>
                                            <TextField
                                                style={{ width: '100%' }}
                                                label={t('payment-offline-description')}
                                                value={paymentOfflineDescription}
                                                onChange={(e) => this.onChangeOfflineDescription({ paymentOfflineDescription: e.target.value })}
                                            />
                                        </Paper>
                                    )}
                                    <RadioGroup
                                        style={{ padding: '0px 15px', width: '100%' }}
                                        value={payment || 'none'}
                                        onChange={this.onChangePayment}
                                    >
                                        <FormControlLabel value='online' control={<Radio />} label={t('payment-online')} />
                                    </RadioGroup>
                                    {(payment === 'online') && (
                                        <Paper style={{ padding: '12px', margin: '0px 48px' }}>
                                            <div>
                                                <FormLabel component='legend' style={{ fontSize: '0.75em' }}>{t('gateway')}</FormLabel>
                                                <Select
                                                    value={paymentParams.gateway}
                                                    onChange={(e) => this.onChangePaymentParams({ id: 'gateway', value: e.target.value })}
                                                >
                                                    <MenuItem value='paypal'>{t('paypal')}</MenuItem>
                                                    <MenuItem value='z-credit'>{t('z-credit')}</MenuItem>
                                                </Select>
                                                {paymentParams.gateway === 'paypal' && (
                                                    <div>
                                                        <div style={{ marginTop: '12px' }}>
                                                            <TextField style={{ width: '100%' }} label={t('paypal-clientid')} value={_.get(paymentParams, 'credentials.paypal.clientId', '')} onChange={(e) => this.onChangePaymentParams({ id: 'credentials.paypal.clientId', value: e.target.value })} />
                                                        </div>
                                                        <div style={{ marginTop: '12px' }}>
                                                            <TextField style={{ width: '100%', marginTop: '6px' }} label={t('paypal-secret')} value={_.get(paymentParams, 'credentials.paypal.secret', '')} onChange={(e) => this.onChangePaymentParams({ id: 'credentials.paypal.secret', value: e.target.value })} />
                                                        </div>
                                                    </div>
                                                )}
                                                {paymentParams.gateway === 'z-credit' && (
                                                    <div>
                                                        <div style={{ marginTop: '12px' }}>
                                                            <TextField style={{ width: '100%' }} label={t('z-credit-terminal')} value={_.get(paymentParams, 'credentials.z-credit.terminal', '')} onChange={(e) => this.onChangePaymentParams({ id: 'credentials.z-credit.terminal', value: e.target.value })} />
                                                        </div>
                                                        <div style={{ marginTop: '12px' }}>
                                                            <TextField style={{ width: '100%', marginTop: '6px' }} label={t('z-credit-username')} value={_.get(paymentParams, 'credentials.z-credit.username', '')} onChange={(e) => this.onChangePaymentParams({ id: 'credentials.z-credit.username', value: e.target.value })} />
                                                        </div>
                                                    </div>
                                                )}
                                                <div style={{ marginTop: '12px' }}>
                                                    <TextField style={{ marginTop: '6px' }} label={t('payment-amount')} value={paymentAmount} onChange={(e) => this.onChangePaymentAmount({ paymentAmount: e.target.value })} />
                                                    <Select
                                                        style={{ marginTop: '22px' }}
                                                        value={paymentCurrency || 'USD'}
                                                        onChange={(e) => this.onChangePaymentCurrency({ paymentCurrency: e.target.value })}
                                                    >
                                                        {Object.keys(currencies).map((symbol) => (
                                                            <MenuItem value={symbol} key={symbol}>
                                                                {currencies[symbol]}
                                                                {' '}
                                                                (
                                                                {new Intl.NumberFormat(locale, { style: 'currency', currency: symbol }).format('12.34')}
                                                                )
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>
                                            </div>
                                        </Paper>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        );
    }
}

const styles = () => ({
    cardtitle: {
        fontWeight: 300,
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > div': {
            marginTop: '16px',
        },
        '& > div:first-child': {
            marginTop: '0px',
        },
    },
});

SettingsPayment.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    t: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    payment: PropTypes.string,
    paymentAmount: PropTypes.string.isRequired,
    paymentCurrency: PropTypes.string.isRequired,
    paymentOfflineDescription: PropTypes.string.isRequired,
    paymentParams: PropTypes.shape({
        credentials: PropTypes.shape({
            paypal: PropTypes.shape({
                clientId: PropTypes.string,
                secret: PropTypes.string,
            }),
        }),
        gateway: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
};

SettingsPayment.defaultProps = {
    payment: 'none',
    paymentParams: {},
};

export default withStyles(styles, { withTheme: true })(withTranslation('settings')(SettingsPayment));
