import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import GoogleSignIn from '../components/GoogleSignIn';
import FacebookSignIn from '../components/FacebookSignIn';
import Wrapper from './HomeI18nWrapper';

class HomeSignIn extends Component {
    constructor(props) {
        super(props);

        this.state = { };
    }

    UNSAFE_componentWillMount() {
        const { i18n: $i18n } = this.props;
        document.body.style.background = 'white';
        document.body.style.direction = 'ltr';
        document.body.setAttribute('dir', 'ltr');
        document.body.classList.remove('rtl');
        document.body.classList.add('ltr');
        $i18n.changeLanguage('en-US');
        moment.locale('en-US');
    }

    googleLogin = (e) => {
        window.sessionStorage.setItem('loggedin', JSON.stringify({
            auth: `com.google|${e.authResponse.id_token}`,
            user: `com.google|${e.email}`,
            name: e.name,
        }));
        window.location.href = '/';
    }

    facebookLogin = (e) => {
        window.sessionStorage.setItem('loggedin', JSON.stringify({
            auth: `com.facebook|${e.accessToken}`,
            user: `com.facebook|${e.id}`,
            name: e.name,
        }));
        window.location.href = '/';
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.wrapper}>
                <div className={classes.logo} />
                <Typography variant='h4' className={classnames([classes.lightweight, classes.margintop])}>
                    Log In
                </Typography>
                <Typography variant='h6' className={classnames([classes.lightweight, classes.margintop])}>
                    New to Start Signal?
                    {' '}
                    <Link to='/new'>Sign Up</Link>
                </Typography>
                <div style={{ marginTop: '24px' }}>
                    <GoogleSignIn onLogin={this.googleLogin} onLogout={this.googleLogout} noAuto />
                </div>
                <div style={{ marginTop: '24px' }}>
                    <FacebookSignIn onLogin={this.facebookLogin} onLogout={this.facebookLogout} noAuto />
                </div>
            </div>
        );
    }
}

HomeSignIn.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    i18n: PropTypes.shape({
        changeLanguage: PropTypes.func.isRequired,
    }).isRequired,
};

const styles = {
    wrapper: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    lightweight: {
        fontWeight: '100',
    },
    margintop: {
        marginTop: '12px',
    },
    logo: {
        alignItems: 'center',
        height: '123px',
        width: '300px',
        backgroundImage: 'url("./logo.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
};

export default Wrapper(withStyles(styles, { withTheme: true, flip: false })(withTranslation('home')(HomeSignIn)));
