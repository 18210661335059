import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import green from '@material-ui/core/colors/green';
import BluetoothSearching from '@material-ui/icons/BluetoothSearching';
import app from '../AppCommunication';

function ScoreScoringBluetooth(props) {
    const {
        t,
        classes,
        open,
        onUpdateScore,
    } = props;

    const [callback, setCallback] = useState(null);
    const [selected, setSelected] = useState(null);
    const [devices, setDevices] = useState([]);
    const [events, setEvents] = useState([]);
    const [eventsIndex, setEventsIndex] = useState(null);
    const [canStart, setCanStart] = useState(true);

    const prevProps = usePrevious({ open });
    const prevState = usePrevious({ selected });

    useEffect(() => {
        const $callback = Math.random().toString();
        setCallback($callback);

        return () => {
            app({ cmd: { src: 'bluetooth', type: 'stop-search' } });
            app({ cmd: { src: 'bluetooth', type: 'stop-listening', params: { callback } } });
        };
    }, []);

    useEffect(() => {
        if (!callback) return;
        window[callback] = ((response) => {
            if (response.type === 'search-result' && open && !selected) {
                setDevices(response.devices || []);
            }

            if (response.type === 'event' && selected) {
                const parsed = (response.event || '').replace(/\n/, '').split(',');
                if (parsed[0] === 'STANDBY') {
                    setEvents([]);
                    setEventsIndex(null);
                    setCanStart(true);
                } else if (parsed[0].match(/^\d+$/)) {
                    const newEvents = _.sortBy([
                        { index: parseInt(parsed[0], 10), time: parseInt(parsed[2], 10) },
                        ...events,
                    ], 'index');
                    setEvents(newEvents);
                    setEventsIndex(newEvents.length - 1);
                    setCanStart(false);
                }
            }
        });
    }, [callback, open, selected, events]);

    useEffect(() => {
        if (!prevProps || !prevState) return;

        if (!prevProps.open && open) {
            if (localStorage.getItem('__scoring_bluetooth_cache')) {
                const cache = JSON.parse(localStorage.getItem('__scoring_bluetooth_cache'));
                if (new Date().getTime() - cache.timestamp < 24 * 60 * 60 * 1000) {
                    setSelected(cache.device);
                }
            } else {
                app({ cmd: { src: 'bluetooth', type: 'search', params: { callback } } });
            }
        }

        if (prevProps.open && !open) {
            app({ cmd: { src: 'bluetooth', type: 'stop-search' } });
        }

        if (!prevState.selected && selected) {
            app({ cmd: { src: 'bluetooth', type: 'stop-search' } });
        }

        if (prevState.selected && !selected) {
            app({ cmd: { src: 'bluetooth', type: 'stop-listening', params: { callback } } });
            app({ cmd: { src: 'bluetooth', type: 'search', params: { callback } } });
        }

        if (prevState.selected !== selected) {
            if (prevState.bluetoothSelected) {
                app({ cmd: { src: 'bluetooth', type: 'stop-listening', params: { callback } } });
            }
            if (selected) {
                app({ cmd: { src: 'bluetooth', type: 'listen', params: { deviceId: selected.id, callback } } });
            }
        }
    }, [open, selected]);

    async function onTimerStart() {
        await Promise.all([setEvents([]), setEventsIndex(null)]);
        app({ cmd: { src: 'bluetooth', type: 'timer-start', params: { deviceId: selected.id } } });
    }

    async function onTimerReset() {
        await Promise.all([setEvents([]), setEventsIndex(null)]);
        app({ cmd: { src: 'bluetooth', type: 'timer-reset', params: { deviceId: selected.id } } });
    }

    return (
        <Paper
            classes={{ root: classes.wrapper }}
            style={{
                display: open ? 'flex' : 'none',
            }}
        >
            <BluetoothSearching fontSize='small' style={{ paddingTop: '6px' }} />
            {!selected && (
                <div style={{ margin: '0px 6px' }}>
                    <Typography variant='body1'>
                        {t('havebluetooth')}
                    </Typography>
                    {devices.length === 0 && (
                        <Typography variant='caption'>
                            {t('havebluetoothsearch')}
                        </Typography>
                    )}
                    {devices.length > 0 && (
                        <Typography variant='caption'>
                            {t('havebluetoothsearchresults')}
                        </Typography>
                    )}
                    {devices.map((device) => (
                        <Button
                            fullWidth
                            onClick={() => {
                                localStorage.setItem('__scoring_bluetooth_cache', JSON.stringify({ device, timestamp: new Date().getTime() }));
                                setDevices([]);
                                setSelected(device);
                            }}
                            color='primary'
                            key={device.id}
                            style={{ marginTop: '6px' }}
                        >
                            {device.name}
                        </Button>
                    ))}
                </div>
            )}
            {selected && (
                <div style={{ margin: '0px 6px', width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='body1'>
                            {t('bluetoothconnected', { name: selected.name })}
                        </Typography>
                        <Button
                            onClick={() => {
                                localStorage.removeItem('__scoring_bluetooth_cache');
                                setSelected(null);
                            }}
                        >
                            <Typography variant='caption'>
                                {t('bluetoothdisconnect')}
                            </Typography>
                        </Button>
                    </div>
                    {events.length > 0 && (
                        <div>
                            <div style={{ padding: '12px 0px', textAlign: 'center' }}>
                                <div style={{ fontFamily: '\'Orbitron\', sans-serif', fontSize: '2em' }}>
                                    {(events[eventsIndex || 0].time / 1000).toFixed(2)}
                                </div>
                                <Typography variant='caption' style={{ direction: 'ltr' }}>
                                    Shot:
                                    &nbsp;
                                    {(eventsIndex || 0) + 1}
                                    /
                                    {events.length}
                                    &nbsp;
                                    &nbsp;
                                    Par:
                                    &nbsp;
                                    {!eventsIndex ? ' -' : ((events[eventsIndex].time - events[eventsIndex - 1].time) / 1000).toFixed(2)}
                                </Typography>
                            </div>
                            <Button
                                fullWidth
                                variant='contained'
                                color='secondary'
                                style={{ marginBottom: '12px', flex: 1 }}
                                onClick={() => {
                                    const value = (events[eventsIndex || 0].time / 1000).toFixed(2);
                                    onUpdateScore('time', value);
                                }}
                            >
                                {t('bluetoothsetastime')}
                            </Button>
                        </div>
                    )}
                    <div style={{ display: 'flex', width: '100%' }}>
                        <Button
                            variant='contained'
                            color='primary'
                            style={{ marginTop: '6px', flex: 1 }}
                            disabled={events.length === 0}
                            onClick={() => {
                                setEventsIndex((eventsIndex + 1) % events.length);
                                setCanStart(true);
                            }}
                        >
                            {t('review')}
                        </Button>
                        <div style={{ width: '5%' }}>&nbsp;</div>
                        <Button
                            fullWidth
                            onClick={() => onTimerStart()}
                            variant='contained'
                            color='primary'
                            style={{ marginTop: '6px', flex: 1 }}
                            disabled={!canStart}
                        >
                            {t('start')}
                        </Button>
                        <div style={{ width: '5%' }}>&nbsp;</div>
                        <Button
                            fullWidth
                            onClick={() => onTimerReset()}
                            variant='contained'
                            color='primary'
                            style={{ marginTop: '6px', flex: 1 }}
                            disabled={!canStart}
                        >
                            {t('reset')}
                        </Button>
                    </div>
                </div>
            )}
        </Paper>
    );
}

const styles = () => ({
    wrapper: {
        width: '100%',
        padding: '12px',
        lineHeight: '1.5em',
        background: green[100],
        transition: 'background 0.2s ease-in-out',
    },
});

ScoreScoringBluetooth.propTypes = {
    t: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    classes: PropTypes.shape({}).isRequired,
    onUpdateScore: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(withTranslation('score')(ScoreScoringBluetooth));

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}
