const lang = {
    en: {
        moreinfotitle: 'How is this calculated?',
        percentageindivision: 'Overall % (in division)',
        combinedpercentageoverall: 'Overall % (combined scores)',
        scoredistribution: 'Score Distribution',
        accuracyscore: 'Accuracy Score (0 - 100)',
        accurayscoremoreinfo: '"Accuracy Score" is the mean accuracy over all stages. Shooter\'s accuracy for a stage is calculated by the shooter\'s points (assuming minor) divided by the best score achieved by all shooters.',
        timescore: 'Speed Score (0 - 100)',
        timescoremoreinfo: '"Speed Score" is the mean speed over all stages. Shooter\'s speed for a stage is calculated by the shooter\'s speed related to the fastest and slowest shooters in the stage (100 is the fastest, 0 is the slowest).',
        stagestitle: 'Stages',
        graphtitle: 'Some graphs are always good...',
        graphfactor: 'Normalized Factors',
        graphfactordescription: 'This graph shows shooters normalized factors for each of the stages they\'ve shot (normalized to the stage\'s best factor across all divisions). A shooter\'s normalized factors should, of course, be as high as possible, but at the same time, it should be consistent across all stages (emphasizing skill and not luck).',
        graphaccuracy: 'Normalized Score',
        graphaccuracydescription: 'This graph shows shooters normalized score for each of the stages they\'ve shot (normalized to the stage\'s best score across all divisions). Same as with normalized factors, a shooter\'s normalized score should, of course, be as high as possible, but at the same time, it should be consistent across all stages (emphasizing skill and not luck).',
        graphspeed: 'Normalized Time',
        graphspeeddescription: 'This graph shows shooters normalized time (speed) for each of the stages they\'ve shot (normalized to the stage\'s best and worst time across all divisions. 100 is the fastest shooter, 0 is the slowest). Same as with normalized factors and score, a shooter\'s normalized time should, of course, be as high as possible (high = fast), but at the same time, it should be consistent across all stages (emphasizing skill and not luck).',
        stageresults: 'Factor, time, and points',
        whatif: 'What if?',
        whatifdescription: 'Ever wondered what if you never missed that target? What if all your scores were alphas? We\'ve got you covered!',
        metals: '{{hit}} hit(s) and {{miss}} miss(es)',
        graphscombined: 'Normalize graphs to all divisions (and not only in shooters\' division).',
        whatifmoremessage: 'You are currently "What If"-ing. Do you want to see the full score table in this scenario? If so click',
        whatiftoscores: 'here',
        whatifminormajor: 'What if I was {{other}}?',
        close: 'Close',
        reset: 'Reset',
    },
    he: {
        moreinfotitle: 'איך אנחנו מחשבים את זה?',
        percentageindivision: 'מיקום בתוצאות הכלליות למחלקה',
        combinedpercentageoverall: 'מיקום בתוצאות הכלליות המשולבות',
        scoredistribution: 'התפלגות פגיעות',
        accuracyscore: 'ציון פגיעות (0 - 100)',
        accurayscoremoreinfo: '"ציון פגיעות" מחשב את ממוצע איכות הפגיעות על פני כל התרגילים. איכות הפגיעות לתרגיל מחושב לפי התפלגות האלפות, צ׳רלים, דאלות ומיסים, ביחס להתפלגות של היורה עם הציון הגבוה ביותר.',
        timescore: 'ציון מהירות (0 - 100)',
        timescoremoreinfo: '"ציון מהירות" מחשב את ממוצע מהירות היורה על פני כל התרגילים. מהירות יורה לתרגיל מחושב ביחס ליורה המהירֿֿ\/ה ביותר, וליורה האיטי\/ת ביותר בכל תרגיל (על פני כל המחלקות).',
        stagestitle: 'תרגילים',
        graphtitle: 'קצת גרפים זה תמיד טוב...',
        graphfactor: 'פקטור מנורמל',
        graphfactordescription: 'הגרף הבא מציג את הפקטורים המנורמלים של היורה לכל תרגיל. הפקטורים מנורמלים ביחס לפקטור הטוב ביותר בין כל היורים בכל המחלקות.',
        graphaccuracy: 'ניקוד (פגיעות) מנורמל',
        graphaccuracydescription: 'הגרף הבא מציג את הניקוד המנורמל של היורה לכל תרגיל. הניקוד מנורמל ביחס לניקוד הטוב ביותר בין כל היורים בכל המחלקות.',
        graphspeed: 'מהירות מנורמלת',
        graphspeeddescription: 'הגרף הבא מציג את מהירות (זמן) המנורמלת של היורה לכל תרגיל. המהירות מנורמלת ביחס למהירות הטובה ביותר והמהירות האיטית ביותר בין כל היורים בכל המחלקות.',
        stageresults: 'פקטור, זמן וניקוד',
        whatif: 'מה אם?',
        whatifdescription: 'אי פעם תהיתם מה היה קורה אם במקום מיס היה לכם צ׳רלי? אם היה לוקח לכם פחות שניה לטפל במעצור? עכשיו אתם יכולים לבדוק את זה!',
        metals: '{{hit}} פגיעות ו-{{miss}} מיסים',
        graphscombined: 'נרמל את הגרפים ביחס לכל המחלקות (ולא רק למחלקה של היורה).',
        whatifmoremessage: 'אתם כרגע צופים במקרה של ״מה אם״. ניתן לעבור לצפות בתוצאות המלאות במקרה החדש',
        whatiftoscores: 'בלחיצה כאן',
        whatifminormajor: 'מה אם הייתי {{other}}?',
        close: 'סגור',
        reset: 'אפס',
    },
};

lang.iw = {
    ...lang.he,
    ...{
        accurayscoremoreinfo: '"ציון פגיעות" מחשב את ממוצע איכות הפגיעות על פני כל התרגילים. איכות הפגיעות לתרגיל מחושב לפי התפלגות האלפות, צ׳רלים, דאלות ומיסים, ביחס להתפלגות של הלוחם/ת עם הציון הגבוה ביותר.',
        timescore: 'ציון מהירות (0 - 100)',
        timescoremoreinfo: '"ציון מהירות" מחשב את ממוצע מהירות הלוחם/ת על פני כל התרגילים. מהירות לוחם/ת לתרגיל מחושב ביחס ללוחם/ת המהירֿֿ\/ה ביותר, וללוחם/ת האיטי\/ת ביותר בכל תרגיל (על פני כל המחלקות).',
        graphfactordescription: 'הגרף הבא מציג את הפקטורים המנורמלים של הלוחם/ת לכל תרגיל. הפקטורים מנורמלים ביחס לפקטור הטוב ביותר בין כל הלוחמים בכל המחלקות.',
        graphaccuracy: 'ניקוד (פגיעות) מנורמל',
        graphaccuracydescription: 'הגרף הבא מציג את הניקוד המנורמל של הלוחם/ת לכל תרגיל. הניקוד מנורמל ביחס לניקוד הטוב ביותר בין כל הלוחמים בכל המחלקות.',
        graphspeed: 'מהירות מנורמלת',
        graphspeeddescription: 'הגרף הבא מציג את מהירות (זמן) המנורמלת של הלוחם/ת לכל תרגיל. המהירות מנורמלת ביחס למהירות הטובה ביותר והמהירות האיטית ביותר בין כל הלוחמים בכל המחלקות.',
        graphscombined: 'נרמל את הגרפים ביחס לכל המחלקות (ולא רק למחלקה של הלוחם/ת).',
    },
};

export default lang;
