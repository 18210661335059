const lang = {
    en: {
        new: 'Create New Announcement',
        existing: 'Current Announcements',
        subject: 'Announcement Title',
        'subject-empty-error': 'Don\'t forget to add a title to your announcement',
        'editor-empty-error': 'It seems you forgot the announcement itself',
        'send-by-email': 'Send announcement to registered shooters by email',
        'coming-soon': '(coming soon)',
        post: 'Announce',
        'post-success': 'Announcement posted to website successfully',
        'announce-website': 'Post announcement to website.',
        'announce-email': 'Send announcement by email to:',
        'past-announce-website': 'Posted to website.',
        'past-announce-email': 'Sent by email to:',
        'email-all': 'all registered shooters',
        'email-paid': 'only shooters that have paid',
        'email-not-paid': 'only shooters that haven\'t paid yet',
        'email-squad': 'only shooters registered to squad \'{{squad}}\'',
    },
    he: {
        new: 'יצירת עדכון חדש',
        existing: 'עדכונים קיימים',
        subject: 'כותרת לעדכון',
        'subject-empty-error': 'שכחתם כותרת לעדכון?',
        'editor-empty-error': 'מה עם גוף העדכון?',
        'send-by-email': 'שלח את העדכון לכל היורים הרשומים באימייל',
        'coming-soon': '(בקרוב)',
        post: 'עדכן',
        'post-success': 'העדכון פורסם באתר בהצלחה',
        'announce-website': 'הצג עדכון באתר.',
        'announce-email': 'שלח עדכון באימייל ל:',
        'past-announce-website': 'פורסם באתר.',
        'past-announce-email': 'נשלח באימייל ל:',
        'email-all': 'כל היורים',
        'email-paid': 'רק יורים ששילמו',
        'email-not-paid': 'רק יורים שטרם שילמו',
        'email-squad': 'רק יורים בסקווד \'{{squad}}\'',
    },
};

lang.iw = {
    ...lang.he,
    ...{
        'email-squad': 'רק ללוחמים במקצה \'{{squad}}\'',
        'send-by-email': 'שלח את העדכון לכל הלוחמים הרשומים באימייל',
        'email-all': 'כל הלוחמים',
        'email-paid': 'רק לוחמים ששילמו',
        'email-not-paid': 'רק לוחמים שטרם שילמו',
    },
};

export default lang;
