const lang = {
    en: {
        title: 'Settings',
        publishing: 'Site Pages',
        'publishing-subtitle': 'Configure which pages in your site are available to shooters',
        registration: 'Registration',
        'registration-subtitle': 'Configure how shooters register to your match',
        'registration-open': 'Open on web site',
        'registration-open-code': 'On web site, but requires special code to access',
        'registration-open-code-placeholder': 'Access code',
        'registration-sold-out': 'Closed (we\'re fully booked)',
        'registration-period-over': 'Closed (registration period is over)',
        'registration-period-over-can-patch': 'Closed (registration period is over) - However, shooters can still update information.',
        'registration-closed': 'Closed (registration not on web site)',
        'registration-fields': 'What information do shooters need to enter during registration?',
        'registration-patch-locked': 'What information shooters cannot change after registration?',
        'registration-ics': 'ICS Classification',
        'registration-ics-checkbox': 'Shooter registrations and scores should include ICS classification.',
        'registration-limit': 'Limit registration',
        'registration-limit-checkbox': 'Limit registration only to authorized shooters listed in the following Google Sheets document:',
        'registration-limit-url': 'https://docs.google.com/spreadsheets/...',
        'registration-limit-url-error': 'The URL you\'ve entered doesn\'t seem to be a valid URL. It should look something like this: "https://docs.google.com/spreadsheets/...".',
        'registration-limit-description': 'The Google Sheet document should include 5 columns - Shooter Region, Shooter Id, Shooter Full Name, and Shooter Club. Also, leave first row blank, or use it for titles. Get a view-only sharable link to the sheet (using the Share button in Google Sheets), and paste the URL to the field above.',
        'registration-include-divisions': 'Match includes divisions:',
        squadselection: 'Squad Selection',
        'squadselection-subtitle': 'Configure when shooters pick their squads',
        'squad-selection-closed': 'Shooters don\'t pick their squads. Squad selection is made by staff.',
        'squad-selection-during-registration': 'Shooters may pick their squads during registration',
        'squad-selection-after-payment': 'Shooters may pick their squads only after they have paid for the match',
        scoring: 'Scoring',
        'scoreformdisplaymode-title': 'Scoring Summary Page',
        'scoreformdisplaymode-subtitle': 'Select what information would you like the scoring summary page, on the RO\'s app, to contain: (not, this does not affect the email sent, which will show full information)',
        'scoreformdisplaymode-normal': 'Full information - including breakdown of scores per targets.',
        'scoreformdisplaymode-corona': 'Condensed summary - including only aggregated count of shots per score & penalties.',
        'scoremode-title': 'Scoring Method',
        'scoremode-subtitle': 'Select how you want to competition to be scored',
        'scoremode-comstock': 'Standard comstock scoring, as defined in 9.2.1 of the rule book.',
        'scoremode-steel-no-miss': 'Steel challenge scoring. No points deducted for a miss.',
        'scoremode-comstock-abd': 'Standard comstock scoring, with the exception that charlie scores are marked as B instead of C.',
        'scoremode-il-army': 'Battle challenge scoring. No (D) and battle criteria bonuses.',
        payment: 'Payment',
        'payment-subtitle': 'How do shooters pay for the match?',
        'payment-none': 'Match is free.',
        'payment-offline': 'Shooters contact us directly for payment (offline).',
        'payment-offline-description': 'How do shooters contact you? What are the payment conditions? (will be displayed on site)',
        'payment-online': 'Shooters pay online during registration using PayPal / Credit Card.',
        'payment-amount': 'Cost of match',
        'paypal-clientid': 'Paypal client id',
        'paypal-secret': 'Paypal secret',
        notifications: 'Emails & Printers',
        'notifications-email': 'Emails',
        'notifications-email-subtitle': 'Configure who should receive automatic emails and when.',
        'notifications-scores': 'Scores',
        'notifications-scores-shooter-email': 'Email scores to shooters when they finish a stage.',
        'notifications-scores-non-shooter-email': 'Email scores to staff when shooters finish a stage.',
        'notifications-enter-email': 'Enter email and press enter (eg. statsoffice@ipscmatch.com)',
        'notifications-email-error': 'Please remove invalid emails or you won\'t be able to save.',
        'notifications-printers': 'Printers',
        'notifications-printers-subtitle': 'Automatically print scores and results',
        'notifications-configured-printers': 'Configured Printers',
        printer: 'Printer',
        'printer-id': 'Printer ID',
        'printer-stages': 'Which stages should the printer print?',
        'no-printers-defined': 'No printers defined yet',
        'notifications-add-printer': 'Add New Printer',
        'notifications-add-printer-description': 'If this is your first time adding a printer, read the instructions ###here###.',
        'printer-name': 'Printer name (for easy identification)',
        'new-printer-unknown': 'Hmm. We can\'t seem to find this printer\'s share invitation. Did you follow the steps above?',
        'new-printer-unauthorized': 'Hmm. We don\'t seem to have permissions to use the printer. Did you follow the steps above?',
        'new-printer-error': 'Hmm. Something went wrong. Did you follow the steps above?',
        'new-printer-printer-exists': 'A printer with this ID already exists in the list above.',
        contacts: 'Contacts',
        'contacts-subtitle': 'Contact numbers for staff',
        'contact-rm': 'To contact the RM, staff should call or send a message this number:',
        'contact-tech': 'To contact on-site tech support, staff should call or send a message this number:',
        gateway: 'Payment gateway',
        paypal: 'Paypal for Businesses',
        'z-credit': 'Z-credit (https://www.z-credit.com)',
        'z-credit-terminal': 'Terminal number',
        'z-credit-username': 'Username',
        'field-equipment': 'Equipment Belt',
        shooternames: 'Shooter Names',
        shooternamessubtitle: 'How are shooter names displayed on the public website?',
        'anonymize-none': 'Full name (eg. "John Doe")',
        'anonymize-firstname': 'First name abbreviated (eg. "J. Doe")',
        'anonymize-lastname': 'Last name abbreviated (eg. "John D.")',
        advanced: 'Advanced',
        scoringmode: 'Scoring Method',
        'scoringmode-subtitle': '"It\'s not the percentage, or the points, or the rules, or the curvature of the Earth when the moon is in the seventh house, and Jupiter aligns with Mars. It\'s the Russians. Or the Ukrainians." (Vince Pinto)',
        'winmss-compatible': 'Use WinMSS compatible calculations, even when it doesn\'t align with the rulebook.',
        roRegistration: 'RO Registration',
        'roRegistration-subtitle': 'Configure how ROs register for your match',
    },
    he: {
        title: 'הגדרות',
        publishing: 'עמודים באתר',
        'publishing-subtitle': 'הגדירו אילו עמודים באתר זמינים לתצוגה ליורים',
        registration: 'הרשמה',
        'registration-subtitle': 'הגדירו את תהליך ההרשמה של יורים לתחרות שלכם',
        'registration-open': 'פתוחה באתר',
        'registration-open-code': 'באתר, אך נדרש קוד גישה מיוחד להגיע לעמוד ההרשמה:',
        'registration-open-code-placeholder': 'קוד הגישה',
        'registration-sold-out': 'סגורה (אנחנו מלאים)',
        'registration-period-over': 'סגורה (מועד ההרשמה עבר)',
        'registration-period-over-can-patch': 'סגורה (מועד ההרשמה עבר) אבל יורים יכולים עוד לעדכן נתונים',
        'registration-closed': 'סגורה (רישום איננו מתבצע באתר)',
        'registration-fields': 'אילו פרטים היורים צריכים להכניס בתהליך ההרשמה?',
        'registration-patch-locked': 'אילו פרטים לא ניתן לשנות לאחר הרשמה?',
        'registration-ics': 'קלסיפיקציית ICS',
        'registration-ics-checkbox': 'רישום יורים והצגת תוצאות יכילו קלסיפיקציית ICS',
        'registration-limit': 'רשימת מאושרים',
        'registration-limit-checkbox': 'אפשר הרשמה רק ליורים הרשומים במסמך ה-Google Sheets הבא:',
        'registration-limit-url': 'https://docs.google.com/spreadsheets/...',
        'registration-limit-url-error': 'הכתובת שהכנסת איננה חוקית. הכתובת אמורה להראות משהו כמו: "https://docs.google.com/spreadsheets/...".',
        'registration-limit-description': 'על מסמך ה-Google Sheets להכיל 5 עמודות: מדינת היורה (IL), מספר יורה, שם היורה המלא ומועדון היורה. יש להשאיר את השורה הראשונה במסמך ריקה (או לנצלה לכותרות לעמודות). לאחר יצירת הקובץ, יש להשיג "view-only sharable link" באמצעות כפתור ה-Share ב-Google Sheets, ולהדביק את הכתובת המוצגת לכאן.',
        'registration-include-divisions': 'התחרות כוללת את המחלקות:',
        squadselection: 'בחירת סקוודים',
        'squadselection-subtitle': 'הגדירו מתי יורים יכולי לבחור את הסקוודים שלהם',
        'squad-selection-closed': 'יורים אינם בוחרים את הסקוודים - החלוקה מתבצעת על-ידינו',
        'squad-selection-during-registration': 'יורים יכולים לבחור סקוודים בזמן תהליך הרישום',
        'squad-selection-after-payment': 'יורים יכולים לבחור סקוודים רק לאחר ביצוע תשלום על התחרות',
        scoring: 'ניקוד',
        'scoreformdisplaymode-title': 'תצוגת סיכום ניקוד',
        'scoreformdisplaymode-subtitle': 'איזה מידע ברצונך להציג במסך סיכום הניקוד במכשיר של השופט (מסך החתימות): (כמובן, האימייל שנשלח מכיל מידע מלא)',
        'scoreformdisplaymode-normal': 'מידע מלא - הכולל רשימת המטרות והציון לכל מטרה.',
        'scoreformdisplaymode-corona': 'מידע מתומצת - הכולל רק כמות פגיעות ועונשים מכל סוג.',
        'scoremode-title': 'שיטת ניקוד',
        'scoremode-subtitle': 'באיזו שיטת ניקוד מנקדים את התחרות?',
        'scoremode-comstock': 'שיטת comstock סטנדרטית כפי שמוגדרת בסעיף 9.2.1 של ספר הכללים.',
        'scoremode-steel-no-miss': 'תחרות ברזלים, בה אין הורדת ניקוד על פספוס מטרת מתכת.',
        'scoremode-comstock-abd': 'שיטת comstock סטנדרטית, רק במקום אזור C יש אזור B.',
        'scoremode-il-army': 'תחרות לחימה. ללא אזור D וכולל בונוסים ליכולות לחימה.',
        payment: 'תשלום',
        'payment-subtitle': 'איך יורים ישלמו על התחרות?',
        'payment-none': 'התחרות חינם',
        'payment-offline': 'יורים יפנו אלינו ישירות לתשלום (במזומן או בהעברה בנקאית)',
        'payment-offline-description': 'הוראות תשלום (יוצגו באתר)',
        'payment-online': 'יורים ישלמו באתר באמצעות PayPal או אשראי (ישנה עמלה של PayPal)',
        'payment-amount': 'עלות התחרות',
        'paypal-clientid': 'Paypal client id',
        'paypal-secret': 'Paypal secret',
        notifications: 'אימייל והדפסות',
        'notifications-email': 'אימייל',
        'notifications-email-subtitle': '',
        'notifications-scores': 'ניקוד',
        'notifications-scores-shooter-email': 'שלח ניקוד ליורים לאחר סיום תרגיל.',
        'notifications-scores-non-shooter-email': 'שלח ניקוד לבעלי תפקידים לאחר שיורה סיים/ה תרגיל.',
        'notifications-enter-email': 'הכניסו אימייל ולחצו על אנטר (לדוגמא statsoffice@ipscmatch.com)',
        'notifications-email-error': 'מצאנו כתובות לא חוקיות ברשימה. יש למחוק אותם לפני שמירה.',
        'notifications-printers': 'מדפסות',
        'notifications-printers-subtitle': 'הדפסה אוטומטית של תוצאות, ישר בסיום התרגיל',
        'notifications-configured-printers': 'מדפסות מוגדרות',
        printer: 'מדפסת',
        'printer-id': 'מזהה מדפסת',
        'printer-stages': 'תרגילים שיודפסו במדפסת',
        'no-printers-defined': 'טרם הוגדרו מדפסות במערכת',
        'notifications-add-printer': 'הוספת מדפסת חדשה',
        'notifications-add-printer-description': 'אם זו הפעם הראשונה שלכם להוסיף מדפסת, אנא קראו את ההוראות ###כאן###.',
        'printer-name': 'שם למדפסת (לזיהוי קל)',
        'new-printer-unknown': 'הממ... אנחנו לא מוצאים את המדפסת. האם עקבתם אחרי ההוראות בלינק למעלה?',
        'new-printer-unauthorized': 'הממ... אין לנו גישה למדפסת. האם עקבתם אחרי ההוראות בלינק למעלה?',
        'new-printer-error': 'הממ... משהו לא הסתדר טוב. האם עקבתם אחרי ההוראות בלינק למעלה?',
        'new-printer-printer-exists': 'מדפסת עם המזהה הזה כבר קיימת בטבלה למעלה.',
        contacts: 'אנשי קשר',
        'contacts-subtitle': 'מספר טלפון לשופטים ואנשי מנהלה',
        'contact-rm': 'ליצירת קשר עם שופט ראשי, יש לשלוח הודעה ל:',
        'contact-tech': 'ליצירת קשר עם תמיכה טכנית, יש לשלוח הודעה ל:',
        gateway: 'ספק תשלום',
        paypal: 'פייפל לעסקים',
        'z-credit': 'זד-קרדיט',
        'z-credit-terminal': 'מספר טרמינל',
        'z-credit-username': 'שם משתמש',
        'field-equipment': 'ציוד היורה',
        shooternames: 'שמות יורים',
        shooternamessubtitle: 'כיצד להציג את שמות היורים?',
        'anonymize-none': 'שם מלא',
        'anonymize-firstname': 'שם פרטי מקוצר',
        'anonymize-lastname': 'שם משפחה מקוצר',
        advanced: 'מתקדם',
        roRegistration: 'רישום שופטים ושופטות',
        'roRegistration-subtitle': 'הגדירו את ההרשמה של שופטים ושופטות לתחרות שלכם',
    },
};

lang.iw = {
    ...lang.he,
    ...{
        squadselection: 'בחירת מקצים',
        'squadselection-subtitle': 'הגדירו מתי לוחמים יכולי לבחור את המקצים שלהם',
        'squad-selection-closed': 'לוחמים אינם בוחרים את המקצים - החלוקה מתבצעת על-ידינו',
        'squad-selection-during-registration': 'לוחמים יכולים לבחור מקצים בזמן תהליך הרישום',
        'squad-selection-after-payment': 'לוחמים יכולים לבחור מקצים רק לאחר ביצוע תשלום על התחרות',

        'publishing-subtitle': 'הגדירו אילו עמודים באתר זמינים לתצוגה ללוחמים',
        'registration-subtitle': 'הגדירו את תהליך ההרשמה של לוחמים לתחרות שלכם',
        'registration-period-over-can-patch': 'סגורה (מועד ההרשמה עבר) אבל לוחמים יכולים עוד לעדכן נתונים',
        'registration-fields': 'אילו פרטים הלוחמים צריכים להכניס בתהליך ההרשמה?',
        'registration-ics-checkbox': 'רישום לוחמים והצגת תוצאות יכילו קלסיפיקציית ICS',
        'registration-limit-checkbox': 'אפשר הרשמה רק ללוחמים הרשומים במסמך ה-Google Sheets הבא:',
        'registration-limit-description': 'על מסמך ה-Google Sheets להכיל 5 עמודות: מדינת הלוחם\\ת (IL), מספר לוחם\\ת, שם הלוחם\\ת המלא ויחידת הלוחם\\ת. יש להשאיר את השורה הראשונה במסמך ריקה (או לנצלה לכותרות לעמודות). לאחר יצירת הקובץ, יש להשיג "view-only sharable link" באמצעות כפתור ה-Share ב-Google Sheets, ולהדביק את הכתובת המוצגת לכאן.',
        'payment-subtitle': 'איך לוחמים ישלמו על התחרות?',
        'payment-offline': 'לוחמים יפנו אלינו ישירות לתשלום (במזומן או בהעברה בנקאית)',
        'payment-online': 'לוחמים ישלמו באתר באמצעות PayPal או אשראי (ישנה עמלה של PayPal)',
        'notifications-scores-shooter-email': 'שלח ניקוד ללוחמים לאחר סיום תרגיל.',
        'notifications-scores-non-shooter-email': 'שלח ניקוד לבעלי תפקידים לאחר שלוחם\\ת סיים\\ה תרגיל.',
        'field-equipment': 'ציוד הלוחם\\ת',
        shooternames: 'שמות לוחמים',
        shooternamessubtitle: 'כיצד להציג את שמות הלוחמים?',
    },
};

export default lang;
