module.exports = {
    en: {
        welcome: 'Hey {{name}}!',
        home: 'Home',
        newclub: '+ Club',
        logout: 'Logout',
    },
    he: {
        welcome: 'היי {{name}}!',
        newclub: '+ מועדון',
        logout: 'התנתק',
    },
};
