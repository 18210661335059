import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import BackIcon from '@material-ui/icons/Backspace';
import PeopleIcon from '@material-ui/icons/People';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import RemoveIcon from '@material-ui/icons/Remove';
import Paper from '@material-ui/core/Paper';
import NavBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavNextIcon from '@material-ui/icons/NavigateNext';
import BluetoothSearching from '@material-ui/icons/BluetoothSearching';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Snackbar from '../components/Snackbar';
import ScoringBluetooth from './Score.scoring.bluetooth';
import { numShotsPerPaper, summarizeScore, pad } from './Utils';

const SCORE_TO_MESSAGE = {
    A: 'Alpha',
    C: 'Charlie',
    D: 'Delta',
    M: 'Mike',
    m: 'Mike (Bonus)',
    AA: 'Double Alpha',
    AC: 'Alpha Charlie',
    AD: 'Alpha Delta',
    AM: 'Alpha Mike',
    Am: 'Alpha Mike (Bonus)',
    CC: 'Double Charlie',
    CD: 'Charlie Delta',
    CM: 'Charlie Mike',
    Cm: 'Charlie Mike (Bonus)',
    DD: 'Double Delta',
    DM: 'Delta Mike',
    MM: 'Double Mike',
    Dm: 'Delta Mike (Bonus)',
    mm: 'Double Mike (Bonus)',
};

let beeps = null;
let beep2s = null;
let soundNb = 0;

function beep({ major, decrease }) {
    const $beeps = decrease ? beep2s : beeps;
    (async () => {
        if (major) {
            $beeps[soundNb++ % $beeps.length].play();
            setTimeout(() => {
                $beeps[soundNb++ % $beeps.length].play();
            }, 150);
        } else {
            $beeps[soundNb++ % $beeps.length].play();
        }
        if (window.navigator.vibrate) {
            window.navigator.vibrate(200);
        }
    })();
}

class ScoreScoring extends Component {
    constructor(props) {
        super(props);

        beeps = beeps || _(5).range().map(() => new Audio('/beep.wav')).value();
        beep2s = beep2s || _(5).range().map(() => new Audio('/beep2.wav')).value();
        soundNb = 0;

        this.state = {
            scoreMessage: null,
            missesAndProc: null,
            invalidRounds: null,
            zeroScore: null,
            displayBluetooth: false,
            innerTime: props.time ? pad(props.time, 2) : '',
            innerBonus: sessionStorage.getItem('bonusfieldstartup') ?  props.bonusField || 0 : null,
        };
    }

    componentDidMount() {
        const {
            t, stageIdx, shooters, shooterId, onDq, onShowNextShooters,
            setTitle, setExtraButtons, setSaveButtonCallback, setBackButtonVisible,
        } = this.props;

        const shooter = shooters.find((s) => s.id === shooterId);
        const division = t(`generic:division-${shooter.division}`);
        setTitle(`${t('managewrapper:scores')} - ${t('stage')} ${stageIdx} / ${shooter.name} (${division})`);
        setExtraButtons([
            <Button key='shooters' color='primary' size='large' onClick={onShowNextShooters}>
                <PeopleIcon />
            </Button>,
            <Button
                key='dq'
                variant='contained'
                color='secondary'
                size='large'
                style={{ minWidth: '100px', padding: '0px', margin: '0px 12px' }}
                onClick={onDq}
            >
                DQ
            </Button>,
        ]);
        setSaveButtonCallback(null);
        setBackButtonVisible(true);
    }

    onTarget(target, score, stage) {
        const { scorePerTarget, onUpdateScore, scoreMode } = this.props;
        const $score = score === 'B' ? 'C' : score;
        const newScore = ((scorePerTarget[target] || '') + $score).split('').sort().join('');
        const maxNumShots = numShotsPerPaper({ stage });
        if (newScore.length > maxNumShots) return;
        beep({ major: newScore.length === maxNumShots });
        const newTarget = Math.min(newScore.length === maxNumShots ? target + 1 : target, (stage.targets.paper || 0) + (stage.targets.minipaper || 0) - 1);
        this.logAction(`T${target}`, $score);
        this.setState({
            scoreMessage: newScore.length === maxNumShots
                ? (SCORE_TO_MESSAGE[newScore] || '').replace(/Charlie/g, scoreMode === 'comstock-abd' ? 'Beta' : 'Charlie')
                : null,
            invalidRounds: null,
            zeroScore: null,
            missesAndProc: null,
        }, () => {
            const newScorePerTarget = JSON.parse(JSON.stringify(scorePerTarget));
            while (newScorePerTarget.length < target) newScorePerTarget.push('');
            newScorePerTarget[target] = newScore;
            onUpdateScore('scorePerTarget', newScorePerTarget);
            if (newTarget !== target) {
                setTimeout(() => onUpdateScore('target', newTarget), 100);
            }
        });
    }

    onEraseTargetScore(target) {
        const { scorePerTarget, onUpdateScore } = this.props;
        const score = scorePerTarget[target] || '';
        this.logAction(`T${target}`, '<');

        const newScorePerTarget = JSON.parse(JSON.stringify(scorePerTarget));

        if (score.length === 1) {
            beep({ decrease: true });
            newScorePerTarget[target] = '';
            onUpdateScore('scorePerTarget', newScorePerTarget);
        } else if (score.length === 2) {
            beep({ decrease: true });
            newScorePerTarget[target] = `${score[0]}`;
            onUpdateScore('scorePerTarget', newScorePerTarget);
        } else if (score.length === 3) {
            beep({ decrease: true });
            newScorePerTarget[target] = `${score[0]}${score[1]}`;
            onUpdateScore('scorePerTarget', newScorePerTarget);
        }
    }

    logAction(action, value) {
        const { stageIdx, shooterId } = this.props;

        setTimeout(() => {
            try {
                const storage = JSON.parse(window.localStorage.getItem('scoring-action-backup') || '[]');
                if (storage.length > 1000) {
                    storage.splice(0, storage.length - 1000);
                }
                storage.push([
                    stageIdx,
                    shooterId,
                    action,
                    value,
                ]);
                window.localStorage.setItem('scoring-action-backup', JSON.stringify(storage));
            } catch (e) {
                // Left blank intentionally
            }
        }, 0);
    }

    renderPlusMinus(type, stageIdx) {
        const {
            t, i18n, stages, onUpdateScore, poppers, tenPointsPoppers, noShoots, scoreMode,
        } = this.props;
        const { [type]: numOfType = 0 } = this.props;

        const { targets } = stages[stageIdx - 1];

        const numNoShootRounds = targets.noshoots === 'single' ? 2
            : targets.noshoots === 'several' ? 100
                : ((targets['paper-no-shoots'] || 0) * 2) + (targets['metal-no-shoots'] || 0);

        const totalMetals = (() => {
            if (type === 'poppers') return (targets.poppers || 0) + (targets.minipoppers || 0) + (targets.plates || 0) + (targets['pcc-frangible'] || 0);
            if (type === 'tenPointsPoppers') return (targets['pcc-ten-points-frangible'] || 0) + (targets['pcc-ten-points-poppers'] || 0);
            return 0;
        })();

        const disableAdd = (type === 'poppers' && totalMetals === 0)
            || (type === 'poppers' && poppers === totalMetals)
            || (type === 'tenPointsPoppers' && totalMetals === 0)
            || (type === 'tenPointsPoppers' && tenPointsPoppers === totalMetals)
            || (type === 'noShoots' && numNoShootRounds === 0)
            || (type === 'noShoots' && noShoots === numNoShootRounds)
            || (type === 'bonusField' && numOfType === 20);

        const disableRemove = (type === 'poppers' && totalMetals === 0)
            || (type === 'poppers' && poppers === 0)
            || (type === 'tenPointsPoppers' && tenPointsPoppers === 0)
            || (type === 'noShoots' && numNoShootRounds === 0)
            || (type === 'noShoots' && noShoots === 0)
            || (type === 'bonusField' && numOfType === 0);

        const onInc = () => {
            this.setState({ missesAndProc: null, invalidRounds: null, zeroScore: null }, () => {
                beep({});
                if (type === 'bonusField') {
                    this.logAction(type, numOfType + 5);
                    onUpdateScore(type, numOfType + 5);
                } else {
                    this.logAction(type, numOfType + 1);
                    onUpdateScore(type, numOfType + 1);
                }
            });
        };

        const onDec = () => {
            this.setState({ missesAndProc: null, invalidRounds: null, zeroScore: null }, () => {
                beep({ decrease: true });
                if (type === 'bonusField') {
                    this.logAction(type, Math.max(numOfType - 5, 0));
                    onUpdateScore(type, Math.max(numOfType - 5, 0));
                } else {
                    this.logAction(type, Math.max(numOfType - 1, 0));
                    onUpdateScore(type, Math.max(numOfType - 1, 0));
                }
            });
        };

        return (
            <div key={type} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div key={type} style={{ display: 'flex', flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row', alignItems: 'center' }}>
                    <Button
                        disabled={disableAdd}
                        variant='contained'
                        color='primary'
                        style={{
                            minWidth: '40px', padding: '0px', width: '60px', height: '60px',
                        }}
                        onClick={onInc}
                    >
                        <AddIcon />
                    </Button>
                    <div style={{ fontSize: '2em', width: '40px', textAlign: 'center' }}>
                        {numOfType}
                    </div>
                    <Button
                        disabled={disableRemove}
                        variant='contained'
                        color='secondary'
                        onClick={onDec}
                        style={{
                            minWidth: '40px', padding: '0px', width: '60px', height: '60px',
                        }}
                    >
                        <RemoveIcon />
                    </Button>
                </div>

                {type === 'poppers' && (!!targets.poppers || !!targets.minipoppers || !!targets.plates || !!targets['pcc-frangible']) && (
                    <Typography variant='caption'>
                        {scoreMode === 'steel-no-miss'
                            ? t('plates-summary-no-miss', { total: totalMetals })
                            : t('plates-summary', { total: totalMetals, misses: totalMetals - poppers })}
                    </Typography>
                )}
                {type === 'tenPointsPoppers' && (!!targets['pcc-ten-points-poppers'] || !!targets['pcc-ten-points-frangible']) && (
                    <Typography variant='caption'>
                        {scoreMode === 'steel-no-miss'
                            ? t('plates-summary-no-miss', { total: totalMetals })
                            : t('plates-summary', { total: totalMetals, misses: totalMetals - tenPointsPoppers })}
                    </Typography>
                )}
                {type === 'noShoots' && (numNoShootRounds > 0) && (
                    <Typography variant='caption'>
                        {t('no-shoots-summary', { total: numNoShootRounds })}
                    </Typography>
                )}
            </div>
        );
    }

    render() {
        const {
            t, classes, mode, i18n, onDone, isWorking, stageIdx, simplifiedPenalties,
            shooterId, time, stages, scorePerTarget, penaltiesByType, penalties: simplifiedPenaltiesValue,
            specialPenalty, poppers, tenPointsPoppers, target, validLocalStorage, scoreMode, onPenalties,
            onUpdateScore, onLoadLocal, onClearLocal,
        } = this.props;
        const {
            innerTime, innerBonus, timeError, errorMsg, scoreMessage,
            missesAndProc, invalidRounds, zeroScore, displayBluetooth,
        } = this.state;

        const numPaperTargets = (stages[stageIdx - 1].targets.paper || 0) + (stages[stageIdx - 1].targets.minipaper || 0);
        const isCurrentTargetBonus = ((stages[stageIdx - 1].bonusTargets || []).indexOf(target) > -1);
        const stage = stages[stageIdx - 1];
        const maxNumShots = numShotsPerPaper({ stage });
        const paperScoringDone = _(numPaperTargets).range().every((targetIndex) => (scorePerTarget[targetIndex] || '').length === maxNumShots);

        return (
            <div className={classes.wrapper} style={{ minHeight: window.innerHeight - 70 }}>
                <div className={classes.timewrapper}>
                    <h4 className={classes.headermargin} style={{ marginBottom: '12px' }}>{t('time')}</h4>
                    <div style={{ width: '100%', display: 'flex' }}>
                        {mode === 'app' && (
                            <IconButton style={{ visibility: 'hidden' }}>
                                <BluetoothSearching />
                            </IconButton>
                        )}
                        <TextField
                            autoFocus
                            placeholder={t('time')}
                            fullWidth
                            inputProps={{
                                style: {
                                    textAlign: 'center', fontSize: '1.5em', width: '100%', step: '0.01',
                                },
                                type: 'number',
                            }}
                            value={innerTime}
                            error={timeError}
                            onChange={async (e) => {
                                const { value } = e.target;
                                if (timeError || errorMsg) {
                                    await new Promise((res) => this.setState({ timeError: false, errorMsg: null }, res));
                                }
                                this.setState({ innerTime: value });
                            }}
                            onBlur={() => {
                                let timeWithDecimal = innerTime.replace(/-/g, '');

                                if ((timeWithDecimal.length > 2)
                                    && (timeWithDecimal.indexOf('.') === -1)
                                    && (timeWithDecimal.indexOf(',') === -1)) {
                                    const val = parseInt(innerTime, 10);
                                    this.logAction('Time', val);
                                    if (val % 100 === 0) {
                                        timeWithDecimal = `${parseInt(innerTime, 10) / 100}.00`;
                                    } else if (val % 10 === 0) {
                                        timeWithDecimal = `${parseInt(innerTime, 10) / 100}0`;
                                    } else {
                                        timeWithDecimal = `${parseInt(innerTime, 10) / 100}`;
                                    }
                                }

                                this.setState({ innerTime: timeWithDecimal }, () => {
                                    onUpdateScore('time', parseFloat(timeWithDecimal));
                                });
                            }}
                        />
                        {mode === 'app' && (
                            <IconButton onClick={() => this.setState({ displayBluetooth: !displayBluetooth })}>
                                <BluetoothSearching />
                            </IconButton>
                        )}
                    </div>
                </div>

                {mode === 'app' && (
                    <ScoringBluetooth open={displayBluetooth} onUpdateScore={(type, value) => { onUpdateScore(type, value); this.setState({ innerTime: `${value}` }); }} />
                )}

                <div className={classes.separator} />
                <h4 className={classes.headermargin}>{t('generic:poppers')}</h4>

                <div className={classes.popperwrapper}>
                    {this.renderPlusMinus('poppers', stageIdx, shooterId)}
                    <div style={{
                        opacity: '0.3', margin: '0px 36px', position: 'relative', [i18n.dir() === 'ltr' ? 'left' : 'right']: '15px',
                    }}
                    >
                        <div style={{
                            width: '25px', height: '25px', borderRadius: '50%', background: 'white', border: '1px solid black',
                        }}
                        />
                        <div style={{
                            width: '9px', height: '55px', background: 'white', margin: 'auto', border: '1px solid black', borderTop: '1px solid white', position: 'relative', top: '-10px', transform: 'perspective(300px) rotateX(-45deg)',
                        }}
                        />
                    </div>
                </div>

                <div className={classes.separator} />

                {(!!stage.targets['pcc-ten-points-frangible'] || !!stage.targets['pcc-ten-points-poppers']) && (
                    <span style={{ width: '100%' }}>
                        <h4 className={classes.headermargin} style={{ textAlign: 'center' }}>{t('generic:tenpointspoppers')}</h4>
                        <div className={classes.popperwrapper}>
                            {this.renderPlusMinus('tenPointsPoppers', stageIdx, shooterId)}
                            <div style={{
                                opacity: '0.3', margin: '0px 36px', position: 'relative', [i18n.dir() === 'ltr' ? 'left' : 'right']: '15px',
                            }}
                            >
                                <div style={{
                                    width: '25px', height: '25px', borderRadius: '50%', background: 'white', border: '1px solid black',
                                }}
                                />
                                <div style={{
                                    width: '9px', height: '55px', background: 'white', margin: 'auto', border: '1px solid black', borderTop: '1px solid white', position: 'relative', top: '-10px', transform: 'perspective(300px) rotateX(-45deg)',
                                }}
                                />
                            </div>
                        </div>

                        <div className={classes.separator} />
                    </span>
                )}

                <div style={{ textAlign: 'center', direction: 'ltr' }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: i18n.dir() === 'ltr' ? 'row' : 'row-reverse',
                        }}
                    >
                        <div>
                            <Button
                                color='primary'
                                disabled={(numPaperTargets === 0) || (target === 0)}
                                onClick={() => onUpdateScore('target', target - 1)}
                                classes={{ root: classes.squarebutton }}
                            >
                                {i18n.dir() === 'ltr' ? <NavBeforeIcon /> : <NavNextIcon />}
                            </Button>
                        </div>
                        <h4 className={classes.headermargin}>{t('targets')}</h4>
                        <div>
                            <Button
                                color='primary'
                                disabled={(numPaperTargets === 0) || (target === numPaperTargets - 1)}
                                onClick={() => onUpdateScore('target', target + 1)}
                                classes={{ root: classes.squarebutton }}
                            >
                                {i18n.dir() === 'rtl' ? <NavBeforeIcon /> : <NavNextIcon />}
                            </Button>
                        </div>
                    </div>

                    {((stages[stageIdx - 1].bonusTargets || []).length > 0) && (
                        <Paper
                            style={{
                                width: '100%',
                                padding: '12px',
                                lineHeight: '1.5em',
                                background: isCurrentTargetBonus ? red[100] : green[100],
                                transition: 'background 0.2s ease-in-out',
                                direction: i18n.dir(),
                            }}
                        >
                            <div>{t('stagehasbonuses1')}</div>
                            <div>{t('stagehasbonuses2', { targets: stages[stageIdx - 1].bonusTargets.sort((a, b) => a - b).map(($t) => `T${$t + 1}`).join(', ') })}</div>
                            <div>
                                <span>
                                    {t('thisisaregularbonus')}
                                </span>
                                &nbsp;
                                <span
                                    style={{
                                        fontWeight: 'bold',
                                        color: isCurrentTargetBonus ? red[900] : green[900],
                                    }}
                                >
                                    {isCurrentTargetBonus ? t('thisisabonus') : t('thisisaregular')}
                                </span>
                                &nbsp;
                                <span>
                                    .
                                </span>
                            </div>
                        </Paper>
                    )}

                    <div style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: '12px', flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
                    }}
                    >
                        <div style={{ width: '190px' }}>
                            {_.compact(['A', scoreMode === 'comstock-abd' ? 'B' : 'C', scoreMode === 'il-army' ? null : 'D', isCurrentTargetBonus ? 'm' : 'M']).map((score) => (
                                <Button
                                    disabled={numPaperTargets === 0}
                                    key={score}
                                    variant='contained'
                                    color={(score === 'M' || score === 'm') ? 'secondary' : 'primary'}
                                    onClick={() => this.onTarget(target, score, stages[stageIdx - 1], shooterId)}
                                    style={{
                                        minWidth: '40px',
                                        padding: '0px',
                                        width: '80px',
                                        height: '80px',
                                        margin: '6px',
                                        fontStyle: isCurrentTargetBonus ? 'italic' : 'normal',
                                    }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ fontSize: '24px' }}>
                                            {score}
                                        </div>
                                        <Typography variant='caption' style={{ color: 'inherit' }}>
                                            {isCurrentTargetBonus ? t('bonus') : null}
                                        </Typography>
                                    </div>
                                </Button>
                            ))}
                        </div>
                        <div>
                            <div style={{ position: 'relative' }}>
                                <img alt='target' src='/classicTarget.png' style={{ width: '100px', opacity: 0.3 }} />
                                {(numPaperTargets > 0) && (
                                    <div style={{
                                        position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
                                    }}
                                    >
                                        <div style={{ fontSize: '36px' }}>
                                            T
                                            {target + 1}
                                            {isCurrentTargetBonus && (
                                                <Typography variant='caption'>
                                                    {t('bonus')}
                                                </Typography>
                                            )}
                                        </div>
                                        <div style={{ fontSize: '24px' }}>
                                            {
                                                scoreMode === 'comstock-abd' && scorePerTarget[target]
                                                    ? scorePerTarget[target].replace(/C/g, 'B')
                                                    : (scorePerTarget[target] || <span>&nbsp;</span>)
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                            <Button
                                disabled={numPaperTargets === 0}
                                variant='text'
                                color='secondary'
                                onClick={() => this.onEraseTargetScore(target, shooterId)}
                                style={{
                                    minWidth: '40px', padding: '0px', width: '60px', height: '44px', margin: '6px',
                                }}
                            >
                                <BackIcon fontSize='large' />
                            </Button>
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '12px' }}>
                        <div style={{
                            display: 'flex', alignItems: 'center', flexWrap: 'wrap', flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row', width: numPaperTargets < 8 ? numPaperTargets * 30 : parseInt(numPaperTargets / 2 + 0.5, 10) * 30, margin: 'auto',
                        }}
                        >
                            {_.range(numPaperTargets).map((targetIndex) => (
                                <div
                                    key={targetIndex}
                                    style={{
                                        display: 'flex', alignItems: 'center', flexDirection: 'column', width: '30px',
                                    }}
                                >
                                    <Typography variant='caption'>
                                        T
                                        {targetIndex + 1}
                                    </Typography>
                                    <div>
                                        {scoreMode === 'comstock-abd' && scorePerTarget[targetIndex]
                                            ? scorePerTarget[targetIndex].replace(/C/g, 'B')
                                            : (scorePerTarget[targetIndex] || '__')}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div>
                            <DoneIcon
                                fontSize='large'
                                style={{
                                    display: paperScoringDone ? 'block' : 'none',
                                }}
                                className={classes.doneicon}
                            />
                        </div>
                    </div>
                </div>

                {stage.bonusField && (
                    <div className={classes.separator} />
                )}

                {stage.bonusField && (
                    <div>
                        <div
                            style={{ textAlign: 'center' }}
                            onClick={() => {
                                if (_.isNull(innerBonus)) {
                                    const { bonusField } = this.props;
                                    sessionStorage.setItem('bonusfieldstartup', 0);
                                    this.setState({ innerBonus: bonusField });
                                } else {
                                    sessionStorage.removeItem('bonusfieldstartup');
                                    this.setState({ innerBonus: null });
                                }
                            }}
                        >
                            <h4 className={classes.headermargin}>{stage.bonusField}</h4>
                        </div>
                        {_.isNull(innerBonus) && (
                            <div style={{ padding: '12px' }}>
                                {this.renderPlusMinus('bonusField', stageIdx, shooterId)}
                            </div>
                        )}
                        {!_.isNull(innerBonus) && (
                            <TextField
                                autoFocus
                                fullWidth
                                inputProps={{
                                    style: {
                                        textAlign: 'center', fontSize: '1.5em', width: '100%', step: '1',
                                    },
                                    type: 'number',
                                }}
                                value={innerBonus}
                                error={timeError}
                                onChange={async (e) => {
                                    const { value } = e.target;
                                    if (timeError || errorMsg) {
                                        await new Promise((res) => this.setState({ timeError: false, errorMsg: null }, res));
                                    }
                                    this.setState({ innerBonus: value });
                                }}
                                onBlur={() => {
                                    onUpdateScore('bonusField', parseInt(innerBonus, 10));
                                }}
                            />
                        )}
                    </div>
                )}

                <div className={classes.separator} />

                <h4 className={classes.headermargin}>{t('generic:no-shoots')}</h4>

                <div style={{
                    display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%', paddingBottom: '12px', paddingTop: '12px',
                }}
                >
                    {this.renderPlusMinus('noShoots', stageIdx, shooterId)}
                </div>

                <div className={classes.separator} />

                <h4 className={classes.headermargin}>{t('generic:penalties')}</h4>
                {simplifiedPenalties && (
                    <div style={{
                        display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%', paddingBottom: '12px', paddingTop: '12px',
                    }}
                    >
                        {this.renderPlusMinus('penalties', stageIdx, shooterId)}
                    </div>
                )}
                {!simplifiedPenalties && (
                    <div style={{
                        display: 'flex', flexWrap: 'wrap', flexDirection: 'column', alignItems: 'center', width: '100%', paddingBottom: '12px', paddingTop: '12px',
                    }}
                    >
                        <Button style={{ height: '60px', padding: '0px 60px', width: '240px' }} onClick={onPenalties} variant='contained' color='secondary'>
                            {t('generic:penalties')}
                            {' '}
                            (
                            {_.sum(_.values(penaltiesByType))}
                            )
                        </Button>
                        {specialPenalty ? (
                            <Typography variant='body2' style={{ marginTop: '6px' }}>
                                +
                                {t('specialpenalty', { specialPenalty })}
                            </Typography>
                        ) : null}
                    </div>
                )}

                <div className={classes.separator} />

                <Button
                    variant='contained'
                    color='primary'
                    disabled={isWorking}
                    onClick={() => {
                        const {
                            alphas, charlies, deltas, misses, bonusMisses,
                        } = summarizeScore({
                            score: {
                                time,
                                scorePerTarget,
                                poppers,
                                tenPointsPoppers,
                            },
                            stage: stages[stageIdx - 1],
                            mode: scoreMode,
                        });
                        if ((!time) && (alphas + charlies + deltas > 0)) {
                            this.setState({ timeError: true, errorMsg: t('missing-time') });
                            return;
                        }
                        if (!time) {
                            this.setState({ timeError: null, errorMsg: null, zeroScore: true });
                            return;
                        }
                        const totalRounds = alphas + charlies + deltas + misses + bonusMisses;
                        if ((totalRounds !== stages[stageIdx - 1].rounds) && (scoreMode !== 'steel-no-miss')) {
                            this.setState({ invalidRounds: true });
                            return;
                        }

                        const penalties = _.sum(_.values(penaltiesByType)) + (simplifiedPenaltiesValue || 0);
                        if (penalties || misses) {
                            this.setState({ missesAndProc: { misses, penalties } });
                            return;
                        }

                        onDone();
                    }}
                    style={{ width: '100%', marginTop: '12px' }}
                >
                    {isWorking && <CircularProgress size={24} />}
                    {!isWorking && <div>{t('end-score')}</div>}
                </Button>
                {scoreMessage && (
                    <div
                        className={classes.scoremessage}
                        ref={(e) => {
                            if (e) {
                                setTimeout(() => {
                                    if (this.fadeoutTO) {
                                        clearTimeout(this.fadeoutTO);
                                    }
                                    e.classList.add(classes.scoremessagegrow);
                                    this.fadeoutTO = setTimeout(() => {
                                        this.fadeoutTO = null;
                                        this.setState({ scoreMessage: null });
                                    }, 2000);
                                }, 0);
                            }
                        }}
                    >
                        {scoreMessage}
                    </div>
                )}
                {missesAndProc && (
                    <Snackbar
                        open={!!missesAndProc}
                        variant='warning'
                        message={(
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                <div style={{ flex: 1 }}>
                                    {(missesAndProc.misses && missesAndProc.penalties) ? t('are-you-sure-misses-and-procedures', { ...missesAndProc }) : null}
                                    {(!missesAndProc.misses && missesAndProc.penalties) ? t('are-you-sure-procedures', { ...missesAndProc }) : null}
                                    {(missesAndProc.misses && !missesAndProc.penalties) ? t('are-you-sure-misses', { ...missesAndProc }) : null}
                                </div>
                                <Button style={{ color: 'white', margin: '12px' }} onClick={() => this.setState({ missesAndProc: null }, onDone)}>
                                    {t('generic:yes')}
                                </Button>
                                <Button style={{ color: 'white' }} onClick={() => this.setState({ missesAndProc: null })}>
                                    {t('generic:no')}
                                </Button>
                            </div>
)}
                    />
                )}
                {invalidRounds && (
                    <Snackbar
                        open={!!invalidRounds}
                        variant='warning'
                        message={(
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                <div style={{ flex: 1 }}>
                                    {t('invalid-rounds')}
                                </div>
                                <Button style={{ color: 'white', margin: '12px' }} onClick={() => this.setState({ invalidRounds: null }, onDone)}>
                                    {t('generic:yes')}
                                </Button>
                                <Button style={{ color: 'white' }} onClick={() => this.setState({ invalidRounds: null })}>
                                    {t('generic:no')}
                                </Button>
                            </div>
)}
                    />
                )}
                {zeroScore && (
                    <Snackbar
                        open={!!zeroScore}
                        variant='warning'
                        message={(
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                <div style={{ flex: 1 }}>
                                    {t('zero-score')}
                                </div>
                                <Button style={{ color: 'white', margin: '12px' }} onClick={() => this.setState({ zeroScore: null }, onDone)}>
                                    {t('generic:yes')}
                                </Button>
                                <Button style={{ color: 'white' }} onClick={() => this.setState({ zeroScore: null })}>
                                    {t('generic:no')}
                                </Button>
                            </div>
)}
                    />
                )}

                {validLocalStorage && (
                    <Snackbar
                        open
                        variant='warning'
                        message={(
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                <div style={{ flex: 1 }}>
                                    {t('should-load-local')}
                                </div>
                                <Button style={{ color: 'white', margin: '12px' }} onClick={onLoadLocal}>
                                    {t('generic:yes')}
                                </Button>
                                <Button style={{ color: 'white', margin: '12px' }} onClick={onClearLocal}>
                                    {t('generic:no')}
                                </Button>
                            </div>
)}
                    />
                )}

                {errorMsg && (
                    <Snackbar
                        open
                        variant='error'
                        message={errorMsg}
                    />
                )}
            </div>
        );
    }
}

const styles = () => ({
    scoremessage: {
        position: 'fixed',
        top: '50%',
        left: '0px',
        width: '100%',
        height: '70px',
        zIndex: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '54px',
        transform: 'scale(1)',
        filter: 'blur(0px)',
        opacity: 1,
        transition: 'none',
        pointerEvents: 'none',
    },
    scoremessagegrow: {
        transition: 'all 2s ease-in-out',
        transform: 'scale(3)',
        filter: 'blur(50px)',
        opacity: 0,
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '24px',
    },
    timewrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingBottom: '12px',
        textAlign: 'center',
        flex: '1',
    },
    headermargin: {
        margin: '12px 0px 0px',
    },
    separator: {
        display: 'flex',
        width: '100%',
        paddingBottom: '12px',
        borderBottom: '1px solid rgba(0,0,0,0.2)',
    },
    popperwrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '12px 0px',
    },
    squarebutton: {
        minWidth: '40px',
        padding: '0px',
        width: '60px',
        height: '60px',
        margin: '6px',
    },
    doneicon: {
        color: green[500],
    },
});

ScoreScoring.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    i18n: PropTypes.shape({
        dir: PropTypes.func.isRequired,
    }).isRequired,
    onDone: PropTypes.func.isRequired,
    isWorking: PropTypes.bool,
    stageIdx: PropTypes.number.isRequired,
    bonusField: PropTypes.number,
    shooterId: PropTypes.string.isRequired,
    mode: PropTypes.string,
    time: PropTypes.number.isRequired,
    stages: PropTypes.arrayOf(PropTypes.shape({
        targets: PropTypes.shape({
            paper: PropTypes.number,
            minipaper: PropTypes.number,
            poppers: PropTypes.number,
            minipoppers: PropTypes.number,
            'pcc-frangible': PropTypes.number,
            'pcc-ten-points-frangible': PropTypes.number,
            'pcc-ten-points-poppers': PropTypes.number,
            plates: PropTypes.number,
            noshoots: PropTypes.number,
            'paper-no-shoots': PropTypes.number,
            'metal-no-shoots': PropTypes.number,
        }).isRequired,
        bonusTargets: PropTypes.arrayOf(PropTypes.number),
        bonusField: PropTypes.string,
        rounds: PropTypes.number.isRequired,
    })).isRequired,
    shooters: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    scorePerTarget: PropTypes.arrayOf(PropTypes.string).isRequired,
    simplifiedPenalties: PropTypes.bool,
    penaltiesByType: PropTypes.shape({}).isRequired,
    specialPenalty: PropTypes.number.isRequired,
    penalties: PropTypes.number,
    poppers: PropTypes.number.isRequired,
    tenPointsPoppers: PropTypes.number.isRequired,
    target: PropTypes.number.isRequired,
    noShoots: PropTypes.number.isRequired,
    scoreMode: PropTypes.string,
    validLocalStorage: PropTypes.bool.isRequired,
    onPenalties: PropTypes.func.isRequired,
    onLoadLocal: PropTypes.func,
    onClearLocal: PropTypes.func,
    onUpdateScore: PropTypes.func.isRequired,
    onDq: PropTypes.func,
    onShowNextShooters: PropTypes.func,
    setTitle: PropTypes.func,
    setExtraButtons: PropTypes.func,
    setSaveButtonCallback: PropTypes.func,
    setBackButtonVisible: PropTypes.func,
};

ScoreScoring.defaultProps = {
    simplifiedPenalties: false,
    isWorking: false,
    penalties: 0,
    bonusField: 0,
    mode: null,
    scoreMode: null,
    setTitle: () => {},
    setExtraButtons: () => {},
    setSaveButtonCallback: () => {},
    setBackButtonVisible: () => {},
    onDq: () => {},
    onShowNextShooters: () => {},
    onLoadLocal: () => {},
    onClearLocal: () => {},
};

export default withStyles(styles, { withTheme: true })(withTranslation('score')(ScoreScoring));
