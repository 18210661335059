import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

class SettingsContact extends Component {
    shouldComponentUpdate(nextProps) {
        const { locale, contacts } = this.props;
        if (nextProps.locale !== locale) return true;
        if (!_.isEqual(nextProps.contacts, contacts)) return true;
        return false;
    }

    changeRm = (e) => {
        const { onChange, contacts } = this.props;
        onChange({ contacts: { ...contacts, rm: e.target.value } });
    }

    changeTech = (e) => {
        const { onChange, contacts } = this.props;
        onChange({ contacts: { ...contacts, tech: e.target.value } });
    }

    render() {
        const { classes, t, contacts = {} } = this.props;
        return (
            <Card>
                <CardHeader
                    classes={{ title: classes.cardtitle }}
                    title={t('contacts')}
                    subheader={t('contacts-subtitle')}
                />
                <CardContent style={{ paddingTop: '0px' }}>
                    <form className={classes.form}>
                        <Grid container spacing={8}>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant='body2'>{t('contact-rm')}</Typography>
                                <div style={{ direction: 'ltr' }}><TextField value={contacts.rm} onChange={this.changeRm} /></div>
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant='body2'>{t('contact-tech')}</Typography>
                                <div style={{ direction: 'ltr' }}><TextField value={contacts.tech} onChange={this.changeTech} /></div>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        );
    }
}

const styles = () => ({
    cardtitle: {
        fontWeight: 300,
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > div': {
            marginTop: '16px',
        },
        '& > div:first-child': {
            marginTop: '0px',
        },
    },
});

SettingsContact.propTypes = {
    locale: PropTypes.string.isRequired,
    contacts: PropTypes.shape({}),
    onChange: PropTypes.func.isRequired,
};

SettingsContact.defaultProps = {
    contacts: null,
};

export default withStyles(styles, { withTheme: true })(withTranslation('settings')(SettingsContact));
