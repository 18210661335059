/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import deburr from 'lodash/deburr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

class ShootersAutosuggest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            suggestions: [],
            nameError: false,
        };
    }

    componentDidUpdate(prevProps) {
        const { name } = this.props;
        if (!name && prevProps.name) {
            this.setState({ name: null });
        }
        if (name && !prevProps.name) {
            this.setState({ name });
        }
    }

    handleSuggestionsFetchRequested = ({ value }) => {
        const { shooters } = this.props;
        this.setState({
            suggestions: getSuggestions(shooters, value),
        });
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    render() {
        const { onSelect, t } = this.props;
        const { suggestions, name, nameError } = this.state;
        return (
            <Autosuggest
                onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                getSuggestionValue={(suggestion) => {
                    onSelect(suggestion);
                    return `${suggestion.publicId} - ${suggestion.name} (${suggestion.club})`;
                }}
                renderSuggestion={renderSuggestion}
                renderInputComponent={renderInputComponent}
                suggestions={suggestions}
                renderSuggestionsContainer={(options) => (
                    <Paper {...options.containerProps} classes={{ root: 'registration-no-ul' }} square>
                        {options.children}
                    </Paper>
                )}
                inputProps={{
                    placeholder: t('generic:shooter_name'),
                    value: name || '',
                    onChange: (e, { newValue }) => this.setState({ name: newValue, nameError: false }),
                    error: nameError,
                    helperText: nameError ? <span style={{ display: 'flex' }}>{t('error')}</span> : null,
                }}
            />
        );
    }
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const text = `${suggestion.publicId} - ${suggestion.name} (${suggestion.club})`;

    const matches = match(text, query);
    const parts = parse(text, matches);

    return (
        <MenuItem selected={isHighlighted} component='div'>
            <div>
                {parts.map((part, index) => (part.highlight ? (
                    <span key={String(index)} style={{ color: '#000', fontWeight: '500' }}>
                        {part.text}
                    </span>
                ) : (
                    <span key={String(index)} style={{ color: '#666' }}>
                        {part.text}
                    </span>
                )))}
            </div>
        </MenuItem>
    );
}

function getSuggestions(shooters, value) {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0
        ? []
        : shooters.filter((shooter) => {
            const keep = count < 10 && `${shooter.publicId} ${shooter.name}`.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

            if (keep) {
                count += 1;
            }

            return keep;
        });
}

function renderInputComponent(inputProps) {
    const {
        classes, inputRef = () => {}, ref, ...other
    } = inputProps;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputRef: (node) => {
                    ref(node);
                    inputRef(node);
                },
            }}
            {...other}
        />
    );
}

export default withTranslation()(ShootersAutosuggest);

ShootersAutosuggest.propTypes = {
    t: PropTypes.func.isRequired,
    name: PropTypes.string,
    shooters: PropTypes.arrayOf(PropTypes.shape({
        publicId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    onSelect: PropTypes.func.isRequired,
};

ShootersAutosuggest.defaultProps = {
    name: null,
};
/* eslint-enable react/no-did-update-set-state */
