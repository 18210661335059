/* eslint-disable max-classes-per-file */
import './ManageWrapper.css';
import React, { Component } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/Inbox';
import SettingsIcon from '@material-ui/icons/Settings';
import PeopleIcon from '@material-ui/icons/People';
import Refresh from '@material-ui/icons/Refresh';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import GroupWork from '@material-ui/icons/GroupWork';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import TuneIcon from '@material-ui/icons/Tune';
import BarChart from '@material-ui/icons/BarChart';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Premium from '../components/Premium';
import Authentication from '../components/Authentication';
import PushNotification from './PushNotification';
import GenericI18nThemeWrapper from '../components/GenericI18nThemeWrapper';
import Chat from './Chat';
import api from '../Api';
import app from '../AppCommunication';

class ManageWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            match: null,
        };
    }

    async UNSAFE_componentWillMount() {
        const { matchId } = this.props;
        const match = await api.getMatch({ matchId });

        this.setState({ match });

        /*
        window.Tawk_API = window.Tawk_API||{};
        window.Tawk_LoadStart = new Date();
        (function(){
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/5c172ce782491369ba9e601e/default';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s0.parentNode.insertBefore(s1,s0);
        })();
        */
    }

    render() {
        const { match } = this.state;
        const { onUpdateThemeLocale } = this.props;

        return (
            <GenericI18nThemeWrapper match={match} onUpdateThemeLocale={onUpdateThemeLocale}>
                <ManageWrapperInner {...this.props} {...this.state} />
            </GenericI18nThemeWrapper>
        );
    }
}

export default ManageWrapper;

ManageWrapper.propTypes = {
    matchId: PropTypes.string.isRequired,
    onUpdateThemeLocale: PropTypes.func.isRequired,
};

class ManageWrapper_ extends Component {
    constructor() {
        super();
        this.state = {
            auth: null,
            me: null,
            title: null,
            mobileOpen: false,
            saving: false,
            saveSuccess: false,
            showpushnotifications: false,
            backButtonVisible: false,
        };
    }

    async UNSAFE_componentWillMount() {
        const { matchId } = this.props;
        const { auth } = this.state;
        const shooters = await api.getShooters({ matchId, auth });
        this.setState({ shooters });
    }

    async componentDidMount() {
        const { auth } = this.state;
        const { matchId, mode } = this.props;

        const match = await api.getMatch({ matchId, auth });
        await new Promise((res) => this.setState({ match }, res));

        if (mode === 'app') {
            app({ cmd: { src: 'manage-wrapper', type: 'did-mount', params: { auth, matchId } } });
            window.showPushNotifications = () => {
                this.setState({ showpushnotifications: true });
            };
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        const { auth } = this.state;
        const { matchId, mode } = this.props;

        if (prevState.auth !== auth) {
            const items = this.getSidebarItems();
            if (((window.location.href.endsWith('manage')) || (window.location.href.endsWith('manage/'))) && (items[0].id !== 'home')) {
                window.location.href = items[0].link;
                return;
            }

            await new Promise((res) => this.setState({ match: null }, res));
            const match = await api.getMatch({ matchId, auth });
            await new Promise((res) => this.setState({ match }, res));

            if (mode === 'app') {
                app({ cmd: { src: 'manage-wrapper', type: 'did-mount', params: { auth, matchId } } });
                window.showPushNotifications = () => {
                    this.setState({ showpushnotifications: true });
                };
            }
        }
    }

    onDisconnect = async () => {
        await new Promise((res) => window.gapi.load('auth2', res));
        const currentAuth = await window.gapi.auth2.init();
        const currentUser = currentAuth.currentUser.get();
        currentAuth.signOut();
        currentUser.disconnect();

        const approveStorage = JSON.parse(window.localStorage.getItem('approve-backup') || '[]');
        const { auth } = this.state;
        const { matchId } = this.props;
        await api.sendDeviceHistory({ matchId, auth, data: approveStorage });

        sessionStorage.removeItem('auth');
        sessionStorage.removeItem('me');
        setTimeout(() => { window.location.href = `${window.location.href}`; }, 1000);
    }

    setSavingState = async ({ saving, saveSuccess, saveError }) => {
        await new Promise((res) => this.setState({ saving, saveSuccess, saveError }, res));
    }

    getSidebarItems() {
        const { me } = this.state;
        const { t, url } = this.props;
        const baseUrl = `${url.split('/manage')[0]}/manage`;
        const ITEMS = [{
            id: 'home',
            permission: ['manage'],
            link: `${baseUrl}`,
            label: t('home'),
            icon: <InboxIcon />,
        }, {
            id: 'settings',
            permission: ['manage'],
            link: `${baseUrl}/settings`,
            label: t('settings'),
            icon: <SettingsIcon />,
        }, {
            id: 'shooterslist',
            permission: ['registration'],
            link: `${baseUrl}/shooters`,
            label: t('shooterslist'),
            icon: <PeopleIcon />,
        }, {
            id: 'stages',
            permission: ['stages'],
            link: `${baseUrl}/stages`,
            label: t('stages'),
            icon: <DirectionsRunIcon />,
        }, {
            id: 'squads',
            permission: ['squads'],
            link: `${baseUrl}/squads`,
            label: t('squads'),
            icon: <GroupAddIcon />,
        }, {
            id: 'announcements',
            permission: ['manage'],
            link: `${baseUrl}/announcements`,
            label: t('announcements'),
            icon: <RecordVoiceOverIcon />,
        }, {
            id: 'scores',
            permission: ['score'],
            link: `${baseUrl}/scores`,
            label: t('scores'),
            icon: <TrackChangesIcon />,
        }, {
            id: 'chrono',
            permission: ['score'],
            link: `${baseUrl}/chrono`,
            label: t('chrono'),
            icon: <TuneIcon />,
        }, {
            id: 'scoretable',
            link: `${baseUrl}/score-table`,
            label: t('scoretable'),
            icon: <InboxIcon />,
        }, {
            id: 'teams',
            permission: ['manage'],
            link: `${baseUrl}/teams`,
            label: t('teams'),
            icon: <GroupWork />,
        }, {
            id: 'staff',
            permission: ['staff'],
            link: `${baseUrl}/staff`,
            label: t('staff'),
            icon: <LockOpenIcon />,
        }, {
            id: 'stats',
            permission: ['manage'],
            link: `${baseUrl}/stats`,
            label: t('stats'),
            icon: <BarChart />,
        }, {
            id: 'winmss',
            permission: ['manage'],
            link: `${baseUrl}/winmss`,
            label: t('winmss'),
            icon: <ImportExportIcon />,
        }];

        return ITEMS.filter((i) => (_.isEmpty(i.permission) || !_.isEmpty(_.intersection(i.permission, (me || {}).permissions))));
    }

    handleDrawerToggle = () => {
        this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
    };

    renderSidebar() {
        const { me, match, shooters } = this.state;
        const {
            t, i18n, pageId, classes, matchId,
        } = this.props;

        const items = this.getSidebarItems();

        return (
            <div className='manage-wrapper-sidebar' style={{ padding: '24px 0px' }}>
                <div>
                    <Typography variant='h6' style={{ color: 'white', padding: '0px 12px' }}>
                        <div style={{ flex: 1 }}>{match.title}</div>
                    </Typography>
                    <Typography variant='caption' style={{ padding: '0px 12px' }}>
                        <a href={`https://www.endofscoring.com/${match.alias}`} target='_blank' rel='noopener noreferrer'>
                            https://www.endofscoring.com/
                            {match.alias}
                        </a>
                    </Typography>
                    <div style={{ display: 'flex', alignItems: 'center', paddingTop: '24px' }}>
                        <Typography variant='body2' style={{ color: 'white', padding: '0px 12px' }}>
                            {t('welcome', { name: me.name })}
                        </Typography>
                        <Button
                            variant='text'
                            style={{
                                fontWeight: 300, padding: '0px', margin: '0px', minWidth: '0px',
                            }}
                            onClick={this.onDisconnect}
                        >
                            <Typography variant='caption' style={{ padding: '0px', textTransform: 'none', textDecoration: 'underline' }}>
                                {t('logout')}
                            </Typography>
                        </Button>
                    </div>
                </div>
                <List component='nav' className={classes.sidebarlist}>
                    {items.map((item) => (
                        <ListItem
                            button
                            component={React.forwardRef((props, ref) => <Link ref={ref} to={item.link} {...props} />)}
                            key={item.id}
                            className={classnames({ [classes.sidebaritem]: true, [classes.sidebaractive]: item.id === pageId })}
                            onClick={() => this.setState({ mobileOpen: false })}
                        >
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.label} />
                        </ListItem>
                    ))}
                </List>
                <Premium
                    matchId={matchId}
                    package={match.alias.startsWith('demo-') ? 'full' : match.package}
                    numShooters={(shooters || []).length}
                    style={{
                        position: 'relative', [i18n.dir() === 'rtl' ? 'right' : 'left']: '12px', width: 'calc(100% - 48px)', height: '37px',
                    }}
                />
            </div>
        );
    }

    render() {
        const {
            t, i18n, history, theme, component, matchId, componentProps, classes, url, mode, src, pageId,
        } = this.props;
        const {
            auth, match, me, title, saving, saveSuccess, saveError, showpushnotifications,
            saveButtonLabel, extraButtons, mobileOpen, backButtonVisible,
        } = this.state;

        const Comp = component;

        if (!match) {
            return (
                <div style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '100vh',
                }}
                >
                    <CircularProgress size={75} />
                </div>
            );
        }

        if (!auth) {
            return (
                <div>
                    {(mode === 'app') && (
                        <div className={classes.topbar}>
                            <Button color='primary' onClick={() => history.goBack()}><ChevronLeftIcon /></Button>
                            <Typography
                                variant='body2'
                                style={{ textAlign: 'center', flex: 1 }}
                                onClick={() => history.push('/')}
                            >
                                Aria
                            </Typography>
                            <Button color='primary' onClick={() => { history.push('/__refresh__'); setTimeout(() => history.goBack(), 100); }}><Refresh /></Button>
                        </div>
                    )}
                    <Authentication src={src} onAuth={(a, m) => this.setState({ auth: a, me: m })} match={match} matchId={matchId} mode={mode} {...componentProps} />
                </div>
            );
        }

        const localData = JSON.parse(window.localStorage.getItem('__offline_cache') || '[]')
            .filter((s) => s.matchId === match.id && s.timestamp > new Date().getTime() - 2 * 24 * 60 * 60 * 1000);

        const totalData = _.sum(Object.keys(window.localStorage).map((s) => window.localStorage[s].length));

        const topError = (() => {
            if ((pageId === 'storage') || (pageId === 'offline')) return null;
            const parts = [];
            if (match.alias.startsWith('demo-')) {
                parts.push(
                    <Button
                        component={React.forwardRef((props, ref) => <Link ref={ref} to='/new' {...props} />)}
                        key='demo'
                        style={{
                            width: '100%',
                            padding: '12px 6px',
                            background: green[500],
                            color: 'white',
                            textAlign: 'center',
                            textTransform: 'none',
                        }}
                    >
                        <div>
                            <Typography variant='body2' style={{ color: 'inherit' }}>
                                {t('thisisdemosite')}
                            </Typography>
                            <Typography variant='caption' style={{ color: 'inherit' }}>
                                {t('thisisdemositeclick')}
                            </Typography>
                        </div>
                    </Button>,
                );
            }
            if (totalData > 4 * 1024 * 1024) {
                parts.push(
                    <Button
                        key='total'
                        style={{
                            width: '100%',
                            padding: '12px 6px',
                            background: red[500],
                            color: 'white',
                        }}
                        onClick={() => history.push(`/${match.alias}/manage/storage${history.location.search}`)}
                    >
                        <div>
                            <Typography variant='body2' style={{ color: 'inherit' }}>
                                {t('totalstoragedata', { percentage: Math.floor((totalData * 100) / (5 * 1024 * 1024)) })}
                            </Typography>
                            <Typography variant='caption' style={{ color: 'inherit' }}>
                                {t('totalstoragedataclick')}
                            </Typography>
                        </div>
                    </Button>,
                );
            }
            if (localData.length > 0) {
                parts.push(
                    <Button
                        key='offline'
                        style={{
                            width: '100%',
                            padding: '12px 6px',
                            background: red[500],
                            color: 'white',
                        }}
                        onClick={() => history.push(`/${match.alias}/manage/offline${history.location.search}`)}
                    >
                        <div>
                            <Typography variant='body2' style={{ color: 'inherit' }}>
                                {t('haslocaldata', { count: localData.length })}
                            </Typography>
                            <Typography variant='caption' style={{ color: 'inherit' }}>
                                {t('haslocaldataclick')}
                            </Typography>
                        </div>
                    </Button>,
                );
            }
            if (_.isEmpty(parts)) return null;
            return (
                <div>
                    {parts}
                </div>
            );
        })();

        return (
            <div>
                {(mode === 'app') && (
                    <div className={classes.topbar}>
                        <Button color='primary' onClick={() => history.goBack()}><ChevronLeftIcon /></Button>
                        <Typography
                            variant='body2'
                            style={{ textAlign: 'center', flex: 1 }}
                            onClick={() => history.push('/')}
                        >
                            Aria
                        </Typography>
                        <Button color='primary' onClick={() => { history.push('/__refresh__'); setTimeout(() => history.goBack(), 100); }}><Refresh /></Button>
                    </div>
                )}
                {topError && (
                    <div
                        style={{
                            position: 'fixed',
                            top: mode === 'app' ? '45px' : '0px',
                            zIndex: 10,
                            left: '0px',
                            width: '100%',
                        }}
                    >
                        {topError}
                    </div>
                )}
                {topError}
                <Grid container spacing={0} alignItems='stretch' style={{ minHeight: mode === 'app' ? 'calc(100vh - 40px)' : '100vh', marginTop: mode === 'app' ? '40px' : '0px' }}>
                    <Hidden smDown>
                        <Grid item md={3} className='noprint'>
                            {this.renderSidebar()}
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} md={9} style={{ position: 'relative' }}>
                        <div className={classnames([classes.appBar, 'noprint'])}>
                            <Toolbar className={classes.container}>
                                <Hidden mdUp>
                                    <IconButton
                                        color='inherit'
                                        aria-label='Open drawer'
                                        onClick={this.handleDrawerToggle}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Hidden>
                                <Hidden smDown>
                                    {backButtonVisible && (
                                        <IconButton onClick={() => { history.goBack(); }} color='primary'>
                                            {i18n.dir() === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                                        </IconButton>
                                    )}
                                    <div className={classes.title}>
                                        {title}
                                    </div>
                                </Hidden>
                                <Hidden mdUp>
                                    <div className={classes.title}>&nbsp;</div>
                                </Hidden>
                                {this.saveButtonCallback && (
                                <Button disabled={saving} classes={{ root: classnames([classes.save, saveSuccess ? classes.savesuccess : null, saveError ? classes.saveerror : null]) }} variant='contained' color='primary' onClick={() => this.saveButtonCallback({ setState: this.setSavingState })}>
                                    {saving && <CircularProgress size={18} />}
                                    {saveSuccess && <DoneIcon />}
                                    {saveError && <CloseIcon />}
                                    {!saveSuccess && !saveError && !saving && (saveButtonLabel || t('generic:save'))}
                                </Button>
                                )}
                                {extraButtons || null}
                                <Chat match={match} me={me} auth={auth} />
                            </Toolbar>
                            <Hidden mdUp>
                                <Toolbar className={classes.container}>
                                    {backButtonVisible && (
                                        <IconButton onClick={() => { history.goBack(); }} color='primary'>
                                            {i18n.dir() === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                                        </IconButton>
                                    )}
                                    <div className={classes.title}>
                                        {title}
                                    </div>
                                </Toolbar>
                            </Hidden>
                        </div>
                        <Comp
                            auth={auth}
                            me={me}
                            match={match}
                            matchId={matchId}
                            url={url}
                            manage
                            src={src}
                            setTitle={(ti) => { this.setState({ title: ti }); }}
                            setSaveButtonLabel={(label) => { this.setState({ saveButtonLabel: label }); }}
                            setSaveButtonCallback={(callback) => { this.saveButtonCallback = callback; }}
                            setBackButtonVisible={(val) => { this.setState({ backButtonVisible: val }); }}
                            setExtraButtons={(eb) => { this.setState({ extraButtons: eb }); }}
                            {...componentProps}
                        />
                    </Grid>

                    <Hidden mdUp implementation='css'>
                        <Drawer
                            variant='temporary'
                            anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                            open={mobileOpen}
                            onClose={this.handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {this.renderSidebar()}
                        </Drawer>
                    </Hidden>
                </Grid>
                {showpushnotifications && <PushNotification auth={auth} matchId={matchId} onDone={() => this.setState({ showpushnotifications: false })} />}
            </div>
        );
    }
}

const styles = (theme) => ({
    topbar: {
        direction: 'ltr',
        position: 'fixed',
        zIndex: 10,
        top: '0px',
        left: '0px',
        width: '100%',
        height: '45px',
        display: 'flex',
        alignItems: 'center',
        background: 'white',
    },
    sidebarlist: {
        margin: '0px 12px',
    },
    sidebaritem: {
        borderRadius: '3px',
    },
    sidebaractive: {
        backgroundColor: theme.palette.primary.light,
        boxShadow: '0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2)',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            boxShadow: '0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2)',
        },
    },
    appBar: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderBottom: '0',
        marginBottom: '0',
        width: '100%',
        paddingTop: '10px',
        zIndex: '1029',
        color: '#555555',
        border: '0',
        borderRadius: '3px',
        padding: '10px 0',
        transition: 'all 150ms ease 0s',
        minHeight: '50px',
        display: 'block',
    },
    container: {
        minHeight: '50px',
    },
    flex: {
        flex: 1,
    },
    title: {
        flex: 1,
        fontWeight: '300',
        lineHeight: '30px',
        fontSize: '18px',
        borderRadius: '3px',
        textTransform: 'none',
        color: 'inherit',
        margin: '0',
        '&:hover,&:focus': {
            background: 'transparent',
        },
    },
    save: {
        minWidth: '100px',
        transition: 'background 0.5s',
    },
    savesuccess: {
        pointerEvents: 'none',
        background: green[500],
    },
    saveerror: {
        pointerEvents: 'none',
        background: red[500],
    },
});

ManageWrapper_.propTypes = {
    t: PropTypes.func.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    classes: PropTypes.shape({}).isRequired,
    i18n: PropTypes.shape({
        dir: PropTypes.func.isRequired,
    }).isRequired,
    theme: PropTypes.shape({
        direction: PropTypes.oneOf(['rtl', 'ltr']).isRequired,
    }).isRequired,
    component: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.func]).isRequired,
    componentProps: PropTypes.shape({}).isRequired,
    matchId: PropTypes.string.isRequired,
    src: PropTypes.string,
    pageId: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    mode: PropTypes.oneOf(['app', 'web']),
};

ManageWrapper_.defaultProps = {
    mode: 'web',
    src: null,
};

const ManageWrapperInner = withStyles(styles, { flip: false, withTheme: true })(withTranslation('managewrapper')(ManageWrapper_));
/* eslint-enable max-classes-per-file */
