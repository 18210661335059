const lang = {
    en: {
        subject: 'Welcome to {{- title}}',
        'subject-updated': 'Registration change for {{- title}}',
        'subject-updated-paid': 'Payment Received for {{- title}}',
        'subject-updated-paid-squad': 'Pick your squad for {{- title}}',
        'subject-updated-unpaid': 'Payment Cancelled for {{- title}}',
        'subject-cancellationrequest': 'You\'ve requested to cancel your registration for {{- title}}',
        title: 'Hey there, {{- firstname}}',
        message: 'You are now registered to {{- title}}. Here\'s your info:',
        updated: 'Your registration info to {{- title}} has changed. Here\'s your new info:',
        cancellationrequest: 'Your request to cancel the registration to {{- title}} has been sent to the match director for acknowledgment. Please wait for another email confirming your cancellation.',
        deactivated: 'Your registration to {{- title}} has been cancelled.',
        reactivated: 'Your registration to {{- title}} has been restored. Here\'s your info:',
        'paid-squad-selection': 'We\'ve received your payment! You can now pick and change your squad. Here\'s your info:',
        paid: 'We\'ve received your payment! Here\'s your current info:',
        unpaid: 'Your payment for the match was cancelled! Here\'s your current info:',
        footer: 'If you have received this email by mistake, or if you have any questions or comments, feel free to visit us at {{- link}}',
        'warning-no-payment-no-squad': 'Note, your registration is not completed. You still need to finalize your payment, and select your squad.',
        'warning-no-squad': 'Note, your registration is not completed. You still need to select your squad.',
        'warning-no-payment': 'Note, your registration is not completed. You still need to finalize your payment.',
        pin: 'Your secret pin is {{- pin}}. You can use this pin to edit your registration on our website. We recommend you don\'t share it with other people.',
    },
    he: {
        subject: 'ברוכים הבאים ל-{{- title}}',
        'subject-updated': 'עדכון הרשמה ל-{{- title}}',
        'subject-updated-paid': 'תשלום התקבל עבור {{- title}}',
        'subject-updated-paid-squad': 'בחרו את הסקווד שלכם ל-{{- title}}',
        'subject-updated-unpaid': 'תשלום בוטל עבור {{- title}}',
        'subject-cancellationrequest': 'בקשתך לביטול ההרשמה עבור {{- title }}',
        title: 'שלום {{- firstname}}',
        message: 'הרשמתך ל{{- title}} הצליחה. הנה פרטי ההרשמה שלך:',
        updated: 'הרשמתך ל{{- title}} עודכנה. הנה פרטי ההרשמה החדשים:',
        deactivated: 'הרשמתך ל"{{- title}}" בוטלה.',
        reactivated: 'הרשמתך ל"{{- title}}" הוחזרה. הנה פרטי ההרשמה שלך:',
        'paid-squad-selection': 'קיבלנו את התשלום עבור הרשמתך! אנחנו מזימנים אותך לבחור את הסקווד שלך.',
        paid: 'קיבלנו את התשלום עבור הרשמתך! הנה הפרטים המעודכנים של ההרשמה:',
        unpaid: 'התשלום עבור הרשמתך בוטל! הנה הפרטים המעודכנים של ההרשמה:',
        footer: 'אם קיבלת מייל זה בטעות, או אם יש לך שאלות או תיקונים, אנחנו נמצאים ב-{{- link}}',
        'warning-no-payment-no-squad': 'שימו לב, הרשמתכם איננה סופית. עליכם עוד לבצע תשלום ולבחור סקווד באתר.',
        'warning-no-squad': 'שימו לב, הרשמתכם איננה סופית. עליכם עוד לבחור סקווד באתר.',
        'warning-no-payment': 'שימו לב, הרשמתכם איננה סופית. עליכם עוד לבצע תשלום לפי ההוראות באתר.',
        pin: 'הקוד הסודי שלך הוא {{- pin}}. ניתן להשתמש בקוד זה לערוך את ההרשמה. אנו ממליצים לא לחלוק את הקוד עם אחרים.',
        cancellationrequest: 'בקשתך לביטול ההרשמה נשלחה למנהל\\ת התחרות לאישור וביצוע. אנו נשלח אימייל נוסף שמאשר את הביטול.',
    },
};

lang.iw = {
    ...lang.he,
    ...{
        'subject-updated-paid-squad': 'בחרו את המקצה שלכם ל-{{- title}}',
        'paid-squad-selection': 'קיבלנו את התשלום עבור הרשמתך! אנחנו מזימנים אותך לבחור את המקצה שלך.',
        'warning-no-payment-no-squad': 'שימו לב, הרשמתכם איננה סופית. עליכם עוד לבצע תשלום ולבחור מקצה באתר.',
        'warning-no-squad': 'שימו לב, הרשמתכם איננה סופית. עליכם עוד לבחור מקצה באתר.',
    },
};

export default lang;
