import React, { useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../Api';

function OfflineManager(props) {
    const {
        t, setTitle, setSaveButtonCallback, setSaveButtonLabel, setExtraButtons, setBackButtonVisible,
        match, auth,
    } = props;

    const [items, setItems] = useState(null);
    const [shooters, setShooters] = useState(null);
    const [statuses, setStatuses] = useState({});

    useEffect(() => {
        setTitle(t('title'));
        setSaveButtonLabel(t('syncall'));
        setSaveButtonCallback(onSyncAll);
        setExtraButtons(null);
        setBackButtonVisible(true);

        (async () => {
            const $shooters = await api.getShooters({ matchId: match.id, auth });
            setShooters($shooters);

            const $items = JSON.parse(window.localStorage.getItem('__offline_cache') || '[]')
                .filter((item) => item.matchId === match.id && item.timestamp > new Date().getTime() - 2 * 24 * 60 * 60 * 1000);
            setItems($items);
        })();
    }, []);

    useEffect(() => {
        setSaveButtonCallback(onSyncAll);
    }, [items, shooters]);

    async function onSyncAll() {
        let newStatuses = JSON.parse(JSON.stringify(statuses));
        items.forEach((item) => { newStatuses[item.id] = newStatuses[item.id] || 'syncing'; });
        setStatuses(newStatuses);

        let p = Promise.resolve();
        items.forEach((item) => {
            p = p.then(async () => {
                try {
                    if (item.type === 'score') {
                        const score = item.data;
                        const key = await api.postScore({
                            matchId: match.id,
                            auth,
                            finalize: score.finalize,
                            score: _.omit(score, ['id', 'finalize']),
                        });
                        const serverScore = await api.getScore({ matchId: match.id, scoreId: key, auth });
                        if ((serverScore.shooterId !== score.shooterId) || (serverScore.stageIdx !== score.stageIdx)) {
                            throw new Error('unmatching results');
                        }
                        if (score.finalize) {
                            await api.patchScore({
                                matchId: match.id,
                                scoreId: key,
                                auth,
                                patch: { editable: false },
                            });
                        }
                    } else if (item.type === 'attendance') {
                        const req = JSON.parse(JSON.stringify(item.data));
                        req.checks = _.pickBy(req.checks, (v, k) => {
                            const shooter = shooters.find((s) => s.id === k);
                            const equipmentCheck = (shooter.equipmentChecks || [])[req.stageIdx - 1] || {};
                            return ((equipmentCheck.timestamp || 0) < item.timestamp);
                        });
                        await api.postShootersEquipmentCheck({
                            matchId: match.id,
                            auth,
                            stageIdx: req.stageIdx,
                            checks: req.checks,
                        });
                    }

                    const $items = JSON.parse(window.localStorage.getItem('__offline_cache') || '[]')
                        .filter((item2) => item2.timestamp > new Date().getTime() - 2 * 24 * 60 * 60 * 1000)
                        .filter((item2) => item2.id !== item.id);
                    window.localStorage.setItem('__offline_cache', JSON.stringify($items));

                    newStatuses = JSON.parse(JSON.stringify(newStatuses));
                    newStatuses[item.id] = 'done';
                    await setStatuses(newStatuses);
                } catch (e) {
                    console.error(e);
                    newStatuses = JSON.parse(JSON.stringify(newStatuses));
                    newStatuses[item.id] = 'error';
                    await setStatuses(newStatuses);
                }
            });
        });

        await p;
    }

    function getData(item) {
        if (item.type === 'score') {
            return t('datascore', { name: (shooters.find((s) => s.id === item.data.shooterId) || {}).name, stageIdx: item.data.stageIdx });
        }
        if (item.type === 'attendance') {
            return t('dataattendance', {
                names: _.map(item.data.checks, (v, k) => (shooters.find((s) => s.id === k) || {}).name).join(', '),
                stageIdx: item.data.stageIdx,
            });
        }
        return '';
    }

    if (!shooters || !items) {
        return (
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <CircularProgress size={75} />
            </div>
        );
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {t('time')}
                        </TableCell>
                        <TableCell>
                            {t('data')}
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>
                                {moment(item.timestamp).locale(match.locale).format('LL LT')}
                            </TableCell>
                            <TableCell>
                                {getData(item)}
                            </TableCell>
                            <TableCell>
                                {statuses[item.id] === 'syncing' && <CircularProgress size={18} />}
                                {statuses[item.id] === 'done' && (
                                    <span style={{ fontSize: '18px', color: green[500] }}>
                                        <i className='fas fa-check-circle' />
                                    </span>
                                )}
                                {statuses[item.id] === 'error' && (
                                    <span style={{ fontSize: '18px', color: red[500] }}>
                                        <i className='fas fa-times' />
                                    </span>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}

OfflineManager.propTypes = {
    setTitle: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func.isRequired,
    setSaveButtonLabel: PropTypes.func.isRequired,
    setBackButtonVisible: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    auth: PropTypes.string.isRequired,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
        locale: PropTypes.string.isRequired,
    }).isRequired,
};

const styles = () => ({});

export default withStyles(styles, { withTheme: true })(withTranslation('offlinemanager')(OfflineManager));
