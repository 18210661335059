import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withTranslation } from 'react-i18next';
import { CountryDropdown, CountryRegionData } from 'react-country-region-selector';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import Refresh from '@material-ui/icons/Refresh';
import Add from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Wrapper from './HomeI18nWrapper';

function HomeAppDiscover(props) {
    const {
        t, i18n, classes, history, matches, stages, onRefresh,
    } = props;

    const [filter, setFilter] = useState(window.localStorage.getItem('__homeapp_discover_level_filter') || 1);
    const [countryPopup, setCountryPopup] = useState(false);
    const [region, setRegion] = useState(window.localStorage.getItem('__homeapp_discover_geo_filter' || ''));
    const [tempRegion, setTempRegion] = useState(window.localStorage.getItem('__homeapp_discover_geo_filter' || ''));

    useEffect(() => {
        window.localStorage.setItem('__homeapp_discover_level_filter', filter);
    }, [filter]);

    useEffect(() => {
        window.localStorage.setItem('__homeapp_discover_geo_filter', region);
    }, [region]);

    const shortCountryCode = useMemo(() => {
        if (!region) return null;
        return (CountryRegionData.find((c) => c[0] === region) || [])[1];
    }, [region]);


    if (!matches || !stages) {
        return (
            <div className={classes.loadingwrapper}>
                <CircularProgress thickness={1} size={72} />
            </div>
        );
    }

    return (
        <div>
            <div className={classes.topbar} style={{ visibility: 'hidden' }}>
                <div>
                    <Button variant='text' color='primary' disabled>
                        <Typography variant='body2' style={{ color: blue[500] }}>
                            {t('discover')}
                        </Typography>
                    </Button>
                </div>
                <div style={{}}>
                    {t(`filter-${filter}`)}
                </div>
                <div style={{ flex: 1 }} />
                <IconButton color='primary' onClick={() => history.push('/new')}><Add /></IconButton>
                <IconButton color='primary' onClick={onRefresh}><Refresh /></IconButton>
            </div>
            <div
                className={classes.topbar}
                style={{
                    position: 'fixed',
                    top: '0px',
                    left: '0px',
                    zIndex: 20,
                }}
            >
                <div>
                    <Button variant='text' color='primary' disabled>
                        <Typography variant='body2' style={{ color: blue[500] }}>
                            {t('discover')}
                        </Typography>
                    </Button>
                </div>
                <div style={{ marginLeft: '-6px', display: 'flex', alignItems: 'center' }}>
                    <Button variant='text' color='primary' onClick={() => setFilter((filter + 1) % 2)}>
                        <Typography variant='body2' style={{ color: blue[500], textTransform: 'none' }}>
                            {t(`homeapp:discover-filter-${filter}`)}
                        </Typography>
                    </Button>
                    <Typography variant='body2' style={{ color: 'black' }}>
                        from
                    </Typography>
                    <Button variant='text' color='primary' onClick={() => setCountryPopup(true)}>
                        <Typography variant='body2' style={{ color: blue[500], textTransform: 'none' }}>
                            {region || t('homeapp:discover-geofilter-all')}
                        </Typography>
                    </Button>
                </div>
                <div style={{ flex: 1 }} />
                <IconButton color='primary' onClick={() => history.push('/new')}><Add /></IconButton>
                <IconButton color='primary' onClick={onRefresh}><Refresh /></IconButton>
            </div>
            {matches
                .filter((m) => m.ipscLevel >= filter)
                .filter((m) => (!shortCountryCode) || (m.countryCode === shortCountryCode))
                .map((m) => {
                    const date = moment([m.startDate.year, m.startDate.month - 1, m.startDate.day, 12, 0, 0, 0]).format('LL');
                    const rounds = _.reduce(m.stages, (sum, s) => sum + ((stages.find(($s) => $s.id.toString() === s.toString()) || {}).rounds || 0), 0);
                    const fixedT = i18n.getFixedT(m.locale, 'homeappdiscover');
                    return (
                        <ButtonBase key={m.title} classes={{ root: classes.matchitem }} onClick={() => history.push(`/${m.alias}${window.location.search}`)}>
                            <div className={classes.matchitemcover} style={{ backgroundImage: `url("${m.cover}")` }}>
                                <div className={m.ipscLevel === 0 ? classes.labelunapproved : classes.label}>
                                    {fixedT(`homeapp:ipsclevel-${m.ipscLevel}`)}
                                    {m.ipscLevel > 0 && m.locale === 'he-IL' ? (
                                        <Typography style={{ color: 'white' }} variant='caption'>
                                            {fixedT(`homeapp:ipsclevel-${m.ipscLevel}-desc`)}
                                        </Typography>
                                    ) : null}
                                </div>
                                <div className={classes.matchitemcovertitledescription}>
                                    <Typography variant='h5' style={{ color: 'inherit' }}>
                                        {m.title}
                                    </Typography>
                                    <Typography variant='subtitle2' style={{ color: 'inherit' }}>
                                        {m.description}
                                    </Typography>
                                </div>
                            </div>
                            <div className={classes.matchitemcoverinfo}>
                                <Typography variant='body2' style={{ flex: '1', textAlign: 'center' }}>
                                    {`${(m.stages || []).length} ${t('matchhome:shooters-stages-title')}`}
                                </Typography>
                                <div style={{ height: '100%', borderRight: '1px solid rgba(0, 0, 0, 0.2)' }} />
                                <Typography variant='body2' style={{ flex: '1', textAlign: 'center' }}>
                                    {`${rounds} ${t('matchhome:rounds')}`}
                                </Typography>
                                <div style={{ height: '100%', borderRight: '1px solid rgba(0, 0, 0, 0.2)' }} />
                                <Typography variant='body2' style={{ flex: '1', textAlign: 'center' }}>
                                    {date}
                                </Typography>
                            </div>
                        </ButtonBase>
                    );
                })}
            <Dialog onClose={() => setCountryPopup(false)} open={countryPopup}>
                <DialogTitle>Pick Region</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        Pick the region you only want to see matches from:
                        <CountryDropdown defaultOptionLabel='All regions' value={tempRegion} onChange={(val) => setTempRegion(val)} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCountryPopup(false)} color='primary'>
                        Cancel
                    </Button>
                    <Button onClick={() => { setRegion(tempRegion); setCountryPopup(false); }} color='primary' autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

HomeAppDiscover.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    classes: PropTypes.shape({}).isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape.isRequired,
    onRefresh: PropTypes.func.isRequired,
    matches: PropTypes.arrayOf(PropTypes.shape({})),
    stages: PropTypes.arrayOf(PropTypes.shape({})),
};

HomeAppDiscover.defaultProps = {
    matches: null,
    stages: null,
};

const styles = () => ({
    loadingwrapper: {
        width: '100vw',
        height: 'calc(100vh - 56px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    matchitem: {
        display: 'block',
        width: '100%',
        height: '200px',
        background: 'white',
        marginBottom: '12px',
    },
    matchitemcover: {
        position: 'relative',
        width: '100%',
        height: '168px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: 'hidden',
        '&:before': {
            content: '""',
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
            zIndex: '1',
        },
    },
    matchitemcovertitledescription: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: '50%',
        height: 'calc(100% - 48px)',
        padding: '24px',
        zIndex: '2',
        color: 'white',
    },
    matchitemcoverinfo: {
        color: 'inherit',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '3px 0px',
        height: '26px',
        width: '100%',
    },
    topbar: {
        width: '100%',
        height: '45px',
        background: 'rgba(255, 255, 255, 0.95)',
        display: 'flex',
        alignItems: 'center',
    },
    label: {
        position: 'absolute',
        right: '0px',
        top: '0px',
        fontSize: '13px',
        backgroundColor: green[900],
        color: 'white',
        zIndex: 2,
        padding: '6px 0px',
        width: '150px',
        textAlign: 'center',
    },
    labelunapproved: {
        position: 'absolute',
        right: '0px',
        top: '0px',
        fontSize: '13px',
        backgroundColor: red[900],
        color: 'white',
        zIndex: 2,
        padding: '6px 0px',
        width: '150px',
        textAlign: 'center',
    },
});

export default Wrapper(withStyles(styles, { withTheme: true, flip: false })(withTranslation('homeappdiscover')(HomeAppDiscover)));
