module.exports = {
    en: {
        'ro-title': '"Stage 5 needs you..."',
        'ro-description1': 'Range master? Match director?',
        'ro-description2': 'Do you want range officers to contact you here using push notifications?',
        'ro-yes': 'Yes, make R.O.s life easier',
        'ro-no': 'No, R.O.s will call me on the phone',
        'app-scoring-title': '"You\'ve got a new score!"',
        'app-scoring-description1': 'Get notified when your scores are online',
        'app-scoring-description2': '',
        'app-scoring-yes': 'Sounds great!',
        'app-scoring-no': 'No, I\'ll get my scores the old fashion way - probably using fax?',
    },
    he: {
    },
};
