const lang = {
    en: {
        title: '{{- name}} - Staff',
        password: 'Password for this user',
        permissions: 'What can this user do?',
        'default-name': 'Staff member',
        'name-placeholder': 'Staff member name',
        ro: 'Range Officer',
        cro: 'Chief Range Officer',
        so: 'Stats Officer',
        qm: 'Quartermaster',
        rm: 'Range Master',
        md: 'Match Director',
        squads: 'Edit squads',
        registration: 'Register shooters',
        'registration-verify': 'Verify registration (eg. equipment)',
        'registration-change-verified': 'Update verified shooters',
        stages: 'Edit stages',
        'stages-force': 'Edit scored stages',
        score: 'Enter scores',
        'approve-rescoring': 'Modify scores',
        manage: 'Manage website',
        staff: 'Manage staff',
        chrono: 'Add chronograph results',
        payments: 'Update shooter payment status',
        'error-no-staff-permissions': 'At least one staff member needs to have the "Manage staff" permission.',
        'error-no-staff-name': 'Did you forget to name one of the staff members?',
        'error-no-staff-password': 'Did you forget to give a password to one of the staff members?',
        'error-duplicate-staff-password': 'You\'ve used the same password twice.',
        'md-recommendation': 'It is recommended MDs have all permissions',
        'rm-recommendation': 'It is recommended RMs have all permissions',
        'ro-recommendation': 'It is recommended ROs have only "$t(score)" permission',
        'cro-recommendation': 'It is recommended CROs have only "$t(score)" permission',
        'qm-recommendation': 'It is recommended QMs have only "$t(manage)" permission',
        'so-recommendation': 'It is recommended SOs have "$t(score)" and "$t(registration)" permissions',
    },
    he: {
        title: '{{- name}} - בעלי תפקידים',
        password: 'סיסמת כניסה (יחודית לבעל התפקיד)',
        permissions: 'אילו הרשאות תרצו שיהיו ל{{name}}?',
        'default-name': 'בעל התפקיד',
        'name-placeholder': 'שם בעל התפקיד',
        squads: 'רישום לסקוודים',
        registration: 'רישום יורים',
        'registration-verify': 'אימות מידע בהרשמה (לדוגמא, ציוד)',
        'registration-change-verified': 'שינוי יורים מאומתים',
        stages: 'עריכת תרגילים',
        'stages-force': 'עריכת תרגילים שנורו',
        score: 'הכנסת תוצאות',
        'approve-rescoring': 'עריכת תוצאות',
        chrono: 'הוספת תוצאות כרונוגרף',
        manage: 'ניהול התחרות',
        staff: 'עריכת בעלי תפקידים',
        payments: 'עריכת תשלום יורה',
        ro: 'שופט',
        cro: 'שופט אזורי',
        so: 'שופט רישום',
        qm: 'תחזוקן',
        rm: 'שופט ראשי',
        md: 'מנהל תחרות',
        'error-no-staff-permissions': 'לפחות בעל תפקיד אחד צריך שיהיה בעל הרשאות של "עריכת בעלי תפקידים".',
        'error-no-staff-name': 'שכחתם שם לאחד מבעלי התפקידים?',
        'error-no-staff-password': 'שכחתם סיסמא לאחד מבעלי התפקידים?',
        'error-duplicate-staff-password': 'לפחות שני בעלי תפקידים חולקים את אותה סיסמא.',
        'md-recommendation': 'מומלץ לתת ל-MD את כל ההרשאות.',
        'rm-recommendation': 'מומלץ לתת ל-RM את כל ההרשאות.',
        'ro-recommendation': 'מומלץ לתת ל-RO הרשאת ״$t(score)״ בלבד',
        'cro-recommendation': 'מומלץ לתת ל-CRO הרשאת ״$t(score)״ בלבד',
        'qm-recommendation': 'מומלץ לתת ל-QM הרשאת ״$t(manage)״ בלבד',
        'so-recommendation': 'מומלץ לתת ל-SO הרשאות ״$t(score)״ ו-״$t(registration)״ בלבד',
    },
};

lang.iw = {
    ...lang.he,
    ...{
        squads: 'רישום למקצים',
        registration: 'רישום לוחמים',
        'registration-change-verified': 'שינוי לוחמים מאומתים',
        payments: 'עריכת תשלום לוחם\\ת',
    },
};

export default lang;
