/* eslint-disable max-classes-per-file */
import React, { Component, useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withTranslation } from 'react-i18next';
import { Link, Route } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import { getFlagSrc } from './Utils';
import api from '../Api';

class Chrono extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStation: 0,
            stations: [],
            dailys: [],
        };
    }

    async componentDidMount() {
        const { match, auth } = this.props;

        try {
            const [chronos, shooters] = await Promise.all([
                (async () => {
                    try {
                        return await api.getChronos({ matchId: match.id, auth });
                    } catch (e) {
                        return [];
                    }
                })(),
                api.getShooters({ matchId: match.id, auth }),
            ]);

            this.setState({
                stations: _.isEmpty(chronos.stations) ? [{}] : chronos.stations,
                shooters,
            });
        } catch (e) {
            this.setState({
                stations: [{}],
                shooters: [],
            });
        }
    }

    renderMain() {
        const {
            stations, shooters, dailys, currentStation,
        } = this.state;
        return (
            <ChronoMain
                {...this.props}
                currentStation={currentStation}
                stations={stations}
                shooters={shooters}
                dailys={dailys}
                setStations={($stations) => new Promise((res) => this.setState({ stations: $stations }, res))}
                setCurrentStation={($currentStation) => new Promise((res) => this.setState({ currentStation: $currentStation }, res))}
            />
        );
    }

    renderNewDaily(stationIdx) {
        return (
            <ChronoDailyTest
                {...this.props}
                stationIdx={stationIdx}
                updateChrono={(chrono) => {
                    this.setState({
                        stations: chrono.stations || [{}],
                    });
                }}
            />
        );
    }

    renderNewRequest(shooterId) {
        const { shooters, stations } = this.state;
        return (
            <ChronoNewRequest
                {...this.props}
                stations={stations}
                shooters={shooters}
                shooterId={shooterId}
                updateChrono={(chrono) => {
                    this.setState({
                        stations: chrono.stations || [{}],
                    });
                }}
            />
        );
    }

    renderForm(stationIdx, timestamp) {
        const { shooters, stations } = this.state;
        const request = stations[stationIdx].requests.find((r) => r.timestamp === timestamp);
        return (
            <ChronoForm
                {...this.props}
                shooters={shooters}
                request={request}
                updateChrono={(chrono) => {
                    this.setState({
                        stations: chrono.stations || [{}],
                    });
                }}
            />
        );
    }

    render() {
        const {
            stations, shooters,
        } = this.state;

        if (stations.length === 0 || !shooters) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <CircularProgress size={75} />
                </div>
            );
        }

        return (
            <div>
                <Route path='/:matchId/manage/chrono' exact render={() => this.renderMain()} />
                <Route
                    path='/:matchId/manage/chrono/station/:stationIdx/newdaily'
                    exact
                    render={({ match }) => {
                        const stationIdx = parseInt(match.params.stationIdx, 10);
                        return this.renderNewDaily(stationIdx);
                    }}
                />
                <Route
                    path='/:matchId/manage/chrono/newrequest/:shooterId'
                    exact
                    render={({ match }) => this.renderNewRequest(match.params.shooterId)}
                />
                <Route
                    path='/:matchId/manage/chrono/station/:stationIdx/request/:timestamp'
                    exact
                    render={({ match }) => this.renderForm(parseInt(match.params.stationIdx, 10), parseInt(match.params.timestamp, 10))}
                />
            </div>
        );
    }
}

Chrono.propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired,
    }).isRequired,
    classes: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    auth: PropTypes.string.isRequired,
};

const styles = (theme) => ({
    wrapper: {
        '& > div': {
            width: '80%',
            marginLeft: '10%',
        },
        '& > p': {
            width: '80%',
            marginLeft: '10%',
        },
    },
    dailyconfiguration: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    dailytable: {
        marginBottom: '12px',
        '& > thead > tr > th': {
            textAlign: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            '& > thead > tr > th': {
                padding: '6px !important',
            },
            '& > tbody > tr > td': {
                padding: '6px !important',
                textAlign: 'center',
            },
            '& > tbody > tr > td > div': {
                textAlign: 'center',
                margin: 'auto',
                display: 'block !important',
            },
        },
    },
});

export default withStyles(styles)(withTranslation('chrono')(Chrono));

class ChronoMain extends Component {
    constructor(props) {
        super(props);

        const { gun = {} } = props.stations[0] || {};

        this.state = {
            testGunOpen: false,
            testGunSaving: false,
            testGunSaveError: null,
            testmakemodel: gun.make,
            testserial: gun.serial,
            testweight: gun.weight,
            errors: {},
        };
    }

    async componentDidMount() {
        const {
            t, setTitle, setSaveButtonCallback, setExtraButtons,
        } = this.props;
        setTitle(t('title'));
        setSaveButtonCallback(null);
        setExtraButtons(null);
        setTimeout(() => window.scrollTo(0, 0), 100);
    }

    async setStateAsync(value) {
        return new Promise((res) => this.setState(value, res));
    }

    onChangeStation = async (station) => {
        const { stations, setCurrentStation } = this.props;
        const { gun = {} } = stations[station] || {};
        await this.setStateAsync({
            testmakemodel: gun.make,
            testserial: gun.serial,
            testweight: gun.weight,
        });
        await setCurrentStation(station);

        setTimeout(() => window.scrollTo(0, 0), 100);
    }

    onAddStation = async () => {
        const { stations, setStations, setCurrentStation } = this.props;
        const station = stations.length;
        stations.push({});
        const { gun = {} } = stations[station] || {};
        await setStations(stations);
        await this.setStateAsync({
            testmakemodel: gun.make,
            testserial: gun.serial,
            testweight: gun.weight,
        });
        await setCurrentStation(station);

        setTimeout(() => window.scrollTo(0, 0), 100);
    }

    onSaveTestGun = async () => {
        const { t, match, auth } = this.props;
        const { testmakemodel, testserial, testweight } = this.state;

        const errors = {};
        if (!testmakemodel) errors.testmakemodel = true;
        if (!testserial) errors.testserial = true;
        if (!testweight) errors.testweight = true;
        await this.setStateAsync({ errors });
        if (Object.keys(errors).length > 0) return;

        const testGun = {
            make: testmakemodel,
            serial: testserial,
            weight: parseFloat(testweight),
        };

        await this.setStateAsync({ testGunSaving: true, testGunSaveError: null });
        try {
            const { currentStation } = this.props;
            await api.postChronoTestGun({
                matchId: match.id,
                station: currentStation,
                auth,
                gun: testGun,
            });
            const { stations, setStations } = this.props;
            stations[currentStation].gun = testGun;
            await setStations(stations);
            await this.setStateAsync({ testGunSaving: false, testGunOpen: false });
        } catch (e) {
            await this.setStateAsync({ testGunSaving: false, testGunSaveError: t('generic:saveerror') });
        }
    }

    renderTextField(field, disabled) {
        const { t } = this.props;
        const { errors, [field]: value } = this.state;
        return (
            <TextField
                required
                disabled={disabled}
                fullWidth
                InputLabelProps={{ shrink: true }}
                error={errors[field]}
                value={value}
                onChange={(e) => this.setState({ [field]: e.target.value, errors: { ...errors, [field]: false } })}
                label={t(field)}
            />
        );
    }

    renderTestGunArea() {
        const { t, stations, currentStation } = this.props;
        const {
            testGunOpen, testGunSaving, testGunSaveError,
        } = this.state;
        const { gun: testGun, dailys = [] } = stations[currentStation];

        if (testGun || testGunOpen) {
            return (
                <div style={{ marginBottom: '12px' }}>
                    <Typography variant='h6' style={{ marginBottom: '12px', display: 'flex', alignItems: 'center' }}>
                        <span style={testGunOpen ? { flex: 1 } : {}}>
                            {t('testgun')}
                        </span>
                        {testGun && !testGunOpen && (dailys.length === 0) && (
                            <IconButton aria-label='Edit' color='primary' onClick={() => this.setState({ testGunOpen: true })}>
                                <EditIcon style={{ fontSize: '18px' }} />
                            </IconButton>
                        )}
                        {testGunOpen && (
                            <IconButton aria-label='Edit' color='secondary' onClick={() => this.setState({ testGunOpen: false })}>
                                <CloseIcon style={{ fontSize: '18px' }} />
                            </IconButton>
                        )}
                    </Typography>
                    <Grid container style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
                        <Grid item xs={12} md={3}>
                            {this.renderTextField('testmakemodel', !testGunOpen)}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            {this.renderTextField('testserial', !testGunOpen)}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            {this.renderTextField('testweight', !testGunOpen)}
                        </Grid>
                    </Grid>
                    {testGunOpen && (
                        <div>
                            <Typography variant='caption'>
                                {t('testwarnings')}
                            </Typography>
                            <Button fullWidth disabled={testGunSaving} color='primary' variant='contained' onClick={this.onSaveTestGun}>
                                {testGunSaving && <CircularProgress size={24} />}
                                {!testGunSaving && t('testsave')}
                            </Button>
                            <Typography variant='caption' style={{ color: red[500] }}>
                                {testGunSaveError || <span>&nbsp;</span>}
                            </Typography>
                        </div>
                    )}
                </div>
            );
        }

        return (
            <div style={{ marginBottom: '12px' }}>
                <Typography variant='h6' style={{ marginBottom: '12px' }}>
                    {t('testgun')}
                </Typography>
                {!testGun && (
                    <Button color='primary' onClick={() => this.setState({ testGunOpen: true })}>
                        +
                        &nbsp;
                        {t('testgunadd')}
                    </Button>
                )}
            </div>
        );
    }

    render() {
        const {
            t, classes, location, stations, match, currentStation, shooters = [],
        } = this.props;

        const {
            gun: testGun, dailys = [], requests = [],
        } = stations[currentStation];

        const DailyAddLink = (props) => <Link to={`${location.pathname}/station/${currentStation}/newdaily${location.search}`} {...props} />;

        return (
            <div className={classes.wrapper}>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <Tabs
                            variant='fullWidth'
                            value={currentStation}
                            classes={{ indicator: classes.indicator }}
                            onChange={(e, v) => this.onChangeStation(parseInt(v, 10))}
                        >
                            {_.range(stations.length).map((stationIndex) => (
                                <Tab style={{ minWidth: '0px' }} key={stationIndex} label={<div style={{ textAlign: 'center' }}>{stationIndex + 1}</div>} />
                            ))}
                        </Tabs>
                    </div>
                    <Button variant='contained' color='primary' style={{ width: 'auto' }} onClick={this.onAddStation}>
                        +
                        &nbsp;
                        {t('addstation')}
                    </Button>
                </div>
                <Card style={{ marginTop: '12px' }}>
                    <CardHeader title={t('daily', { station: currentStation + 1 })} />
                    <CardContent style={{ marginTop: '0px', paddingTop: '0px' }}>
                        {this.renderTestGunArea()}
                        <Typography variant='h6' style={{ marginBottom: '12px' }}>
                            {t('dailyresults')}
                        </Typography>
                        <Table component={(props) => <table border={1} {...props} />} classes={{ root: classes.dailytable }} padding='dense'>
                            <TableHead>
                                <TableRow>
                                    <TableCell rowSpan={2}>{t('dailydate')}</TableCell>
                                    <Hidden xsDown>
                                        <TableCell colSpan={3}>{t('dailyshotvelocity')}</TableCell>
                                    </Hidden>
                                    <TableCell rowSpan={2}>{t('dailyavgvelocity')}</TableCell>
                                    <TableCell rowSpan={2}>{t('dailyavgpower')}</TableCell>
                                    <TableCell rowSpan={2}>{t('dailychange')}</TableCell>
                                    <TableCell colSpan={2}>{t('initials')}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <Hidden xsDown>
                                        <TableCell>{1}</TableCell>
                                        <TableCell>{2}</TableCell>
                                        <TableCell>{3}</TableCell>
                                    </Hidden>
                                    <TableCell>CRO</TableCell>
                                    <TableCell>RM</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dailys.map((daily, index) => {
                                    const avgVelocity = parseInt(((daily.velocity1 + daily.velocity2 + daily.velocity3) / 3) * 100, 10) / 100;
                                    const avgPower = Math.floor((testGun.weight * avgVelocity) / 1000);

                                    const avgBaseVelocity = parseInt(((dailys[0].velocity1 + dailys[0].velocity2 + dailys[0].velocity3) / 3) * 100, 10) / 100;
                                    const avgBasePower = Math.floor((testGun.weight * avgBaseVelocity) / 1000);

                                    const change = index === 0
                                        ? 'N/A'
                                        : `${Math.floor((Math.abs(avgBasePower - avgPower) / avgBasePower) * 10000, 10) / 100}%`;

                                    const error = index === 0
                                        ? false
                                        : Math.floor((Math.abs(avgBasePower - avgPower) / avgBasePower) * 10000, 10) / 100 > 5;

                                    return (
                                        <TableRow key={daily.date} style={error ? { backgroundColor: red[100] } : {}}>
                                            <TableCell>
                                                {moment(daily.date).locale(match.locale).format('L LT')}
                                            </TableCell>
                                            <Hidden xsDown>
                                                <TableCell>
                                                    {daily.velocity1}
                                                </TableCell>
                                                <TableCell>
                                                    {daily.velocity2}
                                                </TableCell>
                                                <TableCell>
                                                    {daily.velocity3}
                                                </TableCell>
                                            </Hidden>
                                            <TableCell>
                                                {avgVelocity}
                                            </TableCell>
                                            <TableCell>
                                                {avgPower}
                                            </TableCell>
                                            <TableCell>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {error && <ErrorIcon style={{ color: red[500] }} />}
                                                    {error && <span>&nbsp;</span>}
                                                    {change}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                {daily.cro}
                                            </TableCell>
                                            <TableCell>
                                                {daily.rm}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {testGun && (
                            <Button color='primary' component={DailyAddLink}>
                                +
                                &nbsp;
                                {dailys.length === 0 ? t('dailyaddfirst') : t('dailyadd')}
                            </Button>
                        )}
                        {!testGun && (
                            <Typography variant='caption'>
                                {t('dailynotestgun')}
                            </Typography>
                        )}
                    </CardContent>
                </Card>
                <Card style={{ marginTop: '12px' }}>
                    <CardHeader title={<Typography variant='h6'>{t('requests')}</Typography>} />
                    <CardContent>
                        <Table component={(props) => <table border={1} {...props} />} classes={{ root: classes.dailytable }} padding='dense'>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '40px' }}>{t('requestdate')}</TableCell>
                                    <TableCell>{t('requestshooter')}</TableCell>
                                    <TableCell>{t('requestro')}</TableCell>
                                    <TableCell style={{ width: '40px' }} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_(requests)
                                    .map((request) => {
                                        const shooter = shooters.find((s) => s.id === request.shooterId) || {};
                                        const chrono = null; // (shooter.chronos || []).find(c => c.requestTimestamp === request.timestamp);

                                        return { request, shooter, chrono };
                                    }).sortBy(
                                        [({ chrono }) => (chrono ? 1 : 0), ({ request }) => -1 * request.timestamp],
                                    ).map(({ request, shooter, chrono }) => {
                                        const $Link = (props) => (
                                            <Link
                                                to={`${location.pathname}/station/${currentStation}/request/${request.timestamp}${location.search}`}
                                                {...props}
                                            />
                                        );
                                        const actionNode = (() => {
                                            if (!chrono) {
                                                return (
                                                    <IconButton variant='outlined' color='primary' component={$Link}>
                                                        <EditIcon />
                                                    </IconButton>
                                                );
                                            }
                                            if (chrono.prevPowerFactor === chrono.nextPowerFactor) {
                                                return (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <i style={{ color: green[500] }} className='fas fa-check' />
                                                        <Hidden xsDown>
                                                            <span style={{ padding: '0px 6px', whiteSpace: 'nowrap' }}>
                                                                {t('registration:chronoconfirmed', chrono)}
                                                            </span>
                                                        </Hidden>
                                                    </div>
                                                );
                                            }
                                            return (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <i style={{ color: red[500] }} className='fas fa-times' />
                                                    <Hidden xsDown>
                                                        <span style={{ padding: '0px 6px', whiteSpace: 'nowrap' }}>
                                                            {t('registration:chronochanged', chrono)}
                                                        </span>
                                                    </Hidden>
                                                </div>
                                            );
                                        })();
                                        return (
                                            <TableRow key={request.timestamp}>
                                                <TableCell>
                                                    {moment(request.timestamp).locale(match.locale).format('L LT')}
                                                </TableCell>
                                                <TableCell>
                                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '6px' }}>
                                                        {(shooter.publicId.indexOf('|') > -1) && (
                                                            <img
                                                                alt={shooter.publicId.split('|')[0]}
                                                                src={getFlagSrc({ countryCode: shooter.publicId.split('|')[0], size: 16 })}
                                                            />
                                                        )}
                                                        <div style={{ padding: '0px 6px' }}>
                                                            {shooter.publicId.split('|').pop()}
                                                        </div>
                                                        <Hidden xsDown>
                                                            <div>
                                                                |
                                                            </div>
                                                        </Hidden>
                                                        <div style={{ padding: '0px 6px' }}>
                                                            {shooter.name}
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {request.ro}
                                                </TableCell>
                                                <TableCell>
                                                    {actionNode}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                    .value()}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

ChronoMain.propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired,
    }).isRequired,
    classes: PropTypes.shape({}).isRequired,
    dailys: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    stations: PropTypes.arrayOf(PropTypes.shape({
        gun: PropTypes.shape({
            make: PropTypes.string,
            serial: PropTypes.string,
            weight: PropTypes.number,
        }),
        dailys: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        requests: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    })).isRequired,
    shooters: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        publicId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        division: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        'power-factor': PropTypes.string.isRequired,
    })).isRequired,
    currentStation: PropTypes.number.isRequired,
    setTitle: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func.isRequired,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
        locale: PropTypes.string.isRequired,
    }).isRequired,
    auth: PropTypes.string.isRequired,
    setStations: PropTypes.func.isRequired,
    setCurrentStation: PropTypes.func.isRequired,
};

function ChronoDailyTest({
    t, classes, updateChrono, history, auth, match, setTitle,
    setSaveButtonCallback, setExtraButtons, stationIdx,
}) {
    const [velocity1, setVelocity1] = useState(0);
    const [velocity2, setVelocity2] = useState(0);
    const [velocity3, setVelocity3] = useState(0);
    const [date, setDate] = useState(new Date().getTime());
    const [cro, setCro] = useState('');
    const [rm, setRm] = useState('');
    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setTitle(t('newdailytitle', { station: stationIdx + 1 }));
        setSaveButtonCallback(null);
        setExtraButtons(null);
        setTimeout(() => window.scrollTo(0, 0), 100);
    }, []);

    async function onSave() {
        const $errors = {};
        if (Number.isNaN(parseFloat(velocity1, 10)) || (parseFloat(velocity1, 10) === 0)) {
            $errors.velocity1 = true;
        }
        if (Number.isNaN(parseFloat(velocity2, 10)) || (parseFloat(velocity2, 10) === 0)) {
            $errors.velocity2 = true;
        }
        if (Number.isNaN(parseFloat(velocity3, 10)) || (parseFloat(velocity3, 10) === 0)) {
            $errors.velocity3 = true;
        }
        if (!cro) {
            $errors.cro = true;
        }
        if (!rm) {
            $errors.rm = true;
        }
        if (!_.isEmpty($errors)) {
            await setErrors($errors);
            return;
        }
        await setErrors({});
        await setSaving(true);
        try {
            const { data: chrono } = await api.postChronoDailyResults({
                matchId: match.id,
                station: stationIdx,
                auth,
                results: {
                    date,
                    velocity1: parseFloat(velocity1, 10),
                    velocity2: parseFloat(velocity2, 10),
                    velocity3: parseFloat(velocity3, 10),
                    cro,
                    rm,
                },
            });
            await setSaving(false);
            history.goBack();
            updateChrono(chrono);
        } catch (e) {
            await setErrors({ save: true });
            await setSaving(false);
        }
    }

    const dateStr = moment(date).toISOString(true).replace(/\..*$/, '');

    return (
        <div className={classes.wrapper}>
            <Grid container spacing={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id='datetime-local'
                        type='datetime-local'
                        label={t('dailydate')}
                        value={dateStr}
                        onChange={(e) => setDate(moment(e.target.value).valueOf())}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid item xs={12} md={4} style={{ marginTop: '12px' }}>
                    <TextField
                        fullWidth
                        label={`${t('dailyshotvelocity')} #1`}
                        error={errors.velocity1}
                        value={velocity1}
                        onChange={(e) => {
                            setVelocity1(e.target.value);
                            setErrors({ ...errors, velocity1: false });
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4} style={{ marginTop: '12px' }}>
                    <TextField
                        fullWidth
                        label={`${t('dailyshotvelocity')} #2`}
                        error={errors.velocity2}
                        value={velocity2}
                        onChange={(e) => {
                            setVelocity2(e.target.value);
                            setErrors({ ...errors, velocity2: false });
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4} style={{ marginTop: '12px' }}>
                    <TextField
                        fullWidth
                        label={`${t('dailyshotvelocity')} #3`}
                        error={errors.velocity3}
                        value={velocity3}
                        onChange={(e) => {
                            setVelocity3(e.target.value);
                            setErrors({ ...errors, velocity3: false });
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid item xs={6} style={{ marginTop: '12px' }}>
                    <TextField
                        fullWidth
                        label={`${t('initials')} - CRO`}
                        error={errors.cro}
                        value={cro}
                        onChange={(e) => {
                            setCro(e.target.value);
                            setErrors({ ...errors, cro: false });
                        }}
                    />
                </Grid>
                <Grid item xs={6} style={{ marginTop: '12px' }}>
                    <TextField
                        fullWidth
                        label={`${t('initials')} - RM`}
                        error={errors.rm}
                        value={rm}
                        onChange={(e) => {
                            setRm(e.target.value);
                            setErrors({ ...errors, rm: false });
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid item xs={12} style={{ marginTop: '12px' }}>
                    <Button fullWidth disabled={saving} color='primary' variant='contained' onClick={onSave}>
                        {saving && <CircularProgress size={24} />}
                        {!saving && t('generic:save')}
                    </Button>
                    {errors.save && (
                        <Typography variant='caption' style={{ color: red[500] }}>
                            {t('generic:saveerror')}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </div>
    );
}

ChronoDailyTest.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    stationIdx: PropTypes.number.isRequired,
    setTitle: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func.isRequired,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    auth: PropTypes.string.isRequired,
    updateChrono: PropTypes.func.isRequired,
};

function ChronoNewRequest({
    t, classes, history, auth, match, setTitle, shooterId, stations, updateChrono,
    setSaveButtonCallback, setExtraButtons, setBackButtonVisible, shooters,
}) {
    const shooter = shooters.find((s) => s.id === shooterId);
    const [stationIdx, setStationIdx] = useState(0);
    const [scheduling, setScheduling] = useState(false);
    const [done, setDone] = useState(false);
    const [schedulingError, setSchedulingError] = useState(null);

    async function onSchedule() {
        await setSchedulingError(null);
        await setScheduling(true);
        try {
            const chrono = await api.postChronoRequest({
                matchId: match.id,
                station: stationIdx,
                auth,
                request: {
                    shooterId,
                },
            });
            await setDone(true);
            await setScheduling(false);
            updateChrono(chrono);
        } catch (e) {
            await setSchedulingError(t('generic:saveerror'));
            await setScheduling(false);
        }
    }

    async function onBack() {
        history.goBack();
    }

    useEffect(() => {
        setTitle(t('newrequesttitle'));
        setSaveButtonCallback(null);
        setExtraButtons(null);
        setBackButtonVisible(true);
        setTimeout(() => window.scrollTo(0, 0), 100);
    }, []);

    if (done) {
        return (
            <div className={classes.wrapper}>
                <div>
                    <Typography variant='h6'>
                        {t('newrequestdonetitle')}
                    </Typography>
                    <Typography variant='body2' style={{ marginTop: '12px' }}>
                        {t('newrequestdone1', { shooter: shooter.name })}
                    </Typography>
                    <Typography variant='body2' style={{ marginTop: '6px' }}>
                        {t('newrequestdone2', { station: stationIdx + 1 })}
                    </Typography>
                    <Button fullWidth style={{ marginTop: '24px' }} color='primary' variant='contained' onClick={onBack}>
                        {t('generic:ok')}
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.wrapper}>
            <div>
                <Typography variant='body2'>
                    {t('newrequestdescription1')}
                </Typography>
                <Typography variant='body2' style={{ marginTop: '12px' }}>
                    {t('newrequestdescription2')}
                </Typography>
                <Typography variant='body2' style={{ marginTop: '12px' }}>
                    {t('newrequestdescription3')}
                </Typography>
                <div style={{ marginTop: '24px' }}>
                    <FormLabel style={{ fontSize: '0.8em' }} component='legend'>{t('generic:shooter_name')}</FormLabel>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '6px' }}>
                        {(shooter.publicId.indexOf('|') > -1) && (
                            <img
                                alt={shooter.publicId.split('|')[0]}
                                src={getFlagSrc({ countryCode: shooter.publicId.split('|')[0], size: 16 })}
                            />
                        )}
                        <div style={{ padding: '0px 6px' }}>
                            {shooter.publicId.split('|').pop()}
                        </div>
                        <div>
                            {shooter.name}
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '24px' }}>
                    <FormLabel style={{ fontSize: '0.8em' }} component='legend'>{t('newrequestselectstation')}</FormLabel>
                    <Select
                        value={stationIdx || 0}
                        onChange={(e) => setStationIdx(e.target.value)}
                    >
                        {_.range(stations.length).map((s) => (
                            <MenuItem key={s} value={s}>
                                {t('stationnum', { num: s + 1 })}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <Button fullWidth style={{ marginTop: '24px' }} disabled={scheduling} color='primary' variant='contained' onClick={onSchedule}>
                    {scheduling && <CircularProgress size={24} />}
                    {!scheduling && t('newrequestschedule', { name: shooter.name })}
                </Button>
                <Typography variant='caption' style={{ color: red[500] }}>
                    {schedulingError || <span>&nbsp;</span>}
                </Typography>
            </div>
        </div>
    );
}

ChronoNewRequest.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    shooterId: PropTypes.string.isRequired,
    setTitle: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func.isRequired,
    setBackButtonVisible: PropTypes.func.isRequired,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    auth: PropTypes.string.isRequired,
    shooters: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        publicId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        division: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        'power-factor': PropTypes.string.isRequired,
    })).isRequired,
    stations: PropTypes.arrayOf(PropTypes.shape({
        gun: PropTypes.shape({
            make: PropTypes.string,
            serial: PropTypes.string,
            weight: PropTypes.number,
        }),
    })).isRequired,
    updateChrono: PropTypes.func.isRequired,
};

function ChronoForm({
    t, classes, history, auth, match, setTitle, request, onUpdateShooter,
    setSaveButtonCallback, setExtraButtons, setBackButtonVisible, shooters,
}) {
    const stored = JSON.parse(window.localStorage.getItem('__chronoform_result') || '{}');
    const defaults = ((stored.shooterId === request.shooterId) && (stored.timestamp === request.timestamp))
        ? stored : {};
    const [weight1, setWeight1] = useState(defaults.weight1 || '');
    const [weight2, setWeight2] = useState(defaults.weight2 || '');
    const [velocity1, setVelocity1] = useState(defaults.velocity1 || '');
    const [velocity2, setVelocity2] = useState(defaults.velocity2 || '');
    const [velocity3, setVelocity3] = useState(defaults.velocity3 || '');
    const [velocity4, setVelocity4] = useState(defaults.velocity4 || '');
    const [velocity5, setVelocity5] = useState(defaults.velocity5 || '');
    const [velocity6, setVelocity6] = useState(defaults.velocity6 || '');
    const [velocity7, setVelocity7] = useState(defaults.velocity7 || '');
    const [stage, setStage] = useState(defaults.stage || '');
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);

    window.localStorage.setItem('__chronoform_result', JSON.stringify({
        shooterId: request.shooterId,
        timestamp: request.timestamp,
        weight1,
        weight2,
        velocity1,
        velocity2,
        velocity3,
        velocity4,
        velocity5,
        velocity6,
        velocity7,
        stage,
    }));

    function onNextStage() {
        if (!stage) {
            setStage(2);
        } else if (stage === 2) {
            setStage(3);
        }
    }
    const shooter = shooters.find((s) => s.id === request.shooterId) || {};

    useEffect(() => {
        setTitle(t('formtitle'));
        setSaveButtonCallback(null);
        setExtraButtons(null);
        setBackButtonVisible(true);
        setTimeout(() => window.scrollTo(0, 0), 100);
    }, []);

    const allFirst = weight1
        && velocity1
        && velocity2
        && velocity3;

    const allSecond = allFirst
        && velocity4
        && velocity5
        && velocity6;

    const allThird = allSecond
        && ((velocity7 && !weight2) || (!velocity7 && weight2));

    const calculated = (() => {
        if (allFirst && !stage) {
            const avgVelocity = (parseFloat(velocity1, 10)
                + parseFloat(velocity2, 10)
                + parseFloat(velocity3, 10)) / 3;
            return Math.floor((avgVelocity * parseFloat(weight1, 10)) / 1000);
        }

        if ((stage === 2) && (allSecond)) {
            const velocities = [
                parseFloat(velocity1, 10),
                parseFloat(velocity2, 10),
                parseFloat(velocity3, 10),
                parseFloat(velocity4, 10),
                parseFloat(velocity5, 10),
                parseFloat(velocity6, 10),
            ];
            velocities.sort((a, b) => b - a);
            const avgVelocity = (velocities[0] + velocities[1] + velocities[2]) / 3;
            return Math.floor((avgVelocity * parseFloat(weight1, 10)) / 1000);
        }

        if ((stage === 3) && (allThird)) {
            const velocities = [
                parseFloat(velocity1, 10),
                parseFloat(velocity2, 10),
                parseFloat(velocity3, 10),
                parseFloat(velocity4, 10),
                parseFloat(velocity5, 10),
                parseFloat(velocity6, 10),
                parseFloat(velocity7 || '0', 10),
            ];
            velocities.sort((a, b) => b - a);
            const avgVelocity = (velocities[0] + velocities[1] + velocities[2]) / 3;
            const weight = Math.max(parseFloat(weight1, 10), parseFloat(weight2 || '0', 10));
            return Math.floor((avgVelocity * weight) / 1000);
        }

        return null;
    })();

    const allOk = (() => {
        if (calculated < 125) return false;
        if (shooter['power-factor'] === 'minor') return true;
        if ((shooter.division === 'standard') && (calculated >= 170)) return true;
        if ((shooter.division === 'classic') && (calculated >= 170)) return true;
        if ((shooter.division === 'open') && (calculated >= 160)) return true;
        return false;
    })();

    const nextPowerFactor = (() => {
        if (allOk) return shooter['power-factor'];
        if (calculated < 125) return 'invalid';
        return 'minor';
    })();

    async function onSave() {
        await setSaving(true);
        await setError(null);
        try {
            const data = await api.postShootersChronoResult({
                matchId: match.id,
                shooterId: request.shooterId,
                auth,
                result: _.omitBy({
                    requestTimestamp: request.timestamp,
                    prevPowerFactor: shooter['power-factor'],
                    nextPowerFactor,
                    weight1: parseFloat(weight1, 10),
                    weight2: parseFloat(weight2, 10),
                    velocity1: parseFloat(velocity1, 10),
                    velocity2: parseFloat(velocity2, 10),
                    velocity3: parseFloat(velocity3, 10),
                    velocity4: parseFloat(velocity4, 10),
                    velocity5: parseFloat(velocity5, 10),
                    velocity6: parseFloat(velocity6, 10),
                    velocity7: parseFloat(velocity7, 10),
                }, (a) => !a),
            });
            await setSaving(false);
            history.goBack();
            onUpdateShooter(data);
        } catch (e) {
            await setSaving(false);
            await setError(t('generic:saveerror'));
        }
    }

    return (
        <div className={classes.wrapper}>
            <div>
                <Grid container>
                    <Grid item xs={12} md={3} style={{ display: 'flex', marginBottom: '12px' }}>
                        <TextField label={t('generic:shooter_name')} value={shooter.name} fullWidth />
                    </Grid>
                    <Hidden xsDown>
                        <Grid item xs={1} md={1} />
                    </Hidden>
                    <Grid item xs={12} md={3} style={{ display: 'flex', marginBottom: '12px' }}>
                        <TextField label={t('generic:shooter_division')} value={t(`generic:division-${shooter.division}`)} fullWidth />
                    </Grid>
                    <Hidden xsDown>
                        <Grid item xs={1} md={1} />
                    </Hidden>
                    <Grid item xs={12} md={3} style={{ display: 'flex', marginBottom: '12px' }}>
                        <TextField label={t('generic:shooter_power_factor')} value={t(`registration:${shooter['power-factor']}`)} fullWidth />
                    </Grid>
                </Grid>

                <Grid container style={{ marginBottom: '12px', marginTop: '12px' }}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                            {t('formfirsttest')}
                            {!!stage && (
                                <Button color='primary' style={{ textTransform: 'none', fontSize: '0.8em' }} onClick={() => setStage(null)}>
                                    {t('generic:edit')}
                                </Button>
                            )}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container style={{ marginBottom: '12px' }}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            label={t('formbulletweight1')}
                            disabled={!!stage}
                            value={weight1}
                            onChange={(e) => setWeight1(e.target.value)}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={3} style={{ display: 'flex', marginBottom: '12px' }}>
                        <TextField
                            label={t('formbulletvolcity1')}
                            disabled={!!stage}
                            value={velocity1}
                            onChange={(e) => setVelocity1(e.target.value)}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Hidden xsDown>
                        <Grid item xs={1} md={1} />
                    </Hidden>
                    <Grid item xs={12} md={3} style={{ display: 'flex', marginBottom: '12px' }}>
                        <TextField
                            label={t('formbulletvolcity2')}
                            disabled={!!stage}
                            value={velocity2}
                            onChange={(e) => setVelocity2(e.target.value)}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Hidden xsDown>
                        <Grid item xs={1} md={1} />
                    </Hidden>
                    <Grid item xs={12} md={3} style={{ display: 'flex', marginBottom: '12px' }}>
                        <TextField
                            label={t('formbulletvolcity3')}
                            disabled={!!stage}
                            value={velocity3}
                            onChange={(e) => setVelocity3(e.target.value)}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
                {((stage === 2) || (stage === 3)) && (
                    <div>
                        <Grid container style={{ marginBottom: '12px', marginTop: '12px' }}>
                            <Grid item xs={12}>
                                <Typography variant='h6'>
                                    {t('formsecondtest')}
                                    {stage !== 2 && (
                                        <Button color='primary' style={{ textTransform: 'none', fontSize: '0.8em' }} onClick={() => setStage(2)}>
                                            {t('generic:edit')}
                                        </Button>
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} md={3} style={{ display: 'flex', marginBottom: '12px' }}>
                                <TextField
                                    label={t('formbulletvolcity4')}
                                    disabled={stage !== 2}
                                    value={velocity4}
                                    onChange={(e) => setVelocity4(e.target.value)}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Hidden xsDown>
                                <Grid item xs={1} md={1} />
                            </Hidden>
                            <Grid item xs={12} md={3} style={{ display: 'flex', marginBottom: '12px' }}>
                                <TextField
                                    label={t('formbulletvolcity5')}
                                    disabled={stage !== 2}
                                    value={velocity5}
                                    onChange={(e) => setVelocity5(e.target.value)}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Hidden xsDown>
                                <Grid item xs={1} md={1} />
                            </Hidden>
                            <Grid item xs={12} md={3} style={{ display: 'flex', marginBottom: '12px' }}>
                                <TextField
                                    label={t('formbulletvolcity6')}
                                    value={velocity6}
                                    disabled={stage !== 2}
                                    onChange={(e) => setVelocity6(e.target.value)}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                )}
                {(stage === 3) && (
                    <div>
                        <Grid container style={{ marginBottom: '12px', marginTop: '12px' }}>
                            <Grid item xs={12}>
                                <Typography variant='h6'>
                                    {t('formlastchancetitle')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} md={3} style={{ display: 'flex', marginBottom: '12px' }}>
                                <TextField
                                    label={t('formbulletvolcity7')}
                                    value={velocity7}
                                    onChange={(e) => setVelocity7(e.target.value)}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Hidden xsDown>
                                <Grid item xs={1} md={1} />
                            </Hidden>
                            <Grid item xs={12} md={3} style={{ display: 'flex', marginBottom: '12px' }}>
                                <TextField
                                    label={t('formbulletweight2')}
                                    value={weight2}
                                    onChange={(e) => setWeight2(e.target.value)}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                )}
                <Grid container>
                    <Grid item xs={12} style={{ marginBottom: '12px' }}>
                        <Typography variant='subtitle1'>
                            {!allFirst && !stage && <span>{t('formresultmissing')}</span>}
                            {!allSecond && (stage === 2) && <span>{t('formresultmissing')}</span>}
                            {!allThird && (stage === 3) && <span>{t('formresultlastchance')}</span>}
                            {allFirst && !stage && <span>{t('formresult', { calculated })}</span>}
                            {allSecond && (stage === 2) && <span>{t('formresult', { calculated })}</span>}
                            {allThird && (stage === 3) && <span>{t('formresult', { calculated })}</span>}
                        </Typography>

                        {!stage && allFirst && !allOk && (
                            <div>
                                <Typography
                                    variant='subtitle1'
                                    style={{
                                        marginTop: '12px',
                                        width: '100%',
                                        textAlign: 'center',
                                        fontWeight: '500',
                                    }}
                                >
                                    <span>{t('formnotok')}</span>
                                </Typography>
                                <Button variant='contained' color='secondary' onClick={onNextStage} fullWidth style={{ marginTop: '12px' }}>
                                    {t('formanotherthree')}
                                </Button>
                            </div>
                        )}

                        {(stage === 2) && allSecond && !allOk && (
                            <div>
                                <Typography
                                    variant='subtitle1'
                                    style={{
                                        marginTop: '12px',
                                        width: '100%',
                                        textAlign: 'center',
                                        fontWeight: '500',
                                    }}
                                >
                                    <span>{t('formnotok')}</span>
                                </Typography>
                                <Button variant='contained' color='secondary' onClick={onNextStage} fullWidth style={{ marginTop: '12px' }}>
                                    {t('formlastchance')}
                                </Button>
                            </div>
                        )}

                        {(stage === 3) && allThird && !allOk && (
                            <div>
                                <Typography
                                    variant='subtitle1'
                                    style={{
                                        marginTop: '12px',
                                        width: '100%',
                                        textAlign: 'center',
                                        fontWeight: '500',
                                    }}
                                >
                                    {(calculated >= 125) && <div>{t('formtominor')}</div>}
                                    {(calculated < 125) && <div>{t('formstopscoring')}</div>}
                                </Typography>
                                <Button variant='contained' color='secondary' onClick={onSave} fullWidth style={{ marginTop: '12px' }}>
                                    {saving && <CircularProgress size={24} />}
                                    {!saving && t('generic:save')}
                                </Button>
                                <Typography variant='caption' style={{ color: red[500] }}>
                                    {error || <span>&nbsp;</span>}
                                </Typography>
                            </div>
                        )}

                        {allOk && (
                            <div>
                                <Typography
                                    variant='subtitle1'
                                    style={{
                                        marginTop: '12px',
                                        width: '100%',
                                        textAlign: 'center',
                                        fontWeight: '500',
                                    }}
                                >
                                    <span>{t('formallok')}</span>
                                </Typography>
                                <Button variant='contained' color='primary' onClick={onSave} fullWidth style={{ marginTop: '12px' }}>
                                    {saving && <CircularProgress size={24} />}
                                    {!saving && t('generic:save')}
                                </Button>
                                <Typography variant='caption' style={{ color: red[500] }}>
                                    {error || <span>&nbsp;</span>}
                                </Typography>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

ChronoForm.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    setTitle: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func.isRequired,
    setBackButtonVisible: PropTypes.func.isRequired,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    auth: PropTypes.string.isRequired,
    shooters: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        publicId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        division: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        'power-factor': PropTypes.string.isRequired,
    })).isRequired,
    request: PropTypes.shape({
        shooterId: PropTypes.string.isRequired,
        timestamp: PropTypes.number.isRequired,
    }).isRequired,
    onUpdateShooter: PropTypes.func.isRequired,
};
/* eslint-enable max-classes-per-file */
