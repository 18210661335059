import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import app from '../AppCommunication';

class FacebookSignIn extends Component {
    constructor(props) {
        super(props);

        if ((typeof (window) !== 'undefined') && (window.FB)) {
            if (!props.noAuto) {
                this.checkSignin();
            }
        }

        this.state = {
            id: null,
            name: null,
            imageUrl: null,
        };
    }

    async componentDidMount() {
        this.checkSignin();
    }

    onLoginSuccess = async () => {
        setTimeout(() => { this.checkSignin(); }, 100);
    }

    onAppClick = () => {
        app({ cmd: { src: 'facebook-login', type: 'on-login' } });
    }

    onClick(callback, v) {
        this.clicked = true;
        callback(v);
    }

    logout = async () => {
        try {
            const { onLogout = () => {} } = this.props;
            window.FB.logout();
            const data = {
                id: null, name: null, imageUrl: null, email: null, accessToken: null,
            };
            this.lastLogin = false;
            onLogout();
            this.setState(data);
        } catch (e) {
            console.error(e);
        }
    }

    async checkSignin() {
        try {
            const { noAuto, autoAccessToken, onLogin = () => {} } = this.props;
            if (noAuto && !this.clicked) return;
            const loginStatus = await new Promise((res) => window.FB.getLoginStatus(res));

            const accessToken = (() => {
                if (loginStatus.status === 'connected') return loginStatus.authResponse.accessToken;
                if (!noAuto && autoAccessToken) return autoAccessToken;
                return null;
            })();

            if (accessToken) {
                const me = await new Promise((res) => window.FB.api('/me?fields=picture,name,email,id', { access_token: accessToken }, res));
                const data = {
                    id: me.id,
                    name: me.name,
                    imageUrl: ((me.picture || {}).data || {}).url,
                    email: me.email,
                    accessToken,
                };
                if (!this.lastLogin) {
                    this.lastLogin = true;
                    onLogin(data);
                }
                this.setState(data);
            }
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        const {
            t, mode, matchId, system,
        } = this.props;

        return <div />;

        const { id, name, imageUrl } = this.state;
        if (id !== null) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{
                        position: 'relative', width: '100%', height: '50px', display: 'flex', alignItems: 'center',
                    }}
                    >
                        <img alt='profile' style={{ width: '36px', height: '36px', margin: '0px 6px' }} src={imageUrl} />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant='caption' style={{ color: 'inherit' }}>{t('authentication:connected-using-facebook')}</Typography>
                            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                <div>
                                    {name}
                                </div>
                                <Button
                                    color='primary'
                                    variant='text'
                                    style={{
                                        fontSize: '11px', padding: '0px 6px', minHeight: '0px', position: 'relative', top: '2px', textTransform: 'none', fontWeight: '100',
                                    }}
                                    onClick={this.logout}
                                >
                                    {t('authentication:sign-in-not-me')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div style={{ position: 'relative', width: '240px', height: '50px' }}>
                <div>
                    {(mode !== 'app') && (
                        <FacebookLogin
                            appId='520383988467547'
                            autoLoad
                            fields='name,email,picture'
                            redirectUri='https://www.endofscoring.com/_facebook/'
                            state={`${system}:${matchId}`}
                            callback={(res) => this.onLoginSuccess(res)}
                            render={(renderProps) => (
                                <Button
                                    onClick={(e) => this.onClick(renderProps.onClick, e)}
                                    color='primary'
                                    variant='contained'
                                    style={{
                                        width: '240px', height: '50px', textTransform: 'none', display: 'flex',
                                    }}
                                >
                                    <Icon className='fab fa-facebook' />
                                    <div style={{ flex: 1 }}>{t('authentication:sign-in-with-facebook')}</div>
                                </Button>
                            )}
                        />
                    )}
                    {(mode === 'app') && (
                        <Button
                            onClick={this.onAppClick}
                            color='primary'
                            variant='contained'
                            style={{
                                width: '240px', height: '50px', textTransform: 'none', display: 'flex',
                            }}
                        >
                            <Icon className='fab fa-facebook' />
                            <div style={{ flex: 1 }}>{t('authentication:sign-in-with-facebook')}</div>
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}

FacebookSignIn.propTypes = {
    noAuto: PropTypes.bool,
    autoAccessToken: PropTypes.string,
    mode: PropTypes.string,
    system: PropTypes.string,
    matchId: PropTypes.string,
    onLogin: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
};

FacebookSignIn.defaultProps = {
    noAuto: null,
    autoAccessToken: null,
    mode: null,
    system: null,
    matchId: null,
};

export default withTranslation('')(FacebookSignIn);
