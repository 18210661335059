module.exports = {
    en: {
        title: 'Export to WinMSS',
        description1: 'Before we begin, please make sure you backup your WinMSS data before proceeding.',
        description2: 'Steps to export data to WinMSS:',
        step1: '(1) Press the "Export" button, which will download a few files.',
        step2: '(2) Double-click the downloaded "prepare.bat" file.',
        step3: '(3) In WinMSS, use "Competition -> Match -> Import" and select the cab file created (should be under a directory named "disk1").',
        lastnote: 'Note: you should do this only on a Windows computer, that has WinMSS installed.',
        export: 'Export',
        exportpcc: 'Export PCC Division',
    },
    he: {
        title: 'יצוא ל-WinMSS',
        description1: 'לפי שנתחיל, ממולץ מאד לגבות את המידע של WinMSS.',
        description2: 'שלבים ליצוא ל-WinMSS:',
        step1: '(1) לחצו את כפתור היצוא, אשר יוריד לכם למחשב מספר קבצים.',
        step2: '(2) לאחר הורדת הקבצים, יש ללחוץ על קובץ ה-prepare.bat ולהריץ אותו.',
        step3: '(3) ב-WinMSS, יש לגשת ל-"Competition -> Match -> Import" ולבחור את קובץ ה-cab שנוצר (אמור להיות תחת ספרייה בשם "disk1").',
        lastnote: 'שימו לב, את כל הפעולות יש לעשות על מחשב ווינדוס עם WinMSS',
        export: 'ייצא',
        exportpcc: 'ייצא תחרות PCC',
    },
};
