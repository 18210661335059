import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

function ViewerResults(props) {
    const { t, classes, match } = props;
    return (
        <div className={classes.wrapper}>
            <Button
                component={($props) => <Link to={`/${match.alias}/score-table${window.location.search}`} {...$props} />}
                style={{ marginBottom: '12px' }}
                fullWidth
                color='primary'
                variant='contained'
            >
                {match.scoresPublished === 'verify-only' ? t('liveverify') : t('liveresults')}
            </Button>
            <Button
                component={($props) => <Link to={`/${match.alias}/shooter-stats${window.location.search}`} {...$props} />}
                style={{ marginBottom: '12px' }}
                fullWidth
                color='primary'
                variant='contained'
            >
                {t('shooterstats')}
            </Button>
            <Button
                component={($props) => <Link to={`/${match.alias}/compare-shooters${window.location.search}`} {...$props} />}
                style={{ marginBottom: '12px' }}
                fullWidth
                color='primary'
                variant='contained'
            >
                {t('compareshooters')}
            </Button>
            {(match.scoresPublished !== 'verify-only') && (
                <Button
                    component={($props) => <Link to={`/${match.alias}/flags-podium${window.location.search}`} {...$props} />}
                    fullWidth
                    color='primary'
                    variant='contained'
                >
                    {t('flagspodium')}
                </Button>
            )}
        </div>
    );
}

ViewerResults.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({
        alias: PropTypes.string.isRequired,
    }).isRequired,
};
const styles = () => ({
    wrapper: {
        width: '90%',
        maxWidth: '720px',
        margin: 'auto',
        paddingTop: '24px',
        paddingBottom: '12px',
        textAlign: 'left',
    },
});

export default withStyles(styles, { withTheme: true })(withTranslation('viewerresults')(ViewerResults));
