const lang = {
    en: {
        title: '{{- name}} - Registration Verification',
        details: 'I\'ve verified all the information written above',
        openmincaliber: 'Bullet caliber / cartridge case length is at least 9 mm (0.354") / 19 mm (0.748")',
        mincaliber: 'Bullet caliber / cartridge case length is at least 9 mm (0.354") / 19 mm (0.748")',
        mincaliber_minor: 'Bullet caliber / cartridge case length is at least 9 mm (0.354") / 19 mm (0.748")',
        mincaliber_major: 'Bullet caliber / cartridge case length is at least 10 mm (0.40") or 357SIG',
        box: 'Handgun (in its ready condition, unloaded, with each of his empty magazines inserted), fits in a box which has internal dimensions of 225 mm x 150 mm x 45 mm (tolerance of +1 mm, -0 mm)',
        maxtorso: 'Distance of handgun and allied equipment from torso is at most 50 mm',
        bones: 'Gun, holster, and allied equipment are placed behind the hip bones',
        nobarrelporting: 'Barrels are not ported',
        nooptics: 'No optical/electronic sights',
        nocompensators: 'No compensators, ports, sound and/or flash suppressors',
        mag170: 'Magazine length is 170 mm at most',
        classichandgun: 'Handguns is based on and visibly resemble the profile and contours of the classic 1911-genre design',
        classicmag: 'Magazine wells do not exceed a maximum outside width of 35 mm (checked by box)',
        classicmod: 'Only approved modifications have been done to the gun (prohibited modifications / parts are slide lightening cuts, weak hand thumb rests and slide rackers)',
        prodtrigger: 'Trigger pull is at least 2.27kg (5lbs.) for first shot or 1.36kg (3lbs.) for every shot',
        maxbarrellength: 'Barrel length is at most 127 mm',
        inprodlist: 'Handgun is listed as approved in the Production Division List. Handgun contains only original parts and components offered by the OFM as standard equipment, or as an option, for this specific model',
        mandatoryoptics: 'Optical/electronic sights are mounted on the upper rear of the slide either in addition to, or replacement of, the rear open sight',
        minoronlyrevolver7round_major: 'Handgun capacity, when declared major, must be 6 rounds at most',
        weightsonbarrel: 'Weight(s) attached to barrel that extend beyond the muzzle are not allowed',
        minoronlyrevolver7round_minor: '',
        notselfloadingrevolver: 'Handgun is not "self-loading" with retractable slides.',
        checkmarks: 'Please check all boxes above',
        verified: 'Shooter is verified. His/her details can no longer be changed.',
        submit: 'Submit',
        checkall: 'Check All',
    },
    he: {
        title: '{{- name}} - אימות רישום',
        details: 'אני מאשר\\ת שכלל הפרטים הרשומים למעלה נכונים',
        openmincaliber: 'אורך תרמיל / קוטר כדור לפחות 9 ממ (0.354") / 19 ממ (0.748")',
        mincaliber: 'אורך תרמיל / קוטר כדור לפחות 9 ממ (0.354") / 19 ממ (0.748")',
        mincaliber_minor: 'אורך תרמיל / קוטר כדור לפחות 9 ממ (0.354") / 19 ממ (0.748")',
        mincaliber_major: 'אורך תרמיל / קוטר כדור לפחות 10 ממ (0.40") או 357SIG',
        box: 'האקדח במצב ״היכון״, לא טעון, אך עם כל אחת מהמחסניות של היורה, חייב להתאים במלואו לקופסא שממדיה הפנימיים 225 ממ x 150 ממ x 45 ממ (עם אפיצות של +1 ממ, -0 ממ)',
        maxtorso: 'מרחק של האקדח והציוד הנלווה מהמותן הוא לכל היותר 50 ממ',
        bones: 'האקדח, נרתיק, וכל הציוד הנלווה, נמצאים מאחורי עצם הירך',
        nobarrelporting: 'הקנה ללא חריצים',
        nooptics: 'ללא כוונות אופטיות או אלקטרוניות',
        nocompensators: 'ללא מפצי סטייה, חריצים, או כל תוסף להקטנת הרעש או הפלאש',
        mag170: 'אורך מחסנית הוא לכל היות 170 ממ',
        classichandgun: 'האקדח מבוסס על דמיון נראה עם שמירה על פרופיל וקימורים לדגם 1911 קלאסי',
        classicmag: 'רוחב פתח בית המחסנית הינו לכל היותר 35 מ"מ',
        classicmod: 'רק שינויים מאושרים בוצעו על האקדח (אין הפחתת משקל מהצינה בכירסום, חיתוך, משען אגודל ליד חלשה, ידיות דריכה, וכו׳)',
        prodtrigger: 'משקל ההדק הוא לכל היותר 2.27 ק״ג (5 ליברות) לכדור הראשון, או 1.36 ק״ג (3 ליברות) לכל כדור',
        maxbarrellength: 'אורך קנה הוא לכל היותר 127 ממ',
        inprodlist: 'סוג האקדח רשום ברשימות האקדח המורשים למחלקת פרודקשיין. האקדח מכיל רק חלקים מקוריים, או חלקים הניתנים ע״י היצרן כתוספות סטדנרטיות למודל זה',
        mandatoryoptics: 'ישנה כוונת אופטית או אלקטרונית אשר מורכבת על החלק העליו האחורי של המחלק, כתוספת או כתחליף לכוות הפתוחה',
        weightsonbarrel: 'משקולות המחוברות לקנה אשר עוברות את הלוע אסורות',
        minoronlyrevolver7round_major: 'מקסימום 6 כדורים בתוף (כמייג׳ור)',
        minoronlyrevolver7round_minor: '',
        notselfloadingrevolver: 'האקדח איננו בעל מחלק נייד',
        checkmarks: 'אנא וודאו את כל הסעיפים הרשומים',
        verified: 'נתוני היורה אומתו. לא ניתן לשנותם.',
        submit: 'שלח',
        checkall: 'סמן הכל',
    },
};

lang.iw = {
    ...lang.he,
    ...{
        box: 'האקדח במצב ״היכון״, לא טעון, אך עם כל אחת מהמחסניות של הלוחם\\ת, חייב להתאים במלואו לקופסא שממדיה הפנימיים 225 ממ x 150 ממ x 45 ממ (עם אפיצות של +1 ממ, -0 ממ)',
        verified: 'נתוני הלוחם\\ת אומתו. לא ניתן לשנותם.',
    },
};

export default lang;
