/* eslint-disable react/no-multi-comp */
/* eslint-disable max-classes-per-file */
import './Stages.css';
import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import arrayToSentence from 'array-to-sentence';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import red from '@material-ui/core/colors/red';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import Stage from './Stage';
import Snackbar from '../components/Snackbar';
import api from '../Api';

class Stages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saveSuccess: false,
        };
    }

    onDelete = () => {
        const { history } = this.props;
        this.setState({ saveSuccess: true }, () => { history.goBack(); });
    }

    onSaveNew = () => {
        const { history } = this.props;
        this.setState({ saveSuccess: true }, () => { history.goBack(); });
    }

    render() {
        const { t } = this.props;
        const { saveSuccess } = this.state;

        return (
            <div style={{
                display: 'flex', alignItems: 'center', flexDirection: 'column', paddingBottom: '24px',
            }}
            >
                <div style={{ width: '90%' }}>
                    <Route
                        path='/:matchId/manage/stages/:stageIdx'
                        exact
                        render={({ match, history }) => (
                            <Stage
                                {...this.props}
                                stageIdx={match.params.stageIdx === 'new' ? 'new' : parseInt(match.params.stageIdx, 10)}
                                manage
                                onSaveNew={this.onSaveNew}
                                onDelete={this.onDelete}
                                history={history}
                            />
                        )}
                    />
                    <Route
                        path='/:matchId/manage/stages'
                        exact
                        render={() => (<StagesInner {...this.props} manage />)}
                    />
                    <Route
                        path='/:matchId/stages'
                        exact
                        render={() => (<StagesInner {...this.props} />)}
                    />
                </div>

                <Snackbar
                    open={saveSuccess}
                    onClose={() => this.setState({ saveSuccess: false })}
                    variant='success'
                    message={t('generic:savesuccess')}
                />
            </div>
        );
    }
}

Stages.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    t: PropTypes.func.isRequired,
};

class StagesInner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stages: null,
        };
    }

    async componentDidMount() {
        const {
            manage, setTitle, match, t, setExtraButtons, setSaveButtonCallback,
        } = this.props;

        setTitle(t('title', { name: match.title }));
        if (manage) {
            setExtraButtons([
                <Tooltip title={t('print')}>
                    <IconButton onClick={this.print}>
                        <i className='fas fa-print' style={{ fontSize: '24px' }} />
                    </IconButton>
                </Tooltip>,
            ]);
            setSaveButtonCallback(null);
        }

        if (typeof (window) !== 'undefined') {
            window.scrollTo(0, 0);
        }

        const $match = await api.getMatch({ matchId: match.id });
        const stages = await api.getStages({ stages: $match.stages });

        this.setState({ stages, countryCode: $match.countryCode });
    }

    print = async () => {
        window.print();
    }

    /*
    printPDF = async () => {
        const { match } = this.props;
        const { stageIdx } = this.state;
        window.html2canvas = html2canvas;
        await new Promise(res => this.setState({ pdfing: true }, res));

        const canvas = await html2canvas(this.$refs.printing);
        console.log(canvas.width, canvas.height);

        const width = 190;
        const height = Math.floor(width * canvas.height / canvas.width);

        const blob = await domtoimage.toPng(this.$refs.printing);
        const doc = new JSPDF();
        doc.addImage(blob, 'PNG', 10, 4, width, height);
        doc.save(`${match.title} - stage ${stageIdx}.pdf`);

        await new Promise(res => this.setState({ pdfing: false }, res));
    }
    */

    renderStage(stage, index) {
        const { t: orgT, url } = this.props;
        const { countryCode } = this.state;

        function t(key, options) {
            if (countryCode === 'SG') {
                return orgT(key.replace(/^ipsc/, 'csp').replace(/^IPSC/, 'CSP'), options);
            } else {
                return orgT(key, options);
            }
        }

        if (stage === null) return this.renderNewStage(index);
        const {
            targets, rounds, inactive,
        } = stage;

        const numNoShoots = targets.noshoots === 'single' ? 1
            : targets.noshoots === 'several' ? 2
                : (targets['paper-no-shoots'] || 0) + (targets['metal-no-shoots'] || 0);

        const targetsStr = arrayToSentence(_.compact([
            targets.paper ? t('ipsctargets', { count: targets.paper }) : null,
            targets.minipaper ? t('ipsctargetsmini', { count: targets.minipaper }) : null,
            targets.poppers ? t('ipscpoppers', { count: targets.poppers }) : null,
            targets.minipoppers ? t('ipscpoppersmini', { count: targets.minipoppers }) : null,
            targets.plates ? t('ipscplates', { count: targets.plates }) : null,
            targets['pcc-ten-points-poppers'] ? t('ipscpcctenpointspoppers', { count: targets['pcc-ten-points-poppers'] }) : null,
            targets['pcc-frangible'] ? t('ipscpccfrangible', { count: targets['pcc-frangible'] }) : null,
            targets['pcc-ten-points-frangible'] ? t('ipscpcctenpointsfrangible', { count: targets['pcc-ten-points-frangible'] }) : null,
            numNoShoots === 1 ? t('noshoots-single') : numNoShoots > 1 ? t('noshoots-several') : null,
        ]), {
            lastSeparator: t('generic:last_separator'),
        });

        const ButtonLink = React.forwardRef((props, ref) => <Link ref={ref} to={`${url}/${index}`} {...props} />);
        return (
            <div style={{
                height: '100%', display: 'flex', flexDirection: 'column', position: 'relative',
            }}
            >
                <div style={{ padding: '0px 3px', minHeight: '104px' }}>
                    <div className='stages-stage-name'>
                        <Typography variant='h6'>
                            {t('stage-number', { number: index })}
                        </Typography>
                    </div>
                    {inactive && (
                        <Typography variant='body2' style={{ fontWeight: 500, color: red[500] }}>
                            {t('inactive')}
                        </Typography>
                    )}
                    {!inactive && (
                        <div className='stages-stage-targets'>
                            <Typography variant='body2'>
                                <b>{t('targets')}</b>
                                :
                                {targetsStr}
                            </Typography>
                        </div>
                    )}
                    {!inactive && (
                        <div className='stages-stage-targets'>
                            <Typography variant='body2'>
                                <b>{t('rounds')}</b>
                                :
                                {rounds}
                            </Typography>
                        </div>
                    )}
                </div>
                <Button component={ButtonLink} style={{ padding: '3px' }}>
                    <Paper style={{ padding: '24px', flex: 1 }} classes={{ root: 'stages-stage-paper' }}>
                        {this.renderInternal(stage, index)}
                    </Paper>
                </Button>
            </div>
        );
    }

    renderInternal(stage, index) {
        const { t: orgT } = this.props;
        const { countryCode } = this.state;

        function t(key, options) {
            if (countryCode === 'SG') {
                return orgT(key.replace(/^ipsc/, 'csp'), options);
            } else {
                return orgT(key, options);
            }
        }

        if (stage === null) return this.renderNewStage(index);
        const {
            title = '', targets, bonusTargets, rounds, startPosition, gunCondition, pccCondition, start, procedure, end, comments,
        } = stage;

        const targetsStr = arrayToSentence(_.compact([
            targets.paper ? t('ipsctargets', { count: targets.paper }) : null,
            targets.minipaper ? t('ipsctargetsmini', { count: targets.minipaper }) : null,
            targets.poppers ? t('ipscpoppers', { count: targets.poppers }) : null,
            targets.minipoppers ? t('ipscpoppersmini', { count: targets.minipoppers }) : null,
            targets['pcc-ten-points-poppers'] ? t('ipscpcctenpointspoppers', { count: targets['pcc-ten-points-poppers'] }) : null,
            targets['pcc-frangible'] ? t('ipscpccfrangible', { count: targets['pcc-frangible'] }) : null,
            targets['pcc-ten-points-frangible'] ? t('ipscpcctenpointsfrangible', { count: targets['pcc-ten-points-frangible'] }) : null,
            targets.plates ? t('ipscplates', { count: targets.plates }) : null,
        ]), {
            lastSeparator: t('generic:last_separator'),
        });

        const tenPointsExtra = ((stage.targets['pcc-ten-points-poppers'] || 0) + (stage.targets['pcc-ten-points-frangible'] || 0)) * 5;

        return (
            <div>
                <div className='stages-stage-table-title' style={{ textAlign: 'center' }}>
                    {title}
                </div>
                <div style={{ position: 'relative', paddingTop: '100%', width: '100%' }}>
                    {stage.img && (
                    <img
                        alt='stage'
                        style={{
                            position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%',
                        }}
                        src={stage.img}
                    />
                    )}
                </div>
                <Typography variant='body1' variantMapping={{ body1: 'div' }}>
                    <table className='stages-stage-table stages-stage-table-text' border='1'>
                        <tbody>
                            <tr>
                                <td>{t('targets')}</td>
                                <td>{targetsStr}</td>
                            </tr>
                            <tr>
                                <td>{t('rounds')}</td>
                                <td>
                                    {t('rounds-points', { rounds, points: rounds * 5 + tenPointsExtra })}
                                    {(((targets.paper || 0) + (targets.minipaper || 0) > 0) && (rounds === (targets.paper || 0) + (targets.minipaper || 0) + (targets.poppers || 0) + (targets.minipoppers || 0) + (targets.plates || 0) + (targets['pcc-ten-points-poppers'] || 0) + (targets['pcc-frangible'] || 0) + (targets['pcc-ten-points-frangible'] || 0))) && (
                                        <Typography variant='caption'>{t('rounds-points-one-shot')}</Typography>
                                    )}
                                    {(((targets.paper || 0) + (targets.minipaper || 0) > 0) && (rounds === (targets.paper || 0) * 3 + (targets.minipaper || 0) * 3 + (targets.poppers || 0) + (targets.minipoppers || 0) + (targets.plates || 0) + (targets['pcc-ten-points-poppers'] || 0) + (targets['pcc-frangible'] || 0) + (targets['pcc-ten-points-frangible'] || 0))) && (
                                        <Typography variant='caption'>{t('rounds-points-three-shot')}</Typography>
                                    )}
                                </td>
                            </tr>
                            {!_.isEmpty(bonusTargets) && (
                                <tr>
                                    <td>{t('bonustargets')}</td>
                                    <td>{bonusTargets.map((s) => `T${s + 1}`).join(', ')}</td>
                                </tr>
                            )}
                            <tr>
                                <td>{t('startposition')}</td>
                                <td>{startPosition}</td>
                            </tr>
                            <tr>
                                <td>{t('guncondition')}</td>
                                <td>{gunCondition}</td>
                            </tr>
                            <tr>
                                <td>{t('pcccondition')}</td>
                                <td>{pccCondition}</td>
                            </tr>
                            <tr>
                                <td>{t('start')}</td>
                                <td>{start}</td>
                            </tr>
                            <tr>
                                <td>{t('procedure')}</td>
                                <td>{procedure}</td>
                            </tr>
                            {end && (
                                <tr>
                                    <td>{t('end')}</td>
                                    <td>{end}</td>
                                </tr>
                            )}
                            <tr>
                                <td>{t('comments')}</td>
                                <td>{comments}</td>
                            </tr>
                        </tbody>
                    </table>
                </Typography>
            </div>
        );
    }

    renderNewStage(index) {
        const { t, url } = this.props;
        const ButtonLink = (props) => <Link to={`${url}/new`} {...props} />;
        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <div style={{ opacity: 0.1 }}>
                    <Typography variant='h6'>
                        {t('stage-number', { number: index })}
                    </Typography>
                </div>
                <Paper
                    style={{
                        padding: '24px', background: 'rgba(255, 255, 255, 0.1)', marginTop: '12px', flex: 1,
                    }}
                    classes={{ root: 'stages-stage-paper' }}
                >
                    <div style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%',
                    }}
                    >
                        <Button component={ButtonLink} variant='fab' color='primary'>
                            <AddIcon />
                        </Button>
                    </div>
                </Paper>
            </div>
        );
    }

    render() {
        const { stages } = this.state;
        const { t, manage } = this.props;

        if (!stages) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <CircularProgress size={75} />
                </div>
            );
        }

        const $stages = manage ? [...stages, null] : [...stages];

        return (
            <div>
                {!manage && (
                    <div className='noprint' style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        <Tooltip title={t('print')}>
                            <IconButton onClick={this.print}>
                                <i className='fas fa-print' style={{ fontSize: '24px' }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}

                <div className='noprint'>
                    <Grid container spacing={2}>
                        {$stages.map((stage, stageIdx) => (
                            <Grid key={stage ? stage.id : 'new'} item xs={6} md={4} lg={3}>
                                {this.renderStage(stage, stageIdx + 1)}
                            </Grid>
                        ))}
                    </Grid>
                </div>

                <div className='onlyprint' style={{ width: '100%', background: 'white' }}>
                    {$stages.map((stage, stageIdx) => stage !== null && (
                        <div key={stage.id} style={{ pageBreakBefore: 'always' }}>
                            {this.renderInternal(stage, stageIdx + 1)}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

StagesInner.propTypes = {
    t: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
    manage: PropTypes.bool,
    setTitle: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func,
    setSaveButtonCallback: PropTypes.func,
};

StagesInner.defaultProps = {
    manage: false,
    setExtraButtons: null,
    setSaveButtonCallback: null,
};

export default withTranslation('stages')(Stages);
/* eslint-enable max-classes-per-file */
/* eslint-enable react/no-multi-comp */
