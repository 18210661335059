/* eslint-disable no-self-assign */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import _ from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { scoreToStr } from './Utils';
import api from '../Api';

class ExtractHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screen: 0,
        };
    }

    async componentDidMount() {
        const {
            t, auth, match, history, setTitle, setExtraButtons, setSaveButtonCallback,
        } = this.props;

        setTitle(t('title', { title: match.title }));
        setExtraButtons([
            <Button style={{ minWidth: '100px' }} key='back' variant='contained' color='primary' onClick={() => history.goBack()}>
                {t('generic:back')}
            </Button>,
        ]);
        setSaveButtonCallback(null);

        (async () => {
            const shooters = await api.getShooters({ matchId: match.id, auth }) || [];
            this.setState({ shooters });
        })();

        (async () => {
            const $match = await api.getMatch({ matchId: match.id });
            const stages = await api.getStages({ stages: $match.stages });
            this.setState({ stages });
        })();
    }

    render() {
        const {
            t, classes, match,
        } = this.props;
        const {
            shooters, stages, screen,
        } = this.state;
        const approveStorage = JSON.parse(window.localStorage.getItem('approve-backup') || '[]');
        const actionStorage = JSON.parse(window.localStorage.getItem('scoring-action-backup') || '[]');

        if ((!shooters) || (!stages)) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <CircularProgress size={75} />
                </div>
            );
        }

        return (
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <div style={{ width: '90%', maxWidth: '720px' }}>
                    <Tabs value={screen} onChange={(e, v) => this.setState({ screen: v })}>
                        <Tab label='Approval Screen' />
                        <Tab label='Clicks' />
                        <Tab label='Clear' />
                    </Tabs>
                    {screen === 0 && (
                        <div style={{ marginTop: '24px' }}>
                            {approveStorage.map((s) => {
                                const s2 = s;
                                if ((s2.matchId || '').toString() !== match.id.toString()) return null;
                                s2.scorePerTarget = _.map(s2.scorePerTarget, (a) => a); // {} => []. [] => [].
                                return (
                                    <div key={s2.timestamp} className={classes.row}>
                                        <Typography variant='h6'>{shooters.find((_s) => _s.id === s2.shooterId).name}</Typography>
                                        <Typography variant='caption'>{moment(s2.timestamp).locale(match.locale.substr(0, 2)).format('LLL')}</Typography>
                                        <Typography variant='body1'>{scoreToStr({ t, score: s2, stage: stages[s2.stageIdx - 1] })}</Typography>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {screen === 1 && (
                        <div style={{ marginTop: '24px' }}>
                            {actionStorage.map((s, i) => {
                                const [stage, shooterId, action, value] = s;
                                const shooter = shooters.find((_s) => _s.id === shooterId);
                                if (!shooter) return null;
                                const key = i;
                                return (
                                    <div key={key} className={classes.row} style={{ display: 'flex', fontSize: '0.75em' }}>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ fontWeight: 800 }}>Stage&nbsp;</div>
                                            <div>
                                                {stage}
                                                &nbsp;
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ fontWeight: 800 }}>
                                                Shooter
                                                &nbsp;
                                            </div>
                                            <div>
                                                {shooter.name}
                                                &nbsp;
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ fontWeight: 800 }}>
                                                {action}
                                                &nbsp;
                                            </div>
                                            <div>{value}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {screen === 2 && (
                        <div style={{ marginTop: '60px' }}>
                            <Button
                                variant='contained'
                                color='secondary'
                                fullWidth
                                onClick={() => {
                                    window.localStorage.setItem('scoring-action-backup', JSON.stringify([]));
                                    window.localStorage.setItem('approve-backup', JSON.stringify([]));
                                    window.location.href = window.location.href;
                                }}
                            >
                                Clear All History
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const styles = () => ({
    row: {
        marginBottom: '12px',
    },
});

ExtractHistory.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    setTitle: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    i18n: PropTypes.shape({}).isRequired,
    auth: PropTypes.string.isRequired,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        locale: PropTypes.string.isRequired,
    }).isRequired,
};

export default withStyles(styles, { withTheme: true })(withTranslation('extracthistory')(ExtractHistory));
/* eslint-enable no-self-assign */
