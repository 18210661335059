/* eslint-disable react/no-array-index-key,react/forbid-prop-types */
import React, { useEffect, useState, useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Route } from 'react-router-dom';
import {
    XYChart, CrossHair, XAxis, YAxis, LineSeries, WithTooltip,
} from '@data-ui/xy-chart';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import yellow from '@material-ui/core/colors/yellow';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import api from '../Api';
import ScoreTable from './ScoreTable';
import ShootersAutosuggest from './ShootersAutosuggest';
import { numShotsPerPaper, getScoresWithPoints, pad } from './Utils';

export default function CompareShooters(props) {
    const [whatIfScores, setWhatIfScores] = useState([]);
    const [shooter1, setShooter1] = useState(null);
    const [shooter2, setShooter2] = useState(null);
    const [whatIf, setWhatIf] = useState({});

    return (
        <div>
            <Route
                path={['/:matchId/shooter-stats', '/:matchId/shooter-stats/shooter/:shooterId', '/:matchId/compare-shooters']}
                exact
                render={({ history, match }) => (
                    <CompareShootersInner
                        {...props}
                        history={history}
                        whatIfScores={whatIfScores}
                        setWhatIfScores={setWhatIfScores}
                        shooter1={shooter1}
                        shooter2={shooter2}
                        setShooter1={setShooter1}
                        setShooter2={setShooter2}
                        forceShooter={match.params.shooterId}
                        whatIf={whatIf}
                        setWhatIf={setWhatIf}
                    />
                )}
            />
            <Route
                path='/:matchId/shooter-stats/score-table/:whatIf'
                exact
                render={({ match }) => {
                    const $whatIf = JSON.parse(decodeURIComponent(atob(match.params.whatIf)));
                    return (
                        <ScoreTable {...props} whatIf={$whatIf} />
                    );
                }}
            />
        </div>
    );
}


function $CompareShootersInner(props) {
    const {
        t, classes, match, single, history,
        whatIfScores, setWhatIfScores, whatIf, setWhatIf,
        shooter1, setShooter1, shooter2, setShooter2, forceShooter,
    } = props;
    const { scoreMode } = match;
    const [shooters, setShooters] = useState(null);
    const [scores, setScores] = useState(null);
    const [stages, setStages] = useState(null);
    const [graphsCombined, setGraphsCombined] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        (async () => {
            const [$shooters, $scores, $stages] = await Promise.all([
                api.getShooters({ matchId: match.id }),
                api.getScores({ matchId: match.id }),
                api.getStages({ stages: match.stages }),
            ]);
            setShooters($shooters);
            setStages($stages);
            setScores($scores);

            if (forceShooter) {
                setShooter2($shooters.find((s) => s.id === forceShooter));
            }
        })();
    }, []);

    const {
        scoresByStage,
        scoresOverall,
        combinedScoresOverall,
        whatIfScoresByStage,
        whatIfScoresOverall,
        whatIfCombinedScoresOverall,
        dqs,
    } = useMemo(() => {
        if (!scores || !shooters) {
            return {};
        }
        const data = getScoresWithPoints({
            scores, shooters, stages, mode: match.scoreMode,
        });
        const data2 = getScoresWithPoints({
            scores, shooters, stages, combined: true, mode: match.scoreMode,
        });

        const whatIfShooters = !whatIf.powerFactor
            ? shooters
            : shooters.map((s) => {
                if (s.id !== shooter2.id) return s;
                return { ...s, 'power-factor': whatIf.powerFactor };
            });

        const data3 = _.isEmpty(whatIfScores) && !whatIf.powerFactor ? {} : getScoresWithPoints({
            scores: [...scores, ...whatIfScores], shooters: whatIfShooters, stages, mode: match.scoreMode,
        });
        const data4 = _.isEmpty(whatIfScores) && !whatIf.powerFactor ? {} : getScoresWithPoints({
            scores: [...scores, ...whatIfScores], shooters: whatIfShooters, stages, combined: true, mode: match.scoreMode,
        });
        return {
            scoresByStage: data.scoresByStage,
            scoresOverall: data.scoresOverall,
            combinedScoresOverall: data2.scoresOverall,
            dqs: data.dqs,
            whatIfScoresByStage: data3.scoresByStage,
            whatIfScoresOverall: data3.scoresOverall,
            whatIfCombinedScoresOverall: data4.scoresOverall,
        };
    }, [scores, whatIfScores, whatIf.powerFactor]);

    if (!scores || !shooters) {
        return (
            <div className={classes.wrapper} style={{ textAlign: 'center' }}>
                <CircularProgress size={72} />
            </div>
        );
    }

    const data = _.compact([
        [(s) => (s ? t(`generic:division-${s.division}`) : ''), t('generic:shooter_division')],
        [(s) => (s ? t(`registration:category-${s.category}`) : ''), t('generic:shooter_category')],
        [(s) => {
            if (!s) return '';

            const myPF = t(`registration:${s['power-factor']}`);
            const base = <span>{myPF}</span>;

            if (!single) {
                return base;
            }

            const inversePFValue = s['power-factor'] === 'major' ? 'minor' : 'major';
            const inversePF = t(`registration:${inversePFValue}`);

            if (whatIf.powerFactor) {
                return (
                    <span>
                        <StrikeThroughNewValue value={myPF} newValue={inversePF} />
                        &nbsp;
                        &nbsp;
                        <IconButton color='primary' onClick={() => setWhatIf({ ...whatIf, powerFactor: null })}>
                            <i style={{ fontSize: '12px' }} className='fas fa-times-circle' />
                        </IconButton>
                    </span>
                );
            }

            if ((s.division === 'standard') || (s.division === 'classic') || (s.divison === 'open')) {
                return (
                    <div>
                        {base}
                        &nbsp;
                        &nbsp;
                        <Button
                            color='primary'
                            variant='text'
                            style={{ padding: '0px', textTransform: 'none', minWidth: '0px' }}
                            onClick={() => setWhatIf({ ...whatIf, powerFactor: inversePFValue })}
                        >
                            <span>
                                {t('whatifminormajor', { other: inversePF })}
                            </span>
                            &nbsp;
                            &nbsp;
                            <Tooltip
                                title={(
                                    <Typography variant='caption' style={{ color: 'inherit' }}>
                                        {t('whatifdescription')}
                                    </Typography>
                                )}
                            >
                                <span>
                                    <i className='fas fa-question-circle' />
                                </span>
                            </Tooltip>
                        </Button>
                    </div>
                );
            }
            return base;
        }, t('generic:shooter_power_factor')],
        [(s) => {
            if (!s) return '';
            if (dqs.find((d) => d.shooterId === s.id)) return 'DQ';
            const score = scoresOverall.find((o) => o.shooterId === s.id);
            const ret = `${pad(score.percentage / 100, 2)}%`;
            if (whatIfScoresOverall) {
                const score2 = whatIfScoresOverall.find((o) => o.shooterId === s.id);
                const ret2 = `${pad(score2.percentage / 100, 2)}%`;
                return <StrikeThroughNewValue value={ret} newValue={ret2} />;
            }
            return ret;
        }, t('percentageindivision')],
        [(s) => {
            if (!s) return '';
            if (dqs.find((d) => d.shooterId === s.id)) return 'DQ';
            const score = combinedScoresOverall.find((o) => o.shooterId === s.id);
            const ret = `${pad(score.percentage / 100, 2)}%`;
            if (whatIfCombinedScoresOverall) {
                const score2 = whatIfCombinedScoresOverall.find((o) => o.shooterId === s.id);
                const ret2 = `${pad(score2.percentage / 100, 2)}%`;
                return <StrikeThroughNewValue value={ret} newValue={ret2} />;
            }
            return ret;
        }, t('combinedpercentageoverall')],
        [(s) => {
            if (!s) return '';
            if (dqs.find((d) => d.shooterId === s.id)) return 'DQ';
            const ret = renderScoreDistribution(s, scoresByStage, scoreMode);
            if (whatIfScoresByStage) {
                return (
                    <div>
                        <div style={{ opacity: 0.2 }}>
                            {ret}
                        </div>
                        <div>
                            {renderScoreDistribution(s, whatIfScoresByStage, scoreMode)}
                        </div>
                    </div>
                );
            }
            return ret;
        }, t('scoredistribution')],
        [(s) => {
            if (!s) return '';
            if (dqs.find((d) => d.shooterId === s.id)) return 'DQ';
            const [ret, ret2] = [scoresByStage, whatIfScoresByStage].map((byStage) => {
                if (!byStage) return null;
                return _.sum(_.compact(byStage.map(
                    (stageScore) => (stageScore.find((s2) => s2.shooterId === s.id) || {}).noShoots,
                ))) || 0;
            });
            return <StrikeThroughNewValue value={ret} newValue={ret2} />;
        }, t('generic:no-shoots')],
        [(s) => {
            if (!s) return '';
            if (dqs.find((d) => d.shooterId === s.id)) return 'DQ';
            const [ret, ret2] = [scoresByStage, whatIfScoresByStage].map((byStage) => {
                if (!byStage) return null;
                return _.sum(_.compact(byStage.map(
                    (stageScore) => (stageScore.find((s2) => s2.shooterId === s.id) || {}).penalties,
                ))) || 0;
            });
            return <StrikeThroughNewValue value={ret} newValue={ret2} />;
        }, t('generic:penalties')],
        [(s) => {
            if (!s) return '';
            if (dqs.find((d) => d.shooterId === s.id)) return 'DQ';
            const [ret, ret2] = [scoresByStage, whatIfScoresByStage].map((byStage) => {
                if (!byStage) return null;
                const accuracies = _.compact(byStage.map((stageScore) => {
                    const best = _.maxBy(stageScore, ($s) => $s.total);
                    if (!best) return null;
                    const shooter = stageScore.find((s2) => s2.shooterId === s.id);
                    if (!shooter) return null;
                    const val = shooter.alphas * 10 + shooter.charlies * 8 + shooter.deltas * 3;
                    const bestVal = best.alphas * 10 + best.charlies * 8 + best.deltas * 3;
                    return val / bestVal;
                }));
                if (accuracies.length === 0) return '';
                return Math.floor(_.mean(accuracies) * 100);
            });
            return <StrikeThroughNewValue value={ret} newValue={ret2} />;
        }, t('accuracyscore'), t('accurayscoremoreinfo')],
        [(s) => {
            if (!s) return '';
            if (dqs.find((d) => d.shooterId === s.id)) return 'DQ';
            const [ret, ret2] = [scoresByStage, whatIfScoresByStage].map((byStage) => {
                if (!byStage) return null;
                const speed = _.compact(byStage.map((stageScore) => {
                    const best = _.minBy(stageScore, ($s) => ($s.time || Number.MAX_VALUE));
                    if (!best) return null;
                    const worst = _.maxBy(stageScore, ($s) => ($s.time || 0));
                    const shooter = stageScore.find((s2) => s2.shooterId === s.id);
                    if (!shooter) return null;
                    return (worst.time - shooter.time) / (worst.time - best.time);
                }));
                if (speed.length === 0) return '';
                return Math.floor(_.mean(speed) * 100);
            });
            return <StrikeThroughNewValue value={ret} newValue={ret2} />;
        }, t('timescore'), t('timescoremoreinfo')],
        ..._.compact(_.flatten(stages.map((stage, stageIndex) => (stage.inactive ? null : [
            [
                () => '', <b>{`${t('generic:stage_number')}${stageIndex + 1}`}</b>,
            ], [
                (s) => {
                    if (!s) return '';
                    const stageScore = scoresByStage[stageIndex] || [];
                    const whatIfStageScore = whatIfScoresByStage ? whatIfScoresByStage[stageIndex] || [] : [];
                    const shooter = stageScore.find((s2) => s2.shooterId === s.id);
                    const whatIfShooter = whatIfStageScore.find((s2) => s2.shooterId === s.id) || {};
                    if (!shooter) return '';
                    return (
                        <div>
                            <div>
                                <b>
                                    {`${t('generic:result_factor')} `}
                                </b>
                                <StrikeThroughNewValue value={shooter.factorStr} newValue={whatIfShooter.factorStr} />
                            </div>
                            <div>
                                <Typography variant='caption' style={{ opacity: 0.6 }}>
                                    <span>
                                        <b>
                                            {`${t('score:time')} `}
                                        </b>
                                        <StrikeThroughNewValue value={shooter.timeStr} newValue={whatIfShooter.timeStr} />
                                    </span>
                                </Typography>
                            </div>
                            <div>
                                <Typography variant='caption' style={{ opacity: 0.6 }}>
                                    <span>
                                        <b>
                                            {`${t('generic:result_total')} `}
                                        </b>
                                        <StrikeThroughNewValue value={shooter.total} newValue={whatIfShooter.total} />
                                    </span>
                                </Typography>
                            </div>
                        </div>
                    );
                },
                t('stageresults'),
            ], [
                (s) => {
                    if (!s) return '';
                    if (dqs.find((d) => d.shooterId === s.id)) return 'DQ';
                    const ret = renderScoreDistribution(s, [scoresByStage[stageIndex]], scoreMode);
                    if (whatIfScoresByStage) {
                        return (
                            <div>
                                <div style={{ opacity: 0.2 }}>
                                    {ret}
                                </div>
                                <div>
                                    {renderScoreDistribution(s, [whatIfScoresByStage[stageIndex]], scoreMode)}
                                </div>
                            </div>
                        );
                    }
                    return ret;
                },
                t('scoredistribution'),
            ], [
                (s) => {
                    if (!s) return '';
                    if (dqs.find((d) => d.shooterId === s.id)) return 'DQ';
                    const latestScore = scoresByStage[stageIndex].find(($s) => $s.shooterId === s.id);
                    const whatIfLatestScore = ((whatIfScoresByStage || [])[stageIndex] || []).find(($s) => $s.shooterId === s.id);
                    if (!latestScore && !whatIfScoresByStage) return '';
                    return <StrikeThroughNewValue value={(latestScore || {}).noShoots || 0} newValue={(whatIfLatestScore || {}).noShoots} />;
                },
                t('generic:no-shoots'),
            ], [
                (s) => {
                    if (!s) return '';
                    if (dqs.find((d) => d.shooterId === s.id)) return 'DQ';
                    const latestScore = scoresByStage[stageIndex].find(($s) => $s.shooterId === s.id);
                    const whatIfLatestScore = ((whatIfScoresByStage || [])[stageIndex] || []).find(($s) => $s.shooterId === s.id);
                    if (!latestScore && !whatIfScoresByStage) return '';
                    return <StrikeThroughNewValue value={(latestScore || {}).penalties || 0} newValue={(whatIfLatestScore || {}).penalties} />;
                },
                t('generic:penalties'),
            ], (!single || !shooter2) ? null : [
                (s) => {
                    if (!s) return null;
                    if (dqs.find((d) => d.shooterId === s.id)) return 'DQ';
                    if (whatIf[stageIndex]) {
                        const latestScore = (whatIfScoresByStage || scoresByStage)[stageIndex].find(($s) => $s.shooterId === s.id) || { timestamp: 0 };
                        const orgScore = scores.find(($s) => (($s.shooterId === s.id) && ($s.timestamp === latestScore.timestamp)));
                        const whatIfScore = whatIfScores.find(($s) => (($s.shooterId === s.id) && ($s.stageIdx === stageIndex + 1))) || orgScore || {
                            shooterId: s.id,
                            stageIdx: stageIndex + 1,
                        };
                        return (
                            <WhatIf
                                t={t}
                                scoreMode={scoreMode}
                                stageIndex={stageIndex}
                                stage={stages[stageIndex]}
                                score={whatIfScore}
                                onReset={() => {
                                    setWhatIfScores(whatIfScores.filter(($s) => ($s.stageIdx !== stageIndex + 1 || $s.shooterId !== s.id)));
                                }}
                                onCancel={() => {
                                    setWhatIf({ ...whatIf, [stageIndex]: false });
                                }}
                                onChangeScore={(change) => {
                                    setWhatIfScores([
                                        ...whatIfScores.filter(($s) => ($s.stageIdx !== stageIndex + 1 || $s.shooterId !== s.id)),
                                        { ...whatIfScore, ...change, timestamp: whatIfScore.timestamp + 1 },
                                    ]);
                                }}
                            />
                        );
                    }
                    return null;
                },
                whatIf[stageIndex] ? (
                    <span>
                        {t('whatif')}
                        &nbsp;
                        &nbsp;
                        <Tooltip
                            title={(
                                <Typography variant='caption' style={{ color: 'inherit' }}>
                                    {t('whatifdescription')}
                                </Typography>
                            )}
                        >
                            <span>
                                <i className='fas fa-question-circle' />
                            </span>
                        </Tooltip>
                    </span>
                ) : (
                    <Button
                        color='primary'
                        variant='text'
                        style={{ padding: '0px', textTransform: 'none', minWidth: '0px' }}
                        onClick={() => setWhatIf({ ...whatIf, [stageIndex]: true })}
                    >
                        {t('whatif')}
                        &nbsp;
                        &nbsp;
                        <Tooltip
                            title={(
                                <Typography variant='caption' style={{ color: 'inherit' }}>
                                    {t('whatifdescription')}
                                </Typography>
                            )}
                        >
                            <span>
                                <i className='fas fa-question-circle' />
                            </span>
                        </Tooltip>
                    </Button>
                ),
            ],
        ])))),
    ]);

    const filterDivision = (shooter1 && shooter2) ? ((shooter1.division === shooter2.division) && !graphsCombined) : graphsCombined;
    const factorDataSets = _.compact([shooter1, shooter2].map((shooter) => (!shooter ? null : scoresByStage.map((stageScore) => {
        const ss = filterDivision ? stageScore.filter(($s) => $s.division === shooter.division) : stageScore;
        const best = _.maxBy(ss, ($s) => $s.factor);
        if (!best) return 0;
        const d = ss.find((s2) => s2.shooterId === shooter.id);
        if (!d) return 0;
        return (d.factor * 100) / best.factor;
    }))));

    const whatIfFactorDataSets = !whatIfScoresByStage
        ? null
        : _.compact([shooter1, shooter2].map((shooter) => (!shooter ? null : whatIfScoresByStage.map((stageScore) => {
            const ss = filterDivision ? stageScore.filter(($s) => $s.division === shooter.division) : stageScore;
            const best = _.maxBy(ss, ($s) => $s.factor);
            if (!best) return 0;
            const d = ss.find((s2) => s2.shooterId === shooter.id);
            if (!d) return 0;
            return (d.factor * 100) / best.factor;
        }))));

    const accuracyDataSets = _.compact([shooter1, shooter2].map((shooter) => (!shooter ? null : scoresByStage.map((stageScore) => {
        const ss = filterDivision ? stageScore.filter(($s) => $s.division === shooter.division) : stageScore;
        const best = _.maxBy(ss, ($s) => $s.total);
        if (!best) return 0;
        const d = ss.find((s2) => s2.shooterId === shooter.id);
        if (!d) return 0;
        return (d.total * 100) / best.total;
    }))));

    const whatIfAccuracyDataSets = !whatIfScoresByStage
        ? null
        : _.compact([shooter1, shooter2].map((shooter) => (!shooter ? null : whatIfScoresByStage.map((stageScore) => {
            const ss = filterDivision ? stageScore.filter(($s) => $s.division === shooter.division) : stageScore;
            const best = _.maxBy(ss, ($s) => $s.total);
            if (!best) return 0;
            const d = ss.find((s2) => s2.shooterId === shooter.id);
            if (!d) return 0;
            return (d.total * 100) / best.total;
        }))));

    const timeDataSets = _.compact([shooter1, shooter2].map((shooter) => (!shooter ? null : scoresByStage.map((stageScore) => {
        const ss = filterDivision ? stageScore.filter(($s) => $s.division === shooter.division) : stageScore;
        const best = _.minBy(ss, ($s) => ($s.time || Number.MAX_VALUE));
        if (!best) return 0;
        const worst = _.maxBy(ss, ($s) => ($s.time || 0));
        const d = ss.find((s2) => s2.shooterId === shooter.id);
        if (!d) return 0;
        return ((worst.time - d.time) * 100) / (worst.time - best.time);
    }))));

    const whatIfTimeDataSets = !whatIfScoresByStage
        ? null
        : _.compact([shooter1, shooter2].map((shooter) => (!shooter ? null : whatIfScoresByStage.map((stageScore) => {
            const ss = filterDivision ? stageScore.filter(($s) => $s.division === shooter.division) : stageScore;
            const best = _.minBy(ss, ($s) => ($s.time || Number.MAX_VALUE));
            if (!best) return 0;
            const worst = _.maxBy(ss, ($s) => ($s.time || 0));
            const d = ss.find((s2) => s2.shooterId === shooter.id);
            if (!d) return 0;
            return ((worst.time - d.time) * 100) / (worst.time - best.time);
        }))));

    return (
        <div className={classes.wrapper}>
            <Table padding='checkbox'>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ display: single ? 'none' : 'flex', padding: '0px' }}>
                            <div style={{ flex: 1 }}>
                                <ShootersAutosuggest
                                    shooters={shooters}
                                    name={shooter1 ? shooter1.name : null}
                                    t={t}
                                    onSelect={(e) => setShooter1(e)}
                                />
                            </div>
                            <IconButton color='secondary' disabled={!shooter1} onClick={() => setShooter1(null)}>
                                <i style={{ fontSize: '12px' }} className='fas fa-times-circle' />
                            </IconButton>
                        </TableCell>
                        {!single && <TableCell />}
                        <TableCell colSpan={single ? 2 : 1} style={single ? {} : { display: 'flex', padding: '0px' }}>
                            <div style={single ? { display: 'inline-block', width: 'calc(100% - 40px)' } : { flex: 1 }}>
                                <ShootersAutosuggest
                                    shooters={shooters}
                                    name={shooter2 ? shooter2.name : null}
                                    t={t}
                                    onSelect={(e) => setShooter2(e)}
                                />
                            </div>
                            <IconButton color='secondary' disabled={!shooter2} onClick={() => setShooter2(null)}>
                                <i style={{ fontSize: '12px' }} className='fas fa-times-circle' />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(([func, title, info], index) => (
                        <TableRow key={`${title}${index}`}>
                            <TableCell style={{ display: single ? 'none' : '', textAlign: 'end' }}>
                                {func(shooter1)}
                            </TableCell>
                            <TableCell style={{ textAlign: single ? 'start' : 'center' }}>
                                <div>
                                    <span>
                                        {title}
                                    </span>
                                    &nbsp;
                                    {info && (
                                        <Tooltip
                                            title={(
                                                <div>
                                                    <Typography variant='caption' style={{ color: 'inherit' }}>
                                                        <b>{t('moreinfotitle')}</b>
                                                    </Typography>
                                                    <Typography variant='caption' style={{ color: 'inherit' }}>
                                                        {info}
                                                    </Typography>
                                                </div>
                                            )}
                                        >
                                            <span>
                                                <i className='fas fa-question-circle' />
                                            </span>
                                        </Tooltip>
                                    )}
                                </div>
                            </TableCell>
                            <TableCell>
                                {func(shooter2)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Typography variant='h5' style={{ textAlign: 'center', margin: '24px 0px 6px 24px' }}>
                {t('graphtitle')}
            </Typography>
            <FormControlLabel
                style={{ width: '100%' }}
                control={(
                    <Checkbox
                        checked={!filterDivision}
                        onChange={() => setGraphsCombined(!graphsCombined)}
                        disabled={(shooter1 && shooter2 && (shooter1.division !== shooter2.division))}
                    />
                )}
                label={(
                    <Typography variant='body2'>
                        {t('graphscombined')}
                    </Typography>
                )}
            />
            <Typography variant='h6' style={{ textAlign: 'center' }}>
                {t('graphfactor')}
            </Typography>
            <Typography variant='body2' style={{ textAlign: 'center' }}>
                {t('graphfactordescription')}
            </Typography>
            {whatIfScoresByStage
                ? (
                    <StageGraph
                        t={t}
                        stages={stages}
                        shooters={[shooter2, { ...shooter2, id: 'whatif', name: `${shooter2.name} - ${t('whatif')}` }]}
                        dataSets={[factorDataSets[0], whatIfFactorDataSets[0]]}
                    />
                )
                : <StageGraph t={t} stages={stages} shooters={_.compact([shooter1, shooter2])} dataSets={factorDataSets} />}
            <Typography variant='h6' style={{ textAlign: 'center' }}>
                {t('graphaccuracy')}
            </Typography>
            <Typography variant='body2' style={{ textAlign: 'center' }}>
                {t('graphaccuracydescription')}
            </Typography>
            {whatIfScoresByStage
                ? (
                    <StageGraph
                        t={t}
                        stages={stages}
                        shooters={[shooter2, { ...shooter2, id: 'whatif', name: `${shooter2.name} - ${t('whatif')}` }]}
                        dataSets={[accuracyDataSets[0], whatIfAccuracyDataSets[0]]}
                    />
                )
                : <StageGraph t={t} stages={stages} shooters={_.compact([shooter1, shooter2])} dataSets={accuracyDataSets} />}
            <Typography variant='h6' style={{ textAlign: 'center' }}>
                {t('graphspeed')}
            </Typography>
            <Typography variant='body2' style={{ textAlign: 'center' }}>
                {t('graphspeeddescription')}
            </Typography>
            {whatIfScoresByStage
                ? (
                    <StageGraph
                        t={t}
                        stages={stages}
                        shooters={[shooter2, { ...shooter2, id: 'whatif', name: `${shooter2.name} - ${t('whatif')}` }]}
                        dataSets={[timeDataSets[0], whatIfTimeDataSets[0]]}
                    />
                )
                : <StageGraph t={t} stages={stages} shooters={_.compact([shooter1, shooter2])} dataSets={timeDataSets} />}
            {whatIfScoresByStage && (
                <div style={{ marginTop: '40px' }}>
                    <div
                        style={{
                            position: 'fixed',
                            bottom: '0px',
                            left: '0px',
                            width: 'calc(100% - 24px)',
                            minHeight: '20px',
                            textAlign: 'start',
                            padding: '12px',
                            backgroundColor: red[500],
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            zIndex: '2',
                        }}
                    >
                        <Typography variant='caption' style={{ color: 'inherit' }}>
                            {t('whatifmoremessage')}
                        </Typography>
                        &nbsp;
                        <Typography
                            variant='caption'
                            style={{
                                color: 'white',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                            onClick={() => history.push(`/${match.alias}/shooter-stats/score-table/${btoa(encodeURIComponent(JSON.stringify({ scores: whatIfScores, shooterId: shooter2.id, powerFactor: whatIf.powerFactor })))}`)}
                        >
                            {t('whatiftoscores')}
                        </Typography>
                        <Typography variant='caption' style={{ color: 'inherit' }}>
                            .
                        </Typography>
                    </div>
                </div>
            )}
        </div>
    );
}

$CompareShootersInner.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    match: PropTypes.shape({
        alias: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        stages: PropTypes.arrayOf(PropTypes.string),
        scoreMode: PropTypes.string.isRequired,
    }).isRequired,
    single: PropTypes.bool.isRequired,
    whatIf: PropTypes.shape({
        powerFactor: PropTypes.string,
    }),
    whatIfScores: PropTypes.arrayOf(PropTypes.shape({})),
    shooter1: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        division: PropTypes.string,
    }),
    shooter2: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        division: PropTypes.string,
    }),
    setWhatIf: PropTypes.func.isRequired,
    setWhatIfScores: PropTypes.func.isRequired,
    setShooter1: PropTypes.func.isRequired,
    setShooter2: PropTypes.func.isRequired,
    forceShooter: PropTypes.string,
};

$CompareShootersInner.defaultProps = {
    whatIf: null,
    whatIfScores: null,
    shooter1: null,
    shooter2: null,
    forceShooter: null,
};

const styles = () => ({
    wrapper: {
        width: '90%',
        maxWidth: '720px',
        margin: 'auto',
        marginTop: '24px',
        textAlign: 'left',
    },
});

const CompareShootersInner = withStyles(styles, { withTheme: true })(withTranslation('compareshooters')($CompareShootersInner));

function renderScoreDistribution(s, scoresByStage, scoreMode) {
    const shooterScores = _.compact(scoresByStage.map((stageScore) => stageScore.find((s2) => s2.shooterId === s.id)));
    let alphas = 0;
    let charlies = 0;
    let deltas = 0;
    let misses = 0;
    shooterScores.forEach((score) => {
        alphas += score.alphas || 0;
        charlies += score.charlies || 0;
        deltas += score.deltas || 0;
        misses += score.misses || 0;
    });
    const total = alphas + charlies + deltas + misses;

    if (total === 0) return null;

    return (
        <div>
            <div style={{ width: '100%', height: '20px', display: 'flex' }}>
                <div style={{ width: `${Math.floor((alphas * 10000) / total) / 100}%`, backgroundColor: green[500], height: '20px' }} />
                <div style={{ width: `${Math.floor((charlies * 10000) / total) / 100}%`, backgroundColor: yellow[500], height: '20px' }} />
                <div style={{ width: `${Math.floor((deltas * 10000) / total) / 100}%`, backgroundColor: grey[500], height: '20px' }} />
                <div style={{ width: `${Math.floor((misses * 10000) / total) / 100}%`, backgroundColor: red[500], height: '20px' }} />
            </div>
            <Typography variant='caption' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ borderBottom: `2px solid ${green[500]}` }}>
                    A
                    <b>
                        {Math.floor((alphas * 10000) / total) / 100}
                        %
                    </b>
                </span>
                <span style={{ borderBottom: `2px solid ${yellow[500]}` }}>
                    {scoreMode === 'comstock-abd' ? 'B' : 'C'}
                    <b>
                        {Math.floor((charlies * 10000) / total) / 100}
                        %
                    </b>
                </span>
                <span style={{ borderBottom: `2px solid ${grey[500]}` }}>
                    D
                    <b>
                        {Math.floor((deltas * 10000) / total) / 100}
                        %
                    </b>
                </span>
                <span style={{ borderBottom: `2px solid ${red[500]}` }}>
                    M
                    <b>
                        {Math.floor((misses * 10000) / total) / 100}
                        %
                    </b>
                </span>
            </Typography>
        </div>
    );
}

function StageGraph(props) {
    const {
        t, dataSets, stages, shooters,
    } = props;

    const colors = [green[500], blue[500]];
    const filteredDataSets = dataSets.map((d) => d.filter((x, index) => !stages[index].inactive));
    const stageNumbers = _.range(stages.length).filter((i) => !stages[i].inactive).map((i) => i + 1);

    const renderTooltip = ({ datum }) => (
        <div style={{ direction: 'ltr' }}>
            <strong>
                {`${t('generic:stage_number')}${stageNumbers[datum.x]}`}
            </strong>
            <br />
            <br />
            {shooters.map((shooter, i) => (
                <div key={shooter.id} style={{ color: colors[i] }}>
                    {`${shooter.name}: `}
                    <b>
                        {pad(filteredDataSets[i][datum.x], 2)}
                    </b>
                </div>
            ))}
        </div>
    );
    renderTooltip.propTypes = {
        datum: PropTypes.shape({
            x: PropTypes.number,
        }).isRequired,
    };

    return (
        <div style={{ position: 'relative' }}>
            <div
                style={{
                    display: 'flex',
                    position: 'absolute',
                    top: '42px',
                    left: '64px',
                }}
            >
                {shooters.map((shooter, i) => (
                    <Typography key={shooter.id} variant='caption' style={{ color: colors[i] }}>
                        {shooter.name}
                        &nbsp;
                        &nbsp;
                        &nbsp;
                    </Typography>
                ))}
            </div>
            <WithTooltip renderTooltip={renderTooltip}>
                {({ onMouseLeave, onMouseMove, tooltipData }) => (
                    <XYChart
                        ariaLabel='graph'
                        width={720}
                        height={400}
                        xScale={{ type: 'linear' }}
                        yScale={{ type: 'linear' }}
                        tooltipData={tooltipData}
                        renderTooltip={null}
                        snapTooltipToDataX
                        onMouseMove={onMouseMove}
                        onMouseLeave={onMouseLeave}
                        eventTrigger='container'
                    >
                        <XAxis
                            tickFormat={(tick, tickIndex) => tickIndex + 1}
                            label={t('generic:stage_number')}
                        />
                        <YAxis label='' numTicks={10} orientation='left' />
                        {shooters.map((shooter, i) => (
                            <LineSeries
                                key={shooter.id}
                                seriesKey={shooter.name}
                                data={filteredDataSets[i].map((y, x) => ({
                                    y, x, color: colors[i],
                                }))}
                                stroke={colors[i]}
                                strokeDasharray=''
                                dashType='solid'
                                strokeWidth={2}
                            />
                        ))}
                        <CrossHair
                            fullHeight
                            showHorizontalLine={false}
                            strokeDasharray=''
                            circleSize={(d) => (d.y === tooltipData.datum.y ? 6 : 4)}
                            circleStroke={(d) => (d.y === tooltipData.datum.y ? '#fff' : d.color)}
                            circleStyles={{ strokeWidth: 1.5 }}
                            circleFill={(d) => (d.y === tooltipData.datum.y ? d.color : '#fff')}
                            showCircle
                            showMultipleCircles
                        />
                    </XYChart>
                )}
            </WithTooltip>
        </div>
    );
}

StageGraph.propTypes = {
    t: PropTypes.func.isRequired,
    shooters: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    stages: PropTypes.arrayOf(PropTypes.shape({
        inactive: PropTypes.bool,
    })).isRequired,
    dataSets: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
};

function WhatIf(props) {
    const {
        t, stageIndex, score, stage, onCancel, onReset, onChangeScore, scoreMode,
    } = props;

    const [time, setTime] = useState(score.time);

    if (!score) return null;

    const metal = ((stage.targets.poppers || 0) + (stage.targets.plates || 0));
    const paper = (stage.targets.paper || 0) + (stage.targets.minipaper || 0);
    const maxNumShots = numShotsPerPaper({ stage });
    const maxNoShoots = ((stage.targets['paper-no-shoots'] || 0) * 2) + (stage.targets['metal-no-shoots'] || 0);
    const possibleScoresForTarget = (target) => {
        const ONE_SCORE = ((stage.bonusTargets || []).indexOf(target) > -1) ? ['A', 'C', 'D', 'm'] : ['A', 'C', 'D', 'M'];
        if (maxNumShots === 1) return ONE_SCORE;
        if (maxNumShots === 2) return _.flatten(ONE_SCORE.map((s, index) => ONE_SCORE.slice(index).map((s2) => `${s}${s2}`)));
        if (maxNumShots === 3) return _.flatten(ONE_SCORE.map((s, index) => _.flatten(ONE_SCORE.slice(index).map((s2, index2) => ONE_SCORE.slice(index + index2).map((s3) => `${s}${s2}${s3}`)))));
        return null;
    };
    const penalties = _.sum(_.values(score.penaltiesByType));

    return (
        <Paper style={{ padding: '6px', margin: '6px', backgroundColor: red[100] }}>
            <Typography variant='subtitle2'>
                {`${t('generic:stage_number')}${stageIndex + 1} - ${t('whatif')}`}
            </Typography>
            <div>
                <Typography variant='caption'>
                    {t('generic:time')}
                </Typography>
                <Input
                    value={time}
                    onChange={(e) => {
                        setTime(e.target.value);
                    }}
                    onBlur={() => {
                        if (parseFloat(time)) {
                            onChangeScore({ time: parseFloat(time) });
                        }
                    }}
                />
            </div>
            {(metal > 0) && (
                <div>
                    <Typography variant='caption' style={{ marginTop: '6px' }}>
                        {t('generic:poppers')}
                    </Typography>
                    <Select
                        value={score.poppers || null}
                        onChange={(e) => onChangeScore({ poppers: e.target.value })}
                    >
                        {_.range(metal + 1).map((m) => (
                            <MenuItem key={m} value={metal - m}>
                                {t('metals', { hit: metal - m, miss: m })}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            )}
            <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '330px' }}>
                {_.range(paper).map((p) => (
                    <span key={`paper${p}`}>
                        <Typography variant='caption' style={{ marginTop: '6px' }}>
                            {`T${p + 1}`}
                        </Typography>
                        <Select
                            value={(score.scorePerTarget || [])[p] || ''}
                            onChange={(e) => {
                                const newScorePerTarget = JSON.parse(JSON.stringify(score.scorePerTarget || []));
                                newScorePerTarget[p] = e.target.value;
                                onChangeScore({ scorePerTarget: newScorePerTarget });
                            }}
                        >
                            {possibleScoresForTarget(p).map((m) => (
                                <MenuItem key={m} value={m}>
                                    {scoreMode === 'comstock-abd' ? m.replace(/C/g, 'B') : m}
                                </MenuItem>
                            ))}
                        </Select>
                    </span>
                ))}
            </div>
            {maxNoShoots > 0 && (
                <div>
                    <Typography variant='caption' style={{ marginTop: '6px' }}>
                        {t('generic:no-shoots')}
                    </Typography>
                    <Select
                        value={score['no-shoots'] || 0}
                        onChange={(e) => onChangeScore({ 'no-shoots': e.target.value })}
                    >
                        {_.range(maxNoShoots).map((m) => (
                            <MenuItem key={m} value={m}>
                                {m}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            )}
            <div>
                <Typography variant='caption' style={{ marginTop: '6px' }}>
                    {t('generic:penalties')}
                </Typography>
                <Select
                    value={penalties || 0}
                    onChange={(e) => onChangeScore({ penaltiesByType: { general: e.target.value } })}
                >
                    {_.range(20).map((m) => (
                        <MenuItem key={m} value={m}>
                            {m}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <div style={{ display: 'flex', width: '25%', justifyContent: 'space-between' }}>
                <Button
                    color='primary'
                    variant='text'
                    style={{
                        padding: '0px', textTransform: 'none', minWidth: '0px', marginTop: '6px',
                    }}
                    onClick={onReset}
                >
                    {t('reset')}
                </Button>
                <Button
                    color='primary'
                    variant='text'
                    style={{
                        padding: '0px', textTransform: 'none', minWidth: '0px', marginTop: '6px',
                    }}
                    onClick={onCancel}
                >
                    {t('close')}
                </Button>
            </div>
        </Paper>
    );
}

WhatIf.propTypes = {
    t: PropTypes.func.isRequired,
    score: PropTypes.shape({
        poppers: PropTypes.number,
        time: PropTypes.number,
        scorePerTarget: PropTypes.arrayOf(PropTypes.string),
        penaltiesByType: PropTypes.shape({}),
        'no-shoots': PropTypes.number,
    }).isRequired,
    stageIndex: PropTypes.number.isRequired,
    stage: PropTypes.shape({
        targets: PropTypes.shape({
            poppers: PropTypes.number,
            plates: PropTypes.number,
            paper: PropTypes.number,
            minipaper: PropTypes.number,
            'paper-no-shoots': PropTypes.number,
            'metal-no-shoots': PropTypes.number,
        }),
        bonusTargets: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
    scoreMode: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onChangeScore: PropTypes.func.isRequired,
};

function StrikeThroughNewValue(props) {
    const { value, newValue } = props;
    if ((newValue === null) || (value === newValue)) return value;
    return (
        <span>
            <span style={{ textDecoration: 'line-through' }}>
                {value}
            </span>
            &nbsp;
            &nbsp;
            <span>
                {newValue}
            </span>
        </span>
    );
}

StrikeThroughNewValue.propTypes = {
    value: PropTypes.any,
    newValue: PropTypes.any,
};

StrikeThroughNewValue.defaultProps = {
    value: null,
    newValue: null,
};

/* eslint-enable react/no-array-index-key,react/forbid-prop-types */
