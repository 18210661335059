module.exports = {
    en: {
        title: 'Teams',
        teamname: 'Team Name',
        teamtypegeneric: 'Generic Team (unofficial)',
        teamtypefamily: 'Family Team',
        teamexceeds4: 'This teams has more than 4 shooters.',
        shooterdoesntmatchteam: 'Shooter\'s division/category doesn\'t match the teams',
    },
    he: {
        title: 'קבוצות',
        teamname: 'שם קבוצה',
        teamtypegeneric: 'קבוצה כללית (לא רשמית)',
        teamtypefamily: 'קבוצת משפחה',
        teamexceeds4: 'לקבוצה יותר מ-4 משתתפים.',
        shooterdoesntmatchteam: 'מחלקה וקטגוריה של היורה אינן מתאימות לקבוצה',
    },
};
