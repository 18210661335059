import React from 'react';
import ReactDOM from 'react-dom';
import queryString from 'query-string';
import { Router } from 'react-router-dom';
import { createBrowserHistory, createMemoryHistory } from 'history';
import './index.css';
import grey from '@material-ui/core/colors/grey';

import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { JssProvider } from 'react-jss';
import { I18nextProvider } from 'react-i18next';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { createMuiTheme, createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import i18n from './i18n';
import App from './client/App';
import 'react-flags-select/css/react-flags-select.css';

const generateClassName = createGenerateClassName();
const defaultLocale = window.locale || 'en-US';
const parsed = queryString.parse(window.location.search);

let history = null;
if (parsed.mode === 'app') {
    const cached = (() => {
        const json = window.localStorage.getItem('__memory_history_cache');
        if (!json) return ['/'];
        const data = JSON.parse(json);
        if (data.timestamp < new Date().getTime() - 10 * 60 * 1000) return ['/'];
        console.log(data.stack);
        if (data.stack.length === 0) return ['/'];
        if (data.stack.length === 1) return data.stack;
        if (data.stack[data.stack.length - 1] === '/') return data.stack;
        if (data.stack[data.stack.length - 1].startsWith('/?')) return data.stack;
        if (data.stack[data.stack.length - 1] === '/app/0') return data.stack;
        if (data.stack[data.stack.length - 1].startsWith('/app/0?')) return data.stack;
        if ((parsed.accessToken) && (parsed.name)) return data.stack;
        return [...data.stack, '/app-should-resume'];
    })();

    history = createMemoryHistory({
        initialEntries: cached,
        initialIndex: cached.length - 1,
    });

    history.listen((location, action) => {
        window.gtag('config', 'UA-140138398-1', { page_path: location.pathname + location.search });
        if (action === 'PUSH') {
            cached.push(`${location.pathname}${location.search}`);
        } else if (action === 'POP') {
            cached.pop();
        } else if (action === 'REPLACE') {
            cached.pop();
            cached.push(`${location.pathname}${location.search}`);
        }
        window.localStorage.setItem('__memory_history_cache', JSON.stringify({
            stack: cached,
            timestamp: new Date().getTime(),
        }));
    });
} else {
    history = createBrowserHistory();
    history.listen((location) => {
        window.gtag('config', 'UA-140138398-1', { page_path: location.pathname + location.search });
    });
}

if (parsed.mode === 'app') {
    /* eslint-disable no-underscore-dangle */
    window.__history = history;
    /* eslint-enable no-underscore-dangle */
    const foo = () => {
        try {
            window.postMessage('{}', '*');
            render();
        } catch (e) {
            console.error(e);
            setTimeout(foo, 100);
        }
    };
    foo();
} else {
    render();
}

function render() {
    class Wrapper extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                locale: defaultLocale,
            };
        }

        onUpdateThemeLocale = (locale) => {
            this.setState({ locale });
        }

        render() {
            const { locale } = this.state;

            const isRtl = locale === 'he-IL' || locale === 'iw-IL';
            const theme = createMuiTheme({
                direction: isRtl ? 'rtl' : 'ltr',
                palette: { primary: { main: '#2196f3' }, secondary: { main: '#f50057' } },
                typography: { useNextVariants: true },
            });

            const jss = create(jssPreset());
            if (isRtl) {
                jss.use(rtl());
            }

            document.body.style.background = grey[200]; // eslint-disable-line prefer-destructuring
            document.body.style.direction = isRtl ? 'rtl' : 'ltr';
            document.body.setAttribute('dir', isRtl ? 'rtl' : 'ltr');
            document.body.classList.remove(isRtl ? 'ltr' : 'rtl');
            document.body.classList.add(isRtl ? 'rtl' : 'ltr');

            return (
                <MuiThemeProvider theme={theme}>
                    <JssProvider jss={jss} generateClassName={generateClassName}>
                        <I18nextProvider i18n={i18n}>
                            <Router history={history}>
                                <App {...parsed} onUpdateThemeLocale={this.onUpdateThemeLocale} />
                            </Router>
                        </I18nextProvider>
                    </JssProvider>
                </MuiThemeProvider>
            );
        }
    }

    i18n.changeLanguage(defaultLocale, () => {
        ReactDOM.render(
            <Wrapper />,
            document.getElementById('root'),
        );
    });

    /*
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/serviceWorker.js');
    }
    */
}
