/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import {
    getScoresWithPoints, pad, getFlagSrc, getAllDivisions, getAllCategories,
} from './Utils';
import api from '../Api';

const ALL_CLASSIFICATIONS = ['grand-master', 'master', 'a', 'b', 'c', 'd'];

class ScoreTable extends Component {
    constructor(props) {
        const {
            match, mode,
        } = props;
        super(props);

        const tvMode = (mode || '').indexOf('tv') === 0;
        const modePages = tvMode ? (mode.split('|')[1] || 'by-stage').split(',') : null;

        this.state = {
            tvMode,
            scoreType: match.scoresPublished === 'verify-only' ? 'verify' : tvMode ? 'by-stage' : 'overall',
            division: 'standard',
            category: 'lady',
            ics: ALL_CLASSIFICATIONS[0],
            teamType: ((match.teams || [])[0] || {}).type,
            stage: 0,
            tvModal: false,
            tvModePages: tvMode ? modePages : ['by-stage', 'overall', 'by-category'],
        };
    }

    async componentDidMount() {
        const {
            setTitle, t, match,
        } = this.props;

        const { tvMode } = this.state;

        if ((!tvMode) && (typeof (window) !== 'undefined')) {
            window.scrollTo(0, 0);
        }

        setTitle(t('title', { name: match.title }));

        await this.refresh();

        const { divisions = getAllDivisions({ matchType: match.type }), categories = getAllCategories({ matchType: match.type }) } = this.state;
        await new Promise((res) => this.setState({
            division: divisions[0],
            category: categories[0],
        }, res));

        if (tvMode) {
            this.interval = setTimeout(() => this.scrollTick(), 10);
        }
    }

    componentWillUnmount() {
        const { tvMode } = this.state;

        if (tvMode) {
            this.interval = null;
        }
    }

    onExportCsv() {
        const { t, match } = this.props;
        const {
            divisions, dqs,
        } = this.state;
        const {
            scoresOverall,
        } = this.state;

        let result = `"","%","${t('generic:result_total')}","${t('generic:shooter_region')}","${t('generic:shooter_id')}","${t('generic:shooter_name')}","${t('generic:shooter_category')}","${t('generic:shooter_division')}","${t('managehome:title-field')}",""\n`;

        result += divisions.map((d) => (
            scoresOverall.filter((s) => (s.division === d)).map((score, index) => {
                if (_.some(dqs, (dq) => dq.shooterId === score.shooterId)) {
                    return null;
                }
                return `${index + 1},${pad(score.percentage / 100, 2)}%,${pad(score.score, 4)},"${score.shooterPublicId.split('|')[0]}","${score.shooterPublicId.split('|')[1]}","${score.shooterName}","${t(`generic:category-${score.category}`)}","${t(`generic:division-${score.division}`)}","${match.title}","${moment([match.startDate.year, match.startDate.month - 1, match.startDate.day]).format('L')}"`;
            }).join('\n')
        )).join('\n');

        if (dqs.length > 0) {
            result += '\n';
            result += dqs.map((d, index) => (
                `${index + 1},"DQ","DQ","${d.shooterPublicId.split('|')[0]}","${d.shooterPublicId.split('|')[1]}","${d.shooterName}","${t(`generic:category-${d.category}`)}","${t(`generic:division-${d.division}`)}","${match.title}","${moment([match.startDate.year, match.startDate.month - 1, match.startDate.day]).format('L')}"`
            )).join('\n');
        }

        const filename = `${match.title}.csv`;
        const blob = new Blob(['\ufeff', result], { type: 'text/plain;charset=utf-8' });
        window.saveAs(blob, filename);
    }

    openTVMode() {
        const { tvModePages } = this.state;
        const { href } = window.location;
        if (href.indexOf('?') > -1) {
            window.location.href = `${href}&mode=tv|${tvModePages.join(',')}`;
        } else {
            window.location.href = `${href}?mode=tv|${tvModePages.join(',')}`;
        }
    }

    async refresh() {
        const { match, auth, whatIf = { scores: [], shooterId: null }, manage } = this.props;

        const promises = await Promise.all([
            api.getScores({ matchId: match.id, auth }),
            api.getShooters({ matchId: match.id, auth }),
            api.getStages({ stages: match.stages }),
        ]);

        const $scores = [...promises.shift(), ...(whatIf.scores || [])];
        const shooters = promises.shift().filter((s) => !s.cancelledParticipation).map((s) => {
            if (s.id !== whatIf.shooterId) return s;
            return { ...s, 'power-factor': whatIf.powerFactor || s['power-factor'] };
        });

        const stages = promises.shift();

        const { scoresByStage, scoresOverall, dqs } = getScoresWithPoints({
            scores: $scores, shooters, stages, mode: match.scoreMode, showNumScoresEntered: manage,
        });

        const { scoresByStage: combinedScoresByStage, scoresOverall: combinedScoresOverall } = getScoresWithPoints({
            scores: $scores, shooters, stages, combined: true, mode: match.scoreMode, showNumScoresEnetered: manage,
        });

        let icsScores = null;
        if (match.icsEnabled) {
            icsScores = _.map([...ALL_CLASSIFICATIONS, null], (ics) => {
                const $$shooters = shooters.filter((s) => s.ics === ics);
                const $$scores = $scores.filter((s) => $$shooters.find((_s) => _s.id === s.shooterId));

                const { scoresByStage: _bystage, scoresOverall: _overall } = getScoresWithPoints({
                    scores: $$scores, shooters: $$shooters, stages, mode: match.scoreMode,
                });

                return {
                    ics,
                    byStage: _bystage,
                    overall: _overall,
                };
            });
        }

        const divisions = _(scoresOverall).map('division').uniq().value();
        const categories = _(scoresOverall).map('category').uniq().without('regular')
            .value();
        const allShooters = _(scoresOverall).map('shooterId')
            .uniq()
            .sortBy((shooterId) => {
                const s = _.find(scoresOverall, { shooterId }).shooterPublicId;
                console.log('@@@@@@@@@@@@', s,  _.find(scoresOverall, { shooterId }));
                return parseInt(s.split('|')[1], 10);
            })
            .value();

        await new Promise((res) => this.setState({
            scoresByStage,
            scoresOverall,
            combinedScoresByStage,
            combinedScoresOverall,
            icsScores,
            divisions: _.isEmpty(divisions) ? getAllDivisions({ matchType: match.type }) : divisions,
            categories: _.isEmpty(categories) ? getAllCategories({ matchType: match.type }) : categories,
            allShooters,
            dqs,
            stages,
        }, res));
    }

    async scrollTick() {
        if (!this.interval) return;
        const {
            stage, stages, division, divisions, category, categories, tvModePages, scoresOverall,
        } = this.state;
        if (window.pageYOffset === this.lastPageYOffset) {
            const shouldWait = (tvModePages[0] !== 'by-category')
                || (scoresOverall.find((s) => s.division === division && s.category === category));

            if (shouldWait) {
                await Promise.all([
                    this.refresh(),
                    new Promise((res) => setTimeout(res, 5000)),
                ]);
            }

            if (tvModePages[0] === 'by-stage') {
                if (stage === stages.length - 1) {
                    const nextDivison = divisions[(divisions.indexOf(division) + 1) % divisions.length];
                    if (nextDivison === divisions[0]) {
                        await new Promise((res) => this.setState({
                            division: nextDivison,
                            stage: 0,
                            tvModePages: [...tvModePages.splice(1), tvModePages[0]],
                        }, res));
                    } else {
                        await new Promise((res) => this.setState({ division: nextDivison, stage: 0 }, res));
                    }
                } else {
                    await new Promise((res) => this.setState({ stage: stage + 1 }, res));
                }
            }

            if (tvModePages[0] === 'by-category') {
                const nextCategory = categories[(categories.indexOf(category) + 1) % categories.length];
                const nextDivison = divisions[(divisions.indexOf(division) + 1) % divisions.length];

                if (nextCategory !== categories[0]) {
                    await new Promise((res) => this.setState({ category: nextCategory }, res));
                } else if (nextDivison !== divisions[0]) {
                    await new Promise((res) => this.setState({ division: nextDivison, category: categories[0] }, res));
                } else {
                    await new Promise((res) => this.setState({
                        division: divisions[0],
                        category: categories[0],
                        tvModePages: [...tvModePages.splice(1), tvModePages[0]],
                    }, res));
                }
            }

            if (tvModePages[0] === 'overall') {
                const nextDivison = divisions[(divisions.indexOf(division) + 1) % divisions.length];
                if (nextDivison === divisions[0]) {
                    await new Promise((res) => this.setState({
                        division: nextDivison,
                        tvModePages: [...tvModePages.splice(1), tvModePages[0]],
                    }, res));
                } else {
                    await new Promise((res) => this.setState({ division: nextDivison }, res));
                }
            }

            this.lastPageYOffset = null;
            window.scrollTo(0, 0);
            this.interval = setTimeout(() => this.scrollTick(), 10);
        } else {
            this.lastPageYOffset = window.pageYOffset;
            window.scrollTo(0, window.pageYOffset + 1);
            this.interval = setTimeout(() => this.scrollTick(), 10);
        }
    }


    renderNoScore() {
        const { t } = this.props;
        return (
            <Typography
                variant='body1'
                style={{
                    textAlign: 'center', width: '90%', margin: 'auto', marginTop: '30px',
                }}
            >
                {t('noscores')}
            </Typography>
        );
    }

    renderHeader() {
        const { t, match } = this.props;
        const date = moment([match.startDate.year, match.startDate.month - 1, match.startDate.day]).format('L');
        return (
            <div>
                <Typography variant='h6'>
                    {t('subtitle', { name: match.title, date })}
                </Typography>
                <Typography variant='caption'>
                    {t('printed', { date: moment().locale(match.locale.substr(0, 2)).format('L') })}
                </Typography>
            </div>
        );
    }

    renderOverall({ byIcs = false, combined = false } = {}) {
        const { t, match, classes } = this.props;
        const {
            divisions, combinedScoresOverall, icsScores, dqs, ics,
        } = this.state;
        let {
            division, scoresOverall,
        } = this.state;

        if (_.isEmpty(scoresOverall)) {
            return this.renderNoScore();
        }

        if (combined) {
            division = null;
            scoresOverall = combinedScoresOverall;
        }

        return (
            <div className={classes.table}>
                {(byIcs ? [...ALL_CLASSIFICATIONS, null] : [null]).map(($ics) => {
                    if (byIcs) {
                        scoresOverall = (icsScores.find((s) => s.ics === $ics)).overall;
                    }
                    return (combined ? [null] : divisions).map((d) => (
                        <div key={d} style={{ display: ((division === 'all' || d === division) && (!byIcs || ics === $ics || ics === 'all')) ? 'block' : 'none', pageBreakBefore: 'always', paddingBottom: '24px' }} className='forceprint'>
                            <Typography variant='h5'>
                                {!combined ? t('overall-title', { division: d }) : null}
                                {combined ? t('combined-title') : null}
                            </Typography>

                            {this.renderHeader()}

                            {byIcs ? (
                                <div style={{ padding: '12px 0px', fontWeight: 800 }}>
                                    {t('overall-ics-title', { ics: $ics || 'unclassified' })}
                                </div>
                            ) : null}

                            <table border='1' style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr>
                                        <td style={{ padding: '6px' }} />
                                        <td style={{ padding: '6px', textAlign: 'center' }}><Typography variant='subtitle2'><b>%</b></Typography></td>
                                        <td style={{ padding: '6px', textAlign: 'center' }}><Typography variant='subtitle2'><b>{t('generic:result_total')}</b></Typography></td>
                                        <td style={{ padding: '6px', textAlign: 'center' }}><Typography variant='subtitle2'><b>{t('generic:shooter_name')}</b></Typography></td>
                                        {combined && <td style={{ padding: '6px', textAlign: 'center' }}><Typography variant='subtitle2'><b>{t('generic:shooter_division')}</b></Typography></td>}
                                        <td style={{ padding: '6px', textAlign: 'center' }}><Typography variant='subtitle2'><b>{t('generic:shooter_category')}</b></Typography></td>
                                        {match.icsEnabled && <td style={{ padding: '6px', textAlign: 'center' }}><Typography variant='subtitle2'><b>{t('generic:shooter_ics-short')}</b></Typography></td>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {scoresOverall.filter((s) => ((combined) || (s.division === d))).map((score, index) => {
                                        if (_.some(dqs, (dq) => dq.shooterId === score.shooterId)) {
                                            return null;
                                        }
                                        return (
                                            <tr key={score.shooterId}>
                                                <td style={{ padding: '12px' }}>{index + 1}</td>
                                                <td style={{ padding: '12px' }}>
                                                    {pad(score.percentage / 100, 2)}
                                                    %
                                                </td>
                                                <td style={{ padding: '12px' }}>{pad(score.score, 4)}</td>
                                                <td style={{ padding: '12px' }}>
                                                    {renderPublicId(score.shooterPublicId, score.shooterName, score.isRo)}
                                                </td>
                                                {combined && <td style={{ padding: '12px' }}>{t(`generic:division-${score.division}`)}</td>}
                                                <td style={{ padding: '12px' }}>{t(`generic:category-${score.category}`)}</td>
                                                {match.icsEnabled && <td style={{ padding: '12px' }}>{score.shooterIcs ? t(`generic:ics-${score.shooterIcs}-short`) : ''}</td>}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ));
                })}
            </div>
        );
    }

    renderByCategory() {
        const { t, classes, match } = this.props;
        const {
            division, category, divisions, categories, scoresOverall, dqs,
        } = this.state;

        if (_.isEmpty(scoresOverall)) {
            return this.renderNoScore();
        }

        return (
            <div className={classes.table}>
                {divisions.map((d) => categories.map((c) => {
                    if (scoresOverall.filter((s) => s.division === d && s.category === c).length === 0) return null;
                    const $top = (scoresOverall
                        .filter((s) => s.division === d && s.category === c)
                        .filter((s) => !_.some(dqs, (dq) => dq.shooterId === s.shooterId)))[0];

                    return (
                        <div key={d + c} style={{ display: ((division === 'all' || d === division) && (category === 'all' || c === category)) ? 'block' : 'none', pageBreakBefore: 'always', paddingBottom: '24px' }} className='forceprint'>
                            <Typography variant='h5'>
                                {t('by-category-title', { division: d })}
                            </Typography>
                            {this.renderHeader()}
                            <Typography variant='subtitle2' style={{ padding: '12px 0px' }}>
                                {t('by-category-subtitle', { category: c })}
                            </Typography>

                            <table border='1' style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr>
                                        <td style={{ padding: '6px' }} />
                                        <td style={{ padding: '6px', textAlign: 'center' }}>
                                            <div>
                                                <Typography variant='subtitle2'>
                                                    <b>%</b>
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography variant='caption'>
                                                    {t('percentageofoverall')}
                                                </Typography>
                                            </div>
                                        </td>
                                        <td style={{ padding: '6px', textAlign: 'center' }}><Typography variant='subtitle2'><b>{t('generic:result_total')}</b></Typography></td>
                                        <td style={{ padding: '6px', textAlign: 'center' }}><Typography variant='subtitle2'><b>{t('generic:shooter_name')}</b></Typography></td>
                                        <td style={{ padding: '6px', textAlign: 'center' }}><Typography variant='subtitle2'><b>{t('generic:shooter_category')}</b></Typography></td>
                                        {match.icsEnabled && <td style={{ padding: '6px', textAlign: 'center' }}><Typography variant='subtitle2'><b>{t('generic:shooter_ics-short')}</b></Typography></td>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {scoresOverall.filter((s) => s.division === d && s.category === c).map((score, index) => {
                                        if (_.some(dqs, (dq) => dq.shooterId === score.shooterId)) {
                                            return null;
                                        }
                                        return (
                                            <tr key={score.shooterId}>
                                                <td style={{ padding: '12px' }}>{index + 1}</td>
                                                <td style={{ padding: '12px' }}>
                                                    {pad(((score.score * 100) / $top.score), 2)}
                                                    %
                                                    <div>
                                                        <Typography variant='caption'>
                                                            ({pad(score.percentage / 100, 2)}%)
                                                        </Typography>
                                                    </div>
                                                </td>
                                                <td style={{ padding: '12px' }}>{pad(score.score, 4)}</td>
                                                <td style={{ padding: '12px' }}>
                                                    {renderPublicId(score.shooterPublicId, score.shooterName, score.isRo)}
                                                </td>
                                                <td style={{ padding: '12px' }}>{t(`generic:category-${score.category}`)}</td>
                                                {match.icsEnabled && <td style={{ padding: '12px' }}>{score.shooterIcs ? t(`generic:ics-${score.shooterIcs}-short`) : ''}</td>}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    );
                }))}
            </div>
        );
    }

    renderByStage({ combined = false, onJumpToScore } = {}) {
        const { t, classes, match } = this.props;
        const {
            divisions, allShooters, combinedScoresByStage, stages, stage, dqs,
        } = this.state;
        let {
            division, scoresByStage,
        } = this.state;

        if (_.every(scoresByStage, (s) => _.isEmpty(s))) {
            return this.renderNoScore();
        }

        if (combined) {
            division = null;
            scoresByStage = combinedScoresByStage;
        }

        return (
            <div className={classes.table}>
                {_.flatten((combined ? [null] : divisions).map((d) => _.range(stages.length).map(($stage) => {
                    if ((stage !== 'all') && ($stage !== stage)) return null;
                    if (stages[$stage].inactive) {
                        return (
                            <div
                                key={`${d}.${$stage}`}
                                style={{ display: (division === 'all' || d === division) ? 'block' : 'none', pageBreakBefore: 'always', paddingBottom: '24px' }}
                                className='noprint'
                            >
                                <Typography variant='h5'>
                                    {!combined
                                        ? t('by-stage-title', { stage: $stage + 1, division: d })
                                        : t('combined-by-stage-title', { stage: $stage + 1 })}
                                </Typography>
                                {this.renderHeader()}
                                <Typography variant='body2' style={{ marginTop: '24px' }}>
                                    {t('stages:inactive')}
                                </Typography>
                            </div>
                        );
                    }
                    return (
                        <div
                            key={`${d}.${$stage}`}
                            style={{ display: (division === 'all' || d === division) ? 'block' : 'none', pageBreakBefore: 'always', paddingBottom: '24px' }}
                            className='forceprint'
                        >
                            <Typography variant='h5'>
                                {!combined
                                    ? t('by-stage-title', { stage: $stage + 1, division: d })
                                    : t('combined-by-stage-title', { stage: $stage + 1 })}
                            </Typography>
                            {this.renderHeader()}
                            <table border='1' style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr>
                                        <td style={{ padding: '6px' }} />
                                        <td style={{ padding: '6px', textAlign: 'center' }}><Typography variant='subtitle2'><b>{t('generic:result_total')}</b></Typography></td>
                                        <td style={{ padding: '6px', textAlign: 'center' }}><Typography variant='subtitle2'><b>{t('generic:result_time')}</b></Typography></td>
                                        <td style={{ padding: '6px', textAlign: 'center' }}><Typography variant='subtitle2'><b>{t('generic:result_factor')}</b></Typography></td>
                                        <td style={{ padding: '6px', textAlign: 'center' }}><Typography variant='subtitle2'><b>{t('%')}</b></Typography></td>
                                        <td style={{ padding: '6px', textAlign: 'center' }}><Typography variant='subtitle2'><b>{t('generic:result_stage_points')}</b></Typography></td>
                                        <td style={{ padding: '6px', textAlign: 'center' }}><Typography variant='subtitle2'><b>{t('generic:shooter_name')}</b></Typography></td>
                                        {combined && <td style={{ padding: '6px', textAlign: 'center' }}><Typography variant='subtitle2'><b>{t('generic:shooter_division')}</b></Typography></td>}
                                        <td style={{ padding: '6px', textAlign: 'center' }}><Typography variant='subtitle2'><b>{t('generic:shooter_category')}</b></Typography></td>
                                        {match.icsEnabled && <td style={{ padding: '6px', textAlign: 'center' }}><Typography variant='subtitle2'><b>{t('generic:shooter_ics-short')}</b></Typography></td>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {_(scoresByStage[$stage]).filter((s) => ((combined) || (s.division === d))).sortBy((s) => -1 * s.factor).map((score, index) => {
                                        if (_.some(dqs, (dq) => dq.shooterId === score.shooterId)) {
                                            return null;
                                        }
                                        return (
                                            <tr key={score.shooterId}>
                                                <td style={{ padding: '12px' }}>{index + 1}</td>
                                                <td style={{ padding: '6px' }}>{score.total}</td>
                                                <td style={{ padding: '6px' }}>{pad(score.time, 2)}</td>
                                                <td style={{ padding: '6px' }}>{pad(score.factor, 4)}</td>
                                                <td style={{ padding: '12px' }}>
                                                    {pad(score.percentage / 100, 2)}
                                                    %
                                                </td>
                                                <td style={{ padding: '12px' }}>{pad(score.points, 4)}</td>
                                                <td style={{ padding: '12px' }}>
                                                    {renderPublicId(
                                                        score.shooterPublicId,
                                                        score.shooterName,
                                                        score.isRo,
                                                        score.numScoresEntered,
                                                        () => onJumpToScore({ shooterId: score.shooterId, stage: $stage + 1 }),
                                                    )}
                                                </td>
                                                {combined && <td style={{ padding: '12px' }}>{t(`generic:division-${score.division}`)}</td>}
                                                <td style={{ padding: '12px' }}>{t(`generic:category-${score.category}`)}</td>
                                                {match.icsEnabled && <td style={{ padding: '12px' }}>{score.shooterIcs ? t(`generic:ics-${score.shooterIcs}-short`) : ''}</td>}
                                            </tr>
                                        );
                                    })
                                        .value()}
                                    {_(allShooters).reject((sId) => scoresByStage[$stage].find((s) => s.shooterId === sId)).map((shooterId) => {
                                        const score = _.flatten(scoresByStage).find((s) => s.shooterId === shooterId);
                                        if (_.some(dqs, (dq) => dq.shooterId === score.shooterId)) {
                                            return null;
                                        }
                                        if ((!combined) && (score.division !== d)) {
                                            return null;
                                        }
                                        return (
                                            <tr key={score.shooterId} className='noprint'>
                                                <td style={{ padding: '12px' }} />
                                                <td style={{ padding: '12px', textAlign: 'center' }} colSpan={5}>{t('nodata')}</td>
                                                <td style={{ padding: '12px' }}>
                                                    {renderPublicId(score.shooterPublicId, score.shooterName, score.isRo)}
                                                </td>
                                                {combined && <td style={{ padding: '12px' }}>{t(`generic:division-${score.division}`)}</td>}
                                                <td style={{ padding: '12px' }}>{t(`generic:category-${score.category}`)}</td>
                                                {match.icsEnabled && <td style={{ padding: '12px' }}>{score.shooterIcs ? t(`generic:ics-${score.shooterIcs}-short`) : ''}</td>}
                                            </tr>
                                        );
                                    }).value()}
                                </tbody>
                            </table>
                        </div>
                    );
                })))}
            </div>
        );
    }

    renderVerify({ exceptionsOnly = false, onJumpToScore }) {
        const { t, match, classes } = this.props;
        const {
            scoresByStage, allShooters, stages, dqs,
        } = this.state;

        const { scoreMode } = match;

        if (_.every(scoresByStage, (s) => _.isEmpty(s))) {
            return this.renderNoScore();
        }

        return (
            <div className={classes.table}>
                <Typography variant='h5'>
                    {t('verify-title')}
                </Typography>
                {this.renderHeader()}
                {allShooters.map((shooterId) => {
                    const scores = scoresByStage.map((s) => s.find(($s) => $s.shooterId === shooterId));
                    const score = _.find(scores, (s) => !!s);
                    if (_.some(dqs, (dq) => dq.shooterId === score.shooterId)) {
                        return null;
                    } if ((exceptionsOnly) && (_.compact(scores).length === match.stages.length)) {
                        return null;
                    }
                    return (
                        <div key={`${score.shooterId}`} style={{ marginTop: '24px' }}>
                            <div style={{ display: 'flex', fontWeight: 'bolder' }}>
                                <div style={{ width: match.icsEnabled ? '50%' : '60%' }}>
                                    {renderPublicId(score.shooterPublicId, score.shooterName, score.isRo)}
                                </div>
                                <div style={{ width: '15%' }}>{t(`generic:division-${score.division}`)}</div>
                                <div style={{ width: '15%' }}>{t(`generic:category-${score.category}`)}</div>
                                <div style={{ width: '10%' }}>{t(`generic:power-factor-${score['power-factor'] || 'minor'}`)}</div>
                                {match.icsEnabled && <td style={{ width: '10%' }}>{score.shooterIcs ? t(`generic:ics-${score.shooterIcs}`) : ''}</td>}
                            </div>
                            <div style={{ display: 'none' }}>
                                {score.shooterId}
                            </div>
                            <table border='1' style={{ width: '100%', marginTop: '6px', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr>
                                        <td style={{ textAlign: 'center' }}>{t('generic:stage_number')}</td>
                                        <td style={{ textAlign: 'center' }}>{t('generic:result_time')}</td>
                                        <td style={{ textAlign: 'center' }}>A</td>
                                        <td style={{ textAlign: 'center' }}>{scoreMode === 'comstock-abd' ? 'B' : 'C'}</td>
                                        <td style={{ textAlign: 'center' }}>{scoreMode === 'il-army' ? '' : 'D'}</td>
                                        <td style={{ textAlign: 'center' }}>M</td>
                                        <td style={{ textAlign: 'center' }}>(M)</td>
                                        <td style={{ textAlign: 'center' }}>PT</td>
                                        <td style={{ textAlign: 'center' }}>Proc</td>
                                        <td style={{ textAlign: 'center' }}>Ded</td>
                                        <td style={{ textAlign: 'center' }}>{t('generic:result_total')}</td>
                                        <td style={{ textAlign: 'center' }}>{t('generic:result_factor')}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {scores.map((result, index) => {
                                        if (!result) return null;
                                        const i = index;
                                        return (
                                            <tr key={i}>
                                                <td style={{ padding: '6px', textAlign: 'center' }}>{index + 1}
                                                    {result.numScoresEntered > 1 && (
                                                        <Chip
                                                            style={{ marginLeft: '6px', backgroundColor: red[500], color: 'white', cursor: 'pointer' }}
                                                            onClick={() => onJumpToScore({ shooterId: result.shooterId, stage: index + 1 })}
                                                            label={result.numScoresEntered}
                                                        />
                                                    )}
                                                </td>
                                                <td style={{ padding: '6px', textAlign: 'center' }}>{result.timeStr}</td>
                                                <td style={{ padding: '6px', textAlign: 'center' }}>{result.alphas}</td>
                                                <td style={{ padding: '6px', textAlign: 'center' }}>{result.charlies}</td>
                                                <td style={{ padding: '6px', textAlign: 'center' }}>
                                                    {scoreMode === 'il-army' && (
                                                        <div>
                                                            <div>
                                                                {result.bonusField}
                                                            </div>
                                                            <Typography variant='caption'>
                                                                {stages[index].bonusField}
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    {scoreMode !== 'il-army' && (
                                                        <div>
                                                            {result.deltas}
                                                        </div>
                                                    )}
                                                </td>
                                                <td style={{ padding: '6px', textAlign: 'center' }}>{result.misses}</td>
                                                <td style={{ padding: '6px', textAlign: 'center' }}>{result.bonusMisses}</td>
                                                <td style={{ padding: '6px', textAlign: 'center' }}>{result.noShoots}</td>
                                                <td style={{ padding: '6px', textAlign: 'center' }}>{result.penalties}</td>
                                                <td style={{ padding: '6px', textAlign: 'center' }}>{result.specialPenaltyPercentageStr}</td>
                                                <td style={{ padding: '6px', textAlign: 'center' }}>{result.total}</td>
                                                <td style={{ padding: '6px', textAlign: 'center' }}>{result.factorStr}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    );
                })}
            </div>
        );
    }

    renderDQs() {
        const { t, match, classes } = this.props;
        const { dqs } = this.state;
        const date = moment([match.startDate.year, match.startDate.month - 1, match.startDate.day]).format('L');

        return (
            <div className={classes.table} style={{ pageBreakBefore: 'always' }}>
                <hr style={{ margin: '36px 0px 24px 0px', opacity: 0.2 }} />
                <Typography variant='h5'>
                    {t('dq-title')}
                </Typography>
                <Typography variant='h6'>
                    {t('subtitle', { name: match.title, date })}
                </Typography>
                <Typography variant='caption' style={{ marginBottom: '6px' }}>
                    {t('printed', { date: moment().locale(match.locale.substr(0, 2)).format('L') })}
                </Typography>
                {getAllDivisions({ matchType: match.type }).map((division) => {
                    const dqsInDivision = dqs.filter((d) => d.division === division);
                    if (dqsInDivision.length === 0) return null;
                    return (
                        <div key={division} style={{ marginBottom: '6px' }}>
                            <Typography variant='subtitle2'>
                                {t(`generic:division-${division}`)}
                            </Typography>
                            {dqsInDivision.sort((a, b) => a.timestamp - b.timestamp).map((dq) => (
                                <div key={`dq_${dq.shooterId}`} style={{ display: 'flex' }}>
                                    {renderPublicId(dq.shooterPublicId, dq.shooterName, dq.isRo)}
                                    &nbsp;-&nbsp;
                                    {moment(dq.timestamp).format('LLL')}
                                    {dq.category !== 'regular' && ` (${t(`registration:category-${dq.category}`)})`}
                                    &nbsp;-&nbsp;
                                    {t('generic:stage_number')}
                                    &nbsp;
                                    {dq.stage}
                                    &nbsp; - &nbsp;
                                    {dq.dq}
                                </div>
                            ))}
                        </div>
                    );
                })}
            </div>
        );
    }

    renderTeams() {
        const {
            t, classes, match,
        } = this.props;
        const {
            teamType, scoresOverall,
        } = this.state;

        const results = {};
        const teamIds = match.teams.filter((team) => team.type === teamType).map((team) => team.id);
        scoresOverall.forEach((score) => {
            if (teamIds.indexOf(score.shooterTeam) === -1) return;
            results[score.shooterTeam] = results[score.shooterTeam] || { team: score.shooterTeam, scores: [] };
            results[score.shooterTeam].scores.push(score.score);
            results[score.shooterTeam].scores.sort((a, b) => b - a);
            results[score.shooterTeam].total = _.sum(results[score.shooterTeam].scores.slice(0, 3));
        });

        const teamTypeStr = (() => {
            const [category, division] = teamType.split('-');
            if (!division) {
                return t(`teamsmanage:teamtype${category}`);
            }
            return `${t(`generic:division-${division}`)} - ${t(`registration:category-${category}`)}`;
        })();

        return (
            <div className={classes.table}>
                <div className='forceprint'>
                    <Typography variant='h5'>
                        {t('teams-title', { teamType: teamTypeStr })}
                    </Typography>
                    {this.renderHeader()}
                    <Typography variant='subtitle2' style={{ padding: '12px 0px' }}>
                        {t('teams-subtitle')}
                    </Typography>
                    <table border='1' style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <td style={{ padding: '6px' }} />
                                <td style={{ padding: '6px', textAlign: 'center' }}><Typography variant='subtitle2'><b>{t('generic:result_total')}</b></Typography></td>
                                <td style={{ padding: '6px', textAlign: 'center' }}><Typography variant='subtitle2'><b>{t('generic:shooter_team')}</b></Typography></td>
                            </tr>
                        </thead>
                        <tbody>
                            {_(results)
                                .values()
                                .sortBy((s) => -1 * s.total)
                                .value()
                                .map((score, index) => (
                                    <tr key={score.team}>
                                        <td style={{ padding: '12px' }}>{index + 1}</td>
                                        <td style={{ padding: '6px' }}>{pad(score.total, 4)}</td>
                                        <td style={{ padding: '6px' }}>{match.teams.find((team) => team.id === score.team).title}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    renderTVMode() {
        const { tvModePages } = this.state;
        return (
            <div>
                {tvModePages[0] === 'by-stage' && this.renderByStage()}
                {tvModePages[0] === 'overall' && this.renderOverall()}
                {tvModePages[0] === 'by-category' && this.renderByCategory()}
            </div>
        );
    }

    render() {
        const {
            t, auth, match, whatIf, manage, history,
        } = this.props;
        const {
            scoreType, division, divisions, category, ics, stage, scoresByStage, teamType, tvMode, tvModal, tvModePages,
        } = this.state;

        if (!scoresByStage) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <CircularProgress size={75} />
                </div>
            );
        }

        if (tvMode) {
            return this.renderTVMode();
        }

        function onJumpToScore({ shooterId, stage }) {
            history.push(`/${match.id}/manage/scores/stage/${stage}/squad/none/score-mode/reviewing-log/${shooterId}`);
        }

        return (
            <div>
                <div className='noprint' style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ flex: 1 }} />
                    <div style={{ display: 'flex' }}>

                        <Select style={{ margin: '0px 12px' }} value={scoreType} onChange={(e) => this.setState({ scoreType: e.target.value })}>
                            {(manage || match.scoresPublished === 'full') && <MenuItem value='overall'>{t('overall')}</MenuItem>}
                            {(manage || match.scoresPublished === 'full') && <MenuItem value='by-stage'>{t('by-stage')}</MenuItem>}
                            {(manage || match.scoresPublished === 'full') && <MenuItem value='by-category'>{t('by-category')}</MenuItem>}
                            <MenuItem value='verify'>{t('verify')}</MenuItem>
                            {match.icsEnabled && <MenuItem value='overall-ics'>{t('overall-ics')}</MenuItem>}
                            {(manage || match.scoresPublished === 'full') && <MenuItem value='combined'>{t('combined')}</MenuItem>}
                            {(manage || match.scoresPublished === 'full') && <MenuItem value='combined-by-stage'>{t('combined-by-stage')}</MenuItem>}
                            {!!auth && <MenuItem value='exceptions'>{t('exceptions')}</MenuItem>}
                            {!_.isEmpty(match.teams) && (manage || match.scoresPublished === 'full') && <MenuItem value='teams'>{t('teams')}</MenuItem>}
                        </Select>

                        {((scoreType === 'by-stage') || (scoreType === 'combined-by-stage')) && (
                            <Select style={{ margin: '0px 12px' }} value={stage} onChange={(e) => this.setState({ stage: e.target.value })}>
                                {_.range(scoresByStage.length).map((s) => (
                                    <MenuItem value={s} key={s}>{`${t('generic:stage_number')} ${s + 1}`}</MenuItem>
                                ))}
                                <MenuItem value='all' key='all'>{`${t('all-stages')}`}</MenuItem>
                            </Select>
                        )}

                        {scoreType !== 'verify' && scoreType !== 'combined' && scoreType !== 'combined-by-stage' && scoreType !== 'teams' && (
                            <Select style={{ margin: '0px 12px' }} value={division} onChange={(e) => this.setState({ division: e.target.value })}>
                                {[...divisions, null].map((d) => (
                                    <MenuItem value={d || 'all'} key={d || 'all'}>{t(d ? `generic:division-${d}` : 'all-divisions')}</MenuItem>
                                ))}
                            </Select>
                        )}

                        {scoreType === 'by-category' && (
                            <Select style={{ margin: '0px 12px' }} value={category} onChange={(e) => this.setState({ category: e.target.value })}>
                                {[...getAllCategories({ matchType: match.type }), null].map((c) => (
                                    <MenuItem value={c || 'all'} key={c || 'all'}>{t(c ? `registration:category-${c}` : 'all-categories')}</MenuItem>
                                ))}
                            </Select>
                        )}

                        {scoreType === 'overall-ics' && (
                            <Select style={{ margin: '0px 12px' }} value={ics} onChange={(e) => this.setState({ ics: e.target.value })}>
                                {[...ALL_CLASSIFICATIONS, null, 'all'].map((c) => (
                                    <MenuItem value={c} key={c}>{t(c !== 'all' ? `generic:ics-${c || 'unclassified'}` : 'all-ics')}</MenuItem>
                                ))}
                            </Select>
                        )}

                        {scoreType === 'teams' && (
                            <Select style={{ margin: '0px 12px' }} value={teamType} onChange={(e) => this.setState({ teamType: e.target.value })}>
                                {match.teams.find((team) => team.type === 'generic') && (
                                    <MenuItem value='generic'>
                                        {t('teamsmanage:teamtypegeneric')}
                                    </MenuItem>
                                )}
                                {getAllCategories({ matchType: match.type }).map(($category) => getAllDivisions({ matchType: match.type }).map(($division) => {
                                    const c = `${$category}-${$division}`;
                                    if (!match.teams.find((team) => team.type === c)) return null;
                                    return (
                                        <MenuItem value={c} key={c}>
                                            {t(`generic:division-${$division}`)}
                                            &nbsp;-&nbsp;
                                            {t(`registration:category-${$category}`)}
                                        </MenuItem>
                                    );
                                }))}
                                {match.teams.find((team) => team.type === 'family') && (
                                    <MenuItem value='family'>
                                        {t('teamsmanage:teamtypefamily')}
                                    </MenuItem>
                                )}
                            </Select>
                        )}
                    </div>
                    <div style={{ flex: 1 }} />
                    {scoreType === 'overall' && auth && (
                        <IconButton onClick={() => this.onExportCsv()}>
                            <i className='fas fa-file-csv' style={{ fontSize: '24px' }} />
                        </IconButton>
                    )}
                    <IconButton onClick={() => this.setState({ tvModal: true })}>
                        <i className='fas fa-tv' style={{ fontSize: '24px' }} />
                    </IconButton>
                </div>

                {(scoreType === 'overall') && (
                    <div style={{ width: '100%' }}>
                        {this.renderOverall()}
                    </div>
                )}
                {(scoreType === 'by-stage') && (
                    <div style={{ width: '100%' }}>
                        {this.renderByStage({ onJumpToScore })}
                    </div>
                )}
                {(scoreType === 'by-category') && (
                    <div style={{ width: '100%' }}>
                        {this.renderByCategory()}
                    </div>
                )}
                {(scoreType === 'exceptions') && (
                    <div style={{ width: '100%' }} className='noprint'>
                        {this.renderVerify({ exceptionsOnly: true, onJumpToScore })}
                    </div>
                )}
                {(scoreType === 'overall-ics') && (
                    <div style={{ width: '100%' }}>
                        {this.renderOverall({ byIcs: true })}
                    </div>
                )}
                {(scoreType === 'combined') && (
                    <div style={{ width: '100%' }}>
                        {this.renderOverall({ combined: true })}
                    </div>
                )}
                {(scoreType === 'combined-by-stage') && (
                    <div style={{ width: '100%' }}>
                        {this.renderByStage({ combined: true, onJumpToScore })}
                    </div>
                )}
                {(scoreType === 'verify') && (
                    <div style={{ width: '100%' }}>
                        {this.renderVerify({ exceptionsOnly: false, onJumpToScore })}
                    </div>
                )}
                {(scoreType === 'teams') && (
                    <div style={{ width: '100%' }}>
                        {this.renderTeams()}
                    </div>
                )}
                {scoreType !== 'teams' && scoreType !== 'verify' && this.renderDQs()}

                <Dialog onClose={() => this.setState({ tvModal: false })} open={tvModal}>
                    <div style={{ padding: '12px 24px' }}>
                        <Typography variant='h6'>{t('tvmodetitle')}</Typography>
                        <Typography variant='body2'>{t('tvmodedescription')}</Typography>
                        <Typography variant='body2'>{t('tvmodedescription2')}</Typography>
                        <div style={{ paddingBottom: '12px' }}>
                            {['overall', 'by-stage', 'by-category'].map((mode) => (
                                <div key={mode}>
                                    <FormControlLabel
                                        control={(
                                            <Checkbox
                                                disabled={tvModePages.length === 1 && mode === tvModePages[0]}
                                                checked={tvModePages.indexOf(mode) > -1}
                                                onChange={() => {
                                                    const has = tvModePages.indexOf(mode) > -1;
                                                    this.setState({ tvModePages: has ? tvModePages.filter((m) => m !== mode) : [...tvModePages, mode] });
                                                }}
                                            />
                                        )}
                                        label={<div>{t(mode)}</div>}
                                    />
                                </div>
                            ))}
                        </div>
                        <Button color='primary' variant='contained' fullWidth onClick={() => this.openTVMode()}>{t('opentvmode')}</Button>
                    </div>
                </Dialog>
                {!_.isEmpty(whatIf) && (
                    <div style={{ marginTop: '40px' }}>
                        <div
                            style={{
                                position: 'fixed',
                                bottom: '0px',
                                left: '0px',
                                width: 'calc(100% - 24px)',
                                minHeight: '20px',
                                textAlign: 'start',
                                padding: '12px',
                                backgroundColor: red[500],
                                color: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                zIndex: '2',
                            }}
                        >
                            <Typography variant='caption' style={{ color: 'inherit' }}>
                                {t('whatif')}
                            </Typography>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const styles = () => ({
    table: {
        width: '90%',
        maxWidth: '720px',
        margin: 'auto',
        marginTop: '24px',
        textAlign: 'left',
    },
});

ScoreTable.propTypes = {
    setTitle: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    mode: PropTypes.string,
    auth: PropTypes.string,
    manage: PropTypes.bool,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        scoreMode: PropTypes.string.isRequired,
        startDate: PropTypes.shape({
            day: PropTypes.number.isRequired,
            month: PropTypes.number.isRequired,
            year: PropTypes.number.isRequired,
        }).isRequired,
        locale: PropTypes.string.isRequired,
        teams: PropTypes.arrayOf(PropTypes.shape({})),
        stages: PropTypes.arrayOf(PropTypes.string),
        icsEnabled: PropTypes.bool,
        scoresPublished: PropTypes.string,
    }).isRequired,
    whatIf: PropTypes.arrayOf(PropTypes.shape({})),
};

ScoreTable.defaultProps = {
    auth: null,
    mode: null,
    whatIf: [],
    manage: false,
};

export default withStyles(styles, { withTheme: true })(withTranslation('scoretable')(ScoreTable));

function renderPublicId(publicId, name, isRo, numScoresEntered, onJumpToScore = () => {}) {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {(publicId.indexOf('|') > -1) && (
                <img
                    alt={publicId.split('|')[0]}
                    src={getFlagSrc({ countryCode: publicId.split('|')[0], size: 16 })}
                />
            )}
            <div style={{ padding: '0px 6px' }}>
                {publicId.split('|').pop()}
            </div>
            {' '}
            /
            {' '}
            {name}
            {isRo && (
                <div>
                    &nbsp;
                    <Chip color='primary' style={{ borderRadius: '0px', height: 'auto', fontSize: '9px' }} label={isRo.toUpperCase()} />
                </div>
            )}
            {numScoresEntered > 1 && (
                <Chip 
                    style={{ marginLeft: '6px', backgroundColor: red[500], color: 'white', cursor: 'pointer' }}
                    color='inherit'
                    label={numScoresEntered}
                    onClick={onJumpToScore}
                />
            )}
        </div>
    );
}
/* eslint-enable react/jsx-one-expression-per-line */
