/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import AnimatedNumber from 'react-animated-number';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { getAllDivisions, getAllCategories } from './Utils';
import api from '../Api';

class MatchHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            announcementsExtended: false,
            stages: null,
            shooters: null,
        };
    }

    async componentDidMount() {
        const { match, setTitle } = this.props;
        setTitle(match.description);
        window.addEventListener('scroll', this.handleScroll);

        (async () => {
            const stages = await api.getStages({ stages: match.stages });
            this.setState({ stages });
        })();

        (async () => {
            const shooters = await api.getShooters({ matchId: match.id });
            this.setState({ shooters: shooters.filter((s) => !s.cancelledParticipation) });
        })();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const { animationStarted } = this.state;
        if (!this.numsNode) return;
        const rect = this.numsNode.getBoundingClientRect();
        if ((rect.top < window.innerHeight - 30) && (!animationStarted)) {
            this.setState({ animationStarted: true });
            window.removeEventListener('scroll', this.handleScroll);
        }
    };

    render() {
        const {
            match, announcements, t, i18n,
        } = this.props;
        const {
            title, about, squadsPublished, stagesPublished, scoresPublished,
        } = match;
        const {
            announcementsExtended, animationStarted, stages, shooters, breakdownDivision,
        } = this.state;

        if (!title) {
            return (
                <div style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '100vh',
                }}
                >
                    <CircularProgress size={75} />
                </div>
            );
        }

        const win = (() => {
            if (typeof (window) !== 'undefined') return window;
            return {
                location: {
                    search: '',
                },
            };
        })();

        const SquadLink = React.forwardRef((props, ref) => <Link ref={ref} to={`/${match.alias}/squads${win.location.search}`} {...props} />);
        const ShootersLink = React.forwardRef((props, ref) => <Link ref={ref} to={`/${match.alias}/shooters${win.location.search}`} {...props} />);
        const StagesLink = React.forwardRef((props, ref) => <Link ref={ref} to={`/${match.alias}/stages${win.location.search}`} {...props} />);
        const ScoreTableLink = React.forwardRef((props, ref) => <Link ref={ref} to={`/${match.alias}/results${win.location.search}`} {...props} />);
        const RegistrationLink = React.forwardRef((props, ref) => <Link ref={ref} to={`/${match.alias}/registration${win.location.search}`} {...props} />);
        const RoRegistrationLink = React.forwardRef((props, ref) => <Link ref={ref} to={`/${match.alias}/ro-registration${win.location.search}`} {...props} />);

        const CtaButtonWrapper = (props) => {
            if (props.published) return props.children;
            return (
                <span>
                    <div>
                        {props.children}
                    </div>
                    <div style={{ fontSize: '11px' }}>
                        {props.notPublishedTooltip}
                    </div>
                </span>
            );
        };

        const squadsfull = shooters ? !_(match.squads).filter((squad) => !squad.ro).some((squad) => !squad.maxShooters || shooters.filter((s) => s.squad === squad.id).length < squad.maxShooters) : false;
        const rosquadsfull = shooters ? !_(match.squads).filter((squad) => !!squad.ro).some((squad) => !squad.maxShooters || shooters.filter((s) => s.squad === squad.id).length < squad.maxShooters) : false;

        return (
            <div>
                {about && (
                    <div style={{
                        paddingTop: '12px', paddingBottom: '24px', marginBottom: '24px', borderBottom: '1px solid rgba(0,0,0,0.2)',
                    }}
                    >
                        <div dangerouslySetInnerHTML={{ __html: about }} style={{ padding: '0px 24px' }} />
                    </div>
                )}
                {announcements.length > 0 && (
                    <div style={{
                        paddingTop: about ? '0px' : '12px', paddingBottom: '24px', marginBottom: '24px', textAlign: 'center', textWeight: '400', borderBottom: '1px solid rgba(0,0,0,0.2)',
                    }}
                    >
                        {(announcementsExtended ? announcements : [announcements[0]]).map((announcement) => (
                            <Paper
                                key={announcement.id}
                                style={{
                                    margin: 'auto', marginTop: '12px', width: '90%', maxWidth: '640px', textAlign: i18n.dir() === 'ltr' ? 'left' : 'right',
                                }}
                            >
                                <div style={{
                                    color: 'white', margin: '0px', padding: '12px', display: 'flex', fontSize: '18px', background: '#2196f3', alignItems: 'center',
                                }}
                                >
                                    <div style={{ flex: 1 }}>
                                        {announcement.subject}
                                    </div>
                                    <Typography variant='caption' style={{ color: 'white' }}>
                                        {moment(announcement.created).format('lll')}
                                    </Typography>
                                </div>
                                <div style={{ padding: '12px', fontSize: '13px', fontWeight: '300' }} dangerouslySetInnerHTML={{ __html: announcement.body }} />
                            </Paper>
                        ))}

                        {announcements.length > 1 && !announcementsExtended && (
                            <div style={{
                                textAlign: i18n.dir() === 'ltr' ? 'left' : 'right', width: '90%', maxWidth: '640px', margin: 'auto', marginTop: '6px',
                            }}
                            >
                                <Typography variant='caption'>
                                    <div
                                        role='button'
                                        tabIndex={0}
                                        style={{ color: 'rgba(0,0,0,0.7)', cursor: 'pointer', textDecoration: 'underline' }}
                                        onClick={() => this.setState({ announcementsExtended: true })}
                                        onKeyDown={(e) => { if (e.keyCode === 13) this.setState({ announcementsExtended: true }); }}
                                    >
                                        {t('more-announcements', { count: announcements.length - 1 })}
                                    </div>
                                </Typography>
                            </div>
                        )}
                        {announcements.length > 1 && announcementsExtended && (
                            <div style={{
                                textAlign: i18n.dir() === 'ltr' ? 'left' : 'right', width: '90%', maxWidth: '640px', margin: 'auto', marginTop: '6px',
                            }}
                            >
                                <Typography variant='caption'>
                                    <div
                                        role='button'
                                        tabIndex={0}
                                        style={{ color: 'rgba(0,0,0,0.7)', cursor: 'pointer', textDecoration: 'underline' }}
                                        onClick={() => this.setState({ announcementsExtended: false })}
                                        onKeyDown={(e) => { if (e.keyCode === 13) this.setState({ announcementsExtended: false }); }}
                                    >
                                        {t('less-announcements')}
                                    </div>
                                </Typography>
                            </div>
                        )}
                    </div>
                )}

                {squadsPublished && stagesPublished && (
                    <div
                        ref={(e) => { this.numsNode = e; this.handleScroll(); }}
                        style={{
                            paddingTop: (about || announcements.length > 0) ? '0px' : '12px', paddingBottom: '24px', marginBottom: '24px', textWeight: '400', borderBottom: '1px solid rgba(0,0,0,0.2)', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap',
                        }}
                    >
                        <div style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0px 72px',
                        }}
                        >
                            {animationStarted && !!stages && !!shooters && (
                                <AnimatedNumber
                                    component='div'
                                    value={stages.length}
                                    style={{ transition: '0.8s ease-out', fontSize: 84, transitionProperty: 'background-color, color, opacity' }}
                                    duration={500}
                                    stepPrecision={0}
                                />
                            )}
                            {!(animationStarted && !!stages && !!shooters) && (
                                <div style={{ fontSize: '84px' }}>0</div>
                            )}
                            <div>{t('shooters-stages-title')}</div>
                        </div>

                        <div style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0px 72px',
                        }}
                        >
                            {animationStarted && !!stages && !!shooters && (
                                <AnimatedNumber
                                    component='div'
                                    value={_.reduce(stages, (count, s) => count + (s.rounds || 0), 0)}
                                    style={{ transition: '0.8s ease-out', fontSize: 84, transitionProperty: 'background-color, color, opacity' }}
                                    duration={500}
                                    stepPrecision={0}
                                />
                            )}
                            {!(animationStarted && !!stages && !!shooters) && (
                                <div style={{ fontSize: '84px' }}>0</div>
                            )}
                            <div>{t('rounds')}</div>
                        </div>

                        <div style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0px 72px',
                        }}
                        >
                            {animationStarted && !!stages && !!shooters && (
                                <AnimatedNumber
                                    component='div'
                                    value={shooters.length}
                                    style={{ transition: '0.8s ease-out', fontSize: 84, transitionProperty: 'background-color, color, opacity' }}
                                    duration={500}
                                    stepPrecision={0}
                                />
                            )}
                            {!(animationStarted && !!stages && !!shooters) && (
                                <div style={{ fontSize: '84px' }}>0</div>
                            )}
                            <div>{t('shooters')}</div>
                        </div>
                    </div>
                )}

                {squadsPublished && stagesPublished && shooters && shooters.length > 20 && (
                    <div
                        ref={(e) => { this.numsBreakdownNode = e; this.handleScroll(); }}
                        style={{
                            paddingTop: '0px', paddingBottom: '24px', marginBottom: '24px', textWeight: '400', borderBottom: '1px solid rgba(0,0,0,0.2)', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap',
                        }}
                    >
                        {getAllDivisions({ matchType: match.type }).map((division) => (
                            <Button
                                key={division}
                                onClick={() => this.setState({ breakdownDivision: division })}
                                style={{ display: 'flex', alignItems: 'center' }}
                                color={breakdownDivision === division ? 'primary' : 'default'}
                            >
                                {animationStarted && !!stages && !!shooters && (
                                    <AnimatedNumber
                                        component='div'
                                        value={shooters.filter((s) => s.division === division).length}
                                        style={{
                                            transition: '0.8s ease-out', fontSize: 14, fontWeight: 'bolder', transitionProperty: 'background-color, color, opacity',
                                        }}
                                        duration={500}
                                        stepPrecision={0}
                                    />
                                )}
                                {!(animationStarted && !!stages && !!shooters) && (
                                    <div style={{ fontSize: '14px', fontWeight: 'bolder' }}>0</div>
                                )}
                                <div style={{ fontSize: '14px', padding: '0px 6px' }}>{t(`generic:division-${division}`)}</div>
                            </Button>
                        ))}
                    </div>
                )}

                {breakdownDivision && (
                    <div
                        ref={(e) => { this.numsBreakdownNode = e; this.handleScroll(); }}
                        style={{
                            paddingTop: '0px', paddingBottom: '24px', marginBottom: '24px', textWeight: '400', borderBottom: '1px solid rgba(0,0,0,0.2)', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap',
                        }}
                    >
                        {getAllCategories({ matchType: match.type }).map((category) => (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {animationStarted && !!stages && !!shooters && (
                                    <AnimatedNumber
                                        component='div'
                                        value={shooters.filter((s) => s.division === breakdownDivision && s.category === category).length}
                                        style={{
                                            transition: '0.8s ease-out', fontSize: 14, fontWeight: 'bolder', transitionProperty: 'background-color, color, opacity',
                                        }}
                                        duration={500}
                                        stepPrecision={0}
                                    />
                                )}
                                {!(animationStarted && !!stages && !!shooters) && (
                                    <div style={{ fontSize: '14px', fontWeight: 'bolder' }}>0</div>
                                )}
                                <div style={{ fontSize: '14px', padding: '0px 6px' }}>{t(`registration:category-${category}`)}</div>
                            </div>
                        ))}
                    </div>
                )}

                <div style={{
                    display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginBottom: '12px',
                }}
                >

                    <Paper style={{
                        textAlign: 'center', padding: '12px', width: '300px', margin: '12px',
                    }}
                    >
                        <div style={{ fontSize: '1.2em', marginBottom: '6px' }}>
                            {t('shooters-show-squads-title')}
                        </div>
                        <div style={{ fontSize: '0.8em', height: '30px', marginBottom: '12px' }}>
                            {t('shooters-show-squads-description')}
                        </div>
                        <CtaButtonWrapper published={squadsPublished} notPublishedTooltip={t('shooters-show-squads-disabled')}>
                            <Button
                                component={SquadLink}
                                variant='contained'
                                color='primary'
                                disabled={!squadsPublished}
                                style={{ width: '60%', marginBottom: '12px', textTransform: 'none' }}
                            >
                                {t('shooters-show-squads-cta')}
                            </Button>
                        </CtaButtonWrapper>
                    </Paper>

                    <Paper style={{
                        textAlign: 'center', padding: '12px', width: '300px', margin: '12px',
                    }}
                    >
                        <div style={{ fontSize: '1.2em', marginBottom: '6px' }}>
                            {t('shooters-show-shooters-title')}
                        </div>
                        <div style={{ fontSize: '0.8em', height: '30px', marginBottom: '12px' }}>
                            {t('shooters-show-shooters-description')}
                        </div>
                        <CtaButtonWrapper published={squadsPublished} notPublishedTooltip={t('shooters-show-shooters-disabled')}>
                            <Button
                                component={ShootersLink}
                                variant='contained'
                                color='primary'
                                disabled={!squadsPublished}
                                style={{ width: '60%', marginBottom: '12px', textTransform: 'none' }}
                            >
                                {t('shooters-show-shooters-cta')}
                            </Button>
                        </CtaButtonWrapper>
                    </Paper>
                    <Paper style={{
                        textAlign: 'center', padding: '12px', width: '300px', margin: '12px',
                    }}
                    >
                        <div style={{ fontSize: '1.2em', marginBottom: '6px' }}>
                            {t('shooters-stages-title')}
                        </div>
                        <div style={{ fontSize: '0.8em', height: '30px', marginBottom: '12px' }}>
                            {t('shooters-stages-description')}
                        </div>
                        <CtaButtonWrapper published={stagesPublished} notPublishedTooltip={t('shooters-stages-disabled')}>
                            <Button
                                component={StagesLink}
                                variant='contained'
                                color='primary'
                                disabled={!stagesPublished}
                                style={{ width: '60%', marginBottom: '12px', textTransform: 'none' }}
                            >
                                {t('shooters-stages-cta')}
                            </Button>
                        </CtaButtonWrapper>
                    </Paper>

                    {match.registration !== 'closed' && (
                        <Paper style={{
                            textAlign: 'center', padding: '12px', width: '300px', margin: '12px',
                        }}
                        >
                            <div style={{ fontSize: '1.2em', marginBottom: '6px' }}>
                                {t('shooters-registration-title')}
                            </div>
                            <div style={{ fontSize: '0.8em', height: '30px', marginBottom: '12px' }}>
                                {t('shooters-registration-description')}
                            </div>
                            <CtaButtonWrapper published={match.registration !== 'closed' && !squadsfull} notPublishedTooltip={t(squadsfull ? 'shooters-registration-full' : `shooters-registration-${match.registration}`)}>
                                <Button
                                    component={RegistrationLink}
                                    disabled={match.registration === 'closed'}
                                    variant='contained'
                                    color='primary'
                                    style={{ width: '60%', marginBottom: '12px', textTransform: 'none' }}
                                >
                                    {t('shooters-registration-cta')}
                                </Button>
                            </CtaButtonWrapper>
                        </Paper>
                    )}

                    <Paper style={{
                        textAlign: 'center', padding: '12px', width: '300px', margin: '12px',
                    }}
                    >
                        <div style={{ fontSize: '1.2em', marginBottom: '6px' }}>
                            {t('shooters-show-scores-title')}
                        </div>
                        <div style={{ fontSize: '0.8em', height: '30px', marginBottom: '12px' }}>
                            {t('shooters-show-scores-description')}
                        </div>
                        <CtaButtonWrapper published={!!scoresPublished} notPublishedTooltip={t('shooters-show-scores-disabled')}>
                            <Button
                                component={ScoreTableLink}
                                variant='contained'
                                color='primary'
                                disabled={!scoresPublished}
                                style={{ width: '60%', marginBottom: '12px', textTransform: 'none' }}
                            >
                                {t('shooters-show-scores-cta')}
                            </Button>
                        </CtaButtonWrapper>
                    </Paper>
                </div>
                {match.roRegistration === 'open' && (
                    <div style={{ padding: '0px 12px' }}>
                        <div style={{ fontSize: '1.2em', marginBottom: '6px' }}>
                            {t('ro-registration-title')}
                        </div>
                        <div
                            style={{
                                fontSize: '0.8em', height: '30px', marginBottom: '12px', display: 'flex', alignItems: 'center',
                            }}
                        >
                            <span>
                                {t('ro-registration-description')}
                            </span>
                            <span>
                                <Button
                                    component={RoRegistrationLink}
                                    disabled={match.roRegistration === 'closed'}
                                    variant='text'
                                    color='primary'
                                    style={{ textTransform: 'none' }}
                                >
                                    {t('ro-registration-cta')}
                                </Button>
                            </span>
                        </div>
                        <Typography variant='caption' style={{ display: match.roRegistration !== 'closed' && !rosquadsfull ? 'none' : 'block' }}>
                            {t(rosquadsfull ? 'ro-registration-full' : `ro-registration-${match.roRegistration}`)}
                        </Typography>
                    </div>
                )}

            </div>
        );
    }
}

const styles = (theme) => ({
    lightTooltip: {
        background: theme.palette.common.white,
        color: theme.palette.text.primary,
        boxShadow: theme.shadows[1],
        textAlign: 'center',
        fontSize: 14,
    },
    tooltipPopper: {
        opacity: 1,
    },
});

MatchHome.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        dir: PropTypes.func.isRequired,
    }).isRequired,
    classes: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        alias: PropTypes.string.isRequired,
        about: PropTypes.string,
        description: PropTypes.string,
        registration: PropTypes.string,
        roRegistration: PropTypes.string,
        stages: PropTypes.arrayOf(PropTypes.number),
        squads: PropTypes.arrayOf(PropTypes.shape({
            maxShooters: PropTypes.number,
        })),
        squadsPublished: PropTypes.bool,
        stagesPublished: PropTypes.bool,
        scoresPublished: PropTypes.string,
    }).isRequired,
    announcements: PropTypes.arrayOf(PropTypes.shape({
        created: PropTypes.number,
        id: PropTypes.string,
        subject: PropTypes.string,
        body: PropTypes.string,
    })),
    setTitle: PropTypes.func.isRequired,
};

MatchHome.defaultProps = {
    announcements: null,
};

export default withStyles(styles)(withTranslation('matchhome')(MatchHome));

/* eslint-enable react/no-danger */
