const lang = {
    en: {
        title: '{{- name}} - Registration',
        'category-regular': 'Regular',
        'category-senior': 'Senior',
        'category-super-senior': 'Super-senior',
        'category-lady-senior': 'Lady-senior',
        'category-grand-senior': 'Grand-senior',
        'category-junior': 'Junior',
        'category-super-junior': 'Super-junior',
        'category-lady': 'Lady',
        minor: 'Minor',
        major: 'Major',
        invalid: 'Invalid',
        'power-factor-error': 'Nice try, but I\'ve read the rulebook... There\'s no "major" in your division',
        userexists: 'We already have registered a shooter with this id',
        squadfullerror: 'Requested squad just got full. You\'ll need to pick a different squad',
        unknownshootererror: 'Shooter details cannot be found in existing shooters database.',
        namemismatcherror: 'The name you\'ve entered isn\'t similiar enough to the name we expected to see for this shooter id.',
        noplacesleft: 'Squad full',
        placesleft: '{{count}} place left.',
        placesleft_plural: '{{count}} places left.',
        payment: 'Payment',
        paid: 'Paid',
        'squadselection-after-payment': 'Squad can be picked only after payment.',
        participation: 'Participation',
        cancelledparticipation: 'Participation cancelled',
        cancelling: 'You\'ve requested to cancel this registration. Your request is currently pending MD acknowledgment.',
        cancelcancel: 'Cancel my request, I\'d like to keep this registration',
        participating: 'Participating',
        notparticipating: 'Not participating',
        instructions: 'Registration Instructions',
        'instructions-online-after-payment': 'Please fill the form below and press \'Save\'.\nAfter saving, you\'ll be able to pay for your registration, and after payment, you\'ll be able to pick a squad.\nNote, registration is final only after you have paid and selected a squad.',
        'instructions-offline-after-payment': 'Please fill the form below and press \'Save\'.\nAfter saving, you\'ll be shown payment instructions.\nOnly after we have received and confirmed your payment, you\'ll be able to reserve your spot in a squad.\nNote, registration is final only after you have paid and selected a squad.',
        'instructions-online-during-registration': 'Please fill the form below and press \'Save\'.\nAfter saving, you\'ll be able to pay for your registration.\nNote, registration is final only after payment.',
        'instructions-offline-during-registration': 'Please fill the form below and press \'Save\'.\nAfter saving, you\'ll be shown payment instructions.\nNote, registration is final only after we have confirmed your payment.',
        'payment-status': 'Paypal #{{id}}   |   status: {{state}}',
        'load-payment-info': 'Payment info',
        'reason-for-change': 'Reason for making this change? (for audit)',
        'change-registration': 'Change in registration',
        'change-shooter-request': 'Shooter\'s request (change before the match, or due to a mistake in registration)',
        'change-chrono': 'Chronograph test (for power factor changes)',
        'change-judge-decision': 'Judge\'s decision (eg. not qualify for division)',
        log: 'List of recent changes:',
        chronos: 'List of chronograph tests:',
        chronoconfirmed: '$t(registration:{{nextPowerFactor}}) confirmed.',
        chronochanged: '$t(registration:{{prevPowerFactor}}) test failed. Moved to $t(registration:{{nextPowerFactor}}).',
        equipment: 'Equipment Belt',
        equipmentforward: 'Front of Belt',
        'equipment-edit': 'edit holster, pouches, and magnets',
        'equipment-close': 'close belt editing',
        'equipment-holster': 'Please choose where is your ###holster### located? (H)',
        'equipment-pouches': 'Also, please choose where your ###pouches### are located? (P)',
        'equipment-magnets': 'And finally, where your ###mangets### are located? (M)',
        equipmentchecks: 'Equipment Checks',
        forceedit: 'Are you sure you want to edit a verified shooter\'s information?',
        makero: '+ RO',
        jumpro: 'RO',
        importshooter: 'Import from other match',
        importshooternoshooters: 'All shooters from that match are already registered to yours.',
        importshooternopermission: 'Since this is not your match, we cannot display full shooter information. You\'ll need to fill in some information yourself, or',
        importshooterusepassword: 'login with a password',
        notifyemail: 'Let the shooter know about the change (by email)',
        invalidcategoryparticiaption: 'Cancelling this shooter\'s participation will make "{{division}} {{category}}" ineligible for recognition (according to Appendix A2).',
        invalidcategorydivision: 'Changing this shooter\'s division will make "{{division}} {{category}}" ineligible for recognition (according to Appendix A2).',
        invalidcategorycategory: 'Changing this shooter\'s category will make "{{division}} {{category}}" ineligible for recognition (according to Appendix A2).',
        filterbyname: 'Filter by name',
        limitedshooternotfound: 'Hmmm... We couldn\'t find the Shooter Id in the list of authorized shooters.',
        'squadselection-error': 'Did you forget to pick a squad?',
        termsofuse: 'I agree to the ###Terms of Service### and ###Privacy Policy###.',
        comments: 'Comments',
        tocomments: '[open]',
        nocomments: ' - None -',
        cancel: 'Request to cancel registration',
        cancellationrequest: 'This shooter has asked to cancel his/her registration. Should we cancel it?',
        cancellationrequestapprove: 'Yes, cancel this registration',
        cancellationrequestdecline: 'No, keep this registration',
        cancellationrequestlog: 'Cancellation Request',
        cancellationrequested: 'Requested',
        cancellationrequesterased: 'Not requested',
    },
    he: {
        title: '{{- name}} - רישום יורים',
        'category-regular': 'כללי',
        'category-senior': 'סניור',
        'category-super-senior': 'סופר סניור',
        'category-lady-senior': 'ליידי סניור',
        'category-grand-senior': 'גראנד סניור',
        'category-junior': 'ג׳וניור',
        'category-super-junior': 'סופר ג׳וניור',
        'category-lady': 'נשים',
        minor: 'מיינור',
        major: 'מייג׳ור',
        invalid: 'לא רשום',
        'power-factor-error': 'ניסיון נחמד, אבל אי אפשר להיות מייג׳ור במחלקה שבחרתם',
        userexists: 'יש לנו כבר יורה עם המספר הזה במערכת',
        squadfullerror: 'הסקווד המבוקש בדיוק התמלא. תצטרכו לבחור סקווד אחר.',
        unknownshootererror: 'פרטי היורה שהוכנסו לא נמצאו ברשימת היורים בארץ. אנא וודאו שאין לכם טעות כתיב, או צרו קשר עם מנהל המועדון שלכם.',
        namemismatcherror: 'השם שהכנסתם לא דומה לשם ברשימות.',
        noplacesleft: 'סקווד מלא',
        placesleft_0: 'מקום פנוי אחד נשאר',
        placesleft_1: '{{count}} מקומות פנויים',
        placesleft_2: '{{count}} מקומות פנויים',
        placesleft_3: '{{count}} מקומות פנויים',
        placesleft_4: '{{count}} מקומות פנויים',
        placesleft_5: '{{count}} מקומות פנויים',
        placesleft_plural: '{{count}} מקומות פנויים',
        payment: 'תשלום',
        paid: 'שולם',
        'squadselection-after-payment': 'ניתן לבחור סקווד רק לאחר תשלום',
        participation: 'השתתפות',
        cancelledparticipation: 'השתתפות בתחרות בוטלה',
        participating: 'משתתף',
        notparticipating: 'לא משתתף',
        instructions: 'הוראות הרשמה',
        'instructions-online-after-payment': 'לאחר הכנסת הנתונים יש ללחוץ על שמור.\nלאחר השמירה תנתן האפשרות לתשלום, ולאחר התשלום, האפשרות לבחור סקווד.\nשימו לב, הרשמה סופית רק לאחר תשלום ובחירת סקווד.',
        'instructions-offline-after-payment': 'לאחר הכנסת הנתונים יש ללחוץ על שמור.\nלאחר השמירה נציג בפניכם הוראות תשלום. רק לאחר שקיבלנו ואישרנו את התשלום, תוכלו לשמור לעצמכם מקום בסקווד.\nשימו לב, הרשמה סופית רק לאחר תשלום ובחירת סקווד.',
        'instructions-online-during-registration': 'לאחר הכנסת הנתונים יש ללחוץ על שמור.\nלאחר השמירה תנתן האפשרות לתשלום.\nשימו לב, הרשמה סופית רק לאחר תשלום.',
        'instructions-offline-during-registration': 'לאחר הכנסת הנתונים יש ללחוץ על שמור.\nלאחר השמירה נציג בפניכם הוראות תשלום.\nשימו לב, הרשמה סופית רק לאחר שקיבלנו ואישרנו את התשלום.',
        'payment-status': 'פייפל #{{id}}   |   סטטוס: {{state}}',
        'load-payment-info': 'פרטי תשלום',
        'reason-for-change': 'סיבה לשינוי? (ללוג)',
        'change-registration': 'שינוי בהרשמה',
        'change-shooter-request': 'לפי בקשת היורה (לדוגמא, בעת טעות בהרשמה)',
        'change-chrono': 'לפי בדיקת כרונו (שינוי בסוג התחמושת בלבד)',
        'change-judge-decision': 'לפי סעיף 6.2.5.1 (ציוד לא מתאים למחלקה)',
        log: 'רשימת שינויים:',
        chronos: 'רשימת בדיקות כרונוגרף:',
        chronoconfirmed: 'בדיקת $t(registration:{{nextPowerFactor}}) עברה בהצלחה.',
        chronochanged: 'בדיקת $t(registration:{{prevPowerFactor}}) נכשלה. רישום היורה שונה ל$t(registration:{{nextPowerFactor}}).',
        equipment: 'חגורה',
        equipmentforward: 'צד קדמי של החגורה',
        'equipment-edit': 'ערוך את הציוד על החגורה',
        'equipment-close': 'סגור אפשרויות עריכה',
        'equipment-holster': 'היכן ממוקם ###נרתיק האקדח###? (H)',
        'equipment-pouches': 'בנוסף, היכן ממוקמות ###הפונדות###? (P)',
        'equipment-magnets': 'ולבסוף, היכן ממוקמים ###המגנטים###? (M)',
        forceedit: 'לציוד היורה כבר בוצע אימות. האם אתם בטוחים שאתם רוצים לערוך?',
        makero: '+ שופט\\ת',
        jumpro: 'שופט\\ת',
        importshooter: 'ייבוא יורה מתחרות קודמת',
        importshooternoshooters: 'כל היורים מהתחרות הזו כבר רשומים לתחרות לשלך.',
        importshooternopermission: 'מכיוון שזו איננה תחרות שלך, לא ניתן להציג פרטים מלאים של היורה. יש להשלים פרטים ידנית, או',
        importshooterusepassword: 'להתחבר עם סיסמא',
        notifyemail: 'עדכן את היורה על השינוי (באימייל)',
        invalidcategoryparticiaption: 'ביטול היורה יגרור ביטול הזכות של קטגוריה "{{division}} {{category}}" לפרסים (לפי פרק A2).',
        invalidcategorydivision: 'שינוי המחלקה של היורה יגרור ביטול הזכות של קטגוריה "{{division}} {{category}}" לפרסים (לפי פרק A2).',
        invalidcategorycategory: 'שינוי הקטגוריה של היורה יגרור ביטול הזכות של קטגוריה "{{division}} {{category}}" לפרסים (לפי פרק A2).',
        filterbyname: 'חיפוש לפי שם',
        limitedshooternotfound: 'הממ... לא מצאנו את מספר היורה שהכנסתם ברשימת היורים המוכרים לתחרות הזו.',
        'squadselection-error': 'שכחת לבחור סקווד?',
        termsofuse: 'אני מסכים ל###תנאי השימוש### ו###הסכם הפרטיות###. המידע שאני מכניס הוא שלי, או שניתן לי רשות ע״י בעליו להכניסו עבורו\\ה.',
        termsofuseIL: 'אני מסכים ל###תנאי השימוש### של התאחדות הקליעה.',
        comments: 'הערות על היורה',
        tocomments: '[פתח]',
        nocomments: ' - אין הערות -',
        cancelling: 'בקשתך לביטול ההרשמה נשלחה למנהל\\ת התחרות לאישור וביצוע. אנו נשלח אימייל נוסף שמאשר את הביטול.',
        cancelcancel: 'במחשבה שניה, אשמח לשמור על ההרשמה',
        cancel: 'ארצה לבטל את ההרשמה',
        cancellationrequest: 'היורה ביקש\\ה לבטל את השתתפותו\\ה. האם לאשר את הביטול?',
        cancellationrequestapprove: 'כן, מחק יורה מהתחרות',
        cancellationrequestdecline: 'לא, שמור יורה בתחרות',
        cancellationrequestlog: 'בקשה לביטול',
        cancellationrequested: 'התקבלה בקשה',
        cancellationrequesterased: 'ללא בקשה',
    },
};

lang.iw = {
    ...lang.he,
    ...{
        squadfullerror: 'המקצה המבוקש בדיוק התמלא. תצטרכו לבחור מקצה אחר.',
        noplacesleft: 'מקצה מלא',
        'squadselection-after-payment': 'ניתן לבחור מקצה רק לאחר תשלום',
        'instructions-online-after-payment': 'לאחר הכנסת הנתונים יש ללחוץ על שמור.\nלאחר השמירה תנתן האפשרות לתשלום, ולאחר התשלום, האפשרות לבחור מקצה.\nשימו לב, הרשמה סופית רק לאחר תשלום ובחירת מקצה.',
        'instructions-offline-after-payment': 'לאחר הכנסת הנתונים יש ללחוץ על שמור.\nלאחר השמירה נציג בפניכם הוראות תשלום. רק לאחר שקיבלנו ואישרנו את התשלום, תוכלו לשמור לעצמכם מקום במקצה.\nשימו לב, הרשמה סופית רק לאחר תשלום ובחירת מקצה.',
        'squadselection-error': 'שכחת לבחור מקצה?',
        title: '{{- name}} - רישום לוחמים',
        userexists: 'יש לנו כבר לוחם\\ת עם המספר הזה במערכת',
        unknownshootererror: 'פרטי הלוחם\\ת שהוכנסו לא נמצאו ברשימת הלוחמים בארץ. אנא וודאו שאין לכם טעות כתיב, או צרו קשר עם מנהל היחידה שלכם.',
        'change-shooter-request': 'לפי בקשת הלוחם\\ת (לדוגמא, בעת טעות בהרשמה)',
        chronochanged: 'בדיקת $t(registration:{{prevPowerFactor}}) נכשלה. רישום הלוחם\\ת שונה ל$t(registration:{{nextPowerFactor}}).',
        forceedit: 'לציוד הלוחם\\ת כבר בוצע אימות. האם אתם בטוחים שאתם רוצים לערוך?',
        importshooter: 'ייבוא לוחם\\ת מתחרות קודמת',
        importshooternoshooters: 'כל הלוחמים מהתחרות הזו כבר רשומים לתחרות לשלך.',
        importshooternopermission: 'מכיוון שזו איננה תחרות שלך, לא ניתן להציג פרטים מלאים של הלוחם\\ת. יש להשלים פרטים ידנית, או',
        notifyemail: 'עדכן את הלוחם\\ת על השינוי (באימייל)',
        invalidcategoryparticiaption: 'ביטול הלוחם\\ת יגרור ביטול הזכות של קטגוריה "{{division}} {{category}}" לפרסים (לפי פרק A2).',
        invalidcategorydivision: 'שינוי המחלקה של הלוחם\\ת יגרור ביטול הזכות של קטגוריה "{{division}} {{category}}" לפרסים (לפי פרק A2).',
        invalidcategorycategory: 'שינוי הקטגוריה של הלוחם\\ת יגרור ביטול הזכות של קטגוריה "{{division}} {{category}}" לפרסים (לפי פרק A2).',
        limitedshooternotfound: 'הממ... לא מצאנו את מספר הלוחם\\ת שהכנסתם ברשימת הלוחמים המוכרים לתחרות הזו.',
        comments: 'הערות על הלוחם\\ת',
        cancellationrequest: 'הלוחם\\ת ביקש\\ה לבטל את השתתפותו\\ה. האם לאשר את הביטול?',
        cancellationrequestapprove: 'כן, מחק לוחם\\ת מהתחרות',
        cancellationrequestdecline: 'לא, שמור לוחם\\ת בתחרות',
        'category-men': 'לוחמים',
        'category-women': 'לוחמות',
        'category-reserves': 'מילואים',
    },
};

export default lang;
