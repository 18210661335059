import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import cyan from '@material-ui/core/colors/cyan';
import app from '../AppCommunication';

class PushNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mounted: false,
        };
    }

    componentDidMount() {
        setTimeout(() => this.setState({ mounted: true }), 100);
    }

    onYes = () => {
        const { accessToken, onDone } = this.props;
        app({ cmd: { src: 'push-notifications', type: 'yes', params: { accessToken } } });
        window.onPostDone = async () => {
            await new Promise((res) => this.setState({ mounted: false }, res));
            await new Promise((res) => setTimeout(res, 500));
            onDone();
        };
    }

    onNo = async () => {
        const { accessToken, onDone } = this.props;
        app({ cmd: { src: 'push-notifications', type: 'no', params: { accessToken } } });
        await new Promise((res) => this.setState({ mounted: false }, res));
        await new Promise((res) => setTimeout(res, 500));
        onDone();
    }

    render() {
        const { t, classes, messaging } = this.props;
        const { mounted } = this.state;
        return (
            <div className={classnames({ [classes.wrapper]: true, [classes.mounted]: mounted })}>
                <i className='far fa-comments' style={{ color: 'white', fontSize: '102px', padding: '48px 0px 0px 0px' }} />
                <Typography variant='h4' style={{ paddingBottom: '12px' }}>{t(`${messaging}-title`)}</Typography>
                <Typography variant='body1' style={{ padding: '12px 12px 2px', fontSize: '18px', textAlign: 'center' }}>{t(`${messaging}-description1`)}</Typography>
                <Typography variant='body1' style={{ padding: '0px 12px', fontSize: '18px', textAlign: 'center' }}>{t(`${messaging}-description2`)}</Typography>
                <Button variant='outlined' classes={{ root: classes.button }} onClick={this.onYes}>{t(`${messaging}-yes`)}</Button>
                <Button variant='text' style={{ margin: '0px 12px', marginTop: '24px', fontSize: '12px' }} onClick={this.onNo}>{t(`${messaging}-no`)}</Button>
            </div>
        );
    }
}

PushNotification.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    accessToken: PropTypes.string.isRequired,
    onDone: PropTypes.func.isRequired,
    messaging: PropTypes.string,
};

PushNotification.defaultProps = {
    messaging: 'ro',
};

const styles = () => ({
    wrapper: {
        position: 'fixed',
        opacity: 0,
        transition: 'opacity 200ms ease-in-out',
        top: '0px',
        left: '0px',
        width: '100vw',
        height: '100vh',
        background: cyan[500],
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        '& *': {
            color: 'white',
        },
    },
    mounted: {
        opacity: 1,
    },
    button: {
        borderColor: 'white',
        marginTop: '24px',
        padding: '24px',
    },
});

export default withStyles(styles, { withTheme: true })(withTranslation('pushnotification')(PushNotification));
