import 'seedrandom';
import React, { Component, useEffect } from 'react';
import { Route, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import TextField from '@material-ui/core/TextField';
import red from '@material-ui/core/colors/red';
import ScoreStage from './Score.stage';
import Registration from './Registration';
import Snackbar from '../components/Snackbar';
import api from '../Api';

class ScoreWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            match: null,
            stages: null,
            stageIdx: 1,
            error: null,
            success: null,
        };
    }

    async componentDidMount() {
        const {
            match: propMatch, stages: propStages, shooters: propShooters, demo, auth,
        } = this.props;

        await Promise.all([
            (async () => {
                const $match = await (async () => {
                    if (demo) return propMatch;
                    return api.getMatch({ matchId: propMatch.id });
                })();

                const $stages = await (async () => {
                    if (demo) return propStages;
                    return api.getStages({ stages: $match.stages });
                })();

                this.setState({ match: $match, stages: $stages });
            })(),

            (async () => {
                if (demo) {
                    this.setState({ shooters: propShooters });
                } else {
                    const $shooters = await api.getShooters({ matchId: propMatch.id, auth });

                    const offlineCache = JSON.parse(window.localStorage.getItem('__offline_cache') || '[]')
                        .filter((s) => s.matchId === propMatch.id && s.timestamp > new Date().getTime() - 2 * 24 * 60 * 60 * 1000)
                        .filter((s) => s.type === 'attendance');

                    offlineCache.forEach((item) => {
                        _.each(item.data.checks, (check, k) => {
                            const $shooter = $shooters.find((s) => s.id === k);
                            $shooter.equipmentChecks = $shooter.equipmentChecks || [];
                            while ($shooter.equipmentChecks.length < item.data.stageIdx) {
                                $shooter.equipmentChecks.push(null);
                            }
                            $shooter.equipmentChecks[item.data.stageIdx - 1] = (($shooter.equipmentChecks[item.data.stageIdx - 1] || {}).timestamp || 0) > item.timestamp
                                ? $shooter.equipmentChecks[item.data.stageIdx - 1] : { check, timestamp: item.timestamp };
                        });
                    });
                    this.setState({ shooters: $shooters });
                }
            })(),
        ]);
    }

    onError = (error) => {
        this.setState({ error });
    }

    onSuccess = (success) => {
        this.setState({ success });
    }

    render() {
        const { t } = this.props;
        const {
            match, stages, shooters, stageIdx, error, success,
        } = this.state;

        if (!stages) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <CircularProgress size={75} />
                </div>
            );
        }

        // overflow x hidden for attendance
        return (
            <div id='__mobile_scoring_wrapper' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <div style={{ width: '90%', maxWidth: '720px' }}>
                    <Route
                        path={['/:matchId/manage/scores', '/scoring-demo/:targets']}
                        exact
                        render={(routeProps) => (
                            <ScoreInner
                                stageIdx={stageIdx}
                                setStageIdx={(v) => this.setState({ stageIdx: v })}
                                {...this.props}
                                routeProps={routeProps}
                                match={match}
                                stages={stages}
                                onError={this.onError}
                                onSuccess={this.onSuccess}
                            />
                        )}
                    />
                    <Route
                        path={['/:matchId/manage/scores/stage/:stageIdx', '/scoring-demo/:targets/stage/:stageIdx2']}
                        render={(routeProps) => (
                            <ScoreStage
                                {...this.props}
                                routeProps={routeProps}
                                match={match}
                                stages={stages}
                                shooters={shooters}
                                targets={routeProps.match.params.targets}
                                stageIdx={parseInt(routeProps.match.params.stageIdx || routeProps.match.params.stageIdx2, 10)}
                                onError={this.onError}
                                onSuccess={this.onSuccess}
                                onUpdateShooters={($shooters) => this.setState({ shooters: $shooters })}
                            />
                        )}
                    />

                    <Route
                        path='/:matchId/manage/scores/edit-shooter/:shooterId'
                        exact
                        render={(routeProps) => {
                            if (!shooters) {
                                return (
                                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                        <CircularProgress size={75} />
                                    </div>
                                );
                            }
                            return (
                                <Registration
                                    {...this.props}
                                    mode='line-officer'
                                    manage
                                    edit={shooters.find((s) => s.id === routeProps.match.params.shooterId)}
                                    onSuccess={async (shooter) => {
                                        if (!_.isEmpty(shooter.id)) {
                                            await new Promise((res) => this.setState({
                                                shooters: shooters.map((s) => (s.id === routeProps.match.params.shooterId ? shooter : s)),
                                            }, res));
                                            this.onSuccess(t('generic:savesuccess'));
                                        }
                                        routeProps.history.goBack();
                                    }}
                                />
                            );
                        }}
                    />

                    <Snackbar
                        open={!!error}
                        onClose={() => this.setState({ error: null })}
                        variant='error'
                        message={error}
                    />

                    <Snackbar
                        open={!!success}
                        onClose={() => this.setState({ success: null })}
                        variant='success'
                        message={success}
                    />
                </div>
            </div>
        );
    }
}

ScoreWrapper.propTypes = {
    t: PropTypes.func.isRequired,
    auth: PropTypes.string.isRequired,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    demo: PropTypes.bool,
    stages: PropTypes.arrayOf(PropTypes.shape({})),
    shooters: PropTypes.arrayOf(PropTypes.shape({})),
};

ScoreWrapper.defaultProps = {
    demo: false,
    stages: null,
    shooters: null,
};

function ScoreInner(props) {
    const {
        t, setTitle, setSaveButtonCallback, setSaveButtonLabel, setExtraButtons, setBackButtonVisible,
        match, stages, routeProps, stageIdx, setStageIdx, me,
    } = props;

    const {
        match: routeMatch,
        history,
    } = routeProps;

    useEffect(() => {
        setTitle(t('managewrapper:scores'));
        setSaveButtonLabel(null);
        setSaveButtonCallback(null);
        setExtraButtons(null);
        setBackButtonVisible(false);
    }, []);

    const max = stages.length;

    if (stages.length === 0) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
            >
                <Typography variant='h5'>
                    {t('nostages1')}
                </Typography>
                {(me.permissions.indexOf('stages') === -1) && (
                    <Typography variant='body1' style={{ marginTop: '12px' }}>
                        {t('nostagespermission')}
                    </Typography>
                )}
                {(me.permissions.indexOf('stages') > -1) && (
                    <div>
                        <Typography variant='body1' style={{ marginTop: '12px' }}>
                            {t('nostages2')}
                        </Typography>
                        <Typography variant='body1'>
                            {t('nostages3')}
                        </Typography>
                        <Button
                            style={{ marginTop: '12px' }}
                            color='primary'
                            variant='contained'
                            component={($props) => <Link to={`/${match.alias}/manage/stages`} {...$props} />}
                        >
                            {t('nostagescta')}
                        </Button>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h4>{t('stage')}</h4>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '24px' }}>
                <Button variant='contained' color='secondary' disabled={stageIdx === 1} onClick={() => setStageIdx(stageIdx - 1)} style={{ width: '60px', height: '60px', margin: '0px 12px' }}>
                    <RemoveIcon />
                </Button>
                <TextField
                    inputProps={{ style: { textAlign: 'center', fontSize: '1.5em', width: '120px' } }}
                    value={stageIdx}
                    onChange={(e) => setStageIdx(e.target.value)}
                    type='number'
                />
                <Button variant='contained' color='primary' disabled={stageIdx === max} onClick={() => setStageIdx(parseInt(stageIdx, 10) + 1)} style={{ width: '60px', height: '60px', margin: '0px 12px' }}>
                    <AddIcon />
                </Button>
            </div>
            <Button
                disabled={stages[stageIdx - 1].inactive}
                variant='contained'
                color='primary'
                size='large'
                onClick={() => history.push(`${routeMatch.url}/stage/${stageIdx}${history.location.search}`)}
                style={{ width: '272px', marginBottom: '24px' }}
            >
                {t('generic:next')}
            </Button>
            <Typography
                variant='caption'
                style={{ color: red[500], textAlign: 'center', visibility: stages[stageIdx - 1].inactive ? 'visible' : 'hidden' }}
            >
                {t('stages:inactive')}
            </Typography>
            <Button style={{ marginTop: '60px' }} onClick={() => history.push(`/${match.id}/manage/history${history.location.search}`)}>
                <Typography variant='caption'>{t('gotohistory')}</Typography>
            </Button>
        </div>
    );
}

ScoreInner.propTypes = {
    stageIdx: PropTypes.number.isRequired,
    setStageIdx: PropTypes.func.isRequired,
    routeProps: PropTypes.shape({
        history: ReactRouterPropTypes.history.isRequired,
        match: ReactRouterPropTypes.match.isRequired,
    }).isRequired,
    setTitle: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func.isRequired,
    setSaveButtonLabel: PropTypes.func.isRequired,
    setBackButtonVisible: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    me: PropTypes.shape({
        permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    stages: PropTypes.arrayOf(PropTypes.shape({
        inactive: PropTypes.bool,
    })).isRequired,
};

const styles = {};

export default withStyles(styles, { withTheme: true })(withTranslation('score')(ScoreWrapper));
