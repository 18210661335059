import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Snackbar from '../components/Snackbar';
import api from '../Api';

function ManageComments(props) {
    const {
        classes, me, t, auth, matchId, shooterId,
        setBackButtonVisible, setTitle, setSaveButtonLabel,
        setSaveButtonCallback, setExtraButtons,
    } = props;
    const [working, setWorking] = useState(false);
    const [comment, setComment] = useState('');
    const [shooter, setShooter] = useState(null);
    const [error, setError] = useState({ show: false, text: '' });

    useEffect(() => {
        setBackButtonVisible(true);
        if (setExtraButtons) setExtraButtons(null);
        if (setSaveButtonLabel) setSaveButtonLabel(null);
        if (setSaveButtonCallback) setSaveButtonCallback(null);
        window.scrollTo(0, 0);
        (async () => {
            const $shooter = await api.getShooter({ matchId, shooterId, auth });
            setShooter($shooter);
            setTitle($shooter.name);
        })();
    }, []);

    if (!shooter) {
        return (
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <CircularProgress size={75} />
            </div>
        );
    }

    async function onSend() {
        setWorking(true);
        try {
            const $shooter = await api.commentShooter({
                matchId, shooterId, auth, comment,
            });
            setWorking(false);
            setComment('');
            setShooter($shooter);
        } catch (e) {
            setWorking(false);
            setError({ show: true, text: t('generic:saveerror') });
        }
    }

    return (
        <div className={classes.wrapper}>
            {(shooter.comments || []).map((c) => (
                <div key={c.timestamp} style={{ padding: '6px 0px' }}>
                    <Paper elevation={1} className={classes.comment}>
                        <div>
                            <Typography variant='body1'>
                                {c.comment}
                            </Typography>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingTop: '6px',
                            }}
                        >
                            <Typography variant='caption'>
                                {c.name}
                            </Typography>
                            <Typography variant='caption'>
                                {moment(c.timestamp).format('LL LT')}
                            </Typography>
                        </div>
                    </Paper>
                </div>
            ))}
            {(shooter.comments || []).length === 0 && (
                <Typography variant='body2' style={{ paddingBottom: '12px' }}>
                    {t('nocomments')}
                </Typography>
            )}
            <TextField
                fullWidth
                variant='outlined'
                multiline
                rows={5}
                className={classes.textfield}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
            />
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingTop: '6px',
                }}
            >
                <Typography variant='caption'>
                    {me.name}
                </Typography>
                <Button variant='contained' color='primary' disabled={working} onClick={onSend}>
                    {working ? <CircularProgress size={24} /> : t('send')}
                </Button>
            </div>
            <Snackbar
                open={error.show}
                onClose={() => setError({ ...error, show: false })}
                variant='error'
                message={error.text}
            />
        </div>
    );
}

const styles = () => ({
    wrapper: {
        maxWidth: '720px',
        width: '90%',
        margin: 'auto',
    },
    textfield: {
        background: 'white',
    },
    comment: {
        padding: '12px',
    },
});

ManageComments.propTypes = {
    t: PropTypes.func.isRequired,
    me: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    matchId: PropTypes.string.isRequired,
    shooterId: PropTypes.string.isRequired,
    auth: PropTypes.string.isRequired,
    setBackButtonVisible: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    setSaveButtonLabel: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(withTranslation('managecomments')(ManageComments));
