import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import ReactFlagsSelect from 'react-flags-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import Wrapper from './HomeI18nWrapper';
import GoogleSignIn from '../components/GoogleSignIn';
import FacebookSignIn from '../components/FacebookSignIn';
import AppleSignIn from '../components/AppleSignIn';
// import AppStorage from '../components/AppStorage';
import LocalStorage from '../components/LocalStorage';
import api from '../Api';
import HomeAppMeUser from './HomeApp.me.loggedin';

function HomeAppMe(props) {
    const {
        t, classes, mode, src, accessToken: queryAccessToken, name: queryName,
    } = props;
    const storage = new LocalStorage(); // mode === 'app' ? new AppStorage() : new LocalStorage();

    const [thinking, setThinking] = useState(false);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        (async () => {
            const prev = await storage.get('__homeapp_userdashboard_accesstoken');
            if (prev) {
                await setThinking(true);
                const prevLogin = JSON.parse(prev);
                try {
                    const login = await api.getLogin({ accessToken: prevLogin.accessToken });
                    await storage.set('__homeapp_userdashboard_accesstoken', JSON.stringify(login));
                    setUserData({
                        mode,
                        accessToken: login.accessToken,
                        name: login.login.name,
                        registered: true,
                    });
                    await setThinking(false);
                    return;
                } catch (e) {
                    setThinking(false);
                }
            }
            if (queryAccessToken) {
                onSocialLogin({ accessToken: queryAccessToken, name: queryName });
            }
        })();
    }, []);

    async function onSocialLogin({ accessToken, name }) {
        await setThinking(true);
        await storage.set('__homeapp_userdashboard_state', JSON.stringify({}));
        try {
            const login = await api.getLogin({ accessToken });
            if (!login.login.name || !login.login.publicId) {
                setUserData({
                    mode,
                    auth: accessToken,
                    name,
                    registered: false,
                });
            } else {
                onUserRegistered(login);
            }
        } catch (ex) {
            if (((ex || {}).response || {}).status === 404) {
                setUserData({
                    mode,
                    auth: accessToken,
                    name,
                    registered: false,
                });
                setThinking(false);
            }
            setThinking(false);
        }
    }

    async function onUserRegistered(login) {
        await storage.set('__homeapp_userdashboard_accesstoken', JSON.stringify(login));
        setUserData({
            mode,
            accessToken: login.accessToken,
            name: login.login.name,
            registered: true,
        });
        setThinking(false);
    }

    async function logout() {
        await storage.set('__homeapp_userdashboard_accesstoken', null);
        setUserData(null);
    }

    async function onGoogleLogin(e) {
        return onSocialLogin({ accessToken: `com.google|${e.authResponse.id_token}`, name: `${e.givenName} ${e.familyName}` });
    }

    async function onFacebookLogin(e) {
        return onSocialLogin({ accessToken: `com.facebook|${e.accessToken}`, name: e.name });
    }

    function onGoogleLogout() {
    }

    function onFacebookLogout() {
    }

    if (thinking) {
        return (
            <div className={classes.loadingwrapper}>
                <CircularProgress thickness={1} size={72} />
            </div>
        );
    }

    if (userData) {
        if (!userData.registered) {
            return (
                <HomeAppContactDetails auth={userData.auth} loginName={userData.name.split(' ')[0]} t={t} onDone={onUserRegistered} />
            );
        }

        return <HomeAppMeUser {..._.omit(props, ['classes', 'accessToken', 'name'])} {...userData} onLogout={logout} />;
    }

    return (
        <div className={classes.loadingwrapper}>
            <div className={classes.notconnectedbox}>
                <Typography variant='h4' style={{ color: blue[500] }}>
                    {t('comehereoften')}
                </Typography>
                <Typography variant='h6' style={{ color: grey[800] }}>
                    {t('letsconnect')}
                </Typography>
                <div className={classes.featuresbox}>
                    <div className={classes.feature}>
                        <i className='fas fa-user-graduate' style={{ fontSize: '48px', marginBottom: '12px', color: blue[500] }} />
                        <Typography variant='subtitle1' style={{ color: blue[500], marginBottom: '6px' }}>
                            {t('smarter')}
                        </Typography>
                        <Typography variant='caption'>
                            {t('smarterdescription')}
                        </Typography>
                    </div>
                    <div className={classes.feature}>
                        <i className='fas fa-running' style={{ fontSize: '48px', marginBottom: '12px', color: blue[500] }} />
                        <Typography variant='subtitle1' style={{ color: blue[500], marginBottom: '6px' }}>
                            {t('faster')}
                        </Typography>
                        <Typography variant='caption'>
                            {t('fasterdescription')}
                        </Typography>
                    </div>
                    <div className={classes.feature}>
                        <i className='fas fa-child' style={{ fontSize: '48px', marginBottom: '12px', color: blue[500] }} />
                        <Typography variant='subtitle1' style={{ color: blue[500], marginBottom: '6px' }}>
                            {t('easier')}
                        </Typography>
                        <Typography variant='caption'>
                            {t('easierdescription')}
                        </Typography>
                    </div>
                </div>
                <Typography variant='h6' style={{ color: grey[800] }}>
                    {t('shootbetter')}
                </Typography>
                <Typography variant='caption' style={{ color: grey[800] }}>
                    {t('shootbetternot')}
                </Typography>
                <div className={classes.signinbutton} style={{ marginTop: '12px' }}>
                    <GoogleSignIn noAuto mode={mode} onLogin={onGoogleLogin} onLogout={onGoogleLogout} />
                </div>
                <div className={classes.signinbutton}>
                    <FacebookSignIn mode={mode} system='manage' noAuto={src !== 'facebook'} onLogin={onFacebookLogin} onLogout={onFacebookLogout} />
                </div>
                <div className={classes.signinbutton}>
                    <AppleSignIn mode={mode} noAuto />
                </div>
            </div>
        </div>
    );
}

HomeAppMe.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    t: PropTypes.func.isRequired,
    mode: PropTypes.string,
    src: PropTypes.string,
    accessToken: PropTypes.string,
    name: PropTypes.string,
};

HomeAppMe.defaultProps = {
    mode: null,
    src: null,
    accessToken: null,
    name: null,
};

const styles = () => ({
    loadingwrapper: {
        width: '100vw',
        height: 'calc(100vh - 56px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    notconnectedbox: {
        textAlign: 'center',
        width: '100%',
    },
    featuresbox: {
        margin: 'auto',
        marginBottom: '12px',
        marginTop: '12px',
        display: 'flex',
        width: 'calc(90% - 16px)',
        padding: '6px',
        background: 'white',
        border: `2px solid ${grey[300]}`,
        borderRadius: '12px',
    },
    feature: {
        padding: '12px',
        flex: '1',
    },
    signinbutton: {
        marginTop: '6px',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'start',
    },
    loggedinwrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    avatar: {
        marginTop: '24px',
        marginBottom: '6px',
        width: '72px',
        height: '72px',
        borderRadius: '50%',
        backgroundColor: 'white',
        fontSize: '48px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: grey[800],
    },
});

export default Wrapper(withStyles(styles, { withTheme: true, flip: false })(withTranslation('homeappme')(HomeAppMe)));

function HomeAppContactDetails(props) {
    const {
        t, loginName, auth, onDone,
    } = props;

    const [publicId, setPublicId] = useState('US|');
    const [name, setName] = useState('');
    const [errors, setErrors] = useState({});
    const [thinking, setThinking] = useState(false);

    const [pIdCountryCode = '', pIdId = ''] = (publicId || '').split('|');

    const newShooterFields = {};

    async function onButton() {
        const $errors = {};
        if (!pIdCountryCode) $errors.pId = true;
        if (!pIdId) $errors.pId = true;
        if (!name) $errors.name = true;
        if (Object.keys($errors).length > 0) {
            setErrors($errors);
        } else {
            setThinking(true);
            const login = await api.postLogin({ accessToken: auth, publicId: `${pIdCountryCode}|${pIdId}`, name });
            setThinking(false);
            onDone(login);
        }
    }

    return (
        <div
            style={{
                width: '90%',
                margin: 'auto',
                marginTop: '24px',
                textAlign: 'center',
            }}
        >
            <Typography variant='h4'>
                {`Hi ${loginName},`}
            </Typography>
            <Typography variant='body1' style={{ marginTop: '24px' }}>Care to give us some details?</Typography>
            <Typography variant='caption'>We need them so we can find all your matches.</Typography>

            <div
                style={{
                    marginTop: '24px', display: 'flex', flex: '1', width: '100%',
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                    <FormLabel><Typography variant='caption'>{t('generic:region')}</Typography></FormLabel>
                    <div style={{ position: 'relative', top: '-4px' }}>
                        <ReactFlagsSelect defaultCountry={pIdCountryCode} onSelect={(countryCode) => setPublicId(`${countryCode}|${pIdId}`)} />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                    <FormLabel><Typography variant='caption'>{t('generic:shooter_regional_id')}</Typography></FormLabel>
                    <TextField
                        value={pIdId}
                        error={errors.pId}
                        onChange={(e) => {
                            setPublicId(`${pIdCountryCode}|${e.target.value}`);
                            setErrors({ ...errors, pId: false });
                        }}
                    />
                </div>
            </div>

            <div style={{ marginTop: '24px' }}>
                <TextField
                    style={{ width: '100%', minWidth: '100%' }}
                    label={t('generic:shooter_name')}
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                        setErrors({ ...errors, name: false });
                    }}
                    onKeyDown={(e) => { if (e.keyCode === 13) onButton(); }}
                    error={errors.name}
                    inputRef={(e) => { newShooterFields.name = e; }}
                />
            </div>

            <div style={{ marginTop: '36px' }}>
                <Button
                    fullWidth
                    disabled={thinking}
                    color='primary'
                    variant='contained'
                    onClick={onButton}
                >
                    {thinking && <CircularProgress size={18} />}
                    {!thinking && <span>Next</span>}
                </Button>
            </div>
        </div>
    );
}

HomeAppContactDetails.propTypes = {
    t: PropTypes.func.isRequired,
    loginName: PropTypes.string.isRequired,
    auth: PropTypes.string.isRequired,
    onDone: PropTypes.func,
};

HomeAppContactDetails.defaultProps = {
    onDone: () => {},
};
