/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { MySnackbarContentWrapper } from '../components/Snackbar';
import HtmlEditor from '../components/HtmlEditor';
import api from '../Api';

class AnnouncementsEditor extends Component {
    constructor(props) {
        const { t, setTitle } = props;
        super(props);
        setTitle(t('managewrapper:announcements'));
        this.state = {
            announcements: null,
            subject: '',
            announceBy: 'website',
            announceByEmailTo: 'all',
            body: '',
            errors: {},
            posting: false,
            postingError: false,
            postingSuccess: false,
        };
    }

    async UNSAFE_componentWillMount() {
        const { matchId, auth } = this.props;
        const announcements = await api.getAnnouncements({ matchId, accessToken: auth }) || [];
        this.setState({
            announcements,
        });
    }

    onSubjectChange = (e) => {
        const { errors } = this.state;
        this.setState({
            subject: e.target.value, errors: { ...errors, subject: false }, postingError: false, postingSuccess: false,
        });
    }

    onAnnounceByChange = (e, value) => {
        this.setState({ announceBy: value });
    }

    onPost = async () => {
        const { auth, matchId } = this.props;
        const {
            subject, body, announcements, errors, announceBy, announceByEmailTo,
        } = this.state;

        const data = { subject, body };

        if (!data.subject) {
            await new Promise((res) => this.setState({ errors: { ...errors, subject: true } }, res));
        }
        if (data.body.length < 10) {
            await new Promise((res) => this.setState({ errors: { ...errors, editor: true } }, res));
        }

        if (_.some(errors, (v) => v)) return;

        await new Promise((res) => this.setState({ posting: true, postingError: false, postingSuccess: false }, res));

        try {
            const newAnnouncement = await api.postAnnouncement({
                matchId,
                accessToken: auth,
                announcement: {
                    announceBy,
                    announceByEmailTo,
                    announcement: data,
                },
            });
            await new Promise((res) => this.setState({
                announcements: [...announcements, newAnnouncement], posting: false, postingSuccess: true, subject: '', body: '',
            }, res));
        } catch (e) {
            await new Promise((res) => this.setState({ posting: false, postingError: true }, res));
        }
    }

    renderAnnouncement(announcement) {
        const { classes, t, match } = this.props;
        const {
            id, subject, created, body, announceBy, announceByEmailTo,
        } = announcement;

        const emailToStr = (() => {
            if (announceByEmailTo.startsWith('squad-')) {
                return t('email-squad', { squad: (match.squads.find((s) => s.id === announceByEmailTo.substr(6)) || {}).title || '' });
            }
            return t(`email-${announceByEmailTo}`);
        })();
        return (
            <div className={classes.announcementOutterWrapper} style={{ marginBottom: '36px' }}>
                <Paper className={classes.announcementOutterWrapper} key={id}>
                    <div className={classes.announcementTitle}>
                        <Typography variant='h4' className={classes.announcementTitleSubject}>
                            {subject}
                        </Typography>
                        <Typography variant='caption' className={classes.announcementTitleDate}>
                            {moment(created).format('lll')}
                        </Typography>
                    </div>
                    <div className={classes.existingAnnouncementBody} dangerouslySetInnerHTML={{ __html: body }} />
                </Paper>
                <Typography variant='caption'>
                    {t(`past-announce-${announceBy}`)}
                    {announceBy === 'email' && (
                        <span>
                            &nbsp;
                            {emailToStr}
                        </span>
                    )}
                </Typography>
            </div>
        );
    }

    render() {
        const { t, classes, match } = this.props;
        const {
            announcements, subject, body, errors, posting, postingError, postingSuccess,
            announceBy, announceByEmailTo,
        } = this.state;

        return (
            <div className={classes.wrapper}>
                <Typography variant='h6' style={{ padding: '12px 0px' }}>
                    {t('new')}
                </Typography>
                <div className={classes.announcementOutterWrapper}>
                    <div className={classes.announcementWrapper}>
                        <div className={classes.announcementTitle}>
                            <Typography variant='h4' className={classes.announcementTitleSubject}>
                                <TextField
                                    style={{ width: '100%' }}
                                    placeholder={t('subject')}
                                    InputProps={{ style: { color: 'white' }, disableUnderline: true }}
                                    value={subject}
                                    onChange={this.onSubjectChange}
                                    error={errors.subject}
                                    helperText={errors.subject ? t('subject-empty-error') : null}
                                />
                            </Typography>
                        </div>
                        <div className={classes.newAnnouncementBody}>
                            <HtmlEditor
                                locale={match.locale}
                                value={body}
                                onChange={($body) => this.setState({ body: $body })}
                            />
                        </div>
                    </div>
                    <FormControl component='fieldset'>
                        <RadioGroup
                            value={announceBy || 'website'}
                            onChange={this.onAnnounceByChange}
                        >
                            <FormControlLabel value='website' control={<Radio />} label={t('announce-website')} />
                            <FormControlLabel
                                value='email'
                                control={<Radio />}
                                label={(
                                    <span>
                                        <span>
                                            {t('announce-email')}
                                        </span>
                                        <span style={{ padding: '6px' }}>
                                            <Select
                                                value={announceByEmailTo}
                                                onChange={(e) => this.setState({ announceByEmailTo: e.target.value })}
                                                disabled={announceBy !== 'email'}
                                            >
                                                <MenuItem value='all'>{t('email-all')}</MenuItem>
                                                <MenuItem value='paid'>{t('email-paid')}</MenuItem>
                                                <MenuItem value='not-paid'>{t('email-not-paid')}</MenuItem>
                                                {(match.squads || []).map((s) => (
                                                    <MenuItem key={s.id} value={`squad-${s.id}`}>{t('email-squad', { squad: s.title })}</MenuItem>
                                                ))}
                                            </Select>
                                        </span>
                                    </span>
                                )}
                            />
                        </RadioGroup>
                    </FormControl>
                    <Button onClick={this.onPost} disabled={posting} style={{ marginTop: '6px' }} fullWidth variant='contained' color='primary'>
                        {posting && <CircularProgress size={18} />}
                        {!posting ? t('post') : null}
                    </Button>
                    {postingSuccess && (
                    <MySnackbarContentWrapper
                        onClose={() => this.setState({ postingError: false, postingSuccess: false })}
                        style={{ maxWidth: 'none', width: 'calc(100% - 48px)', marginTop: '6px' }}
                        variant='success'
                        message={t('post-success')}
                    />
                    )}
                    {postingError && (
                    <MySnackbarContentWrapper
                        onClose={() => this.setState({ postingError: false, postingSuccess: false })}
                        style={{ maxWidth: 'none', width: 'calc(100% - 48px)', marginTop: '6px' }}
                        variant='error'
                        message={t('generic:saveerror')}
                    />
                    )}
                </div>
                <Typography variant='h6' style={{ padding: '12px 0px' }}>
                    {t('existing')}
                </Typography>
                {!announcements && (
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <CircularProgress size={75} />
                    </div>
                )}
                {announcements && announcements.length === 0 && (
                    <div className={classes.noannouncements}><Typography variant='subtitle1'>{t('no-announcements')}</Typography></div>
                )}
                {announcements && announcements.length > 0 && (
                    <div>
                        {announcements.sort((a, b) => b.created - a.created).map((a) => (
                            <div>
                                {this.renderAnnouncement(a)}
                            </div>
                        ))}
                    </div>
                )}

            </div>
        );
    }
}

const styles = {
    wrapper: {
        padding: '0px 20px',
    },
    title: {
        flex: 1,
        fontWeight: '300',
        lineHeight: '30px',
        fontSize: '16px',
    },
    announcementOutterWrapper: {
        width: '640px',
        margin: 'auto',
        marginBottom: '12px',
    },
    announcementWrapper: {
        background: 'white',
        border: '1px solid #aaa',
    },
    announcementTitle: {
        background: '#2196f3',
        color: 'white',
        padding: '12px',
        margin: '0px',
        fontSize: '26px',
        display: 'flex',
        alignItems: 'center',
    },
    announcementTitleSubject: {
        background: '#2196f3',
        color: 'white',
        padding: '0px',
        margin: '0px',
        fontSize: '26px',
        flex: '1',
    },
    announcementTitleDate: {
        background: '#2196f3',
        color: 'white',
        padding: '0px',
        margin: '0px',
    },
    newAnnouncementBody: {
        fontSize: '13px',
        fontWeight: '300',
    },
    existingAnnouncementBody: {
        fontSize: '13px',
        fontWeight: '300',
        padding: '12px',
    },
    editor: {
        border: '1px solid rgba(0,0,0,0.1)',
        padding: '0px 12px',
    },
    noannouncements: {
        textAlign: 'center',
    },
};

export default withStyles(styles)(withTranslation('announcements')(AnnouncementsEditor));

AnnouncementsEditor.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    setTitle: PropTypes.func.isRequired,
    matchId: PropTypes.string.isRequired,
    match: PropTypes.shape({
        locale: PropTypes.string.isRequired,
        squads: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    auth: PropTypes.string.isRequired,
};

/* eslint-enable react/no-danger */
