module.exports = {
    en: {
        comehereoften: 'So, come here often?',
        letsconnect: 'Lets connect!',
        smarter: 'Smarter',
        smarterdescription: 'Get stats and reports from all your matches',
        faster: 'Faster',
        fasterdescription: 'Quickly access all your info and your matches',
        easier: 'Easier',
        easierdescription: 'Get notified with every new score you get',
        shootbetter: 'And you\'ll even shoot better!',
        shootbetternot: '(ok, maybe we went too far there...)',
        matches: 'Matches',
        beststage: 'Your Best Stage',
        beststagenodata: 'Not enough data :(',
        avgpercentage: 'Average stage %',
        alphaspecentage: 'Alpha %',
        matchessectiontitle: 'Your Matches',
        statssectiontitle: 'Your Stats',
        staff: 'Staff',
        step1: '"Next shooter on the line"',
        step2: '"Are you ready?"',
        step3: '"Standby..."',
        nomatchestitle: 'You still haven\'t experienced a match with ARIA???',
        nomatchesdescription1: 'Sign up to one of the matches in the \'Discover\' tab to experience a new level of match management!',
        nomatchesdescription2: 'Come back here for your own quick links and statistics.',
    },
    he: {
    },
};
