module.exports = {
    en: {
        ipsclevel: 'IPSC Recognition Level',
        ipsclevelsub: 'My match is a...',
        name: 'Hi there!',
        namesub: 'Lets create your website. What\'s your match\'s name?',
        when: 'When & Where?',
        whensub: 'When is your match taking place?',
        location: 'When & Where?',
        locationsub: 'Where is your match taking place?',
        locationskip: 'Skip, I don\'t want to show where the match is on my site',
        description: 'Short Description',
        descriptionsub: 'Help your shooters out with a short description of this match',
        descriptiontip: 'We recommended writing the location and date of the match, for example: "Our Home Range | 10-1-2025"',
        descriptionskip: 'I\'ll add one later',
        registration: 'Registration',
        registrationsub: 'Will shooters be able to register to the match on your new website?',
        registrationopen: 'Yes, of course',
        registrationclosed: 'No, I\'m old fashioned and people will need to contact me directly to register',
        squad: 'Squads Selection',
        squadsub: 'Will shooters be able to pick their squad on your new website?',
        squadduring: 'Yes, of course',
        squadafter: 'Yes, but only after payment',
        squadclose: 'No, I\'ll do the manual work of picking their squads',
        alias: 'Lets pick your address',
        aliassub: 'What address would you want for your website?',
        aliassuccess: 'Address free!',
        aliaserrortaken: 'Address taken or invalid characters used!',
        aliaserrorinvalid: 'Please use letters and numbers only - spaces and special characters aren\'t allowed.',
        login: 'One last thing...',
        loginsub: 'We need to know who you are so you can manage your new site',
        logintermspre: 'I agree to the',
        loginterms: 'Terms of Service',
        logintermsand: 'and',
        logintermsprivacy: 'Privacy Policy',
        logincreate: 'Create My Match',
        done: 'Congratulations!',
        donesub: 'Your Website is ready! Check it out:',
        donedashboard: 'My Site\'s Dashboard',
    },
    he: {
        ipsclevel: 'סוג התחרות',
        ipsclevelsub: 'התחרות שלי היא ...',
        name: 'שלום לך!',
        namesub: 'שניצור לך אתר לתחרות? נתחיל בשם - מה שם התחרות שלך?',
        when: 'מיקום וזמן',
        whensub: 'מתי תתקיים התחרות שלכם?',
        location: 'מיקום וזמן',
        locationsub: 'איפה תתקיים התחרות שלכם?',
        locationskip: 'עזבו, אני מעדיף לא להראות איפה התחרות תתקיים',
        description: 'תיאור קצר',
        descriptionsub: 'תיאור שמוצג בעמוד הראשי כתת-כותרת של התחרות',
        descriptiontip: 'אנו ממליצים לרשום את מיקום התחרות והתאריך, לדוגמא ״מטווח כפר סבא | 11-3-2025״',
        descriptionskip: 'אני אוסיף תיאור אח״כ',
        registration: 'רישום לתחרות',
        registrationsub: 'האם היורים יוכלו לרשום את עצמם לתחרות באתר?',
        registrationopen: 'כן, כמובן',
        registrationclosed: 'לא, אני לא אוהב טכנולוגיה. מעדיף שיורים יצרו קשר איתי ישירות להרשמה',
        squad: 'בחירת סקווד',
        squadsub: 'האם היורים יוכלו לבחור את הסקווד שלהם באתר התחרות?',
        squadduring: 'כן, ברור',
        squadafter: 'כן, אבל רק אחרי תשלום',
        squadclose: 'לא, אני אעבוד קשה לבחור לכל יורה סקווד ידנית',
        alias: 'בואו ניצור לינק',
        aliassub: 'איזו כתובת תרצו לתחרות שלכם?',
        aliassuccess: 'הכתובת פנויה!',
        aliaserrortaken: 'הכתובת תפוסה - נסו כתובת אחרת.',
        aliaserrorinvalid: 'אנחנו מקבלים רק אותיות ומספרים - רווחים ותווים מיוחדים לא תופסים פה...',
        login: 'דבר אחד אחרון...',
        loginsub: 'אנחנו צריכים לדעת מי אתם שתוכלו לנהל את האתר החדש שלכם',
        logintermspre: 'אני מסכים\\ה ל',
        loginterms: 'תנאי השימוש',
        logintermsand: 'ו',
        logintermsprivacy: 'תנאי הפרטיות',
        logincreate: 'צור את התחרות שלי',
        done: 'סיימנו!',
        donesub: 'התחרות שלך מוכנה. הלינק הישיר שלה:',
        donedashboard: 'למערכת ניהול התחרות',
    },
};
