import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

export default function PlusMinus(props) {
    const {
        i18n, num, onInc, onDec,
    } = props;

    return (
        <div style={{ display: 'flex', flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row', alignItems: 'center' }}>
            <Button
                variant='contained'
                color='primary'
                style={{
                    minWidth: '40px', padding: '0px', width: '60px', height: '60px',
                }}
                onClick={onInc}
            >
                <AddIcon />
            </Button>
            <div style={{ fontSize: '2em', width: '40px', textAlign: 'center' }}>
                {num}
            </div>
            <Button
                disabled={num === 0}
                variant='contained'
                color='secondary'
                onClick={onDec}
                style={{
                    minWidth: '40px', padding: '0px', width: '60px', height: '60px',
                }}
            >
                <RemoveIcon />
            </Button>
        </div>
    );
}

PlusMinus.propTypes = {
    i18n: PropTypes.shape({
        dir: PropTypes.func.isRequired,
    }).isRequired,
    num: PropTypes.number.isRequired,
    onInc: PropTypes.func.isRequired,
    onDec: PropTypes.func.isRequired,
};
