/* eslint-disable class-methods-use-this */
/* eslint-disable no-tabs */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import xmlescape from 'xml-escape';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { summarizeScore, getLatestScoreForShooterPerStage } from './Utils';
import api from '../Api';
import WINMSS_COUNTRIES from './winmsscountries.json';

class ExportToWinMss extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {
        const {
            match, auth, setTitle, t,
        } = this.props;
        setTitle(t('title'));

        const promises = await Promise.all([
            api.getMatch({ matchId: match.id, auth }),
            api.getShooters({ matchId: match.id, auth }),
            api.getStaff({ matchId: match.id, auth }),
            api.getScores({ matchId: match.id, auth }),
        ]);

        const $match = promises[0];
        const shooters = promises[1];
        const shootersPcc = shooters.filter((s) => (s.division === 'pcc-optics' || s.division === 'pcc-iron')).sort((a,b) => a.created - b.created);
        const shootersNoPcc = shooters.filter((s) => (s.division !== 'pcc-optics' && s.division !== 'pcc-iron')).sort((a,b) => a.created - b.created);
        const staff = promises[2];
        const scores = promises[3];

        /* eslint-disable no-param-reassign */
        shootersPcc.forEach((s) => {
            if (parseInt(s.publicId, 10) > 50000) s.publicId = 1000 + parseInt(Math.random() * 1000, 10);
        });
        shootersNoPcc.forEach((s) => {
            if (parseInt(s.publicId, 10) > 50000) s.publicId = 1000 + parseInt(Math.random() * 1000, 10);
        });
        /* eslint-enable no-param-reassign */

        const stages = await api.getStages({ stages: $match.stages });

        const latestScoresPerStage = getLatestScoreForShooterPerStage({ scores, numStages: stages.length });

        const clubsPcc = _.uniq(shootersPcc.map((s) => s.club));
        const clubsNoPcc = _.uniq(shootersNoPcc.map((s) => s.club));

        this.setState({
            match: $match, shootersPcc, shootersNoPcc, staff, stages, clubsPcc, clubsNoPcc, scores: latestScoresPerStage,
        });
    }

    getMemberData({ isPcc }) {
        const { shootersPcc, shootersNoPcc } = this.state;
        const shooters = isPcc ? shootersPcc : shootersNoPcc;
        return `${''
            + '<xml xmlns:s=\'uuid:BDC6E3F0-6DA3-11d1-A2A3-00AA00C14882\'\n'
            + '	xmlns:dt=\'uuid:C2F41010-65B3-11d1-A29F-00AA00C14882\'\n'
            + '	xmlns:rs=\'urn:schemas-microsoft-com:rowset\'\n'
            + '	xmlns:z=\'#RowsetSchema\'>\n'
            + '<s:Schema id=\'RowsetSchema\'>\n'
            + '	<s:ElementType name=\'row\' content=\'eltOnly\' rs:updatable=\'true\'>\n'
            + '		<s:AttributeType name=\'MemberId\' rs:number=\'1\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMember\'\n'
            + '			 rs:basecolumn=\'MemberId\' rs:keycolumn=\'true\' rs:autoincrement=\'true\'>\n'
            + '			<s:datatype dt:type=\'int\' dt:maxLength=\'4\' rs:precision=\'10\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Lastname\' rs:number=\'2\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMember\' rs:basecolumn=\'Lastname\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'25\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Firstname\' rs:number=\'3\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMember\' rs:basecolumn=\'Firstname\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'15\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Init\' rs:number=\'4\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMember\'\n'
            + '			 rs:basecolumn=\'Comment\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'1\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'IcsAlias\' rs:number=\'5\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMember\' rs:basecolumn=\'ICS\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'16\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'RefNo\' rs:number=\'6\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMember\'\n'
            + '			 rs:basecolumn=\'RefNo\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'10\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'InActive\' rs:number=\'7\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMember\'\n'
            + '			 rs:basecolumn=\'InActive\'>\n'
            + '			<s:datatype dt:type=\'boolean\' dt:maxLength=\'2\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Female\' rs:number=\'8\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMember\'\n'
            + '			 rs:basecolumn=\'Female\'>\n'
            + '			<s:datatype dt:type=\'boolean\' dt:maxLength=\'2\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'PrintLabel\' rs:number=\'9\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMember\'\n'
            + '			 rs:basecolumn=\'PrintLabel\'>\n'
            + '			<s:datatype dt:type=\'boolean\' dt:maxLength=\'2\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'RegionId\' rs:number=\'10\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMember\' rs:basecolumn=\'TypeRegionId\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'3\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'DOB\' rs:number=\'11\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMember\'\n'
            + '			 rs:basecolumn=\'DOB\'>\n'
            + '			<s:datatype dt:type=\'dateTime\' rs:dbtype=\'variantdate\' dt:maxLength=\'16\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Address1\' rs:number=\'12\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMember\' rs:basecolumn=\'Address1\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'40\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Address2\' rs:number=\'13\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMember\' rs:basecolumn=\'Address2\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'40\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'City\' rs:number=\'14\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMember\'\n'
            + '			 rs:basecolumn=\'City\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'20\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Province\' rs:number=\'15\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMember\' rs:basecolumn=\'Province\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'24\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'CountryId\' rs:number=\'16\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMember\' rs:basecolumn=\'TypeCountryId\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'3\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'PostCode\' rs:number=\'17\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMember\' rs:basecolumn=\'PostCode\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'10\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'PhoneHome\' rs:number=\'18\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMember\' rs:basecolumn=\'PhoneHome\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'20\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'PhoneAlt\' rs:number=\'19\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMember\' rs:basecolumn=\'PhoneAlternate\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'20\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'PhoneFax\' rs:number=\'20\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMember\' rs:basecolumn=\'PhoneFax\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'20\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Email\' rs:number=\'21\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMember\'\n'
            + '			 rs:basecolumn=\'Email\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'36\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'ClassId\' rs:number=\'22\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMember\' rs:basecolumn=\'TypeScoreClassId\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'1\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'DfltDivId\' rs:number=\'23\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMember\' rs:basecolumn=\'DfltDivisionId\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'DfltCatId\' rs:number=\'24\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMember\' rs:basecolumn=\'DfltNonTeamCategoryId\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'DfltTagId\' rs:number=\'25\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMember\' rs:basecolumn=\'DfltTagId\'>\n'
            + '			<s:datatype dt:type=\'int\' dt:maxLength=\'4\' rs:precision=\'10\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'QualId\' rs:number=\'26\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMember\' rs:basecolumn=\'TypeQualificationId\'>\n'
            + '			<s:datatype dt:type=\'int\' dt:maxLength=\'4\' rs:precision=\'10\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Register\' rs:number=\'27\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMember\'\n'
            + '			 rs:basecolumn=\'Register\'>\n'
            + '			<s:datatype dt:type=\'boolean\' dt:maxLength=\'2\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Note\' rs:number=\'28\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMember\'\n'
            + '			 rs:basecolumn=\'Note\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'536870910\' rs:long=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:extends type=\'rs:rowbase\'/>\n'
            + '	</s:ElementType>\n'
            + '</s:Schema>\n'
            + '<rs:data>\n'}${
            shooters.map((shooter, shooterIdx) => {
                if (shooter.cancelledParticipation) return '';
                const firstName = _.trim(shooter.name).split(' ')[0].replace(/'/g, '&apos;');
                const lastName = _.trim(shooter.name).substr(firstName.length + 1).replace(/'/g, '&apos;');

                return `   <z:row MemberId='${shooterIdx + 1}' Lastname='${lastName || ' '}' Firstname='${firstName}' IcsAlias='${shooter.publicId}' RefNo='' InActive='False' Female='${shooter.category === 'lady' ? 'TRUE' : 'FALSE'}'`
                    + `	    PrintLabel='False' RegionId='${publicIdToRegion(shooter.publicId)}' CountryId='' ClassId='U' DfltDivId='2' DfltCatId='0' DfltTagId='0' QualId='0'`
                    + '	    Register=\'False\'/>\n';
            }).join('')
        }</rs:data>\n`
            + '</xml>\n';
    }

    getMatchData({ isPcc }) {
        const { match, staff } = this.state;
        const md = (staff.find((s) => s.role === 'md') || {}).name || 'MD';
        const rm = (staff.find((s) => s.role === 'rm') || {}).name || 'RM';
        const so = (staff.find((s) => s.role === 'so') || {}).name || 'SO';
        return ''
            + '<xml xmlns:s=\'uuid:BDC6E3F0-6DA3-11d1-A2A3-00AA00C14882\'\n'
            + '	xmlns:dt=\'uuid:C2F41010-65B3-11d1-A29F-00AA00C14882\'\n'
            + '	xmlns:rs=\'urn:schemas-microsoft-com:rowset\'\n'
            + '	xmlns:z=\'#RowsetSchema\'>\n'
            + '<s:Schema id=\'RowsetSchema\'>\n'
            + '	<s:ElementType name=\'row\' content=\'eltOnly\' rs:updatable=\'true\'>\n'
            + '		<s:AttributeType name=\'MatchId\' rs:number=\'1\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatch\'\n'
            + '			 rs:basecolumn=\'MatchId\' rs:keycolumn=\'true\' rs:autoincrement=\'true\'>\n'
            + '			<s:datatype dt:type=\'int\' dt:maxLength=\'4\' rs:precision=\'10\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'MatchName\' rs:number=\'2\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatch\' rs:basecolumn=\'MatchName\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'50\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'MatchDt\' rs:number=\'3\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatch\' rs:basecolumn=\'MatchDt\'>\n'
            + '			<s:datatype dt:type=\'dateTime\' rs:dbtype=\'variantdate\' dt:maxLength=\'16\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'ClubId\' rs:number=\'4\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatch\'\n'
            + '			 rs:basecolumn=\'ClubId\'>\n'
            + '			<s:datatype dt:type=\'i2\' dt:maxLength=\'2\' rs:precision=\'5\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Chrono\' rs:number=\'5\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatch\'\n'
            + '			 rs:basecolumn=\'Chronograph\'>\n'
            + '			<s:datatype dt:type=\'boolean\' dt:maxLength=\'2\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'MatchLevel\' rs:number=\'6\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatch\' rs:basecolumn=\'TypeMatchLevel\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'CountryId\' rs:number=\'7\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatch\' rs:basecolumn=\'TypeCountryId\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'3\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'FirearmId\' rs:number=\'8\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatch\' rs:basecolumn=\'TypeFirearmId\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'SquadCount\' rs:number=\'9\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatch\' rs:basecolumn=\'SquadCount\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'MD\' rs:number=\'10\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatch\'\n'
            + '			 rs:basecolumn=\'MatchDirector\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'40\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'RM\' rs:number=\'11\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatch\'\n'
            + '			 rs:basecolumn=\'RangeMaster\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'40\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'SD\' rs:number=\'12\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatch\'\n'
            + '			 rs:basecolumn=\'StatsDirector\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'40\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:extends type=\'rs:rowbase\'/>\n'
            + '	</s:ElementType>\n'
            + '</s:Schema>\n'
            + '<rs:data>\n'
            + `	<z:row MatchId='1' MatchName='${match.title}${isPcc ? ' (PCC)' : ''}' MatchDt='${match.startDate.year}-${_.padStart(match.startDate.month, 2, '0')}-${_.padStart(match.startDate.day, 2, '0')}T00:00:00' ClubId='1' Chrono='False' MatchLevel='2' CountryId='${countryCodeToRegion(match.region)}'\n`
            + `		 FirearmId='${isPcc ? 7 : 1}' SquadCount='${match.squads.length}' MD='${md}' RM='${rm}' SD='${so}'/>\n`
            + '</rs:data>\n'
            + '</xml>\n';
    }

    getSquadData() {
        const { match } = this.state;
        return `${''
            + '<xml xmlns:s=\'uuid:BDC6E3F0-6DA3-11d1-A2A3-00AA00C14882\'\n'
            + '	xmlns:dt=\'uuid:C2F41010-65B3-11d1-A29F-00AA00C14882\'\n'
            + '	xmlns:rs=\'urn:schemas-microsoft-com:rowset\'\n'
            + '	xmlns:z=\'#RowsetSchema\'>\n'
            + '<s:Schema id=\'RowsetSchema\'>\n'
            + '	<s:ElementType name=\'row\' content=\'eltOnly\' rs:updatable=\'true\'>\n'
            + '		<s:AttributeType name=\'MatchId\' rs:number=\'1\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchSquad\' rs:basecolumn=\'MatchId\' rs:keycolumn=\'true\'>\n'
            + '			<s:datatype dt:type=\'int\' dt:maxLength=\'4\' rs:precision=\'10\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'SquadId\' rs:number=\'2\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchSquad\' rs:basecolumn=\'SquadId\' rs:keycolumn=\'true\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Squad\' rs:number=\'3\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchSquad\'\n'
            + '			 rs:basecolumn=\'Squad\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'3\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:extends type=\'rs:rowbase\'/>\n'
            + '	</s:ElementType>\n'
            + '</s:Schema>\n'
            + '<rs:data>\n'}${
            match.squads.map((squad, squadIdx) => `	<z:row MatchId='1' SquadId='${squadIdx + 1}' Squad='${squad.title}'/>\n`).join('')
        }</rs:data>\n`
            + '</xml>\n';
    }

    getStagesData() {
        const { stages } = this.state;

        return `${''
            + '<xml xmlns:s=\'uuid:BDC6E3F0-6DA3-11d1-A2A3-00AA00C14882\'\n'
            + '	xmlns:dt=\'uuid:C2F41010-65B3-11d1-A29F-00AA00C14882\'\n'
            + '	xmlns:rs=\'urn:schemas-microsoft-com:rowset\'\n'
            + '	xmlns:z=\'#RowsetSchema\'>\n'
            + '<s:Schema id=\'RowsetSchema\'>\n'
            + '	<s:ElementType name=\'row\' content=\'eltOnly\' rs:updatable=\'true\'>\n'
            + '		<s:AttributeType name=\'MatchId\' rs:number=\'1\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStage\' rs:basecolumn=\'MatchId\' rs:keycolumn=\'true\'>\n'
            + '			<s:datatype dt:type=\'int\' dt:maxLength=\'4\' rs:precision=\'10\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'StageId\' rs:number=\'2\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStage\' rs:basecolumn=\'StageId\' rs:keycolumn=\'true\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'StageName\' rs:number=\'3\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStage\' rs:basecolumn=\'StageName\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'32\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Location\' rs:number=\'4\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStage\' rs:basecolumn=\'Location\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'32\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'FirearmId\' rs:number=\'5\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStage\' rs:basecolumn=\'TypeFirearmId\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'CourseId\' rs:number=\'6\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStage\' rs:basecolumn=\'TypeStageCourseId\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'ScoringId\' rs:number=\'7\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStage\' rs:basecolumn=\'TypeScoringId\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'TrgtTypeId\' rs:number=\'8\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStage\' rs:basecolumn=\'TypeTargetClassifyId\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'IcsStageId\' rs:number=\'9\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStage\' rs:basecolumn=\'TypeStdStageSetupId\'>\n'
            + '			<s:datatype dt:type=\'i2\' dt:maxLength=\'2\' rs:precision=\'5\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Remove\' rs:number=\'10\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchStage\'\n'
            + '			 rs:basecolumn=\'RemoveScoring\'>\n'
            + '			<s:datatype dt:type=\'boolean\' dt:maxLength=\'2\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'TrgtPaper\' rs:number=\'11\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStage\' rs:basecolumn=\'TrgtPaper\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'TrgtPopper\' rs:number=\'12\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStage\' rs:basecolumn=\'TrgtPopper\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'TrgtPlates\' rs:number=\'13\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStage\' rs:basecolumn=\'TrgtPlates\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'TrgtVanish\' rs:number=\'14\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStage\' rs:basecolumn=\'TrgtDisappear\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'TrgtPenlty\' rs:number=\'15\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStage\' rs:basecolumn=\'TrgtPenalty\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'MinRounds\' rs:number=\'16\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStage\' rs:basecolumn=\'MinRounds\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'ReportOn\' rs:number=\'17\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchStage\'\n'
            + '			 rs:basecolumn=\'ReportOn\'>\n'
            + '			<s:datatype dt:type=\'boolean\' dt:maxLength=\'2\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'MaxPoints\' rs:number=\'18\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStage\' rs:basecolumn=\'MaxPoints\'>\n'
            + '			<s:datatype dt:type=\'i2\' dt:maxLength=\'2\' rs:precision=\'5\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'StartPos\' rs:number=\'19\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStage\' rs:basecolumn=\'StartPosition\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'536870910\' rs:long=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'StartOn\' rs:number=\'20\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStage\' rs:basecolumn=\'StartOn\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'8\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'StringCnt\' rs:number=\'21\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStage\' rs:basecolumn=\'StringsOfFire\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Descriptn\' rs:number=\'22\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStage\' rs:basecolumn=\'Description\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'536870910\' rs:long=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:extends type=\'rs:rowbase\'/>\n'
            + '	</s:ElementType>\n'
            + '</s:Schema>\n'
            + '<rs:data>\n'}${
            stages.map((stage, stageIdx) => ''
                    + `<z:row MatchId='1' StageId='${stageIdx + 1}' StageName='${stage.title}' Location='1' FirearmId='1' CourseId='3' ScoringId='1' TrgtTypeId='2'`
                    + `     IcsStageId='0' Remove='False' TrgtPaper='${stage.targets.paper || 0}' TrgtPopper='${stage.targets.poppers || 0}' TrgtPlates='${stage.targets.plates || 0}' TrgtVanish='0' TrgtPenlty='0' MinRounds='${stage.rounds}'`
                    + `     ReportOn='False' MaxPoints='${stage.rounds * 5}' StartPos=''`
                    + '     StartOn=\'00\' StringCnt=\'0\' Descriptn=\'\'/>\n').join('')
        }</rs:data>\n`
            + '</xml>\n';
    }

    getClassifyData() {
        return ''
            + '<xml xmlns:s=\'uuid:BDC6E3F0-6DA3-11d1-A2A3-00AA00C14882\'\n'
            + '	xmlns:dt=\'uuid:C2F41010-65B3-11d1-A29F-00AA00C14882\'\n'
            + '	xmlns:rs=\'urn:schemas-microsoft-com:rowset\'\n'
            + '	xmlns:z=\'#RowsetSchema\'>\n'
            + '<s:Schema id=\'RowsetSchema\'>\n'
            + '	<s:ElementType name=\'row\' content=\'eltOnly\' rs:updatable=\'true\'>\n'
            + '		<s:AttributeType name=\'MemberId\' rs:number=\'1\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMemberClassify\'\n'
            + '			 rs:basecolumn=\'MemberId\' rs:keycolumn=\'true\'>\n'
            + '			<s:datatype dt:type=\'int\' dt:maxLength=\'4\' rs:precision=\'10\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'DivId\' rs:number=\'2\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMemberClassify\'\n'
            + '			 rs:basecolumn=\'TypeDivisionId\' rs:keycolumn=\'true\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'IntlId\' rs:number=\'3\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMemberClassify\'\n'
            + '			 rs:basecolumn=\'TypeClassIntlId\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'1\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'NatlId\' rs:number=\'4\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMemberClassify\'\n'
            + '			 rs:basecolumn=\'TypeClassNatlId\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'1\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'ClassId\' rs:number=\'5\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMemberClassify\' rs:basecolumn=\'TypeScoreClassId\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'1\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:extends type=\'rs:rowbase\'/>\n'
            + '	</s:ElementType>\n'
            + '</s:Schema>\n'
            + '<rs:data>\n'
            + '</rs:data>\n'
            + '</xml>\n';
    }

    getClubsData({ isPcc }) {
        const { clubsPcc, clubsNoPcc } = this.state;
        const clubs = isPcc ? clubsPcc : clubsNoPcc;
        return `${''
            + '<xml xmlns:s=\'uuid:BDC6E3F0-6DA3-11d1-A2A3-00AA00C14882\'\n'
            + '	xmlns:dt=\'uuid:C2F41010-65B3-11d1-A29F-00AA00C14882\'\n'
            + '	xmlns:rs=\'urn:schemas-microsoft-com:rowset\'\n'
            + '	xmlns:z=\'#RowsetSchema\'>\n'
            + '<s:Schema id=\'RowsetSchema\'>\n'
            + '	<s:ElementType name=\'row\' content=\'eltOnly\' rs:updatable=\'true\'>\n'
            + '		<s:AttributeType name=\'ClubId\' rs:number=\'1\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatch\'\n'
            + '			 rs:basecolumn=\'ClubId\'>\n'
            + '			<s:datatype dt:type=\'i2\' dt:maxLength=\'2\' rs:precision=\'5\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'ClubCode\' rs:number=\'2\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblClub\' rs:basecolumn=\'ClubCode\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'6\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Club\' rs:number=\'3\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblClub\'\n'
            + '			 rs:basecolumn=\'Club\' rs:keycolumn=\'true\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'50\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Contact\' rs:number=\'4\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblClub\' rs:basecolumn=\'Contact\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'50\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Address1\' rs:number=\'5\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblClub\' rs:basecolumn=\'Address1\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'40\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Address2\' rs:number=\'6\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblClub\' rs:basecolumn=\'Address2\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'40\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'City\' rs:number=\'7\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblClub\'\n'
            + '			 rs:basecolumn=\'City\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'20\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Province\' rs:number=\'8\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblClub\' rs:basecolumn=\'Province\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'24\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'CountryId\' rs:number=\'9\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblClub\' rs:basecolumn=\'TypeCountryId\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'3\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'PostCode\' rs:number=\'10\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblClub\' rs:basecolumn=\'PostCode\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'10\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Phone\' rs:number=\'11\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblClub\'\n'
            + '			 rs:basecolumn=\'Phone\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'20\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'PhoneAlt\' rs:number=\'12\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblClub\' rs:basecolumn=\'PhoneAlternate\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'20\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'PhoneFax\' rs:number=\'13\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblClub\' rs:basecolumn=\'PhoneFax\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'20\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Email\' rs:number=\'14\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblClub\'\n'
            + '			 rs:basecolumn=\'Email\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'36\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'WebSite\' rs:number=\'15\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblClub\' rs:basecolumn=\'WebSite\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'64\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:extends type=\'rs:rowbase\'/>\n'
            + '	</s:ElementType>\n'
            + '</s:Schema>\n'
            + '<rs:data>\n'}${
            clubs.map((club, clubIdx) => `	<z:row ClubId='${clubIdx + 1}' Club='${xmlescape(club)}'/>\n`).join('')
        }</rs:data>\n`
            + '</xml>\n';
    }

    getEnrolledData({ isPcc }) {
        const {
            scores, match, shootersPcc, shootersNoPcc,
        } = this.state;
        const shooters = isPcc ? shootersPcc : shootersNoPcc;

        return `${''
            + '<xml xmlns:s=\'uuid:BDC6E3F0-6DA3-11d1-A2A3-00AA00C14882\'\n'
            + '	xmlns:dt=\'uuid:C2F41010-65B3-11d1-A29F-00AA00C14882\'\n'
            + '	xmlns:rs=\'urn:schemas-microsoft-com:rowset\'\n'
            + '	xmlns:z=\'#RowsetSchema\'>\n'
            + '<s:Schema id=\'RowsetSchema\'>\n'
            + '	<s:ElementType name=\'row\' content=\'eltOnly\' rs:updatable=\'true\'>\n'
            + '		<s:AttributeType name=\'MatchId\' rs:number=\'1\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchCompetitor\' rs:basecolumn=\'MatchId\' rs:keycolumn=\'true\'>\n'
            + '			<s:datatype dt:type=\'int\' dt:maxLength=\'4\' rs:precision=\'10\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'MemberId\' rs:number=\'2\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchCompetitor\' rs:basecolumn=\'MemberId\' rs:keycolumn=\'true\'>\n'
            + '			<s:datatype dt:type=\'int\' dt:maxLength=\'4\' rs:precision=\'10\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'CompId\' rs:number=\'3\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchCompetitor\'\n'
            + '			 rs:basecolumn=\'CompetitorId\'>\n'
            + '			<s:datatype dt:type=\'i2\' dt:maxLength=\'2\' rs:precision=\'5\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'DivId\' rs:number=\'4\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchCompetitor\'\n'
            + '			 rs:basecolumn=\'TypeDivisionId\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'CatId\' rs:number=\'5\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchCompetitor\'\n'
            + '			 rs:basecolumn=\'TypeNonTeamCategoryId\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'TeamId\' rs:number=\'6\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchCompetitor\'\n'
            + '			 rs:basecolumn=\'TeamId\'>\n'
            + '			<s:datatype dt:type=\'i2\' dt:maxLength=\'2\' rs:precision=\'5\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'SquadId\' rs:number=\'7\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchCompetitor\' rs:basecolumn=\'SquadId\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'TagId\' rs:number=\'8\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchCompetitor\'\n'
            + '			 rs:basecolumn=\'TagId\'>\n'
            + '			<s:datatype dt:type=\'int\' dt:maxLength=\'4\' rs:precision=\'10\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'MajorPF\' rs:number=\'9\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchCompetitor\'\n'
            + '			 rs:basecolumn=\'MajorPowerFactor\'>\n'
            + '			<s:datatype dt:type=\'boolean\' dt:maxLength=\'2\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Classified\' rs:number=\'10\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchCompetitor\'\n'
            + '			 rs:basecolumn=\'ClassifiedScoring\'>\n'
            + '			<s:datatype dt:type=\'boolean\' dt:maxLength=\'2\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'IsDisq\' rs:number=\'11\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchCompetitor\'\n'
            + '			 rs:basecolumn=\'IsDisqualified\'>\n'
            + '			<s:datatype dt:type=\'boolean\' dt:maxLength=\'2\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'DisqRuleId\' rs:number=\'12\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchCompetitor\' rs:basecolumn=\'TypeDisqualifyRuleId\'>\n'
            + '			<s:datatype dt:type=\'i2\' dt:maxLength=\'2\' rs:precision=\'5\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'StageDisq\' rs:number=\'13\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchCompetitor\'\n'
            + '			 rs:basecolumn=\'StageDisqualification\'>\n'
            + '			<s:datatype dt:type=\'boolean\' dt:maxLength=\'2\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'DisqDt\' rs:number=\'14\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchCompetitor\' rs:basecolumn=\'DisqualifyDateTime\'>\n'
            + '			<s:datatype dt:type=\'dateTime\' rs:dbtype=\'variantdate\' dt:maxLength=\'16\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'DisqMemo\' rs:number=\'15\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchCompetitor\' rs:basecolumn=\'DisqualifyMemo\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'536870910\' rs:long=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'RefNo\' rs:number=\'16\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchCompetitor\'\n'
            + '			 rs:basecolumn=\'RefNo\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'10\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:extends type=\'rs:rowbase\'/>\n'
            + '	</s:ElementType>\n'
            + '</s:Schema>\n'
            + '<rs:data>\n'}${
            shooters.map((shooter, shooterIdx) => {
                if (shooter.cancelledParticipation) return '';
                const squadIdx = match.squads.findIndex((sq) => sq.id === shooter.squad);
                const division = {
                    open: 1,
                    standard: 2,
                    production: 4,
                    revolver: 5,
                    classic: 18,
                    'production-optics': 24,
                    'production-optics-light': 28,
                    'pcc-optics': 29,
                    'pcc-iron': 30,
                }[shooter.division];
                const category = {
                    lady: 1,
                    junior: 2,
                    senior: 3,
                    'super-senior': 4,
                    'grand-senior': 6,
                    'lady-senior': 7,
                    'super-junior': 5,
                }[shooter.category] || 0;

                const dq = _.find(scores.map((scoresForStage) => scoresForStage.find((score) => score.dq && score.shooterId === shooter.id)), (a) => a);
                return ''
                    + `	<z:row MatchId='1' MemberId='${shooterIdx + 1}' CompId='${shooterIdx + 1}' DivId='${division}' CatId='${category}' SquadId='${squadIdx + 1}' TagId='0' MajorPF='${shooter['power-factor'] === 'major' ? 'TRUE' : 'FALSE'}' Classified='False'\n`
                    + `		 IsDisq='${dq ? 'True' : 'False'}' DisqRuleId='${dq ? '1' : '0'}' StageDisq='False' RefNo='UUU'/>\n`;
            }).join('')
        }</rs:data>\n`
            + '</xml>\n';
    }

    getTagData() {
        return ''
            + '<xml xmlns:s=\'uuid:BDC6E3F0-6DA3-11d1-A2A3-00AA00C14882\'\n'
            + '	xmlns:dt=\'uuid:C2F41010-65B3-11d1-A29F-00AA00C14882\'\n'
            + '	xmlns:rs=\'urn:schemas-microsoft-com:rowset\'\n'
            + '	xmlns:z=\'#RowsetSchema\'>\n'
            + '<s:Schema id=\'RowsetSchema\'>\n'
            + '	<s:ElementType name=\'row\' content=\'eltOnly\' rs:updatable=\'true\'>\n'
            + '		<s:AttributeType name=\'TagId\' rs:number=\'1\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblTag\'\n'
            + '			 rs:basecolumn=\'TagId\' rs:keycolumn=\'true\' rs:autoincrement=\'true\'>\n'
            + '			<s:datatype dt:type=\'int\' dt:maxLength=\'4\' rs:precision=\'10\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Tag\' rs:number=\'2\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblTag\'\n'
            + '			 rs:basecolumn=\'Tag\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'3\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:extends type=\'rs:rowbase\'/>\n'
            + '	</s:ElementType>\n'
            + '</s:Schema>\n'
            + '<rs:data>\n'
            + '</rs:data>\n'
            + '</xml>';
    }

    getTeamData() {
        return ''
            + '<xml xmlns:s=\'uuid:BDC6E3F0-6DA3-11d1-A2A3-00AA00C14882\'\n'
            + '	xmlns:dt=\'uuid:C2F41010-65B3-11d1-A29F-00AA00C14882\'\n'
            + '	xmlns:rs=\'urn:schemas-microsoft-com:rowset\'\n'
            + '	xmlns:z=\'#RowsetSchema\'>\n'
            + '<s:Schema id=\'RowsetSchema\'>\n'
            + '	<s:ElementType name=\'row\' content=\'eltOnly\' rs:updatable=\'true\'>\n'
            + '		<s:AttributeType name=\'MatchId\' rs:number=\'1\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchTeam\' rs:basecolumn=\'MatchId\' rs:keycolumn=\'true\'>\n'
            + '			<s:datatype dt:type=\'int\' dt:maxLength=\'4\' rs:precision=\'10\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'TeamId\' rs:number=\'2\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchTeam\'\n'
            + '			 rs:basecolumn=\'TeamId\' rs:keycolumn=\'true\'>\n'
            + '			<s:datatype dt:type=\'i2\' dt:maxLength=\'2\' rs:precision=\'5\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'DivId\' rs:number=\'3\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchTeam\'\n'
            + '			 rs:basecolumn=\'TypeDivisionId\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'CatId\' rs:number=\'4\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchTeam\'\n'
            + '			 rs:basecolumn=\'TypeNonTeamCategoryId\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Team\' rs:number=\'5\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchTeam\'\n'
            + '			 rs:basecolumn=\'Team\'>\n'
            + '			<s:datatype dt:type=\'string\' dt:maxLength=\'32\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:extends type=\'rs:rowbase\'/>\n'
            + '	</s:ElementType>\n'
            + '</s:Schema>\n'
            + '<rs:data>\n'
            + '</rs:data>\n'
            + '</xml>';
    }

    getScoreData({ isPcc }) {
        const {
            scores, shootersPcc, shootersNoPcc, stages,
        } = this.state;
        const shooters = isPcc ? shootersPcc : shootersNoPcc;

        return `${''
            + '<xml xmlns:s=\'uuid:BDC6E3F0-6DA3-11d1-A2A3-00AA00C14882\'\n'
            + '	xmlns:dt=\'uuid:C2F41010-65B3-11d1-A29F-00AA00C14882\'\n'
            + '	xmlns:rs=\'urn:schemas-microsoft-com:rowset\'\n'
            + '	xmlns:z=\'#RowsetSchema\'>\n'
            + '<s:Schema id=\'RowsetSchema\'>\n'
            + '	<s:ElementType name=\'row\' content=\'eltOnly\' rs:updatable=\'true\'>\n'
            + '		<s:AttributeType name=\'MatchId\' rs:number=\'1\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStageScore\' rs:basecolumn=\'MatchId\' rs:keycolumn=\'true\'>\n'
            + '			<s:datatype dt:type=\'int\' dt:maxLength=\'4\' rs:precision=\'10\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'StageId\' rs:number=\'2\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStageScore\' rs:basecolumn=\'StageId\' rs:keycolumn=\'true\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'MemberId\' rs:number=\'3\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStageScore\' rs:basecolumn=\'MemberId\' rs:keycolumn=\'true\'>\n'
            + '			<s:datatype dt:type=\'int\' dt:maxLength=\'4\' rs:precision=\'10\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'ScoreA\' rs:number=\'4\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchStageScore\'\n'
            + '			 rs:basecolumn=\'ScoreA\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'ScoreB\' rs:number=\'5\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchStageScore\'\n'
            + '			 rs:basecolumn=\'ScoreB\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'ScoreC\' rs:number=\'6\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchStageScore\'\n'
            + '			 rs:basecolumn=\'ScoreC\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'ScoreD\' rs:number=\'7\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchStageScore\'\n'
            + '			 rs:basecolumn=\'ScoreD\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Misses\' rs:number=\'8\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchStageScore\'\n'
            + '			 rs:basecolumn=\'Misses\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Penalties\' rs:number=\'9\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStageScore\' rs:basecolumn=\'Penalties\'>\n'
            + '			<s:datatype dt:type=\'i2\' dt:maxLength=\'2\' rs:precision=\'5\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'ProcError\' rs:number=\'10\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStageScore\' rs:basecolumn=\'Procedurals\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'ShootTime\' rs:number=\'11\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStageScore\' rs:basecolumn=\'Time\'>\n'
            + '			<s:datatype dt:type=\'r4\' dt:maxLength=\'4\' rs:precision=\'7\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Remove\' rs:number=\'12\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchStageScore\'\n'
            + '			 rs:basecolumn=\'RemoveScoring\'>\n'
            + '			<s:datatype dt:type=\'boolean\' dt:maxLength=\'2\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'Deduction\' rs:number=\'13\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchStageScore\'\n'
            + '			 rs:basecolumn=\'Deduction\'>\n'
            + '			<s:datatype dt:type=\'boolean\' dt:maxLength=\'2\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'FlagDelete\' rs:number=\'14\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchStageScore\'\n'
            + '			 rs:basecolumn=\'FlagForDelete\'>\n'
            + '			<s:datatype dt:type=\'boolean\' dt:maxLength=\'2\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'IsDisq\' rs:number=\'15\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchStageScore\'\n'
            + '			 rs:basecolumn=\'Disqualified\'>\n'
            + '			<s:datatype dt:type=\'boolean\' dt:maxLength=\'2\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'DedPctg\' rs:number=\'16\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStageScore\' rs:basecolumn=\'DeductionPercent\'>\n'
            + '			<s:datatype dt:type=\'ui1\' dt:maxLength=\'1\' rs:precision=\'3\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'ExtraShot\' rs:number=\'17\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStageScore\' rs:basecolumn=\'ExtraShot\'>\n'
            + '			<s:datatype dt:type=\'i2\' dt:maxLength=\'2\' rs:precision=\'5\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'OverTime\' rs:number=\'18\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStageScore\' rs:basecolumn=\'OverTime\'>\n'
            + '			<s:datatype dt:type=\'r4\' dt:maxLength=\'4\' rs:precision=\'7\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'HitFactor\' rs:number=\'19\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStageScore\' rs:basecolumn=\'HitFactor\'>\n'
            + '			<s:datatype dt:type=\'r4\' dt:maxLength=\'4\' rs:precision=\'7\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'FinalScore\' rs:number=\'20\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStageScore\' rs:basecolumn=\'FinalScore\'>\n'
            + '			<s:datatype dt:type=\'i2\' dt:maxLength=\'2\' rs:precision=\'5\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'LastModify\' rs:number=\'21\' rs:nullable=\'true\' rs:maydefer=\'true\' rs:writeunknown=\'true\'\n'
            + '			 rs:basetable=\'tblMatchStageScore\' rs:basecolumn=\'LastModified\'>\n'
            + '			<s:datatype dt:type=\'dateTime\' rs:dbtype=\'variantdate\' dt:maxLength=\'16\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:AttributeType name=\'NoVerify\' rs:number=\'22\' rs:maydefer=\'true\' rs:writeunknown=\'true\' rs:basetable=\'tblMatchStageScore\'\n'
            + '			 rs:basecolumn=\'NoScoreVerify\'>\n'
            + '			<s:datatype dt:type=\'boolean\' dt:maxLength=\'2\' rs:fixedlength=\'true\'/>\n'
            + '		</s:AttributeType>\n'
            + '		<s:extends type=\'rs:rowbase\'/>\n'
            + '	</s:ElementType>\n'
            + '</s:Schema>\n'
            + '<rs:data>\n'}${
            scores.map((scoresForStage, stageIdx) => scoresForStage.map((score) => {
                if (score.dq) return '';
                const memberId = shooters.findIndex((s) => s.id === score.shooterId) + 1;
                const shooter = shooters.find((s) => s.id === score.shooterId);
                if (!shooter) return '';
                if (shooter.cancelledParticipation) return '';
                const {
                    alphas, charlies, deltas, misses, factorStr, total, noShoots, penalties,
                } = summarizeScore({
                    score, stage: stages[stageIdx], powerFactor: shooter['power-factor'],
                });

                return ''
                    + `	<z:row MatchId='1' StageId='${stageIdx + 1}' MemberId='${memberId}' ScoreA='${alphas}' ScoreB='0' ScoreC='${charlies}' ScoreD='${deltas}' Misses='${misses}' Penalties='${noShoots || 0}'\n`
                    + `		 ProcError='${penalties || 0}' ShootTime='${score.time}' Remove='False' Deduction='False' FlagDelete='False' IsDisq='False' DedPctg='0' ExtraShot='0'\n`
                    + `		 OverTime='0' HitFactor='${factorStr}' FinalScore='${total}' LastModify='${new Date(score.timestamp).toISOString().replace(/\..*$/, '')}' NoVerify='FALSE'/>\n`;
            }).join('')).join('')
        }</rs:data>\n`
            + '</xml>\n';
    }

    getPrepareBatch() {
        return ''
            + '@echo off\n'
            + 'dir /s /b /a-d *.XML >files.txt\n'
            + 'makecab /d "CabinetName1=WinMSS.cab" /f files.txt\n'
            + 'del /q /f files.txt\n';
    }

    saveFiles = async ({ isPcc }) => {
        this.saveData(this.getPrepareBatch(), 'PREPARE.BAT');
        await new Promise((res) => setTimeout(res, 1000));
        this.saveData(this.getScoreData({ isPcc }), 'SCORE.XML');
        await new Promise((res) => setTimeout(res, 1000));
        this.saveData(this.getClassifyData(), 'CLASSIFY.XML');
        await new Promise((res) => setTimeout(res, 1000));
        this.saveData(this.getClubsData({ isPcc }), 'CLUB.XML');
        await new Promise((res) => setTimeout(res, 1000));
        this.saveData(this.getEnrolledData({ isPcc }), 'ENROLLED.XML');
        await new Promise((res) => setTimeout(res, 1000));
        this.saveData(this.getMemberData({ isPcc }), 'MEMBER.XML');
        await new Promise((res) => setTimeout(res, 1000));
        this.saveData(this.getMatchData({ isPcc }), 'THEMATCH.XML');
        await new Promise((res) => setTimeout(res, 1000));
        this.saveData(this.getSquadData(), 'SQUAD.XML');
        await new Promise((res) => setTimeout(res, 1000));
        this.saveData(this.getStagesData(), 'STAGE.XML');
        await new Promise((res) => setTimeout(res, 1000));
        this.saveData(this.getTagData(), 'TAG.XML');
        await new Promise((res) => setTimeout(res, 1000));
        this.saveData(this.getTeamData(), 'TEAM.XML');
    }

    saveData = (text, filename) => {
        const blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
        window.saveAs(blob, filename);
    }

    render() {
        const { t } = this.props;
        const { shootersPcc } = this.state;

        if (!shootersPcc) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <CircularProgress size={75} />
                </div>
            );
        }

        return (
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <div style={{ padding: '0px 24px' }}>
                    <Typography variant='body2' style={{ fontWeight: 400 }}>{t('description1')}</Typography>
                    <Typography variant='body2' style={{ fontWeight: 400, paddingTop: '12px' }}>{t('description2')}</Typography>
                    <Typography variant='body2' style={{ fontWeight: 400, padding: '0px 12px' }}>{t('step1')}</Typography>
                    <Typography variant='body2' style={{ fontWeight: 400, padding: '0px 12px' }}>{t('step2')}</Typography>
                    <Typography variant='body2' style={{ fontWeight: 400, padding: '0px 12px' }}>{t('step3')}</Typography>
                    <Typography variant='caption' style={{ fontWeight: '500', paddingTop: '12px' }}>{t('lastnote')}</Typography>
                    <Button onClick={() => this.saveFiles({ isPcc: false })} variant='contained' color='primary' fullWidth style={{ marginTop: '24px' }}>
                        {t('export')}
                    </Button>
                    {shootersPcc.length > 0 && (
                        <Button onClick={() => this.saveFiles({ isPcc: true })} variant='contained' color='primary' fullWidth style={{ marginTop: '24px' }}>
                            {t('exportpcc')}
                        </Button>
                    )}

                </div>
            </div>
        );
    }
}

ExportToWinMss.propTypes = {
    setTitle: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    match: PropTypes.shape({
        id: PropTypes.string,
    }).isRequired,
    auth: PropTypes.string.isRequired,
};

const styles = {};

function publicIdToRegion(publicId) {
    if (publicId.indexOf('|') === -1) return 'ISR';
    const countryCode = publicId.split('|')[0];
    return countryCodeToRegion(countryCode);
}

function countryCodeToRegion(cc) {
	const entry = WINMSS_COUNTRIES.find(a => a['iso3166_2'] === cc);
    if (!entry && cc === 'ME') return 'MTO';
    if (!entry && cc === 'FR') return 'FRA';
    if (!entry && cc === 'RS') return 'SER';
    if (!entry) return 'ISR';
	return entry.winmss;
}

export default withStyles(styles)(withTranslation('exporttowinmss')(ExportToWinMss));
/* eslint-enable no-tabs */
/* eslint-enable class-methods-use-this */
