import axios from 'axios';
import _ from 'lodash';
import localforage from 'localforage';

/* eslint-disable no-underscore-dangle */
if (typeof (window) !== 'undefined') {
    window.__LOCALFORAGE = localforage;
    (async () => {
        localforage.clear();
        /*
        try {
            const keys = await localforage.keys();
            keys.filter((k) => k.indexOf('api-cache-') === 0).forEach((key) => {
                (async () => {
                    try {
                        const { timestamp } = JSON.parse(await localforage.getItem(key || '{}'));
                        if (timestamp && timestamp < new Date().getTime() - 24 * 60 * 60 * 1000) {
                            await localforage.removeItem(key);
                        }
                    } catch (e) {
                        // Nothing to do
                    }
                })();
            });
        } catch (e) {
            // Nothing to do
        }
        */
    })();
}
/* eslint-enable no-underscore-dangle */

let isApp = false;
let baseUrl = '';

export default {
    init(/* { isApp: $isApp } */ { baseUrl: $baseUrl }) {
        isApp = false; // $isApp;
        baseUrl = $baseUrl || '';
    },
    getMatches: async ({
        mode, accessToken, loginId, publicId,
    } = {}) => {
        const url = `/api/matches/?mode=${mode || ''}&accessToken=${accessToken || ''}&loginId=${loginId || ''}&publicId=${publicId || ''}`;
        if (isApp) return appApi({ method: 'GET', url });
        return axiosGet(url);
    },
    getMatchesForUser: async ({ user, auth }) => {
        const url = `/api/matches/?user=${user}&auth=${auth}`;
        if (isApp) return appApi({ method: 'GET', url });
        return axiosGet(url);
    },
    getMatch: async ({ matchId, auth }) => {
        const url = `${baseUrl}/api/matches/${matchId}?auth=${auth || ''}`;
        if (isApp) return appApi({ method: 'GET', url });
        return axiosGet(url);
    },
    patchMatch: async ({ matchId, auth, patch }) => {
        const { data } = await axios.patch(`/api/matches/${matchId}?auth=${auth || ''}`, patch);
        return data;
    },
    postMatch: async ({ match, auth }) => {
        const { data } = await axios.post(`/api/matches/?auth=${auth}`, match);
        return data;
    },
    postMatchStage: async ({ matchId, auth, stageId }) => {
        const { data } = await axios.post(`/api/matches/${matchId}/stages/?auth=${auth}`, { stageId });
        return data;
    },
    postMatchImage: async ({ matchId, auth, imageData }) => {
        const { data } = await axios.post(`/api/matches/${matchId}/image?auth=${auth}`, imageData);
        return data;
    },
    getShooters: async ({ matchId, user, auth }) => {
        const url = `${baseUrl}/api/matches/${matchId}/shooters?user=${user || ''}&auth=${auth || ''}`;
        if (isApp) return appApi({ method: 'GET', url });
        return axiosGet(url);
    },
    getShooter: async ({
        matchId, shooterId, user, auth,
    }) => {
        const url = `/api/matches/${matchId}/shooters/${shooterId}?user=${user || ''}&auth=${auth || ''}`;
        if (isApp) return appApi({ method: 'GET', url });
        return axiosGet(url);
    },
    postShootersEquipmentCheck: async ({
        matchId, auth, stageIdx, checks,
    }) => {
        const { data } = await axios.post(`/api/matches/${matchId}/shooters/equipmentCheck?auth=${auth}`, {
            stageIdx,
            checks,
        });
        return data;
    },
    postShootersChronoResult: async ({
        matchId, shooterId, auth, result,
    }) => {
        const { data } = await axios.post(`/api/matches/${matchId}/shooters/${shooterId}/chrono?auth=${auth}`, result);
        return data;
    },
    checkShooterPublicId: async ({ matchId, auth, publicId }) => {
        const { data } = await axios.get(`/api/matches/${matchId}/shooters/checkId/${encodeURIComponent(publicId)}?auth=${auth}`);
        return data;
    },
    postShooter: async ({
        matchId, auth, shooter, registrationCode, ro,
    }) => {
        const { data } = await axios.post(`/api/matches/${matchId}/shooters/?auth=${auth || ''}&registrationCode=${registrationCode || ''}${ro ? '&ro=true' : ''}`, shooter);
        return data;
    },
    deleteShooter: async ({
        matchId, auth, shooterId,
    }) => {
        await axios.delete(`/api/matches/${matchId}/shooters/${shooterId}?auth=${auth}`);
    },
    patchShooter: async ({
        matchId, shooterId, auth, shooter, reason, noemail, registrationCode, ro,
    }) => {
        const { data } = await axios.patch(`/api/matches/${matchId}/shooters/${shooterId}?auth=${auth}&reason=${reason || ''}&noemail=${noemail ? 'true' : ''}&registrationCode=${registrationCode || ''}${ro ? '&ro = true' : ''}`, shooter);
        return data;
    },
    commentShooter: async ({
        matchId, shooterId, auth, comment,
    }) => {
        const { data } = await axios.put(`/api/matches/${matchId}/shooters/${shooterId}/comments?auth=${auth}`, { comment });
        return data;
    },
    getShooterPayment: async ({
        matchId, shooterId, user, auth, status,
    }) => {
        const url = `/api/matches/${matchId}/shooters/${shooterId}/payment?user=${user || ''}&auth=${auth || ''}&status=${status || ''}`;
        if (isApp) return appApi({ method: 'GET', url });
        return axiosGet(url);
    },
    getStaff: async ({ matchId, auth, anonymize }) => {
        const url = `/api/matches/${matchId}/staff?auth=${auth || ''}&anonymize=${anonymize ? 'true' : ''}`;
        if (isApp) return appApi({ method: 'GET', url });
        return axiosGet(url);
    },
    setStaff: async ({ matchId, auth, staff }) => {
        await axios.put(`/api/matches/${matchId}/staff/?auth=${auth}`, { staff });
    },
    getStages: async ({ stages }) => {
        if (_.isEmpty(stages)) return [];

        if (stages.length === 1) {
            const url = `${baseUrl}/api/stages/${stages[0]}`;
            const stage = await (async () => {
                if (isApp) return appApi({ method: 'GET', url });
                return axiosGet(url);
            })();
            return [stage];
        }

        const promises = _.chunk(stages, 100).map((chunkStages) => {
            const url = `${baseUrl}/api/stages/${chunkStages.join(',')}`;
            if (isApp) return appApi({ method: 'GET', url });
            return axiosGet(url);
        });

        return Promise.all(promises).then((results) => _.flatten(results));
    },
    postStage: async ({ stage }) => {
        const { data } = await axios.post('/api/stages/', stage);
        return data;
    },
    updateStage: async ({
        stageId, auth, force, stage,
    }) => {
        const { data } = await axios.put(`/api/stages/${stageId}/?auth=${auth}&force=${force || ''}`, stage);
        return data;
    },
    postStageImage: async ({ stageId, auth, img }) => {
        const { data } = await axios.post(`/api/stages/${stageId}/image?auth=${auth}`, img);
        return data;
    },
    deleteStage: async ({
        matchId, stageIdx, auth, stageId,
    }) => {
        const { data } = await axios.delete(`/api/matches/${matchId}/stages/${stageIdx}?auth=${auth}&stageId=${stageId}`);
        return data;
    },
    getScores: async ({ matchId, auth }) => {
        let base = null;
        let scores = null;

        const url = `${baseUrl}/api/matches/${matchId}/scores?auth=${auth || ''}`;

        if (isApp) {
            ({ base, scores } = await appApi({ method: 'GET', url }));
        } else {
            ({ base, scores } = await axiosGet(url));
        }

        if (base) {
            const baseData = await axiosGet(base);
            scores = _.uniqBy([...scores, ...baseData], (s) => s.id);
        }

        return scores;
    },
    getScore: async ({ matchId, scoreId, auth }) => {
        const url = `/api/matches/${matchId}/scores/${scoreId}?auth=${auth || ''}`;
        if (isApp) return appApi({ method: 'GET', url });
        return axiosGet(url);
    },
    postScore: async ({
        matchId, auth, finalize, score,
    }) => {
        const { data } = await axios.post(`/api/matches/${matchId}/scores/?auth=${auth}&finalize=${finalize ? 'true' : 'false'}`, score);
        return data;
    },
    patchScore: async ({
        matchId, scoreId, auth, patch,
    }) => {
        const { data } = await axios.patch(`/api/matches/${matchId}/scores/${scoreId}?auth=${auth}`, patch);
        return data;
    },
    deleteScore: async ({
        matchId, scoreId, auth,
    }) => {
        await axios.delete(`/api/matches/${matchId}/scores/${scoreId}?auth=${auth}`);
    },
    getAnnouncements: async ({ matchId, accessToken }) => {
        const url = `/api/matches/${matchId}/announcements?accessToken=${accessToken || ''}`;
        if (isApp) return appApi({ method: 'GET', url });
        return axiosGet(url);
    },
    postAnnouncement: async ({ matchId, accessToken, announcement }) => {
        const { data: newAnnouncement } = await axios.post(`/api/matches/${matchId}/announcements/?auth=${accessToken}`, announcement);
        return newAnnouncement;
    },
    getMessages: async ({ matchId, auth }) => {
        const url = `/api/matches/${matchId}/messages?auth=${auth || ''}`;
        if (isApp) return appApi({ method: 'GET', url });
        return axiosGet(url);
    },
    postMessage: async ({ matchId, auth, message }) => {
        await axios.post(`/api/matches/${matchId}/messages/?auth=${auth}`, message);
    },
    postMessagesRead: async ({ matchId, auth, read }) => {
        await axios.post(`/api/matches/${matchId}/messages/read?auth=${auth}`, read);
    },
    getChronos: async ({ matchId, auth }) => {
        const url = `/api/matches/${matchId}/chronos?auth=${auth || ''}`;
        if (isApp) return appApi({ method: 'GET', url });
        return axiosGet(url);
    },
    postChronoRequest: async ({
        matchId, station, auth, request,
    }) => {
        const { data: chrono } = await axios.post(`/api/matches/${matchId}/chronos/${station}/requests?auth=${auth}`, request);
        return chrono;
    },
    postChronoTestGun: async ({
        matchId, station, auth, gun,
    }) => {
        await axios.post(`/api/matches/${matchId}/chronos/${station}/gun?auth=${auth}`, gun);
    },
    postChronoDailyResults: async ({
        matchId, station, auth, results,
    }) => {
        const { data: chrono } = await axios.post(`/api/matches/${matchId}/chronos/${station}/daily?auth=${auth}`, results);
        return chrono;
    },
    getLeague: async ({ leagueId, auth }) => {
        const { data } = await axios(`/api/leagues/${leagueId}?auth=${auth || ''}`);
        return data;
    },
    getLogin: async ({ accessToken, loginId }) => {
        const url = accessToken ? `/api/logins/?auth=${accessToken}` : `/api/logins/${loginId}`;
        if (isApp) return appApi({ method: 'GET', url });
        return axiosGet(url);
    },
    postLogin: async ({ accessToken, publicId, name }) => {
        const { data: login } = await axios.post(`/api/logins/?auth=${accessToken}`, { publicId, name });
        return login;
    },
    postLoginProfileImage: async ({ accessToken, img }) => {
        const { data } = await axios.post(`/api/logins/profile-image?accessToken=${accessToken}`, img);
        return data;
    },
    deleteLoginMedia: async ({ accessToken, matchId, url }) => {
        await axios.delete(`/api/logins/media/${matchId}/${encodeURIComponent(url)}?accessToken=${accessToken}`);
    },
    getPackages: async ({ matchId, auth }) => {
        const url = `/api/matches/${matchId}/packages?auth=${auth || ''}`;
        if (isApp) return appApi({ method: 'GET', url });
        return axiosGet(url);
    },
    getPackage: async ({ matchId, packageId, auth }) => {
        const url = `/api/matches/${matchId}/packages/${packageId}?auth=${auth || ''}`;
        if (isApp) return appApi({ method: 'GET', url });
        return axiosGet(url);
    },
    getClub: async ({ clubId, auth }) => {
        const url = `/api/clubs/${clubId}?auth=${auth || ''}`;
        if (isApp) return appApi({ method: 'GET', url });
        return axiosGet(url);
    },
    postClub: async ({ club, auth }) => {
        const { data } = await axios.post(`/api/clubs/?auth=${auth}`, club);
        return data;
    },
    getClubs: async ({ regionId, auth }) => {
        const url = `/api/clubs/?region=${regionId || ''}&auth=${auth || ''}`;
        if (isApp) return appApi({ method: 'GET', url });
        return axiosGet(url);
    },
    getClubShooters: async ({ clubId, auth }) => {
        const url = `/api/clubs/${clubId}/shooters?auth=${auth || ''}`;
        if (isApp) return appApi({ method: 'GET', url });
        return axiosGet(url);
    },
    postClubShooter: async ({ clubId, auth, shooter }) => {
        const { data } = await axios.post(`/api/club/${clubId}/shooters/?auth=${auth}`, shooter);
        return data;
    },
    getRegion: async ({ regionId, auth }) => {
        const url = `/api/regions/${regionId}?auth=${auth || ''}`;
        if (isApp) return appApi({ method: 'GET', url });
        return axiosGet(url);
    },
    deleteClubShooter: async ({ clubId, auth, shooterId }) => {
        await axios.delete(`/api/clubs/${clubId}/shooters/${shooterId}?auth=${auth}`);
    },
    getAuth: async ({
        matchId, clubId, regionId, accessToken,
    }) => {
        const url = (() => {
            if (matchId) return `/api/matches/${matchId}/auth?auth=${accessToken}`;
            if (clubId) return `/api/clubs/${clubId}/auth?auth=${accessToken}`;
            return `/api/regions/${regionId}/auth?auth=${accessToken}`;
        })();

        if (isApp) return appApi({ method: 'GET', url });
        return axiosGet(url);
    },
    sendDeviceHistory: async ({ matchId, auth, data }) => {
        await axios.post(`/api/state-storage/history/?auth=${auth}&matchId=${matchId}&timestamp=${new Date().getTime()}`, data);
    },
    authorizePrinter: async ({ printerId }) => {
        await axios(`/api/utils/authorize-printer?printerId=${printerId}`);
    },
    duplicateDemo: async ({ demoId }) => {
        await axios(`/api/utils/duplicate-demo?demoId=${demoId}`);
    },
    sendFeedback: async ({ name, email, message }) => {
        await axios.post('/api/utils/feedback', { name, email, message });
    },
};

function appApi({ method, url }) {
    const callback = `api${Math.random().toString()}`;
    let res = null;
    window[callback] = (e) => {
        res(e);
    };
    const promise = new Promise((_res) => { res = _res; });
    window.postMessage(JSON.stringify({
        src: 'api',
        params: {
            method,
            url,
            callback,
        },
    }), '*');
    return promise;
}

async function axiosGet(url) {
    let data = null;
    ({ data } = await axios(url));
    if (data && data.action === 'redirect') {
        ({ data } = await axios(data.url));
    }
    /*
    let data = null;
    try {
        ({ data } = await axios(url));
        if (data && data.action === 'redirect') {
            ({ data } = await axios(data.url));
        }
    } catch (e) {
        if (e && e.response && e.response.status && e.response.status >= 500) {
            const cache = await localforage.getItem(`api-cache-${url}`);
            if (!cache) throw e;
            return JSON.parse(cache).data;
        }
        throw e;
    }
    try {
        await localforage.setItem(`api-cache-${url}`, JSON.stringify({ data, timestamp: new Date().getTime() }));
    } catch (e) {
        // Nothing to do...
    }
    */
    return data;
}
