/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EquipmentBelt from './EquipmentBelt';
import { getFlagSrc } from '../matches/Utils';

function ShooterInfo(props) {
    const {
        t, classes, shooter, match,
    } = props;
    const equipmentObj = JSON.parse((shooter.extraFields || {}).equipment || '{}');
    return (
        <div>
            <Typography variant='h6'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {(shooter.publicId.indexOf('|') > -1) && (
                        <img
                            alt={shooter.publicId.split('|')[0]}
                            src={getFlagSrc({ countryCode: shooter.publicId.split('|')[0], size: 32 })}
                        />
                    )}
                    <div style={{ padding: '0px 6px' }}>
                        {shooter.publicId.split('|').pop()}
                    </div>
                    <div>
                        {shooter.name}
                    </div>
                    <div>
                        &nbsp;
                        (
                        {shooter.club}
                        )
                    </div>
                </div>
            </Typography>
            <div style={{ display: 'flex', marginTop: '12px' }}>
                <div style={{ flex: 1 }}>
                    <Typography variant='body2' className={classes.inlineLight}>
                        {t('generic:shooter_division')}
                        &nbsp;
                    </Typography>
                    <Typography variant='body2' className={classes.inlineBold}>
                        {t(`generic:division-${shooter.division}`)}
                    </Typography>
                </div>
                <div style={{ flex: 1 }}>
                    <Typography variant='body2' className={classes.inlineLight}>
                        {t('generic:shooter_category')}
                        &nbsp;
                    </Typography>
                    <Typography variant='body2' className={classes.inlineBold}>
                        {t(`registration:category-${shooter.category}`)}
                    </Typography>
                </div>
                <div style={{ flex: 1 }}>
                    <Typography variant='body2' className={classes.inlineLight}>
                        {t('generic:shooter_power_factor')}
                        &nbsp;
                    </Typography>
                    <Typography variant='body2' className={classes.inlineBold}>
                        {t(`registration:${shooter['power-factor']}`)}
                    </Typography>
                </div>
            </div>
            {(match.registrationExtraFields || []).filter((field) => field !== 'equipment').map((field) => (
                <div key={field} style={{ flex: 1, marginTop: '12px' }}>
                    <Typography variant='body2' className={classes.inlineLight}>
                        {t(`generic:${field.split('_optional')[0]}`)}:&nbsp;
                    </Typography>
                    <Typography variant='body2' className={classes.inlineBold}>
                        {(shooter.extraFields || {})[field]}
                    </Typography>
                </div>
            ))}

            {(match.registrationExtraFields || []).includes('equipment') && (
                <div>
                    <EquipmentBelt
                        equipmentObj={equipmentObj}
                        edit={false}
                        onSetEdit={null}
                        onChange={null}
                    />
                </div>
            )}
        </div>
    );
}

ShooterInfo.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    shooter: PropTypes.shape({
        id: PropTypes.string.isRequired,
        publicId: PropTypes.string.isRequired,
        club: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        division: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        'power-factor': PropTypes.string.isRequired,
        extraFields: PropTypes.shape({}),
    }).isRequired,
    match: PropTypes.shape({
        squads: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        })).isRequired,
        registrationExtraFields: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
};

const styles = () => ({
    inlineBold: {
        fontWeight: 800,
        display: 'inline-block',
    },
    inlineLight: {
        fontWeight: 100,
        display: 'inline-block',
    },
});

export default withStyles(styles, { withTheme: true })(ShooterInfo);
/* eslint-enable react/jsx-one-expression-per-line */
