const lang = {
    en: {
        signature: 'Signature',
        count: 'count area',
        'shooter-info': 'Shooter',
    },
    he: {
        signature: 'חתימה',
        count: 'אזור לספירה',
        'shooter-info': 'יורה',
    },
};

lang.iw = {
    ...lang.he,
    ...{
        'shooter-info': 'לוחם\\ת',
    },
};

export default lang;
