module.exports = {
    en: {
        title: 'Offline Items',
        time: 'Time',
        syncall: 'Sync All',
        data: 'Item',
        datascore: 'Score for {{name}} at stage {{stageIdx}}',
        dataattendance: 'Attendance for {{names}} at stage {{stageIdx}}',
    },
    he: {
        title: 'פריטים שמורים לוקלית',
        time: 'תאריך ושעה',
        syncall: 'סנכרן הכל',
        data: 'פריט',
        datascore: 'תוצאה של {{name}} בתרגיל {{stageIdx}}',
        dataattendance: 'בדיקת ציוד של {{names}} בתרגיל {{stageIdx}}',
    },
};
