import app from '../AppCommunication';

export default class AppStorage {
    constructor() {
        this.base = Math.random().toString();
    }

    async set(key, value) {
        const callback = this.base + Math.random().toString();
        let res = null;
        window[callback] = (e) => { res(e); };
        const promise = new Promise((_res) => { res = _res; });
        app({ cmd: { src: 'storage', type: 'set', params: { key, value, callback } } });
        return promise;
    }

    async get(key) {
        const callback = this.base + Math.random().toString();
        let res = null;
        window[callback] = (e) => { res(e); };
        const promise = new Promise((_res) => { res = _res; });
        app({ cmd: { src: 'storage', type: 'get', params: { key, callback } } });
        return promise;
    }
}
