/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import IconVisibility from '@material-ui/icons/Visibility';
import IconMenu from '@material-ui/icons/Menu';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconSettings from '@material-ui/icons/Settings';
import api from '../Api';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userOpen: false,
            demo: false,
            demoMatchId: null,
        };
    }

    async componentDidMount() {
        const { onLoggedIn } = this.props;
        window.addEventListener('scroll', this.handleScroll);

        const loggedInJson = window.sessionStorage.getItem('loggedin');
        if (loggedInJson) {
            const loggedIn = JSON.parse(loggedInJson);
            // TODO: ack auth
            await new Promise((res) => this.setState({ auth: loggedIn.auth, name: loggedIn.name }, res));
            const matches = await api.getMatchesForUser({ user: loggedIn.user, auth: loggedIn.auth });
            this.setState({ matches });

            onLoggedIn();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { fixedHeader } = this.state;
        const { demo } = this.props;
        if (fixedHeader !== prevState.fixedHeader) {
            setTimeout(() => {
                if (fixedHeader) {
                    this.setState({ animateHeader: true });
                } else {
                    this.setState({ animateHeader: false });
                }
            }, 10);
        }

        if (prevProps.demo !== demo) {
            this.onDemo();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const { fixedHeader } = this.state;
        if ((window.scrollY > 140) && (!fixedHeader)) {
            this.setState({ fixedHeader: true });
        }
        if ((window.scrollY < 5) && (fixedHeader)) {
            this.setState({ fixedHeader: false });
        }
    };

    onUser = async () => {
        this.setState((state) => ({
            userOpen: !state.userOpen,
        }));
    }

    onDisconnect = async () => {
        const { auth } = this.state;
        const { onLoggedOut } = this.props;
        if (auth.startsWith('com.google')) {
            await new Promise((res) => window.gapi.load('auth2', res));
            const $auth = await window.gapi.auth2.init();
            $auth.disconnect();
        } else if (auth.startsWith('com.facebook')) {
            window.FB.logout();
        }
        window.sessionStorage.removeItem('loggedin');
        this.setState(() => ({
            userOpen: false,
            auth: null,
            name: null,
        }));
        onLoggedOut();
    }

    onDemo = async () => {
        await new Promise((res) => this.setState({ demo: true }, res));
        const demoId = new Date().getTime();
        await api.duplicateDemo({ demoId });
        await new Promise((res) => this.setState({ demo: true, demoMatchId: `demo-${demoId}` }, res));
        if (window && window.fbq) {
            window.fbq('track', 'Lead');
        }
    }

    onPrivacy = () => {
        window.location.href = 'https://www.endofscoring.com/privacy/';
    }


    renderDemo() {
        const { mode } = this.props;
        const {
            demo, demoMatchId,
        } = this.state;

        return (
            <Modal open={demo}>
                <div style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100vw',
                }}
                >
                    <div style={{
                        width: '300px', height: demoMatchId ? '330px' : '170px', background: 'white', borderRadius: '3px', boxShadow: '0px 0px 5px rgba(0,0,0,0.2)', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: '24px', textAlign: 'center',
                    }}
                    >
                        <Typography variant='body1' style={{ marginBottom: '16px' }}>
                            {demoMatchId && <span>We&apos;ve got your demo all set up!</span>}
                            {!demoMatchId && <span>Creating a demo match for you...</span>}
                        </Typography>
                        {!demoMatchId && <CircularProgress size={36} />}
                        {demoMatchId && (
                            <Button
                                fullWidth
                                style={{ marginBottom: '6px' }}
                                component={(props) => (mode === 'app' ? (
                                    <Link to={`/${demoMatchId}?mode=app`} {...props} />
                                ) : (
                                    <a href={`/${demoMatchId}`} target='_blank' rel='noopener noreferrer' {...props} onClick={() => this.setState({ demo: false })} />
                                ))}
                                color='primary'
                                variant='contained'
                            >
                                To Match Website
                            </Button>
                        )}
                        {demoMatchId && <Typography variant='caption' style={{ marginBottom: '12px' }}>See what your shooters will see: Match info, registration, live results, squads, stages, and much much more.</Typography>}
                        {demoMatchId && (
                            <Button
                                fullWidth
                                style={{ marginBottom: '6px' }}
                                component={(props) => (mode === 'app' ? (
                                    <Link to={`/${demoMatchId}/manage?mode=app`} {...props} />
                                ) : (
                                    <a href={`/${demoMatchId}/manage`} target='_blank' rel='noopener noreferrer' {...props} onClick={() => this.setState({ demo: false })} />
                                ))}
                                color='primary'
                                variant='contained'
                            >
                                To Match Management
                            </Button>
                        )}
                        {demoMatchId && <Typography variant='caption' style={{ marginBottom: '12px' }}>What can you control? Almost everything. Check out the different settings and setups you can create.</Typography>}
                        {demoMatchId && (
                            <Button
                                fullWidth
                                style={{ marginBottom: '6px' }}
                                component={(props) => (mode === 'app' ? (
                                    <Link to={`/${demoMatchId}/manage/scores?mode=app`} {...props} />
                                ) : (
                                    <a href={`/${demoMatchId}/manage/scores`} target='_blank' rel='noopener noreferrer' {...props} onClick={() => this.setState({ demo: false })} />
                                ))}
                                color='primary'
                                variant='contained'
                            >
                                To Scoring
                            </Button>
                        )}
                        {demoMatchId && <Typography variant='caption'>The day of the match is here. Be an Aria RO, and enter scores, manage attendence, and conduct equipment checks for squads.</Typography>}
                    </div>
                </div>
            </Modal>
        );
    }

    render() {
        const { classes, mode } = this.props;
        const {
            fixedHeader, animateHeader, auth, name, userOpen, matches,
        } = this.state;

        if (mode === 'app') {
            return this.renderDemo();
        }

        return (
            <Grid item classes={{ item: classnames([classes.header, fixedHeader ? classes.fixedheader : null, animateHeader ? classes.animateheader : null]) }} xs={12}>
                <div className={classes.logo} />
                <div style={{ flex: 1 }} />
                {!auth && (
                <div className='home-topbar-buttons'>
                    <Button variant='text' style={{ margin: '0px 12px', height: '40px' }} onClick={this.onPrivacy}>privacy</Button>
                    <Hidden smDown>
                        <Button variant='contained' color='primary' style={{ margin: '0px 12px', height: '40px' }} component={(props) => <Link to='/new' {...props} />}>Sign Up!</Button>
                        <Button variant='contained' color='secondary' style={{ margin: '0px 12px', height: '40px' }} onClick={this.onDemo}>Demo</Button>
                    </Hidden>
                    <Button style={{ margin: '0px 1px', height: '40px' }} component={(props) => <Link to='/signin' {...props} />}>Sign In</Button>
                </div>
                )}
                {auth && (
                    <div className='home-topbar-buttons'>
                        <Hidden smDown>
                            <Button variant='contained' color='primary' style={{ margin: '0px 12px', height: '40px' }} component={(props) => <Link to='/new' {...props} />}>+ New Match</Button>
                        </Hidden>
                        <Button style={{ margin: '0px 12px', height: '40px' }} onClick={this.onUser}>
                            {name}
&nbsp;
                            <IconMenu />
                        </Button>
                        <Drawer open={userOpen} onClose={() => this.setState({ userOpen: false })} anchor='right' PaperProps={{ style: { width: '300px' } }}>
                            {_.values(matches || {}).map((match) => (
                                <Paper style={{ padding: '6px', margin: '6px' }}>
                                    <Typography variant='body1'>
                                        {match.title}
                                    </Typography>
                                    <Typography variant='caption'>
                                        {match.description}
                                    </Typography>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '6px' }}>
                                        <IconButton component={(props) => <Link to={`/${match.alias}`} {...props} />} color='primary'>
                                            <IconVisibility />
                                        </IconButton>
                                        <IconButton component={(props) => <Link to={`/${match.alias}/manage`} {...props} />} color='secondary'>
                                            <IconSettings />
                                        </IconButton>
                                    </div>
                                </Paper>

                            ))}
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '6px 6px 36px 6px' }}>
                                <Typography className={classes.linkbutton} color='primary' variant='body2' component={(props) => <Link to='/new' {...props} />}>+ New Match</Typography>
                                <Typography className={classes.linkbutton} variant='body2' onClick={this.onDisconnect}>Disconnect</Typography>
                            </div>
                        </Drawer>
                    </div>
                )}
                {this.renderDemo()}
            </Grid>
        );
    }
}

const styles = {
    header: {
        borderBottom: '1px solid rgba(50,48,57,0.08)',
        background: '#ffffff',
        position: 'absolute',
        height: '80px',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 24px',
    },
    fixedheader: {
        position: 'fixed',
        top: '-80px',
    },
    animateheader: {
        top: '0px',
        boxShadow: '1px 1px 4px rgba(0,0,0,0.5)',
        transition: 'all 0.3s cubic-bezier(0.34, 0.61, 0.59, 1.11) 0.4s',
    },
    top: {
        marginTop: '80px',
        position: 'relative',
    },
    logo: {
        alignItems: 'center',
        height: '70px',
        width: '170px',
        backgroundImage: 'url("./logo.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
};

Header.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    onLoggedIn: PropTypes.func,
    onLoggedOut: PropTypes.func,
    demo: PropTypes.bool,
    mode: PropTypes.string,
};

Header.defaultProps = {
    onLoggedIn: null,
    onLoggedOut: null,
    demo: false,
    mode: null,
};

export default withStyles(styles, { withTheme: true, flip: false })(withTranslation('home')(Header));
/* eslint-enable jsx-a11y/anchor-has-content */
