import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import IconCheck from '@material-ui/icons/Check';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';

const Pricing = (props) => {
    const { classes } = props;

    return (
        <Grid container classes={{ container: classes.wrapper }}>
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography variant='h4'>
                    Pricing
                </Typography>
                <Typography variant='body1'>
                    Free for up to 50 Shooters! Try us out before purchasing.
                </Typography>
            </Grid>
            <Grid
                container
                spacing={24}
                style={{
                    marginTop: '12px', alignItems: 'center', display: 'flex', justifyContent: 'center', textAlign: 'center',
                }}
            >
                <Grid item xs={12} md={3} className='home-pricinggrid'>
                    <div style={{ height: '100%' }}>
                        <Card classes={{ root: 'home-pricingcard' }}>
                            <CardHeader
                                title='Trying us out?'
                                subheader={(
                                    <div>
                                        <div>Free!</div>
                                        <Typography variant='caption' className={classes.whiteText}>Up to 50 shooters</Typography>
                                    </div>
)}
                                classes={{ title: classes.whiteText, subheader: classes.whiteText }}
                                style={{ background: grey[500] }}
                            />
                            <CardContent style={{ height: 'calc(100% - 200px)' }}>
                                {['Shooter Registration', 'Showcase Unlimited Stages', 'Squad Management', 'Smart Scoring System', 'Live Scores', 'RO registration', 'Website for $9 per month (free for first 3 months)'].map((feature) => (
                                    <div
                                        key={feature}
                                        style={{
                                            display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '12px',
                                        }}
                                    >
                                        <IconCheck />
                                        <div style={{ flex: 1, margin: '0px 12px' }}>
                                            {feature}
                                        </div>
                                    </div>
                                ))}
                            </CardContent>
                            <CardActions>
                                <Button fullWidth variant='contained' color='primary' component={($props) => <Link to='/new' {...$props} />}>Sign Up!</Button>
                            </CardActions>
                        </Card>
                    </div>
                </Grid>
                <Grid item xs={12} md={3} className='home-pricinggrid'>
                    <div style={{ height: '100%' }}>
                        <Card classes={{ root: 'home-pricingcard' }}>
                            <CardHeader
                                title='Unlimited'
                                subheader={(
                                    <div>
                                        <div>$299 per year</div>
                                        <Typography variant='caption' className={classes.whiteText}>Manage unlimited matches with unlimited shooters</Typography>
                                    </div>
)}
                                classes={{ title: classes.whiteText, subheader: classes.whiteText }}
                                style={{ background: red[300] }}
                            />
                            <CardContent style={{ height: 'calc(100% - 200px)' }}>
                                {['Shooter Registration', 'Showcase Unlimited Stages', 'Squad Management', 'Smart Scoring System', 'Live Scores', 'Shooters Stats and Compare Shooters', 'Site and Email Announcements', 'Website for your matches'].map((feature) => (
                                    <div
                                        key={feature}
                                        style={{
                                            display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '12px',
                                        }}
                                    >
                                        <IconCheck />
                                        <div style={{ flex: 1 }}>
                                            {feature}
                                        </div>
                                    </div>
                                ))}
                            </CardContent>
                            <CardActions>
                                <Button fullWidth variant='contained' color='primary' component={($props) => <Link to='/new' {...$props} />}>Sign Up!</Button>
                            </CardActions>
                        </Card>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h5' style={{ marginTop: '24px' }}>I just want a website...</Typography>
            </Grid>
            <Grid
                container
                spacing={24}
                style={{
                    marginTop: '12px', alignItems: 'center', display: 'flex', justifyContent: 'center', textAlign: 'center',
                }}
            >
                <Grid item xs={12} md={3} className='home-pricinggrid'>
                    <div style={{ height: '100%' }}>
                        <Card classes={{ root: 'home-pricingcard' }}>
                            <CardHeader title='Website Only' subheader='$9 per month' classes={{ title: classes.whiteText, subheader: classes.whiteText }} style={{ background: green[300] }} />
                            <CardContent>
                                {['Generic Match Info', 'Shooter Registration', 'Showcase Unlimited Stages'].map((feature) => (
                                    <div
                                        key={feature}
                                        style={{
                                            display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '12px',
                                        }}
                                    >
                                        <IconCheck />
                                        <div style={{ flex: 1, margin: '0px 12px' }}>
                                            {feature}
                                        </div>
                                    </div>
                                ))}
                            </CardContent>
                            <CardActions>
                                <Button fullWidth variant='contained' color='primary' component={($props) => <Link to='/new' {...$props} />}>Sign Up!</Button>
                            </CardActions>
                        </Card>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};

const styles = {
    wrapper: {
        padding: '0px 12px',
        textAlign: 'center',
    },
    whiteText: {
        color: 'white',
    },
};

export default withStyles(styles, { withTheme: true, flip: false })(withTranslation('home')(Pricing));
