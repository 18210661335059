import './Squads.css';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import PrintIcon from '@material-ui/icons/Print';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import _ from 'lodash';
import uuidv4 from 'uuid/v4';
import { getFlagSrc } from './Utils';
import Snackbar from '../components/Snackbar';
import api from '../Api';

class Squads extends Component {
    constructor(props) {
        const { setTitle, match, t } = props;
        super(props);
        setTitle(t('title', { name: match.title }));
        this.state = {
            squads: null,
            errors: {},
        };
    }

    async UNSAFE_componentWillMount() {
        const { match, auth } = this.props;
        const promises = await Promise.all([
            api.getMatch({ matchId: match.id, auth }),
            api.getShooters({ matchId: match.id, auth }),
        ]);
        const $match = promises[0];
        const shooters = promises[1] || [];

        this.setState({
            squads: $match.squads || [],
            shooters,
        });
    }

    componentDidMount() {
        const {
            manage, setTitle, setSaveButtonCallback, setExtraButtons, t, match,
        } = this.props;
        if (manage) setTitle(t('title', { name: match.title }));
        if (manage) setSaveButtonCallback(null);
        if (manage) setExtraButtons(null);

        if (typeof (window) !== 'undefined') {
            window.scrollTo(0, 0);
        }
    }

    handleOnClick = async () => {
        const { match, auth } = this.props;
        const { squads } = this.state;

        const errors = {};
        squads.forEach((squad) => {
            squad.maxShooters = squad.maxShooters || 0; // eslint-disable-line no-param-reassign
            squad.firstStage = squad.firstStage || 0; // eslint-disable-line no-param-reassign
            if (Number.isNaN(parseInt(squad.maxShooters, 10))) {
                errors[squad.id] = errors[squad.id] || {};
                errors[squad.id].maxShooters = true;
            } else {
                squad.maxShooters = parseInt(squad.maxShooters, 10); // eslint-disable-line no-param-reassign
            }
            if (Number.isNaN(parseInt(squad.firstStage, 10))) {
                errors[squad.id] = errors[squad.id] || {};
                errors[squad.id].firstStage = true;
            } else {
                squad.firstStage = parseInt(squad.firstStage, 10); // eslint-disable-line no-param-reassign
            }
        });

        if (!_.isEmpty(errors)) {
            this.setState({ errors });
            return;
        }

        await new Promise((res) => this.setState({ sending: true, errors }, res));
        try {
            await api.patchMatch({
                matchId: match.id,
                auth,
                patch: {
                    squads,
                },
            });
            this.setState({ sending: false, saveSuccess: true });
        } catch (e) {
            if (_.get(e, 'response.data.type', '').indexOf('delete-non-empty-squad') > -1) {
                this.setState({ sending: false, saveError: 'delete-non-empty-squad' });
            } else {
                this.setState({ sending: false, saveError: 'generic:saveerror' });
            }
        }
    }

    handleOnOrderUp = ({ squadIndex, order, shooterId }) => {
        const fromIndex = order.indexOf(shooterId);
        if (fromIndex === 0) return;
        order.splice(fromIndex, 1);
        order.splice(fromIndex - 1, 0, shooterId);

        const { squads } = this.state;
        const $squads = JSON.parse(JSON.stringify(squads));
        $squads[squadIndex].order = order;
        this.setState({ squads: $squads });
    }

    handleOnOrderDown = ({ squadIndex, order, shooterId }) => {
        const fromIndex = order.indexOf(shooterId);
        if (fromIndex === order.length - 1) return;
        order.splice(fromIndex, 1);
        order.splice(fromIndex + 1, 0, shooterId);

        const { squads } = this.state;
        const $squads = JSON.parse(JSON.stringify(squads));
        $squads[squadIndex].order = order;
        this.setState({ squads: $squads });
    }

    renderSquad(squadIndex) {
        const { t, manage } = this.props;
        const {
            squads, shooters, errors, reorder,
        } = this.state;

        if (squadIndex >= squads.length) return manage ? this.renderNewSquad() : null;
        const {
            id, title = '', description = '', maxShooters = 0, firstStage = 0, order = [], ro = false,
        } = squads[squadIndex];

        const titleError = !title || !!squads.find((s, si) => (si !== squadIndex) && (s.title === title));

        const allShootersForSquad = shooters.filter((s) => s.squad === id).map((s) => s.id);
        const orderShooters = _.map(_.intersection(_.union(order, allShootersForSquad), allShootersForSquad), (s) => shooters.find((s2) => s2.id === s));

        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <div className='squads-squad-name'>
                    {manage && (
                    <TextField
                        style={{ flex: 1 }}
                        label={t('squad-name')}
                        value={title}
                        error={titleError}
                        onChange={(e) => {
                            const newSquads = JSON.parse(JSON.stringify(squads));
                            newSquads[squadIndex].title = e.target.value;
                            this.setState({ squads: newSquads });
                        }}
                    />
                    )}
                    {manage && (
                        <IconButton
                            disabled={shooters.find((s) => s.squad === id)}
                            color='secondary'
                            onClick={() => {
                                const newSquads = JSON.parse(JSON.stringify(squads)).filter((s) => s.id !== id);
                                this.setState({ squads: newSquads });
                            }}
                        >
                            <DeleteForeverOutlinedIcon fontSize='small' />
                        </IconButton>
                    )}
                </div>
                <div className='squads-squad-description'>
                    {manage && (
                    <TextField
                        style={{ width: '100%', marginTop: '12px' }}
                        label={t('squad-description')}
                        value={description}
                        onChange={(e) => {
                            const newSquads = JSON.parse(JSON.stringify(squads));
                            newSquads[squadIndex].description = e.target.value;
                            this.setState({ squads: newSquads });
                        }}
                    />
                    )}
                </div>
                {manage && (
                <div className='squads-squad-description'>
                    <TextField
                        style={{ width: '100%', marginTop: '12px' }}
                        label={t('squad-max-shooters')}
                        value={maxShooters}
                        onChange={(e) => {
                            const newSquads = JSON.parse(JSON.stringify(squads));
                            newSquads[squadIndex].maxShooters = e.target.value;
                            this.setState({ squads: newSquads });
                        }}
                        error={(errors[squads[squadIndex].id] || {}).maxShooters}
                    />
                </div>
                )}
                {manage && (
                    <div className='squads-squad-description'>
                        <TextField
                            style={{ width: '100%', marginTop: '12px' }}
                            label={t('squad-first-stage')}
                            value={firstStage}
                            onChange={(e) => {
                                const newSquads = JSON.parse(JSON.stringify(squads));
                                newSquads[squadIndex].firstStage = e.target.value;
                                this.setState({ squads: newSquads });
                            }}
                            error={(errors[squads[squadIndex].id] || {}).firstStage}
                        />
                    </div>
                )}

                {manage && (
                    <FormControlLabel
                        control={(
                            <Checkbox
                                checked={ro}
                                onChange={() => {
                                    const newSquads = JSON.parse(JSON.stringify(squads));
                                    newSquads[squadIndex].ro = !ro;
                                    this.setState({ squads: newSquads });
                                }}
                            />
                        )}
                        label={(<div>{t('ro')}</div>)}
                    />
                )}

                <Paper style={{ padding: '24px', marginTop: '12px', flex: 1 }} classes={{ root: 'squads-squad-paper' }}>
                    {!manage && <Typography variant='h5'>{title}</Typography>}
                    {!manage && <Typography variant='caption'>{description}</Typography>}
                    {!manage && ro && (<Typography style={{ fontWeight: 800 }} variant='caption'>{t('ro')}</Typography>)}
                    {!manage && <hr style={{ opacity: '0.5', margin: '12px 0px' }} />}
                    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '6px' }}>
                        <div style={{ flex: '1' }}>
                            <Typography variant='caption'>{t('numshooters', { count: shooters.filter((s) => s.squad === id).length })}</Typography>
                        </div>
                        {manage && (
                            <Button size='small' color='primary' variant='text' onClick={() => this.setState({ reorder: reorder === squadIndex ? -1 : squadIndex })}>
                                {t('reorder')}
                            </Button>
                        )}
                    </div>
                    {orderShooters.map((shooter, index) => {
                        if (!shooter.publicId) return null;
                        return (
                            <div key={shooter.id} style={{ display: 'flex', alignItems: 'center', padding: '6px 0px' }} className='squads-squad-shooter'>
                                <div style={{ flex: 1 }}>
                                    <Typography variant='subtitle1'>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div>
                                                {(shooter.publicId.indexOf('|') > -1) && (
                                                    <img
                                                        alt={shooter.publicId.split('|')[0]}
                                                        src={getFlagSrc({ countryCode: shooter.publicId.split('|')[0], size: 16 })}
                                                    />
                                                )}
                                            </div>
                                            <div>
                                                <div style={{ display: 'flex', lineHeight: '12px' }}>
                                                    <div style={{ padding: '0px 6px' }}>
                                                        {shooter.publicId.split('|').pop()}
                                                    </div>
                                                    {' '}
                                                    /
                                                    {' '}
                                                    {shooter.name}
                                                </div>
                                                <div>
                                                    <Typography
                                                        variant='body1'
                                                        style={{
                                                            margin: '0px 3px', flex: 1, opacity: 0.6, fontSize: '0.7em',
                                                        }}
                                                    >
                                                        (
                                                        {shooter.club}
                                                        &nbsp;
                                                        |
                                                        &nbsp;
                                                        {t(`generic:division-${shooter.division}`)}
                                                        &nbsp;
                                                        |
                                                        &nbsp;
                                                        {t(`registration:category-${shooter.category}`)}
                                                        )
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </Typography>
                                </div>
                                {manage && reorder === squadIndex && (
                                    <div style={{ flex: 0 }}>
                                        <Button
                                            color='primary'
                                            variant='text'
                                            disabled={index === 0}
                                            onClick={() => this.handleOnOrderUp({ squadIndex, order: orderShooters.map((s) => s.id), shooterId: shooter.id })}
                                        >
                                            <ArrowDropUpIcon />
                                        </Button>
                                        <Button
                                            color='secondary'
                                            variant='text'
                                            disabled={index === orderShooters.length - 1}
                                            onClick={() => this.handleOnOrderDown({ squadIndex, order: orderShooters.map((s) => s.id), shooterId: shooter.id })}
                                        >
                                            <ArrowDropDownIcon />
                                        </Button>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </Paper>
            </div>
        );
    }

    renderNewSquad() {
        const { t } = this.props;
        const { squads } = this.state;
        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <div style={{ opacity: 0.1 }}>
                    <Typography variant='h6'>
                        {t('new-squad-title')}
                    </Typography>
                </div>
                <Paper style={{ padding: '24px', background: 'rgba(255, 255, 255, 0.1)', flex: 1 }}>
                    <div style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%',
                    }}
                    >
                        <Button
                            variant='fab'
                            color='primary'
                            aria-label='Add'
                            onClick={() => {
                                this.setState({ squads: [...squads, { id: uuidv4(), title: '' }] });
                            }}
                        >
                            <AddIcon />
                        </Button>
                    </div>
                </Paper>
            </div>
        );
    }

    render() {
        const {
            squads, sending, saveError, saveSuccess,
        } = this.state;
        const {
            url, t, manage, classes,
        } = this.props;

        if (!squads) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <CircularProgress size={75} />
                </div>
            );
        }

        const ButtonLink = (props) => <Link to={`${url}/print`} {...props} />;

        return (
            <div style={{
                display: 'flex', alignItems: 'center', flexDirection: 'column', position: 'relative',
            }}
            >
                {squads.length > 0 && manage && (
                    <Button component={ButtonLink} classes={{ root: classes.printbutton }}>
                        <PrintIcon color='primary' />
                    </Button>
                )}

                {squads.length === 0 && !manage && (
                    <Typography variant='h6' style={{ textAlign: 'center', marginTop: '12px' }}>
                        {t('nosquads')}
                    </Typography>
                )}

                <div style={{ maxWidth: '720px', width: '90%' }}>
                    <Grid container spacing={8}>
                        {[...squads, null].map((squad, squadIndex) => (
                            <Grid key={(squad || {}).id || 'new'} item xs={12} md={manage ? 6 : 12}>
                                {this.renderSquad(squadIndex)}
                            </Grid>
                        ))}
                    </Grid>
                </div>

                {manage && (
                <Button
                    variant='contained'
                    color='primary'
                    style={{
                        maxWidth: '720px', width: '90%', marginBottom: '48px', marginTop: '24px',
                    }}
                    onClick={this.handleOnClick}
                    disabled={sending}
                >
                    {sending ? <CircularProgress size={18} /> : t('generic:save')}
                </Button>
                )}

                <Snackbar
                    open={saveError}
                    onClose={() => this.setState({ saveError: null })}
                    variant='error'
                    message={t(saveError)}
                />

                <Snackbar
                    open={saveSuccess}
                    onClose={() => this.setState({ saveSuccess: false })}
                    variant='success'
                    message={t('generic:savesuccess')}
                />
            </div>
        );
    }
}

const styles = () => ({
    printbutton: {
        position: 'absolute',
        right: '0px',
        top: '0px',
    },
});

Squads.propTypes = {
    setTitle: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func,
    setExtraButtons: PropTypes.func,
    t: PropTypes.func.isRequired,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        squads: PropTypes.array.isRequired,
    }).isRequired,
    classes: PropTypes.shape({}).isRequired,
    auth: PropTypes.string,
    manage: PropTypes.bool,
    url: PropTypes.string.isRequired,
};

Squads.defaultProps = {
    auth: null,
    manage: false,
    setSaveButtonCallback: () => {},
    setExtraButtons: () => {},
};

export default withStyles(styles, { withTheme: true })(withTranslation('squads')(Squads));
