module.exports = {
    en: {
        newclub: 'Create New Club',
        title: 'Club name',
        alias: 'Club URL',
        aliasexists: 'The URL you have entered is taken. Please try a different one.',
    },
    he: {
        newclub: 'הוספת מועדון חדש',
        title: 'שם המועדון',
        alias: 'כתובת האינטרנט של המועדון',
        aliasexists: 'כתובת האינטרנט שהכנסתם תפוסה. אנא נסו אחת אחר.',
    },
};
