/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import lime from '@material-ui/core/colors/lime';
import blue from '@material-ui/core/colors/blue';
import CircularProgress from '@material-ui/core/CircularProgress';
import Refresh from '@material-ui/icons/Refresh';
import Wrapper from './HomeI18nWrapper';
import { getScoresWithPoints, pad } from '../matches/Utils';
import api from '../Api';
import app from '../AppCommunication';

class HomeAppUserDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        const { classes } = this.props;
        window.scrollTo(0, 0);

        document.body.classList.add(classes.body);
        document.documentElement.classList.add(classes.body);

        this.refresh();
    }

    componentWillUnmount() {
        const { classes } = this.props;
        document.documentElement.classList.remove(classes.body);
        document.body.classList.remove(classes.body);
    }

    refresh = async () => {
        const { accessToken, loginId, match: routeMatch } = this.props;
        const { matchId } = routeMatch.params;

        await new Promise((res) => this.setState({
            login: null, registration: null, match: null, scoresByStage: null, scoresOverall: null, shooters: null, stages: null, dqs: null,
        }, res));

        const [{ registrations, staff }, match, scores, shooters, { login }] = await Promise.all([
            api.getMatches({ mode: 'as-shooter-or-staff', accessToken }),
            api.getMatch({ matchId }),
            new Promise((res) => api.getScores({ matchId }).then(res).catch(() => res([]))),
            api.getShooters({ matchId }),
            accessToken ? api.getLogin({ accessToken }) : api.getLogin({ loginId }),
        ]);

        const stages = await api.getStages({ stages: match.stages });

        const registration = registrations.find((r) => r.matchId === matchId);
        const isStaff = !!staff.find((s) => s === matchId);

        const { scoresByStage, scoresOverall, dqs } = getScoresWithPoints({ scores, stages, shooters });

        this.setState({
            login, registration, match, isStaff, scoresByStage, scoresOverall, shooters, stages, dqs,
        });
    }

    toMatchSite = () => {
        const { history, accessToken } = this.props;
        const { match } = this.state;
        history.push(`/${match.alias}?mode=app&accessToken=${accessToken}`);
    }

    toMatchManagement = () => {
        const { history } = this.props;
        const { match } = this.state;
        history.push(`/${match.alias}/manage?mode=app`);
    }

    toShooterStats = () => {
        const { history } = this.props;
        const { match, registration } = this.state;
        history.push(`/${match.alias}/shooter-stats/shooter/${registration.id}?mode=app`);
    }

    onAddVideo = async () => {
        const { accessToken, match: routeMatch } = this.props;
        const { matchId } = routeMatch.params;

        await new Promise((res) => this.setState({ uploading: true }, res));

        const callback = Math.random().toString();
        app({
            cmd: {
                src: 'image-picker',
                params: {
                    callback,
                    destUrl: `/api/logins/media/${matchId}?accessToken=${accessToken}`,
                    type: 'videos',
                },
            },
            callbackName: callback,
            callback: async (url) => {
                await new Promise((res) => this.setState({ uploading: false }, res));
                if (!url) return;
                const { login } = this.state;
                const newLogin = JSON.parse(JSON.stringify(login));
                newLogin.images = newLogin.images || {};
                newLogin.images[matchId] = newLogin.images[matchId] || [];
                newLogin.images[matchId].push({ url, type: 'mov' });
                this.setState({ login: newLogin });
            },
        });
    }

    onAddImage = async () => {
        const { accessToken, match: routeMatch } = this.props;
        const { matchId } = routeMatch.params;

        await new Promise((res) => this.setState({ uploading: true }, res));

        const callback = Math.random().toString();
        app({
            cmd: {
                src: 'image-picker',
                params: {
                    callback,
                    destUrl: `/api/logins/media/${matchId}?accessToken=${accessToken}`,
                    type: 'images',
                },
            },
            callbackName: callback,
            callback: async (url) => {
                await new Promise((res) => this.setState({ uploading: false }, res));
                if (!url) return;
                const { login } = this.state;
                const newLogin = JSON.parse(JSON.stringify(login));
                newLogin.images = newLogin.images || {};
                newLogin.images[matchId] = newLogin.images[matchId] || [];
                newLogin.images[matchId].push({ url, type: 'jpeg' });
                this.setState({ login: newLogin });
            },
        });
    }

    onPlayVideo = (url) => {
        app({
            cmd: {
                src: 'play-video',
                params: {
                    url,
                },
            },
            callbackName: 'onDelete',
            callback: () => {
                this.setState({ deleteRequest: url });
            },
        });
    }

    onDelete = () => {
        const { accessToken, match: routeMatch } = this.props;
        const { matchId } = routeMatch.params;
        const { login, deleteRequest } = this.state;

        api.deleteLoginMedia({ matchId, url: deleteRequest, accessToken });

        const newLogin = JSON.parse(JSON.stringify(login));
        newLogin.images[matchId] = newLogin.images[matchId].filter((m) => m.url !== deleteRequest);
        this.setState({ login: newLogin });
    }

    onShowImage = (url) => {
        app({
            cmd: {
                src: 'show-image',
                params: {
                    url,
                },
            },
            callbackName: 'onDelete',
            callback: () => {
                this.setState({ deleteRequest: url });
            },
        });
    }

    onLoadedData = (e, media) => {
        const { target } = e;
        const func = () => {
            target.removeEventListener('timeupdate', func);
            const canvas = document.createElement('canvas');
            canvas.width = target.videoWidth;
            canvas.height = target.videoHeight;
            canvas.getContext('2d').drawImage(target, 0, 0, canvas.width, canvas.height);
            const image = canvas.toDataURL();
            const div = document.getElementById(encodeURIComponent(media.url));
            if (!div) return;
            div.style.backgroundColor = 'black';
            div.style.backgroundImage = `url(${image})`;
            div.style.backgroundSize = 'contain';
            div.style.backgroundPosition = 'center center';
            div.style.backgroundRepeat = 'no-repeat';
        };
        target.addEventListener('timeupdate', func);
        target.currentTime = 10;
    }

    renderStageScore(stageIdx) {
        const { t } = this.props;
        const {
            registration, match, stages, scoresByStage,
        } = this.state;

        const stage = stages.find((s) => s.id.toString() === match.stages[stageIdx].toString());
        const userScoreIdx = scoresByStage[stageIdx].findIndex((s) => s.shooterId.toString() === ((registration || {}).id || '').toString());

        if (userScoreIdx === -1) {
            return (
                <div style={{ borderTop: stageIdx === 0 ? 'none' : `1px solid ${grey[300]}`, padding: '12px 0px' }}>
                    <div>
                        <Typography variant='h6'>{t('scorestage', { index: stageIdx + 1, title: stage.title })}</Typography>
                    </div>
                    <Typography style={{ color: grey[500], marginTop: '12px' }} variant='subtitle2'>
                        {t('no-result-for-stage')}
                    </Typography>
                </div>
            );
        }

        const userScore = scoresByStage[stageIdx][userScoreIdx];

        return (
            <div key={stageIdx} style={{ borderTop: stageIdx === 0 ? 'none' : `1px solid ${grey[300]}`, padding: '12px 0px' }}>
                <div>
                    <Typography variant='h6'>{t('scorestage', { index: stageIdx + 1, title: stage.title })}</Typography>
                </div>
                {[[
                    { title: 'A', value: userScore.alphas },
                    { title: 'C', value: userScore.charlies },
                    { title: 'D', value: userScore.deltas },
                    { title: 'M', value: userScore.misses },
                    { title: '(M)', value: userScore.bonusMisses },
                    { title: 'PT', value: userScore.noShoots },
                    { title: 'Proc', value: userScore.penalties },
                    { title: 'Ded', value: userScore.specialPenaltyPercentageStr },
                ], [
                    { title: t('generic:result_time'), value: userScore.timeStr },
                    { title: t('generic:result_total'), value: userScore.total },
                    { title: t('generic:result_factor'), value: userScore.factorStr },
                ], [
                    { title: t('result-%'), value: `${pad(parseInt((userScore.factor * 10000) / scoresByStage[stageIdx][0].factor, 10) / 100, 2)}%` },
                    { title: t('generic:result_stage_points'), value: userScore.points },
                    { title: t('result-pos'), value: `${userScoreIdx + 1} / ${scoresByStage[stageIdx].length}` },
                ]].map((line, lineIdx) => (
                    <div key={lineIdx}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {line.filter((a) => !!a.value).map((a, i) => ([
                                (i > 0) ? (
                                    <Typography variant='body2' style={{ fontWeight: 100, display: 'inline-block' }}>
                                        &nbsp;
                                        |
                                        &nbsp;
                                    </Typography>
                                ) : null,
                                <div key={a.title} style={{ marginTop: '12px' }}>
                                    <div>
                                        <Typography variant='body2' style={{ fontWeight: 800, display: 'inline-block' }}>
                                            {a.value}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography variant='caption' style={{ fontWeight: 100, display: 'inline-block' }}>
                                            {a.title}
                                        </Typography>
                                    </div>
                                </div>,
                            ]))}
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    renderDQ(dq) {
        const { t } = this.props;
        return (
            <Card style={{ marginTop: '12px' }}>
                <CardContent>
                    <div>
                        <Typography variant='h6' style={{ color: red[500] }}>{t('result-dq')}</Typography>
                    </div>
                    {[[
                        { title: t('dq-rule'), value: dq.dq },
                        { title: t('dq-date'), value: new Date(dq.timestamp).toLocaleString() },
                    ]].map((line, lineIdx) => (
                        <div key={lineIdx}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {line.filter((a) => !!a.value).map((a, i) => (
                                    <div key={a.title} style={{ marginTop: '12px' }}>
                                        {(i > 0) && (
                                            <Typography variant='body2' style={{ fontWeight: 100, display: 'inline-block' }}>
                                                &nbsp;
                                                |
                                                &nbsp;
                                            </Typography>
                                        )}
                                        <Typography variant='body2' style={{ fontWeight: 100, display: 'inline-block' }}>
                                            {a.title}
                                        </Typography>
                                        <Typography variant='body2' style={{ fontWeight: 800, display: 'inline-block' }}>
                                            {a.value}
                                        </Typography>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </CardContent>
            </Card>
        );
    }

    renderResult() {
        const { t } = this.props;
        const { scoresOverall, registration } = this.state;

        let result = <Typography variant='h6'>{t('registration')}</Typography>;
        let avatar = null;

        if (registration) {
            const scoresDivision = scoresOverall.filter((s) => s.division === registration.division);
            const meIndex = scoresDivision.findIndex((s) => s.shooterId === registration.id);
            if (meIndex > -1) {
                const me = scoresDivision[meIndex];
                const scoresCategory = scoresDivision.filter((s) => s.category === registration.category);
                const meCatIndex = registration.category === 'regular' ? -1 : scoresCategory.findIndex((s) => s.shooterId === registration.id);

                result = (() => {
                    if (registration.category !== 'regular') {
                        return (
                            <Typography variant='h6'>
                                {t('result-category', {
                                    pos: t((meIndex + 1).toString()),
                                    all: scoresDivision.length,
                                    points: pad(me.score, 4),
                                    percentage: parseInt(me.percentage * 10000, 10) / 100,
                                    catPos: t((meCatIndex + 1).toString()),
                                    catPercentage: parseInt((me.percentage / scoresCategory[0].percentage) * 10000, 10) / 100,
                                })}
                            </Typography>
                        );
                    }

                    return (
                        <Typography variant='h6'>
                            {t('result', {
                                pos: t((meIndex + 1).toString()),
                                all: scoresDivision.length,
                                points: pad(me.score, 4),
                                percentage: pad(me.percentage / 100, 2),
                            })}
                        </Typography>
                    );
                })();

                avatar = (() => {
                    if ((meIndex === 0) || (meCatIndex === 0)) {
                        return <i style={{ color: yellow[600], fontSize: '36px', marginBottom: '12px' }} className='fas fa-trophy' />;
                    }
                    if ((meIndex === 1) || (meCatIndex === 1)) {
                        return <i style={{ color: grey[500], fontSize: '36px', marginBottom: '12px' }} className='fas fa-medal' />;
                    }
                    if ((meIndex === 2) || (meCatIndex === 2)) {
                        return <i style={{ color: lime[900], fontSize: '36px', marginBottom: '12px' }} className='fas fa-medal' />;
                    }
                    return null;
                })();
            }
        }

        return (
            <Card style={{ margin: 'auto', marginTop: '12px', width: 'calc(100% - 12px)' }}>
                <CardContent>
                    {avatar}
                    {result}
                    {registration && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '12px',
                            }}
                        >
                            <div style={{ flex: 1 }}>
                                <div>
                                    <Typography variant='body2' style={{ fontWeight: 800, display: 'inline-block' }}>
                                        {t(`generic:division-${registration.division}`)}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant='caption' style={{ fontWeight: 100, display: 'inline-block' }}>
                                        {t('generic:shooter_division')}
                                    </Typography>
                                </div>
                            </div>
                            <Typography variant='body2' style={{ fontWeight: 100, display: 'inline-block' }}>
                                &nbsp;
                                |
                                &nbsp;
                            </Typography>
                            <div style={{ flex: 1 }}>
                                <div>
                                    <Typography variant='body2' style={{ fontWeight: 800, display: 'inline-block' }}>
                                        {t(`registration:category-${registration.category}`)}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant='caption' style={{ fontWeight: 100, display: 'inline-block' }}>
                                        {t('generic:shooter_category')}
                                    </Typography>
                                </div>
                            </div>
                            <Typography variant='body2' style={{ fontWeight: 100, display: 'inline-block' }}>
                                &nbsp;
                                |
                                &nbsp;
                            </Typography>
                            <div style={{ flex: 1 }}>
                                <div>
                                    <Typography variant='body2' style={{ fontWeight: 800, display: 'inline-block' }}>
                                        {t(`registration:${registration['power-factor']}`)}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant='caption' style={{ fontWeight: 100, display: 'inline-block' }}>
                                        {t('generic:shooter_power_factor')}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    )}
                </CardContent>
            </Card>
        );
    }

    renderMedia(media) {
        const {
            t, classes, mode,
        } = this.props;
        const {
            deleteRequest,
        } = this.state;

        const deleteNode = (
            <div
                style={{
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    width: '100%',
                    height: '100%',
                    zIndex: 1,
                    background: 'rgba(244, 67, 54, 0.9)',
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Typography
                    variant='caption'
                    style={{
                        color: 'white',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        borderBottom: '1px solid white',
                    }}
                >
                    Are you sure you want to delete?
                </Typography>
                <Typography
                    variant='body2'
                    style={{
                        color: 'white',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        borderBottom: '1px solid white',
                    }}
                    onClick={() => this.onDelete()}
                >
                    {t('generic:yes')}
                </Typography>
                <Typography
                    variant='body2'
                    style={{
                        color: 'white',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        borderBottom: '1px solid white',
                    }}
                    onClick={() => this.setState({ deleteRequest: null })}
                >
                    {t('generic:no')}
                </Typography>
            </div>
        );

        if (media.type === 'mov') {
            return (
                <div className={classes.mediabox} key={media.url}>
                    <video
                        crossOrigin='anonymous'
                        onLoadedData={(e) => this.onLoadedData(e, media)}
                        width={200}
                        height={200}
                        controls
                        style={mode === 'app' ? { pointerEvents: 'none' } : {}}
                    >
                        <source src={media.url} type='video/mp4' />
                        <track kind='captions' />
                    </video>
                    {mode === 'app' && deleteRequest !== media.url && (
                        <div
                            label='play-video'
                            id={encodeURIComponent(media.url)}
                            role='button'
                            tabIndex={0}
                            onKeyDown={() => {}}
                            onClick={() => this.onPlayVideo(media.url)}
                            style={{
                                position: 'absolute',
                                top: '0px',
                                left: '0px',
                                width: '100%',
                                height: '100%',
                                zIndex: 1,
                            }}
                        />
                    )}
                    {deleteRequest === media.url && deleteNode}
                </div>
            );
        }

        return (
            <div
                key={media.url}
                role='button'
                tabIndex={0}
                className={classes.mediabox}
                style={{
                    backgroundImage: `url(${media.url})`,
                }}
                onKeyDown={() => {}}
                onClick={() => this.onShowImage(media.url)}
            >
                {deleteRequest === media.url && deleteNode}
            </div>
        );
    }

    render() {
        const {
            t, classes, history, match: routeMatch,
        } = this.props;
        const {
            login, registration, isStaff, match, stages, dqs, shooters, uploading,
        } = this.state;
        const { matchId } = routeMatch.params;

        if ((!registration) && (!isStaff)) {
            return (
                <div className={classes.wrapper}>
                    <div className={classes.topbar}>
                        <div style={{ flex: 1 }}>
                            <Button variant='text' style={{ minWidth: '0px' }} color='primary' onClick={() => history.goBack()}>
                                <Typography variant='body2' style={{ color: grey[500] }}>
                                    {t('me')}
                                </Typography>
                            </Button>
                            <i className='fas fa-chevron-right' style={{ fontSize: '12px', opacity: 0.5 }} />
                            <Button variant='text' color='primary' disabled>
                                <Typography variant='body2' style={{ color: blue[500] }}>
                                    {t('match')}
                                </Typography>
                            </Button>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh',
                        }}
                    >
                        <CircularProgress thickness={1} size={72} />
                    </div>
                </div>
            );
        }

        const rounds = _.reduce(stages, (sum, s) => sum + (s.rounds || 0), 0);
        const dq = dqs.find((s) => s.shooterId === (registration || {}).id);

        return (
            <div className={classes.wrapper}>
                <div className={classes.topbar}>
                    <div style={{ flex: 1 }}>
                        <Button variant='text' color='primary' style={{ minWidth: '0px' }} onClick={() => history.goBack()}>
                            <Typography variant='body2' style={{ color: grey[500] }}>
                                {t('me')}
                            </Typography>
                        </Button>
                        <i className='fas fa-chevron-right' style={{ fontSize: '12px', opacity: 0.5 }} />
                        <Button variant='text' color='primary' disabled>
                            <Typography variant='body2' style={{ color: blue[500] }}>
                                {t('match')}
                            </Typography>
                        </Button>
                    </div>
                    <IconButton color='primary' onClick={this.refresh}><Refresh /></IconButton>
                </div>

                <Card classes={{ root: classes.header }} style={{ backgroundImage: `url(${match.cover})` }}>
                    <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ background: 'rgba(0, 0, 0, 0.3)', padding: '24px', paddingTop: '12px' }}>
                            <Typography variant='h5' style={{ paddingTop: '12px', color: grey[300] }}>
                                {match.title}
                            </Typography>
                            <Typography variant='subtitle2' style={{ paddingTop: '6px', color: grey[400] }}>
                                {`${match.stages.length} ${t('matchhome:shooters-stages-title')} | ${rounds} ${t('matchhome:rounds')} | ${shooters.length} ${t('matchhome:shooters')}`}
                            </Typography>
                        </div>
                    </CardContent>
                </Card>
                {!registration ? null : (
                    <div>
                        <Typography
                            variant='body2'
                            style={{
                                color: grey[500],
                                margin: '0px 12px',
                                marginTop: '12px',
                                textAlign: 'start',
                            }}
                        >
                            {t('yourresult')}
                        </Typography>
                        {dq ? this.renderDQ(dq) : this.renderResult()}
                    </div>
                )}
                {login.publicId === 'IL|30116' && (
                    <div>
                        <Typography
                            variant='body2'
                            style={{
                                color: grey[500],
                                margin: '0px 12px',
                                marginTop: '12px',
                                textAlign: 'start',
                            }}
                        >
                            {t('yourmedia')}
                        </Typography>
                        <div className={classes.media}>
                            {((login.images || {})[matchId] || []).map((media) => this.renderMedia(media))}
                            <div className={classes.newmediabox}>
                                {uploading && (
                                    <div
                                        style={{
                                            flex: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <CircularProgress size={48} thickness={1} />
                                        <div>
                                            <Typography variant='caption' style={{ color: blue[500], fontSize: '8px' }}>
                                                {t('takestime')}
                                            </Typography>
                                        </div>
                                    </div>
                                )}
                                {!uploading && (
                                    <Button variant='text' color='primary' onClick={this.onAddVideo}>
                                        <div>
                                            <i className='fas fa-video' />
                                            <Typography variant='caption' style={{ color: 'inherit' }}>
                                                {t('newvideo')}
                                            </Typography>
                                        </div>
                                    </Button>
                                )}
                                {!uploading && (
                                    <Button variant='text' color='primary' onClick={this.onAddImage}>
                                        <div>
                                            <i className='fas fa-image' />
                                            <Typography variant='caption' style={{ color: 'inherit' }}>
                                                {t('newimage')}
                                            </Typography>
                                        </div>
                                    </Button>
                                )}
                            </div>
                            <div style={{ width: '6px', minWidth: '12px' }} />
                        </div>
                    </div>
                )}
                {dq || !registration ? null : (
                    <div>
                        <Typography
                            variant='body2'
                            style={{
                                color: grey[500],
                                margin: '0px 12px',
                                marginTop: '12px',
                                textAlign: 'start',
                            }}
                        >
                            {t('yourscores')}
                        </Typography>
                        <Card style={{ margin: 'auto', marginTop: '12px', width: 'calc(100% - 12px)' }}>
                            {match.stages.map((stage, idx) => this.renderStageScore(idx))}
                        </Card>
                    </div>
                )}
                <Typography
                    variant='body2'
                    style={{
                        color: grey[500],
                        margin: '0px 12px',
                        marginTop: '12px',
                        textAlign: 'start',
                    }}
                >
                    {t('links')}
                </Typography>
                <Card style={{ margin: 'auto', marginTop: '12px', width: 'calc(100% - 12px)' }}>
                    <ButtonBase classes={{ root: classes.matchitem }} onClick={this.toMatchSite}>
                        <div style={{ flex: 1 }}>
                            <Typography variant='subtitle2'>
                                {t('tosite')}
                            </Typography>
                            <Typography variant='caption'>
                                {t('tositedescription')}
                            </Typography>
                        </div>
                        <div>
                            <i className='fas fa-chevron-right' style={{ fontSize: '18px' }} />
                        </div>
                    </ButtonBase>
                    {isStaff && (
                        <ButtonBase classes={{ root: classes.matchitem }} onClick={this.toMatchManagement}>
                            <div style={{ flex: 1 }}>
                                <Typography variant='subtitle2'>
                                    {t('tomanage')}
                                </Typography>
                                <Typography variant='caption'>
                                    {t('tomanagedescription')}
                                </Typography>
                            </div>
                            <div>
                                <i className='fas fa-chevron-right' style={{ fontSize: '18px' }} />
                            </div>
                        </ButtonBase>
                    )}
                    <ButtonBase classes={{ root: classes.matchitem }} onClick={this.toShooterStats}>
                        <div style={{ flex: 1 }}>
                            <Typography variant='subtitle2'>
                                {t('tostats')}
                            </Typography>
                            <Typography variant='caption'>
                                {t('tostatsdescription')}
                            </Typography>
                        </div>
                        <div>
                            <i className='fas fa-chevron-right' style={{ fontSize: '18px' }} />
                        </div>
                    </ButtonBase>
                </Card>
            </div>
        );
    }
}

const styles = {
    body: {
        background: grey[200],
    },
    topbar: {
        width: '100%',
        height: '45px',
        background: 'white',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'start',
    },
    wrapper: {
        background: grey[200],
        textAlign: 'center',
        paddingBottom: '70px',
    },
    header: {
        position: 'relative',
        width: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    matchitem: {
        padding: '12px',
        textAlign: 'start',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        borderTop: `1px solid ${grey[300]}`,
        '&:nth-child(1)': {
            borderTop: 'none',
        },
    },
    media: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: 'calc(100vw - 12px)',
        marginTop: '12px',
        paddingLeft: '6px',
        paddingRight: '6px',
        overflowX: 'auto',
    },
    mediabox: {
        display: 'flex',
        width: '200px',
        height: '200px',
        background: 'white',
        marginRight: '12px',
        position: 'relative',
        minWidth: '200px',
        minHeight: '200px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
    },
    newmediabox: {
        display: 'flex',
        flexDirection: 'column',
        width: '200px',
        height: '200px',
        background: 'white',
        minWidth: '200px',
        '&>button': {
            flex: 1,
            '&>span': {
                '&>div': {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    '&>i': {
                        fontSize: '30px',
                    },
                },
            },
        },
    },
};

HomeAppUserDashboard.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    accessToken: PropTypes.string,
    loginId: PropTypes.string,
    mode: PropTypes.string,
};

HomeAppUserDashboard.defaultProps = {
    accessToken: null,
    loginId: null,
    mode: null,
};

export default Wrapper(withStyles(styles, { withTheme: true, flip: false })(withTranslation('homeappuserdashboard')(HomeAppUserDashboard)));

/* eslint-enable react/no-array-index-key */
