const lang = {
    en: {
        welcome: 'Hey {{- name}}!',
        home: 'Home',
        squads: 'Squads',
        scores: 'Enter Scores',
        chrono: 'Chronograph',
        scoretable: 'See Live Scores',
        stages: 'Stages',
        registration: 'Registration',
        shooterslist: 'Shooters',
        staff: 'Staff',
        teams: 'Teams',
        logout: 'Disconnect',
        announcements: 'Announcements',
        settings: 'Settings',
        winmss: 'WinMSS',
        stats: 'Stats & Reports',
        haslocaldata: 'You have {{count}} items saved locally',
        haslocaldataclick: 'Click here to sync them',
        totalstoragedata: 'You\'re running low on local storage ({{percentage}}%)',
        totalstoragedataclick: 'Click here to free some storage',
        thisisdemosite: 'Hey there, this is a demo match just for you - feel free to play around.',
        thisisdemositeclick: 'When you\'re done, click here to create a new match.',
    },
    he: {
        welcome: 'היי {{- name}}!',
        home: 'ראשי',
        squads: 'סקוודים',
        scores: 'הכנסת ניקוד',
        chrono: 'כרונוגרף',
        scoretable: 'תוצאות',
        stages: 'תרגילים',
        registration: 'רישום',
        shooterslist: 'יורים',
        staff: 'בעלי תפקידים',
        teams: 'קבוצות',
        logout: 'התנתק',
        announcements: 'עדכונים והודעות',
        settings: 'הגדרות',
        winmss: 'WinMSS',
        stats: 'סטטיסטיקות',
        haslocaldata: 'ישנם {{count}} אירועים ששומרים על המכשיר',
        haslocaldataclick: 'לחצו כאן לסנכרן אותם עם השרת',
        totalstoragedata: 'הממ... עוד מעט נגמר המקום על המכישר ({{percentage}}%)',
        totalstoragedataclick: 'לחצו כאן לשחרר קצת מקום',
    },
};

lang.iw = {
    ...lang.he,
    ...{
        squads: 'מקצים',
        shooterslist: 'לוחמים',
    },
};

export default lang;
