import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

class SettingsPublishing extends Component {
    shouldComponentUpdate(nextProps) {
        const {
            scoresPublished, stagesPublished, squadsPublished, nameAnonymizationScheme,
        } = this.props;
        if (nextProps.scoresPublished !== scoresPublished) return true;
        if (nextProps.stagesPublished !== stagesPublished) return true;
        if (nextProps.squadsPublished !== squadsPublished) return true;
        if (nextProps.nameAnonymizationScheme !== nameAnonymizationScheme) return true;
        return false;
    }

    onTogglePublish = (field) => {
        const { onChange, [field]: value } = this.props;
        onChange({ [field]: !value });
    }

    onToggleScoresPublish = () => {
        const { onChange, scoresPublished: value } = this.props;
        onChange({ scoresPublished: !value ? 'full' : null });
    }

    onToggleScoresVerifyonly = () => {
        const { onChange, scoresPublished: value } = this.props;
        onChange({ scoresPublished: value === 'full' ? 'verify-only' : 'full' });
    }

    onAnonymizeChange = (e, value) => {
        const { onChange } = this.props;
        onChange({ nameAnonymizationScheme: value });
    }

    render() {
        const {
            classes, t, scoresPublished, stagesPublished, squadsPublished, nameAnonymizationScheme,
        } = this.props;

        return (
            <div>
                <Card>
                    <CardHeader
                        classes={{ title: classes.cardtitle }}
                        title={t('publishing')}
                        subheader={t('publishing-subtitle')}
                    />
                    <CardContent style={{ paddingTop: '0px' }}>
                        <form className={classes.form}>
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <FormControlLabel style={{ margin: '0px' }} control={<Switch color='primary' checked={stagesPublished} onChange={() => this.onTogglePublish('stagesPublished')} />} label={t('stages:published')} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel style={{ margin: '0px' }} control={<Switch color='primary' checked={squadsPublished} onChange={() => this.onTogglePublish('squadsPublished')} />} label={t('squads:published')} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel style={{ margin: '0px' }} control={<Switch color='primary' checked={!!scoresPublished} onChange={() => this.onToggleScoresPublish()} />} label={t('scoretable:published')} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel style={{ margin: '0px' }} control={<Checkbox color='primary' checked={scoresPublished === 'verify-only'} onChange={() => this.onToggleScoresVerifyonly()} disabled={!scoresPublished} />} label={t('scoretable:verifyonly')} />
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
                <Card style={{ marginTop: '12px' }}>
                    <CardHeader
                        classes={{ title: classes.cardtitle }}
                        title={t('shooternames')}
                        subheader={t('shooternamessubtitle')}
                    />
                    <CardContent style={{ paddingTop: '0px' }}>
                        <form className={classes.form}>
                            <RadioGroup
                                row
                                aria-label={t('anonymize')}
                                value={nameAnonymizationScheme || 'lastname'}
                                onChange={this.onAnonymizeChange}
                            >
                                <FormControlLabel value='none' control={<Radio />} label={t('anonymize-none')} />
                                <FormControlLabel value='firstname' control={<Radio />} label={t('anonymize-firstname')} />
                                <FormControlLabel value='lastname' control={<Radio />} label={t('anonymize-lastname')} />
                            </RadioGroup>
                        </form>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

const styles = () => ({
    cardtitle: {
        fontWeight: 300,
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > div': {
            marginTop: '16px',
        },
        '& > div:first-child': {
            marginTop: '0px',
        },
    },
});

export default withStyles(styles, { withTheme: true })(withTranslation('settings')(SettingsPublishing));

SettingsPublishing.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    scoresPublished: PropTypes.string,
    stagesPublished: PropTypes.bool,
    squadsPublished: PropTypes.bool,
    nameAnonymizationScheme: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

SettingsPublishing.defaultProps = {
    scoresPublished: null,
    stagesPublished: false,
    squadsPublished: false,
    nameAnonymizationScheme: 'lastname',
};
