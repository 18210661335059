import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import HtmlEditor from '../components/HtmlEditor';

class ManageHomeAbout extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    shouldComponentUpdate() {
        return true;
    }

    onChange = (about) => {
        const { onChange } = this.props;
        onChange({ about });
    }

    render() {
        const {
            t, classes, locale, about,
        } = this.props;

        return (
            <Card>
                <CardHeader
                    classes={{ title: classes.cardtitle }}
                    title={t('about-match')}
                    subheader={t('about-match-subtitle')}
                />
                <CardContent style={{ position: 'relative' }}>
                    <HtmlEditor locale={locale} value={about} onChange={this.onChange} />
                </CardContent>
            </Card>
        );
    }
}

const styles = () => ({
    cardtitle: {
        fontWeight: 300,
    },
    editor: {
        border: '1px solid rgba(0,0,0,0.1)',
        padding: '0px 12px',
    },
});

ManageHomeAbout.propTypes = {
    about: PropTypes.string,
    locale: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

ManageHomeAbout.defaultProps = {
    about: null,
    locale: null,
};

export default withStyles(styles, { withTheme: true })(withTranslation('managehome')(ManageHomeAbout));
