const lang = {
    en: {
        title: 'Chronograph',
        newdailytitle: 'Daily Chronograph Report - Station #{{station}}',
        newrequesttitle: 'Schedule Chronograph',
        daily: 'Chronograph Daily Report - Station #{{station}}',
        stationnum: 'Station #{{num}}',
        testgun: 'Test Gun',
        testgunadd: 'Add test gun information',
        testmakemodel: 'Test Gun Make/Model',
        testserial: 'Test Gun Serial',
        testweight: 'Bullet Weight',
        testwarnings: 'Note, once you save the test equitment data, and enter at least one measurement, you won\'t be able to change it.',
        testsave: 'Save Test Gun',
        dailyresults: 'Results',
        dailydate: 'Date',
        dailyshotvelocity: 'Shot Velocity (FPS)',
        dailyavgvelocity: 'Average Velocity',
        dailyavgpower: 'Average Power Factor',
        dailychange: 'Change (%)',
        dailyaddfirst: 'Add your first daily measurement',
        dailyadd: 'Add a daily measurement',
        dailynotestgun: 'To add daily measurements, first enter test gun information above.',
        initials: 'Initials',
        addstation: 'Add Chrono Station',
        newrequestdescription1: 'Please draw 8 sample rounds from the competitor.',
        newrequestdescription2: 'Place the rounds in a sealed and labeled it according to the shooter id and name (written above).',
        newrequestdescription3: 'After clicking "Schedule", the competitor can go to the chosen chronograph station to be tested. Results will posted to the competitor registartion page, and sent by email.',
        newrequestschedule: 'Schedule',
        newrequestselectstation: 'Chronograph station',
        newrequestdonetitle: 'Chronograph Request Added',
        newrequestdone1: 'Your request for a chronograph test for {{shooter}} has been successfully added.',
        newrequestdone2: 'Please ask the competitor to go to chronograph station #{{station}} a bit later on today.',
        requests: 'Chronograph Requests',
        requestdate: 'Date',
        requestshooter: 'Shooter',
        requestro: 'RO',
        formtitle: 'Chronograph Test Form',
        formfirsttest: 'First Test (4 Bullets)',
        formsecondtest: 'Second Test (3 More Bullets)',
        formlastchancetitle: 'Third Test (1 More Bullet)',
        formbulletweight1: 'Weight of Bullet 1 (grains)',
        formbulletweight2: 'Weight of Bullet 8 (grains)',
        formbulletvolcity1: 'Velocity of Bullet 2 (fps)',
        formbulletvolcity2: 'Velocity of Bullet 3 (fps)',
        formbulletvolcity3: 'Velocity of Bullet 4 (fps)',
        formbulletvolcity4: 'Velocity of Bullet 5 (fps)',
        formbulletvolcity5: 'Velocity of Bullet 6 (fps)',
        formbulletvolcity6: 'Velocity of Bullet 7 (fps)',
        formbulletvolcity7: 'Velocity of Bullet 8 (fps)',
        formresultmissing: 'Power Factor: N/A (fill in all data)',
        formresult: 'Power Factor: {{calculated}}',
        formanotherthree: 'Test three more bullets',
        formlastchance: 'Test one more bullet',
        formresultlastchance: 'Power Factor: N/A (Enter either velocity or weight, but not both!)',
        formnotok: 'Result: Failed to meet requirements, needs further testing.',
        formtominor: 'Competitor failed to meet requirements for Major, and will be scored as Minor.',
        formstopscoring: 'Competitor failed to meet requirements for Minor, and will not be scored.',
        formallok: 'Result: Passed',
        toform: 'Test Summary Form',
    },
    he: {
        title: 'כרונוגרף',
        newdailytitle: 'דו״ח כרונוגרף יומי - עמדה #{{station}}',
        newrequesttitle: 'תיאום בדיקת כרונוגרף',
        daily: 'דו״ח כרונוגרף יומי - עמדה #{{station}}',
        stationnum: 'עמדה #{{num}}',
        testgun: 'אקדח בדיקה',
        testgunadd: 'הוספת נתוני אקדח בדיקה',
        testmakemodel: 'יצרן וסוג',
        testserial: 'מספר סידורי',
        testweight: 'משקל כדור',
        testwarnings: 'שימו לב, לא ניתן לשנות את הפרטים הללו לאחר הכנסת נתוני בדיקה ראשונים.',
        testsave: 'שמור נתוני אקדח',
        dailyresults: 'תוצאות',
        dailydate: 'תאריך',
        dailyshotvelocity: 'מהירות לוע (FPS)',
        dailyavgvelocity: 'מהירות ממוצעת',
        dailyavgpower: 'עוצמה ממוצעת',
        dailychange: 'שינוי (%)',
        dailyaddfirst: 'הוספת דו״ח יומי ראשון',
        dailyadd: 'הוספת דו״ח יומי',
        dailynotestgun: 'להוספת דו״חות יומיים, יש להכניס פרטי אקדח בדיקה.',
        initials: 'ראשי תיבות',
        addstation: 'הוסף עמדה',
        newrequestdescription1: 'אנא הוציאו שמונה כדורים מאחת המחסניות של היורה.',
        newrequestdescription2: 'יש לשם את הכדורים בשקית אטומה, ולרשום את שם היורה בבירור על השקית.',
        newrequestdescription3: 'לאחר הלחיצה על ״אישור״, היורה יוכל לדווח לעמדת הבדיקה שנבחרה לצורך ביצוע הבדיקה. תוצאות הבדיקה יפורסמו בעמוד הרישום של היורה, ועותק ישלח גם באימייל אל היורה.',
        newrequestschedule: 'אישור',
        newrequestselectstation: 'עמדת בדיקה',
        newrequestdonetitle: 'בקשתכם לכרונוגרף התקבלה',
        newrequestdone1: 'בקשתכם לקבוע בדיקת כרונוגרף ל{{shooter}} התקבלה בהצלחה.',
        newrequestdone2: 'אנא ביקשו מהיורה לגשת לעמדת בדיקה #{{station}} בהמשך היום.',
        toform: 'לטופס סיכום בדיקה',
        requests: 'בקשות לכרונוגרף',
        requestdate: 'תאריך',
        requestshooter: 'יורה',
        requestro: 'שופט',
        formtitle: 'דו״ח תוצאות בדיקת כרונוגרף',
        formfirsttest: 'שלב ראשון (4 כדורים)',
        formsecondtest: 'שלב שני (3 כדורים נוספים)',
        formlastchancetitle: 'שלב שלישי (כדור נוסף)',
        formbulletweight1: 'משקל כדור 1 (גריינים)',
        formbulletweight2: 'משקל כדור 8 (גריינים)',
        formbulletvolcity1: 'מהירות לוע כדור 2 (fps)',
        formbulletvolcity2: 'מהירות לוע כדור 3 (fps)',
        formbulletvolcity3: 'מהירות לוע כדור 4 (fps)',
        formbulletvolcity4: 'מהירות לוע כדור 5 (fps)',
        formbulletvolcity5: 'מהירות לוע כדור 6 (fps)',
        formbulletvolcity6: 'מהירות לוע כדור 7 (fps)',
        formbulletvolcity7: 'מהירות לוע כדור 8 (fps)',
        formresultmissing: 'עוצמה: לא ינתן לחישוב, נא למלא את כל השדות',
        formresult: 'עוצמה: {{calculated}}',
        formanotherthree: 'המשך לבדיקת שלושה כדורים נוספים',
        formlastchance: 'המשך לבדיקת כדור נוסף',
        formresultlastchance: 'עוצמה: לא ניתן לחישוב, אנא מלאו עוצמה או מהירות לוע של כדור אחרון (לא שניהם)',
        formnotok: 'תוצאה: התוצאה לא עמדה בדרישות. יש להמשיך בבדיקה.',
        formtominor: 'ציוד היורה נכשל בעמידה בדרישות למייג׳ור. תוצאות היורה יחושבו כמיינור.',
        formstopscoring: 'ציוד היורה נכשל בעמידה במינימום הדרישות לתחרות. תוצאות היורה יבוטלו.',
        formallok: 'תוצאה: ציוד היורה עבר בהצלחה את הדרישות.',
    },
};

lang.iw = {
    ...lang.he,
    ...{
        newrequestdescription1: 'אנא הוציאו שמונה כדורים מאחת המחסניות של הלוחם\\ת.',
        newrequestdescription2: 'יש לשם את הכדורים בשקית אטומה, ולרשום את שם הלוחם\\ת בבירור על השקית.',
        newrequestdescription3: 'לאחר הלחיצה על ״אישור״, הלוחם\\ת יוכל לדווח לעמדת הבדיקה שנבחרה לצורך ביצוע הבדיקה. תוצאות הבדיקה יפורסמו בעמוד הרישום של הלוחם\\ת, ועותק ישלח גם באימייל אל הלוחם\\ת.',
        newrequestdone2: 'אנא ביקשו מהלוחם\\ת לגשת לעמדת בדיקה #{{station}} בהמשך היום.',
        requestshooter: 'לוחם\\ת',
        formtominor: 'ציוד הלוחם\\ת נכשל בעמידה בדרישות למייג׳ור. תוצאות הלוחם\\ת יחושבו כמיינור.',
        formstopscoring: 'ציוד הלוחם\\ת נכשל בעמידה במינימום הדרישות לתחרות. תוצאות הלוחם\\ת יבוטלו.',
        formallok: 'תוצאה: ציוד הלוחם\\ת עבר בהצלחה את הדרישות.',
    },
};

export default lang;
