import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import IconSettings from '@material-ui/icons/Settings';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { getFlagSrc } from '../matches/Utils';
import api from '../Api';

class RegionHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            region: null,
            page: 0,
        };
    }

    async componentDidMount() {
        const { regionId, i18n } = this.props;
        const region = await api.getRegion({ regionId });
        const matchesMap = await api.getMatches();
        const matches = _(matchesMap)
            .values()
            .sortBy((match) => -1 * (match.startDate.day + match.startDate.month * 31 + match.startDate.year * 365))
            .value();
        const stageIds = _.compact(_.flatten(matches.map((m) => m.stages)));
        const allStages = await api.getStages({ stages: stageIds });
        matches.forEach((m, index) => {
            matches[index].stages = (matches[index].stages || []).map((s) => allStages.find((s2) => s2.id.toString() === s.toString()));
        });

        this.setState({ region, matches });

        if (typeof (document) !== 'undefined') {
            const dir = 'ltr'; // region.locale === 'he-IL' ? 'rtl' : 'ltr';

            document.body.style.direction = dir;
            document.body.setAttribute('dir', dir);
            document.body.classList.remove('rtl');
            document.body.classList.remove('ltr');
            document.body.classList.add(dir);
        }
        i18n.changeLanguage('en-US'); // region.locale);
        moment.locale('en-us'); // region.locale);
    }

    openMatch = (alias) => {
        const { history } = this.props;
        history.push(`/${alias}`);
    }

    renderMatch(match) {
        const { i18n, classes } = this.props;
        const { stages } = match;

        const t = i18n.getFixedT(match.locale, 'regionshome');
        const isRtl = i18n.dir(match.locale);

        const rounds = _.reduce(stages, (sum, s) => sum + (s.rounds || 0), 0);
        const time = new Date(match.startDate.year, match.startDate.month - 1, match.startDate.day, 12, 0, 0, 0);

        return (
            <Card key={match.id} classes={{ root: classnames({ [classes.feeditem]: true, [classes.feeditemrtl]: isRtl }) }}>
                <CardActionArea onClick={() => this.openMatch(match.alias || match.id)}>
                    <CardMedia
                        component='img'
                        className={classes.media}
                        height='140'
                        image={match.cover}
                        title='Match Cover'
                    />
                    <CardHeader
                        title={<Typography variant='h5'>{match.title}</Typography>}
                        subheader={`${moment(time).locale(match.locale).format('LL')} | ${match.stages.length} ${t('matchhome:shooters-stages-title')} | ${rounds} ${t('matchhome:rounds')}`}
                    />
                    <CardContent />
                </CardActionArea>
                <div className={match.ipscLevel === 0 ? classes.labelunapproved : classes.label}>
                    {t(`homeapp:ipsclevel-${match.ipscLevel}`)}
                    {match.ipscLevel > 0 && match.locale === 'he-IL' ? (
                        <Typography style={{ color: 'white' }} variant='caption'>
                            {t(`homeapp:ipsclevel-${match.ipscLevel}-desc`)}
                        </Typography>
                    ) : null}
                </div>
            </Card>
        );
    }

    renderContent() {
        const { t, classes } = this.props;
        const { region, matches, page } = this.state;
        const regionMatches = matches.filter((m) => m.countryCode === region.countryCode);

        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 12, 0, 0, 0);
        const pastMatches = regionMatches.filter((match) => {
            const time = new Date(match.startDate.year, match.startDate.month - 1, match.startDate.day, 12, 0, 0, 0);
            return (time.getTime() - today.getTime() < (-7 * 24 * 60 * 60 * 1000));
        });
        const presentMatches = regionMatches.filter((match) => {
            const time = new Date(match.startDate.year, match.startDate.month - 1, match.startDate.day, 12, 0, 0, 0);
            return ((time.getTime() - today.getTime() >= (-7 * 24 * 60 * 60 * 1000))
                && (time.getTime() - today.getTime() < (7 * 24 * 60 * 60 * 1000)));
        });
        const futureMatches = _(regionMatches)
            .filter((match) => {
                const time = new Date(match.startDate.year, match.startDate.month - 1, match.startDate.day, 12, 0, 0, 0);
                return (time.getTime() - today.getTime() >= (7 * 24 * 60 * 60 * 1000));
            })
            .sortBy((match) => 1 * (match.startDate.day + match.startDate.month * 31 + match.startDate.year * 365))
            .value();

        const displayedMatches = [pastMatches, presentMatches, futureMatches][page];

        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Tabs
                    variant='fullWidth'
                    value={page}
                    classes={{ indicator: classes.indicator }}
                    onChange={(e, v) => this.setState({ page: v }, () => window.scrollTo(0, 0))}
                >
                    <Tab label={<div style={{ width: '100px' }}>{t('past')}</div>} />
                    <Tab label={<div style={{ width: '100px' }}>{t('present')}</div>} />
                    <Tab label={<div style={{ width: '100px' }}>{t('future')}</div>} />
                </Tabs>
                <div className={classes.feed}>
                    {_.map(displayedMatches, (m) => (
                        <div key={m.id}>
                            {this.renderMatch(m)}
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    renderInner() {
        const { region } = this.state;
        const { classes } = this.props;
        const MainLink = (props) => <Link to={`/${region.id}`} className={classes.logo} {...props} />;
        const ManageLink = (props) => <Link to={`/${region.id}/manage`} {...props} />;
        return (
            <div>
                <AppBar>
                    <Toolbar>
                        <MainLink>
                            <div className={classes.logo}>
                                <div
                                    style={{
                                        background: `url(${getFlagSrc({ countryCode: region.countryCode, size: 64 })})`, backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', width: '64px', height: '64px',
                                    }}
                                />

                                <Typography variant='h6' color='inherit' noWrap style={{ margin: '0px 12px' }}>
                                    {region.title}
                                </Typography>
                            </div>
                        </MainLink>
                    </Toolbar>
                </AppBar>

                <div style={{ marginTop: '124px' }}>
                    {this.renderContent()}
                </div>

                <div style={{ marginBottom: '24px' }} />
                <div
                    style={{
                        display: 'flex', alignItems: 'center', padding: '0px 24px', borderTop: '1px solid rgba(0,0,0,0.2)',
                    }}
                >
                    <div style={{ flex: 1 }}>
                        <Typography variant='caption'>
                            Powered by
                            {' '}
                            <a href='/' style={{ color: 'inherit' }}>endofscoring.com</a>
                        </Typography>
                    </div>
                    <IconButton color='inherit' component={ManageLink}>
                        <IconSettings fontSize='small' style={{ color: 'rgba(0,0,0,0.54)' }} />
                    </IconButton>
                </div>
            </div>
        );
    }

    render() {
        const { classes } = this.props;
        const { region } = this.state;

        if (!region) {
            return (
                <div className={classes.loader}>
                    <CircularProgress size={75} />
                </div>
            );
        }

        return this.renderInner();
    }
}

RegionHome.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        changeLanguage: PropTypes.func.isRequired,
        getFixedT: PropTypes.func.isRequired,
        dir: PropTypes.func.isRequired,
    }).isRequired,
    classes: PropTypes.shape({}).isRequired,
    regionId: PropTypes.string.isRequired,
};

const styles = () => ({
    logo: {
        height: '100px',
        textDecoration: 'none',
        color: 'inherit',
        display: 'flex',
        alignItems: 'center',
        '& > h6': {
            textDecoration: 'none',
            color: 'inherit',
        },
    },
    loader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100vh',
    },
    feed: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: 'calc(100% - 24px)',
        margin: '0px 12px',
    },
    feeditem: {
        margin: '12px auto',
        maxWidth: '500px',
        position: 'relative',
    },
    feeditemrtl: {
        direction: 'rtl',
    },
    label: {
        position: 'absolute',
        right: '0px',
        top: '0px',
        fontSize: '15px',
        backgroundColor: green[900],
        color: 'white',
        zIndex: 2,
        padding: '6px 0px',
        width: '200px',
        textAlign: 'center',
    },
    labelunapproved: {
        position: 'absolute',
        right: '0px',
        top: '0px',
        fontSize: '15px',
        backgroundColor: red[900],
        color: 'white',
        zIndex: 2,
        padding: '6px 0px',
        width: '200px',
        textAlign: 'center',
    },
});

export default withStyles(styles, { withTheme: true })(withTranslation('regionshome')(RegionHome));
