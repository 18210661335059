import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import queryString from 'qs';

export default class App extends React.Component {
    componentDidMount() {
        document.getElementById('root').style = 'height: 100%';

        const params = queryString.parse(window.location.search.replace(/^\?/, ''));
        window.parent.postMessage({ type: 'thinking-loaded', params }, '*');
    }

    render() {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <CircularProgress size={96} thickness={4} />
            </div>
        );
    }
}
