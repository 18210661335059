/* eslint-disable max-classes-per-file */
import './ManageWrapper.css';
import React, { Component } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/Inbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Premium from '../components/Premium';
import Authentication from '../components/Authentication';
import GenericI18nThemeWrapper from '../components/GenericI18nThemeWrapper';
import api from '../Api';

class ManageWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            club: null,
        };
    }

    async UNSAFE_componentWillMount() {
        const { clubId } = this.props;
        const club = await api.getClub({ clubId });
        const region = await api.getRegion({ regionId: club.region });

        this.setState({ club, region });
    }

    render() {
        const { club } = this.state;

        return (
            <GenericI18nThemeWrapper match={club}>
                <ManageWrapperInner {...this.props} {...this.state} />
            </GenericI18nThemeWrapper>
        );
    }
}

ManageWrapper.propTypes = {
    clubId: PropTypes.string.isRequired,
};

export default ManageWrapper;

class ManageWrapper_ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auth: null,
            me: null,
            title: null,
            mobileOpen: false,
            saving: false,
            saveSuccess: false,
        };
    }

    async componentDidUpdate(prevProps, prevState) {
        const { clubId } = this.props;
        const { auth } = this.state;
        if (prevState.auth !== auth) {
            await new Promise((res) => this.setState({ club: null }, res));
            const club = await api.getClub({ clubId, auth });
            await new Promise((res) => this.setState({ club }, res));
        }
    }

    handleDrawerToggle = () => {
        this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
    };

    setSavingState = async ({ saving, saveSuccess, saveError }) => {
        await new Promise((res) => this.setState({ saving, saveSuccess, saveError }, res));
    }

    onDisconnect = async () => {
        await new Promise((res) => window.gapi.load('auth2', res));
        const auth = await window.gapi.auth2.init();
        const user = auth.currentUser.get();
        auth.signOut();
        user.disconnect();

        sessionStorage.removeItem('auth');
        sessionStorage.removeItem('me');
        setTimeout(() => { window.location.href = `${window.location.href}`; }, 1000);
    }

    renderSidebar() {
        const { me, club } = this.state;
        const {
            t, clubId, pageId, classes, url,
        } = this.props;
        const baseUrl = `${url.split('/manage')[0]}/manage`;

        const ITEMS = [{
            id: 'home',
            permission: ['manage'],
            link: `${baseUrl}`,
            label: t('home'),
            icon: <InboxIcon />,
        }];

        const items = ITEMS.filter((i) => !_.isEmpty(_.intersection(i.permission, me.permissions)));

        return (
            <div className='manage-wrapper-sidebar'>
                <div style={{ padding: '24px 0px' }}>
                    <Typography variant='h6' style={{ color: 'white', padding: '0px 12px' }}>
                        <div style={{ flex: 1 }}>{club.title}</div>
                    </Typography>
                    <Typography variant='caption' style={{ padding: '0px 12px' }}>
                        <a href={`https://www.endofscoring.com/clubs/${club.alias}`} target='_blank' rel='noopener noreferrer'>
                            https://www.endofscoring.com/clubs/
                            {club.alias}
                        </a>
                    </Typography>
                    <div style={{ display: 'flex', alignItems: 'center', paddingTop: '24px' }}>
                        <Typography variant='body2' style={{ color: 'white', padding: '0px 12px' }}>
                            {t('welcome', { name: me.name })}
                        </Typography>
                        <Button
                            variant='text'
                            style={{
                                fontWeight: 300, padding: '0px', margin: '0px', minWidth: '0px',
                            }}
                            onClick={this.onDisconnect}
                        >
                            <Typography variant='caption' style={{ padding: '0px', textTransform: 'none', textDecoration: 'underline' }}>
                                {t('logout')}
                            </Typography>
                        </Button>
                    </div>
                </div>
                <List component='nav' className={classes.sidebarlist}>
                    {items.map((item) => (
                        <ListItem
                            button
                            component={(props) => <Link to={item.link} {...props} />}
                            key={item.id}
                            className={classnames({ [classes.sidebaritem]: true, [classes.sidebaractive]: item.id === pageId })}
                        >
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.label} />
                        </ListItem>
                    ))}
                </List>
                <Premium
                    clubId={clubId}
                    package={club.package}
                    style={{
                        position: 'relative', left: '12px', width: 'calc(100% - 48px)', height: '37px',
                    }}
                />
            </div>
        );
    }

    render() {
        const {
            t, theme, component, clubId, componentProps, classes, url, region,
        } = this.props;
        const {
            auth, club, me, title, saving, saveSuccess, saveError, saveButtonLabel, extraButtons, mobileOpen,
        } = this.state;

        const Comp = component;

        if (!auth) {
            return (
                <Authentication onAuth={($auth, $me) => this.setState({ auth: $auth, me: $me })} clubId={clubId} />
            );
        }

        if (!club) {
            return (
                <div style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '100vh',
                }}
                >
                    <CircularProgress size={75} />
                </div>
            );
        }

        return (
            <div>
                <Grid container spacing={8} alignItems='stretch' style={{ minHeight: '100vh' }}>
                    <Hidden smDown>
                        <Grid item md={3} className='noprint'>
                            {this.renderSidebar()}
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} md={9} style={{ position: 'relative', paddingTop: '70px' }}>
                        <AppBar className={classes.appBar}>
                            <Toolbar className={classes.container}>
                                <Hidden mdUp>
                                    <IconButton
                                        color='inherit'
                                        aria-label='Open drawer'
                                        onClick={this.handleDrawerToggle}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Hidden>
                                <div className={classes.title}>
                                    {title}
                                </div>
                                {this.saveButtonCallback && (
                                <Button disabled={saving} classes={{ root: classnames([classes.save, saveSuccess ? classes.savesuccess : null, saveError ? classes.saveerror : null]) }} variant='contained' color='primary' onClick={() => this.saveButtonCallback({ setState: this.setSavingState })}>
                                    {saving && <CircularProgress size={18} />}
                                    {saveSuccess && <DoneIcon />}
                                    {saveError && <CloseIcon />}
                                    {!saveSuccess && !saveError && !saving && (saveButtonLabel || t('generic:save'))}
                                </Button>
                                )}
                                {extraButtons || null}
                            </Toolbar>
                        </AppBar>
                        <Comp auth={auth} me={me} club={club} region={region} clubId={clubId} url={url} manage setTitle={($title) => this.setState({ title: $title })} setSaveButtonLabel={(label) => this.setState({ saveButtonLabel: label })} setSaveButtonCallback={(callback) => { this.saveButtonCallback = callback; }} setExtraButtons={($extraButtons) => this.setState({ extraButtons: $extraButtons })} {...componentProps} />
                    </Grid>

                    <Hidden mdUp implementation='css'>
                        <Drawer
                            variant='temporary'
                            anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                            open={mobileOpen}
                            onClose={this.handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {this.renderSidebar()}
                        </Drawer>
                    </Hidden>
                </Grid>
            </div>
        );
    }
}

const styles = (theme) => ({
    sidebarlist: {
        margin: '0px 12px',
    },
    sidebaritem: {
        borderRadius: '3px',
    },
    sidebaractive: {
        backgroundColor: theme.palette.primary.light,
        boxShadow: '0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2)',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            boxShadow: '0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2)',
        },
    },
    appBar: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderBottom: '0',
        marginBottom: '0',
        position: 'absolute',
        width: '100%',
        paddingTop: '10px',
        zIndex: '1029',
        color: '#555555',
        border: '0',
        borderRadius: '3px',
        padding: '10px 0',
        transition: 'all 150ms ease 0s',
        minHeight: '50px',
        display: 'block',
    },
    container: {
        minHeight: '50px',
    },
    flex: {
        flex: 1,
    },
    title: {
        flex: 1,
        fontWeight: '300',
        lineHeight: '30px',
        fontSize: '18px',
        borderRadius: '3px',
        textTransform: 'none',
        color: 'inherit',
        margin: '0',
        '&:hover,&:focus': {
            background: 'transparent',
        },
    },
    save: {
        minWidth: '100px',
        transition: 'background 0.5s',
    },
    savesuccess: {
        pointerEvents: 'none',
        background: green[500],
    },
    saveerror: {
        pointerEvents: 'none',
        background: red[500],
    },
});

const ManageWrapperInner = withStyles(styles, { withTheme: true })(withTranslation('managewrapper')(ManageWrapper_));

ManageWrapper_.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    theme: PropTypes.shape({
        direction: PropTypes.oneOf(['rtl', 'ltr']).isRequired,
    }).isRequired,
    clubId: PropTypes.string.isRequired,
    club: PropTypes.shape({}).isRequired,
    region: PropTypes.shape({}).isRequired,
    url: PropTypes.string.isRequired,
    pageId: PropTypes.string.isRequired,
    clubs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
    })).isRequired,
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    componentProps: PropTypes.shape({}).isRequired,
};
/* eslint-enable max-classes-per-file */
