const lang = {
    en: {
        title: 'Reports & Stats',
        stagestats: 'Stage Stats',
        stagestatslastscoretime: 'Time of last scoring:',
        stagestatsabsent: 'Absent Shooters:',
        stagestatsabsentwho: 'who?',
        stagestatsaveragescoretime: 'Average stage time per shooter (sec):',
        stagestatsaveragegap: 'Average time between shooters (in minutes, exc top and bottom 25%):',
        graph: 'Histogram',
        progress: 'Match Progress',
        progressdetails: 'The table below shows the progress of squads between stages. A yellow box means that the squad has been checked for attendance. A green box means all attending members have finished shooting the stage.',
        progressdetailslastupdate: '(Last updated: {{lastUpdate}})',
        progresstooltip: 'Squad {{squad}} | Stage {{stage}}',
        progresstooltiplist: 'Shooters pending:',
        progresstooltipabsent: 'Shooters absent:',
        financialstats: 'Financial Stats',
        numpaid: 'Number of paying shooters:',
        numpaiddidntshoot: 'Number of absent paying shooters:',
    },
    he: {
        title: 'דו״חות וסטטיסטיקות',
        stagestats: 'סטיסטיקות לתרגילים',
        stagestatslastscoretime: 'זמן של תוצאה אחרונה:',
        stagestatsabsent: 'יורים שלא נוכחו בתרגיל:',
        stagestatsabsentwho: 'מי?',
        stagestatsaveragescoretime: 'ממוצע זמן תרגיל ליורה (שניות):',
        stagestatsaveragegap: 'ממוצע זמן בין יורים (דקות, ללא 25% המהירים והאיטיים ביותר):',
        graph: 'גרף',
        progress: 'התקדמות בתחרות',
        progressdetails: 'הטבלה למטה מראה את התקדמות הסקוודים בין תרגילים. קופסא צהובה מסמנת שהסקווד עבר בדיקת ציוד ונוכחות. קופסא ירוקה מסמנת שכל היורים הנוכחים סיימו את ירי התרגיל.',
        progressdetailslastupdate: '(עדכון אחרון: {{lastUpdate}})',
        progresstooltip: 'סקווד {{squad}} | תרגיל {{stage}}',
        progresstooltiplist: 'יורים שטרם ירו:',
        progresstooltipabsent: 'יורים חסרים:',
        financialstats: 'דוח פיננסי',
        numpaid: 'מספר יורים משלמים:',
        numpaiddidntshoot: 'מספר יורים משלמים שלא ירו אף תרגיל:',
    },
};

lang.iw = {
    ...lang.he,
    ...{
        progresstooltip: 'מקצה {{squad}} | תרגיל {{stage}}',
        progressdetails: 'הטבלה למטה מראה את התקדמות המקצים בין תרגילים. קופסא צהובה מסמנת שהמקצה עבר בדיקת ציוד ונוכחות. קופסא ירוקה מסמנת שכל הלוחמים הנוכחים סיימו את ירי התרגיל.',
        stagestatsabsent: 'לוחמים שלא נוכחו בתרגיל:',
        stagestatsaveragescoretime: 'ממוצע זמן תרגיל ללוחם\\ת (שניות):',
        stagestatsaveragegap: 'ממוצע זמן בין לוחמים (דקות, ללא 25% המהירים והאיטיים ביותר):',
        progresstooltiplist: 'לוחמים שטרם ירו:',
        progresstooltipabsent: 'לוחמים חסרים:',
        numpaid: 'מספר לוחמים משלמים:',
        numpaiddidntshoot: 'מספר לוחמים משלמים שלא ירו אף תרגיל:',
    },
};

export default lang;
