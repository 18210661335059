const lang = {
    en: {
        title: 'Registration',
        rotitle: 'Range Officers Registration',
        termsofuse: 'By logging in I agree to the ###Terms of Service### and ###Privacy Policy###.',
        'loggedin-title': 'Hi {{name}}!',
        'loggedin-new': 'New Shooter Registration',
        'loggedin-new-success': 'Shooter {{name}} has been registered to the match successfully.',
        'nonloggedin-new-success-part1': 'Great, you are registered!',
        'nonloggedin-new-success-part2': 'You should receive a confirmation email shortly.',
        'loggedin-existing': 'Update Existing Shooter Registration',
        'loggedin-existing-success': 'Shooter\'s {{name}} registeration to the match has been updated successfully.',
        'loggedin-new-useprevious': 'We have found {{num}} previous registrations. Would you want to use any of them? (you\'ll be able to edit the information)',
        'loggedin-new-cancelling': 'Sending your cancellation request to the match director',
        newregistrationbutton: 'New Registration',
        editregistrationbutton: 'Edit Existing Registration',
        'signup-title': 'Log In',
        'signup-intro': 'Sign in to register and manage your registration.',
        'signup-intro-registration-code': 'This match requires a special code to register and manage registrations.',
        'registration-code': 'Registration code',
        'registernologin-part1': 'Don\'t want to sign in?',
        'registernologin-part2': 'Click here to register without signing in',
        'signup-google': 'Sign in using Gmail Account',
        'signup-social': 'We use google/facebook just so we know who you are when you come back. We will never post in your name, share, or use your connections.',
        'status-pending-offline-payment': 'Your registration is pending payment',
        'status-pending-online-payment': 'Your registration is pending payment',
        'status-allgood': 'You\'re all set. See you at the match!',
        'status-pendingsquad': 'Please pick a squad to finish your registration.',
        'status-pendingsquadclosed': 'You\'re all set. We\'ll update you when you\'ll be assigned a squad.',
        'status-pendingcancellation': 'You\'ve requested to cancel your registration (waiting for approval).',
        pay: 'Pay for Match',
        addnew: 'I\'ve changed my mind, Start from Scratch',
        usethis: 'Start from this',
        expand: '{{name}} has {{count}} other registration(s). Click to show.',
        cannotfindpast: 'We couldn\'t find any registration on your name. Registration for new shooters is now closed.',
        thirdparty: 'Someone else signed you up?',
        thirdpartydescription: 'You should have received a pin code with our registration confirmation email.',
        signinusingpin: 'Sign in with Pin',
        signin: 'Sign in',
        pincode: 'Pin code',
        incorrectpin: 'We\'re sorry, but we couldn\'t find a registration with that shooter id and pin.',
        scoreforms: 'Scores:',
        skipfilter: 'Show older registrations',
        dontskipfilter: 'Show only newer registrations',
    },
    he: {
        title: 'רישום יורים',
        rotitle: 'רישום שופטים ושופטות',
        termsofuse: 'בהתחברות אני נותן\\ת את הסכמתי ל###תנאי השימוש### ו###הסכם הפרטיות###.',
        'loggedin-title': 'שלום {{name}}!',
        'loggedin-new': 'הרשמת יורה חדש\\ה',
        'loggedin-new-success': 'ההרשמה של {{name}} לתחרות הצליחה. בהצלחה בתחרות!',
        'nonloggedin-new-success-part1': 'מעולה, נרשמת בהצלחה!',
        'nonloggedin-new-success-part2': 'מייל אישור על ההרשמה יגיע אליך עוד מעט',
        'registernologin-part1': 'לא רוצה להתחבר?',
        'registernologin-part2': 'המשך הרשמה ללא התחברות',
        'loggedin-existing': 'עריכת הרשמה קיימת',
        'loggedin-existing-success': 'ההרשמה של {{name}} לתחרות עודכנה בהצלחה. בהצלחה בתחרות!',
        'loggedin-new-useprevious': 'מצאנו {{num}} הרשמות קודמות. תרצו להשתמש באחת מהן? (תוכלו לערוך את הנתונים לפני הרשמה)',
        'loggedin-new-cancelling': 'הבקשה לביטול ההרשמה נשלחה למנהל\\ת התחרות',
        newregistrationbutton: 'הרשמה חדשה',
        editregistrationbutton: 'עריכת הרשמה קיימת',
        'signup-title': 'כניסה',
        'signup-intro': 'התחברו על מנת להרשם לתחרות או לשנות הרשמה קיימת',
        'signup-intro-registration-code': 'הרשמה או עריכת הרשמה בתחרות זו מוגבלת רק לאנשים בעלי קוד סודי.',
        'registration-code': 'קוד גישה להרשמה',
        'signup-google': 'המשיכו עם Gmail',
        'signup-social': 'התחברו כך שתוכלו לערוך את הרישום לתחרות בעתיד',
        'status-pending-offline-payment': 'הרשמתך מחכה לתשלום',
        'status-pending-online-payment': 'הרשמתך מחכה לתשלום',
        'status-allgood': 'הכל טוב עם הרשמתך. נתראה בתחרות!',
        'status-pendingsquad': 'לסיום ההרשמה, יש לבחור סקווד',
        'status-pendingsquadclosed': 'הכל טוב עם הרשמתך. נודיע לך בהקדם כשנקצה סקוודים',
        'status-pendingcancellation': 'ביקשת לבטל את ההרשמה. מחכה לאישור של מנהל\\ת התחרות.',
        pay: 'לתשלום לתחרות',
        addnew: 'הוסף יורה חדש',
        usethis: 'הוסף את היורה הזה',
        expand: 'ל{{name}} עוד {{count}} הרשמות נוספות (לחצו להצגה).',
        cannotfindpast: 'לא מצאנו הרשמה על המשתמש הזה. הרשמה חדשה איננה אפשרית עוד.',
        thirdparty: 'לא אתם רשמתם את עצמכם?',
        thirdpartydescription: 'אם מישהו אחר רשם אתכם (ראש מועדון?), תוכלו עדיין להכנס באמצעות קוד שנשלח לכם למייל לאחר ההרשמה.',
        signinusingpin: 'כניסה באמצעות קוד',
        signin: 'הכנס',
        pincode: 'קוד סודי',
        incorrectpin: 'לא הצלחנו למצוא הרשמה עם מספר היורה והקוד שכנסתם.',
        scoreforms: 'תוצאות:',
        skipfilter: 'הצג הרשמות ישנות',
        dontskipfilter: 'הצג רק הרשמות חדשות',
    },
};

lang.iw = {
    ...lang.he,
    ...{
        'status-pendingsquad': 'לסיום ההרשמה, יש לבחור מקצה',
        'status-pendingsquadclosed': 'הכל טוב עם הרשמתך. נודיע לך בהקדם כשנקצה מקצים',
        title: 'רישום הלוחמים',
        'loggedin-new': 'הרשמת לוחם\\ת חדש\\ה',
        addnew: 'הוסף לוח\\ת חדש',
        usethis: 'הוסף את הלוח\\ת הזה',
        incorrectpin: 'לא הצלחנו למצוא הרשמה עם מספר הלוח\\ת והקוד שכנסתם.',
        thirdpartydescription: 'אם מישהו אחר רשם אתכם (מפקד היחידה?), תוכלו עדיין להכנס באמצעות קוד שנשלח לכם למייל לאחר ההרשמה.',
    },
};

export default lang;
