import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import General from './ManageHome.general';
import About from './ManageHome.about';
import api from '../Api';

class ManageHome extends Component {
    constructor(props) {
        const { match } = props;
        super(props);

        this.state = {
            title: match.title || '',
            type: match.type,
            countryCode: match.countryCode || '',
            ipscLevel: match.ipscLevel,
            description: match.description || '',
            location: match.location || null,
            startDate: match.startDate,
            endDate: match.endDate,
            logo: match.logo || '',
            cover: match.cover || '',
            alias: match.alias || '',
            about: match.about || '',
            locale: match.locale,
            package: match.package || 'free',
            showInSearch: match.showInSearch || false,
        };
    }

    componentDidMount() {
        const {
            setTitle, setSaveButtonCallback, t, match,
        } = this.props;
        setTitle(t('title', { name: match.title }));
        setSaveButtonCallback(this.onSave);
    }

    onSave = async ({ setState }) => {
        const { match, auth } = this.props;
        const {
            title,
            countryCode,
            ipscLevel,
            description,
            location,
            startDate,
            endDate,
            locale,
            about,
            showInSearch,
        } = this.state;
        setState({ saving: true });

        const patch = {
            title, countryCode, ipscLevel, description, startDate, endDate, locale, about, showInSearch,
        };

        patch.location = _.isEmpty(location) ? null : _.pick(location, ['geocode', 'formatted', 'placeId']);

        try {
            const promises = ['logo', 'cover'].map((type) => {
                const { [`${type}-file`]: fileData } = this.state;
                if (fileData) {
                    return (async () => {
                        const data = new FormData();
                        data.append('file', fileData);
                        const { url } = await api.postMatchImage({ matchId: match.id, auth, imageData: data });
                        patch[type] = url;
                    })();
                }
                return Promise.resolve();
            });

            await Promise.all(promises);

            await api.patchMatch({ matchId: match.id, auth, patch });
            await new Promise((res) => this.setState({ 'logo-file': null, 'cover-file': null }, res));
            await setState({ saving: false, saveSuccess: true });
        } catch (e) {
            console.error(e);
            await setState({ saving: false, saveError: true });
        }
        await new Promise((res) => setTimeout(res, 3000));
        setState({ saving: false, saveSuccess: false, saveError: false });
    }

    render() {
        const { match, auth } = this.props;
        return (
            <Grid
                container
                spacing={8}
                style={{
                    marginBottom: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                }}
            >
                <Grid item md={11} xs={11}>
                    <General {...this.state} matchId={match.id} auth={auth} onChange={(e) => this.setState(e)} />
                </Grid>
                <Grid item md={11} xs={11}>
                    <About {...this.state} onChange={(e) => this.setState(e)} />
                </Grid>
            </Grid>
        );
    }
}

const styles = () => ({});

export default withStyles(styles, { withTheme: true })(withTranslation('managehome')(ManageHome));

ManageHome.propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
        countryCode: PropTypes.string,
        ipscLevel: PropTypes.number,
        description: PropTypes.string,
        location: PropTypes.shape({
            placeId: PropTypes.string.isRequired,
            formatted: PropTypes.string.isRequired,
            geocode: PropTypes.shape({
                lat: PropTypes.number.isRequired,
                lng: PropTypes.number.isRequired,
            }).isRequired,
        }),
        startDate: PropTypes.shape({
            year: PropTypes.number,
            month: PropTypes.number,
            day: PropTypes.number,
        }),
        endDate: PropTypes.shape({
            year: PropTypes.number,
            month: PropTypes.number,
            day: PropTypes.number,
        }),
        logo: PropTypes.string,
        cover: PropTypes.string,
        alias: PropTypes.string,
        about: PropTypes.string,
        locale: PropTypes.string,
        package: PropTypes.string,
        showInSearch: PropTypes.bool,
    }).isRequired,
    auth: PropTypes.string.isRequired,
    setTitle: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
};
