/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Link, Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import red from '@material-ui/core/colors/red';
import Registration from './Registration';
import RegistrationVerify from './RegistrationVerify';
import RegistrationImport from './RegistrationImport';
import { getFlagSrc } from './Utils';
import Snackbar from '../components/Snackbar';
import api from '../Api';

class ShootersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            match: null,
            shooters: null,
        };
    }

    async componentDidMount() {
        const { match, auth } = this.props;

        if (typeof (window) !== 'undefined') {
            window.scrollTo(0, 0);
        }

        const promises = await Promise.all([
            api.getMatch({ matchId: match.id }),
            api.getShooters({ matchId: match.id, auth }),
            (async () => { try { const ret = await api.getStaff({ matchId: match.id, auth }); return ret; } catch (e) { return null; } })(),
        ]);
        const $match = promises[0];
        const shooters = promises[1];
        const staff = promises[2];
        this.setState({ match: $match, shooters, staff });
    }

    render() {
        const { t } = this.props;
        const {
            shooters, match, staff, saveSuccess, baseNewShooter,
        } = this.state;

        if (!shooters) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <CircularProgress size={75} />
                </div>
            );
        }

        return (
            <div>
                <Route
                    path='/:matchId/manage/shooters/new/import'
                    exact
                    render={({ history, match: routeMatch }) => (
                        <RegistrationImport
                            {...this.props}
                            history={history}
                            routeMatch={routeMatch}
                        />
                    )}
                />
                <Route
                    path='/:matchId/manage/shooters/new/import/:selectedMatchId'
                    exact
                    render={({ history, match: routeMatch }) => (
                        <RegistrationImport
                            {...this.props}
                            history={history}
                            routeMatch={routeMatch}
                            selectedMatchId={routeMatch.params.selectedMatchId}
                            onSelect={(s) => {
                                this.setState({ baseNewShooter: s }, () => {
                                    history.push(`/${routeMatch.params.matchId}/manage/shooters/new`);
                                });
                            }}
                        />
                    )}
                />

                <Route
                    path='/:matchId/manage/shooters/:shooterId'
                    exact
                    render={({ match: routeMatch, history }) => (
                        <Registration
                            {...this.props}
                            edit={shooters.find((s) => s.id === routeMatch.params.shooterId)}
                            autofill={routeMatch.params.shooterId === 'new' ? baseNewShooter : null}
                            onSuccess={async () => {
                                await new Promise((res) => this.setState({ shooters: null, saveSuccess: true }, res));
                                this.componentDidMount();
                                history.goBack();
                            }}
                        />
                    )}
                />

                <Route
                    path='/:matchId/manage/shooters/:shooterId/verify'
                    exact
                    render={({ match: routeMatch, history }) => (
                        <RegistrationVerify
                            {...this.props}
                            shooter={shooters.find((s) => s.id === routeMatch.params.shooterId)}
                            onDone={async () => {
                                await new Promise((res) => this.setState({ shooters: null, saveSuccess: true }, res));
                                this.componentDidMount();
                                history.goBack();
                            }}
                        />
                    )}
                />

                <Route
                    path='/:matchId/manage/shooters'
                    exact
                    render={() => {
                        if (baseNewShooter) this.setState({ baseNewShooter: null });
                        return <InnerShooterList {...this.props} shooters={shooters} staff={staff} match={match} />;
                    }}
                />

                <Route
                    path='/:matchId/shooters'
                    exact
                    render={() => <InnerShooterList {...this.props} shooters={shooters} staff={staff} match={match} />}
                />

                <Snackbar
                    open={saveSuccess}
                    onClose={() => this.setState({ saveSuccess: false })}
                    variant='success'
                    message={t('generic:savesuccess')}
                />
            </div>
        );
    }
}

ShootersList.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({}).isRequired,
    auth: PropTypes.string,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};

ShootersList.defaultProps = {
    auth: null,
};

export default withTranslation('shooterslist')(ShootersList);

class $InnerShooterList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sortBy: 'squad',
            sortAscDesc: 'asc',
            filter: false,
            filterName: '',
            filterId: '',
            tab: 0,
        };
    }

    async componentDidMount() {
        const { auth, match } = this.props;

        const stateJson = window.localStorage.getItem('__shooter_list_state');
        if (stateJson) {
            const state = JSON.parse(stateJson);
            if (state.matchId === match.id) {
                this.setState(state.state);
            }
        }

        if (auth) {
            this.setMainButtons();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { auth, match } = this.props;
        const { filter } = this.state;

        window.localStorage.setItem('__shooter_list_state', JSON.stringify({
            matchId: match.id,
            state: this.state,
        }));

        if ((auth) && (prevState.filter !== filter)) {
            this.setMainButtons();
        }
    }

    sortBy = (field) => {
        const { sortBy, sortAscDesc } = this.state;
        const ascDesc = ((field === sortBy) && (sortAscDesc === 'asc')) ? 'desc' : 'asc';
        this.setState({ sortBy: field, sortAscDesc: ascDesc });
    }

    addShooter = () => {
        const { history, url } = this.props;
        history.push(`${url}/new`);
    }

    openShooter = ({ id, mode }) => {
        const { history, url } = this.props;
        if (mode === 'verification') {
            history.push(`${url}/${id}/verify`);
        } else {
            history.push(`${url}/${id}`);
        }
    }

    setMainButtons = () => {
        const {
            setTitle, setSaveButtonCallback, setExtraButtons, t, match, url,
        } = this.props;
        const {
            filter,
        } = this.state;
        setTitle(t('title', { name: match.title }));
        setSaveButtonCallback(null);
        setExtraButtons([
            <Button key='addshooter' color='primary' variant='contained' onClick={this.addShooter}>
                {t('addshooter')}
            </Button>,
            <IconButton key='excel' color='primary' style={{ margin: '0px 12px' }} aria-label='import from excel' component={(props) => <Link to={`${url}-import`} {...props} />}>
                <i className='fas fa-file-excel' />
            </IconButton>,
            <IconButton key='filter' color={filter ? 'secondary' : 'primary'} aria-label='show filter row' onClick={() => this.setState({ filter: !filter })}>
                <i className='fas fa-filter' />
            </IconButton>,
        ]);
    }

    onDelete = async (e, shooterId) => {
        const {
            auth, match,
        } = this.props;

        e.stopPropagation();

        if (window.$_delete_shooter === shooterId) {
            window.$_delete_shooter = null;
            try {
                await api.deleteShooter({ matchId: match.id, shooterId, auth });
                this.setState({ saveSuccess: true });
            } catch (err) {
                console.log('[Delete shooter]:', err);
                this.setState({ saveError: true });
            }
        } else {
            window.$_delete_shooter = shooterId;
        }
    }

    renderTable({ mode }) {
        const {
            t, i18n, me, classes, shooters, match, staff,
        } = this.props;
        const {
            sortBy, sortAscDesc, filter, filterName, filterId, saveSuccess, saveError,
        } = this.state;
        const { permissions = [] } = (me || {});
        const isRegistration = _.includes(permissions, 'registration');

        const showPayment = _.some(shooters, (s) => typeof (s.paid) !== 'undefined');
        const showTeam = (match.teams || []).length > 0;
        const showIcs = match.icsEnabled || _.some(shooters, (s) => (s.ics || null) !== null);
        const showCreated = _.some(shooters, (s) => typeof (s.created) !== 'undefined');
        const displayRegistration = { display: mode === 'registration' ? '' : 'none' };
        const displayAttendance = { display: mode === 'verification' ? '' : 'none' };

        return (
            <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                <Table style={{ marginBottom: '24px' }}>
                    <TableHead>
                        <TableRow>
                            {((me || {}).publicId === 'admin') && (
                                <TableCell size='small' />
                            )}
                            <TableCell>
                                <TableSortLabel active={sortBy === 'publicId'} direction={sortAscDesc} onClick={() => this.sortBy('publicId')}>
                                    {t('generic:shooter_id')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={sortBy === 'name'} direction={sortAscDesc} onClick={() => this.sortBy('name')}>
                                    {t('generic:shooter_name')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={sortBy === 'club'} direction={sortAscDesc} onClick={() => this.sortBy('club')}>
                                    {t('generic:shooter_club')}
                                </TableSortLabel>
                            </TableCell>
                            {showTeam && (
                                <TableCell style={displayRegistration}>
                                    <TableSortLabel active={sortBy === 'team'} direction={sortAscDesc} onClick={() => this.sortBy('team')}>
                                        {t('generic:shooter_team')}
                                    </TableSortLabel>
                                </TableCell>
                            )}
                            {showIcs && (
                                <TableCell style={displayRegistration}>
                                    <TableSortLabel active={sortBy === 'ics'} direction={sortAscDesc} onClick={() => this.sortBy('ics')}>
                                        {t('generic:shooter_ics')}
                                    </TableSortLabel>
                                </TableCell>
                            )}
                            {showPayment && (
                                <TableCell>
                                    <TableSortLabel active={sortBy === 'paid'} direction={sortAscDesc} onClick={() => this.sortBy('paid')}>
                                        {t('registration:paid')}
                                    </TableSortLabel>
                                </TableCell>
                            )}
                            <TableCell style={displayRegistration}>
                                <TableSortLabel active={sortBy === 'squad'} direction={sortAscDesc} onClick={() => this.sortBy('squad')}>
                                    {t('generic:squad_number')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={displayRegistration}>
                                <TableSortLabel active={sortBy === 'division'} direction={sortAscDesc} onClick={() => this.sortBy('division')}>
                                    {t('generic:shooter_division')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={displayRegistration}>
                                <TableSortLabel active={sortBy === 'category'} direction={sortAscDesc} onClick={() => this.sortBy('category')}>
                                    {t('generic:shooter_category')}
                                </TableSortLabel>
                            </TableCell>
                            {(match.type !== 'ipsc-shotgun') && (
                                <TableCell style={displayRegistration}>
                                    <TableSortLabel active={sortBy === 'power-factor'} direction={sortAscDesc} onClick={() => this.sortBy('power-factor')}>
                                        {t('generic:shooter_power_factor')}
                                    </TableSortLabel>
                                </TableCell>
                            )}
                            <TableCell style={displayAttendance}>
                                <TableSortLabel active={sortBy === 'verified'} direction={sortAscDesc} onClick={() => this.sortBy('verified')}>
                                    {t('verified')}
                                </TableSortLabel>
                            </TableCell>
                            {showCreated && (
                                <TableCell>
                                    <TableSortLabel active={sortBy === 'created'} direction={sortAscDesc} onClick={() => this.sortBy('created')}>
                                        {t('created')}
                                    </TableSortLabel>
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filter && (
                            <TableRow>
                                {((me || {}).publicId === 'admin') && (
                                    <TableCell size='small' />
                                )}
                                <TableCell>
                                    <TextField value={filterId} onChange={(e) => this.setState({ filterId: e.target.value })} />
                                </TableCell>
                                <TableCell>
                                    <TextField value={filterName} onChange={(e) => this.setState({ filterName: e.target.value })} />
                                </TableCell>
                            </TableRow>
                        )}
                        {_.orderBy(shooters, (s) => {
                            const squad = s.squad ? match.squads.find((sq) => sq.id === s.squad) : null;
                            if (sortBy === 'squad') {
                                const sortNumberic = _.every(_.map(match.squads, 'title'), (title) => !Number.isNaN(parseInt(title, 10)));
                                const title = squad ? squad.title : '';
                                return [s.cancellationRequest ? -1 : 0, !isValidRo({ shooter: s, squad, staff }) ? -1 : 0, sortNumberic ? Number(parseInt(title, 10)) : title];
                            } if (sortBy === 'division') {
                                return [s.cancellationRequest ? -1 : 0, !isValidRo({ shooter: s, squad, staff }) ? -1 : 0, s.division, s.category, s.name];
                            } if (sortBy === 'category') {
                                return [s.cancellationRequest ? -1 : 0, !isValidRo({ shooter: s, squad, staff }) ? -1 : 0, s.category, s.division, s.name];
                            } if (sortBy === 'club') {
                                return [s.cancellationRequest ? -1 : 0, !isValidRo({ shooter: s, squad, staff }) ? -1 : 0, (s[sortBy] || '').replace(/\u00A0/gi, ' '), s.name];
                            }
                            return [s.cancellationRequest ? 1 : 0, !isValidRo({ shooter: s, squad, staff }) ? -1 : 0, s[sortBy], s.name];
                        }, [sortAscDesc, sortAscDesc, sortAscDesc]).filter((s) => {
                            if (!filter) return true;
                            if ((filterName) && (s.name.indexOf(filterName) === -1)) return false;
                            if ((filterId) && (s.publicId.toString().indexOf(filterId) === -1)) return false;
                            return true;
                        }).map((s) => {
                            const squad = s.squad ? match.squads.find((sq) => sq.id === s.squad) : null;
                            return (
                                <TableRow classes={{ root: s.cancelledParticipation ? classes.rowcancelled : null }} hover={isRegistration} onClick={() => isRegistration && this.openShooter({ id: s.id, mode })} key={s.id}>
                                    {((me || {}).publicId === 'admin') && (
                                        <TableCell classes={{ root: s.cancellationRequest ? classes.rowcancelrequest : null }} size='small'>
                                            <IconButton color='secondary' onClick={(e) => this.onDelete(e, s.id)}>
                                                <i className='fas fa-times' style={{ fontSize: '9px' }} />
                                            </IconButton>
                                        </TableCell>
                                    )}
                                    <TableCell classes={{ root: s.cancellationRequest || !isValidRo({ shooter: s, squad, staff }) ? classes.rowcancelrequest : null }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {s.cancellationRequest && (
                                                <Tooltip title={t('cancellationrequest')}>
                                                    <i className='fas fa-exclamation-triangle' style={{ fontSize: '18px', color: red[500], [i18n.dir() === 'rtl' ? 'marginLeft' : 'marginRight']: '12px' }} />
                                                </Tooltip>
                                            )}
                                            {!s.cancellationRequest && !isValidRo({ shooter: s, squad, staff }) && (
                                                <Tooltip title={t('invalidro')}>
                                                    <i className='fas fa-gavel' style={{ fontSize: '18px', color: red[500], [i18n.dir() === 'rtl' ? 'marginLeft' : 'marginRight']: '12px' }} />
                                                </Tooltip>
                                            )}
                                            {(s.publicId.indexOf('|') > -1) && (
                                                <img
                                                    alt={s.publicId.split('|')[0]}
                                                    src={getFlagSrc({ countryCode: s.publicId.split('|')[0], size: 16 })}
                                                />
                                            )}
                                            <div style={{ padding: '0px 6px' }}>
                                                {s.publicId.split('|').pop()}
                                            </div>
                                        </div>
                                        <div style={{ display: 'none' }}>
                                            {s.id}
                                        </div>
                                    </TableCell>
                                    <TableCell classes={{ root: s.cancellationRequest ? classes.rowcancelrequest : null }}>
                                        <div style={{ display: 'flex' }}>
                                            {s.name}
                                            {((s.comments || []).length > 0) && (
                                                <i className='fas fa-comments' />
                                            )}
                                        </div>
                                        {['ro', 'cro', 'so', 'md', 'rm', 'qm'].map((role) => ((s.labels || []).indexOf(role) > -1) && (
                                            <div key={role}>
                                                <Chip color='primary' style={{ borderRadius: '0px', height: 'auto', fontSize: '9px' }} label={role.toUpperCase()} />
                                            </div>
                                        ))}
                                    </TableCell>
                                    <TableCell classes={{ root: s.cancellationRequest ? classes.rowcancelrequest : null }}>
                                        {s.club}
                                    </TableCell>
                                    {showTeam && <TableCell style={displayRegistration}>{s.team ? match.teams.find((team) => team.id === s.team).title : ''}</TableCell>}
                                    {showIcs && <TableCell style={displayRegistration}>{s.ics ? t(`generic:ics-${s.ics}`) : ''}</TableCell>}
                                    {showPayment && <TableCell classes={{ root: s.cancellationRequest ? classes.rowcancelrequest : null }}>{s.paid ? '✓' : ''}</TableCell>}
                                    <TableCell classes={{ root: s.cancellationRequest ? classes.rowcancelrequest : null }} style={displayRegistration}>{squad ? squad.title : ''}</TableCell>
                                    <TableCell classes={{ root: s.cancellationRequest ? classes.rowcancelrequest : null }} style={displayRegistration}>{t(`generic:division-${s.division}`)}</TableCell>
                                    <TableCell classes={{ root: s.cancellationRequest ? classes.rowcancelrequest : null }} style={displayRegistration}>{t(`generic:category-${s.category}`)}</TableCell>
                                    {(match.type !== 'ipsc-shotgun') && (
                                        <TableCell classes={{ root: s.cancellationRequest ? classes.rowcancelrequest : null }} style={displayRegistration}>{t(`generic:power-factor-${s['power-factor']}`)}</TableCell>
                                    )}
                                    <TableCell classes={{ root: s.cancellationRequest ? classes.rowcancelrequest : null }} style={displayAttendance}>{s.verified ? '✓' : ''}</TableCell>
                                    {showCreated && <TableCell classes={{ root: s.cancellationRequest ? classes.rowcancelrequest : null }}>{moment(s.created || 1554432499000).format('ll LTS')}</TableCell>}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                {isRegistration && (
                <Typography variant='caption'>
                    <div style={{ padding: '0px 12px', width: 'calc(100% - 24px)', paddingBottom: '36px' }}>
                        {t('num-active-shooters')}
                        :
                        {shooters.filter((s) => !s.cancelledParticipation).length}
                        &nbsp;|&nbsp;
                        {t('num-cancelled-shooters')}
                        :
                        {shooters.filter((s) => s.cancelledParticipation).length}
                        &nbsp;|&nbsp;
                        {t('num-paying-shooters')}
                        :
                        {shooters.filter((s) => s.paid).length}
                        &nbsp;|&nbsp;
                        {t('num-squad-shooters')}
                        :
                        {shooters.filter((s) => !!s.squad).length}
                    </div>
                </Typography>
                )}

                {((me || {}).publicId === 'admin') && (
                    <div>
                        <Snackbar
                            open={saveError || false}
                            onClose={() => this.setState({ saveError: false })}
                            variant='error'
                            message={t('generic:saveerror')}
                        />

                        <Snackbar
                            open={saveSuccess || false}
                            onClose={() => this.setState({ saveSuccess: false })}
                            variant='success'
                            message={t('generic:savesuccess')}
                        />
                    </div>
                )}
            </div>
        );
    }

    render() {
        const {
            t, me, manage,
        } = this.props;

        const {
            tab,
        } = this.state;

        if (manage && ((me || {}).permissions.indexOf('registration-verify') > -1)) {
            return (
                <div>
                    <Tabs value={tab} onChange={(e, v) => this.setState({ tab: v })}>
                        <Tab label={t('tab-registration')} />
                        <Tab label={t('tab-verification')} />
                    </Tabs>
                    <Typography variant='caption' style={{ padding: '0px 24px', opacity: 0.8 }}>
                        {tab === 0 && t('tab-registration-details')}
                        {tab === 1 && t('tab-verification-details')}
                    </Typography>
                    {tab === 0 && this.renderTable({ mode: 'registration' })}
                    {tab === 1 && this.renderTable({ mode: 'verification' })}
                </div>
            );
        }

        return this.renderTable({ mode: 'registration' });
    }
}

function isValidRo({ shooter, squad, staff }) {
    if (!staff) return true;
    if (!squad) return true;
    if (!squad.ro) return true;
    return !!staff.find((s) => s.publicId === shooter.publicId);
}

const styles = {
    rowcancelled: {
        opacity: 0.5,
        textDecoration: 'line-through',
    },
    rowcancelrequest: {
        color: red[500],
        background: 'rgba(244, 67, 54, 0.2)',
    },
};

$InnerShooterList.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    classes: PropTypes.shape({}).isRequired,
    manage: PropTypes.bool,
    url: PropTypes.string.isRequired,
    setTitle: PropTypes.func,
    setSaveButtonCallback: PropTypes.func,
    setExtraButtons: PropTypes.func,
    setSaveButtonLabel: PropTypes.func,
    setBackButtonVisible: PropTypes.func,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        dir: PropTypes.func,
    }).isRequired,
    auth: PropTypes.string,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        squads: PropTypes.arrayOf(PropTypes.shape({})),
        teams: PropTypes.arrayOf(PropTypes.shape({})),
        icsEnabled: PropTypes.bool,
    }).isRequired,
    me: PropTypes.shape({
        name: PropTypes.string.isRequired,
        permissions: PropTypes.array.isRequired,
    }),
    shooters: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        club: PropTypes.string,
        division: PropTypes.string,
        category: PropTypes.string,
        cancelledParticipation: PropTypes.bool,
        paid: PropTypes.bool,
        squad: PropTypes.string,
    })).isRequired,
    staff: PropTypes.arrayOf(PropTypes.shape({
    })),
};

$InnerShooterList.defaultProps = {
    manage: false,
    setTitle: null,
    setSaveButtonCallback: null,
    setExtraButtons: null,
    setSaveButtonLabel: null,
    setBackButtonVisible: null,
    auth: null,
    me: null,
    staff: null,
};

const InnerShooterList = withStyles(styles)(withTranslation('shooterslist')($InnerShooterList));
/* eslint-enable max-classes-per-file */
