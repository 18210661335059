import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import app from '../AppCommunication';

function AppleSignIn(props) {
    const { t } = props;

    const onLogin = () => {
        app({ cmd: { src: 'apple-login', type: 'on-login' } });
    };

    return (
        <div style={{ position: 'relative', width: '240px', height: '50px' }}>
            <Button
                color='primary'
                variant='contained'
                style={{
                    width: '240px', height: '50px', textTransform: 'none', display: 'flex',
                }}
                onClick={onLogin}
            >
                <Icon className='fab fa-apple' />
                <div style={{ flex: 1 }}>{t('authentication:sign-in-with-apple')}</div>
            </Button>
        </div>
    );
}

AppleSignIn.propTypes = {
    t: PropTypes.func.isRequired,
};

AppleSignIn.defaultProps = {
};

export default withTranslation('')(AppleSignIn);
