import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import ReactFlagsSelect from 'react-flags-select';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconPhotoCamera from '@material-ui/icons/PhotoCamera';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Premium from '../components/Premium';
import AddressInput from '../components/AddressInput';
import api from '../Api';

class ManageHomeGeneral extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async UNSAFE_componentWillMount() {
        const { matchId, auth } = this.props;
        const shooters = await api.getShooters({ matchId, auth });
        this.setState({ shooters });
    }

    shouldComponentUpdate(nextProps, nextState) {
        /* eslint-disable react/destructuring-assignment */
        if (nextProps.title !== this.props.title) return true;
        if (nextProps.type !== this.props.type) return true;
        if (nextProps.description !== this.props.description) return true;
        if (!_.isEqual(nextProps.location, this.props.location)) return true;
        if (nextProps.startDate !== this.props.startDate) return true;
        if (nextProps.endDate !== this.props.endDate) return true;
        if (nextProps.package !== this.props.package) return true;
        if (nextProps.alias !== this.props.alias) return true;
        if (nextProps.cover !== this.props.cover) return true;
        if (nextProps.logo !== this.props.logo) return true;
        if (nextProps.ipscLevel !== this.props.ipscLevel) return true;
        if (nextProps.showInSearch !== this.props.showInSearch) return true;
        if (nextState.shooters !== this.state.shooters) return true;
        /* eslint-enable react/destructuring-assignment */
        return false;
    }

    handleImageUpload = async ({ field, event }) => {
        const { onChange } = this.props;
        const file = event.target.files[0];

        const reader = new FileReader();
        reader.onload = (e2) => {
            onChange({ [`${field}-file`]: file, [field]: e2.target.result });
        };
        reader.readAsDataURL(file);
    }

    render() {
        const {
            classes, t, title, type, countryCode, ipscLevel,
            description, location, startDate, endDate, package: _package,
            alias, cover, logo, showInSearch, onChange,
        } = this.props;
        const { shooters } = this.state;
        return (
            <Card>
                <CardHeader
                    classes={{ title: classes.cardtitle }}
                    title={t('about')}
                    subheader={t('about-subtitle')}
                />
                <CardContent style={{ position: 'relative' }}>
                    <Premium
                        numShooters={(shooters || []).length}
                        matchId={alias}
                        package={alias.startsWith('demo-') ? 'full' : _package}
                        style={{
                            position: 'absolute',
                            right: '6px',
                            top: '-82px',
                            width: '250px',
                            height: '37px',
                        }}
                    />
                    <form className={classes.form}>
                        <TextField label={t('title-field')} value={title} onChange={(e) => onChange({ title: e.target.value })} />
                        <TextField label={t('type-field')} disabled value={t(type)} />
                        <Typography variant='caption'>
                            {t('type-field-comment')}
                        </Typography>
                        <div classes={{ root: classes.region }}>
                            <FormLabel style={{ fontSize: '12px' }}>{t('generic:region')}</FormLabel>
                            <div className={classes.flagwrapper}>
                                <ReactFlagsSelect defaultCountry={countryCode} onSelect={(c) => onChange({ countryCode: c })} />
                            </div>
                        </div>
                        <TextField label={t('description-field')} value={description} fullWidth onChange={(e) => onChange({ description: e.target.value })} />
                        <div classes={{ root: classes.region }}>
                            <FormLabel style={{ fontSize: '12px' }}>{t('ipsclevel')}</FormLabel>
                            <br />
                            <Select
                                value={ipscLevel}
                                onChange={(e) => onChange({ ipscLevel: e.target.value })}
                            >
                                <MenuItem value={0}>{t('ipsclevel0')}</MenuItem>
                                <MenuItem value={1}>{t('ipsclevel1')}</MenuItem>
                                <MenuItem value={2}>{t('ipsclevel2')}</MenuItem>
                                <MenuItem value={3}>{t('ipsclevel3')}</MenuItem>
                                <MenuItem value={4}>{t('ipsclevel4')}</MenuItem>
                                <MenuItem value={5}>{t('ipsclevel5')}</MenuItem>
                            </Select>
                        </div>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                value={moment([startDate.year, startDate.month - 1, startDate.day])}
                                onChange={(value) => onChange({
                                    startDate: {
                                        year: value.year(),
                                        month: value.month() + 1,
                                        day: value.date(),
                                    },
                                })}
                                maxDate={moment([endDate.year, endDate.month - 1, endDate.day])}
                                animateYearScrolling
                                format='LL'
                                TextFieldComponent={(props) => <TextField {...props} label={t('start-date-field')} fullWidth InputProps={{ ...props.InputProps }} />}
                            />
                            <DatePicker
                                value={moment([endDate.year, endDate.month - 1, endDate.day])}
                                onChange={(value) => onChange({
                                    endDate: {
                                        year: value.year(),
                                        month: value.month() + 1,
                                        day: value.date(),
                                    },
                                })}
                                minDate={moment([startDate.year, startDate.month - 1, startDate.day])}
                                animateYearScrolling
                                format='LL'
                                TextFieldComponent={(props) => <TextField {...props} label={t('end-date-field')} fullWidth InputProps={{ ...props.InputProps }} />}
                            />
                        </MuiPickersUtilsProvider>
                        <div className={classes.logocover}>
                            <FormControl classes={{ root: classes.logowrapper }}>
                                <InputLabel shrink>{t('logo')}</InputLabel>
                                <input
                                    accept='image/*'
                                    style={{ display: 'none' }}
                                    id='logo-image'
                                    type='file'
                                    hidden
                                    onChange={(e) => this.handleImageUpload({ field: 'logo', event: e })}
                                />
                                <label htmlFor='logo-image'>
                                    <div className={classnames([classes.logo, classes.imageinput, logo ? classes.imageinputwithimage : null])} style={{ backgroundImage: `url("${logo}")` }}>
                                        <div>
                                            <IconPhotoCamera fontSize='large' />
                                            <FormHelperText style={{ color: 'inherit' }}>{t(logo ? 'changephotohelper' : 'newphotohelper')}</FormHelperText>
                                        </div>
                                    </div>
                                </label>
                            </FormControl>

                            <FormControl classes={{ root: classes.coverwrapper }}>
                                <InputLabel shrink>{t('cover')}</InputLabel>
                                <input
                                    accept='image/*'
                                    style={{ display: 'none' }}
                                    id='cover-image'
                                    type='file'
                                    hidden
                                    onChange={(e) => this.handleImageUpload({ field: 'cover', event: e })}
                                />
                                <label htmlFor='cover-image'>
                                    <div className={classnames([classes.cover, classes.imageinput, cover ? classes.imageinputwithimage : null])} style={{ backgroundImage: `url("${cover}")` }}>
                                        <div>
                                            <IconPhotoCamera fontSize='large' />
                                            <FormHelperText style={{ color: 'inherit' }}>{t(cover ? 'changephotohelper' : 'newphotohelper')}</FormHelperText>
                                        </div>
                                    </div>
                                </label>
                            </FormControl>
                        </div>
                        <div>
                            <FormLabel style={{ fontSize: '12px' }}>{t('location-field')}</FormLabel>
                            <AddressInput
                                onChange={({ address }) => onChange({ location: address })}
                                error={false}
                                address={location || {}}
                                classes={{ map: 'home-address-map' }}
                                textFieldProps={{
                                    inputProps: { className: '' },
                                }}
                            />
                        </div>
                        <TextField label={t('url')} fullWidth value={`https://www.endofscoring.com/${alias}`} disabled />
                        <div>
                            <FormControlLabel control={<Switch color='primary' checked={showInSearch} onChange={() => onChange({ showInSearch: !showInSearch })} />} label={t('showinsearch')} />
                        </div>
                    </form>
                </CardContent>
            </Card>
        );
    }
}

const styles = (theme) => ({
    cardtitle: {
        fontWeight: 300,
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        '& > div': {
            marginTop: '16px',
        },
        '& > div:first-child': {
            marginTop: '0px',
        },
    },
    logocover: {
        display: 'flex',
        width: '100%',
    },
    logowrapper: {
        width: 'calc(20% - 8px)',
        [theme.rtl ? 'marginLeft' : 'marginRight']: '16px',
    },
    imageinput: {
        cursor: 'pointer',
        border: '1px solid rgba(0,0,0,0.2)',
        boxSizing: 'border-box',
        '&:hover': {
            border: '1px solid rgba(0,0,0,0.4)',
        },
        '&:hover > div': {
        },
        '& > div': {
            position: 'absolute',
            top: '16px',
            left: '0px',
            width: '100%',
            height: 'calc(100% - 16px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            opacity: '0.8',
        },
    },
    imageinputwithimage: {
        '&:hover': {
            border: '1px solid rgba(0,0,0,0.2)',
        },
        '&:hover > div': {
            color: 'white',
            background: 'rgba(0,0,0,0.7)',
            opacity: '0.8',
        },
        '& > div': {
            opacity: '0',
            transition: 'opacity .15s ease-in-out',
        },
    },
    logo: {
        marginTop: '16px',
        width: '100%',
        paddingTop: '100%',
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
    },
    coverwrapper: {
        width: 'calc(80% - 8px)',
    },
    cover: {
        marginTop: '16px',
        width: '100%',
        paddingTop: 'calc(25% - 6px)',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
    },
    flagwrapper: {
        width: '75px',
        minWidth: '75px',
        maxWidth: '75px',
        display: 'flex',
        flex: 1,
        textAlign: 'left',
        '& > div': {
            flex: 1,
        },
        '& .selected--flag--option  .country-label': {
            display: 'none',
        },
        '& .flag-select': {
            textAlign: 'left',
        },
    },
    region: {
        display: 'flex',
        flexDirection: 'column',
    },
});

ManageHomeGeneral.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    auth: PropTypes.string.isRequired,
    matchId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    location: PropTypes.shape({
        placeId: PropTypes.string.isRequired,
        formatted: PropTypes.string.isRequired,
        geocode: PropTypes.shape({
            lat: PropTypes.number.isRequired,
            lng: PropTypes.number.isRequired,
        }).isRequired,
    }),
    startDate: PropTypes.shape({
        day: PropTypes.number.isRequired,
        month: PropTypes.number.isRequired,
        year: PropTypes.number.isRequired,
    }).isRequired,
    endDate: PropTypes.shape({
        day: PropTypes.number.isRequired,
        month: PropTypes.number.isRequired,
        year: PropTypes.number.isRequired,
    }).isRequired,
    alias: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
    package: PropTypes.string,
    cover: PropTypes.string,
    logo: PropTypes.string,
    ipscLevel: PropTypes.number.isRequired,
    showInSearch: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

ManageHomeGeneral.defaultProps = {
    package: null,
    cover: null,
    logo: null,
    showInSearch: false,
    location: null,
};

export default withStyles(styles, { withTheme: true })(withTranslation('managehome')(ManageHomeGeneral));
