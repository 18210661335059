import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '../components/Snackbar';
import ScoreShooter from './Score.shooter';
import { scoreToStr } from './Utils';
import api from '../Api';

class ScoreTransfer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            squadId: null,
        };
    }

    componentDidMount() {
        const {
            t, shooters, stageIdx, shooterId, setTitle, setExtraButtons, setSaveButtonCallback, setBackButtonVisible,
        } = this.props;

        setTitle(`${t('managewrapper:scores')} - ${t('stage')} ${stageIdx} / ${shooters.find((s) => s.id === shooterId).name} - ${t('transfer')}`);
        setExtraButtons([]);
        setSaveButtonCallback(null);
        setBackButtonVisible(true);
    }

    onTransfer = async () => {
        const {
            t, score, matchId, auth, onUpdateScore, onSaveError,
        } = this.props;

        const {
            askTransfer, askSwap,
        } = this.state;

        const newShooterId = askTransfer || askSwap.shooterId;

        await new Promise((res) => this.setState({ transfering: true }, res));

        try {
            const newScore = await api.patchScore({
                matchId,
                scoreId: score.id,
                auth,
                patch: {
                    shooterId: newShooterId, editable: false,
                },
            });
            await new Promise((res) => this.setState({ transfering: false, askTransfer: null }, res));
            onUpdateScore([newScore]);
        } catch (e) {
            await new Promise((res) => this.setState({ transfering: false }, res));
            onSaveError(t('senderror'));
        }
    }

    onSwap = async () => {
        const {
            t, score, matchId, auth, onUpdateScore, onSaveError,
        } = this.props;

        const {
            askSwap,
        } = this.state;

        const score1Id = score.id;
        const score2Id = askSwap.id;
        const score1ShooterId = score.shooterId;
        const score2ShooterId = askSwap.shooterId;

        await new Promise((res) => this.setState({ swapping: true }, res));

        try {
            await api.patchScore({
                matchId,
                scoreId: score2Id,
                auth,
                patch: {
                    editable: true,
                },
            });

            const promises = await Promise.all([
                api.patchScore({
                    matchId,
                    scoreId: score1Id,
                    auth,
                    patch: {
                        shooterId: score2ShooterId, editable: false,
                    },
                }),
                api.patchScore({
                    matchId,
                    scoreId: score2Id,
                    auth,
                    patch: {
                        shooterId: score1ShooterId, editable: false,
                    },
                }),
            ]);

            await new Promise((res) => this.setState({ swapping: false }, res));
            onUpdateScore([promises[0].data, promises[1].data]);
        } catch (e) {
            await new Promise((res) => this.setState({ swapping: false }, res));

            await Promise.all([
                api.patchScore({
                    matchId,
                    scoreId: score1Id,
                    auth,
                    patch: {
                        shooterId: score1ShooterId,
                    },
                }),
                api.patchScore({
                    matchId,
                    scoreId: score2Id,
                    auth,
                    patch: {
                        shooterId: score2ShooterId,
                    },
                }),
            ]);

            onSaveError(t('senderror'));
        }
    }

    render() {
        const {
            t, score, scores, stages, stageIdx, shooterId, shooters, squads, match,
        } = this.props;

        const {
            squadId, askSwap, askTransfer, transfering, swapping,
        } = this.state;

        return (
            <div>
                <ScoreShooter
                    match={match}
                    setTitle={() => {}}
                    setSaveButtonLabel={() => {}}
                    setSaveButtonCallback={() => {}}
                    setExtraButtons={() => {}}
                    setBackButtonVisible={() => {}}
                    shooters={shooters.filter((s) => (squadId ? s.squad === squadId : true))}
                    stageIdx={stageIdx}
                    scores={[]}
                    squads={squads}
                    squadId={squadId}
                    shootersMissingFromBriefing={[shooterId]}
                    shootersDqed={{}}
                    onSquad={($squadId) => { this.setState({ squadId: $squadId }); }}
                    onEditShooter={null}
                    onShooterMissing={null}
                    onSorting={null}
                    onBriefing={null}
                    onShooter={($shooterId) => {
                        const existing = scores[stageIdx - 1].find((s) => $shooterId === s.shooterId);

                        if (existing) {
                            this.setState({ askSwap: existing });
                        } else {
                            this.setState({ askTransfer: $shooterId });
                        }
                    }}
                />

                <Snackbar
                    open={!!askTransfer}
                    onClose={() => this.setState({ askTransfer: null })}
                    variant='info'
                    message={(
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 1 }}>
                                <div>
                                    {t('transfer-approve', {
                                        stageIdx,
                                        src: (shooters.find((s) => s.id === shooterId) || {}).name,
                                        dest: (shooters.find((s) => s.id === askTransfer) || {}).name,
                                    })}
                                </div>
                                <div>
                                    {askTransfer ? scoreToStr({ t, score, stage: stages[stageIdx - 1] }) : null}
                                </div>

                            </div>
                            <Button style={{ color: 'white', margin: '12px' }} onClick={this.onTransfer}>
                                {transfering ? <CircularProgress size={12} color='white' /> : t('generic:yes')}
                            </Button>
                            <Button style={{ color: 'white' }} onClick={() => this.setState({ askTransfer: null })}>
                                {t('generic:no')}
                            </Button>
                        </div>
                    )}
                />

                <Snackbar
                    open={!!askSwap}
                    onClose={() => this.setState({ askSwap: null })}
                    variant='info'
                    message={(
                        <div style={{
                            width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center',
                        }}
                        >
                            <div style={{ flex: 1 }}>
                                <div>
                                    {t('transfer-swap-approve', {
                                        stageIdx,
                                        src: (shooters.find((s) => s.id === shooterId) || {}).name,
                                        dest: (shooters.find((s) => s.id === (askSwap || {}).shooterId) || {}).name,
                                    })}
                                </div>
                            </div>
                            <Button style={{ color: 'white', margin: '12px' }} disabled={transfering || swapping} onClick={this.onTransfer}>
                                {transfering
                                    ? <CircularProgress size={12} color='white' />
                                    : t('action-transfer', {
                                        stageIdx,
                                        src: (shooters.find((s) => s.id === shooterId) || {}).name,
                                        dest: (shooters.find((s) => s.id === (askSwap || {}).shooterId) || {}).name,
                                    })}
                            </Button>
                            <Button style={{ color: 'white', margin: '12px' }} disabled={transfering || swapping} onClick={this.onSwap}>
                                {swapping
                                    ? <CircularProgress size={12} color='white' />
                                    : t('action-swap', {
                                        stageIdx,
                                        src: (shooters.find((s) => s.id === shooterId) || {}).name,
                                        dest: (shooters.find((s) => s.id === (askSwap || {}).shooterId) || {}).name,
                                    })}
                            </Button>
                            <Button style={{ color: 'white' }} onClick={() => this.setState({ askSwap: null })}>
                                {t('generic:cancel')}
                            </Button>
                        </div>
                    )}
                />

            </div>
        );
    }
}

const styles = () => ({});

ScoreTransfer.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        dir: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        payment: PropTypes.string,
    }).isRequired,
    stageIdx: PropTypes.number.isRequired,
    shooterId: PropTypes.string.isRequired,
    matchId: PropTypes.string.isRequired,
    auth: PropTypes.string.isRequired,
    squads: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    })).isRequired,
    shooters: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        publicId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        division: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        'power-factor': PropTypes.string.isRequired,
    })).isRequired,
    score: PropTypes.shape({
        id: PropTypes.string.isRequired,
        shooterId: PropTypes.string.isRequired,
    }).isRequired,
    scores: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        shooterId: PropTypes.string.isRequired,
    }))).isRequired,
    stages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onUpdateScore: PropTypes.func.isRequired,
    onSaveError: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    setBackButtonVisible: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(withTranslation('score')(ScoreTransfer));
