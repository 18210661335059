const lang = {
    en: {
        title: 'Score Sheet',
        published: 'Score sheet (partial or full) are available for shooters',
        verifyonly: 'Show only verify',
        overall: 'Overall',
        combined: 'Overall - Combined',
        'overall-ics': 'By Classifications',
        'by-stage': 'By Stage',
        'combined-by-stage': 'By Stage - Combined',
        'by-category': 'By Category',
        verify: 'Verify',
        exceptions: 'Exceptions',
        'overall-title': 'Overall Match Results for $t(generic:division-{{division}})',
        'overall-ics-title': '"$t(generic:ics-{{ics}})" Class Shooters',
        'combined-title': 'Overall Match Results for All Shooters Combined',
        'by-stage-title': 'Stage {{stage}} results for $t(generic:division-{{division}})',
        'combined-by-stage-title': 'Stage {{stage}} results for All Shooters Combined',
        'by-category-title': '$t(generic:division-{{division}}) - Results by Category',
        'by-category-subtitle': 'Category $t(registration:category-{{category}})',
        'verify-title': 'Score Verification by Competitor',
        'exceptions-title': 'Exceptions by Competitor',
        subtitle: '{{- date}} - {{- name}} ',
        printed: 'Printed: {{- date}}',
        'dq-title': 'DQs',
        nodata: 'Score hasn\'t been entered yet',
        noscores: 'We still don\'t have any scores for this match. Come back here after the match begins to see live scores.',
        teams: 'By Teams',
        'teams-title': 'Team Match Results for: {{teamType}}',
        'teams-subtitle': 'Team scores are calculated by accumulating the best three shooters\' overall division scores.',
        'all-stages': 'All stages',
        'all-divisions': 'All divisions',
        'all-categories': 'All categories',
        'all-ics': 'All classifications',
        tvmodetitle: 'TV Mode',
        tvmodedescription: 'TV mode automatically shows, switches, and scrolls through the different score tables.',
        tvmodedescription2: 'Please select which scores to show:',
        opentvmode: 'Open TV Mode',
        whatif: 'Scores above are not the real scores. They have been updated with a "What If" scenario.',
        percentageofoverall: '(% of overall)',
    },
    he: {
        title: 'תוצאות',
        published: 'תוצאות (חלקיות או מלאות) מוכנות להצגה באתר',
        verifyonly: 'הצג ווריפיי בלבד',
        overall: 'תוצאות כלליות',
        combined: 'תוצאות כלליות משולבות',
        'combined-by-stage': 'תוצאות לפי תרגיל משולבות',
        'by-stage': 'תוצאות לפי תרגיל',
        'by-category': 'תוצאות לפי קטגוריה',
        verify: 'ווריפיי',
        exceptions: 'חריגים',
        'overall-title': 'תוצאות כלליות עבור $t(generic:division-{{division}})',
        'overall-ics-title': 'יורי קלסיפיקציה "$t(generic:ics-{{ics}})"',
        'combined-title': 'תוצאות כלליות עבור כלל היורים',
        'by-stage-title': 'תוצאות תרגיל {{stage}} עבור $t(generic:division-{{division}})',
        'combined-by-stage-title': 'תוצאות תרגיל {{stage}} עבור כלל היורים',
        'by-category-title': 'תוצאות לפי קטגוריה עבור $t(generic:division-{{division}})',
        'by-category-subtitle': 'תוצאות לקטגורית $t(registration:category-{{category}})',
        'verify-title': 'ווידוא תוצאות ליורה',
        'exceptions-title': 'חריגים',
        subtitle: '{{- date}} - {{- name}} ',
        printed: 'הודפס: {{- date}}',
        'dq-title': 'פסילות',
        nodata: 'טרם הכניסו תוצאות',
        noscores: 'טרם הוכנסו תוצאות לתחרות שלנו. חיזרו לכאן אחרי תחילת התחרות לצפיה בתוצאות חיות ומיידיות.',
        teams: 'תוצאות לפי קבוצות',
        'teams-title': 'תוצאות לקבוצות מסוג: {{teamType}}',
        'teams-subtitle': 'תוצאות מחושבות לפי סכום שלושת התוצאות הכלליות הגבוהות ביותר של יורי הקבוצה',
        'all-stages': 'כל התרגילים',
        'all-divisions': 'כל המחלקות',
        'all-categories': 'כל הקטגוריות',
        tvmodetitle: 'מצב טלויזיה',
        tvmodedescription: 'במצב טלויזיה המערכת אוטומטית גוללת ומחליפה ביו התוצאות השונות כל מספר שניות.',
        tvmodedescription2: 'אנא בחרו את התוצאות להציג:',
        opentvmode: 'עבור למצב טלויזיה',
        whatif: 'אתם צופים כרגע בתוצאות ״מה אם״. התוצאות המוצגות אינן התוצאות האמיתיות.',
        percentageofoverall: '(% מהניקוד הכללי)',
    },
};

lang.iw = {
    ...lang.he,
    ...{
        'overall-ics-title': 'לוחמי קלסיפיקציה "$t(generic:ics-{{ics}})"',
        'combined-title': 'תוצאות כלליות עבור כלל הלוחמים',
        'combined-by-stage-title': 'תוצאות תרגיל {{stage}} עבור כלל הלוחמים',
        'verify-title': 'ווידוא תוצאות ללוחם\\ת',
        'teams-subtitle': 'תוצאות מחושבות לפי סכום שלושת התוצאות הכלליות הגבוהות ביותר של לוחמי הקבוצה',
    },
};

export default lang;
