module.exports = {
    en: {
        discover: 'Discover',
        me: 'Me',
        quickscore: 'Quick Score',
        'ipsclevel-0': 'Unapproved Match',
        'ipsclevel-1': 'Approved Regional L1',
        'ipsclevel-2': 'Approved Regional L2',
        'ipsclevel-3': 'Approved Regional L3',
        'ipsclevel-4': 'Approved Continental',
        'ipsclevel-5': 'The World Shoots',
        'discover-filter-0': 'all matches',
        'discover-filter-1': 'approved matches',
        'discover-geofilter-all': 'around the world',
    },
    he: {
        'ipsclevel-0': 'תחרות לא מאושרת',
        'ipsclevel-1': 'תחרות רמה 1',
        'ipsclevel-1-desc': 'מאושרת ע״י ההתאחדות',
        'ipsclevel-2': 'תחרות רמה 2',
        'ipsclevel-2-desc': 'מאושרת ע״י ההתאחדות',
        'ipsclevel-3': 'אליפות אזורית',
        'ipsclevel-3-desc': 'מאושרת IPSC',
        'ipsclevel-4': 'אליפות יבשת',
        'ipsclevel-4-desc': 'מאושרת IPSC',
        'ipsclevel-5': 'The World Shoots',
        'ipsclevel-5-desc': 'מאושרת IPSC',
    },
};
