const lang = {
    en: {
        'title-match': 'Match director or range officers?',
        'description-match': 'Enter your password to access the match management pages',
        'title-club': 'Club manager or Regional Manager?',
        'description-club': 'Enter your password to access the club management pages',
        'title-region': 'Regional manager?',
        'description-region': 'Enter your password to access the region management pages',
        password: 'Password',
        error: 'Hmm... that\'s not the right password',
        demomessage: 'Demo site? The password is \'password\'.',
        'google-error': 'This user doesn\'t seems to have permissions to this site. Either use a different account, or login using the password.',
        'google-useraccount': 'Use this account',
        'facebook-error': 'This user doesn\'t seems to have permissions to this site. Either use a different account, or login using the password.',
        'facebook-useraccount': 'Use this account',
        'sign-in-with-google': 'Sign in with Google',
        'sign-in-with-facebook': 'Sign in with Facebook',
        'sign-in-with-apple': 'Sign in with Apple',
        'connected-using-google': 'Connected using Google:',
        'connected-using-facebook': 'Connected using Facebook:',
        'sign-in-not-me': '(That\'s not me)',
    },
    he: {
        'title-match': 'שופטים או בעלי תפקיד?',
        'description-match': 'הכניסו סיסמא להכנס לעמודי ניהול התחרות',
        'title-club': 'מנהלי מועדון או מחוז?',
        'description-club': 'הכניסו סיסמא להכנס לעמודי ניהול המועדון',
        'title-region': 'מנהלים אזוריים?',
        'description-region': 'הכניסו סיסמא להכנס לעמודי ניהול האזור',
        password: 'סיסמא',
        error: 'הממ... לא נראה שזו הסיסמא הנכונה',
        demomessage: 'אתר הדגמא? סיסמת הניהול היא \'password\'.',
        'google-error': 'לא נראה שלמשתמש הזה יש הרשאות.',
        'google-useraccount': 'התחבר באמצעות חשבון זה',
        'facebook-error': 'לא נראה שלמשתמש הזה יש הרשאות.',
        'facebook-useraccount': 'התחבר באמצעות חשבון זה',
        'sign-in-with-google': 'התחבר באמצעות Google',
        'sign-in-with-facebook': 'התחבר באמצעות Facebook',
        'sign-in-with-apple': 'התחבר באמצעות Apple',
        'connected-using-google': 'מחובר באמצעות Google:',
        'connected-using-facebook': 'מחובר באמצעות Facebook:',
        'sign-in-not-me': '(זה לא אני)',
    },
};

lang.iw = {
    ...lang.he,
    ...{
        'title-club': 'מנהלי יחידה או מחוז?',
        'description-club': 'הכניסו סיסמא להכנס לעמודי ניהול היחידה',
    },
};

export default lang;
