const lang = {
    en: {
        title: 'Stages',
        'stage-number': 'Stage {{number}}',
        targets: 'Targets',
        targetspcc: 'Targets (PCC Only)',
        rounds: 'Number of rounds to be scored',
        'rounds-points': '{{rounds}} rounds ({{points}} points)',
        'rounds-points-one-shot': '(one round per paper target)',
        'rounds-points-three-shot': '(three rounds per paper target)',

        ipsctargets_title: 'IPSC targets',
        ipsctargets: '{{count}} IPSC paper target',
        ipsctargets_plural: '{{count}} IPSC paper targets',
        ipsctargetsmini_title: 'IPSC mini targets',
        ipsctargetsmini: '{{count}} IPSC mini paper target',
        ipsctargetsmini_plural: '{{count}} IPSC mini paper targets',
        ipscpoppers_title: 'IPSC poppers',
        ipscpoppers: '{{count}} IPSC popper',
        ipscpoppers_plural: '{{count}} IPSC poppers',
        ipscpoppersmini_title: 'IPSC mini poppers',
        ipscpoppersmini: '{{count}} IPSC mini popper',
        ipscpoppersmini_plural: '{{count}} IPSC mini poppers',
        ipscplates_title: 'IPSC metal plates',
        ipscplates: '{{count}} IPSC metal plate',
        ipscplates_plural: '{{count}} IPSC metal plates',
        ipscmetal_noshoots: '{{count}} IPSC metal penalty target',
        ipscmetal_noshoots_plural: '{{count}} IPSC metal penalty targets',
        ipscpaper_noshoots: '{{count}} IPSC paper penalty target',
        ipscpaper_noshoots_plural: '{{count}} IPSC paper penalty targets',
        ipscpcctenpointspoppers_title: 'IPSC 10 points poppers',
        ipscpcctenpointspoppers: '{{count}} IPSC 10 points popper',
        ipscpcctenpointspoppers_plural: '{{count}} IPSC 10 points poppers',
        ipscpccfrangible_title: 'IPSC frangible targets',
        ipscpccfrangible: '{{count}} IPSC frangible target',
        ipscpccfrangible_plural: '{{count}} IPSC frangible targets',
        ipscpcctenpointsfrangible_title: 'IPSC 10 points frangible targets',
        ipscpcctenpointsfrangible: '{{count}} IPSC 10 points frangible target',
        ipscpcctenpointsfrangible_plural: '{{count}} IPSC 10 points frangible targets',
        csptargets_title: 'CSP targets',
        csptargets: '{{count}} CSP paper target',
        csptargets_plural: '{{count}} CSP paper targets',
        csptargetsmini_title: 'CSP mini targets',
        csptargetsmini: '{{count}} CSP mini paper target',
        csptargetsmini_plural: '{{count}} CSP mini paper targets',
        csppoppers_title: 'CSP poppers',
        csppoppers: '{{count}} CSP popper',
        csppoppers_plural: '{{count}} CSP poppers',
        csppoppersmini_title: 'CSP mini poppers',
        csppoppersmini: '{{count}} CSP mini popper',
        csppoppersmini_plural: '{{count}} CSP mini poppers',
        cspplates_title: 'CSP metal plates',
        cspplates: '{{count}} CSP metal plate',
        cspplates_plural: '{{count}} CSP metal plates',
        cspmetal_noshoots: '{{count}} CSP metal penalty target',
        cspmetal_noshoots_plural: '{{count}} CSP metal penalty targets',
        csppaper_noshoots: '{{count}} CSP paper penalty target',
        csppaper_noshoots_plural: '{{count}} CSP paper penalty targets',
        csppcctenpointspoppers_title: 'CSP 10 points poppers',
        csppcctenpointspoppers: '{{count}} CSP 10 points popper',
        csppcctenpointspoppers_plural: '{{count}} CSP 10 points poppers',
        csppccfrangible_title: 'CSP frangible targets',
        csppccfrangible: '{{count}} CSP frangible target',
        csppccfrangible_plural: '{{count}} CSP frangible targets',
        csppcctenpointsfrangible_title: 'CSP 10 points frangible targets',
        csppcctenpointsfrangible: '{{count}} CSP 10 points frangible target',
        csppcctenpointsfrangible_plural: '{{count}} CSP 10 points frangible targets',
        noshoots_title: 'penalty target',
        'noshoots-single': '1 penalty target',
        'noshoots-several': 'several penalty targets',
        startposition: 'Start position',
        guncondition: 'Gun condition',
        pcccondition: 'PCC condition',
        start: 'Start',
        procedure: 'Procedure',
        end: 'End',
        comment: 'Comments',
        'default-new-stage-title': 'New Stage',
        'default-new-stage-start-position': 'Anywhere in the designated area.',
        'default-new-stage-gun-condition': '',
        'default-new-stage-pcc-condition': '',
        'default-new-stage-start': 'Audible',
        'default-new-stage-end': 'Last round',
        'default-new-stage-procedure': 'After start signal engage all targets from designated area.',
        areyousure: 'Are you sure you wanted to delete stage {{index}}?',
        published: 'Stages are ready to be seen by shooters',
        'invalid-number-rounds': 'Invalid number of rounds. Should be {{oneShotRounds}}, {{twoShotsRounds}}, or {{threeShotsRounds}}.',
        errorscoringstarted: 'Cannot delete stage after scoring has started. Try canceling the stage from its page instead.',
        savescored: 'Match already started. You should delete this stage\'s scores before changing it.',
        'savescored-force': 'Ok, undestood - Save anyhow.',
        permissiondenied: 'Hmm... you don\'t seem to have permissions to do that.',
        'should-split-label': 'Hot range: This stage is in the same range as another stage - offer RO to split squad to two or three groups.',
        'inactive-label': 'Cancelled stage: Stage should not count for scoring (eg. it has been deleted from the match during the match).',
        inactive: 'Stage is disabled - it is not counted for scoring.',
        bonustargets: 'Bonus targets',
        openimg: 'Open image in new window',
        print: 'Print',
        bonusfieldname: 'Bonus points field name:',
    },
    he: {
        title: 'תרגילים',
        'stage-number': 'תרגיל {{number}}',
        targets: 'מטרות',
        targetspcc: 'מטרות (קראבין בלבד)',
        rounds: 'מספר כדורים לניקוד',
        'rounds-points': '{{rounds}} כדורים ({{points}} נקודות)',
        'rounds-points-one-shot': '(כדור אחד לכל מטרת נייר)',
        'rounds-points-three-shot': '(שלושה כדורים לכל מטרת נייר)',
        ipsctargets_title: 'מטרות נייר',
        ipsctargets_0: '{{count}} מטרת נייר',
        ipsctargets_1: '{{count}} מטרות נייר',
        ipsctargets_2: '{{count}} מטרות נייר',
        ipsctargets_3: '{{count}} מטרות נייר',
        ipsctargetsmini_title: 'מטרות נייר (מיני)',
        ipsctargetsmini_0: '{{count}} מטרה נייר (מיני)',
        ipsctargetsmini_1: '{{count}} מטרות נייר (מיני)',
        ipsctargetsmini_2: '{{count}} מטרות נייר (מיני)',
        ipsctargetsmini_3: '{{count}} מטרות נייר (מיני)',
        ipscpoppers_title: 'פופרים',
        ipscpoppers_0: '{{count}} פופר',
        ipscpoppers_1: '{{count}} פופרים',
        ipscpoppers_2: '{{count}} פופרים',
        ipscpoppers_3: '{{count}} פופרים',
        ipscpoppersmini_title: 'מיני פופרים',
        ipscpoppersmini_0: '{{count}} מיני פופר',
        ipscpoppersmini_1: '{{count}} מיני פופרים',
        ipscpoppersmini_2: '{{count}} מיני פופרים',
        ipscpoppersmini_3: '{{count}} מיני פופרים',
        ipscplates_title: 'צלחות מתכת',
        ipscplates_0: '{{count}} צלחת מתכת',
        ipscplates_1: '{{count}} צלחות מתכת',
        ipscplates_2: '{{count}} צלחות מתכת',
        ipscplates_3: '{{count}} צלחות מתכת',
        ipscmetal_noshoots: 'ברזל אל-ירי',
        ipscmetal_noshoots_0: '{{count}} ברזל אל-ירי',
        ipscmetal_noshoots_1: '{{count}} ברזלי אל-ירי',
        ipscmetal_noshoots_2: '{{count}} ברזלי אל-ירי',
        ipscmetal_noshoots_3: '{{count}} ברזלי אל-ירי',
        ipscpaper_noshoots: 'נייר אל-ירי',
        ipscpaper_noshoots_0: '{{count}} נייר אל-ירי',
        ipscpaper_noshoots_1: '{{count}} ניירות אל-ירי',
        ipscpaper_noshoots_2: '{{count}} ניירות אל-ירי',
        ipscpaper_noshoots_3: '{{count}} ניירות אל-ירי',
        ipscpcctenpointspoppers_title: 'מטרות ברזל 10 נקודות',
        ipscpcctenpointspoppers_0: '{{count}} מטרת ברזל 10 נקודות',
        ipscpcctenpointspoppers_1: '{{count}} מטרות ברזל 10 נקודות',
        ipscpcctenpointspoppers_2: '{{count}} מטרות ברזל 10 נקודות',
        ipscpcctenpointspoppers_3: '{{count}} מטרות ברזל 10 נקודות',
        ipscpccfrangible_title: 'מטרות שבירות',
        ipscpccfrangible_0: '{{count}} מטרה שבירה',
        ipscpccfrangible_1: '{{count}} מטרות שבירה',
        ipscpccfrangible_2: '{{count}} מטרות שבירה',
        ipscpccfrangible_3: '{{count}} מטרות שבירה',
        ipscpcctenpointsfrangible_title: 'מטרות שבירות 10 נקודות',
        ipscpcctenpointsfrangible_0: '{{count}} מטרה שבירה 10 נקודות',
        ipscpcctenpointsfrangible_1: '{{count}} מטרות שבירות 10 נקודות',
        ipscpcctenpointsfrangible_2: '{{count}} מטרות שבירות 10 נקודות',
        ipscpcctenpointsfrangible_3: '{{count}} מטרות שבירות 10 נקודות',
        noshoots_title: 'אל-ירי',
        'noshoots-single': '1 אל-ירי',
        'noshoots-several': 'מספר אל-ירי',
        startposition: 'מצב התחלה',
        guncondition: 'מצב התחלה - אקדח',
        pcccondition: 'מצב התחלה - קרבין',
        start: 'התחלה ב...',
        procedure: 'מהלך תרגיל',
        end: 'סיום ב...',
        comments: 'הערות',
        'default-new-stage-title': 'תרגיל חדש',
        'default-new-stage-start-position': 'יורה מתחיל/ה בכל מקום באזור המתוחם',
        'default-new-stage-gun-condition': '',
        'default-new-stage-pcc-condition': '',
        'default-new-stage-start': 'אות קולי',
        'default-new-stage-end': 'כדור אחרון',
        'default-new-stage-procedure': 'לאחר האות הקולי, מעסיק/ה את כל המטרות הנגלות מהאזור המתחום בלבד',
        areyousure: 'האם אתם בטוחים שרציתם למחוק את תרגיל {{index}}? לא תוכלו להחזיר אותו אם תתחרטו.',
        published: 'התרגילים מוכנים להצגה באתר',
        'invalid-number-rounds': 'מספר הכדורים לא מתאים למספר המטרות. אמור להיות {{oneShotRounds}}, {{twoShotsRounds}} או {{threeShotsRounds}}.',
        errorscoringstarted: 'לא ניתן למחוק תרגיל לאחר שהתחלתם להכניס תוצאות. נסו לבטל את התרגיל מעמוד התרגיל במקום זאת.',
        savescored: 'התחרות כבר החלה. עליך למחוק את כל תוצאות התרגיל לפני שיהיה ניתן לשנות אותו',
        'savescored-force': 'ברור - אבל ארצה לשמור למרות זאת.',
        permissiondenied: 'הממ... לא נראה שיש לך הרשאות לפעולה',
        bonustargets: 'מטרות בונוס',
        openimg: 'הצג תמונה בחלון חדש',
        print: 'הדפס',
        'should-split-label': 'הוט-ריינג׳: התרגיל נמצא באותו טאנל של תרגיל אחר - יש לתת לשופט הקו אפשרות לחלק את הסקווד לשתיים או שלוש קבוצות.',
        'inactive-label': 'תרגיל בוטל: אנחנו לא נתחשב בתוצאות שלו בחישוב התוצאות לתחרות.',
        inactive: 'תרגיל בוטל - איננו מחושב לצורך ניקוד.',
        bonusfieldname: 'ניקוד נוסף (שם שדה)',
    },
};

lang.iw = {
    ...lang.he,
    ...{
        'should-split-label': 'הוט-ריינג׳: התרגיל נמצא באותו טאנל של תרגיל אחר - יש לתת לשופט הקו אפשרות לחלק את המקצה לשתיים או שלוש קבוצות.',
        'default-new-stage-start-position': 'לוחם\\ת מתחיל/ה בכל מקום באזור המתוחם',
    },
};

export default lang;
