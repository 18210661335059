import React, { useEffect, useState } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Refresh from '@material-ui/icons/Refresh';
import blue from '@material-ui/core/colors/blue';
import PushNotification from '../matches/PushNotification';
import HomeAppUserDashboard from './HomeApp.userdashboard';
import Profile from './Profile';
import app from '../AppCommunication';

function HomeAppMeUser(props) {
    const { history } = props;
    const [name, setName] = useState(null);
    const [publicId, setPublicId] = useState(null);
    const [images, setImages] = useState(null);
    const [registrations, setRegistrations] = useState(null);
    const [staff, setStaff] = useState(null);
    const [matches, setMatches] = useState(null);
    const [stages, setStages] = useState(null);
    const [shooters, setShooters] = useState(null);
    const [scores, setScores] = useState(null);

    return (
        <div>
            <Route
                path='/app/1/'
                exact
                render={() => (
                    <HomeAppMeUserInner
                        name={name}
                        setName={setName}
                        publicId={publicId}
                        setPublicId={setPublicId}
                        images={images}
                        setImages={setImages}
                        registrations={registrations}
                        setRegistrations={setRegistrations}
                        staff={staff}
                        setStaff={setStaff}
                        matches={matches}
                        setMatches={setMatches}
                        stages={stages}
                        setStages={setStages}
                        shooters={shooters}
                        setShooters={setShooters}
                        scores={scores}
                        setScores={setScores}
                        onMatchClick={(m) => history.push(`/app/1/${m}${window.location.search}`)}
                        {...props}
                    />
                )}
            />
            <Route
                path='/app/1/:matchId'
                exact
                render={($props) => <HomeAppUserDashboard {..._.omit(props, ['classes'])} {...$props} />}
            />
        </div>
    );
}

HomeAppMeUser.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
};

const userstyles = () => ({
    topbar: {
        width: '100%',
        height: '45px',
        background: 'white',
        display: 'flex',
        alignItems: 'center',
    },
    loggedinwrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        paddingBottom: '56px',
    },
});

export default withStyles(userstyles, { withTheme: true, flip: false })(withTranslation('homeappme')(HomeAppMeUser));

function HomeAppMeUserInner(props) {
    const {
        t, classes, accessToken, mode, onLogout, setRegistrations,
    } = props;
    const [showPushNotifications, setShowPushNotifications] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (mode === 'app') {
            app({
                cmd: { src: 'app', type: 'did-mount', params: { accessToken } },
                callbackName: 'showPushNotifications',
                callback: () => {
                    setShowPushNotifications(true);
                },
            });
        }
    }, [mode, accessToken]);

    return (
        <div className={classes.loggedinwrapper}>
            <div className={classes.topbar}>
                <div style={{ flex: 1 }}>
                    <Button variant='text' color='primary' disabled>
                        <Typography variant='body2' style={{ color: blue[500] }}>
                            {t('me')}
                        </Typography>
                    </Button>
                </div>
                <IconButton color='primary' onClick={() => setRegistrations(null)}><Refresh /></IconButton>
                <IconButton color='secondary' onClick={onLogout}>
                    <i className='fas fa-sign-out-alt' />
                </IconButton>
            </div>
            <Profile {..._.omit(props, ['classes'])} />
            {showPushNotifications && <PushNotification messaging='app-scoring' accessToken={accessToken} onDone={() => setShowPushNotifications(false)} />}
        </div>
    );
}

HomeAppMeUserInner.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    accessToken: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    mode: PropTypes.string,
    setRegistrations: PropTypes.func.isRequired,
    setStages: PropTypes.func.isRequired,
    setMatches: PropTypes.func.isRequired,
    setStaff: PropTypes.func.isRequired,
    setShooters: PropTypes.func.isRequired,
    setScores: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    staff: PropTypes.arrayOf(PropTypes.string),
    registrations: PropTypes.arrayOf(PropTypes.shape({})),
    matches: PropTypes.arrayOf(PropTypes.shape({})),
    scores: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
    stages: PropTypes.arrayOf(PropTypes.shape({})),
    shooters: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
};

HomeAppMeUserInner.defaultProps = {
    mode: null,
    staff: null,
    registrations: null,
    matches: null,
    scores: null,
    stages: null,
    shooters: null,
};
