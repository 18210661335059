import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';

const styles1 = (theme) => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    messageWrapper: {
        width: '100%',
    },
});

const variantIcon = {
    success: CheckCircleIcon,
    error: ErrorIcon,
    info: InfoIcon,
    warning: WarningIcon,
};

function MySnackbarContent(props) {
    const {
        classes, className, message, onClose, variant, ...other
    } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={classNames(classes[variant], className)}
            classes={{ message: classes.messageWrapper }}
            style={{ width: '100%' }}
            aria-describedby='client-snackbar'
            message={(
                <span id='client-snackbar' className={classes.message}>
                    <div className={classes.message} style={{ flex: 1, marginLeft: '12px' }}>
                        <Icon className={classNames(classes.icon, classes.iconVariant)} />
&nbsp;
                        {message}
                    </div>

                    {onClose && (
                        <IconButton
                            key='close'
                            aria-label='Close'
                            color='inherit'
                            className={classes.close}
                            onClick={onClose}
                        >
                            <CloseIcon className={classes.icon} />
                        </IconButton>
                    )}
                </span>
)}
            {...other}
        />
    );
}

MySnackbarContent.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.string.isRequired,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    onClose: PropTypes.func,
};

MySnackbarContent.defaultProps = {
    className: null,
    onClose: null,
};

export const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

function MySnackBar(props) {
    const {
        variant, message, open, onClose,
    } = props;
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={open}
            onClose={onClose}
            autoHideDuration={onClose ? 6000 : 0}
        >
            <MySnackbarContentWrapper
                onClose={onClose}
                variant={variant}
                message={message}
            />
        </Snackbar>
    );
}

MySnackBar.propTypes = {
    variant: PropTypes.string,
    onClose: PropTypes.func,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    open: PropTypes.bool,
};

MySnackBar.defaultProps = {
    variant: null,
    onClose: null,
    message: null,
    open: false,
};

export default MySnackBar;
