import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import {
    ComposableMap,
    ZoomableGroup,
    Geographies,
    Geography,
    Markers,
    Marker,
} from 'react-simple-maps';
import axios from 'axios';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import Header from './Header';
import api from '../Api';

function Map(props) {
    const { classes, history } = props;
    const [markers, setMarkers] = useState(null);
    const [geographies, setGeographies] = useState(null);
    const [matches, setMatches] = useState(null);

    useEffect(() => {
        (async () => {
            const { data: $geographies } = await axios.get('https://raw.githubusercontent.com/d3/d3.github.com/master/world-50m.v1.json');
            setGeographies($geographies);
            const $matches = await api.getMatches();
            await setMatches(Object.keys($matches).map((k) => $matches[k]));
            const $markers = Object.keys($matches).map((k) => $matches[k]).map((m) => ({
                markerOffset: -25,
                name: m.title,
                alias: m.alias,
                region: `${m.countryCode.toLowerCase()}-ipsc`,
                coordinates: m.location ? [m.location.geocode.lng, m.location.geocode.lat] : null,
            })).filter((m) => m.coordinates);
            setMarkers($markers);
        })();
    }, []);

    if (!markers) {
        return (
            <Grid container>
                <Header demo={null} onLoggedIn={null} onLoggedOut={null} />
                <div
                    style={{
                        marginTop: '120px',
                        display: 'flex',
                        minWidth: '980px',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <CircularProgress size={48} />
                </div>
            </Grid>
        );
    }

    return (
        <Grid container>
            <Header demo={null} onLoggedIn={null} onLoggedOut={null} />
            <div
                style={{
                    marginTop: '80px',
                    display: 'flex',
                    minWidth: '980px',
                    width: '100%',
                    alignItems: 'center',
                    background: 'white',
                }}
            >
                <ComposableMap
                    projectionConfig={{ scale: 205 }}
                    width={980}
                    height={551}
                    style={{
                        margin: 'auto',
                        width: '980px',
                        height: 'auto',
                    }}
                >
                    <ZoomableGroup center={[0, 20]} disablePanning>
                        <Geographies geography={geographies}>
                            {($geographies, projection) => $geographies.map((geography) => {
                                if (!matches.find((m) => m.countryCode === geography.properties.ISO_A2)) {
                                    return geography.id !== 'ATA' && (
                                        <Geography
                                            key={geography.id}
                                            geography={geography}
                                            projection={projection}
                                            style={{
                                                default: {
                                                    fill: grey[300],
                                                    stroke: grey[500],
                                                    strokeWidth: 0,
                                                    outline: 'none',
                                                },
                                                hover: {
                                                    fill: grey[400],
                                                    stroke: grey[500],
                                                    strokeWidth: 0,
                                                    outline: 'none',
                                                },
                                                pressed: {
                                                    fill: grey[400],
                                                    stroke: grey[500],
                                                    strokeWidth: 0,
                                                    outline: 'none',
                                                },
                                            }}
                                        />
                                    );
                                }

                                return geography.id !== 'ATA' && (
                                    <Geography
                                        key={geography.id}
                                        geography={geography}
                                        projection={projection}
                                        style={{
                                            default: {
                                                fill: grey[600],
                                                stroke: grey[500],
                                                strokeWidth: 0,
                                                outline: 'none',
                                            },
                                            hover: {
                                                fill: grey[550],
                                                stroke: grey[500],
                                                strokeWidth: 0,
                                                outline: 'none',
                                            },
                                            pressed: {
                                                fill: grey[550],
                                                stroke: grey[500],
                                                strokeWidth: 0,
                                                outline: 'none',
                                            },
                                        }}
                                    />
                                );
                            })}
                        </Geographies>
                        <Markers>
                            {markers.map((marker) => (
                                <Marker key={marker.name} marker={marker}>
                                    <circle
                                        onClick={() => {
                                            history.push(`/regions/${marker.region}`);
                                        }}
                                        className={classes.circle}
                                        style={{ cursor: 'pointer' }}
                                        cx={0}
                                        cy={0}
                                        r={6}
                                    />
                                </Marker>
                            ))}
                        </Markers>
                    </ZoomableGroup>
                </ComposableMap>
            </div>
        </Grid>
    );
}

Map.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    classes: PropTypes.shape({}).isRequired,
};

const styles = () => ({
    circle: {
        fill: red[500],
        stroke: red[700],
        '&:hover': {
            fill: red[600],
        },
    },
});

export default withStyles(styles, { withTheme: true, flip: false })(withTranslation('homemap')(Map));
