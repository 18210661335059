import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import grey from '@material-ui/core/colors/grey';
import Score from '../matches/Score';
import Wrapper from './HomeI18nWrapper';

class HomeScore extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            paper: 8,
            plates: 0,
            bgratio: null,
            loading: false,
        };
    }

    async componentDidMount() {
        const { i18n: $i18n } = this.props;
        document.body.style.direction = 'ltr';
        document.body.setAttribute('dir', 'ltr');
        document.body.classList.remove('rtl');
        document.body.classList.add('ltr');
        $i18n.changeLanguage('en-US');
        moment.locale('en-US');

        const widthRatio = ((window.outerWidth + 40) * window.devicePixelRatio) / 877;
        const heightRatio = ((window.outerHeight + 40) * window.devicePixelRatio) / 585;
        await new Promise((res) => this.setState({ bgratio: Math.max(widthRatio, heightRatio) }, res));
    }

    onPaperTargetChange = (e) => {
        const { errors } = this.state;
        this.setState({ paper: e.target.value, errors: { ...errors, paper: false } });
    }

    onPaperTargetKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.target.blur();
        }
    }

    onPaperTargetBlur = () => {
        const { errors, paper } = this.state;
        this.setState({ paper: paper || '0', errors: { ...errors, paper: Number.isNaN(parseInt(paper || 0, 10)) } });
    }

    onPlatesChange = (e) => {
        const { errors } = this.state;
        this.setState({ plates: e.target.value, errors: { ...errors, plates: false } });
    }

    onPlatesBlur = () => {
        const { errors, plates } = this.state;
        this.setState({ plates: plates || '0', errors: { ...errors, plates: Number.isNaN(parseInt(plates || 0, 10)) } });
    }

    onPlatesKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.target.blur();
        }
    }

    onClick = async () => {
        const {
            paper, plates,
        } = this.state;
        await new Promise((res) => this.setState({ loading: true }, res));
        window.location.href = `/scoring-demo/${parseInt(paper, 10)}:${parseInt(plates, 10)}/stage/1/squad/squad-1`;
    }

    render() {
        const { classes, match: routeMatch, history } = this.props;
        const {
            title, extraButtons, errors, paper, plates, bgratio, loading,
        } = this.state;

        if (routeMatch && routeMatch.params.targets) {
            const paperTargets = parseInt(routeMatch.params.targets.split(':')[0], 10);
            const platesTargets = parseInt(routeMatch.params.targets.split(':')[1], 10);
            return (
                <div>
                    <div style={{
                        position: 'fixed', zIndex: 10, top: '0px', left: '0px', width: '100%', display: 'flex', alignItems: 'center', background: grey[900],
                    }}
                    >
                        <Button style={{ color: 'white' }} onClick={() => history.goBack()}><ChevronLeftIcon /></Button>
                        <div style={{ textAlign: 'center', flex: '1', color: 'white' }}>IPSC Matches</div>
                        <Button style={{ color: 'white', visibility: 'hidden', pointerEvents: 'none' }}><ChevronLeftIcon /></Button>
                    </div>
                    <Grid container spacing={0} alignItems='stretch' style={{ minHeight: 'calc(100vh - 40px)', marginTop: '40px' }}>
                        <Grid item xs={12} md={9} style={{ margin: '0px auto', position: 'relative' }}>
                            <div className={classes.appBar}>
                                <Toolbar className={classes.container}>
                                    <div className={classes.title}>
                                        {title}
                                    </div>
                                    <div className={classes.title}>&nbsp;</div>
                                    {extraButtons || null}
                                </Toolbar>
                            </div>
                            <Score
                                demo
                                matchId=''
                                match={{
                                    id: '',
                                    squads: [{ id: 'squad-1', title: 'Squad 1' }],
                                }}
                                stages={[{
                                    title: 'Demo stage',
                                    rounds: paperTargets * 2 + platesTargets,
                                    targets: {
                                        paper: paperTargets,
                                        plates: platesTargets,
                                        'paper-no-shoots': 100,
                                    },
                                }]}
                                scores={[]}
                                shooters={_.range(10).map((i) => ({
                                    id: `12345678-1234-1234-1234567${i}`,
                                    publicId: `US|${i + 1}`,
                                    club: 'Club',
                                    name: `Shooter ${i + 1}`,
                                    division: 'standard',
                                    category: 'regular',
                                    'power-factor': 'minor',
                                    squad: 'squad-1',
                                }))}
                                auth=''
                                me={{
                                    name: 'John Doe',
                                    permissions: ['score'],
                                }}
                                setTitle={(ti) => { this.setState({ title: ti }); }}
                                setBackButtonVisible={() => {}}
                                setExtraButtons={(eb) => { this.setState({ extraButtons: eb }); }}
                                setSaveButtonCallback={() => {}}
                                setSaveButtonLabel={() => {}}
                            />
                        </Grid>
                    </Grid>
                </div>
            );
        }

        return (
            <div className={classes.wrapper}>
                <div className={classes.background} style={bgratio ? { backgroundSize: '100%', width: `${bgratio * 100}%`, height: `${bgratio * 100}%` } : {}} />
                <Typography variant='h3'>Quick Scoring</Typography>
                <Typography variant='body1' style={{ marginTop: '12px' }}>Try out our scoring app without creating a match</Typography>
                <Typography variant='body1' style={{ marginTop: '24px' }}>How many paper targets does your stage have (up to 16)?</Typography>
                <Input
                    style={{ width: '100px' }}
                    label='Paper targets'
                    onChange={this.onPaperTargetChange}
                    onKeyDown={this.onPaperTargetKeyDown}
                    onBlur={this.onPaperTargetBlur}
                    value={paper}
                    classes={{ underline: classes.inputcore, focused: classes.inputcore, input: classes.input }}
                    error={errors.paper}
                />
                <Typography variant='body1' style={{ marginTop: '24px' }}>And how many metal targets (poppers & plates)?</Typography>
                <Input
                    style={{ width: '100px' }}
                    label='Metal targets'
                    onChange={this.onPlatesChange}
                    onKeyDown={this.onPlatesKeyDown}
                    onBlur={this.onPlatesBlur}
                    value={plates}
                    classes={{ underline: classes.inputcore, focused: classes.inputcore, input: classes.input }}
                    error={errors.plates}
                />
                <Button
                    variant='contained'
                    color='primary'
                    disabled={loading || Number.isNaN(parseInt(paper, 10)) || Number.isNaN(parseInt(plates, 10)) || (parseInt(paper, 10) + parseInt(plates, 10) === 0)}
                    classes={{ root: classes.button }}
                    onClick={this.onClick}
                >
                    {loading && <CircularProgress size={24} />}
                    {!loading && <span>OK</span>}
                </Button>
            </div>
        );
    }
}

HomeScore.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    i18n: PropTypes.shape({
        changeLanguage: PropTypes.func.isRequired,
    }).isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    match: ReactRouterPropTypes.match,
};

HomeScore.defaultProps = {
    match: null,
};

const styles = {
    wrapper: {
        height: '100vh',
        width: '100vw',
        padding: '40px 24px 0px 24px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        '& *': {
            color: 'white',
        },
    },
    lightweight: {
        fontWeight: '100',
    },
    margintop: {
        marginTop: '12px',
    },
    appBar: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderBottom: '0',
        marginBottom: '0',
        width: '100%',
        paddingTop: '10px',
        zIndex: '1029',
        color: '#555555',
        border: '0',
        borderRadius: '3px',
        padding: '10px 0',
        transition: 'all 150ms ease 0s',
        minHeight: '50px',
        display: 'block',
    },
    background: {
        position: 'fixed',
        top: '-20px',
        left: '-20px',
        width: 'calc(100% + 40px)',
        height: 'calc(100% + 40px)',
        background: 'url("/loginbg-blur.png")',
        backgroundSize: 'cover',
        zIndex: -2,
    },
    inputcore: {
        maxWidth: '50%',
        width: '50%',
        marginTop: '12px',
        fontSize: '24px',
        '&:before': {
            borderColor: 'rgba(255, 255, 255, 1)',
        },
        '&:after': {
            borderColor: 'rgba(255, 255, 255, 0.5)',
        },
    },
    input: {
        textAlign: 'center',
    },
    button: {
        marginTop: '24px',
        width: '50%',
        minWidth: '50%',
    },
};

export default Wrapper(withStyles(styles, { withTheme: true, flip: false })(withTranslation('home')(HomeScore)));
