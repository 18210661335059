module.exports = {
    en: {
        areyousure: 'Are you sure you want to delete {{name}}? This action can\'t be undone.',
        registration_end_date: 'Valid until',
        public_id_exists: 'Shooter with this id exists already in your region.',
        add: 'Add',
    },
    he: {
        areyousure: 'האם אתם בטוחים שברצונכם למחוק את {{name}}?',
        registration_end_date: 'בתוקף עד',
        public_id_exists: 'מספר יורה כבר בשימוש באזורכם',
        add: 'הוסף',
    },
};
