import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

class SettingsSquadSelection extends Component {
    shouldComponentUpdate(nextProps) {
        const { squadSelection } = this.props;
        return nextProps.squadSelection !== squadSelection;
    }

    onChange = (e, value) => {
        const { onChange } = this.props;
        onChange({ squadSelection: value });
    }

    render() {
        const { classes, t, squadSelection } = this.props;
        return (
            <Card>
                <CardHeader
                    classes={{ title: classes.cardtitle }}
                    title={t('squadselection')}
                    subheader={t('squadselection-subtitle')}
                />
                <CardContent style={{ paddingTop: '0px' }}>
                    <form className={classes.form}>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <FormControl component='fieldset'>
                                    <RadioGroup
                                        style={{ padding: '0px 15px' }}
                                        value={squadSelection || 'closed'}
                                        onChange={this.onChange}
                                    >
                                        <FormControlLabel value='closed' control={<Radio />} label={t('squad-selection-closed')} />
                                        <FormControlLabel value='during-registration' control={<Radio />} label={t('squad-selection-during-registration')} />
                                        <FormControlLabel value='after-payment' control={<Radio />} label={t('squad-selection-after-payment')} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        );
    }
}

const styles = () => ({
    cardtitle: {
        fontWeight: 300,
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > div': {
            marginTop: '16px',
        },
        '& > div:first-child': {
            marginTop: '0px',
        },
    },
});

SettingsSquadSelection.propTypes = {
    squadSelection: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

SettingsSquadSelection.defaultProps = {
    squadSelection: null,
};

export default withStyles(styles, { withTheme: true })(withTranslation('settings')(SettingsSquadSelection));
