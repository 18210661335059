import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import deepOrange from '@material-ui/core/colors/deepOrange';
import red from '@material-ui/core/colors/red';

class Premium extends Component {
    render() {
        const {
            t, i18n, matchId, package: _package, numShooters = 0, style,
        } = this.props;

        const text = (() => {
            if ((_package === 'free') && (numShooters < 40)) {
                return t('free');
            } if ((_package === 'free') && (numShooters >= 50)) {
                return t('free-used-up');
            } if ((_package === 'free') && (numShooters > 40)) {
                return t('free-about');
            }

            if ((_package === 'small') && (numShooters >= 200)) {
                return t('small-used-up');
            } if ((_package === 'small') && (numShooters > 180)) {
                return t('small-about');
            }
            return '';
        })();
        if (text) {
            return (
                <Paper style={{
                    ...style, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '12px', backgroundColor: deepOrange[50], fontSize: '14px',
                }}
                >
                    {' '}
                    <div style={{
                        flex: 1, textAlign: i18n.dir() === 'rtl' ? 'right' : 'left', color: red[500], fontWeight: 100, letterSpacing: '-1px',
                    }}
                    >
                        {text}
                    </div>
                    <Button
                        component={React.forwardRef((props, ref) => <Link ref={ref} {...props} to={`/${matchId}/manage/upgrade`} />)}
                        color='secondary'
                        variant='contained'
                        size='small'
                        style={{ fontWeight: 400 }}
                    >
                        {t('upgrade')}
                    </Button>
                </Paper>
            );
        }
        return null;
    }
}

Premium.propTypes = {
    i18n: PropTypes.shape({
        dir: PropTypes.func.isRequired,
    }).isRequired,
    matchId: PropTypes.string.isRequired,
    package: PropTypes.string.isRequired,
    style: PropTypes.shape({}).isRequired,
    numShooters: PropTypes.number.isRequired,
};

const styles = {
};

export default withStyles(styles, { withTheme: true })(withTranslation('premium')(Premium));
