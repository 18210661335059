import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import IconChat from '@material-ui/icons/Chat';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import FaceIcon from '@material-ui/icons/Face';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import api from '../Api';

class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            msg: '',
            to: null,
            stageIdx: null,
            sending: false,
            error: false,
            messages: null,
            read: [],
        };
    }

    componentDidMount = async () => {
        const { match } = this.props;
        const staff = await api.getStaff({ matchId: match.id, anonymize: true });
        this.setState({ staff });

        this.messageTimeout = setTimeout(() => this.onUpdateMessages(), 10000);
    }

    shouldComponentUpdate(nextProp, nextState) {
        return !_.isEqual(this.state, nextState);
    }

    async componentDidUpdate(prevProps, prevState) {
        const { me, match, auth } = this.props;
        const { to, messages, read } = this.state;
        if ((to) && (messages) && ((prevState.to !== to) || (_.isEqual(prevState.messages, messages)))) {
            const toMarkRead = _(messages)
                .filter((m) => m.to.publicId === me.publicId && m.from.publicId === to.publicId)
                .filter((m) => read.indexOf(m.id) === -1)
                .map('id')
                .value();
            await api.postMessagesRead({ matchId: match.id, auth, read: toMarkRead });
            await new Promise((res) => this.setState({ read: [...read, ...toMarkRead] }, res));
        }
    }

    componentWillUnmount = () => {
        clearTimeout(this.messageTimeout);
        this.messageTimeout = null;
    }

    onUpdateMessages = async () => {
        const { auth, match, me } = this.props;

        if (!auth) {
            if (this.messageTimeout) {
                this.messageTimeout = setTimeout(() => this.onUpdateMessages(), 10000);
            } else {
                this.messageTimeout = null;
            }
            return;
        }

        try {
            const messages = await api.getMessages({ matchId: match.id, auth });
            if (this.messageTimeout) {
                const read = _(messages)
                    .filter((m) => m.read)
                    .filter((m) => m.to.publicId === me.publicId)
                    .map('id')
                    .value();
                await new Promise((res) => this.setState({ messages, read }, res));
            }
        } catch (e) {
            // Left blank intentionally
        }

        if (this.messageTimeout) {
            this.messageTimeout = setTimeout(() => this.onUpdateMessages(), 10000);
        }
    }

    handleOpen = () => {
        this.setState({
            open: true, to: null, stageIdx: null, msg: '',
        });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    send = async () => {
        const {
            match, auth,
        } = this.props;
        const {
            to, msg,
        } = this.state;
        await this.asyncSetState({ sending: true });
        try {
            await api.postMessage({
                matchId: match.id,
                auth,
                message: {
                    to: {
                        type: 'publicId',
                        publicId: to.publicId,
                    },
                    message: msg,
                },
            });
            await this.asyncSetState({ sending: false, msg: '' });
        } catch (e) {
            await this.asyncSetState({ sending: false, error: 'error_network' });
        }
    }

    asyncSetState = async (state) => new Promise((res) => this.setState(state, res));

    renderMessage(message) {
        const { t, classes, me } = this.props;
        const { to } = this.state;
        const fromMe = message.from.publicId === me.publicId;
        return (
            <Card key={message.id} classes={{ root: classnames([classes.msg, fromMe ? classes.msgfromme : null]) }}>
                <CardHeader
                    classes={{ root: classes.msgheader }}
                    avatar={
                        fromMe ? <FaceIcon /> : <Avatar className={classes.avatarsmall}>{to.name[0]}</Avatar>
                    }
                    subheader={`${fromMe ? t('me') : to.name} - ${moment(message.timestamp).format('lll')}`}
                />
                <CardContent classes={{ root: classes.msgcontent }}>
                    <Typography component='p'>
                        {message.message}
                    </Typography>
                </CardContent>
            </Card>
        );
    }

    renderTo() {
        const {
            staff, messages, read,
        } = this.state;

        const {
            classes, me,
        } = this.props;

        const parts = _([{ name: 'Match Owner', publicId: 'owner' }, ...staff || []])
            .filter((s) => s.publicId !== me.publicId)
            .map((s) => {
                const lastMsg = _(messages)
                    .filter((m) => m.to.publicId === s.publicId || m.from.publicId === s.publicId)
                    .reduce((acc, latest) => (((acc || {}).timestamp || 0) > latest.timestamp ? acc : latest), null);

                const lastUnreadMsg = _(messages)
                    .filter((m) => m.to.publicId === me.publicId && m.from.publicId === s.publicId)
                    .filter((m) => read.indexOf(m.id) === -1)
                    .reduce((acc, latest) => (((acc || {}).timestamp || 0) > latest.timestamp ? acc : latest), null);

                const numUnread = _(messages)
                    .filter((m) => m.to.publicId === me.publicId && m.from.publicId === s.publicId)
                    .filter((m) => read.indexOf(m.id) === -1)
                    .value()
                    .length;

                return {
                    numUnread,
                    lastUnreadMsg,
                    lastMsg,
                    comp: (
                        <Grid item xs={12} key={s.publicId} classes={{ item: classes.contact }} onClick={() => { this.setState({ to: s }); }}>
                            <Grid container>
                                <div>
                                    <Badge badgeContent={numUnread} showZero={false} color='secondary'>
                                        <Avatar className={classes.avatar}>{s.name[0]}</Avatar>
                                    </Badge>
                                </div>
                                <div style={{ flex: 1, width: 'calc(100% - 42px)' }}>
                                    <Grid container classes={{ container: classes.contactlist }}>
                                        <Grid item xs={12}>
                                            <Typography variant='caption' classes={{ root: classes.contactname }}>
                                                {s.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='caption' classes={{ root: classes.contactmsgpreview }}>
                                                {lastMsg ? lastMsg.message : ' '}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    ),
                };
            })
            .sortBy([(s) => -1 * ((s.lastUnreadMsg || {}).timestamp || 0), (s) => -1 * ((s.lastMsg || {}).timestamp || 0)])
            .value();

        return (
            <Grid container>
                {parts.map((p) => p.comp)}
            </Grid>
        );
    }

    renderDialogTitle() {
        return (
            <DialogTitle style={{ padding: '6px 6px', display: 'flex' }} disableTypography>
                <div style={{ flex: 1 }}>&nbsp;</div>
                <IconButton variant='text' onClick={this.handleClose}>
                    <CloseIcon fontSize='small' />
                </IconButton>
            </DialogTitle>
        );
    }

    renderChatTitle() {
        const {
            to,
        } = this.state;

        const {
            classes, i18n,
        } = this.props;

        return (
            <DialogTitle classes={{ root: classes.chattitle }}>
                <Grid container alignItems='center'>
                    <IconButton variant='text' classes={{ root: classes.backbutton }} onClick={() => { this.setState({ to: null }); }}>
                        {i18n.dir() === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                    <div>
                        <Avatar className={classes.avatarsmall}>{to.name[0]}</Avatar>
                    </div>
                    <div style={{ flex: 1 }}>
                        <Grid container classes={{ container: classes.contactlist }} alignItems='center'>
                            <Grid item xs={12}>
                                <Typography variant='caption' classes={{ root: classes.contactname }}>
                                    {to.name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                    <IconButton variant='text' onClick={this.handleClose}>
                        <CloseIcon fontSize='small' />
                    </IconButton>
                </Grid>
            </DialogTitle>
        );
    }

    renderChatFooter() {
        const {
            msg, sending,
        } = this.state;

        const {
            classes, t, i18n,
        } = this.props;

        return (
            <DialogActions classes={{ root: classes.chatfooter }}>
                <TextField
                    fullWidth
                    classes={{ root: classes.textfield }}
                    placeholder={t('msg')}
                    value={msg}
                    onChange={(e) => this.setState({ msg: e.target.value })}
                    onKeyDown={(e) => { if (e.keyCode === 13) { this.send(); } }}
                />
                <IconButton
                    color='primary'
                    variant='contained'
                    onClick={this.send}
                    disabled={sending}
                >
                    {sending && <CircularProgress size={24} />}
                    {!sending && <SendIcon style={{ transform: i18n.dir() === 'ltr' ? 'scaleX(1)' : 'scaleX(-1)' }} />}
                </IconButton>
            </DialogActions>
        );
    }

    renderChat() {
        const {
            to, messages,
        } = this.state;

        const {
            classes,
        } = this.props;

        const msgsToDisplay = _(messages)
            .filter((m) => m.to.publicId === to.publicId || m.from.publicId === to.publicId)
            .sortBy((m) => m.timestamp)
            .value();
        return (
            <div className={classes.chatarea}>
                {msgsToDisplay.map((m) => this.renderMessage(m))}
            </div>
        );
    }

    render() {
        const {
            classes, me, fullScreen,
        } = this.props;
        const {
            open, to, read, messages,
        } = this.state;

        const numUnread = _(messages)
            .filter((m) => m.to.publicId === me.publicId)
            .filter((m) => read.indexOf(m.id) === -1)
            .value()
            .length;

        const button = (
            <IconButton classes={{ root: classes.button }} onClick={this.handleOpen}>
                <Badge classes={{ badge: numUnread ? classes.badge : null }} showZero={false} badgeContent={numUnread} color='secondary'>
                    <IconChat />
                </Badge>
            </IconButton>
        );

        const dialog = (() => {
            if (!messages) {
                return (
                    <Dialog
                        open={open}
                        onClose={this.handleClose}
                        scroll='paper'
                        fullScreen={fullScreen}
                        style={{ overflowX: 'hidden' }}
                    >
                        <CircularProgress size={36} style={{ padding: '24px 96px' }} />
                    </Dialog>
                );
            }
            return (
                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    scroll='paper'
                    fullScreen={fullScreen}
                    style={{ overflowX: 'hidden' }}
                >
                    {!to && this.renderDialogTitle()}
                    {to && this.renderChatTitle()}
                    <DialogContent
                        classes={{ root: 'chat-dialog-content' }}
                        ref={() => {
                            const { to: $to } = this.state;
                            const objDiv = document.getElementsByClassName('chat-dialog-content')[0];
                            if ($to && objDiv) {
                                objDiv.scrollTop = objDiv.scrollHeight;
                            }
                        }}
                    >
                        {!to && this.renderTo()}
                        {to && this.renderChat()}
                    </DialogContent>
                    {to && this.renderChatFooter()}
                </Dialog>
            );
        })();

        return (
            <div>
                {button}
                {dialog}
            </div>
        );
    }
}

Chat.propTypes = {
    i18n: PropTypes.shape({
        dir: PropTypes.func.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
        stages: PropTypes.array.isRequired,
    }).isRequired,
    me: PropTypes.shape({
        name: PropTypes.string.isRequired,
        publicId: PropTypes.string.isRequired,
    }).isRequired,
    auth: PropTypes.string.isRequired,
    fullScreen: PropTypes.bool.isRequired,
};

const styles = () => ({
    avatarsmall: {
        width: '18px',
        height: '18px',
        lineHeight: '18px',
        fontSize: '12px',
    },

    button: {
        marginLeft: '12px',
    },

    tobutton: {
        margin: '12px 6px',
        minWidth: '144px',
    },

    textfield: {
        margin: '0px 24px',
    },

    stagebutton: {
        margin: '12px 6px',
        minWidth: '144px',
    },

    suggestions: {
        marginTop: '12px',
        paddingTop: '12px',
        borderTop: '1px solid rgba(0,0,0,0.1)',
    },

    suggestion: {
        marginBottom: '6px',
        height: '60px',
    },

    error: {
        margin: '24px 0px',
        color: red[500],
    },

    badge: {
        animationName: 'pulse',
        animation: 'pulse 5s infinite',
    },

    contact: {
        padding: '12px 6px',
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        cursor: 'pointer',
        '&:nth-child(1)': {
            borderTop: '1px solid rgba(0,0,0,0.1)',
        },
        '&:hover': {
            background: 'rgba(0,0,0,0.05)',
        },
    },

    contactname: {
        fontWeight: '800',
    },

    contactmsgpreview: {
        fontWeight: '300',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },

    contactlist: {
        marginLeft: '18px',
    },

    chattitle: {
        minWidth: '300px',
        paddingTop: '0px',
        paddingLeft: '0px',
        paddingBottom: '0px',
        boxShadow: '3px 3px 3px rgba(0,0,0,0.2)',
        zIndex: '2',
    },

    chatarea: {
        paddingTop: '12px',
    },

    chatfooter: {
        borderTop: '1px solid rgba(0,0,0,0.2)',
    },

    backbutton: {
        margin: '0px 6px',
    },

    msg: {
        marginBottom: '12px',
    },

    msgfromme: {
        background: green[100],
    },

    msgheader: {
        padding: '6px',
    },

    msgcontent: {
        padding: '0px 12px 6px !important',
    },

    '@keyframes pulse': {
        '0%': { transform: 'scale(1) translate(50%, -50%);' },
        '2%': { transform: 'scale(1.4) translate(50%, -50%);' },
        '5%': { transform: 'scale(.9) translate(50%, -50%);' },
        '8%': { transform: 'scale(1.2) translate(50%, -50%);' },
        '10%': { transform: 'scale(1) translate(50%, -50%);' },
    },
});

export default withMobileDialog()(withStyles(styles, { withTheme: true })(withTranslation('chat')(Chat)));
