module.exports = {
    en: {
        title: 'Local Storage',
        name: 'Storage key',
        description: 'Description',
        size: 'Size',
        areyousure: 'Are you sure you want to erase "{{item}}"?',
        __homeapp_userdashboard_state: 'App quick loading storage (can easily be erased)',
        'scoring-action-backup': 'Scoring clicks history (can easily be erased)',
        'approve-backup': 'Sent scores local backup (erase only between matches, after making sure all scores are submitted)',
        __offline_cache: 'Local stored scores that have yet to be sent to server (don\'t erase this unless you know what you\'re doing!)',
    },
    he: {
        title: 'נתונים לוקליים',
        name: 'שם הנתון',
        description: 'תיאור',
        size: 'גודל',
        areyousure: 'האם בוודאות למחוק את "{{item}}"?',
        __homeapp_userdashboard_state: 'מידע לטעינה מהירה של אפליקציה (ניתן למחוק)',
        'scoring-action-backup': 'היסטוריית לחיצות בעמוד הניקוד (ניתן למחוק)',
        'approve-backup': 'גיבוי לוקלי לתוצאות שנשלחו (עדיף למחוק בין תחרויות, או לאחר ווידוא כי כל התוצאות נשלחו)',
        __offline_cache: 'תוצאות לוקליות שטרם נשלחו לשרת. אסור למחוק!! (אלא אם אתם יודעים מה אתם עושים. אבל באמת יודים.)',
    },
};
