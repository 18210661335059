import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';
import uuidv4 from 'uuid/v4';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import DeleteIcon from '@material-ui/icons/Delete';
import Scoring from '../matches/Score.scoring';

function HomeAppQuickScoreShooter(props) {
    const {
        classes, t, history, data, matchIndex, stageIndex, scoreIndex, setData, mode,
    } = props;

    const [working, setWorking] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    const { auth } = data[matchIndex];
    const matchData = data[matchIndex].match;
    const { shooters } = matchData;
    const stageData = matchData.stages[stageIndex];
    const scoreData = stageData.scores[scoreIndex] || {};

    const [title, setTitle] = useState(scoreData.shooter ? shooters.find((s) => s.id === scoreData.shooter).name : scoreData.title);
    const [score, setScore] = useState(scoreData.score ? {
        ..._.omit(scoreData.score, ['no-shoots']),
        stageIdx: 1,
        target: 0,
        noShoots: scoreData.score['no-shoots'],
        penalties: scoreData.score.penaltiesByType.general,
    } : {
        time: '',
        target: 0,
        scorePerTarget: [],
        poppers: 0,
        noShoots: 0,
        penalties: 0,
        penaltiesByType: {},
    });

    const onUpdateScore = useCallback((type, value) => {
        setScore(($score) => ({ ...$score, [type]: JSON.parse(JSON.stringify(value)) }));
    }, [score]);

    const onDone = useCallback(async () => {
        setWorking(true);
        const { data: login } = await axios(`/api/logins?auth=${auth}`);
        const shooterId = scoreData.shooter || uuidv4();
        const shooter = {
            id: shooterId,
            publicId: `XX|${parseInt(Math.random() * 100000, 10)}`,
            name: title,
            club: 'n/a',
            division: 'open',
            category: 'regular',
            'power-factor': 'minor',
        };
        const $score = {
            ..._.omit(score, ['target', 'noShoots', 'id', 'penalties']),
            stageIdx: stageIndex + 1,
            shooterId,
            'no-shoots': score.noShoots,
            time: parseFloat(score.time),
            penaltiesByType: {
                general: score.penalties,
            },
        };

        if (scoreData.shooter) {
            await axios.patch(`/api/matches/${matchData.id}/shooters/${shooterId}?auth=${login.accessToken}`, _.omit(shooter, ['id']));
            const { data: scoreId } = await axios.post(`/api/matches/${matchData.id}/scores?auth=${login.accessToken}`, $score);
            if (score.id) {
                await axios.delete(`/api/matches/${matchData.id}/scores/${score.id}?auth=${login.accessToken}`);
            }
            $score.id = scoreId;
        } else {
            await axios.post(`/api/matches/${matchData.id}/shooters?auth=${login.accessToken}`, shooter);
            const { data: scoreId } = await axios.post(`/api/matches/${matchData.id}/scores?auth=${login.accessToken}`, $score);
            $score.id = scoreId;
        }

        await setData(($data) => {
            const $$data = JSON.parse(JSON.stringify($data));
            if ($$data[matchIndex].match.stages[stageIndex].scores.length <= scoreIndex) {
                $$data[matchIndex].match.stages[stageIndex].scores.push({});
            }
            $$data[matchIndex].match.shooters = $$data[matchIndex].match.shooters.filter((s) => s.id !== shooter.id);
            $$data[matchIndex].match.shooters.push(shooter);
            $$data[matchIndex].match.stages[stageIndex].scores[scoreIndex].score = $score;
            $$data[matchIndex].match.stages[stageIndex].scores[scoreIndex].shooter = shooter.id;
            return $$data;
        });
        setWorking(false);
        history.goBack();
    }, [score, title]);

    const onDelete = useCallback(async () => {
        const { data: login } = await axios(`/api/logins?auth=${auth}`);
        if (scoreData.shooter) {
            await axios.delete(`/api/matches/${matchData.id}/scores/${score.id}?auth=${login.accessToken}`);
        }

        await setData(($data) => {
            const $$data = JSON.parse(JSON.stringify($data));
            _.pullAt($$data[matchIndex].match.stages[stageIndex].scores, scoreIndex);
            return $$data;
        });
        history.goBack();
    }, []);

    return (
        <div>
            <div className={classes.topbar}>
                <div style={{ flex: 1 }}>
                    <Button variant='text' color='primary' onClick={() => history.go(-3)}>
                        <Typography variant='body2' style={{ color: grey[500] }}>
                            {t('homeapp:quickscore')}
                        </Typography>
                    </Button>
                    <i className='fas fa-chevron-right' style={{ fontSize: '12px', opacity: 0.5 }} />
                    <Button variant='text' color='primary' onClick={() => history.go(-2)}>
                        <Typography variant='body2' style={{ color: grey[500] }}>
                            {matchData.title}
                        </Typography>
                    </Button>
                    <i className='fas fa-chevron-right' style={{ fontSize: '12px', opacity: 0.5 }} />
                    <Button variant='text' color='primary' onClick={() => history.goBack()}>
                        <Typography variant='body2' style={{ color: grey[500] }}>
                            {stageData.title}
                        </Typography>
                    </Button>
                    <i className='fas fa-chevron-right' style={{ fontSize: '12px', opacity: 0.5 }} />
                    <Button variant='text' color='primary' disabled>
                        <Typography variant='body2' style={{ color: blue[500] }}>
                            {scoreData.title}
                        </Typography>
                    </Button>
                </div>
                <IconButton color='secondary' onClick={() => setShowDelete(true)}>
                    <DeleteIcon />
                </IconButton>
            </div>
            {showDelete && (
                <Paper classes={{ root: classes.removequestion }}>
                    <Typography variant='subtitle2' style={{ color: 'inherit' }}>
                        {t('areyousurescore')}
                    </Typography>
                    <div>
                        <Button variant='text' onClick={() => onDelete()}>
                            <Typography variant='body2' style={{ color: 'white' }}>
                                {t('generic:yes')}
                            </Typography>
                        </Button>
                        <Button variant='text' onClick={() => setShowDelete(false)}>
                            <Typography variant='body2' style={{ color: 'white' }}>
                                {t('generic:no')}
                            </Typography>
                        </Button>
                    </div>
                </Paper>
            )}
            <Typography variant='body2' style={{ color: grey[500], margin: '0px 12px', marginTop: '12px' }}>{t('shootertitle')}</Typography>
            <Paper
                style={{
                    textAlign: 'center',
                    margin: '12px',
                    marginTop: '6px',
                    padding: '12px 0px',
                }}
            >
                <TextField
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    onBlur={() => {
                        if (!title) {
                            setTitle(scoreData.title);
                        }
                    }}
                />
            </Paper>
            <Typography variant='body2' style={{ color: grey[500], margin: '0px 12px', marginTop: '12px' }}>{t('shooterscore')}</Typography>
            <Paper style={{ padding: '12px', margin: '12px', marginBottom: '72px' }}>
                <Scoring
                    onDone={() => onDone()}
                    isWorking={working}
                    mode={mode}
                    stageIdx={1}
                    shooterId={scoreData.shooter ? scoreData.shooter : '0'}
                    stages={[{
                        rounds: (stageData.targets.paper || 0) * 2 + (stageData.targets.poppers || 0),
                        targets: {
                            plates: 0,
                            paper: stageData.targets.paper || 0,
                            poppers: stageData.targets.poppers || 0,
                            'paper-no-shoots': 100,
                        },
                    }]}
                    shooters={scoreData.shooter ? shooters : [{ id: '0', name: '' }]}
                    penaltiesByType={{}}
                    specialPenalty={0}
                    target={score.target}
                    validLocalStorage={false}
                    simplifiedPenalties
                    onPenalties={() => {}}
                    onUpdateScore={onUpdateScore}
                    {...score}
                />
            </Paper>
        </div>
    );
}

HomeAppQuickScoreShooter.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    i18n: PropTypes.shape({}).isRequired,
    classes: PropTypes.shape({}).isRequired,
    t: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
        auth: PropTypes.string.isRequired,
        match: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            stages: PropTypes.arrayOf(PropTypes.shape({
                title: PropTypes.string,
                targets: PropTypes.shape({
                    poppers: PropTypes.number,
                    paper: PropTypes.number,
                }),
                scores: PropTypes.arrayOf(PropTypes.shape({})),
            })),
            shooters: PropTypes.arrayOf(PropTypes.shape({
            })),
        }).isRequired,
    })).isRequired,
    setData: PropTypes.func.isRequired,
    matchIndex: PropTypes.number.isRequired,
    stageIndex: PropTypes.number.isRequired,
    scoreIndex: PropTypes.number.isRequired,
    mode: PropTypes.string,
};

HomeAppQuickScoreShooter.defaultProps = {
    mode: '',
};

const styles = () => ({
    topbar: {
        minHeight: '45px',
        background: 'white',
        display: 'flex',
        alignItems: 'center',
    },
    removequestion: {
        textAlign: 'center',
        margin: '12px',
        padding: '12px',
        backgroundColor: red[500],
        color: 'white',
    },
});

export default withStyles(styles, { withTheme: true, flip: false })(withTranslation('homeappquickscore')(HomeAppQuickScoreShooter));
