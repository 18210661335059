/* eslint jsx-a11y/anchor-has-content: 0 */
import './Home.new.css';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import IconCheck from '@material-ui/icons/Done';
import IconClose from '@material-ui/icons/Close';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import GoogleSignIn from '../components/GoogleSignIn';
import FacebookSignIn from '../components/FacebookSignIn';
import Snackbar from '../components/Snackbar';
import AddressInput from '../components/AddressInput';
import Wrapper from './HomeI18nWrapper';
import api from '../Api';

class HomeNew extends Component {
    constructor(props) {
        super(props);
        const { match, accessToken } = props;
        const { state } = match.params || {};

        let title = null;
        let description = null;
        let dateValue = null;
        let alias = null;
        let location = null;
        let registration = null;
        let squadSelection = null;
        let ipscLevel = 2;

        if (state) {
            const parsed = JSON.parse(decodeURIComponent(atob(state)));
            ({
                title, description, dateValue, alias, location, registration, squadSelection, ipscLevel,
            } = parsed);
        }

        this.state = {
            title: title || '',
            description: description || '',
            titleError: false,
            date: dateValue ? moment(parseInt(dateValue, 10)) : moment(),
            auth: accessToken ? [accessToken] : [],
            alias: alias || '',
            ipscLevel,
            aliasError: false,
            aliasChecking: false,
            aliasSuccess: false,
            aliasInvalid: false,
            location: location || {},
            locationError: false,
            errorCreating: false,
            creating: false,
            registration,
            squadSelection,
            acceptTos: false,
            acceptTosIl: false,
        };
    }

    UNSAFE_componentWillMount() {
        const { i18n } = this.props;
        const { location } = this.state;
        if (typeof (document) !== 'undefined') {
            document.body.style.background = 'white';
            if ((location || {}).countryCode === 'IL') {
                document.body.style.direction = 'rtl';
                document.body.setAttribute('dir', 'rtl');
                document.body.classList.remove('ltr');
                document.body.classList.add('rtl');
                i18n.changeLanguage('he-IL');
                moment.locale('he-IL');
            } else {
                document.body.style.direction = 'ltr';
                document.body.setAttribute('dir', 'ltr');
                document.body.classList.remove('rtl');
                document.body.classList.add('ltr');
                i18n.changeLanguage('en-US');
                moment.locale('en-US');
            }
        }
    }

    async componentDidMount() {
        await new Promise((res) => setTimeout(res, 100));

        const { match, accessToken } = this.props;

        if (match.params.state) {
            const { alias } = this.state;
            if (alias) {
                this.checkAlias(alias);
            }
        }

        const steps = this.getSteps();
        console.log(steps);
        if ((accessToken) && (steps[8] === 'during')) {
            this.onDone();
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        const { i18n } = this.props;
        const { alias, location } = this.state;
        if (alias !== prevState.alias) {
            this.checkAlias(alias);
        }
        if ((typeof (document) !== 'undefined') && (location !== prevState.location)) {
            document.body.style.background = 'white';
            if ((location || {}).countryCode === 'IL') {
                document.body.style.direction = 'rtl';
                document.body.setAttribute('dir', 'rtl');
                document.body.classList.remove('ltr');
                document.body.classList.add('rtl');
                i18n.changeLanguage('he-IL');
                moment.locale('he-IL');
            } else {
                document.body.style.direction = 'ltr';
                document.body.setAttribute('dir', 'ltr');
                document.body.classList.remove('rtl');
                document.body.classList.add('ltr');
                i18n.changeLanguage('en-US');
                moment.locale('en-US');
            }
        }
    }

    getSteps() {
        const { match } = this.props;
        const { state } = match.params || {};

        if (state) {
            const parsed = JSON.parse(decodeURIComponent(atob(state)));

            if (parsed.steps) {
                return parsed.steps;
            }
        }

        const steps = [];
        for (let i = 0; i < 10; i++) {
            steps.push('before');
        }
        steps[0] = 'during';

        return steps;
    }

    setSteps(newSteps) {
        const {
            title, alias, location, date, ipscLevel,
        } = this.state;
        const { history } = this.props;
        history.push(`/new/${btoa(encodeURIComponent(JSON.stringify({
            steps: newSteps, title, alias, location, dateValue: date.valueOf(), ipscLevel,
        })))}`);
    }

    onTitle = () => {
        const { title } = this.state;
        if (!title) {
            this.setState({ titleError: true });
            return;
        }
        const $steps = this.getSteps();
        $steps[0] = 'after';
        $steps[1] = 'during';
        this.setState({ alias: _.kebabCase(title) }, () => {
            this.setSteps($steps);
        });
    }

    onDate = () => {
        const { title } = this.state;
        const $steps = this.getSteps();
        $steps[1] = 'after';
        $steps[2] = 'during';
        this.setState({ alias: _.kebabCase(title) }, () => {
            this.setSteps($steps);
        });
    }

    onAddress = () => {
        const {
            title, location, description, date,
        } = this.state;
        if (_.isEmpty(location)) {
            this.setState({ locationError: true });
            return;
        }
        const $steps = this.getSteps();
        $steps[2] = 'after';
        $steps[3] = 'during';

        let newDescription = description;
        if (!newDescription) {
            newDescription = `${location.formatted} | ${date.format('L')}`;
        }

        this.setState({ alias: _.kebabCase(title), description: newDescription }, () => {
            this.setSteps($steps);
        });
    }

    onSkipAddress = () => {
        const { title, description, date } = this.state;
        const $steps = this.getSteps();
        $steps[2] = 'after';
        $steps[3] = 'during';

        let newDescription = description;
        if (!newDescription) {
            newDescription = `${date.format('L')}`;
        }

        this.setState({ location: {}, alias: _.kebabCase(title), description: newDescription }, () => {
            this.setSteps($steps);
        });
    }

    onDescription = () => {
        const { description, title } = this.state;
        if (!description) {
            this.setState({ descriptionError: true });
            return;
        }
        const $steps = this.getSteps();
        $steps[3] = 'after';
        $steps[4] = 'during';
        this.setState({ alias: _.kebabCase(title) }, () => {
            this.setSteps($steps);
        });
    }

    onSkipDescription = () => {
        const { title } = this.state;
        const $steps = this.getSteps();
        $steps[3] = 'after';
        $steps[4] = 'during';
        this.setState({ description: null, descriptionError: false, alias: _.kebabCase(title) }, () => {
            this.setSteps($steps);
        });
    }

    onRegister = (registration) => {
        const $steps = this.getSteps();
        $steps[4] = 'after';
        $steps[5] = 'during';
        this.setState({ registration }, () => {
            this.setSteps($steps);
        });
    }

    onSquadSelection = (squadSelection) => {
        const $steps = this.getSteps();
        $steps[5] = 'after';
        $steps[6] = 'during';
        this.setState({ squadSelection }, () => {
            this.setSteps($steps);
        });
    }

    onLevel = () => {
        const $steps = this.getSteps();
        $steps[6] = 'after';
        $steps[7] = 'during';
        this.setSteps($steps);
    }

    onAlias = () => {
        const { alias, aliasSuccess } = this.state;
        if (!alias) {
            this.setState({ aliasEmptyError: true });
            return;
        }
        if (!aliasSuccess) {
            return;
        }
        const $steps = this.getSteps();
        $steps[7] = 'after';
        $steps[8] = 'during';
        this.setSteps($steps);
    }

    onLoginGoogle = (e) => {
        const { auth } = this.state;
        const $auth = [...auth, `com.google|${e.authResponse.id_token}`];
        this.setState({ auth: $auth });
    }

    onLoginFacebook = (e) => {
        const { auth } = this.state;
        const $auth = [...auth, `com.facebook|${e.accessToken}`];
        this.setState({ auth: $auth });
    }

    onLogout = () => {
        const { auth } = this.state;
        const newAuth = JSON.parse(JSON.stringify(auth));
        newAuth.pop();
        this.setState({ auth: newAuth });
    }

    onDone = async () => {
        const {
            title, description, alias, date, squadSelection, registration, auth, ipscLevel,
        } = this.state;
        try {
            const { location } = this.state;
            await new Promise((res) => this.setState({ creating: true }, res));
            const match = await api.postMatch({
                auth,
                match: {
                    title,
                    description: description || ' ',
                    locale: (location || {}).countryCode === 'IL' ? 'he-IL' : 'en-US',
                    ipscLevel,
                    registrationLimitGSheet: (((location || {}).countryCode === 'IL') && (ipscLevel > 0)) ? 'https://docs.google.com/spreadsheets/d/IPSC-IL' : '',
                    scoreFormDisplayMode: (((location || {}).countryCode === 'IL') && (ipscLevel > 0)) ? 'corona' : 'normal',
                    alias,
                    startDate: {
                        day: date.date(),
                        month: date.month() + 1,
                        year: date.year(),
                    },
                    endDate: {
                        day: date.date(),
                        month: date.month() + 1,
                        year: date.year(),
                    },
                    location: _.isEmpty(location) ? null : {
                        placeId: location.placeId,
                        geocode: location.geocode,
                        formatted: location.formatted,
                    },
                    cover: 'https://storage.googleapis.com/ipsc-1541235451574-stage-images/0.1774547036096603.cover.jpg',
                    squadsPublished: false,
                    scoresPublished: null,
                    stagesPublished: false,
                    registration,
                    squadSelection,
                },
            });
            const $steps = this.getSteps();
            $steps[8] = 'after';
            $steps[9] = 'during';
            this.setState({ match }, () => {
                this.setSteps($steps);
            });

            if (window && window.fbq) {
                window.fbq('track', 'CompleteRegistration');
            }
        } catch (e) {
            console.error(e);
            await new Promise((res) => this.setState({ creating: false, errorCreating: true }, res));
        }
    }

    /* eslint-disable react/no-did-update-set-state */
    async checkAlias(alias) {
        if (!alias) {
            this.setState({ aliasChecking: false, aliasError: false, aliasSuccess: false });
            return;
        }
        if (escape(alias) !== alias) {
            this.setState({
                aliasChecking: false, aliasError: false, aliasSuccess: false, aliasInvalid: true,
            });
            return;
        }
        if (alias.startsWith('demo') || alias === 'new' || alias === 'signup' || alias === 'signin' || encodeURIComponent(alias) !== alias) {
            this.setState({ aliasChecking: false, aliasError: true, aliasSuccess: false });
            return;
        }
        await new Promise((res) => this.setState({ aliasChecking: true, aliasError: false, aliasSuccess: false }, res));
        try {
            await api.getMatch({ matchId: alias });
            const { alias: stateAlias } = this.state;
            if (alias === stateAlias) {
                await new Promise((res) => this.setState({ aliasChecking: false, aliasError: true, aliasSuccess: false }, res));
            }
        } catch (e) {
            const { alias: stateAlias } = this.state;
            if (alias === stateAlias) {
                await new Promise((res) => this.setState({ aliasChecking: false, aliasError: false, aliasSuccess: true }, res));
            }
        }
    }
    /* eslint-enable react/no-did-update-set-state */

    render() {
        const {
            title, titleError, description, descriptionError, date, location, ipscLevel,
            locationError, alias, aliasChecking,
            aliasSuccess, aliasError, aliasEmptyError,
            aliasInvalid, errorCreating, creating, auth,
            acceptTos, acceptTosIl,
        } = this.state;
        const {
            classes, t, history, mode, i18n,
        } = this.props;

        const steps = this.getSteps();

        return (
            <div className='home-new-wrapper'>
                {(mode === 'app') && (
                    <div className={classes.topbar}>
                        <Button color='primary' onClick={() => history.goBack()}><ChevronLeft /></Button>
                        <Typography variant='body2' style={{ textAlign: 'center', flex: 1 }}>Aria</Typography>
                        <Button color='primary' style={{ visibility: 'hidden' }}><ChevronLeft /></Button>
                    </div>
                )}
                <div
                    className={classnames({
                        'home-new-wrapper': true,
                        'home-new-hiddendown': steps[0] === 'before',
                        'home-new-hiddenleft': steps[0] === 'after',
                    })}
                >
                    <Typography variant='h2' className={classnames([classes.lightweight, classes.titles])}>
                        {t('name')}
                    </Typography>
                    <Typography variant='h4' className={classnames([classes.lightweight, classes.titles])}>
                        {t('namesub')}
                    </Typography>
                    <TextField value={title} onChange={(e) => this.setState({ title: e.target.value, titleError: false })} error={titleError} inputProps={{ className: 'home-new-input' }} autoFocus placeholder='eg. Tenerif Open' />
                    <Button color='primary' variant='text' classes={{ root: 'home-new-button' }} onClick={this.onTitle}>{t('generic:next')}</Button>
                </div>

                <div
                    className={classnames({
                        'home-new-wrapper': true,
                        'home-new-hiddenright': steps[1] === 'before',
                        'home-new-hiddenleft': steps[1] === 'after',
                    })}
                >
                    <Typography variant='h2' className={classnames([classes.lightweight, classes.titles])}>
                        {t('when')}
                    </Typography>
                    <Typography variant='h4' className={classnames([classes.lightweight, classes.titles])}>
                        {t('whensub')}
                    </Typography>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                            value={moment(date.valueOf())}
                            onChange={(value) => this.setState({ date: moment(value) })}
                            animateYearScrolling
                            format='LL'
                            minDate={new Date()}
                            TextFieldComponent={(props) => <TextField {...props} InputProps={{ ...props.InputProps, inputProps: { className: 'home-new-input' } }} />}
                        />
                    </MuiPickersUtilsProvider>
                    <Button color='primary' variant='text' classes={{ root: 'home-new-button' }} onClick={this.onDate}>{t('generic:next')}</Button>
                </div>

                <div
                    className={classnames({
                        'home-new-wrapper': true,
                        'home-new-hiddenright': steps[2] === 'before',
                        'home-new-hiddenleft': steps[2] === 'after',
                    })}
                >
                    <Typography variant='h2' className={classnames([classes.lightweight, classes.titles])}>
                        {t('location')}
                    </Typography>
                    <Typography variant='h4' className={classnames([classes.lightweight, classes.titles])}>
                        {t('locationsub')}
                    </Typography>
                    <div className='home-address-input'>
                        <AddressInput
                            onChange={({ address }) => this.setState({ location: address, locationError: false })}
                            error={locationError}
                            address={location}
                            classes={{ map: 'home-address-map' }}
                            textFieldProps={{
                                inputProps: { className: 'home-new-input' },
                            }}
                        />
                    </div>
                    <Button color='primary' variant='text' classes={{ root: 'home-new-button' }} onClick={this.onAddress}>{t('generic:next')}</Button>
                    <Button color='primary' variant='text' classes={{ root: 'home-new-button' }} style={{ marginTop: '0px' }} onClick={() => this.onSkipAddress()}>
                        <Typography variant='caption'>
                            {t('locationskip')}
                        </Typography>
                    </Button>
                </div>

                <div
                    className={classnames({
                        'home-new-wrapper': true,
                        'home-new-hiddenright': steps[3] === 'before',
                        'home-new-hiddenleft': steps[3] === 'after',
                    })}
                >
                    <Typography variant='h2' className={classnames([classes.lightweight, classes.titles])}>
                        {t('description')}
                    </Typography>
                    <Typography variant='h4' className={classnames([classes.lightweight, classes.titles])}>
                        {t('descriptionsub')}
                    </Typography>
                    <TextField value={description} onChange={(e) => this.setState({ description: e.target.value, descriptionError: false })} error={descriptionError} inputProps={{ className: 'home-new-input' }} autoFocus placeholder='eg. Tenerif Open' />
                    <Typography variant='caption' className={classnames([classes.lightweight, classes.titles])}>
                        {t('descriptiontip')}
                    </Typography>
                    <Button color='primary' variant='text' classes={{ root: 'home-new-button' }} onClick={this.onDescription}>{t('generic:next')}</Button>
                    <Button color='primary' variant='text' classes={{ root: 'home-new-button' }} style={{ marginTop: '0px' }} onClick={() => this.onSkipDescription()}>
                        <Typography variant='caption'>
                            {t('descriptionskip')}
                        </Typography>
                    </Button>
                </div>

                <div
                    className={classnames({
                        'home-new-wrapper': true,
                        'home-new-hiddenright': steps[4] === 'before',
                        'home-new-hiddenleft': steps[4] === 'after',
                    })}
                >
                    <Typography variant='h2' className={classnames([classes.lightweight, classes.titles])}>
                        {t('registration')}
                    </Typography>
                    <Typography variant='h4' className={classnames([classes.lightweight, classes.titles])}>
                        {t('registrationsub')}
                    </Typography>
                    <Button color='primary' variant='contained' classes={{ root: 'home-new-button' }} onClick={() => this.onRegister('open')}>{t('registrationopen')}</Button>
                    <Button color='primary' variant='text' classes={{ root: 'home-new-button' }} onClick={() => this.onRegister('closed')}>
                        <Typography variant='caption'>
                            {t('registrationclosed')}
                        </Typography>
                    </Button>
                </div>
                <div
                    className={classnames({
                        'home-new-wrapper': true,
                        'home-new-hiddenright': steps[5] === 'before',
                        'home-new-hiddenleft': steps[5] === 'after',
                    })}
                >
                    <Typography variant='h2' className={classnames([classes.lightweight, classes.titles])}>
                        {t('squad')}
                    </Typography>
                    <Typography variant='h4' className={classnames([classes.lightweight, classes.titles])}>
                        {t('squadsub')}
                    </Typography>
                    <Button color='primary' variant='contained' classes={{ root: 'home-new-button' }} onClick={() => this.onSquadSelection('during-registration')}>{t('squadduring')}</Button>
                    <Button color='primary' variant='text' classes={{ root: 'home-new-button' }} onClick={() => this.onSquadSelection('after-payment')}>
                        <Typography variant='caption' style={{ color: 'inherit' }}>
                            {t('squadafter')}
                        </Typography>
                    </Button>
                    <Button color='primary' variant='text' classes={{ root: 'home-new-button' }} onClick={() => this.onSquadSelection('closed')}>
                        <Typography variant='caption'>
                            {t('squadclose')}
                        </Typography>
                    </Button>
                </div>
                <div
                    className={classnames({
                        'home-new-wrapper': true,
                        'home-new-hiddenright': steps[6] === 'before',
                        'home-new-hiddenleft': steps[6] === 'after',
                    })}
                >
                    <Typography variant='h2' className={classnames([classes.lightweight, classes.titles])}>
                        {t('ipsclevel')}
                    </Typography>
                    <Typography variant='h4' className={classnames([classes.lightweight, classes.titles])}>
                        {t('ipsclevelsub')}
                    </Typography>
                    <Select
                        value={ipscLevel}
                        onChange={(e) => this.setState({ ipscLevel: e.target.value })}
                    >
                        <MenuItem value={0}>
                            <Typography variant='h4' className={classnames([classes.lightweight, classes.titles])}>
                                {t('managehome:ipsclevel0')}
                            </Typography>
                        </MenuItem>
                        <MenuItem value={1}>
                            <Typography variant='h4' className={classnames([classes.lightweight, classes.titles])}>
                                {t('managehome:ipsclevel1')}
                            </Typography>
                        </MenuItem>
                        <MenuItem value={2}>
                            <Typography variant='h4' className={classnames([classes.lightweight, classes.titles])}>
                                {t('managehome:ipsclevel2')}
                            </Typography>
                        </MenuItem>
                        <MenuItem value={3}>
                            <Typography variant='h4' className={classnames([classes.lightweight, classes.titles])}>
                                {t('managehome:ipsclevel3')}
                            </Typography>
                        </MenuItem>
                        <MenuItem value={4}>
                            <Typography variant='h4' className={classnames([classes.lightweight, classes.titles])}>
                                {t('managehome:ipsclevel4')}
                            </Typography>
                        </MenuItem>
                        <MenuItem value={5}>
                            <Typography variant='h4' className={classnames([classes.lightweight, classes.titles])}>
                                {t('managehome:ipsclevel5')}
                            </Typography>
                        </MenuItem>
                    </Select>
                    <Button color='primary' variant='text' classes={{ root: 'home-new-button' }} onClick={this.onLevel}>{t('generic:next')}</Button>
                </div>
                <div
                    className={classnames({
                        'home-new-wrapper': true,
                        'home-new-hiddenright': steps[7] === 'before',
                        'home-new-hiddenleft': steps[7] === 'after',
                    })}
                >
                    <Typography variant='h2' className={classnames([classes.lightweight, classes.titles])}>
                        {t('alias')}
                    </Typography>
                    <Typography variant='h4' className={classnames([classes.lightweight, classes.titles])}>
                        {t('aliassub')}
                    </Typography>
                    <div style={{ direction: 'ltr', display: 'flex', alignItems: 'flex-end' }}>
                        <div className={classnames(['home-new-url-text', classes.lightweight])}>
                            https://www.endofscoring.com/
                        </div>
                        <TextField value={alias} onChange={(e) => this.setState({ alias: e.target.value, aliasEmptyError: false, aliasInvalid: false })} inputProps={{ className: 'home-new-urlinput' }} error={aliasEmptyError || aliasInvalid} placeholder='eg. tenerif-open' />
                        <div className={classnames(['home-new-url-text', classes.lightweight])}>
                            /
                        </div>
                    </div>
                    {aliasSuccess && (
                    <div className={classes.addressstatus} style={{ color: green[500] }}>
                        <IconCheck fontSize='small' />
                        <div>{t('aliassuccess')}</div>
                    </div>
                    )}
                    {aliasChecking && (
                    <div className={classes.addressstatus}>
                        <CircularProgress size={18} />
                    </div>
                    )}
                    {aliasError && (
                    <div className={classes.addressstatus} style={{ color: red[500] }}>
                        <IconClose fontSize='small' />
                        <div>{t('aliaserrortaken')}</div>
                    </div>
                    )}
                    {aliasInvalid && (
                    <div className={classes.addressstatus} style={{ color: red[500] }}>
                        <IconClose fontSize='small' />
                        <div>{t('aliaserrorinvalid')}</div>
                    </div>
                    )}
                    <Button color='primary' variant='text' classes={{ root: 'home-new-button' }} onClick={this.onAlias}>{t('generic:next')}</Button>

                </div>
                <div
                    className={classnames({
                        'home-new-wrapper': true,
                        'home-new-hiddenright': steps[8] === 'before',
                        'home-new-hiddenleft': steps[8] === 'after',
                    })}
                >
                    <Typography variant='h2' className={classnames([classes.lightweight, classes.titles])}>
                        {t('login')}
                    </Typography>
                    <Typography variant='h4' className={classnames([classes.lightweight, classes.titles])}>
                        {t('loginsub')}
                    </Typography>
                    {(auth.length === 0 || (auth[0].startsWith('com.google|') && mode !== 'app')) && (
                    <div style={{ marginTop: '24px' }}>
                        <GoogleSignIn noAuto mode={mode} onLogin={this.onLoginGoogle} onLogout={this.onLogout} />
                    </div>
                    )}
                    {(auth.length === 0 || (auth[0].startsWith('com.facebook|') && mode !== 'app')) && (
                    <div style={{ marginTop: '24px' }}>
                        <FacebookSignIn mode={mode} system='new' matchId={steps[8] === 'during' ? btoa(escape(JSON.stringify(this.state))) : ''} onLogin={this.onLoginFacebook} onLogout={this.onLogout} />
                    </div>
                    )}
                    <div style={{ textAlign: i18n.dir() === 'rtl' ? 'right' : 'left', width: '400px' }}>
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    checked={acceptTos}
                                    onChange={() => { this.setState({ acceptTos: !acceptTos }); }}
                                />
                            )}
                            label={(
                                <div>
                                    <span>
                                        {t('logintermspre')}
                                        &nbsp;
                                    </span>
                                    <a target='_blank' rel='noopener noreferrer' href='https://docs.google.com/document/d/1Saeek_HSNjO4_yW6FvtkQHOAFplhmxEs1Ctd-UMYOto/edit?usp=sharing' style={{ color: 'inherit' }}>{t('loginterms')}</a>
                                    &nbsp;
                                    {t('logintermsand')}
                                    &nbsp;
                                    <a target='_blank' rel='noopener noreferrer' href='https://docs.google.com/document/d/1l7tK7PK0I3BYNuFC104ipvMhWIMETxahUi690oL_Wr0/edit?usp=sharing' style={{ color: 'inherit' }}>{t('logintermsprivacy')}</a>
                                </div>
                            )}
                        />
                    </div>
                    {((location || {}).countryCode === 'IL') && (
                        <div style={{ textAlign: i18n.dir() === 'rtl' ? 'right' : 'left', width: '400px' }}>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        checked={acceptTosIl}
                                        onChange={() => { this.setState({ acceptTosIl: !acceptTosIl }); }}
                                    />
                                )}
                                label={(
                                    <div>
                                        <span>אני מסכים ל</span>
                                        <a target='_blank' rel='noopener noreferrer' href='https://docs.google.com/document/d/1vcmmVPyjv-f2ByJ_t2VVg-2K_KRGbYpdAHxzzObt46c/edit?usp=sharing' style={{ color: 'inherit' }}>תנאי השימוש</a>
                                        &nbsp;של התאחדות הקליעה
                                    </div>
                                )}
                            />
                        </div>
                    )}
                    <Button
                        color='primary'
                        style={{ display: auth.length > 0 ? 'block' : 'none' }}
                        variant='contained'
                        classes={{ root: 'home-new-button' }}
                        onClick={creating ? () => {} : this.onDone}
                        disabled={!acceptTos || (((location || {}).countryCode === 'IL') && !acceptTosIl)}
                    >
                        <div style={{ position: 'relative' }}>
                            <div style={{ opacity: creating ? 0 : 1 }}>{t('logincreate')}</div>
                            <div style={{
                                display: creating ? 'block' : 'none', position: 'absolute', top: 'calc(50% - 9px)', left: 'calc(50% - 9px)', fontSize: '18px',
                            }}
                            >
                                <CircularProgress size={18} color='white' />
                            </div>
                        </div>
                    </Button>
                </div>

                <div
                    className={classnames({
                        'home-new-wrapper': true,
                        'home-new-hiddenright': steps[9] === 'before',
                        'home-new-hiddenleft': steps[9] === 'after',
                    })}
                >
                    <Typography variant='h2' className={classnames([classes.lightweight, classes.titles])}>
                        {t('done')}
                    </Typography>
                    <Typography variant='h4' className={classnames([classes.lightweight, classes.titles])}>
                        {t('donesub')}
                    </Typography>
                    <Typography variant='h4' className={classnames([classes.lightweight, classes.titles, 'home-new-sitefinalurl'])} style={{ marginTop: '12px' }}>
                        {mode !== 'app' && (
                            <a href={`https://www.endofscoring.com/${alias}`} target='_blank' rel='noopener noreferrer'>
                                https://www.endofscoring.com/
                                {alias}
                            </a>
                        )}
                        {mode === 'app' && (
                            <Link to={`/${alias}`}>
                                https://www.endofscoring.com/
                                {alias}
                            </Link>
                        )}
                    </Typography>
                    <Button
                        color='primary'
                        component={React.forwardRef((props, ref) => (
                            mode === 'app'
                                ? <Link ref={ref} to={`https://www.endofscoring.com/${alias}/manage`} {...props} />
                                : <a href={`https://www.endofscoring.com/${alias}/manage`} target='_blank' rel='noopener noreferrer' {...props} />
                        ))}
                        variant='contained'
                        classes={{ root: 'home-new-button' }}
                    >
                        {t('donedashboard')}
                    </Button>
                </div>

                <div className='home-new-smallprints'>
                    <Typography variant='caption'>
                        This site is powered by us at StartSignal Ltd.
                        <br />
                        We have no affiliation whatsoever to IPSC - we&apos;re our own commerical company
                        <br />
                        By using our wonderful system you agree to our basic
                        {' '}
                        <a target='_blank' rel='noopener noreferrer' href='https://docs.google.com/document/d/1Saeek_HSNjO4_yW6FvtkQHOAFplhmxEs1Ctd-UMYOto/edit?usp=sharing' style={{ color: 'inherit' }}>Terms of Service</a>
                        {' '}
                        and
                        {' '}
                        <a target='_blank' rel='noopener noreferrer' href='https://docs.google.com/document/d/1l7tK7PK0I3BYNuFC104ipvMhWIMETxahUi690oL_Wr0/edit?usp=sharing' style={{ color: 'inherit' }}>Privacy Policy</a>
                    </Typography>
                </div>

                <Snackbar
                    open={!!errorCreating}
                    onClose={() => this.setState({ errorCreating: false })}
                    variant='error'
                    message={t('generic:saveerror')}
                />
            </div>
        );
    }
}

const styles = {
    topbar: {
        direction: 'ltr',
        position: 'fixed',
        zIndex: 10,
        top: '0px',
        left: '0px',
        width: '100%',
        height: '45px',
        display: 'flex',
        alignItems: 'center',
        background: 'white',
    },
    lightweight: {
        fontWeight: '100',
    },
    titles: {
        textAlign: 'center',
        margin: '0px 24px',
        marginTop: '12px',
    },
    margintop: {
        marginTop: '12px',
    },
    addressstatus: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '12px',
        minHeight: '24px',
    },
};

HomeNew.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    i18n: PropTypes.shape({
        changeLanguage: PropTypes.func.isRequired,
        dir: PropTypes.func.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
    mode: PropTypes.string,
    accessToken: PropTypes.string,
    match: ReactRouterPropTypes.match.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
};

HomeNew.defaultProps = {
    accessToken: null,
    mode: 'web',
};

export default Wrapper(withStyles(styles, { withTheme: true, flip: false })(withTranslation('homenew')(HomeNew)));
