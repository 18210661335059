import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import ReactFlagsSelect from 'react-flags-select';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import api from '../Api';

class Staff extends Component {
    constructor(props) {
        const { setTitle, t, match } = props;
        super(props);
        setTitle(t('title', { name: match.title }));
        this.state = {
            staff: null,
        };
    }

    async componentDidMount() {
        const {
            t, setTitle, setSaveButtonLabel, setSaveButtonCallback, setExtraButtons, match, auth,
        } = this.props;
        setTitle(t('title', { name: match.title }));

        setSaveButtonLabel(t('generic:save'));
        setSaveButtonCallback(this.handleOnSave);
        setExtraButtons(null);

        const staff = await api.getStaff({
            matchId: match.id,
            auth,
        });
        this.setState({ staff });
    }

    handleOnSave = async ({ setState }) => {
        const { t, auth, match } = this.props;
        const { staff } = this.state;

        if (_.some(staff, (s) => !s.publicId.split('|')[0])) {
            setState({ saveError: t('error-no-staff-region') });
            await new Promise((res) => setTimeout(res, 3000));
            setState({ saving: false, saveSuccess: false, saveError: false });
            return;
        }

        if (_.some(staff, (s) => !s.publicId.split('|')[1])) {
            setState({ saveError: t('error-no-staff-public-id') });
            await new Promise((res) => setTimeout(res, 3000));
            setState({ saving: false, saveSuccess: false, saveError: false });
            return;
        }

        if (_.some(staff, (s) => !s.name)) {
            setState({ saveError: t('error-no-staff-name') });
            await new Promise((res) => setTimeout(res, 3000));
            setState({ saving: false, saveSuccess: false, saveError: false });
            return;
        }

        if (_.some(staff, (s) => !s.user)) {
            setState({ saveError: t('error-no-staff-password') });
            await new Promise((res) => setTimeout(res, 3000));
            setState({ saving: false, saveSuccess: false, saveError: false });
            return;
        }

        if (_(staff).map('user').uniq().size() !== staff.length) {
            setState({ saveError: t('error-duplicate-staff-user') });
            await new Promise((res) => setTimeout(res, 3000));
            setState({ saving: false, saveSuccess: false, saveError: false });
            return;
        }

        if (_(staff).map('publicId').uniq().size() !== staff.length) {
            setState({ saveError: t('error-duplicate-staff-publicId') });
            await new Promise((res) => setTimeout(res, 3000));
            setState({ saving: false, saveSuccess: false, saveError: false });
            return;
        }

        await setState({ saving: true });
        try {
            await api.setStaff({ matchId: match.id, auth, staff });
            await setState({ saving: false, saveSuccess: true });
        } catch (e) {
            await setState({ saving: false, saveError: t('generic:saveerror') });
        }
        await new Promise((res) => setTimeout(res, 3000));
        setState({ saving: false, saveSuccess: false, saveError: false });
    }

    renderPerson(person, idx) {
        const { classes, t, match } = this.props;
        const { staff } = this.state;

        if (person === null) {
            return this.renderAddPersonCard(idx);
        }

        const toggle = (key) => () => {
            const newStaff = JSON.parse(JSON.stringify(staff));
            if ((person.permissions || []).includes(key)) {
                newStaff[idx].permissions = _.without(newStaff[idx].permissions, key);
                this.setState({ staff: newStaff });
            } else {
                newStaff[idx].permissions = [...newStaff[idx].permissions, key];
                this.setState({ staff: newStaff });
            }
        };

        const changeText = (key) => (e) => {
            const newStaff = JSON.parse(JSON.stringify(staff));
            newStaff[idx][key] = e.target.value;
            this.setState({ staff: newStaff });
        };

        const changePublicId = (e) => {
            const newStaff = JSON.parse(JSON.stringify(staff));
            newStaff[idx].publicId = newStaff[idx].publicId || '|';
            newStaff[idx].publicId = `${newStaff[idx].publicId.split('|')[0] || match.region}|${e.target.value}`;
            this.setState({ staff: newStaff });
        };

        const changeRegion = (countryCode) => {
            const newStaff = JSON.parse(JSON.stringify(staff));
            newStaff[idx].publicId = newStaff[idx].publicId || '|';
            newStaff[idx].publicId = `${countryCode}|${newStaff[idx].publicId.split('|')[1]}`;
            this.setState({ staff: newStaff });
        };

        const changePassword = (e) => {
            const newStaff = JSON.parse(JSON.stringify(staff));
            newStaff[idx].user = `com.endofscoring|${match.id}|${e.target.value}`;
            this.setState({ staff: newStaff });
        };

        return (
            <div
                key={idx}
                ref={(e) => {
                    const jumpTo = window && window.location && window.location.hash ? decodeURIComponent(window.location.hash.substr(1)) : null;
                    if ((e) && (jumpTo) && (jumpTo === person.publicId)) {
                        e.scrollIntoView(true);
                        setTimeout(() => {
                            window.history.replaceState(null, null, '#');
                        }, 1000);
                    }
                }}
            >
                <Card className={classes.card} key={idx}>
                    <CardHeader
                        avatar={<Avatar className={classes.avatar}>{person.name[0]}</Avatar>}
                        action={(
                            <IconButton color='secondary' onClick={() => this.setState({ staff: _.without(staff, person) })}>
                                <CloseIcon fontSize='small' />
                            </IconButton>
    )}
                        title={<TextField placeholder={t('name-placeholder')} value={person.name} onChange={changeText('name')} error={!person.name} />}
                        subheader={(
                            <Select classes={{ root: classes.roleselect }} value={person.role} onChange={changeText('role')}>
                                {['ro', 'cro', 'so', 'qm', 'rm', 'md'].map((v) => (
                                    <MenuItem value={v} key={v}>{t(v)}</MenuItem>
                                ))}
                            </Select>
                    )}
                    />
                    <CardContent classes={{ root: classes.cardcontent }}>
                        <FormLabel>{t('generic:region')}</FormLabel>
                        <ReactFlagsSelect defaultCountry={(person.publicId || '').split('|')[0] || match.region} onSelect={changeRegion} />

                        <FormLabel classes={{ root: classes.form }}>{t('generic:shooter_regional_id')}</FormLabel>
                        <TextField
                            value={(person.publicId || '').split('|')[1]}
                            error={!(person.publicId || '').split('|')[1]}
                            onChange={changePublicId}
                        />

                        <FormLabel classes={{ root: classes.form }}>{t('password')}</FormLabel>
                        <TextField
                            value={person.user.split('|')[2]}
                            error={!person.user.split('|')[2]}
                            onChange={changePassword}
                        />
                        <FormControl classes={{ root: classes.form }}>
                            <FormLabel>{t('permissions', { name: person.name || t('default-name') })}</FormLabel>
                            <FormHelperText>{t(`${person.role}-recommendation`)}</FormHelperText>
                            <FormGroup row>
                                {['manage', 'squads', 'registration', 'payments', 'registration-verify', 'registration-change-verified', 'stages', 'stages-force', 'score', 'approve-rescoring', 'staff', 'chrono'].map((perm) => (
                                    <FormControlLabel
                                        key={perm}
                                        control={<Checkbox checked={(person.permissions || []).includes(perm)} onChange={toggle(perm)} />}
                                        label={t(perm)}
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    </CardContent>
                </Card>
            </div>
        );
    }

    renderAddPersonCard(idx) {
        const { classes, match } = this.props;
        const { staff } = this.state;

        return (
            <Card className={classnames([classes.card, classes.cardadd])} key={idx}>
                <CardContent classes={{ root: classes.cardcontent }}>
                    <div style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%',
                    }}
                    >
                        <Button
                            variant='fab'
                            color='primary'
                            onClick={() => this.setState({
                                staff: [...staff, {
                                    publicId: `${match.region}|`, name: '', role: 'ro', permissions: [], user: `com.endofscoring|${match.id}|${parseInt(10000 + Math.random() * 10000, 10)}`,
                                }],
                            })}
                        >
                            <AddIcon />
                        </Button>
                    </div>
                </CardContent>
            </Card>
        );
    }

    render() {
        const { match, classes } = this.props;
        const { staff } = this.state;

        if (!staff || !match) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <CircularProgress size={75} />
                </div>
            );
        }

        const renderStaff = (staff.length % 2 === 1) ? [...staff, null] : [...staff, null];

        return (
            <div style={{
                display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '24px',
            }}
            >
                <div className={classes.staffwrapper}>
                    {renderStaff.map((person, idx) => this.renderPerson(person, idx))}
                </div>
            </div>
        );
    }
}

const styles = {
    staffwrapper: {
        display: 'flex',
        width: '90%',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },

    card: {
        margin: '12px',
        width: '400px',
        minHeight: '408px',
    },

    cardadd: {
        background: 'rgba(255, 255, 255, 0.1)',
    },

    cardcontent: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 48px)',
    },

    form: {
        marginTop: '24px',
    },

    roleselect: {
        fontSize: '11px',
    },
};

Staff.propTypes = {
    setTitle: PropTypes.func.isRequired,
    setSaveButtonLabel: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        region: PropTypes.string.isRequired,
    }).isRequired,
    classes: PropTypes.shape({}).isRequired,
    auth: PropTypes.string.isRequired,
};

export default withStyles(styles)(withTranslation('staff')(Staff));
