import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import validator from 'validator';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import red from '@material-ui/core/colors/red';
import api from '../Api';

class SettingsNotifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nonshooteremail: '',
            scoresNonShooterEmailActive: !!((props.notifications || {}).scores || []).find((s) => s.destination !== 'shooter' && s.channel === 'email' && s.active),
            newprinterid: '',
            newprintername: '',
            errors: {},
        };
    }

    async componentDidMount() {
        const { match } = this.props;
        const stages = await api.getStages({ stages: match.stages });
        this.setState({ stages });
    }

    shouldComponentUpdate() {
        return true;
    }

    onToggleScoresShooterEmail = () => {
        const { onChange, notifications = {} } = this.props;
        const { scores = [] } = notifications;
        const scoresShooterEmail = scores.find((s) => s.destination === 'shooter' && s.channel === 'email' && s.active);

        const newScores = [
            ...scores.filter((s) => s.destination !== 'shooter' || s.channel !== 'email'),
            { destination: 'shooter', channel: 'email', active: !scoresShooterEmail },
        ];

        onChange({ notifications: { ...notifications, scores: newScores } });
    }

    onToggleScoresNonShooterEmail = () => {
        const { scoresNonShooterEmailActive } = this.state;
        const { onChange, notifications = {} } = this.props;
        const { scores = [] } = notifications;

        this.setState({ scoresNonShooterEmailActive: !scoresNonShooterEmailActive }, () => {
            const newScores = scores.map((s) => ({ ...s, active: s.destination === 'shooter' ? s.active : !scoresNonShooterEmailActive }));
            onChange({ notifications: { ...notifications, scores: newScores } });
        });
    }

    onNonShooterEmailKeyDown = (e) => {
        const { nonshooteremail } = this.state;
        const { onChange, notifications = {} } = this.props;
        const { scores = [] } = notifications;

        if ((e.keyCode === 13) || (e.keyCode === 32)) {
            if (nonshooteremail.length > 0) {
                const newScores = [
                    ...scores,
                    { destination: _.trim(nonshooteremail), channel: 'email', active: true },
                ];
                this.setState({ nonshooteremail: '' }, () => {
                    onChange({ notifications: { ...notifications, scores: newScores } });
                });
            }
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
        return true;
    }

    handleNonShooterDeleteEmail = (shooter) => {
        const { onChange, notifications = {} } = this.props;
        const { scores = [] } = notifications;

        const newScores = scores.filter((s) => !_.isEqual(s, shooter));
        onChange({ notifications: { ...notifications, scores: newScores } });
    }

    onAddPrinter = async () => {
        const { newprinterid, newprintername } = this.state;
        const { t, onChange, notifications } = this.props;
        const { scores = [] } = notifications;

        /* eslint-disable react/destructuring-assignment,react/no-access-state-in-setstate */
        await new Promise((res) => this.setState({ errors: { ...this.state.errors, newprinter: '' } }, res));

        if (!newprinterid) {
            await new Promise((res) => this.setState({ errors: { ...this.state.errors, newprinterid: true } }, res));
        }
        if (!newprintername) {
            await new Promise((res) => this.setState({ errors: { ...this.state.errors, newprintername: true } }, res));
        }

        if (scores.find((s) => ((s.channel === 'printer') && (s.destination.indexOf(newprinterid) === 0)))) {
            await new Promise((res) => this.setState({ errors: { ...this.state.errors, newprinter: t('new-printer-printer-exists') } }, res));
        }

        if (this.state.errors.newprinterid || this.state.errors.newprintername || this.state.errors.newprinter) {
            return;
        }

        await new Promise((res) => this.setState({ newprinterchecking: true }, res));

        try {
            await api.authorizePrinter({ printerId: newprinterid });

            await new Promise((res) => this.setState({
                newprinterid: '',
                newprintername: '',
                newprinterchecking: false,
            }, res));

            onChange({
                notifications: {
                    ...notifications,
                    scores: [
                        ...scores,
                        { destination: `${newprinterid}|${newprintername}|`, channel: 'printer', active: true },
                    ],
                },
            });
        } catch (e) {
            if (_.get(e, 'response.data.title') === '111|Unknown printer.') {
                this.setState({ errors: { ...this.state.errors, newprinter: t('new-printer-unknown') }, newprinterchecking: false });
            } else if (_.get(e, 'response.data.title') === '8|User is not authorized.') {
                this.setState({ errors: { ...this.state.errors, newprinter: t('new-printer-unauthorized') }, newprinterchecking: false });
            } else {
                this.setState({ errors: { ...this.state.errors, newprinter: t('new-printer-error') }, newprinterchecking: false });
            }
        }
        /* eslint-enable react/destructuring-assignment,react/no-access-state-in-setstate */
    }

    onPrinterCheck = ({ printerId, stageId }) => {
        const { onChange, notifications, match } = this.props;
        const { scores = [] } = notifications;

        const printer = scores.find((s) => s.destination.indexOf(printerId) === 0);
        const [id, name, stagesStr] = printer.destination.split('|');

        let stages = printer.active ? (stagesStr ? stagesStr.split(',') : JSON.parse(JSON.stringify(match.stages))) : [];
        if (stages.indexOf(stageId) > -1) {
            stages = stages.filter((s) => s !== stageId);
        } else {
            stages = [...stages, stageId];
        }

        const newScores = (() => {
            if (stages.length > 0) {
                return scores.map((s) => (s !== printer ? s : ({
                    destination: `${id}|${name}|${stages.join(',')}`,
                    channel: 'printer',
                    active: true,
                })));
            }
            return scores.map((s) => (s !== printer ? s : ({
                destination: `${id}|${name}|`,
                channel: 'printer',
                active: false,
            })));
        })();
        onChange({ notifications: { ...notifications, scores: newScores } });
    }

    onRemovePrinter = async (printerId) => {
        const { onChange, notifications } = this.props;
        const { scores = [] } = notifications;
        const newScores = scores.filter((s) => !((s.destination.indexOf(printerId) > -1) && (s.channel === 'printer')));

        onChange({ notifications: { ...notifications, scores: newScores } });
    }

    renderEmails() {
        const { nonshooteremail, scoresNonShooterEmailActive } = this.state;
        const { classes, t, notifications = {} } = this.props;

        const { scores = [] } = notifications;

        const scoresShooterEmail = !!scores.find((s) => s.destination === 'shooter' && s.channel === 'email' && s.active);
        let scoresNonShooterError = null;
        const scoresNonShooterEmails = scores
            .filter((s) => s.destination !== 'shooter' && s.channel === 'email')
            .map((s) => {
                const valid = validator.isEmail(s.destination);
                if (!valid) {
                    scoresNonShooterError = (
                        <Typography variant='caption' style={{ color: red[500] }}>
                            {t('notifications-email-error')}
                        </Typography>
                    );
                }
                return (
                    <Chip
                        key={s.destination}
                        color={valid ? 'primary' : 'secondary'}
                        label={s.destination}
                        onDelete={() => this.handleNonShooterDeleteEmail(s)}
                    />
                );
            });


        return (
            <Card>
                <CardHeader
                    classes={{ title: classes.cardtitle }}
                    title={t('notifications-email')}
                    subheader={t('notifications-email-subtitle')}
                />
                <CardContent style={{ paddingTop: '0px' }}>
                    <form className={classes.form}>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <Typography variant='subtitle1'>
                                    {t('notifications-scores')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel style={{ margin: '0px' }} control={<Switch color='primary' checked={scoresShooterEmail} onChange={this.onToggleScoresShooterEmail} />} label={t('notifications-scores-shooter-email')} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel style={{ margin: '0px' }} control={<Switch color='primary' checked={scoresNonShooterEmailActive} onChange={this.onToggleScoresNonShooterEmail} />} label={t('notifications-scores-non-shooter-email')} />
                            </Grid>
                            <Grid item xs={12}>
                                {scoresNonShooterEmailActive && scoresNonShooterEmails}
                            </Grid>
                            <Grid item xs={12}>
                                {scoresNonShooterEmailActive && scoresNonShooterError}
                            </Grid>
                            <Grid item xs={12}>
                                {scoresNonShooterEmailActive && (
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            value={nonshooteremail}
                                            onChange={(e) => this.setState({ nonshooteremail: e.target.value })}
                                            onKeyDown={this.onNonShooterEmailKeyDown}
                                            onBlur={this.onNonShooterEmailBlur}
                                            placeholder={t('notifications-enter-email')}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        );
    }

    renderPrinters() {
        const {
            stages, newprinterid, newprintername, newprinterchecking, errors,
        } = this.state;
        const {
            classes, t, notifications = {}, match,
        } = this.props;

        const { scores = [] } = notifications;
        const printers = scores
            .filter((s) => s.channel === 'printer')
            .map((s) => [s.destination.split('|'), s.active])
            .map(([s, active]) => ({ id: s[0], name: s[1], stages: s[2] ? s[2].split(',') : active ? match.stages : [] }));

        return (
            <Card style={{ marginTop: '12px' }}>
                <CardHeader
                    classes={{ title: classes.cardtitle }}
                    title={t('notifications-printers')}
                    subheader={t('notifications-printers-subtitle')}
                />
                <CardContent style={{ paddingTop: '0px' }}>
                    <form className={classes.form}>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <Typography variant='subtitle1'>
                                    {t('notifications-scores')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='subtitle2'>
                                    {t('notifications-configured-printers')}
                                </Typography>
                            </Grid>
                            {printers.length === 0 && (
                                <Grid item xs={12}>
                                    <div style={{
                                        padding: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '2px dashed rgba(0,0,0,0.2)',
                                    }}
                                    >
                                        <Typography variant='h6' style={{ opacity: 0.2 }}>
                                            {t('no-printers-defined')}
                                        </Typography>
                                    </div>
                                </Grid>
                            )}

                            {printers.length > 0 && (
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} sm={3}>
                                            <Typography variant='caption'>{t('printer')}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Typography variant='caption'>{t('printer-stages')}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={1} />
                                    </Grid>
                                </Grid>
                            )}

                            {printers.map((printer) => (
                                <Grid key={printer.id} item xs={12} style={{ borderBottom: '1px solid rgba(0,0,0,0.2)', marginBottom: '12px', paddingBottom: '12px' }}>
                                    <Grid container>
                                        <Grid item xs={12} sm={3}>
                                            <Typography variant='body1'>
                                                {printer.name}
                                            </Typography>
                                            <Typography variant='caption'>
                                                {printer.id}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            {match.stages.map((sId, i) => (
                                                <FormControlLabel
                                                    key={sId}
                                                    control={(
                                                        <Checkbox
                                                            checked={printer.stages.indexOf(sId) > -1}
                                                            onChange={() => this.onPrinterCheck({ printerId: printer.id, stageId: sId })}
                                                        />
)}
                                                    label={(
                                                        <div>
                                                            <Typography variant='caption'>
                                                                {t('generic:stage_number') + (i + 1)}
                                                                {' '}
                                                                (
                                                                {((stages || []).find((s) => s.id === sId) || {}).title}
                                                                )
                                                            </Typography>
                                                        </div>
                                                    )}
                                                />
                                            ))}
                                        </Grid>
                                        <Grid item xs={12} sm={1}>
                                            <Button color='secondary' size='small' onClick={() => this.onRemovePrinter(printer.id)}><CloseIcon /></Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid item xs={12}>
                                <Typography variant='subtitle2' style={{ marginTop: '12px' }}>
                                    {t('notifications-add-printer')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='caption'>
                                    {[t('notifications-add-printer-description').split('###')].map((parts) => (
                                        <span key='inst'>
                                            {parts[0]}
                                            <a href='/gcp-explained.html' target='_blank'>{parts[1]}</a>
                                            {parts[2]}
                                        </span>
                                    ))}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid container spacing={8}>
                                        <Grid item xs={5}>
                                            <TextField
                                                disabled={newprinterchecking}
                                                value={newprinterid}
                                                error={errors.newprinterid}
                                                onChange={(e) => this.setState({ newprinterid: e.target.value, errors: { ...errors, newprinterid: false } })}
                                                fullWidth
                                                label={t('printer-id')}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField
                                                disabled={newprinterchecking}
                                                value={newprintername}
                                                error={errors.newprintername}
                                                onChange={(e) => this.setState({ newprintername: e.target.value, errors: { ...errors, newprintername: false } })}
                                                fullWidth
                                                label={t('printer-name')}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button disabled={newprinterchecking} variant='contained' color='primary' onClick={this.onAddPrinter}>
                                                {newprinterchecking && <CircularProgress size={18} />}
                                                {!newprinterchecking && <AddIcon />}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='caption' style={{ color: red[500] }}>
                                    {errors.newprinter}
                                </Typography>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        );
    }

    render() {
        return (
            <div>
                {this.renderEmails()}
                {this.renderPrinters()}
            </div>
        );
    }
}

const styles = () => ({
    cardtitle: {
        fontWeight: 300,
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > div': {
            marginTop: '16px',
        },
        '& > div:first-child': {
            marginTop: '0px',
        },
    },
});

export default withStyles(styles, { withTheme: true })(withTranslation('settings')(SettingsNotifications));

SettingsNotifications.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({
        stages: PropTypes.array,
    }).isRequired,
    notifications: PropTypes.shape({
        scores: PropTypes.arrayOf(PropTypes.shape({
            destination: PropTypes.string.isRequired,
            channel: PropTypes.string.isRequired,
            active: PropTypes.bool.isRequired,
        })),
    }),
    onChange: PropTypes.func,
};

SettingsNotifications.defaultProps = {
    notifications: {},
    onChange: () => {},
};
