const lang = {
    en: {
        nostages1: 'Scoring with no stages?',
        nostages2: 'You still haven\'t constructed any stages for you to score.',
        nostages3: 'Go to the stages tab or click the button below.',
        nostagescta: 'Create Your First Stage',
        nostagespermission: 'Match direction still hasn\'t constructed any stages for you to score.',
        stage: 'Stage',
        senderror: 'Hmm... we hit a snag. Perhaps the internet is down? Try again in a few moments.',
        'missing-signature': 'Signature is missing to finalize score',
        'ro-missing-signature': 'RO signature is missing to finalize score',
        sendsuccess: 'Perfect - score sent successfully!',
        totalrounds: 'Entered {{num}} rounds out of {{outOf}}',
        score: 'Score',
        time: 'Time',
        pickreason: 'Pick DQ Reason',
        'dq-10-4': '10.4 Accidental Discharge',
        'dq-10-5': '10.5 Unsafe Gun Handling',
        'dq-10-6': '10.6 Unsportsmanlike Conduct',
        'dq-10-7': '10.7 Prohibited Substances',
        'dq-10-4-1': '10.4.1 Shot in an unsafe direction',
        'dq-10-4-2': '10.4.2 Shot to ground within 3 meters of the competitor',
        'dq-10-4-3': '10.4.3 A shot during loading, reloading or unloading a firearm',
        'dq-10-4-4': '10.4.4 A shot during remedial action',
        'dq-10-4-5': '10.4.5 A shot while transfering handgun between hands',
        'dq-10-4-6': '10.4.6 A shot during movement, except whle shooting at targets',
        'dq-10-4-7': '10.4.7 A shot fired to metal plate less than 7 meters',
        'dq-10-5-1': '10.5.1 Handling of gun not in Safety Area',
        'dq-10-5-2': '10.5.2 Muzzle pointing in an unsafe direction',
        'dq-10-5-3': '10.5.3 Competitor dropped the gun during course of fire',
        'dq-10-5-4': '10.5.4 Drawing or holstering gun in tunnel',
        'dq-10-5-5': '10.5.5 Muzzle pointing to competitor\'s body (sweeping)',
        'dq-10-5-6': '10.5.6 Allowing the muzzle of a firearm to point at any part of another person’s body during a course of fire',
        'dq-10-5-7': '10.5.7 Muzzle pointing rearwards beyond a radius of 1 meter from feet during drawing or re-holstering.',
        'dq-10-5-8': '10.5.8 Wearing one or more firearms',
        'dq-10-5-9': '10.5.9 Finger in trigger guard while clearing a malfunction',
        'dq-10-5-10': '10.5.10 Finger in trigger guard while loading, reloading, or unloading',
        'dq-10-5-11': '10.5.11 Finger in trigger guard while moving',
        'dq-10-5-12': '10.5.12 Having a loaded and holstered handgun (without safety / hammer coocked)',
        'dq-10-5-13': '10.5.13 Handling ammunition in safety area',
        'dq-10-5-14': '10.5.14 Having a loaded firearm other than when specifically authorized by a Range Officer',
        'dq-10-5-15': '10.5.15 Retrieving a dropped firearm',
        'dq-10-5-16': '10.5.16 Using prohibited and/or unsafe ammunition',
        'dq-10-6-1': '10.6.1 General unsportsmanlike Conduct',
        'dq-10-6-2': '10.6.2 Intentional removal of eye or ear protection',
        'dq-10-6-3': '10.6.3 Other persons unsportsmanlike conduct',
        'dq-10-7-0': '10.7.x Any use of prohibited substances',
        'pen-1-1-5-2': '1.1.5.2/10.2.4. Failure to comply with mandatory reload/shooting stance (short courses / classifiers)',
        'pen-2-2-1-5': '2.2.1.5. Taking a shortcut outside passageway',
        'pen-4-5-1': '4.5.1. Interfere with range equiptment or surface',
        'pen-8-6-2': '8.6.2. Providing outside assistance to competitor',
        'pen-8-7-1': '8.7.1. Taking sight picture prior to Start Signal',
        'pen-8-7-2': '8.7.2. Using sighting aid during walkthrough',
        'pen-9-1-1': '9.1.1. Approaching targets',
        'pen-9-5-6-7': '9.5.6.7/10.2.7 Failure to engage a target',
        'pen-10-2-1': '10.2.1. Engaging target while beyond fault line',
        'pen-10-2-2': '10.2.2. Failure to comply with a written procedure',
        'pen-10-2-5': '10.2.5. Movement of overhead material in Cooper Tunnel',
        'pen-10-2-6': '10.2.6. Creeping before Start Signal',
        'pen-10-2-8': '10.2.8. Strong/weak hand courses - Touching handgun with other hand',
        'pen-10-2-9': '10.2.9. Level I/II - Returning to a location when not allowed',
        'pen-10-2-10': '10.2.10. Special Penalty (1% - 20%)',
        'pen-10-2-11': '10.2.11. Shots over 1.8m',
        'pen-general': 'Generic Penalty',
        signature: 'Shooter Signature',
        'ro-signature': 'RO Signature',
        targets: 'Paper Targets',
        'end-score': 'End of Scoring',
        match: 'Match Information',
        shooter: 'Shooter Information',
        ro: 'Range Officer',
        pickshooter: 'Pick a shooter to score...',
        picksquad: '... or pick a squad',
        'sort-reset': 'Match Order',
        'sort-by-random': 'Stage Unique Order',
        'sort-by-round-robin': 'IROA Order',
        'save-temporary': 'Temporary Save without Signatures',
        'save-and-send-email': 'Done - Send email & print',
        'plates-summary': 'Out of {{total}} ({{misses}} misses)',
        'plates-summary-no-miss': 'Out of {{total}}',
        'no-shoots-summary': 'Out of {{total}}',
        'invalid-rounds': 'Hmm. You seem to be missing some shots. Are you sure you want to submit?',
        'missing-time': 'You forgot to enter the time it took.',
        'zero-score': 'Did you want to submit a 0 (zero) score?',
        'score-uneditable-error': 'Reshoot/correction hasn\'t been approved by RM',
        'load-local-or-remote': 'Stored data found both on phone, and on server. Which one should we restore from?',
        'load-remote': 'From remote',
        'load-local': 'From phone',
        'should-load-remote': 'Found stored session on server. Should we continue from it?',
        'should-load-local': 'Found stored session on phone. Should we continue from it?',
        'load-nothing': 'Skip',
        dqtime: 'DQ occured at',
        'are-you-sure-misses-and-procedures': 'Shooter really has {{misses}} misses and received {{penalties}} penalties?',
        'are-you-sure-misses': 'Shooter really has {{misses}} misses?',
        'are-you-sure-procedures': 'Shooter really received {{penalties}} penalties?',
        'are-you-sure-delete-score': 'Are you sure you want to delete this score (you can\'t undo this...)?',
        transfer: 'Transfer/swap scores',
        'transfer-approve': 'Are you sure you want to transfer {{src}}\'s score for stage {{stageIdx}} to {{dest}}?',
        'transfer-swap-approve': '{{dest}} already has a score for this stage. What would you like to do?',
        'action-transfer': 'Transfer score from {{src}} to {{dest}}',
        'action-swap': 'Swap scores between {{src}} and {{dest}}',
        'score-summary': '{{timeStr}} seconds, {{alphas}} alpha(s), {{charlies}} charlie{s), {{deltas}} delta(s), {{noShoots}} penalty target(s), and {{penalties}} penalty(ies)',
        gotohistory: 'Scoring Log',
        bonus: '(Bonus)',
        specialpenalty: '{{specialPenalty}}% (by 10.2.10)',
        'penalties-showmore': 'Show more penalties...',
        'penalties-cof': 'During Course of Fire',
        'penalties-others': 'Others',
        'edit-not-in-demo': 'To edit shooter details, and various other cool features, create a match using the "New Match" button in the main screen.',
        'chrono-not-in-demo': 'To schedule a chronograph test, and various other cool features, create a match using the "New Match" button in the main screen.',
        'briefing-not-in-demo': 'To see the stage briefing, and various other cool features, create a match using the "New Match" button in the main screen.',
        shooterhere: 'I, {{ro}}, by clicking the button above, acknowledge that {{shooter}} is present for this stage, and I have validated that the shooter\'s equipment matches the declaration above.',
        'shooterhere-review': 'Shooter is present and validated.',
        'shooternothere-review': 'Shooter is not present for this stage.',
        'previous-shooter': 'Previous shooter',
        'next-shooter': 'Next shooter',
        equipmentcheckdonetitle: 'Yey! All Done!',
        equipmentcheckdonebody: 'That\'s all the people we have listed in this squad. You can now submit their attendance and equipment check, and go ahead to brief them on the stage and score them.',
        equipmentcheckdonebutton: 'Submit & Scoring',
        equipmentcheckdoneredo: 'Let me check them again',
        shooternothere: 'Not here',
        shooteredit: 'Edit details',
        shooterequipmentfailed: 'Equipment not ok',
        shooterequipmentfailedmovetoopen: 'Move to Open',
        shooterequipmentfailedmovetonoscore: 'Don\'t Score Shooter',
        welcome: 'First time with this squad? Read attendance and check their equipment. Not interested, skip directly to scoring.',
        welcomeattendance: 'Attendance & Equipment Check',
        welcomescore: 'Skip to scoring',
        welcomeallattendance: 'You (or someone else) already checked attendance and equipment to this squad. You can go directly to score them, or edit the attendance and equipment check',
        welcomeallattendancescoresquad: 'To Scoring',
        welcomeallattendanceattendance: 'Edit Attendance & Equipment Check',
        stagehasbonuses1: 'Note: This stage has both regular and bonus targets!',
        stagehasbonuses2: 'Bonus targets are {{targets}}.',
        thisisaregularbonus: 'You are currently scoring a',
        thisisaregular: 'regular target',
        thisisabonus: 'bonus target',
        signingwarning: 'Make sure you indeed have {{warning}}',
        signingwarningtargetmisses: '{{count}} target miss',
        signingwarningtargetmisses_plural: '{{count}} target misses',
        signingwarningbonustargetmisses: '{{count}} bonus target miss',
        signingwarningbonustargetmisses_plural: '{{count}} bonus target misses',
        signingwarningmetalmisses: '{{count}} metal plates miss',
        signingwarningmetalmisses_plural: '{{count}} metal plates misses',
        savelocal: 'Hmmm, there seem to be some network problems.',
        savelocalsavelocal: 'Save to Device (sync with network later)',
        nextshooters: 'Shooting next are:',
        nonextshooters: 'All shooters in this squad are done.',
        roundrobinfirststage: '(in stage #1, IROA order and match order are the same)',
        unpaid: 'Payment Required',
        havebluetooth: 'Own a Bluetooth Timer?',
        havebluetoothsearch: 'Search for all devices...',
        havebluetoothsearchresults: 'Here are the devices we\'ve found. Click on your device to connect. (we\'re continuing to search in the background)',
        bluetoothconnected: 'Connected to "{{name}}"',
        bluetoothdisconnect: '(disconnect)',
        bluetoothsetastime: 'Set as Stage Time',
        splitting: 'Need to split the squad?',
        'splitting-all': 'No, I am scoring the entire squad.',
        'splitting-button-02': 'Split squad to two, and score the first half',
        'splitting-button-12': 'Split squad to two, and score the second half',
        'splitting-button-03': 'Split squad to three, and score the first part',
        'splitting-button-13': 'Split squad to three, and score the second part',
        'splitting-button-23': 'Split squad to three, and score the third part',
        addspeedticket: '+ Speed Ticket',
        speedtickets: 'Speed Tickets',
        'scoreformdisplaymode-corona-showdetail': 'Show full details...',
    },
    he: {
        nostages1: 'מנגדים ללא תרגילים?',
        nostages2: 'טרם הגדרתם תרגילים לתחרות.',
        nostages3: 'עברו לעמוד התרגילים, או ליחצו על הכפתור למטה',
        nostagescta: 'ליצירת את התרגיל הראשון',
        nostagespermission: 'מנהל\\ת התחרות טרם הגדיר\\ה תרגילים לתחרות. צרו איתם קשר כך שתוכלו לנקד.',
        stage: 'תרגיל',
        senderror: 'המממ... נתקלנו בבעיה. אולי יש בעיה באינטרנט? נסו שוב עוד כמה רגעים.',
        'missing-signature': 'חסרה חתימה לצורך סיום',
        'ro-missing-signature': 'חסרה חתימת שופט לצורך סיום',
        sendsuccess: 'מעולה - ציון נשלח בהצלחה.',
        totalrounds: 'הכנסתם {{num}} כדורים מתוך {{outOf}}',
        score: 'ניקוד',
        time: 'זמן',
        pickreason: 'בחרו את הסיבה ל-DQ',
        signature: 'חתימת יורה',
        'ro-signature': 'חתימת שופט',
        targets: 'מטרות נייר',
        'end-score': 'סיום ניקוד',
        match: 'התחרות',
        shooter: 'היורה',
        ro: 'שופט קו',
        pickshooter: 'בחרו יורה לניקוד...',
        picksquad: '... או בחרו סקווד',
        'sort-reset': 'סידור רגיל',
        'sort-by-random': 'סידור רנדומלי לתרגיל',
        'sort-by-round-robin': 'סידור IROA',
        'save-temporary': 'שמירה זמנית ללא חתימות',
        'save-and-send-email': 'סיום - שלח אימייל והדפס',
        'plates-summary': 'מתוך {{total}} ({{misses}} מיס)',
        'plates-summary-no-miss': 'מתוך {{total}}',
        'no-shoots-summary': 'מתוך {{total}}',
        'invalid-rounds': 'נראה שלא הכנסת את כל הכדורים שנורו. האם להמשיך בכל זאת?',
        'missing-time': 'שכחתם להכניס את זמן התרגיל',
        'zero-score': 'האם רציתם לתת ליורה ציון אפס?',
        'score-uneditable-error': 'יש להשיג אישור של שופט ראשי לשינוי תוצאה קיימת.',
        'load-local-or-remote': 'נמצא מיגע שמור על הטלפון ובשרת. האם לטעון אחד מהם?',
        'load-remote': 'כן, מהשרת',
        'load-local': 'כן, מהטלפון',
        'should-load-remote': 'נמצא מידע שמור על השרת. האם תרצו שנחזר אותו?',
        'should-load-local': 'נמצא מידע שמור על הטלפון. האם להעתיק אותו ליורה?',
        'load-nothing': 'לא, דלג',
        dqtime: 'פסילה התרחשה ב:',
        'are-you-sure-misses-and-procedures': 'רק לוודא, ליורה {{misses}} מיסים ו-{{penalties}} ענישות?',
        'are-you-sure-misses': 'רק לוודא, ליורה {{misses}} מיסים?',
        'are-you-sure-procedures': 'רק לוודא, באמת רצית לתת {{penalties}} ענישות?',
        'are-you-sure-delete-score': 'האם אתם בטוחים שאתה רוצים למחוק את התוצאה? לא תוכלו לשחזר אותה...',
        transfer: 'העברה\\החלפת תוצאות',
        'transfer-approve': 'אתם עומדים להעביר את התוצאה של {{src}} עבור תרגיל {{stageIdx}} ל{{dest}}. האם אתם בטוחים?',
        'transfer-swap-approve': 'ל-{{dest}} יש כבר תוצאה עבור תרגיל {{stageIdx}}. מה אתם רוצים לעשות?',
        'action-transfer': 'רק העבר את התוצה מ{{src}} ל{{dest}}',
        'action-swap': 'החלף בתוצאות בין {{src}} לבין {{dest}}',
        'score-summary': '{{timeStr}} שניות, {{alphas}} אלפא, {{charlies}} צ׳רלי, {{deltas}} דלתא, {{noShoots}} אל-ירי, ו-{{penalties}} ענישות',
        gotohistory: 'יומן תוצאות',
        bonus: '(בונוס)',
        specialpenalty: '{{specialPenalty}}% (לפי 10.2.10)',
        'penalties-showmore': 'הצג עוד ענישות...',
        'penalties-cof': 'ענישות בזמן תרגיל',
        'penalties-others': 'ענישות נוספות',
        shooterhere: 'אני, {{ro}}, בלחיצה על הכפתור הירוק, מאשר ש{{shooter}} נוכח\\ת בתרגיל, ווידאתי שהציוד שלו\\ה תואם להצהרה למעלה.',
        'previous-shooter': 'קודם\\ת',
        'next-shooter': 'הבא\\ה',
        equipmentcheckdonetitle: 'יש! סיימנו!',
        equipmentcheckdonebody: 'אלה כל האנשים בסקווד הזה. ניתן לשלוח את רשימת הנוכחים ואת רשימת הציוד לשרת, ולהמשיך לתדרוך על התרגיל ולניקוד.',
        equipmentcheckdonebutton: 'שלח ועבור לניקוד',
        equipmentcheckdoneredo: 'שניה, אני רוצה לעבור על הרשימה שוב',
        shooternothere: 'לא כאן',
        shooteredit: 'עריכה',
        shooterequipmentfailed: 'בעיה בבדיקת הציוד',
        shooterequipmentfailedmovetoopen: 'העבר יורה לאופן',
        shooterequipmentfailedmovetonoscore: 'בטל ניקוד ליורה',
        welcome: 'פעם ראשונה עם הסקווד הזה? קראו רשימת נוכחות ובידקו את הציוד שלהם. אין צורך? קפצו ישר לניקוד.',
        welcomeattendance: 'נוכחות ורשימת ציוד',
        welcomescore: 'ישר לניקוד',
        welcomeallattendance: 'אתם או מישהו אחר כבר בדק את הנוכחות לתרגיל זה. ניתן לעבור ישירות לשלב הניקוד, או לערוך את הנוכחות ובדיקת הציוד',
        welcomeallattendancescoresquad: 'לניקוד',
        welcomeallattendanceattendance: 'עריכת נוכחות ורשימת ציוד',
        stagehasbonuses1: 'שימו לב: תרגיל זה מכיל גם מטרות רגילות וגם מטרות בונוס!',
        stagehasbonuses2: 'מטרות הבונוס הן {{targets}}.',
        thisisaregularbonus: 'אתם כרגע מנקדים',
        thisisaregular: 'מטרה רגילה',
        thisisabonus: 'מטרת בונוס',
        signingwarning: 'התוצאה כוללת {{warning}}',
        signingwarningtargetmisses_0: 'מיס אחד במטרות',
        signingwarningtargetmisses_1: '{{count}} מיסים במטרות',
        signingwarningtargetmisses_2: '{{count}} מיסים במטרות',
        signingwarningtargetmisses_3: '{{count}} מיסים במטרות',
        signingwarningbonustargetmisses_0: 'מיס אחד במטרות בונוס',
        signingwarningbonustargetmisses_1: '{{count}} מיסים במטרות בונוס',
        signingwarningbonustargetmisses_2: '{{count}} מיסים במטרות בונוס',
        signingwarningbonustargetmisses_3: '{{count}} מיסים במטרות בונוס',
        signingwarningmetalmisses_0: 'מיס אחד במטרות ברזל',
        signingwarningmetalmisses_1: '{{count}} מיסים במטרות ברזל',
        signingwarningmetalmisses_2: '{{count}} מיסים במטרות ברזל',
        signingwarningmetalmisses_3: '{{count}} מיסים במטרות ברזל',
        savelocal: 'המממ... נראה שיש בעיות תקשורת.',
        savelocalsavelocal: 'שמור על המכשיר (תצטרכו לסנכרן עם השרת אח״כ)',
        nextshooters: 'היורים הבאים הם:',
        nonextshooters: 'כל היורים מהסקווד הזה ירו',
        roundrobinfirststage: '(בתרגיל #1, סידור IROA וסידור רגיל זהים)',
        havebluetooth: 'יש לכם טיימר בלוטותֿ\'?',
        havebluetoothsearch: 'מחפש מכשירים...',
        havebluetoothsearchresults: 'להלן המכשירים שמצאנו. לחצו על המכשיר שלכם להתחבר. (אנחנו ממשיכים לחפש ברקע)',
        bluetoothconnected: 'מחובר ל-{{name}}',
        bluetoothdisconnect: '(התנתק)',
        bluetoothsetastime: 'קבע כזמן התרגיל',
        splitting: 'צריכים לחלק את הסקווד (הוט ריינג׳)?',
        'splitting-all': 'לא, כל הסקווד יורה ביחד',
        'splitting-button-02': 'חלק סקווד לשתי קבוצות, ונקד חצי ראשון של הסקווד',
        'splitting-button-12': 'חלק סקווד לשתי קבוצות, ונקד חצי שני של הסקווד',
        'splitting-button-03': 'חלק סקווד לשלוש קבוצות, ונקד שליש ראשון של הסקווד',
        'splitting-button-13': 'חלק סקווד לשלוש קבוצות, ונקד שליש שני של הסקווד',
        'splitting-button-23': 'חלק סקווד לשלוש קבוצות, ונקד שליש שלישי של הסקווד',
        addspeedticket: '+ ספיד טיקט',
        speedtickets: 'ספיד טיקטים',
        'scoreformdisplaymode-corona-showdetail': 'הצג פרטים מלאים...',
    },
};

lang.iw = {
    ...lang.he,
    ...{
        equipmentcheckdonebody: 'אלה כל האנשים במקצה הזה. ניתן לשלוח את רשימת הנוכחים ואת רשימת הציוד לשרת, ולהמשיך לתדרוך על התרגיל ולניקוד.',
        splitting: 'צריכים לחלק את המקצה (הוט ריינג׳)?',
        'splitting-all': 'לא, כל המקצה יורה ביחד',
        'splitting-button-02': 'חלק מקצה לשתי קבוצות, ונקד חצי ראשון של המקצה',
        'splitting-button-12': 'חלק מקצה לשתי קבוצות, ונקד חצי שני של המקצה',
        'splitting-button-03': 'חלק מקצה לשלוש קבוצות, ונקד שליש ראשון של המקצה',
        'splitting-button-13': 'חלק מקצה לשלוש קבוצות, ונקד שליש שני של המקצה',
        'splitting-button-23': 'חלק מקצה לשלוש קבוצות, ונקד שליש שלישי של המקצה',
        picksquad: '... או בחרו מקצה',
        welcome: 'פעם ראשונה עם המקצה הזה? קראו רשימת נוכחות ובידקו את הציוד שלהם. אין צורך? קפצו ישר לניקוד.',
        nonextshooters: 'כל הלוחמים מהמקצה הזה ירו',
        signature: 'חתימת לוחם\\ת',
        shooter: 'הלוחם\\ת',
        pickshooter: 'בחרו לוחם\\ת לניקוד...',
        'zero-score': 'האם רציתם לתת ללוחם\\ת ציון אפס?',
        'should-load-local': 'נמצא מידע שמור על הטלפון. האם להעתיק אותו ללוחם\\ת?',
        'are-you-sure-misses-and-procedures': 'רק לוודא, ללוחם\\ת {{misses}} מיסים ו-{{penalties}} ענישות?',
        'are-you-sure-misses': 'רק לוודא, ללוחם\\ת {{misses}} מיסים?',
        shooterequipmentfailedmovetoopen: 'העבר לוחם\\ת לאופן',
        shooterequipmentfailedmovetonoscore: 'בטל ניקוד ללוחם\\ת',
        nextshooters: 'הלוחמים הבאים הם:',
    },
};

export default lang;
