import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import ReactFlagsSelect from 'react-flags-select';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import cyan from '@material-ui/core/colors/cyan';
import Registration from './Registration';
import Snackbar from '../components/Snackbar';
import GoogleSignIn from '../components/GoogleSignIn';
import AppleSignIn from '../components/AppleSignIn';
import FacebookSignIn from '../components/FacebookSignIn';
import { getFlagSrc } from './Utils';
import api from '../Api';

class ViewerRegistration extends Component {
    constructor(props) {
        const {
            match, auth, accessToken = null, name = null, user = null,
        } = props;
        super(props);

        this.state = {
            auth: user && accessToken ? accessToken : auth,
            name,
            user,
            myShooters: null,
            myShootersError: false,
            saveSuccess: null,
            pinsignin: false,
            skipFilter: false,
            pinPublicId: '',
            pinCountryCode: match.countryCode || 'US',
            pin: '',
            errors: {},
            nonAuthSavedSuccess: false,
        };
    }

    componentDidMount() {
        const { t, setTitle, ro } = this.props;
        window.addEventListener('message', this.onWindowMessage);
        setTitle(ro ? t('rotitle') : t('title'));

        if (typeof (window) !== 'undefined') {
            window.scrollTo(0, 0);
        }

        const { auth } = this.state;
        if (auth) {
            this.reloadShooters();
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        const { auth } = this.state;
        let reloadShooters = false;

        if (prevState.auth !== auth) {
            reloadShooters = true;
        }

        const { pathname } = this.props;

        if (prevProps.pathname !== pathname) {
            if ((!pathname.endsWith('/new')) && (pathname.indexOf('/existing/') === -1)) {
                reloadShooters = true;
            }
        }

        if (reloadShooters) {
            await this.reloadShooters();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.onWindowMessage);
    }

    onGoogleLogin = (e) => {
        this.setState({
            auth: `com.google|${e.authResponse.id_token}`,
            user: `com.google|${e.email}`,
            name: e.name,
        });
    }

    onFacebookLogin = (e) => {
        this.setState({
            auth: `com.facebook|${e.accessToken}`,
            user: `com.facebook|${e.id}`,
            name: e.name,
        });
    }

    async onRegistrationCode() {
        const { registrationCode, errors } = this.state;
        const { history, match, ro } = this.props;
        const msgUint8 = new TextEncoder().encode(registrationCode);
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

        if (hashHex === (match.registrationCode || {}).value) {
            history.push(`/${match.alias}/${ro ? 'ro-' : ''}registration?registrationCode=${registrationCode}`);
            this.setState({ acceptedRegistrationCode: registrationCode });
        } else {
            this.setState({ errors: { ...errors, registrationCode: true } });
        }
    }

    onPay = (shooterId) => {
        const { paymentUrls } = this.state;
        window.open(paymentUrls[shooterId], '_blank');
    }

    reloadShooters = async ({ expandSuggestions = false } = {}) => {
        const { user, auth, skipFilter } = this.state;
        const { match } = this.props;
        await new Promise((res) => this.setState({ myShooters: null, myShootersError: false }, res));
        let myShooters = null;
        let suggestions = null;
        let scores = null;
        try {
            ([myShooters, scores, suggestions] = await Promise.all([
                api.getShooters({ matchId: match.id, user, auth }),
                (async () => {
                    try {
                        return await api.getScores({ matchId: match.id });
                    } catch (e) {
                        return [];
                    }
                })(),
                auth.indexOf('pin|') === 0 ? [] : api.getShooters({ matchId: '*', user, auth }),
            ]));
        } catch (e) {
            this.setState({ myShootersError: true });
            return;
        }
        const paymentUrls = {};
        await Promise.all(myShooters.map(async (shooter) => {
            if (match.payment !== 'online') return;
            try {
                const payment = await api.getShooterPayment({
                    matchId: match.id,
                    shooterId: shooter.id,
                    user,
                    auth,
                });
                if (match.paymentParams.gateway === 'paypal') {
                    paymentUrls[shooter.id] = payment.links.find((l) => l.rel === 'approval_url').href;
                } else if (match.paymentParams.gateway === 'z-credit') {
                    paymentUrls[shooter.id] = `https://pci.zcredit.co.il/WebControl/Transaction.aspx?GUID=${payment.guid}&DataPackage=${payment.aux}`;
                }
            } catch (e) {
                // Nothing to do...
            }
        }));

        let normailzedSuggestions = (suggestions || [])
            .filter((sug) => skipFilter || (new Date().getTime()) - (sug.created || 0) < (365 / 2) * 24 * 60 * 60 * 1000).map((sug) => {
                const mandatory = _.pick(sug, ['publicId', 'name', 'club', 'email', 'division', 'power-factor', 'category', 'created']);
                const extraFields = _.pick(sug.extraFields || {}, match.registrationExtraFields);
                return { ...mandatory, extraFields };
            });

        normailzedSuggestions = normailzedSuggestions.filter((s) => s.created);
        normailzedSuggestions = normailzedSuggestions.filter((s) => !myShooters.find((s2) => s2.publicId === s.publicId));
        normailzedSuggestions = _.groupBy(normailzedSuggestions, 'publicId');
        normailzedSuggestions = _.mapValues(normailzedSuggestions, (s) => _.sortBy(s, (a) => -1 * a.created));

        const myShootersScores = _.fromPairs(_.map(myShooters, (shooter) => {
            const scoresPerStage = _.fromPairs(_.map(match.stages, (stageId, stageIdx) => {
                const s = scores.filter(($s) => (($s.shooterId === shooter.id) && ($s.stageIdx === (stageIdx + 1)) && !$s.deleted));
                return [stageIdx + 1, s];
            }));
            return [shooter.id, scoresPerStage];
        }));

        const { user: currentUser } = this.state;
        if (currentUser === user) {
            this.setState({
                myShooters,
                myShootersScores,
                paymentUrls,
                suggestions: normailzedSuggestions || [],
                useSuggestion: null,
                expandSuggestionForPublicIds: [],
                expandSuggestions,
            });
        }
    }

    loginWithPin = () => {
        const { pinCountryCode, pinPublicId, pin } = this.state;

        const errors = {};

        if (!pinPublicId) {
            errors.pinPublicId = true;
        }

        if (!pin) {
            errors.pin = true;
        }

        if (!_.isEmpty(errors)) {
            this.setState({ errors });
            return;
        }

        this.setState({
            auth: `pin|${pinCountryCode}|${pinPublicId}|${pin}`,
            user: `pin|${pinCountryCode}|${pinPublicId}`,
            name: '',
        });
    }

    onWindowMessage = (e) => {
        if (e.data.event === 'payment:approved') {
            this.reloadShooters();
        }
    }

    renderLogin() {
        const {
            t, classes, src, mode, matchId, accessToken, match, ro
        } = this.props;
        const {
            errors, pin, pinsignin, pinPublicId, pinCountryCode,
        } = this.state;

        const NewLink = (props) => <Link to={`/${match.alias}/${ro ? 'ro-' : ''}registration/new-no-auth`} {...props} />;

        return (
            <div
                style={{
                    width: '90%', margin: 'auto', display: 'flex', alignItems: 'center', flexDirection: 'column',
                }}
            >
                <Typography variant='h3'>
                    {t('signup-title')}
                </Typography>
                <Typography variant='h6' style={{ margin: '12px', textAlign: 'center' }}>
                    {t('signup-intro')}
                </Typography>
                {!isFacebookApp() && (
                    <div>
                        <GoogleSignIn mode={mode} noAuto onLogin={this.onGoogleLogin} />
                    </div>
                )}
                <div style={{ marginTop: '6px' }}>
                    <FacebookSignIn mode={mode} matchId={matchId} system='client' noAuto={src !== 'facebook'} autoAccessToken={accessToken} onLogin={this.onFacebookLogin} />
                </div>
                {mode === 'app' && (
                    <div style={{ marginTop: '6px' }}>
                        <AppleSignIn mode={mode} noAuto onLogin={this.onAppleLogin} />
                    </div>
                )}
                <Typography variant='caption' style={{ margin: '12px', textAlign: 'center' }}>
                    {t('signup-social')}
                </Typography>
                <div style={{ margin: '12px', marginTop: '0px', textAlign: 'center' }}>
                    <Typography variant='caption'>
                        {t('termsofuse').split('###').map((s, i) => {
                            if (i === 1) {
                                return <a target='_blank' rel='noopener noreferrer' href='https://docs.google.com/document/d/1Saeek_HSNjO4_yW6FvtkQHOAFplhmxEs1Ctd-UMYOto/edit?usp=sharing' style={{ color: 'inherit' }}>{s}</a>;
                            }
                            if (i === 3) {
                                return <a target='_blank' rel='noopener noreferrer' href='https://docs.google.com/document/d/1l7tK7PK0I3BYNuFC104ipvMhWIMETxahUi690oL_Wr0/edit?usp=sharing' style={{ color: 'inherit' }}>{s}</a>;
                            }
                            return <span>{s}</span>;
                        })}
                    </Typography>
                </div>
                <div style={{ marginTop: '12px' }}>
                    <Typography variant='h6'>
                        {t('registernologin-part1')}
                    </Typography>
                </div>
                <div style={{ marginTop: '6px' }}>
                    <Button component={NewLink} variant='text' color='primary' onClick={() => {}}>
                        {t('registernologin-part2')}
                    </Button>
                </div>
                <div style={{ marginTop: '12px' }}>
                    <Typography variant='h6'>
                        {t('thirdparty')}
                    </Typography>
                </div>
                <div style={{ marginTop: '6px' }}>
                    <Typography variant='body1'>
                        {t('thirdpartydescription')}
                    </Typography>
                </div>
                {!pinsignin && (
                    <div style={{ marginTop: '6px' }}>
                        <Button variant='text' color='primary' onClick={() => this.setState({ pinsignin: true })}>
                            {t('signinusingpin')}
                        </Button>
                    </div>
                )}
                {pinsignin && (
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex' }}>
                            <div className={classes.flagwrapper}>
                                <ReactFlagsSelect defaultCountry={pinCountryCode} onSelect={(v) => this.setState({ pinCountryCode: v })} />
                            </div>
                            <TextField
                                error={errors.pinPublicId}
                                label={t('generic:shooter_id')}
                                value={pinPublicId}
                                onChange={(e) => this.setState({
                                    pinPublicId: e.target.value,
                                    errors: { ...errors, pinPublicId: false },
                                })}
                            />
                        </div>
                        <div>
                            <TextField
                                error={errors.pin}
                                style={{ margin: '0px 12px' }}
                                label={t('pincode')}
                                value={pin}
                                onChange={(e) => this.setState({
                                    pin: e.target.value,
                                    errors: { ...errors, pin: false },
                                })}
                            />
                        </div>
                        <Button variant='text' color='primary' onClick={() => this.loginWithPin()}>
                            {t('signin')}
                        </Button>
                    </div>
                )}
            </div>
        );
    }

    renderNoAuthSuccess() {
        const { t, match, ro } = this.props;
        const { name, user, saveSuccess, myShooters } = this.state;

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='h6' style={{ textAlign: 'center', marginTop: '24px', marginBottom: '24px' }}>
                    {t('nonloggedin-new-success-part1')}
                </Typography>
                <Typography variant='body' style={{ textAlign: 'center', marginBottom: '24px' }}>
                    {t('nonloggedin-new-success-part2')}
                </Typography>

                <div style={{ width: '300px', margin: 'auto' }}>
                    <Button
                        onClick={() => {
                            this.setState({ nonAuthSavedSuccess: false });
                            window.location.href = `/${match.alias}/${ro ? 'ro-' : ''}registration`;
                        }}
                        color='primary'
                        variant='contained'
                        style={{ width: '100%', margin: '6px 0px' }}
                    >
                        {t('newregistrationbutton')}
                    </Button>
                </div>
            </div>
        );
    }

    renderLoggedIn() {
        const { t, match, ro } = this.props;
        const { name, user, saveSuccess, myShooters } = this.state;

        const NewLink = (props) => <Link to={`/${match.alias}/${ro ? 'ro-' : ''}registration/new`} {...props} />;

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='h5' style={{ textAlign: 'center' }}>
                    {t('loggedin-title', { name })}
                </Typography>
                {this.renderExisting()}
                {((match.registration === 'open' || match.registration === 'open-code')) && (user.indexOf('pin|') !== 0) && (!ro || (myShooters || []).length === 0) && (
                    <div style={{ width: '300px', margin: 'auto' }}>
                        <Button component={NewLink} color='primary' variant='contained' style={{ width: '100%', margin: '6px 0px' }}>{t('newregistrationbutton')}</Button>
                    </div>
                )}
                <Snackbar
                    open={saveSuccess}
                    onClose={() => this.setState({ saveSuccess: null })}
                    variant='success'
                    message={saveSuccess}
                />
            </div>
        );
    }

    renderNew() {
        const {
            t, classes, history, registrationCode,
        } = this.props;
        const {
            name, auth, suggestions, useSuggestion, expandSuggestions, acceptedRegistrationCode,
            expandSuggestionForPublicIds, skipFilter,
        } = this.state;

        return (
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <Typography variant='h5'>
                    {t('loggedin-title', { name })}
                </Typography>
                <Typography variant='caption' style={{ marginBottom: '12px' }}>
                    {t('loggedin-new')}
                </Typography>
                {suggestions && Object.keys(suggestions).length > 0 && !expandSuggestions && !useSuggestion && (
                    <Paper classes={{ root: classes.loggedinnewmessage }}>
                        <Typography variant='body1'>
                            {t('loggedin-new-useprevious', { num: Object.keys(suggestions).length })}
                        </Typography>
                        <Button onClick={() => this.setState({ expandSuggestions: true, skipFilter: false })}><span style={{ color: 'white' }}>{t('generic:yes')}</span></Button>
                        <Button onClick={() => this.setState({ suggestions: {} })}><span style={{ color: 'white' }}>{t('generic:no')}</span></Button>
                    </Paper>
                )}
                {suggestions && Object.keys(suggestions).length > 0 && expandSuggestions && !useSuggestion && (
                    <div style={{ width: '90%', maxWidth: '720px' }}>
                        {Object.keys(suggestions).sort().map((publicId) => {
                            const ss = expandSuggestionForPublicIds.includes(publicId) ? suggestions[publicId] : [suggestions[publicId][0]];
                            return ss.map((s) => (
                                <div key={s.created}>
                                    <Card style={{ marginTop: '6px' }}>
                                        <CardHeader
                                            avatar={(
                                                <img
                                                    alt={s.publicId.split('|')[0]}
                                                    src={getFlagSrc({ countryCode: s.publicId.split('|')[0], size: 32 })}
                                                />
                                            )}
                                            title={`${s.publicId.split('|')[1]} \\ ${s.name} (${s.club})`}
                                            subheader={`${t(`generic:division-${s.division}`)} | ${t(`registration:category-${s.category}`)} | ${t(`registration:${s['power-factor'] || 'minor'}`)}`}
                                        />
                                        <CardContent>
                                            <div style={{ display: 'flex' }}>
                                                <Typography variant='caption' style={{ opacity: 0.8 }}>
                                                    {t('generic:shooter_email')}
                                                    :
                                                    &nbsp;
                                                </Typography>
                                                <Typography variant='caption'>
                                                    {s.email}
                                                </Typography>
                                            </div>
                                            {_.map(s.extraFields || {}, (v, k) => (
                                                <div style={{ display: 'flex' }}>
                                                    <Typography variant='caption' style={{ opacity: 0.8 }}>
                                                        {t(`generic:${k.split('_optional')[0]}`)}
                                                        :
                                                        &nbsp;
                                                    </Typography>
                                                    <Typography variant='caption'>
                                                        {v}
                                                    </Typography>
                                                </div>
                                            ))}
                                        </CardContent>
                                        <CardActions>
                                            <Button color='primary' onClick={() => this.setState({ useSuggestion: s })}>{t('usethis')}</Button>
                                        </CardActions>
                                    </Card>
                                    {(suggestions[publicId].length > 1) && (!expandSuggestionForPublicIds.includes(publicId)) && (
                                        <Button color='secondary' onClick={() => this.setState({ expandSuggestionForPublicIds: [...expandSuggestionForPublicIds, publicId] })}>{t('expand', { count: suggestions[publicId].length - 1, name: s.name })}</Button>
                                    )}
                                </div>
                            ));
                        })}
                        <Button
                            variant='text'
                            color='secondary'
                            style={{ marginTop: '6px' }}
                            onClick={() => this.setState({ skipFilter: !skipFilter }, () => this.reloadShooters({ expandSuggestions: true }))}
                        >
                            {skipFilter ? t('dontskipfilter') : t('skipfilter')}
                        </Button>
                        <Button
                            style={{ marginTop: '6px' }}
                            onClick={() => this.setState({ expandSuggestions: false, suggestions: [] })}
                            fullWidth
                            color='primary'
                            variant='contained'
                        >
                            {t('addnew')}
                        </Button>
                    </div>
                )}
                {(!suggestions || (Object.keys(suggestions).length === 0 || useSuggestion)) && (
                    <Registration
                        {...this.props}
                        registrationCode={acceptedRegistrationCode || registrationCode}
                        autofill={useSuggestion}
                        setTitle={() => {}}
                        onSuccess={(shooter) => {
                            if (!auth) {
                                this.setState({ nonAuthSavedSuccess: true });
                            } else {
                                this.setState({ saveSuccess: t('loggedin-existing-success', shooter) }, () => history.goBack());
                            }
                        }}
                        auth={auth}
                    />
                )}
            </div>
        );
    }

    renderExisting() {
        const {
            t, match, classes, registrationCode, ro,
        } = this.props;
        const {
            myShooters, myShootersScores, myShootersError, user, acceptedRegistrationCode, auth,
        } = this.state;

        if (myShootersError && user.indexOf('pin|') === 0) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <Typography variant='body1' style={{ marginTop: '12px' }}>
                        {t('incorrectpin')}
                    </Typography>
                    <Button
                        variant='contained'
                        color='primary'
                        style={{ marginTop: '12px' }}
                        onClick={() => this.setState({ auth: null, user: null, name: null })}
                    >
                        {t('generic:back')}
                    </Button>
                </div>
            );
        }

        if (myShooters === null) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    {myShooters === null && (
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <CircularProgress size={75} />
                        </div>
                    )}
                </div>
            );
        }

        if (myShooters.length === 0) {
            if (match.registration !== 'open' && match.registration !== 'open-code') {
                return (
                    <Typography variant='body1' style={{ textAlign: 'center' }}>
                        {t('cannotfindpast')}
                    </Typography>
                );
            }
            return null;
        }

        const showRegion = _.some(myShooters, (s) => !!s.region);
        const showPayment = match.payment !== 'none';

        return (
            <div style={{
                display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '24px',
            }}
            >
                <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                    <Table style={{ marginBottom: '24px' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell classes={{ root: classes.tablecell }} />
                                <TableCell classes={{ root: classes.tablecell }}>
                                    {t('generic:shooter_name')}
                                </TableCell>
                                {showRegion && (
                                    <TableCell classes={{ root: classes.tablecell }}>
                                        {t('generic:shooter_region')}
                                    </TableCell>
                                )}
                                <TableCell classes={{ root: classes.tablecell }}>
                                    {t('generic:shooter_division')}
                                    {' '}
                                    /
                                    {t('generic:shooter_category')}
                                    {' '}
                                    /
                                    {t('generic:shooter_power_factor')}
                                </TableCell>
                                <TableCell classes={{ root: classes.tablecell }}>
                                    {t('generic:squad_number')}
                                </TableCell>
                                {showPayment && (
                                    <TableCell classes={{ root: classes.tablecell }}>
                                        {t('status')}
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {myShooters.map((s) => {
                                const LinkTo = (props) => <Link to={`/${match.alias}/${ro ? 'ro-' : ''}registration/existing/${s.id}?registrationCode=${acceptedRegistrationCode || registrationCode}`} {...props} />;
                                const hasScores = _.some(_.values(myShootersScores[s.id] || {}), (a) => !!a);
                                return (
                                    <TableRow key={s.id}>
                                        <TableCell classes={{ root: classes.tablecell }}>
                                            <IconButton color='primary' component={LinkTo}>
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell classes={{ root: classes.tablecell }}>
                                            {renderPublicIdNameClub(s.publicId, s.name, s.club)}
                                        </TableCell>
                                        {showRegion && <TableCell classes={{ root: classes.tablecell }}>{s.region}</TableCell>}
                                        <TableCell classes={{ root: classes.tablecell }}>
                                            {t(`generic:division-${s.division}`)}
                                            {' '}
                                            /
                                            {' '}
                                            {t(`registration:category-${s.category}`)}
                                            {' '}
                                            (
                                            {t(`registration:${s['power-factor'] || 'minor'}`)}
                                            )
                                        </TableCell>
                                        <TableCell classes={{ root: classes.tablecell }}>{s.squad ? match.squads.find((sq) => sq.id === s.squad).title : ''}</TableCell>
                                        {!s.paid && match.payment === 'offline' && (
                                        <TableCell classes={{ root: classes.tablecell }}>
                                            {t('status-pending-offline-payment')}
                                            <sup>*</sup>
                                        </TableCell>
                                        )}
                                        {!s.paid && match.payment === 'online' && (
                                        <TableCell classes={{ root: classes.tablecell }}>
                                            {t('status-pending-online-payment')}
&nbsp;&nbsp;&nbsp;
                                            <Button variant='contained' color='primary' onClick={() => this.onPay(s.id)}>{t('pay')}</Button>
                                        </TableCell>
                                        )}
                                        <TableCell classes={{ root: classes.tablecell }}>
                                            {s.cancellationRequest && <div>{t('status-pendingcancellation')}</div>}
                                            {!s.cancellationRequest && (s.paid || match.payment === 'none') && !s.squad && match.squadSelection === 'closed' && <div>{t('status-pendingsquadclosed')}</div>}
                                            {!s.cancellationRequest && (s.paid || match.payment === 'none') && !s.squad && match.squadSelection === 'during-registration' && <div><Button component={LinkTo} color='primary' style={{ padding: '0px', textTransform: 'none' }}>{t('status-pendingsquad')}</Button></div>}
                                            {!s.cancellationRequest && (s.paid || match.payment === 'none') && !s.squad && match.squadSelection === 'after-payment' && <div><Button component={LinkTo} color='primary' style={{ padding: '0px', textTransform: 'none' }}>{t('status-pendingsquad')}</Button></div>}
                                            {!s.cancellationRequest && (s.paid || match.payment === 'none') && s.squad && <div>{t('status-allgood')}</div>}
                                            { hasScores && (
                                                <div style={{ paddingTop: '12px' }}>
                                                    {t('scoreforms')}
                                                    {match.stages.map((stageId, stageIdx) => {
                                                        const score = myShootersScores[s.id][stageIdx + 1] || [];
                                                        if (score.length === 0) return null;
                                                        score.sort((o1, o2) => o2.timestamp - o1.timestamp);
                                                        const latestScore = score[0];
                                                        return (
                                                            <a
                                                                href={`/api/matches/scores/view-email/byQuery?scoreId=${latestScore.id}&matchId=${match.id}&auth=${auth}`}
                                                                target='_blank'
                                                                rel='noopener noreferrer'
                                                                style={{ padding: '0px 12px' }}
                                                            >
                                                                {stageIdx + 1}
                                                            </a>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    {match.payment === 'offline' && !!myShooters.find((s) => !s.paid) && match.paymentOfflineDescription && (
                        <Typography variant='caption'>
                            <sup>*</sup>
                            {' '}
                            {match.paymentOfflineDescription}
                        </Typography>
                    )}
                </div>
            </div>
        );
    }

    renderEdit({ shooterId }) {
        const {
            t, history, registrationCode,
        } = this.props;
        const {
            name, myShooters, auth, acceptedRegistrationCode,
        } = this.state;
        const shooter = (myShooters || []).find((s) => s.id === shooterId);
        if (!shooter) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <CircularProgress size={75} />
                </div>
            );
        }
        return (
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <Typography variant='h5'>
                    {t('loggedin-title', { name })}
                </Typography>
                <Typography variant='caption' style={{ marginBottom: '12px' }}>
                    {t('loggedin-existing')}
                </Typography>
                <Registration
                    {...this.props}
                    edit={shooter}
                    setTitle={() => {}}
                    onSuccess={($shooter) => {
                        this.setState({ saveSuccess: $shooter.cancellationRequest ? t('loggedin-new-cancelling') : t('loggedin-new-success', $shooter) }, () => history.goBack());
                    }}
                    auth={auth}
                    registrationCode={acceptedRegistrationCode || registrationCode}
                />
            </div>
        );
    }

    renderRegistrationCode() {
        const {
            t,
        } = this.props;
        const {
            errors, registrationCode,
        } = this.state;

        return (
            <div
                style={{
                    width: '90%', margin: 'auto', display: 'flex', alignItems: 'center', flexDirection: 'column',
                }}
            >
                <Typography variant='h3'>
                    {t('signup-title')}
                </Typography>
                <Typography variant='body' style={{ margin: '12px', textAlign: 'center' }}>
                    {t('signup-intro-registration-code')}
                </Typography>
                <div>
                    <TextField
                        error={errors.registrationCode}
                        label={t('registration-code')}
                        value={registrationCode || ''}
                        onChange={(e) => this.setState({
                            registrationCode: e.target.value,
                            errors: { ...errors, registrationCode: false },
                        })}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                this.onRegistrationCode();
                            }
                        }}
                    />
                </div>
                <Button variant='text' color='primary' onClick={() => this.onRegistrationCode()}>
                    {t('signin')}
                </Button>
            </div>
        );
    }

    render() {
        const {
            match, pathname, registrationCode, ro,
        } = this.props;
        const { auth, acceptedRegistrationCode: stateRegistrationCode, nonAuthSavedSuccess } = this.state;

        if (match.registration === 'open-code' && !registrationCode && !stateRegistrationCode) {
            return this.renderRegistrationCode();
        }

        if (nonAuthSavedSuccess) {
            return this.renderNoAuthSuccess();
        }

        if (pathname.endsWith('/new-no-auth')) {
            return this.renderNew();
        }

        if (!auth) {
            if (!pathname.endsWith(`/${ro ? 'ro-' : ''}registration`)) {
                window.location.href = `/${match.alias}/${ro ? 'ro-' : ''}registration`;
                return null;
            }
            return this.renderLogin();
        }
        if (pathname.endsWith('/new')) {
            return this.renderNew();
        } if (pathname.indexOf('/existing/') > -1) {
            return this.renderEdit({ shooterId: pathname.split('/').pop() });
        }
        return this.renderLoggedIn();
    }
}

const styles = () => ({
    tablecell: {
        padding: '12px',
    },
    loggedinnewmessage: {
        backgroundColor: cyan[500],
        width: '90%',
        maxWidth: '720px',
        padding: '12px',
        boxSizing: 'border-box',
        marginBottom: '12px',
        '& p': {
            color: 'white',
        },
    },
    flagwrapper: {
        whiteSpace: 'nowrap',
        display: 'flex',
        flex: 1,
        textAlign: 'left',
        '& > div': {
            flex: 1,
        },
        '& .selected--flag--option  .country-label': {
            display: 'none',
        },
        '& .flag-select': {
            textAlign: 'left',
        },
    },
});

ViewerRegistration.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    t: PropTypes.func.isRequired,
    ro: PropTypes.bool,
    auth: PropTypes.string,
    src: PropTypes.string,
    mode: PropTypes.string,
    accessToken: PropTypes.string,
    name: PropTypes.string,
    user: PropTypes.string,
    registrationCode: PropTypes.string,
    matchId: PropTypes.string.isRequired,
    match: PropTypes.shape({
        countryCode: PropTypes.string.isRequired,
        registrationCode: PropTypes.string,
        alias: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        stages: PropTypes.arrayOf(PropTypes.number),
        payment: PropTypes.string,
        paymentParams: PropTypes.shape({
            gateway: PropTypes.string,
        }),
        registration: PropTypes.string,
        registrationExtraFields: PropTypes.arrayOf(PropTypes.strings),
        squads: PropTypes.arrayOf(PropTypes.shape({
        })),
        squadSelection: PropTypes.string,
        paymentOfflineDescription: PropTypes.string,
    }).isRequired,
    classes: PropTypes.shape({}).isRequired,
    pathname: PropTypes.string.isRequired,
    setTitle: PropTypes.func,
};

ViewerRegistration.defaultProps = {
    ro: false,
    accessToken: null,
    name: null,
    user: null,
    auth: null,
    src: null,
    mode: null,
    setTitle: null,
    registrationCode: null,
};

export default withStyles(styles, { withTheme: true })(withTranslation('viewerregistration')(ViewerRegistration));

function renderPublicIdNameClub(publicId, name, club) {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {(publicId.indexOf('|') > -1) && (
                <img
                    alt={publicId.split('|')[0]}
                    src={getFlagSrc({ countryCode: publicId.split('|')[0], size: 16 })}
                />
            )}
            <div style={{ padding: '0px 6px' }}>
                {publicId.split('|').pop()}
            </div>
            &nbsp;/&nbsp;
            {name}
            &nbsp;(
            {club}
            )
        </div>
    );
}

function isFacebookApp() {
    if (!navigator) return false;
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    return (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1);
}
