const lang = {
    en: {
        rounds: 'Rounds',
        shooters: 'Shooters',
        'shooters-show-squads-title': 'Squads',
        'shooters-show-squads-description': 'See registered shooters and squad assignments.',
        'shooters-show-squads-cta': 'See Squads',
        'shooters-show-squads-disabled': 'We\'re still working on the squads - We\'ll let you know once we publish them.',
        'shooters-show-scores-title': 'Results',
        'shooters-show-scores-description': 'See live scores, shooter stats, "what if\'s", and compare results between shooters.',
        'shooters-show-scores-cta': 'See Results',
        'shooters-show-scores-disabled': 'We\'ll start publishing live scores when the match starts.',
        'shooters-show-shooters-title': 'Shooters',
        'shooters-show-shooters-description': 'See who you\'re up against with this full list of our match\'s shooters.',
        'shooters-show-shooters-cta': 'See Shooters',
        'shooters-show-shooters-disabled': 'We\'re still working on gathering the initial list, and haven\'t published it yet. We\'ll let you know once we publish it.',
        'shooters-registration-title': 'Registration',
        'shooters-registration-description': 'Feel up to the competition? Register to the match here.',
        'shooters-registration-cta': 'Register',
        'shooters-registration-sold-out': 'We\'re sold out',
        'shooters-registration-full': 'Note, we\'re currently full',
        'shooters-registration-period-over': 'Registration period over',
        'shooters-registration-period-over-can-patch': 'Registration period over',

        'ro-registration-title': 'Range Officer Registration',
        'ro-registration-description': 'Want to help us manage and score the match?',
        'ro-registration-cta': 'Click here.',
        'ro-registration-sold-out': 'We\'re sold out',
        'ro-registration-full': 'All our range officer\'s positions are full at the moment. Thanks for considering helping us out.',

        'shooters-stages-title': 'Stages',
        'shooters-stages-description': 'Want to see what we got planned? Click here for our match\'s stage.',
        'shooters-stages-cta': 'See Stages',
        'shooters-stages-disabled': 'We\'re still building our planned stages. We\'ll publish them soon and let you know.',
        'show-staff': 'Are you match staff? Click here.',
        'more-announcements': 'Show {{count}} more announcements',
        'less-announcements': 'Show less announcements',
        breakdown: 'Breakdown',
        tomanage: 'MD & RO Login',
    },
    he: {
        rounds: 'כדורים',
        shooters: 'יורים',
        'shooters-show-squads-title': 'סקוודים',
        'shooters-show-squads-description': 'צפו ברשימת היורים שנרשמו, ובחלוקה לסקוודים.',
        'shooters-show-squads-cta': 'הצג סקוודים',
        'shooters-show-squads-disabled': '(טרם סגרנו סקוודים. נסגור ונפרסם בקרוב)',
        'shooters-show-scores-title': 'תוצאות',
        'shooters-show-scores-description': 'צפו בתוצאות חיות, סטטיסטיקות יורים, השוואות בין יורים, ו״מה האם״.',
        'shooters-show-scores-cta': 'הצג תוצאות',
        'shooters-show-scores-disabled': '(תוצאות יפורסמו ברגע שהתחרות תתחיל)',
        'shooters-stages-title': 'תרגילים',
        'shooters-stages-description': 'כל התרגילים במקום אחד',
        'shooters-stages-cta': 'הצג תרגילים',
        'shooters-stages-disabled': '(אנחנו עדיין עובדים על תרגילים. נפרסם אותם ממש בקרוב)',
        'shooters-show-shooters-title': 'יורים',
        'shooters-show-shooters-description': 'צפו נגד מי אתם מתחרים',
        'shooters-show-shooters-cta': 'הצג יורים',
        'shooters-show-shooters-disabled': '(טרם סגרנו רשימות. נסגור ונפרסם בקרוב)',
        'shooters-registration-title': 'הרשמה',
        'shooters-registration-description': 'מוכנים לקרב? הרשמו כאן לתחרות.',
        'shooters-registration-cta': 'להרשמה',
        'shooters-registration-sold-out': 'אנחנו מלאים - אין מקומות פנויים',
        'shooters-registration-full': 'אנחנו מלאים - אין מקומות פנויים',
        'shooters-registration-period-over': 'ההרשמה נסגרה',
        'shooters-registration-period-over-can-patch': 'ההרשמה נסגרה',
        'ro-registration-title': 'רישום שופטים ושופטות',
        'ro-registration-description': 'מוכנים לעזור לנו לנהל ולשפוט את התחרות?',
        'ro-registration-cta': 'לחצו כאן.',
        'ro-registration-full': 'נכון לכרגע אין לנו צורך בשופטים ושופטות נוספים.',
        'show-staff': 'מנהלי התחרות או שופטים בה? לחצו כאן.',
        'more-announcements': 'הצג {{count}} הודעות נוספות',
        'less-announcements': 'הצג פחות הודעות',
        breakdown: 'פירוט',
        tomanage: 'כניסת בעלי תפקידים',
    },
};

lang.iw = {
    ...lang.he,
    ...{
        shooters: 'לוחמים',
        'shooters-show-squads-title': 'מקצים',
        'shooters-show-squads-description': 'צפו ברשימת הלוחמים שנרשמו, ובחלוקה למקצים.',
        'shooters-show-squads-cta': 'הצג מקצים',
        'shooters-show-squads-disabled': '(טרם סגרנו מקצים. נסגור ונפרסם בקרוב)',
        'shooters-show-scores-description': 'צפו בתוצאות חיות, סטטיסטיקות לוחמים, השוואות בין לוחמים, ו״מה האם״.',
        'shooters-show-shooters-title': 'לוחמים',
        'shooters-show-shooters-cta': 'הצג לוחמים',
    },
};

export default lang;
