import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
    Histogram, DensitySeries, withParentSize, XAxis,
} from '@data-ui/histogram';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import { getScoresWithPoints, pad } from './Utils';
import api from '../Api';

function SmartReports(props) {
    const [report/* , setReport */] = useState('stage-stats');

    return (
        <div>
            { report === 'stage-stats' && <StageStats {...props} /> }
        </div>
    );
}

SmartReports.propTypes = {
    classes: PropTypes.shape({}).isRequired,
};

function StageStats(props) {
    const {
        t, classes, match, auth,
        setTitle, setExtraButtons, setSaveButtonLabel, setSaveButtonCallback, setBackButtonVisible,
    } = props;
    const refreshing = useRef({});
    const [shooters, setShooters] = useState(null);
    const [scores, setScores] = useState(null);
    const [stages, setStages] = useState(null);
    const [showParts, setShowParts] = useState([]);
    const [listUnparticipating, setListUnparticipating] = useState(false);
    const [tab, setTab] = useState(0);

    async function refresh(e, skipProgress) {
        if (refreshing.current.refreshing || !refreshing.current.attached) {
            return;
        }

        if (refreshing.current.refreshTimeout) {
            clearTimeout(refreshing.current.refreshTimeout);
            refreshing.current.refreshTimeout = null;
        }
        refreshing.current.refreshing = true;

        try {
            if (!skipProgress) {
                await setShooters(null);
                await setScores(null);
                await setStages(null);
            }
            const p = await Promise.all([
                api.getShooters({ matchId: match.id, auth }),
                api.getScores({ matchId: match.id, auth }),
                api.getStages({ stages: match.stages }),
            ]);
            const $shooters = p.shift();
            const $scores = p.shift();
            const $stages = p.shift();
            await setShooters($shooters);
            await setScores($scores);
            await setStages($stages);
            refreshing.current.timestamp = new Date().getTime();
        } catch (e2) {
            // Left blank intentionally
        }

        refreshing.current.refreshing = false;
        refreshing.current.refreshTimeout = setTimeout(() => refresh(null, true), 1000 * 60 * 5);
    }

    useEffect(() => {
        refreshing.current.attached = true;
        setTitle(t('title', { name: match.title }));
        setExtraButtons([
            <Button key='sync' onClick={refresh} variant='contained' color='primary'>
                <i className='fas fa-sync-alt' />
            </Button>,
        ]);
        setBackButtonVisible(false);
        setSaveButtonLabel(null);
        setSaveButtonCallback(null);

        refresh();

        return () => {
            refreshing.current.attached = false;
            refreshing.current.refreshing = false;
            if (refreshing.current.refreshTimeout) {
                clearTimeout(refreshing.current.refreshTimeout);
                refreshing.current.refreshTimeout = null;
            }
        };
    }, []);

    if (!shooters || !scores || !stages) {
        return (
            <div className={classes.center}>
                <CircularProgress size={48} />
            </div>
        );
    }

    const { scoresByStage, dqs } = getScoresWithPoints({ scores, shooters, stages });

    function renderProgressTable() {
        return (
            <div style={{ overflowX: 'auto', padding: '0px 12px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding='checkbox' />
                            {stages.map((stage, stageIdx) => <TableCell align='center' padding='none' key={stage.id}>{stageIdx + 1}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {match.squads.map((squad) => (
                            <TableRow key={squad.id}>
                                <TableCell padding='checkbox'>{squad.title}</TableCell>
                                {stages.map((stage, stageIdx) => {
                                    const squadShooters = shooters
                                        .filter((s) => s.squad === squad.id)
                                        .filter((s) => !dqs.find((s2) => s.id === s2.shooterId));

                                    const briefing = _.some(squadShooters, (shooter) => ((shooter.equipmentChecks || [])[stageIdx] || {}).check)
                                        || _.some(squadShooters, (shooter) => scoresByStage[stageIdx].find((s) => s.shooterId === shooter.id));
                                    const allScore = _.every(squadShooters, (shooter) => {
                                        if (_.get(shooter, `equipmentChecks[${stageIdx}].check`) === 'absent') return true;
                                        const ret = scoresByStage[stageIdx].find((s) => s.shooterId === shooter.id);
                                        return ret;
                                    });

                                    const bgColor = (() => {
                                        if (allScore) return green[500];
                                        if (briefing) return yellow[100];
                                        return null;
                                    })();

                                    const tooltipTitle = t('progresstooltip', { squad: squad.title, stage: stageIdx + 1 });

                                    const toScore = _.filter(squadShooters, (shooter) => {
                                        if (_.get(shooter, `equipmentChecks[${stageIdx}].check`) === 'absent') return false;
                                        return !scoresByStage[stageIdx].find((s) => s.shooterId === shooter.id);
                                    });

                                    const absent = _.filter(squadShooters, (shooter) => _.get(shooter, `equipmentChecks[${stageIdx}].check`) === 'absent');

                                    return (
                                        <Tooltip
                                            key={stage.id}
                                            title={(
                                                <>
                                                    <Typography color='inherit'>{tooltipTitle}</Typography>
                                                    {toScore.length > 0 && <b>{t('progresstooltiplist')}</b>}
                                                    {toScore.map((shooter) => (
                                                        <div>{shooter.name}</div>
                                                    ))}
                                                    {absent.length > 0 && <b>{t('progresstooltipabsent')}</b>}
                                                    {absent.map((shooter) => (
                                                        <div>{shooter.name}</div>
                                                    ))}
                                                </>
                                            )}
                                            placement='top-start'
                                        >
                                            <TableCell padding='dense' style={{ cursor: 'pointer', background: bgColor, border: '1px solid rgba(0,0,0,0.2)' }} key={stage.id}>
                                                &nbsp;
                                            </TableCell>
                                        </Tooltip>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        );
    }

    function renderStageStats() {
        return stages.map((stage, stageIdx) => {
            const lastScore = _(scoresByStage[stageIdx])
                .sortBy(['timestamp'])
                .last();

            if (!lastScore) return null;

            const averageScoreTime = _.reduce(scoresByStage[stageIdx], (sum, a) => sum + a.time, 0) / scoresByStage[stageIdx].length;

            const absent = shooters
                .filter((s) => ((s.equipmentChecks || [])[stageIdx] || {}).check === 'absent');

            const absentCount = absent.length;

            let { gaps } = _(scoresByStage[stageIdx])
                .sortBy(['timestamp'])
                .reduce((acc, n) => { acc.gaps.push(n.timestamp - acc.last); acc.last = n.timestamp; return acc; }, { gaps: [], last: 0 });
            gaps.shift(); // Remove the first
            const midGaps = gaps.sort((a, b) => a - b).slice(Math.floor(gaps.length / 4), Math.floor((gaps.length * 3) / 4));
            const averageGap = parseInt(_.reduce(midGaps, (s, a) => s + Math.floor(a / 1000), 0) / midGaps.length, 10);
            gaps = gaps.map((g) => Math.floor(g / 1000));

            return (
                <div key={stage.id} className={classes.wrapper} style={{ marginBottom: '12px' }}>
                    <Typography variant='h6'>
                        {stageIdx + 1}
                        .
                        &nbsp;
                        {stage.title}
                    </Typography>
                    <div>
                        <div style={{ display: 'flex' }}>
                            <Typography variant='body1'>
                                {t('stagestatslastscoretime')}
                            </Typography>
                            &nbsp;
                            <Typography variant='body1' style={{ fontWeight: '800' }}>
                                {moment(lastScore.timestamp).locale(match.locale).format('LL LT')}
                            </Typography>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Typography variant='body1'>
                                {t('stagestatsabsent')}
                            </Typography>
                            &nbsp;
                            {absentCount === 0 && (
                                <Typography variant='body1' style={{ fontWeight: '800' }}>
                                    {absentCount}
                                </Typography>
                            )}
                            {!showParts.includes(`absent-${stageIdx}`) && absentCount > 0 && (
                                <div style={{ display: 'flex' }}>
                                    <Typography variant='body1' style={{ fontWeight: '800' }}>
                                        {absentCount}
                                    </Typography>
                                    <Button classes={{ root: classes.basicButton }} onClick={() => setShowParts([...showParts, `absent-${stageIdx}`])}>{t('stagestatsabsentwho')}</Button>
                                </div>
                            )}
                        </div>
                        {showParts.includes(`absent-${stageIdx}`) && absentCount > 0 && (
                            <div style={{ display: 'flex' }}>
                                <Typography variant='body1' style={{ fontWeight: '800' }}>
                                    {absent.map((a) => a.name).join(' | ')}
                                </Typography>
                                <Button classes={{ root: classes.basicButton }} onClick={() => setShowParts(showParts.filter((s) => s !== `absent-${stageIdx}`))}>{t('generic:close')}</Button>
                            </div>
                        )}
                        <div style={{ display: 'flex' }}>
                            <Typography variant='body1'>
                                {t('stagestatsaveragescoretime')}
                            </Typography>
                            &nbsp;
                            <Typography variant='body1' style={{ fontWeight: '800' }}>
                                {pad(averageScoreTime, 2)}
                            </Typography>
                            {!showParts.includes(`histogram-avg-score-${stageIdx}`) && (
                                <Button
                                    classes={{ root: classes.basicButton }}
                                    onClick={() => setShowParts([...showParts, `histogram-avg-score-${stageIdx}`])}
                                >
                                    {t('graph')}
                                </Button>
                            )}
                            {showParts.includes(`histogram-avg-score-${stageIdx}`) && (
                                <Button
                                    classes={{ root: classes.basicButton }}
                                    onClick={() => setShowParts(showParts.filter((s) => s !== `histogram-avg-score-${stageIdx}`))}
                                >
                                    {t('generic:close')}
                                </Button>
                            )}
                        </div>
                        <div
                            className={classes.histogram}
                            style={{
                                width: '100%',
                                height: '400px',
                                maxHeight: showParts.includes(`histogram-avg-score-${stageIdx}`) ? '400px' : '0px',
                            }}
                        >
                            <ResponsiveHistogram
                                ariaLabel='times'
                                orientation='vertical'
                                cumulative={false}
                                binCount={25}
                                valueAccessor={(datum) => datum.time}
                                binType='numeric'
                            >
                                <DensitySeries
                                    animated
                                    rawData={scoresByStage[stageIdx]}
                                />
                                <XAxis />
                            </ResponsiveHistogram>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Typography variant='body1'>
                                {t('stagestatsaveragegap')}
                            </Typography>
                            &nbsp;
                            <Typography variant='body1' style={{ fontWeight: '800' }}>
                                {Math.floor(averageGap / 60)}
                                :
                                {_.padStart(averageGap % 60, 2, '0')}
                            </Typography>
                            {!showParts.includes(`histogram-avg-gaps-${stageIdx}`) && (
                                <Button
                                    classes={{ root: classes.basicButton }}
                                    onClick={() => setShowParts([...showParts, `histogram-avg-gaps-${stageIdx}`])}
                                >
                                    {t('graph')}
                                </Button>
                            )}
                            {showParts.includes(`histogram-avg-gaps-${stageIdx}`) && (
                                <Button
                                    classes={{ root: classes.basicButton }}
                                    onClick={() => setShowParts(showParts.filter((s) => s !== `histogram-avg-gaps-${stageIdx}`))}
                                >
                                    {t('generic:close')}
                                </Button>
                            )}
                        </div>
                        <div
                            className={classes.histogram}
                            style={{
                                width: '100%',
                                height: '400px',
                                maxHeight: showParts.includes(`histogram-avg-gaps-${stageIdx}`) ? '400px' : '0px',
                            }}
                        >
                            <ResponsiveHistogram
                                ariaLabel='gaps'
                                orientation='vertical'
                                cumulative={false}
                                binCount={25}
                                valueAccessor={(datum) => datum}
                                binType='numeric'
                            >
                                <DensitySeries
                                    animated
                                    rawData={gaps}
                                />
                                <XAxis
                                    tickFormat={(tick) => `${Math.floor(tick / 60)}:${_.padStart(tick % 60, 2, '0')}`}
                                />
                            </ResponsiveHistogram>
                        </div>
                    </div>
                </div>
            );
        });
    }

    function renderFinancialStats() {
        const paidShooters = shooters.filter((s) => s.paid);
        const unparticipatingPaidShooters = paidShooters.filter((s) => !scores.find((sc) => sc.shooterId === s.id));

        return (
            <div className={classes.wrapper} style={{ marginBottom: '12px' }}>
                <Typography variant='body1'>
                    {t('numpaid')}
                </Typography>
                <div>
                    <Typography variant='body1' style={{ fontWeight: '800' }}>
                        {paidShooters.length}
                    </Typography>
                </div>
                <br />
                <Typography variant='body1'>
                    {t('numpaiddidntshoot')}
                </Typography>
                {unparticipatingPaidShooters.length === 0 && (
                    <Typography variant='body1' style={{ fontWeight: '800' }}>
                        0
                    </Typography>
                )}
                {!listUnparticipating && unparticipatingPaidShooters.length > 0 && (
                    <div style={{ display: 'flex' }}>
                        <Typography variant='body1' style={{ fontWeight: '800' }}>
                            {unparticipatingPaidShooters.length}
                        </Typography>
                        <Button classes={{ root: classes.basicButton }} onClick={() => setListUnparticipating(true)}>{t('stagestatsabsentwho')}</Button>
                    </div>
                )}
                {listUnparticipating && unparticipatingPaidShooters.length > 0 && (
                    <div style={{ display: 'flex' }}>
                        <Typography variant='body1' style={{ fontWeight: '400' }}>
                            {unparticipatingPaidShooters.map((a) => a.name).join(' | ')}
                        </Typography>
                        <Button classes={{ root: classes.basicButton }} onClick={() => setListUnparticipating(false)}>{t('generic:close')}</Button>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div>
            <div className={classes.wrapper}>
                <Tabs value={tab} onChange={(e, v) => setTab(v)}>
                    <Tab label={t('progress')} />
                    <Tab label={t('stagestats')} />
                    <Tab label={t('financialstats')} />
                </Tabs>
                <Typography variant='caption' style={{ padding: '12px 0px', opacity: 0.8 }}>
                    {tab === 0 && t('progressdetails')}
                </Typography>
                {tab === 0 && refreshing && refreshing.current && refreshing.current.timestamp && (
                    <Typography variant='caption' style={{ opacity: 0.8 }}>
                        {t('progressdetailslastupdate', { lastUpdate: moment(refreshing.current.timestamp).format('LTS') })}
                    </Typography>
                )}
            </div>
            {tab === 0 && renderProgressTable()}
            {tab === 1 && renderStageStats()}
            {tab === 2 && renderFinancialStats()}
        </div>
    );
}

StageStats.propTypes = {
    setTitle: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func.isRequired,
    setSaveButtonLabel: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    setBackButtonVisible: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        locale: PropTypes.string.isRequired,
        stages: PropTypes.arrayOf(PropTypes.string).isRequired,
        squads: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
    auth: PropTypes.string.isRequired,
};

const styles = {
    wrapper: {
        maxWidth: '720px',
        width: '90%',
        margin: 'auto',
    },
    center: {
        textAlign: 'center',
    },
    basicButton: {
        paddingTop: '0px',
        paddingBottom: '0px',
        textTransform: 'none',
        textDecoration: 'underline',
        minWidth: '0px',
    },
    histogram: {
        transition: 'max-height 0.5s ease-in-out',
        overflow: 'hidden',
    },
};

export default withStyles(styles)(withTranslation('smartreports')(SmartReports));

const ResponsiveHistogram = withParentSize(({ parentWidth, parentHeight, ...rest }) => (
    <Histogram
        width={parentWidth}
        height={parentHeight}
        {...rest}
    />
));
