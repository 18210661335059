import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import TextField from '@material-ui/core/TextField';
import yellow from '@material-ui/core/colors/yellow';
import { getFlagSrc } from './Utils';
import api from '../Api';

function RegistrationImport(props) {
    const {
        t, classes, history, routeMatch, match, selectedMatchId, auth, onSelect,
        setTitle, setExtraButtons, setSaveButtonLabel, setSaveButtonCallback, setBackButtonVisible,
    } = props;
    const [matches, setMatches] = useState(null);
    const [shooters, setShooters] = useState(null);
    const [nameFilter, setNameFilter] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState(global.$$registrationPassword || '');
    const [passwordEmpty, setPasswordEmpty] = useState(false);
    const [passwordInvalid, setPasswordInvalid] = useState(false);
    const [passwordNoPermissions, setPasswordNoPermissions] = useState(false);
    const [passwordChecking, setPasswordChecking] = useState(false);

    async function refresh(passwordAccessToken) {
        await setMatches(null);
        await setShooters(null);
        if (!selectedMatchId) {
            const data = await api.getMatches();
            const allMatches = Object.keys(data).map((k) => ({ ...data[k], id: k }))
                .filter((m) => (m.id !== match.id) && (m.countryCode === match.countryCode));
            setMatches(allMatches);
        } else {
            const $auth = passwordAccessToken || auth;
            const cacheJSON = window.localStorage.getItem('__homeapp_shooters');
            if (cacheJSON) {
                const cache = JSON.parse(cacheJSON);
                if ((cache.matchId === selectedMatchId) && (!passwordAccessToken) && (cache.timestamp > new Date().getTime() - 1 * 60 * 60 * 1000)) {
                    setShooters(cache.shooters);
                    return;
                }
            }
            const getShooters = await api.getShooters({ matchId: selectedMatchId, auth: $auth });
            const matchShooters = (await api.getShooters({ matchId: match.id, auth: $auth })).map((s) => s.publicId);
            const newShooters = getShooters.filter((s) => matchShooters.indexOf(s.publicId) === -1);
            const normalizedShooters = newShooters.map((s) => {
                const mandatory = _.pick(s, ['publicId', 'name', 'club', 'email', 'division', 'power-factor', 'category']);
                const extraFields = _.pick(s.extraFields || {}, match.registrationExtraFields);
                return { ...mandatory, extraFields };
            });
            setShooters(normalizedShooters);
            window.localStorage.setItem('__homeapp_shooters', JSON.stringify({
                matchId: selectedMatchId,
                timestamp: new Date().getTime(),
                shooters: normalizedShooters,
            }));
        }
    }

    useEffect(() => {
        setTitle(t('registration:importshooter'));
        setExtraButtons([]);
        setSaveButtonLabel(null);
        setBackButtonVisible(true);
        setSaveButtonCallback(null);
        window.scrollTo(0, 0);

        refresh();
    }, []);

    async function onSelectMatch(matchId) {
        history.push(`${routeMatch.url}/${matchId}`);
    }

    async function handlePassword() {
        if (!password) {
            setPasswordEmpty(true);
            return;
        }
        await setPasswordChecking(true);
        const newAuth = `com.endofscoring|${selectedMatchId}|${password}`;

        try {
            let login = null;
            try {
                login = await api.getLogin({ accessToken: newAuth });
            } catch (e) {
                if (e.response.status === 404) {
                    login = await api.postLogin({ accessToken: newAuth });
                } else {
                    throw e;
                }
            }

            const { accessToken } = login;

            const me = await api.getAuth({
                accessToken,
                matchId: selectedMatchId,
            });

            setPasswordChecking(false);
            if ((me.permissions || []).indexOf('registration') === -1) {
                setPasswordNoPermissions(true);
                return;
            }
            setShowPassword(false);
            await refresh(accessToken);
        } catch (e) {
            if (e.response.status === 401) {
                setPasswordInvalid(true);
                setPasswordChecking(false);
            }
        }
    }

    if (showPassword) {
        return (
            <div className={classes.wrapper}>
                <TextField
                    autoFocus
                    label={t('authentication:password')}
                    type='password'
                    variant='filled'
                    InputLabelProps={{
                        classes: { root: classes.colorwhite, shrink: classes.colorwhite },
                        FormLabelClasses: { root: classes.colorwhite, focused: classes.colorwhite },
                        shrink: true,
                    }}
                    InputProps={{
                        classes: { input: classes.colorwhite },
                    }}
                    value={password}
                    onChange={(e) => {
                        setPasswordEmpty(false);
                        setPasswordInvalid(false);
                        setPassword(e.target.value);
                        global.$$registrationPassword = e.target.value;
                    }}
                    onKeyDown={(e) => { if (e.keyCode === 13) handlePassword(); }}
                    error={passwordEmpty || passwordInvalid}
                    helperText={
                        passwordInvalid || passwordNoPermissions ? <span style={{ display: 'flex', width: '100%', textAlign: 'right' }}>{t('authentication:error')}</span> : null
}
                />
                <Button
                    variant='contained'
                    color='primary'
                    style={{ width: '60%', marginTop: '24px' }}
                    onClick={handlePassword}
                    disabled={passwordChecking}
                >
                    {passwordChecking ? <CircularProgress size={18} /> : t('generic:ok')}
                </Button>
            </div>
        );
    }

    if (!matches && !shooters) {
        return (
            <div className={classes.wrapper}>
                <CircularProgress size={48} />
            </div>
        );
    }

    return (
        <div className={classes.wrapper}>
            {!shooters && matches.map((m) => (
                <Button style={{ marginBottom: '12px' }} variant='contained' color='primary' fullWidth onClick={() => onSelectMatch(m.id)}>
                    {m.title}
                </Button>
            ))}
            {shooters && (
                <div style={{ width: '100%' }}>
                    {(shooters.length === 0) && (
                        <div style={{ display: 'flex', margin: '12px 0px' }}>
                            <i className='fas fa-exclamation-triangle' style={{ color: yellow[700] }} />
                            &nbsp;
                            {t('registration:importshooternoshooters')}
                        </div>
                    )}
                    {shooters.length > 0 && typeof (shooters[0].email) === 'undefined' && (
                        <div>
                            <div style={{ display: 'flex', margin: '12px 0px', alignItems: 'center' }}>
                                <i className='fas fa-exclamation-triangle' style={{ color: yellow[700] }} />
                                &nbsp;
                                {t('registration:importshooternopermission')}
                                &nbsp;
                                <span onClick={() => setShowPassword(true)} onKeyDown={() => {}} style={{ cursor: 'pointer', textDecoration: 'underline' }} role='button' tabIndex={0}>
                                    {t('registration:importshooterusepassword')}
                                </span>
                                .
                            </div>
                        </div>
                    )}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ flex: 1 }}>
                            <TextField label={t('registration:filterbyname')} fullWidth value={nameFilter} onChange={(e) => setNameFilter(e.target.value)} />
                        </div>
                        <div style={{ flex: 0, opacity: 0.5 }}>
                            <i className='fas fa-search' />
                        </div>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-around',
                            marginTop: '12px',
                        }}
                    >
                        {shooters.map((s) => (!matchNameFilter(s, nameFilter) ? null : (
                            <Card style={{ marginTop: '6px', width: 'calc(50% - 6px)' }}>
                                <CardActionArea onClick={() => onSelect(s)}>
                                    <CardHeader
                                        avatar={(
                                            <img
                                                alt={s.publicId.split('|')[0]}
                                                src={getFlagSrc({ countryCode: s.publicId.split('|')[0], size: 32 })}
                                            />
                                        )}
                                        title={`${s.publicId.split('|')[1]} | ${s.name} (${s.club})`}
                                        subheader={`${t(`generic:division-${s.division}`)} | ${t(`registration:category-${s.category}`)} | ${t(`registration:${s['power-factor'] || 'minor'}`)}`}
                                    />
                                    <CardContent>
                                        {s.email && (
                                            <div style={{ display: 'flex' }}>
                                                <Typography variant='caption' style={{ opacity: 0.8 }}>
                                                    {t('generic:shooter_email')}
                                                    :
                                                    &nbsp;
                                                </Typography>
                                                <Typography variant='caption'>
                                                    {s.email}
                                                </Typography>
                                            </div>
                                        )}
                                        {_.map(s.extraFields || {}, (v, k) => (
                                            <div style={{ display: 'flex' }}>
                                                <Typography variant='caption' style={{ opacity: 0.8 }}>
                                                    {t(`generic:${k}`)}
                                                    :
                                                    &nbsp;
                                                </Typography>
                                                <Typography variant='caption'>
                                                    {v}
                                                </Typography>
                                            </div>
                                        ))}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        )))}
                    </div>
                </div>
            )}
        </div>
    );
}

RegistrationImport.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    routeMatch: ReactRouterPropTypes.match.isRequired,
    classes: PropTypes.shape({}).isRequired,
    t: PropTypes.func.isRequired,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
        countryCode: PropTypes.string.isRequired,
        registrationExtraFields: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    auth: PropTypes.string.isRequired,
    selectedMatchId: PropTypes.string,
    onSelect: PropTypes.func,
    setTitle: PropTypes.func,
    setExtraButtons: PropTypes.func,
    setSaveButtonLabel: PropTypes.func,
    setSaveButtonCallback: PropTypes.func,
    setBackButtonVisible: PropTypes.func,
};

RegistrationImport.defaultProps = {
    selectedMatchId: null,
    onSelect: null,
    setTitle: null,
    setExtraButtons: null,
    setSaveButtonLabel: null,
    setSaveButtonCallback: null,
    setBackButtonVisible: null,
};

const styles = () => ({
    wrapper: {
        margin: 'auto',
        minWidth: '720px',
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export default withStyles(styles, { withTheme: true })(withTranslation('registartionimport')(RegistrationImport));

function matchNameFilter(s, nameFilter) {
    if (!nameFilter) return true;
    if ((s.name || '').indexOf(nameFilter) > -1) return true;
    if ((s.publicId || '').toString().indexOf(nameFilter) === 3) return true;
    return false;
}
