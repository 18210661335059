/* eslint jsx-a11y/anchor-has-content: 0 */
import './Home.css';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Hidden from '@material-ui/core/Hidden';
import IconPeople from '@material-ui/icons/People';
import IconPages from '@material-ui/icons/Pages';
import IconCollections from '@material-ui/icons/Collections';
import Paper from '@material-ui/core/Paper';
import pink from '@material-ui/core/colors/pink';
import blue from '@material-ui/core/colors/blue';
import api from '../Api';
import Header from './Header';
import Pricing from './Pricing';
import ContactUs from './ContactUs';
import Wrapper from './HomeI18nWrapper';

class Home extends Component {
    constructor(props) {
        super(props);

        this.cards = [];
        this.state = {
            demo: 0,
            loggedIn: false,
        };
    }

    UNSAFE_componentWillMount() {
        const { i18n } = this.props;
        if (typeof (document) !== 'undefined') {
            document.body.style.background = 'white';
            document.body.style.direction = 'ltr';
            document.body.setAttribute('dir', 'ltr');
            document.body.classList.remove('rtl');
            document.body.classList.add('ltr');
        }
        i18n.changeLanguage('en-US');
        moment.locale('en-US');

        /*
        if (typeof (window) !== 'undefined') {
            window.Tawk_API = window.Tawk_API || {};
            window.Tawk_LoadStart = new Date();
            (function tawk() {
                const s1 = document.createElement('script'); const s0 = document.getElementsByTagName('script')[0];
                s1.async = true;
                s1.src = 'https://embed.tawk.to/5c172ce782491369ba9e601e/default';
                s1.charset = 'UTF-8';
                s1.setAttribute('crossorigin', '*');
                s0.parentNode.insertBefore(s1, s0);
            }());
        }
        */
    }

    async componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        const matches = await api.getMatches();
        const lastMatches = _(matches)
            .values()
            .filter((m) => {
                const startDate = new Date(m.startDate.year, m.startDate.month - 1, m.startDate.day, 12, 0, 0);

                if (startDate.getTime() > new Date().getTime()) return false;
                if (!m.package) return false;
                return true;
            })
            .sortBy([(m) => {
                const startDate = new Date(m.startDate.year, m.startDate.month - 1, m.startDate.day, 12, 0, 0);
                return -1 * startDate.getTime();
            }])
            .value()
            .slice(0, 3);
        this.setState({ matches: lastMatches });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        this.checkCards();
    };

    checkCards = () => {
        const { classes } = this.props;
        this.cards.forEach((card) => {
            if (!card.getBoundingClientRect) return;
            const rect = card.getBoundingClientRect();
            if (rect.top < window.innerHeight - 30) {
                card.classList.add(classes.animate);
            }
        });
    }

    onDemo = () => {
        const { demo } = this.state;
        this.setState({ demo: demo + 1 });
    }

    render() {
        const { classes, history } = this.props;
        const { demo, loggedIn, matches } = this.state;

        return (
            <Grid container>
                <Header demo={demo} onLoggedIn={() => this.setState({ loggedIn: true })} onLoggedOut={() => this.setState({ loggedIn: false })} />
                <Grid item classes={{ item: classes.top }} xs={12}>
                    <div className='home-topcover'>
                        <div className='home-titlecover' ref={(e) => { if (e) { setTimeout(() => e.classList.add(classes.animate, 500)); } }}>
                            <h1 className='home-title'>
                                Manage Your Shooting Matches
                            </h1>
                            <p className='home-subtitle'>
                                Bring your IPSC/USPSA/IDPA match to the 21st century with online registration, squad management, and live scoring.
                            </p>
                        </div>
                        <div className={classnames([classes.hometopcoverimg, 'home-topcoverimg'])} ref={(e) => { if (e) { setTimeout(() => e.classList.add(classes.animate, 500)); } }} />
                        <img alt='swosh' className='home-swosh' src='https://kid.io/wp-content/themes/kidio/src/m_images/content-swoosh.svg' />
                    </div>
                </Grid>

                <Hidden mdUp>
                    <Grid item xs={12} style={{ paddingTop: '36px' }}>
                        <Button variant='caption' color='secondary' style={{ margin: '0px 12px', height: '40px', width: '33%' }} onClick={this.onPrivacy}>privacy</Button>
                        {!loggedIn && (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button variant='contained' color='primary' style={{ margin: '0px 12px', height: '40px', width: '33%' }} component={(props) => <Link to='/new' {...props} />}>Sign Up!</Button>
                            <Button variant='contained' color='secondary' style={{ margin: '0px 12px', height: '40px', width: '33%' }} onClick={this.onDemo}>Demo</Button>
                        </div>
                        )}
                        {loggedIn && (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button variant='contained' color='primary' style={{ margin: '0px 12px', height: '40px', width: '50%' }} component={(props) => <Link to='/new' {...props} />}>+ New Match</Button>
                            </div>
                        )}
                    </Grid>
                </Hidden>

                <Grid item xs={12} style={{ paddingTop: '36px', textAlign: 'center' }}>
                    <Typography variant='h4'>
                        So what do we have to offer?
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ padding: '0px 32px', paddingTop: '16px' }}>
                    <Grid container spacing={32}>
                        <Grid item xs={12} md={6}>
                            <div className={classes.featurecardleft} ref={(e) => { if (e) { this.cards.push(e); this.checkCards(); } }}>
                                <Card>
                                    <CardHeader title='Squad Management & Scoring' classes={{ root: classes.featureheaderaria, title: classes.featureheadertitle }} />
                                    <CardContent>
                                        <div style={{ display: 'flex' }}>
                                            <div>
                                                <IconPages classes={{ root: classnames([classes.featureicon, classes.featureiconspecial]) }} />
                                            </div>
                                            <div className='home-featurecontent'>
                                                We&apos;re in the 21st century! It&apos;s time we use technology to help us manage our matches! Aria is a complete squad management and live smart scoring solution. Check it out.
                                                <Button component={(props) => <Link to='/aria' {...props} />} fullWidth variant='contained' color='secondary'>How does it work?</Button>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className={classes.featurecardright} ref={(e) => { if (e) { this.cards.push(e); this.checkCards(); } }}>
                                <Card>
                                    <CardHeader title='Shooter Registration' classes={{ root: classes.featureheader, title: classes.featureheadertitle }} />
                                    <CardContent>
                                        <div style={{ display: 'flex' }}>
                                            <div>
                                                <IconPeople classes={{ root: classes.featureicon }} />
                                            </div>
                                            <div className='home-featurecontent'>
                                                Let shooters register to your match on your website, complete with their division, category, and power factor. Including squad selection (if you want), and contact details so you can update them of changes to your match.
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className={classes.featurecardleft} ref={(e) => { if (e) { this.cards.push(e); this.checkCards(); } }}>
                                <Card style={{ height: '100%' }}>
                                    <CardHeader title='Showcase Stages' classes={{ root: classes.featureheader, title: classes.featureheadertitle }} />
                                    <CardContent>
                                        <div style={{ display: 'flex' }}>
                                            <div>
                                                <IconCollections classes={{ root: classes.featureicon }} />
                                            </div>
                                            <div className='home-featurecontent'>
                                                Place your stages on your website so shooters have one easy place to find all the information they need. Upload an image, enter some information, and you&apos;ll even have a complete briefing you can print out.
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className={classes.featurecardright} ref={(e) => { if (e) { this.cards.push(e); this.checkCards(); } }}>
                                <Card>
                                    <CardHeader title='Live Scoring' classes={{ root: classes.featureheaderaria, title: classes.featureheadertitle }} />
                                    <CardContent>
                                        <div style={{ display: 'flex' }}>
                                            <div>
                                                <i className={classnames(['fas', 'fa-medal', classnames([classes.featureicon, classes.featureiconspecial])])} />
                                            </div>
                                            <div className='home-featurecontent'>
                                                Have your judges update results on the fly with our easy-to-use mobile app! Shooters can then view their own and their competitors results live on the website. More info here:
                                                <Button component={(props) => <Link to='/aria' {...props} />} fullWidth variant='contained' color='secondary'>How does it work?</Button>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className={classes.featurecardleft} ref={(e) => { if (e) { this.cards.push(e); this.checkCards(); } }}>
                                <Card>
                                    <CardHeader title='Mobile' classes={{ root: classes.featureheader, title: classes.featureheadertitle }} />
                                    <CardContent>
                                        <div style={{ display: 'flex' }}>
                                            <div>
                                                <i className={classnames(['fas', 'fa-mobile-alt', classes.featureicon])} />
                                            </div>
                                            <div className='home-featurecontent'>
                                                Everyone has a smartphone these days, so why shouldn&apos;t your website work perfectly for them? We provide full mobile support for all the features we have - both for your shooters, and your staff.
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className={classes.featurecardright} ref={(e) => { if (e) { this.cards.push(e); this.checkCards(); } }}>
                                <Card>
                                    <CardHeader title='Language Support' classes={{ root: classes.featureheader, title: classes.featureheadertitle }} />
                                    <CardContent>
                                        <div style={{ display: 'flex' }}>
                                            <div>
                                                <i className={classnames(['fas', 'fa-globe-americas', classes.featureicon])} />
                                            </div>
                                            <div className='home-featurecontent'>
                                                Don&apos;t speak English? Not a problem - we support any language you need, including right-to-left languages. Need a langugage we don&apos;t support? Lets us know, and we&apos;ll add it to our list in a flash.
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Grid container classes={{ container: classnames([classes.step, classes.step4]) }}>
                                    <Grid item xs={12} md={6} classes={{ item: classes.innerpaper }}>
                                        <Typography variant='h2'>Aria</Typography>
                                        <Typography variant='body1'>IPSC Smart Squad Management and Scoring System</Typography>
                                        <Typography variant='body1'>Easy. Fast. Reliable. Fun.</Typography>
                                        <Button style={{ marginTop: '12px' }} component={(props) => <Link to='/aria' {...props} />} variant='contained' color='secondary'>More Info</Button>

                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <img alt={'aria\'s scoring screen'} className={classes.headimg} src='/scoring-hand.png' />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Hidden mdUp>
                            <Grid item xs={12} style={{ paddingTop: '36px' }}>
                                {!loggedIn && (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button variant='contained' color='primary' style={{ margin: '0px 12px', height: '40px', width: '42%' }} component={(props) => <Link to='/new' {...props} />}>Sign Up!</Button>
                                    <Button variant='contained' color='secondary' style={{ margin: '0px 12px', height: '40px', width: '42%' }} onClick={this.onDemo}>Demo</Button>
                                </div>
                                )}
                                {loggedIn && (
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button variant='contained' color='primary' style={{ margin: '0px 12px', height: '40px', width: '70%' }} component={(props) => <Link to='/new' {...props} />}>+ New Match</Button>
                                    </div>
                                )}
                            </Grid>
                        </Hidden>

                        <Pricing />

                        <Hidden mdUp>
                            <Grid item xs={12} style={{ paddingTop: '36px' }}>
                                {!loggedIn && (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button variant='contained' color='primary' style={{ margin: '0px 12px', height: '40px', width: '42%' }} component={(props) => <Link to='/new' {...props} />}>Sign Up!</Button>
                                    <Button variant='contained' color='secondary' style={{ margin: '0px 12px', height: '40px', width: '42%' }} onClick={this.onDemo}>Demo</Button>
                                </div>
                                )}
                                {loggedIn && (
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button variant='contained' color='primary' style={{ margin: '0px 12px', height: '40px', width: '70%' }} component={(props) => <Link to='/new' {...props} />}>+ New Match</Button>
                                    </div>
                                )}
                            </Grid>
                        </Hidden>
                        <Grid item xs={12}>
                            <div className={classnames([classes.bottomcover, 'home-bottomcover'])}>
                                <img alt='screenshot on mac' src='./mac.png' className={classnames([classes.animatefromleft, 'home-mac'])} ref={(e) => { if (e) { this.cards.push(e); this.checkCards(); } }} />
                            </div>
                        </Grid>

                        <ContactUs />
                    </Grid>
                </Grid>
                <div className={classnames([classes.footer, matches ? classes.footeranimated : null])} style={{ position: 'static', visibility: 'hidden' }}>
                    <Typography variant='subtitle2'>
                        Shooters? Latest matches to use Aria:
                    </Typography>
                    {(matches || []).map((m) => (
                        <Button key={m.title} onClick={() => history.push(`/${m.alias}`)}>
                            <Typography variant='body2'>
                                {m.title}
                            </Typography>
                        </Button>
                    ))}
                    <Button color='primary' onClick={() => history.push('/map')}>
                        <Typography variant='body2' style={{ color: 'inherit', textTransform: 'none' }}>
                            full map
                        </Typography>
                    </Button>
                </div>
                <div className={classnames([classes.footer, matches ? classes.footeranimated : null])}>
                    <Typography variant='subtitle2'>
                        Shooters? Latest matches to use Aria:
                    </Typography>
                    {(matches || []).map((m) => (
                        <Button key={m.title} onClick={() => history.push(`/${m.alias}`)}>
                            <Typography variant='body2'>
                                {m.title}
                            </Typography>
                        </Button>
                    ))}
                    <Button color='primary' onClick={() => history.push('/map')}>
                        <Typography variant='body2' style={{ color: 'inherit', textTransform: 'none' }}>
                            full map
                        </Typography>
                    </Button>
                </div>
            </Grid>
        );
    }
}

const styles = {
    bottomcover: {
        backgroundImage: 'url(./bottom.jpg)',
    },
    hometopcoverimg: {
        backgroundImage: 'url(./cover.png)',
    },
    header: {
        borderBottom: '1px solid rgba(50,48,57,0.08)',
        background: '#ffffff',
        position: 'absolute',
        height: '80px',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 24px',
    },
    fixedheader: {
        position: 'fixed',
        top: '-80px',
    },
    animateheader: {
        top: '0px',
        boxShadow: '1px 1px 4px rgba(0,0,0,0.5)',
        transition: 'all 0.3s cubic-bezier(0.34, 0.61, 0.59, 1.11) 0.4s',
    },
    top: {
        marginTop: '80px',
        position: 'relative',
    },
    logo: {
        '& > div': {
            transform: 'scale(0.5)',
            transformOrigin: 0,
        },
        display: 'flex',
        alignItems: 'center',
        height: '80px',
        flex: 1,
    },
    featureheader: {
        background: blue[500],
        textAlign: 'center',
    },
    featureheaderaria: {
        background: pink.A400,
        textAlign: 'center',
    },
    featureheadertitle: {
        color: 'white',
        textTransform: 'uppercase',
        opacity: '0.8',
        fontSize: '14px',
        fontWeight: '700',
    },
    featureicon: {
        marginRight: '24px',
        background: 'linear-gradient(#248ecc, #040205)',
        boxShadow: '0px 3px 12px rgba(36,142,204,0.68)',
        position: 'relative',
        transition: 'all 0.3s cubic-bezier(0.34, 0.61, 0.59, 1.11) 0.4s',
        height: '50px',
        width: '50px',
        borderRadius: '50%',
        padding: '16px',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        fontSize: '45px',
    },
    featureiconspecial: {
        background: `linear-gradient(${pink.A400}, #040205)`,
        boxShadow: '0px 3px 12px rgba(244,67,54,0.68)',
    },
    whiteText: {
        color: 'white',
    },
    featurecardleft: {
        height: '100%',
        opacity: '0.01',
        transform: 'translate(-20px, -20px)',
        transition: 'all 0.5s cubic-bezier(0.34, 0.61, 0.59, 1.11) 0.2s',
    },
    featurecardright: {
        opacity: '0.01',
        transform: 'translate(20px, -20px)',
        transition: 'all 0.5s cubic-bezier(0.34, 0.61, 0.59, 1.11) 0.2s',
    },
    featurecardhidden: {
        opacity: '0.01',
        transition: 'all 0.5s cubic-bezier(0.34, 0.61, 0.59, 1.11) 0.2s',
    },
    animatefromleft: {
        opacity: '0.01',
        transform: 'translate(-20px, 0px)',
        transition: 'all 0.5s cubic-bezier(0.34, 0.61, 0.59, 1.11) 0.2s',
    },
    animate: {
        opacity: '1',
        transform: 'translate(0px, 0px)',
    },
    linkbutton: {
        cursor: 'pointer',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    innerpaper: {
        display: 'flex',
        padding: '12px',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        maxWidth: '66%',
        margin: 'auto',
    },
    step4: {
        background: 'linear-gradient(to bottom, #f44336 0%,#661c17 100%)',
        '& *': {
            color: 'white',
        },
    },
    headimg: {
        width: '100%',
    },
    footer: {
        position: 'fixed',
        bottom: '-100px',
        left: '0px',
        width: 'calc(100% - 24px)',
        background: 'white',
        boxShadow: '1px 1px 4px rgba(0,0,0,0.5)',
        padding: '12px',
        display: 'flex',
        alignItems: 'center',
        transition: 'all 0.3s cubic-bezier(0.34, 0.61, 0.59, 1.11) 0.4s',
    },
    footeranimated: {
        bottom: '0px',
    },
};

Home.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    classes: PropTypes.shape({}).isRequired,
    i18n: PropTypes.shape({
        changeLanguage: PropTypes.func.isRequired,
    }).isRequired,
};

export default Wrapper(withStyles(styles, { withTheme: true, flip: false })(withTranslation('home')(Home)));
