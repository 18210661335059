import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';
import {
    getScoresWithPoints, getFlagSrc, getAllDivisions, getAllCategories,
} from './Utils';
import api from '../Api';

function FlagsPodium(props) {
    const {
        match, auth, whatIf = { scores: [], shooterId: null }, t,
    } = props;
    const [mode, setMode] = useState('countryCode');
    const [groups, setGroups] = useState(null);
    const [topShooters, setTopShooters] = useState(null);

    useEffect(() => {
        (async () => {
            const promises = await Promise.all([
                api.getScores({ matchId: match.id, auth }),
                api.getShooters({ matchId: match.id, auth }),
                api.getStages({ stages: match.stages }),
            ]);

            const $scores = [...promises.shift(), ...(whatIf.scores || [])];
            const shooters = promises.shift().filter((s) => !s.cancelledParticipation).map((s) => {
                if (s.id !== whatIf.shooterId) return s;
                return { ...s, 'power-factor': whatIf.powerFactor || s['power-factor'] };
            });

            const stages = promises.shift();

            const { scoresOverall } = getScoresWithPoints({
                scores: $scores, shooters, stages, mode: match.scoreMode,
            });

            const divisions = getAllDivisions({ matchType: match.type });
            const categories = getAllCategories({ matchType: match.type });

            const $topShooters = [];
            divisions.forEach((division) => {
                categories.forEach((category) => {
                    const s = scoresOverall.filter((so) => so.division === division && (category === 'regular' || so.category === category));
                    if (s.length < 3) return;
                    for (let i = 0; i < Math.min(3, s.length); i++) {
                        const shooter = shooters.find((ss) => ss.publicId === s[i].shooterPublicId);
                        $topShooters.push({
                            place: i + 1,
                            division,
                            category,
                            shooterPublicId: s[i].shooterPublicId,
                            shooterName: s[i].shooterName,
                            club: shooter.club,
                        });
                    }
                });
            });

            let $mode = 'countries';
            let $groups = _.uniq($topShooters.map((ts) => ts.shooterPublicId.split('|')[0]));
            if ($groups.length === 1) {
                $mode = 'clubs';
                $groups = _.uniq($topShooters.map((ts) => _.trim(ts.club)));
            }

            setMode($mode);
            setGroups($groups);
            setTopShooters($topShooters);
        })();
    }, []);

    if (match.scoresPublished === 'verify-only') return (<div />);

    if (!groups || !topShooters) {
        return (
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <CircularProgress size={75} />
            </div>
        );
    }

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {groups.map((group) => (
                <div key={group} style={{ width: '300px', margin: '12px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {(mode === 'countries') && (
                            <img
                                alt={group}
                                src={getFlagSrc({ countryCode: group, size: 256 })}
                            />
                        )}
                        {(mode === 'clubs') && (
                            <div style={{ textAlign: 'center' }}>
                                <Typography variant='h5'>
                                    {group}
                                    &nbsp;
                                    (
                                    {topShooters.filter((ts) => _.trim(ts.club) === group).length}
                                    )
                                </Typography>
                            </div>
                        )}
                        {topShooters.map((ts) => {
                            if ((mode === 'countries') && (ts.shooterPublicId.indexOf(group) !== 0)) return null;
                            if ((mode === 'clubs') && (_.trim(ts.club) !== group)) return null;
                            return (
                                <div key={ts.shooterName} style={{ marginTop: '6px', display: 'flex' }}>
                                    <div>
                                        {ts.place === 1 && <i style={{ color: '#ffd700', fontSize: '36px', marginBottom: '12px' }} className='fas fa-trophy' />}
                                        {ts.place === 2 && <i style={{ color: '#c0c0c0', fontSize: '36px', marginBottom: '12px' }} className='fas fa-medal' />}
                                        {ts.place === 3 && <i style={{ color: '#cd7f32', fontSize: '36px', marginBottom: '12px' }} className='fas fa-medal' />}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', margin: '0px 6px' }}>
                                        <div>
                                            {ts.shooterPublicId.split('|')[1]}
                                            &nbsp;|&nbsp;
                                            {ts.shooterName}
                                        </div>
                                        <Typography variant='caption'>
                                            {t(`generic:division-${ts.division}`)}
                                            &nbsp;-&nbsp;
                                            {_.startCase(ts.category)}
                                        </Typography>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            ))}
        </div>
    );
}

FlagsPodium.propTypes = {
    t: PropTypes.func.isRequired,
    auth: PropTypes.string,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        scoreMode: PropTypes.string.isRequired,
        scoresPublished: PropTypes.string.isRequired,
        startDate: PropTypes.shape({
            day: PropTypes.number.isRequired,
            month: PropTypes.number.isRequired,
            year: PropTypes.number.isRequired,
        }).isRequired,
        locale: PropTypes.string.isRequired,
        teams: PropTypes.arrayOf(PropTypes.shape({})),
        stages: PropTypes.arrayOf(PropTypes.string),
        icsEnabled: PropTypes.bool,
    }).isRequired,
    whatIf: PropTypes.arrayOf(PropTypes.shape({})),
};

FlagsPodium.defaultProps = {
    auth: null,
    whatIf: [],
};

export default withTranslation('')(FlagsPodium);
