const lang = {
    en: {
        title: 'Squads',
        'squad-name': 'Squad Name',
        'squad-description': 'Squad Description',
        'new-squad-title': 'New Squad...',
        'new-shooter-title': 'Add New Shooter to Squad',
        'new-squad-shooter-id': 'Shooter Id',
        'new-squad-shooter-name': 'Shooter Name',
        'new-squad-shooter-club': 'Shooter Club',
        'squad-max-shooters': 'Maximum number of shooters in squad',
        'squad-first-stage': 'Squad\'s first stage (for IROA ordering)',
        published: 'Squads and shooters list can be viewed by shooters',
        nosquads: 'We still haven\'t set up our squads. Come back later, and we\'ll be sure to have them.',
        newsquadtitle: 'New Squad',
        'delete-non-empty-squad': 'Opps. You tried to delete a squad with shooters in it. Please remove shooters first.',
        numshooters: '{{count}} shooter',
        numshooters_plural: '{{count}} shooters',
        reorder: 'Reorder',
        ro: 'This squad is for ROs and Match officials only',
    },
    he: {
        title: 'סקוודים',
        'squad-name': 'שם הסקווד',
        'squad-description': 'תיאור',
        'new-squad-title': 'סקווד חדש...',
        'new-shooter-title': 'הוספת יורה לסקווד',
        'new-squad-shooter-id': 'מספר יורה',
        'new-squad-shooter-name': 'שם',
        'new-squad-shooter-club': 'מועדון',
        'squad-max-shooters': 'מספר יורים בסקווד',
        'squad-first-stage': 'תרגיל ראשון לסקווד (עבור סידור IROA)',
        published: 'הסקוודים ורשימת היורים מוכנים להצגה באתר',
        nosquads: 'עדיין לא סידרנו את הסקוודים שלנו. חזרו קצת מאוחר יותר, ותוכלו לראות אותם פה',
        newsquadtitle: 'הוספת סקווד',
        'delete-non-empty-squad': 'אופס. ניסיתם למחוק סקווד עם יורים. אנא העבירו את היורים לסקווד אחר לפני.',
        numshooters_0: 'יורה אחד\\ת',
        numshooters_1: '{{count}} יורים',
        numshooters_2: '{{count}} יורים',
        numshooters_3: '{{count}} יורים',
        reorder: 'סדר',
        ro: 'סקווד זה לשופטים\\ות, אנשי ונשות מנהלה בלבד.',
    },
};

lang.iw = {
    ...lang.he,
    ...{
        title: 'מקצים',
        'squad-name': 'שם המקצה',
        'squad-description': 'תיאור',
        'new-squad-title': 'מקצה חדש...',
        'new-shooter-title': 'הוספת לוחם\\ת למקצה',
        'squad-max-shooters': 'מספר לוחמים במקצה',
        'squad-first-stage': 'תרגיל ראשון למקצה (עבור סידור IROA)',
        published: 'המקצים ורשימת הלוחמים מוכנים להצגה באתר',
        nosquads: 'עדיין לא סידרנו את המקצהים שלנו. חזרו קצת מאוחר יותר, ותוכלו לראות אותם פה',
        newsquadtitle: 'הוספת מקצה',
        'delete-non-empty-squad': 'אופס. ניסיתם למחוק מקצה עם לוחמים. אנא העבירו את הלוחמים למקצה אחר לפני.',
        'new-squad-shooter-id': 'מספר אישי',
        numshooters_0: 'לוחם\\ת אחד\\ת',
        numshooters_1: '{{count}} לוחמים',
        numshooters_2: '{{count}} לוחמים',
        numshooters_3: '{{count}} לוחמים',
        'new-squad-shooter-club': 'יחידה',
    },
};

export default lang;
