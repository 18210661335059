module.exports = {
    en: {
        welcomeback: 'Hey, welcome back!',
        question: 'Do you want to resume from where you last left off, or go back to our main page?',
        resume: 'Resume where I last left off',
        startnew: 'Back to main page',
    },
    he: {
    },
};
