module.exports = {
    en: {
        past: 'Past Matches',
        present: 'This Week\'s Matches',
        future: 'Up & Coming Matches',
    },
    he: {
        past: 'תחרויות עבר',
        present: 'תחרויות השבוע',
        future: 'תחרויות עתידיות',
    },
};
