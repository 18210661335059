import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pathToRegexp } from 'path-to-regexp';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import green from '@material-ui/core/colors/green';
import TimerIcon from '@material-ui/icons/Timer';
import ListAlt from '@material-ui/icons/ListAlt';
import ShootersAutosuggest from './ShootersAutosuggest';
import ScoreSquad from './Score.squad';

class ScoreShooter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startTimer: null,
        };
    }

    componentDidMount() {
        this.updateHeader();
    }

    componentDidUpdate(prevProps, prevState) {
        /* eslint-disable react/destructuring-assignment */
        if ((prevState.startTimer !== this.state.startTimer)
            || (prevProps.stageIdx !== this.props.stageIdx)
            || (prevProps.squadId !== this.props.squadId)
            || (prevProps.squads !== this.props.squads)) {
            this.updateHeader();
        }
        /* eslint-enable react/destructuring-assignment */
    }

    updateHeader = () => {
        const {
            t, squads, setTitle, setSaveButtonCallback, setBackButtonVisible,
            setExtraButtons, stageIdx, squadId, onBriefing,
        } = this.props;
        const {
            startTimer,
        } = this.state;

        if (!squadId) {
            setTitle(`${t('managewrapper:scores')} - ${t('stage')} ${stageIdx}`);
            setExtraButtons(null);
            setSaveButtonCallback(null);
            setBackButtonVisible(true);
        } else {
            setTitle(`${t('managewrapper:scores')} - ${t('stage')} ${stageIdx} - ${squads.find((s) => s.id === squadId).title}`);
            const time = parseInt((new Date().getTime() - (startTimer || 0)) / 1000, 10);
            setExtraButtons([
                <IconButton key='briefing' color='primary' onClick={onBriefing}>
                    <ListAlt />
                </IconButton>,
                <Button
                    key='timer'
                    color='primary'
                    onClick={() => {
                        if (startTimer) {
                            this.setState({ startTimer: null });
                        } else {
                            this.setState({ startTimer: new Date().getTime() }, () => {
                                const updateTimer = () => {
                                    const { startTimer: $startTime } = this.state;
                                    const { pathname } = window.location;
                                    if (!$startTime) return;
                                    if (!pathToRegexp('/:matchId/manage/scores/stage/:stageIdx/squad/:squadId').exec(pathname)) {
                                        this.setState({ startTimer: null });
                                        return;
                                    }
                                    this.setState({ startTimer: $startTime + 1 }); // We use ms to tick
                                    setTimeout(() => updateTimer(), 1000);
                                };
                                updateTimer();
                            });
                        }
                    }}
                >
                    <TimerIcon />
                    {startTimer && (
                    <div>
                        {_.padStart(parseInt(time / 60, 10), 2, '0')}
                        :
                        {_.padStart(time % 60, 2, '0')}
                    </div>
                    )}
                </Button>,
            ]);
            setSaveButtonCallback(null);
            setBackButtonVisible(true);
        }
    }

    renderSquad() {
        const {
            t, i18n, match, shooters, scores, shootersDqed, shootersMissingFromBriefing, shootersSorting, onShooter, onEditShooter, onShooterMissing, onSorting,
        } = this.props;

        const $shootersSorting = shootersSorting || shooters.map((s) => s.id);

        const missingList = $shootersSorting
            .map((sId) => shooters.find((s) => s.id === sId))
            .filter((s) => shootersMissingFromBriefing.indexOf(s.id) > -1)
            .filter((s) => !shootersDqed[s.id]);

        const dqs = $shootersSorting
            .map((sId) => shooters.find((s) => s.id === sId))
            .filter((s) => !!shootersDqed[s.id]);

        const unpaid = match.payment === 'none' ? [] : $shootersSorting
            .map((sId) => shooters.find((s) => s.id === sId))
            .filter((s) => !s.paid);

        return (
            <div>
                <div style={{ display: onSorting ? 'flex' : 'none', justifyContent: 'space-between' }}>
                    <div>
                        <Button color='primary' onClick={() => onSorting(null)}>
                            {t('sort-reset')}
                        </Button>
                        <Button color='primary' onClick={() => onSorting('round-robin')}>
                            {t('sort-by-round-robin')}
                        </Button>
                        {i18n.dir() === 'ltr' && (
                        <Button color='primary' onClick={() => onSorting('random')}>
                            {t('sort-by-random')}
                        </Button>
                        )}
                    </div>
                </div>
                {$shootersSorting
                    .map((sId) => shooters.find((s) => s.id === sId))
                    .filter((s) => match.payment === 'none' || s.paid)
                    .filter((s) => shootersMissingFromBriefing.indexOf(s.id) === -1)
                    .filter((s) => !shootersDqed[s.id])
                    .map((shooter) => (
                        <div key={shooter.id} style={{ display: 'flex', marginTop: '12px' }}>
                            <Button variant='contained' color='primary' onClick={() => onShooter(shooter.id)} style={{ flex: 1 }}>
                                <div style={{
                                    display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center',
                                }}
                                >
                                    {scores.find((s) => s.shooterId === shooter.id)
                                        ? scores.find((s) => s.shooterId === shooter.id).dq
                                            ? <i style={{ fontSize: '24px', padding: '0px 12px' }}>DQ</i>
                                            : scores.find((s) => s.shooterId === shooter.id).signature
                                                ? <i className='fas fa-check' style={{ fontSize: '24px', padding: '0px 12px' }} />
                                                : <i className='fas fa-exclamation' style={{ fontSize: '24px', padding: '0px 12px' }} />
                                        : null}
                                    <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
                                        <Typography variant='h6' style={{ color: 'inherit' }}>
                                            {shooter.publicId}
                                            {' '}
                                            /
                                            {' '}
                                            {shooter.name}
                                        </Typography>
                                        <Typography variant='body1' style={{ color: 'inherit' }}>
                                            {[
                                                shooter.division ? t(`generic:division-${shooter.division}`) : '',
                                                shooter.category ? t(`generic:category-${shooter.category}`) : '',
                                                shooter['power-factor'] ? t(`generic:power-factor-${shooter['power-factor']}`) : '',
                                            ].filter((s) => !!s).join(' | ')}
                                        </Typography>
                                    </div>
                                </div>
                            </Button>
                            {onShooterMissing && (
                            <Button color='secondary' onClick={() => onShooterMissing(shooter.id, true)} style={{ fontSize: '24px' }}>
                                <i className='fas fa-user-times' />
                            </Button>
                            )}
                            {onEditShooter && (
                            <Button color='primary' onClick={() => onEditShooter(shooter.id)} style={{ fontSize: '24px' }}>
                                <i className='fas fa-user-edit' />
                            </Button>
                            )}
                        </div>
                    ))}
                {!_.isEmpty(missingList) && (
                    <div style={{
                        borderBottom: '1px solid rgba(0,0,0,0.2)', paddingTop: '12px', paddingBottom: '12px', width: '100%',
                    }}
                    />
                )}
                {missingList.map((shooter) => (
                    <div key={shooter.id} style={{ display: 'flex', marginTop: '12px' }}>
                        <Button disabled variant='contained' color='primary' onClick={() => onShooter(shooter.id)} style={{ flex: 1 }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant='h6' style={{ color: 'inherit' }}>
                                    {shooter.publicId}
                                    {' '}
                                    /
                                    {' '}
                                    {shooter.name}
                                </Typography>
                                <Typography variant='body1' style={{ color: 'inherit' }}>
                                    {[
                                        shooter.division ? t(`generic:division-${shooter.division}`) : '',
                                        shooter.category ? t(`generic:category-${shooter.category}`) : '',
                                        shooter['power-factor'] ? t(`generic:power-factor-${shooter['power-factor']}`) : '',
                                    ].filter((s) => !!s).join(' | ')}
                                </Typography>
                            </div>
                        </Button>
                        {onShooterMissing && (
                            <Button color='primary' onClick={() => onShooterMissing(shooter.id, false)} style={{ fontSize: '24px' }}>
                                <i className='fas fa-user-check' style={{ color: green[500] }} />
                            </Button>
                        )}
                        {onEditShooter && (
                            <Button disabled color='primary' onClick={() => onShooter(shooter.id)} style={{ fontSize: '24px' }}>
                                <i className='fas fa-user-edit' />
                            </Button>
                        )}
                    </div>
                ))}
                {!_.isEmpty(dqs) && (
                    <div>
                        <div style={{
                            borderBottom: '1px solid rgba(0,0,0,0.2)', paddingTop: '12px', paddingBottom: '12px', width: '100%',
                        }}
                        />
                        <Typography variant='h6' style={{ textAlign: 'center', marginTop: '12px' }}>DQ</Typography>
                    </div>
                )}
                {dqs.map((shooter) => (
                    <div key={shooter.id} style={{ display: 'flex', marginTop: '12px' }}>
                        <Button disabled variant='contained' color='primary' onClick={() => onShooter(shooter.id)} style={{ flex: 1 }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant='h6' style={{ color: 'inherit' }}>
                                    {shooter.publicId}
                                    {' '}
                                    /
                                    {' '}
                                    {shooter.name}
                                </Typography>
                                <Typography variant='body1' style={{ color: 'inherit' }}>
                                    {t('generic:stage_number')}
                                    {' '}
                                    {shootersDqed[shooter.id]}
                                </Typography>
                            </div>
                        </Button>
                    </div>
                ))}
                {!_.isEmpty(unpaid) && (
                    <div>
                        <div style={{
                            borderBottom: '1px solid rgba(0,0,0,0.2)', paddingTop: '12px', paddingBottom: '12px', width: '100%',
                        }}
                        />
                        <Typography variant='h6' style={{ textAlign: 'center', marginTop: '12px' }}>{t('unpaid')}</Typography>
                    </div>
                )}
                {unpaid.map((shooter) => (
                    <div key={shooter.id} style={{ display: 'flex', marginTop: '12px' }}>
                        <Button disabled variant='contained' color='primary' onClick={() => onShooter(shooter.id)} style={{ flex: 1 }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant='h6' style={{ color: 'inherit' }}>
                                    {shooter.publicId}
                                    {' '}
                                    /
                                    {' '}
                                    {shooter.name}
                                </Typography>
                                <Typography variant='body1' style={{ color: 'inherit' }}>
                                    {[
                                        shooter.division ? t(`generic:division-${shooter.division}`) : '',
                                        shooter.category ? t(`generic:category-${shooter.category}`) : '',
                                        shooter['power-factor'] ? t(`generic:power-factor-${shooter['power-factor']}`) : '',
                                    ].filter((s) => !!s).join(' | ')}
                                </Typography>
                            </div>
                        </Button>
                    </div>
                ))}
            </div>
        );
    }

    render() {
        const {
            t, shooters, squads, squadId, onShooter, onSquad,
        } = this.props;

        if (squadId) {
            return this.renderSquad();
        }

        return (
            <div>
                <div style={{ marginTop: '24px', textAlign: 'center' }}>
                    <Typography variant='h6'>
                        {t('pickshooter')}
                    </Typography>
                    <ShootersAutosuggest shooters={shooters.filter((s) => !s.cancelledParticipation)} onSelect={(shooter) => { onShooter(shooter.id); }} />
                    <ScoreSquad squads={squads} onSquad={(squad) => onSquad(squad.id)} />
                </div>
            </div>
        );
    }
}

ScoreShooter.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        dir: PropTypes.func.isRequired,
    }).isRequired,
    stageIdx: PropTypes.number.isRequired,
    squadId: PropTypes.string,
    match: PropTypes.shape({
        payment: PropTypes.string,
    }).isRequired,
    squads: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    })).isRequired,
    shooters: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        publicId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        division: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        'power-factor': PropTypes.string.isRequired,
    })).isRequired,
    scores: PropTypes.arrayOf(PropTypes.shape({
        shooterId: PropTypes.string.isRequired,
        signature: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })).isRequired,
    shootersDqed: PropTypes.objectOf(PropTypes.bool).isRequired,
    shootersMissingFromBriefing: PropTypes.arrayOf(PropTypes.string).isRequired,
    shootersSorting: PropTypes.arrayOf(PropTypes.string),
    onSquad: PropTypes.func.isRequired,
    onShooter: PropTypes.func.isRequired,
    onEditShooter: PropTypes.func,
    onShooterMissing: PropTypes.func,
    onSorting: PropTypes.func,
    onBriefing: PropTypes.func,
    setTitle: PropTypes.func.isRequired,
    setBackButtonVisible: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func.isRequired,
};

ScoreShooter.defaultProps = {
    squadId: null,
    shootersSorting: null,
    onEditShooter: null,
    onShooterMissing: null,
    onSorting: null,
    onBriefing: null,
};

export default withTranslation('score')(ScoreShooter);
