import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PeopleIcon from '@material-ui/icons/People';

class ScoreDq extends Component {
    componentDidMount() {
        const {
            t, stageIdx, shooters, shooterId, onShowNextShooters,
            setTitle, setExtraButtons, setSaveButtonCallback, setBackButtonVisible,
        } = this.props;

        setTitle(`${t('managewrapper:scores')} - ${t('stage')} ${stageIdx} / ${shooters.find((s) => s.id === shooterId).name}`);
        setExtraButtons([
            <Button key='shooters' color='primary' size='large' onClick={onShowNextShooters}>
                <PeopleIcon />
            </Button>,
        ]);
        setSaveButtonCallback(null);
        setBackButtonVisible(true);
    }

    render() {
        const {
            t, onFilter, filter, onRule,
        } = this.props;

        return (
            <div style={{
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '24px',
            }}
            >
                <Typography variant='h6'>
                    {t('pickreason')}
                </Typography>
                { !filter && ['10.4', '10.5', '10.6', '10.7'].map((rule) => (
                    <Button
                        key={rule}
                        variant='contained'
                        color='primary'
                        size='large'
                        onClick={() => onFilter(rule)}
                        style={{
                            textTransform: 'none', direction: 'ltr', width: '332px', marginBottom: '24px',
                        }}
                    >
                        {t(`dq-${rule.replace(/\./g, '-')}`)}
                    </Button>
                ))}
                { filter && ['10.4.1', '10.4.2', '10.4.3', '10.4.4', '10.4.5', '10.4.6', '10.4.7', '10.5.1', '10.5.2', '10.5.3', '10.5.4', '10.5.5', '10.5.6', '10.5.7', '10.5.8', '10.5.9', '10.5.10', '10.5.11', '10.5.12', '10.5.13', '10.5.14', '10.5.15', '10.5.16', '10.6.1', '10.6.2', '10.6.3', '10.7.0'].filter((r) => r.startsWith(filter)).map((rule) => (
                    <Button
                        key={rule}
                        variant='contained'
                        color='primary'
                        size='large'
                        onClick={() => onRule(rule)}
                        style={{
                            textTransform: 'none', direction: 'ltr', width: '332px', marginBottom: '24px',
                        }}
                    >
                        {t(`dq-${rule.replace(/\./g, '-')}`)}
                    </Button>
                ))}
            </div>
        );
    }
}

const styles = () => ({
});

ScoreDq.propTypes = {
    t: PropTypes.func.isRequired,
    filter: PropTypes.string,
    classes: PropTypes.shape({}).isRequired,
    i18n: PropTypes.shape({}).isRequired,
    onFilter: PropTypes.func,
    onRule: PropTypes.func,
    stageIdx: PropTypes.number.isRequired,
    shooterId: PropTypes.string.isRequired,
    shooters: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    onShowNextShooters: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    setBackButtonVisible: PropTypes.func.isRequired,
};

ScoreDq.defaultProps = {
    filter: null,
    onRule: null,
    onFilter: null,
};

export default withStyles(styles, { withTheme: true })(withTranslation('score')(ScoreDq));
