import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { withStyles } from '@material-ui/core/styles';

class SaveButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            success: false,
            error: false,
        };
    }

    onClick = async () => {
        const { onClick = () => {} } = this.props;
        await new Promise((res) => this.setState({ saving: true }, res));
        const result = await onClick();
        if (result) {
            await new Promise((res) => this.setState({ saving: false, success: true }, res));
        } else {
            await new Promise((res) => this.setState({ saving: false, error: true }, res));
        }
        await new Promise((res) => setTimeout(res, 2000));
        await new Promise((res) => this.setState({ saving: false, success: false, error: false }, res));
    }

    render() {
        const { classes, label, disabled } = this.props;
        const { saving, success, error } = this.state;

        return (
            <div>
                <Button
                    disabled={saving || disabled}
                    classes={{
                        root: classnames([
                            classes.save,
                            success ? classes.savesuccess : null,
                            error ? classes.saveerror : null,
                        ]),
                    }}
                    variant='contained'
                    color='primary'
                    onClick={this.onClick}
                >
                    {saving && <CircularProgress size={18} />}
                    {success && <DoneIcon />}
                    {error && <CloseIcon />}
                    {!success && !error && !saving && label}
                </Button>
            </div>
        );
    }
}

const styles = () => ({
    save: {
        minWidth: '100px',
        transition: 'background 0.5s',
    },
    savesuccess: {
        pointerEvents: 'none',
        background: green[500],
    },
    saveerror: {
        pointerEvents: 'none',
        background: red[500],
    },
});

SaveButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

SaveButton.defaultProps = {
    disabled: false,
};

export default withStyles(styles, { withTheme: true })(SaveButton);
