import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PeopleIcon from '@material-ui/icons/People';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const POPULAR = [
    '10.2.1', '9.5.6.7', '2.2.1.5.', '8.7.1',
];

const MORE = [
    '10.2.2.', '1.1.5.2.', '10.2.5.', '4.5.1', '10.2.6.', '10.2.8.', '10.2.9.', '10.2.11.', 'general',
];

const OTHERS = [
    '8.6.2', '8.7.2', '9.1.1',
];

class ScorePenalties extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMore: _.some([...MORE, ...OTHERS], (rule) => !!props.penalties[rule]),
            prevValue: JSON.parse(JSON.stringify(props.penalties)),
        };
    }

    componentDidMount() {
        const {
            t, onCancel, stageIdx, shooters, shooterId, onShowNextShooters,
            setTitle, setExtraButtons, setSaveButtonCallback, setBackButtonVisible,
        } = this.props;

        const {
            prevValue,
        } = this.state;

        setTitle(`${t('managewrapper:scores')} - ${t('stage')} ${stageIdx} / ${shooters.find((s) => s.id === shooterId).name}`);
        setExtraButtons([
            <Button key='shooters' color='primary' size='large' onClick={onShowNextShooters}>
                <PeopleIcon />
            </Button>,
            <Button style={{ minWidth: '100px' }} key='back' variant='contained' color='primary' onClick={() => onCancel(prevValue)}>
                {t('generic:cancel')}
            </Button>,
        ]);
        setSaveButtonCallback(null);
        setBackButtonVisible(false);
    }

    renderPlusMinus(type, val, unit, min, max, onUpdate) {
        const { i18n } = this.props;

        const onInc = () => {
            onUpdate(Math.min(val + 1, max));
        };

        const onDec = () => {
            onUpdate(Math.max(val - 1, min));
        };

        return (
            <div
                key={type}
                style={{
                    display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '12px',
                }}
            >
                <div key={type} style={{ display: 'flex', flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row', alignItems: 'center' }}>
                    <Button
                        disabled={val === max}
                        variant='contained'
                        color='primary'
                        style={{
                            minWidth: '40px', padding: '0px', width: '60px', height: '60px',
                        }}
                        onClick={onInc}
                    >
                        <AddIcon />
                    </Button>
                    <div style={{ fontSize: '2em', width: '80px', textAlign: 'center' }}>
                        {val}
                        {unit}
                    </div>
                    <Button
                        disabled={val === min}
                        variant='contained'
                        color='secondary'
                        onClick={onDec}
                        style={{
                            minWidth: '40px', padding: '0px', width: '60px', height: '60px',
                        }}
                    >
                        <RemoveIcon />
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        const { showMore } = this.state;
        const {
            t, classes, onSave, penalties, specialPenalty, onUpdatePenalty, onUpdateSpecialPenalty,
        } = this.props;

        return (
            <div className={classes.wrapper}>
                <Typography variant='h6'>{t('penalties-cof')}</Typography>
                {[...POPULAR, ...(showMore ? MORE : [])].map((type) => (
                    <div key={type} style={{ direction: 'ltr' }}>
                        <Typography variant='caption' style={{ textAlign: 'center' }}>
                            {t(`pen-${_.kebabCase(type)}`)}
                        </Typography>
                        {this.renderPlusMinus(type, penalties[type] || 0, '', 0, 32, onUpdatePenalty.bind(null, type))}
                    </div>
                ))}

                {!showMore && (
                    <Button classes={{ root: classes.showmore }} color='primary' onClick={() => this.setState({ showMore: true })}>
                        {t('penalties-showmore')}
                    </Button>
                )}

                {showMore && (
                    <div style={{ paddingTop: '12px' }}>
                        <Typography variant='h6'>{t('penalties-others')}</Typography>
                        {OTHERS.map((type) => (
                            <div style={{ direction: 'ltr' }}>
                                <Typography variant='caption' style={{ textAlign: 'center' }}>
                                    {t(`pen-${_.kebabCase(type)}`)}
                                </Typography>
                                {this.renderPlusMinus(type, penalties[type] || 0, '', 0, 32, onUpdatePenalty.bind(null, type))}
                            </div>
                        ))}

                        <Typography variant='caption'>
                            {t('pen-10-2-10')}
                        </Typography>
                        {this.renderPlusMinus('10.2.10', specialPenalty || 0, '%', 0, 20, onUpdateSpecialPenalty)}
                    </div>
                )}


                <Button fullWidth variant='contained' color='primary' onClick={onSave}>
                    {t('generic:save')}
                </Button>
            </div>
        );
    }
}

const styles = () => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        marginBottom: '24px',
    },
    showmore: {
        margin: '6px 0px 24px 0px',
        textTransform: 'none',
    },
});

ScorePenalties.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        dir: PropTypes.func.isRequired,
    }).isRequired,
    classes: PropTypes.shape({}).isRequired,
    penalties: PropTypes.objectOf(PropTypes.number).isRequired,
    stageIdx: PropTypes.number.isRequired,
    shooterId: PropTypes.string.isRequired,
    shooters: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    specialPenalty: PropTypes.number,
    onShowNextShooters: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onUpdateSpecialPenalty: PropTypes.func.isRequired,
    onUpdatePenalty: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    setBackButtonVisible: PropTypes.func.isRequired,
};

ScorePenalties.defaultProps = {
    specialPenalty: null,
};

export default withStyles(styles, { withTheme: true })(withTranslation('score')(ScorePenalties));
