import React, { useEffect, useState } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ExploreIcon from '@material-ui/icons/Explore';
import FaceIcon from '@material-ui/icons/Face';
import RunnerIcon from '@material-ui/icons/DirectionsRun';
import SettingsIcon from '@material-ui/icons/Settings';
import Wrapper from './HomeI18nWrapper';
import HomeAppDiscover from './HomeApp.discover';
import HomeAppMe from './HomeApp.me';
import HomeAppQuickScore from './HomeApp.quickscore';
import Aria from './Aria';
import api from '../Api';

function HomeApp(props) {
    const {
        history, match, classes, t, i18n,
    } = props;
    const { screenId = '0' } = match.params;
    const screen = parseInt(screenId, 10);

    const [matches, setMatches] = useState(null);
    const [stages, setStages] = useState(null);

    async function refresh() {
        await setMatches(null);
        await setStages(null);

        const $matches = await api.getMatches();
        const values = Object.keys($matches).map((k) => $matches[k]);
        values.sort((a, b) => (new Date(b.startDate.year, b.startDate.month - 1, b.startDate.day).getTime()) - (new Date(a.startDate.year, a.startDate.month - 1, a.startDate.day).getTime()));
        await setMatches(values);

        const stageIds = _.compact(_.flatten(Object.keys($matches).map((m) => $matches[m].stages)));
        setStages(await api.getStages({ stages: stageIds }));
    }

    useEffect(() => {
        if (typeof (document) !== 'undefined') {
            const dir = 'ltr'; // region.locale === 'he-IL' ? 'rtl' : 'ltr';

            document.body.style.direction = dir;
            document.body.setAttribute('dir', dir);
            document.body.classList.remove('rtl');
            document.body.classList.remove('ltr');
            document.body.classList.add(dir);
        }
        i18n.changeLanguage('en-US'); // region.locale);
        moment.locale('en-us'); // region.locale);

        refresh();
    }, []);

    return (
        <div>
            {screen === 0 && <HomeAppDiscover matches={matches} stages={stages} onRefresh={refresh} {..._.omit(props, 'classes')} />}
            {screen === 1 && <HomeAppMe {..._.omit(props, 'classes')} />}
            {screen === 2 && <HomeAppQuickScore {..._.omit(props, 'classes')} />}
            {screen === 3 && <Aria {..._.omit(props, 'classes')} />}
            <BottomNavigation
                value={screen}
                onChange={(event, value) => history.push(`/app/${value}${window.location.search}`)}
                showLabels
                className={classes.toolbar}
            >
                <BottomNavigationAction label={t('discover')} icon={<ExploreIcon />} />
                <BottomNavigationAction label={t('me')} icon={<FaceIcon />} />
                <BottomNavigationAction label={t('quickscore')} icon={<RunnerIcon />} />
                <BottomNavigationAction
                    label={(
                        <img
                            src='https://www.endofscoring.com/logo.png'
                            alt=''
                            style={{
                                height: '20px',
                                width: '50px',
                            }}
                        />
                    )}
                />
                <BottomNavigationAction style={{ display: 'none' }} label={t('settings')} icon={<SettingsIcon />} />
            </BottomNavigation>
        </div>
    );
}

const styles = () => ({
    toolbar: {
        position: 'fixed',
        bottom: '0px',
        width: '100%',
        zIndex: '20',
        opacity: '0.95',
    },
});

HomeApp.propTypes = {
    t: PropTypes.func.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    classes: PropTypes.shape({}).isRequired,
    i18n: PropTypes.shape({
        changeLanguage: PropTypes.func.isRequired,
    }).isRequired,
    name: PropTypes.string,
    auth: PropTypes.string,
    mode: PropTypes.string,
    src: PropTypes.string,
    accessToken: PropTypes.string,
};

HomeApp.defaultProps = {
    name: null,
    auth: null,
    mode: null,
    src: null,
    accessToken: null,
};

export default Wrapper(withStyles(styles, { withTheme: true, flip: false })(withTranslation('homeapp')(HomeApp)));
