import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import axios from 'axios';
import uuidv4 from 'uuid/v4';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Route } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Paper from '@material-ui/core/Paper';
import blue from '@material-ui/core/colors/blue';
import cyan from '@material-ui/core/colors/cyan';
import grey from '@material-ui/core/colors/grey';
import AddIcon from '@material-ui/icons/Add';
import Wrapper from './HomeI18nWrapper';
import HomeAppQuickScoreMatch from './HomeApp.quickscore.match';
import HomeAppQuickScoreStage from './HomeApp.quickscore.stage';
import HomeAppQuickScoreShooter from './HomeApp.quickscore.shooter';

function HomeAppQuickScore(props) {
    const { classes, t, history } = props;
    const [data, setData] = useState(typeof (window) !== 'undefined'
        ? JSON.parse(window.localStorage.getItem('__homeapp_quickscore_2') || 'null')
        : null);
    const [working, setWorking] = useState(false);

    useEffect(() => {
        window.localStorage.setItem('__homeapp_quickscore_2', JSON.stringify(data));
    }, [data]);

    async function onNewMatch() {
        setWorking(true);
        const index = (data || []).length;

        const user = `com.endofscoring|${uuidv4()}`;
        const password = uuidv4();
        const { data: login } = await axios.post(`/api/logins?auth=${user}|${password}`, { user: `${user}|${password}` });
        const match = {
            title: `QuickScore Match ${index + 1}`,
            alias: Math.random().toString(36).slice(-5),
            startDate: {
                year: (new Date()).getFullYear(),
                month: (new Date()).getMonth() + 1,
                day: (new Date()).getDate(),
            },
            endDate: {
                year: (new Date()).getFullYear(),
                month: (new Date()).getMonth() + 1,
                day: (new Date()).getDate(),
            },
            locale: 'en-US',
            stagesPublished: true,
            scoresPublished: 'full',
            squadsPublished: true,
        };
        const { data: newMatch } = await axios.post(`/api/matches?auth=${login.accessToken}`, match);

        await setData(($data) => {
            const newData = JSON.parse(JSON.stringify($data || []));
            newData.push({ match: { ...newMatch, shooters: [] }, auth: `${user}|${password}` });
            return newData;
        });

        setWorking(false);
        history.push(`/app/2/${index}/0`);
    }

    async function onOpen(index) {
        await new Promise((res) => setTimeout(res, 200)); // For button effect
        history.push(`/app/2/${index}`);
    }

    if (!data || data.length === 0) {
        return (
            <div className={classes.loadingwrapper}>
                <div className={classes.nomatchesbox}>
                    <Typography variant='h4' style={{ color: blue[500], marginBottom: '12px' }}>
                        {t('matchnotusingaria')}
                    </Typography>
                    <Typography variant='h6' style={{ color: grey[800], marginBottom: '12px' }}>
                        {t('usefortraining')}
                    </Typography>
                    <Typography variant='body2' style={{ color: grey[800] }}>
                        {t('description1')}
                    </Typography>
                    <div className={classes.newmatchbutton} style={{ marginTop: '12px' }}>
                        <Button variant='contained' disabled={working} color='primary' onClick={() => onNewMatch()}>
                            {working && <CircularProgress size={24} />}
                            {!working && <div>{t('createnewmatch')}</div>}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Route
                path='/app/2'
                exact
                render={() => (
                    <div style={{ paddingBottom: '70px' }}>
                        <div className={classes.topbar}>
                            <Button variant='text' color='primary' disabled>
                                <Typography variant='body2' style={{ color: cyan[500] }}>
                                    {t('homeapp:quickscore')}
                                </Typography>
                            </Button>
                        </div>
                        <Typography variant='body2' style={{ color: grey[500], margin: '0px 12px', marginTop: '12px' }}>{t('matches')}</Typography>
                        {data.map(({ match }, index) => (
                            <Paper key={match.created} classes={{ root: classes.matchitemwrapper }}>
                                <ButtonBase classes={{ root: classes.matchitem }} onClick={() => onOpen(index)}>
                                    <div style={{ flex: 1 }}>
                                        <Typography variant='subtitle2'>
                                            {match.title}
                                        </Typography>
                                        <Typography variant='caption'>
                                            {`${moment(match.created).format('L')} | ${t('numstages', { count: (match.stages || []).length })}`}
                                        </Typography>
                                    </div>
                                    <div>
                                        <i className='fas fa-chevron-right' style={{ fontSize: '18px' }} />
                                    </div>
                                </ButtonBase>
                            </Paper>
                        ))}
                        <div className={classes.newmatch}>
                            <Fab disabled={working} color='primary' onClick={() => onNewMatch()}>
                                {working && <CircularProgress size={24} />}
                                {!working && <AddIcon />}
                            </Fab>
                            <Typography variant='caption' style={{ color: blue[500], marginTop: '6px' }}>
                                {t('newmatch')}
                            </Typography>
                        </div>
                    </div>
                )}
            />
            <Route
                path='/app/2/:matchIndex'
                exact
                render={({ match }) => <HomeAppQuickScoreMatch {..._.omit(props, ['classes'])} {..._.mapValues(match.params, parseInt)} data={data} setData={setData} />}
            />
            <Route
                path='/app/2/:matchIndex/:stageIndex'
                exact
                render={({ match }) => <HomeAppQuickScoreStage {..._.omit(props, ['classes'])} {..._.mapValues(match.params, parseInt)} data={data} setData={setData} />}
            />
            <Route
                path='/app/2/:matchIndex/:stageIndex/:scoreIndex'
                exact
                render={({ match }) => <HomeAppQuickScoreShooter {..._.omit(props, ['classes'])} {..._.mapValues(match.params, parseInt)} data={data} setData={setData} />}
            />
        </div>
    );
}

const styles = () => ({
    loadingwrapper: {
        width: '100vw',
        height: 'calc(100vh - 56px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    nomatchesbox: {
        textAlign: 'center',
        width: '90%',
    },
    newmatchbutton: {
        marginTop: '6px',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'start',
    },
    topbar: {
        height: '45px',
        background: 'white',
        display: 'flex',
        alignItems: 'center',
    },
    matchitemwrapper: {
        margin: '12px',
        marginTop: '6px',
    },
    matchitem: {
        padding: '12px',
        textAlign: 'start',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    newmatch: {
        textAlign: 'center',
        margin: '12px',
        padding: '12px',
        background: 'rgba(255, 255, 255, 0.5)',
    },
});

export default Wrapper(withStyles(styles, { withTheme: true, flip: false })(withTranslation('homeappquickscore')(HomeAppQuickScore)));

HomeAppQuickScore.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    t: PropTypes.func.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    mode: PropTypes.string,
};

HomeAppQuickScore.defaultProps = {
    mode: '',
};
