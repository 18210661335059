import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '../components/WorkingButton';
import Snackbar from '../components/Snackbar';
import api from '../Api';

class NewClub extends Component {
    constructor(props) {
        const { setTitle, t, region } = props;
        super(props);
        setTitle(t('title', { name: region.title }));
        this.state = {
            clubTitle: '',
            clubAlias: '',
            error: null,
            errors: {},
            working: false,
        };
    }

    onAdd = async () => {
        const {
            t, region, auth,
        } = this.props;
        const { clubTitle, clubAlias } = this.state;

        if (!clubTitle) {
            if (!clubAlias) {
                this.setState({ errors: { clubTitle: true, clubAlias: true } });
                return;
            }
            this.setState({ errors: { clubTitle: true } });
            return;
        } if (!clubAlias) {
            this.setState({ errors: { clubAlias: true } });
            return;
        }

        await new Promise((res) => this.setState({ working: true }, res));

        try {
            const club = await api.postClub({
                auth,
                club: {
                    title: clubTitle,
                    alias: clubAlias,
                    region: region.id,
                },
            });
            window.location.href = `/regions/${region.id}/manage/clubs/${club.id}`;
        } catch (e) {
            if (_.get(e, 'response.data.status') === 409) {
                this.setState({ working: false, error: t('aliasexists'), errors: { clubAlias: true } });
            } else {
                await new Promise((res) => this.setState({ working: false, error: t('generic:saveerror') }, res));
            }
        }
    }

    render() {
        const { t } = this.props;
        const {
            clubTitle, clubAlias, errors, error, working,
        } = this.state;

        return (
            <div style={{
                maxWidth: '720px', width: '90%', margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center',
            }}
            >
                <Typography variant='h4'>{t('newclub')}</Typography>
                <div>
                    <FormControl style={{ width: '100%' }}>
                        <FormLabel component='legend' error={errors.clubTitle}>{t('title')}</FormLabel>
                        <TextField
                            autoFocus
                            fullWidth
                            value={clubTitle}
                            onChange={(e) => this.setState({
                                clubTitle: e.target.value,
                                errors: { ...errors, clubTitle: false },
                            })}
                            onKeyDown={(e) => { if (e.keyCode === 13) this.aliasField.focus(); }}
                            error={errors.clubTitle}
                        />
                    </FormControl>

                    <FormControl style={{ width: '100%', marginTop: '24px' }}>
                        <FormLabel component='legend' error={errors.clubAlias}>{t('alias')}</FormLabel>
                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                            <div style={{ position: 'relative', top: '-6px' }}>
                                http://www.endofscoring.com/clubs/&nbsp;
                            </div>

                            <TextField
                                value={clubAlias}
                                onChange={(e) => this.setState({
                                    clubAlias: e.target.value,
                                    errors: { ...errors, clubAlias: false },
                                })}
                                error={errors.clubAlias}
                                inputRef={(e) => { this.aliasField = e; }}
                            />
&nbsp;/
                        </div>
                    </FormControl>
                    <Button working={working} variant='contained' color='primary' style={{ margin: '24px 0px' }} fullWidth onClick={this.onAdd}>
                        {t('add')}
                    </Button>
                </div>

                <Snackbar
                    open={!!error}
                    onClose={() => this.setState({ error: null })}
                    variant='error'
                    message={error}
                />
            </div>
        );
    }
}

const styles = {};

NewClub.propTypes = {
    setTitle: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    auth: PropTypes.string.isRequired,
    region: PropTypes.shape({
        title: PropTypes.string,
        id: PropTypes.number,
    }).isRequired,
};

export default withStyles(styles, { withTheme: true })(withTranslation('newclub')(NewClub));
