import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

class ClubsManageHome extends Component {
    constructor(props) {
        const {
            setTitle, setSaveButtonCallback, t, region,
        } = props;
        super(props);
        setTitle(t('title', { name: region.title }));
        setSaveButtonCallback(this.onSave);

        this.state = {
        };
    }

    render() {
        return (
            <Grid
                container
                spacing={8}
                style={{
                    marginBottom: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                }}
            />
        );
    }
}

const styles = () => ({
});

ClubsManageHome.propTypes = {
    setTitle: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    region: PropTypes.shape({
        title: PropTypes.string.isRequired,
    }).isRequired,
};

export default withStyles(styles, { withTheme: true })(withTranslation('regionsmanagehome')(ClubsManageHome));
