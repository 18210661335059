import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import PaymentIcon from '@material-ui/icons/Payment';
import PeopleIcon from '@material-ui/icons/People';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SupervisorsIcon from '@material-ui/icons/SupervisorAccount';
import Publishing from './Settings.publishing';
import SquadSelection from './Settings.squadselection';
import Scoring from './Settings.scoring';
import Payment from './Settings.payment';
import Registration from './Settings.registration';
import Notifications from './Settings.notifications';
import Contacts from './Settings.contacts';
import api from '../Api';

class Settings extends Component {
    constructor(props) {
        const { match } = props;
        super(props);

        this.state = {
            page: 0,
            scoresPublished: match.scoresPublished || null,
            squadsPublished: match.squadsPublished || false,
            stagesPublished: match.stagesPublished || false,
            nameAnonymizationScheme: match.nameAnonymizationScheme || 'lastname',
            registration: match.registration || 'closed',
            roRegistration: match.roRegistration || 'closed',
            registrationExtraFields: match.registrationExtraFields || [],
            registrationPatchLockedFields: match.registrationPatchLockedFields || [],
            registrationLimitGSheet: match.registrationLimitGSheet || '',
            registrationExcludeDivisions: match.registrationExcludeDivisions || [],
            registrationCode: match.registrationCode || null,
            icsEnabled: match.icsEnabled || false,
            squadSelection: match.squadSelection || 'closed',
            payment: match.payment || 'none',
            paymentOfflineDescription: match.paymentOfflineDescription || '',
            paymentAmount: ((match.paymentAmount || 0) / 100).toFixed(2),
            paymentCurrency: match.paymentCurrency || 'USD',
            paymentParams: match.paymentParams || {},
            notifications: match.notifications || {},
            contacts: match.contacts || {},
            locale: match.locale || 'en-US',
            scoreFormDisplayMode: match.scoreFormDisplayMode || 'normal',
            scoreMode: match.scoreMode || 'comstock',
        };
    }

    componentDidMount() {
        const {
            setTitle, setSaveButtonCallback, setExtraButtons, t, match,
        } = this.props;
        setTitle(t('title', { name: match.title }));
        setSaveButtonCallback(this.onSave);
        setExtraButtons(null);
    }

    onSave = async ({ setState }) => {
        const { match, auth, me } = this.props;
        setState({ saving: true });

        const patch = _.pick(this.state, ['scoresPublished',
            'squadsPublished',
            'stagesPublished',
            'nameAnonymizationScheme',
            'roRegistration',
            'registration',
            'registrationExtraFields',
            'registrationPatchLockedFields',
            'registrationLimitGSheet',
            'registrationExcludeDivisions',
            'registrationCode',
            'icsEnabled',
            'squadSelection',
            'notifications',
            'contacts',
            'scoreFormDisplayMode',
            'scoreMode',
        ]);

        /* eslint-disable react/destructuring-assignment */
        if (me.role === 'owner') {
            patch.payment = this.state.payment;
            if (patch.payment === 'online') {
                patch.paymentParams = this.state.paymentParams;
                patch.paymentAmount = parseInt(this.state.paymentAmount * 100, 10);
                patch.paymentCurrency = this.state.paymentCurrency;
            } else if ((patch.payment === 'offline') && (this.state.paymentOfflineDescription)) {
                patch.paymentOfflineDescription = this.state.paymentOfflineDescription;
            }
        }
        /* eslint-enable react/destructuring-assignment */

        try {
            await api.patchMatch({ matchId: match.id, auth, patch });
            await setState({ saving: false, saveSuccess: true });
        } catch (e) {
            console.error(e);
            await setState({ saving: false, saveError: true });
        }

        await new Promise((res) => setTimeout(res, 3000));
        setState({ saving: false, saveSuccess: false, saveError: false });
    }

    render() {
        const {
            t, me, match,
        } = this.props;
        const { page } = this.state;
        return (
            <div>
                <BottomNavigation
                    value={page}
                    onChange={(e, v) => this.setState({ page: v })}
                    showLabels
                >
                    <BottomNavigationAction label={t('publishing')} icon={<LibraryBooksIcon />} />
                    <BottomNavigationAction label={t('registration')} icon={<PeopleIcon />} />
                    <BottomNavigationAction label={t('squadselection')} icon={<GroupAddIcon />} />
                    <BottomNavigationAction label={t('scoring')} icon={<TrackChangesIcon />} />
                    <BottomNavigationAction label={t('notifications')} icon={<MailOutlineIcon />} />
                    <BottomNavigationAction label={t('contacts')} icon={<SupervisorsIcon />} />
                    {(me.role === 'owner') && <BottomNavigationAction label={t('payment')} icon={<PaymentIcon />} />}
                </BottomNavigation>

                <Grid
                    container
                    spacing={8}
                    style={{
                        margin: '24px 0px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                    }}
                >
                    {page === 0 && (
                        <Grid item md={11} xs={11}>
                            <Publishing {...this.state} onChange={(e) => this.setState(e)} />
                        </Grid>
                    )}
                    {page === 1 && (
                        <Grid item md={11} xs={11}>
                            <Registration {...this.state} matchType={match.type} onChange={(e) => this.setState(e)} />
                        </Grid>
                    )}
                    {page === 2 && (
                        <Grid item md={11} xs={11}>
                            <SquadSelection {...this.state} onChange={(e) => this.setState(e)} />
                        </Grid>
                    )}
                    {page === 3 && (
                        <Grid item md={11} xs={11}>
                            <Scoring {...this.state} onChange={(e) => this.setState(e)} />
                        </Grid>
                    )}
                    {page === 4 && (
                        <Grid item md={11} xs={11}>
                            <Notifications {...this.state} match={match} matchId={match.id} onChange={(e) => this.setState(e)} />
                        </Grid>
                    )}
                    {page === 5 && (
                        <Grid item md={11} xs={11}>
                            <Contacts {...this.state} match={match} matchId={match.id} onChange={(e) => this.setState(e)} />
                        </Grid>
                    )}
                    {page === 6 && (me.role === 'owner') && (
                        <Grid item md={11} xs={11}>
                            <Payment {...this.state} onChange={(e) => this.setState(e)} />
                        </Grid>
                    )}
                </Grid>
            </div>
        );
    }
}

const styles = () => ({});

Settings.propTypes = {
    setTitle: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    auth: PropTypes.string.isRequired,
    me: PropTypes.shape({
        role: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        id: PropTypes.string,
        scoresPublished: PropTypes.string,
        squadsPublished: PropTypes.bool,
        stagesPublished: PropTypes.bool,
        locale: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
        nameAnonymizationScheme: PropTypes.string,
        roRegistration: PropTypes.string,
        registration: PropTypes.string,
        registrationExtraFields: PropTypes.arrayOf(PropTypes.string),
        registrationPatchLockedFields: PropTypes.arrayOf(PropTypes.string),
        registrationLimitGSheet: PropTypes.string,
        registrationExcludeDivisions: PropTypes.arrayOf(PropTypes.string),
        registrationCode: PropTypes.shape,
        icsEnabled: PropTypes.bool,
        squadSelection: PropTypes.string,
        payment: PropTypes.string,
        paymentOfflineDescription: PropTypes.string,
        paymentAmount: PropTypes.number,
        paymentCurrency: PropTypes.string,
        paymentParams: PropTypes.shape({}),
        notifications: PropTypes.shape({}),
        contacts: PropTypes.shape({}),
        scoreFormDisplayMode: PropTypes.string,
        scoreMode: PropTypes.string,
    }).isRequired,
};

export default withStyles(styles, { withTheme: true })(withTranslation('settings')(Settings));
