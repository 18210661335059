/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import ButtonBase from '@material-ui/core/ButtonBase';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import api from '../Api';
import app from '../AppCommunication';
import { pad, getScoresWithPoints, getFlagSrc } from '../matches/Utils';

function Profile(props) {
    const {
        classes, t, onMatchClick, loginId, accessToken,
        name, setName,
        images, setImages,
        publicId, setPublicId,
        registrations, setRegistrations,
        staff, setStaff,
        matches, setMatches,
        stages, setStages,
        shooters, setShooters,
        scores, setScores,
    } = props;

    const [updatingImage, setUpdatingImage] = useState(false);

    async function refresh() {
        setRegistrations(null);
        setStaff(null);
        setMatches(null);
        setStages(null);
        setShooters(null);
        setScores(null);

        if (!loginId || loginId.indexOf('|') === -1) {
            const { login } = accessToken ? await api.getLogin({ accessToken }) : await api.getLogin({ loginId });
            setName(login.name);
            setPublicId(login.publicId);
            setImages(login.images || {});
            const { registrations: $registrations, staff: $staff } = await api.getMatches({ mode: 'as-shooter-or-staff', loginId, accessToken });
            setRegistrations($registrations);
            setStaff($staff);
        } else {
            setName(loginId);
            setPublicId(loginId);
            setImages({});
            const { registrations: $registrations, staff: $staff } = await api.getMatches({ mode: 'as-shooter-or-staff', publicId: loginId, accessToken });
            setRegistrations($registrations);
            setStaff($staff);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!registrations) {
            refresh();
        }
    }, [registrations]);

    useEffect(() => {
        if ((!registrations) || (!staff)) return;

        (async () => {
            const matchesIds = _.uniq([
                ...staff,
                ...registrations.map((r) => r.matchId),
            ]);
            const $matches = await Promise.all(matchesIds.map((m) => api.getMatch({ matchId: m })));
            $matches.sort((a, b) => ((new Date(b.startDate.year, b.startDate.month - 1, b.startDate.day).getTime()) - (new Date(a.startDate.year, a.startDate.month - 1, a.startDate.day).getTime())));
            await setMatches($matches);
        })();
    }, [registrations, staff]);

    useEffect(() => {
        if (!matches) return;
        (async () => {
            const allStages = await api.getStages({ stages: _.compact(_.flatten(matches.map((m) => m.stages))) });
            setStages(allStages);
        })();
        (async () => {
            const allScores = (await Promise.all(matches.map((m) => (m.scoresPublished ? api.getScores({ matchId: m.id }) : Promise.resolve([])))));
            setScores(allScores);
        })();
        (async () => {
            const allShooters = await Promise.all(matches.map((m) => (m.scoresPublished ? api.getShooters({ matchId: m.id }) : Promise.resolve([]))));
            setShooters(allShooters);
        })();
    }, [matches]);

    const fullScores = useMemo(() => {
        if ((!registrations) || (!matches) || (!scores) || (!shooters)) return null;
        return _(registrations)
            .map((r) => {
                const index = matches.findIndex((m) => m.id.toString() === r.matchId.toString());
                const match = matches[index];
                const $stages = match.stages.map((s) => stages.find((ss) => ss.id.toString() === s.toString()));
                const $scores = scores[index];
                const $shooters = shooters[index];
                const { scoresOverall, scoresByStage, dqs } = _.isEmpty(scores) ? { scoresOverall: [], dqs: [] } : getScoresWithPoints({ scores: $scores, shooters: $shooters, stages: $stages });
                return {
                    match,
                    stages: $stages,
                    scoresOverall,
                    scoresByStage,
                    dqs,
                    registration: r,
                };
            })
            .sortBy((a) => -1 * (a.match.startDate.day + a.match.startDate.month * 31 + a.match.startDate.year * 365))
            .value();
    }, [registrations, matches, scores, shooters]);

    const stageScores = _.sortBy(_.compact(_.flatten((fullScores || []).map(({ registration, scoresByStage }) => scoresByStage.map(($stageScores) => $stageScores.find(($s) => $s.shooterId === registration.id))))), [($s) => $s.timestamp]);

    if (!registrations) {
        return (
            <div className={classes.loadingwrapper}>
                <CircularProgress thickness={1} size={72} />
                <Typography variant='caption' style={{ color: blue[500] }}>
                    {t('step1')}
                </Typography>
            </div>
        );
    }

    if (!matches) {
        return (
            <div className={classes.loadingwrapper}>
                <CircularProgress thickness={1} size={72} />
                <Typography variant='caption' style={{ color: blue[500] }}>
                    {t('step2')}
                </Typography>
            </div>
        );
    }

    if (!stages || !shooters || !scores) {
        return (
            <div className={classes.loadingwrapper}>
                <CircularProgress thickness={1} size={72} />
                <Typography variant='caption' style={{ color: blue[500] }}>
                    {t('step3')}
                </Typography>
            </div>
        );
    }

    const avgPercentage = Math.floor(_.sumBy(stageScores, ($s) => $s.percentage) / stageScores.length);
    const alphaPecentage = Math.floor(_.sumBy(stageScores, ($s) => ($s.alphas * 10000) / ((($s.alphas || 0) + ($s.charlies || 0) + ($s.deltas || 0) + ($s.misses || 0)) || 1)) / stageScores.length);

    const divisions = _.uniq(_.compact(registrations.map((r, index) => {
        const match = matches.find((m) => m.id === r.matchId);
        const matchDate = new Date(match.startDate.year, match.startDate.month - 1, match.startDate.day).getTime();
        if ((new Date().getTime() - matchDate > 365 * 24 * 60 * 60 * 1000) && (index !== 0)) return null;
        return r.division;
    }))).map((d) => t(`generic:division-${d}`));

    const categories = _.uniq(_.compact(registrations.map((r) => (r.category === 'regular' ? null : r.category))))
        .map((c) => t(`registration:category-${c}`));

    const handleImageUpload = async () => {
        if (updatingImage) return;
        setUpdatingImage(true);
        const callback = Math.random().toString();
        app({
            cmd: {
                src: 'image-picker',
                params: {
                    callback,
                    destUrl: `/api/logins/profile-image?accessToken=${accessToken}`,
                },
            },
            callbackName: callback,
            callback: async (url) => {
                setUpdatingImage(false);
                if (!url) return;
                setImages({ ...(images || {}), profile: url });
            },
        });
    };

    if (loginId && loginId.indexOf('|') > -1) {
        return (
            <div>
                <div>
                    {publicId}
                </div>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    Match
                                </th>
                                <th>
                                    Date
                                </th>
                                <th>
                                    Name
                                </th>
                                <th>
                                    Division
                                </th>
                                <th>
                                    Category
                                </th>
                                <th>
                                    Pos (category)
                                </th>
                                <th>
                                    % (division)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {(matches || []).map((m) => {
                                const registration = (registrations || []).find((r) => r.matchId === m.id);
                                if (!registration) return null;

                                const date = moment([m.startDate.year, m.startDate.month - 1, m.startDate.day, 12, 0, 0, 0]).format('LL');
                                const { scoresOverall, dqs } = (fullScores || []).find((f) => f.match.id === m.id) || {};
                                const meIndex = (() => {
                                    if (!registration) return { division: -1, category: -1 };
                                    const scoresDivision = (scoresOverall || []).filter(($s) => $s.division === registration.division);
                                    const scoresCategory = scoresDivision.filter(($s) => $s.category === registration.category);
                                    const meDivIndex = scoresDivision.findIndex(($s) => $s.shooterId === registration.id);
                                    const meCatIndex = registration.category === 'regular' ? -1 : scoresCategory.findIndex(($s) => $s.shooterId === registration.id);
                                    return { division: meDivIndex, category: meCatIndex, percentage: meDivIndex === -1 ? 0 : scoresDivision[meDivIndex].percentage };
                                })();

                                const pos = registration.category !== 'regular' ? meIndex.category : meIndex.division;
                                const dq = dqs.find((dq) => dq.shooterId === registration.id);

                                if ((pos === -1) && (!dq)) return null;

                                return (
                                    <tr key={m.id}>
                                        <td>
                                            <a href={`https://www.endofscoring.com/${m.alias}/score-table`} target='_blank'>{m.title}</a>
                                        </td>
                                        <td>
                                            {date}
                                        </td>
                                        <td>
                                            {registration.name}
                                        </td>
                                        <td>
                                            {dq ? '' : registration.division}
                                        </td>
                                        <td>
                                            {dq || registration.category === 'regular' ? '' : registration.category}
                                        </td>
                                        <td>
                                            {dq ? 'DQ' : pos + 1}
                                        </td>
                                        <td>
                                            {dq ? 'DQ' : `${meIndex.percentage / 100}%`}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.loggedinwrapper}>
            <div className={classes.toprow}>
                <ButtonBase
                    classes={{ root: classes.avatar }}
                    style={(images || {}).profile ? { backgroundImage: `url("${images.profile}")` } : {}}
                    onClick={handleImageUpload}
                >
                    {!(images || {}).profile && (<i className='fas fa-user-tie' />)}
                    {updatingImage && (
                        <div
                            style={{
                                position: 'absolute',
                                top: '0px',
                                left: '0px',
                                width: '100%',
                                height: '100%',
                                lineHeight: 1,
                                fontSize: '36px',
                                background: 'rgba(0, 0, 0, 0.5)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: 'white',
                            }}
                        >
                            <CircularProgress size={36} thickness={1} color='inherit' />
                        </div>
                    )}
                </ButtonBase>
                <div className={classes.info}>
                    <div className={classes.singleinfo}>
                        <Typography variant='caption' classes={{ root: classes.infotitle }}>
                            {t('generic:shooter_name')}
                        </Typography>
                        <Typography variant='body2' classes={{ root: classes.infovalue }}>
                            {name}
                        </Typography>
                    </div>
                    <div className={classes.singleinfo}>
                        <Typography variant='caption' classes={{ root: classes.infotitle }}>
                            {t('generic:shooter_region')}
                        </Typography>
                        <Typography variant='body2' classes={{ root: classes.infovalue }}>
                            <img
                                alt={publicId.split('|')[0]}
                                style={{ width: '18px', height: '18px' }}
                                src={getFlagSrc({ countryCode: publicId.split('|')[0], size: 32 })}
                            />
                        </Typography>
                    </div>
                    <div className={classes.singleinfo}>
                        <Typography variant='caption' classes={{ root: classes.infotitle }}>
                            {t('generic:shooter_division')}
                        </Typography>
                        <Typography variant='body2' classes={{ root: classes.infovalue }}>
                            {divisions.join(', ')}
                        </Typography>
                    </div>
                    <div className={classes.singleinfo}>
                        <Typography variant='caption' classes={{ root: classes.infotitle }}>
                            {t('generic:shooter_category')}
                        </Typography>
                        <Typography variant='body2' classes={{ root: classes.infovalue }}>
                            {categories.length === 0 ? t('registration:category-regular') : categories.join(', ')}
                        </Typography>
                    </div>
                </div>
            </div>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <div className={classes.matchessection}>
                        <Typography variant='body2' style={{ color: grey[500], margin: '0px 12px', marginTop: '12px' }}>{t('matchessectiontitle')}</Typography>
                        <Paper style={{ padding: '0px 12px', margin: '12px' }}>
                            {(matches.length === 0) && (
                                <div style={{ textAlign: 'center', padding: '24px' }}>
                                    <i className='far fa-surprise' style={{ fontSize: '56px', marginBottom: '12px' }} />
                                    <Typography variant='h6' style={{ marginBottom: '12px' }}>
                                        {t('nomatchestitle')}
                                    </Typography>
                                    <Typography variant='body2' style={{ marginBottom: '12px' }}>
                                        {t('nomatchesdescription1')}
                                    </Typography>
                                    <Typography variant='body2'>
                                        {t('nomatchesdescription2')}
                                    </Typography>
                                </div>
                            )}
                            {(matches || []).map((m) => {
                                const registration = (registrations || []).find((r) => r.matchId === m.id);
                                const s = (staff || []).indexOf(m.id) > -1;
                                if ((!registration) && (!s)) return null;

                                const date = moment([m.startDate.year, m.startDate.month - 1, m.startDate.day, 12, 0, 0, 0]).format('L');
                                const { scoresOverall } = (fullScores || []).find((f) => f.match.id === m.id) || {};
                                const meIndex = (() => {
                                    if (!registration) return { division: -1, category: -1 };
                                    const scoresDivision = (scoresOverall || []).filter(($s) => $s.division === registration.division);
                                    const scoresCategory = scoresDivision.filter(($s) => $s.category === registration.category);
                                    const meDivIndex = scoresDivision.findIndex(($s) => $s.shooterId === registration.id);
                                    const meCatIndex = registration.category === 'regular' ? -1 : scoresCategory.findIndex(($s) => $s.shooterId === registration.id);
                                    return { division: meDivIndex, category: meCatIndex };
                                })();
                                const pos = Math.max(meIndex.division, meIndex.category);

                                return (
                                    <ButtonBase key={(registration || m).id} classes={{ root: classes.matchitem }} onClick={() => onMatchClick(m.id)}>
                                        <div style={{ flex: 1 }}>
                                            <div style={{ display: 'flex' }}>
                                                <Typography style={{ color: 'inherit' }} variant='body2'>
                                                    {m.title}
                                                </Typography>
                                                {pos === 0 && <i className={`${classes.trophymedal} fas fa-trophy`} />}
                                                {pos === 1 && <i className={`${classes.trophymedal} fas fa-medal`} />}
                                                {pos === 2 && <i className={`${classes.trophymedal} fas fa-medal`} />}
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                {registration && (
                                                    <Typography style={{ color: 'inherit' }} variant='caption'>
                                                        {`${date} | ${t(`generic:division-${registration.division}`)} | ${t(`registration:category-${registration.category}`)}`}
                                                    </Typography>
                                                )}
                                                {s && (
                                                    <Typography
                                                        style={{
                                                            color: 'white',
                                                            background: blue[700],
                                                            padding: '0px 12px',
                                                            margin: registration ? '0px 12px' : '0px',
                                                        }}
                                                        variant='caption'
                                                    >
                                                        {t('staff')}
                                                    </Typography>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <i className='fas fa-chevron-right' style={{ fontSize: '18px' }} />
                                        </div>
                                    </ButtonBase>
                                );
                            })}
                        </Paper>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    {matches.length > 0 && (
                        <div className={classes.statssection}>
                            <Typography variant='body2' style={{ color: grey[500], margin: '0px 12px', marginTop: '12px' }}>{t('statssectiontitle')}</Typography>
                            <Paper style={{ margin: '12px' }}>
                                <div className={classes.matchitem}>
                                    <div style={{ flex: 1 }}>
                                        <Typography style={{ color: 'inherit' }} variant='body2'>
                                            {t('avgpercentage')}
                                        </Typography>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <Typography style={{ color: 'inherit', textAlign: 'end' }} variant='body2'>
                                            {`${pad(avgPercentage / 100, 2)}%`}
                                        </Typography>
                                    </div>
                                </div>
                                <div className={classes.matchitem}>
                                    <div style={{ flex: 1 }}>
                                        <Typography style={{ color: 'inherit' }} variant='body2'>
                                            {t('alphaspecentage')}
                                        </Typography>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <Typography style={{ color: 'inherit', textAlign: 'end' }} variant='body2'>
                                            {`${pad(alphaPecentage / 100, 2)}%`}
                                        </Typography>
                                    </div>
                                </div>
                            </Paper>
                        </div>
                    )}
                </Grid>
            </Grid>
        </div>
    );
}

const styles = () => ({
    loadingwrapper: {
        width: '100vw',
        height: 'calc(100vh - 56px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loggedinwrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        background: grey[200],
    },
    toprow: {
        background: `linear-gradient(to right, ${blue[900]} 0%, ${blue[800]} 100%)`,
        display: 'flex',
        alignItems: 'center',
        padding: '0px 12px',
        width: 'calc(100% - 24px)',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    info: {
        flex: 1,
        display: 'flex',
        margin: '24px',
        flexWrap: 'wrap',
    },
    singleinfo: {
        minWidth: '200px',
        margin: '6px 24px 6px 0px',
    },
    infotitle: {
        color: 'rgba(255, 255, 255, 0.5)',
        fontSize: '12px',
    },
    infovalue: {
        color: 'rgba(255, 255, 255, 0.75)',
        fontSize: '18px',
        lineHeight: '27px',
        height: '27px',
        display: 'flex',
        alignItems: 'center',
    },
    avatar: {
        position: 'relative',
        width: '144px',
        height: '144px',
        color: 'rgba(255, 255, 255, 0.30)',
        display: 'flex',
        fontSize: '144px',
        marginTop: '12px',
        marginBottom: '12px',
        justifyContent: 'center',
        padding: '12px',
        borderRadius: '6px',
        background: 'rgba(0,0,0,0.2)',
        boxShadow: '0px 0px 3px rgba(255,255, 255, 0.3) inset',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
    },
    matchessection: {
        width: '100%',
    },
    statssection: {
        paddingBottom: '24px',
        width: '100%',
    },
    matchitem: {
        padding: '12px',
        textAlign: 'start',
        minHeight: '65px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        borderTop: `1px solid ${grey[300]}`,
        boxSizing: 'border-box',
        '&:nth-of-type(1)': {
            borderTop: 'none',
        },
    },
    trophymedal: {
        color: 'inherit',
        fontSize: '18px',
        marginLeft: '12px',
        opacity: 0.7,
    },
});

Profile.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    t: PropTypes.func.isRequired,
    loginId: PropTypes.string,
    accessToken: PropTypes.string,
    onMatchClick: PropTypes.func,
    name: PropTypes.string,
    setName: PropTypes.func.isRequired,
    publicId: PropTypes.string,
    setPublicId: PropTypes.func.isRequired,
    registrations: PropTypes.array,
    setRegistrations: PropTypes.func.isRequired,
    staff: PropTypes.array,
    setStaff: PropTypes.func.isRequired,
    images: PropTypes.shape({
        profile: PropTypes.string,
    }),
    setImages: PropTypes.func.isRequired,
    matches: PropTypes.array,
    setMatches: PropTypes.func.isRequired,
    stages: PropTypes.array,
    setStages: PropTypes.func.isRequired,
    shooters: PropTypes.array,
    setShooters: PropTypes.func.isRequired,
    scores: PropTypes.array,
    setScores: PropTypes.func.isRequired,
};

Profile.defaultProps = {
    loginId: null,
    accessToken: null,
    name: null,
    publicId: null,
    images: null,
    registrations: null,
    staff: null,
    matches: null,
    stages: null,
    shooters: null,
    scores: null,
};
export default withStyles(styles, { withTheme: true, flip: false })(withTranslation('homeappme')(Profile));
/* eslint-enable react/forbid-prop-types */
