import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import _ from 'lodash';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import grey from '@material-ui/core/colors/grey';
import cyan from '@material-ui/core/colors/cyan';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { summarizeScore } from '../matches/Utils';
import PlusMinus from '../components/PlusMinus';
import HomeAppQuickScoreOnlineHeader from './HomeApp.quickscore.onlineheader';

function HomeAppQuickScoreStage(props) {
    const {
        classes, t, i18n, history, data, matchIndex, stageIndex, setData,
    } = props;

    const [showDelete, setShowDelete] = useState(false);
    const [working, setWorking] = useState(false);

    const matchData = data[matchIndex].match;
    const { shooters } = matchData;
    const { auth } = data[matchIndex];
    const stageData = (matchData.stages || [])[stageIndex] || { matchId: `${matchData.id}`, targets: { 'paper-no-shoots': 100 }, scores: [] };

    const [stage, setStage] = useState(stageData);
    const [edit, setEdit] = useState(typeof (stageData.targets.paper) === 'undefined' && typeof (stageData.targets.poppers) === 'undefined');
    const [title, setTitle] = useState(stageData.title || `Stage ${stageIndex + 1}`);

    const otherStagesShooters = _(shooters)
        .reject((s) => _.some(stageData.scores, (s2) => s2.shooter === s.id))
        .value();

    function onInc(type) {
        setStage(($stage) => {
            const newData = JSON.parse(JSON.stringify($stage));
            newData.targets[type] = newData.targets[type] || 0;
            newData.targets[type]++;
            return newData;
        });
    }

    function onDec(type) {
        setStage(($stage) => {
            const newData = JSON.parse(JSON.stringify($stage));
            newData.targets[type] = newData.targets[type] || 0;
            newData.targets[type]--;
            return newData;
        });
    }

    async function onAddScore(shooter) {
        const index = matchData.stages[stageIndex].scores.length;
        await setData(($data) => {
            const newData = JSON.parse(JSON.stringify($data));
            if (!shooter) {
                newData[matchIndex].match.stages[stageIndex].scores.push({
                    title: `Shooter ${index + 1}`,
                    created: new Date().getTime(),
                });
            } else {
                newData[matchIndex].match.stages[stageIndex].scores.push({
                    title: `Shooter ${index + 1}`,
                    created: new Date().getTime(),
                    shooter: shooter.id,
                });
            }
            return newData;
        });
        history.push(`/app/2/${matchIndex}/${stageIndex}/${index}`);
    }

    async function onSave() {
        setWorking(true);
        stage.title = title;
        stage.matchId = `${matchData.id}`;
        stage.rounds = (stage.targets.paper || 0) * 2 + (stage.targets.poppers || 0);
        const { data: login } = await axios(`/api/logins?auth=${auth}`);
        if (!stage.id) {
            const { data: { stageId } } = await axios.post('/api/stages/', _.omit(stage, ['scores']));
            stage.id = stageId;
            await axios.post(`/api/matches/${matchData.id}/stages/?auth=${login.accessToken}`, { stageId });
        } else {
            await axios.put(`/api/stages/${stage.id}?auth=${login.accessToken}&force=true`, _.omit(stage, ['id', 'scores']));
        }

        await setData(($data) => {
            const newData = JSON.parse(JSON.stringify($data));
            newData[matchIndex].match.stages = newData[matchIndex].match.stages || [];
            if (newData[matchIndex].match.stages.length < stageIndex) {
                newData[matchIndex].match.stages.push(stage);
            } else {
                newData[matchIndex].match.stages[stageIndex] = stage;
            }
            return newData;
        });

        setEdit(false);
        setWorking(false);
    }

    async function onOpen(index) {
        await new Promise((res) => setTimeout(res, 500));
        history.push(`/app/2/${matchIndex}/${stageIndex}/${index}`);
    }

    const onDelete = useCallback(async () => {
        stage.inactive = true;
        const { data: login } = await axios(`/api/logins?auth=${auth}`);
        await axios.put(`/api/stages/${stage.id}?auth=${login.accessToken}&force=true`, _.omit(stage, ['id', 'scores']));
        await setData(($data) => {
            const $$data = JSON.parse(JSON.stringify($data));
            $$data[matchIndex].match.stages[stageIndex].inactive = true;
            return $$data;
        });
        history.goBack();
    }, []);

    return (
        <div>
            <div className={classes.topbar}>
                <div style={{ flex: 1 }}>
                    <Button variant='text' color='primary' onClick={() => history.goBack()}>
                        <Typography variant='body2' style={{ color: grey[500] }}>
                            {t('homeapp:quickscore')}
                        </Typography>
                    </Button>
                    <i className='fas fa-chevron-right' style={{ fontSize: '12px', opacity: 0.5 }} />
                    <Button variant='text' color='primary' onClick={() => history.goBack()}>
                        <Typography variant='body2' style={{ color: grey[500] }}>
                            {matchData.title}
                        </Typography>
                    </Button>
                    <i className='fas fa-chevron-right' style={{ fontSize: '12px', opacity: 0.5 }} />
                    <Button variant='text' color='primary' disabled>
                        <Typography variant='body2' style={{ color: cyan[500] }}>
                            {stage.title}
                        </Typography>
                    </Button>
                </div>
                <IconButton color='secondary' onClick={() => setShowDelete(true)}>
                    <DeleteIcon />
                </IconButton>
            </div>
            {showDelete && (
                <Paper classes={{ root: classes.removequestion }}>
                    <Typography variant='subtitle2' style={{ color: 'inherit' }}>
                        {t('areyousurestage')}
                    </Typography>
                    <div>
                        <Button variant='text' onClick={() => onDelete()}>
                            <Typography variant='body2' style={{ color: 'white' }}>
                                {t('generic:yes')}
                            </Typography>
                        </Button>
                        <Button variant='text' onClick={() => setShowDelete(false)}>
                            <Typography variant='body2' style={{ color: 'white' }}>
                                {t('generic:no')}
                            </Typography>
                        </Button>
                    </div>
                </Paper>
            )}
            {edit && (
                <div>
                    <Typography variant='body2' style={{ color: grey[500], margin: '0px 12px', marginTop: '12px' }}>{t('stagedetails')}</Typography>
                    <Paper
                        style={{
                            textAlign: 'center',
                            margin: '12px',
                            marginTop: '6px',
                            padding: '12px 0px',
                        }}
                    >
                        <Typography variant='body2' style={{ marginBottom: '12px' }}>{t('stagedetailsname')}</Typography>
                        <TextField
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            onBlur={() => {
                                if (!title) {
                                    setTitle(stage.title);
                                }
                            }}
                        />
                        <Typography variant='body2' style={{ marginBottom: '12px' }}>{t('stagedetailsdescription')}</Typography>
                        <Typography variant='h6'>{t('generic:poppers')}</Typography>
                        <div className={classes.popperwrapper}>
                            <PlusMinus
                                t={t}
                                i18n={i18n}
                                num={stage.targets.poppers || 0}
                                onInc={() => onInc('poppers')}
                                onDec={() => onDec('poppers')}
                            />
                            <div
                                style={{
                                    opacity: '0.3',
                                    marginLeft: '47px',
                                    marginRight: '25px',
                                    position: 'relative',
                                    [i18n.dir() === 'ltr' ? 'left' : 'right']: '15px',
                                    height: '65px',
                                }}
                            >
                                <div style={{
                                    width: '25px', height: '25px', borderRadius: '50%', background: 'white', border: '1px solid black',
                                }}
                                />
                                <div style={{
                                    width: '9px', height: '55px', background: 'white', margin: 'auto', border: '1px solid black', borderTop: '1px solid white', position: 'relative', top: '-10px', transform: 'perspective(300px) rotateX(-45deg)',
                                }}
                                />
                            </div>
                        </div>
                        <Typography variant='h6'>{t('score:targets')}</Typography>
                        <div className={classes.popperwrapper}>
                            <PlusMinus
                                t={t}
                                i18n={i18n}
                                num={stage.targets.paper || 0}
                                onInc={() => onInc('paper')}
                                onDec={() => onDec('paper')}
                            />
                            <img alt='target' src='/classicTarget.png' style={{ marginLeft: '50px', width: '50px', opacity: 0.3 }} />
                        </div>
                        <Button variant='contained' color='primary' style={{ width: '260px', marginTop: '12px' }} onClick={() => onSave()} disabled={(!stage.targets.paper && !stage.targets.poppers) || working}>
                            {working && <CircularProgress size={24} />}
                            {!working && <div>{t('generic:save')}</div>}
                        </Button>
                    </Paper>
                </div>
            )}
            {!edit && (
                <div>
                    <HomeAppQuickScoreOnlineHeader {...props} />
                    <Typography variant='body2' style={{ color: grey[500], margin: '0px 12px', marginTop: '12px' }}>{t('stagedetails')}</Typography>
                    <Paper
                        style={{
                            textAlign: 'center',
                            margin: '12px',
                            marginTop: '6px',
                            padding: '12px 0px',
                        }}
                    >
                        <div>
                            <Typography variant='h6' style={{ color: 'inherit' }}>
                                {t('stagedetails')}
                            </Typography>
                        </div>
                        <Typography variant='body2' style={{ marginLeft: '9px', marginTop: '6px' }}>
                            {`${t('generic:poppers')}: ${stage.targets.poppers || 0} | ${t('score:targets')}: ${stage.targets.paper || 0}`}
                        </Typography>
                        <Button
                            variant='text'
                            color='primary'
                            style={{
                                padding: '0px',
                                margin: '0px',
                                minWidth: '0px',
                                textTransform: 'none',
                                marginTop: '9px',
                            }}
                        >
                            <Typography variant='caption' style={{ color: 'inherit' }} onClick={() => setEdit(true)}>
                                {t('generic:edit')}
                            </Typography>
                        </Button>
                    </Paper>
                    <Typography variant='body2' style={{ color: grey[500], margin: '0px 12px', marginTop: '12px' }}>{t('stagescores')}</Typography>
                    <Paper
                        style={{
                            textAlign: 'center',
                            margin: '12px',
                            marginTop: '6px',
                        }}
                    >
                        {(stage.scores || []).length === 0 && otherStagesShooters.length === 0 && (
                            <div style={{ paddingBottom: '24px' }}>
                                <Typography variant='h6' style={{ paddingTop: '24px' }}>{t('stagesnoscorestitle')}</Typography>
                                <Typography variant='body2' style={{ paddingBottom: '24px' }}>{t('stagesnoscores')}</Typography>
                                <Fab
                                    color='primary'
                                    onClick={() => onAddScore()}
                                >
                                    <AddIcon />
                                </Fab>
                            </div>
                        )}
                        {(stage.scores || []).length > 0 && (
                            <div>
                                {stage.scores.map((s, index) => {
                                    if (!s.score) {
                                        return (
                                            <ButtonBase key={s.created} classes={{ root: classes.matchitem }} onClick={() => onOpen(index)}>
                                                <div style={{ flex: 1 }}>
                                                    <Typography variant='subtitle2'>
                                                        {(shooters.find((s2) => s2.id === s.shooter) || {}).name}
                                                    </Typography>
                                                </div>
                                                <div style={{ marginRight: '24px' }}>
                                                    <Typography variant='caption'>
                                                        {t('noscore')}
                                                    </Typography>
                                                </div>
                                                <div>
                                                    <i className='fas fa-chevron-right' style={{ fontSize: '18px' }} />
                                                </div>
                                            </ButtonBase>
                                        );
                                    }

                                    const {
                                        total, factorStr, timeStr,
                                    } = summarizeScore({
                                        score: s.score,
                                        powerFactor: 'minor',
                                        stage: {
                                            rounds: (stage.targets.paper || 0) * 2 + (stage.targets.poppers || 0),
                                            targets: {
                                                plates: 0,
                                                paper: (stage.targets.paper || 0),
                                                poppers: stage.targets.poppers || 0,
                                                'paper-no-shoots': 100,
                                            },
                                        },
                                    });

                                    return (
                                        <ButtonBase key={s.created} classes={{ root: classes.matchitem }} onClick={() => onOpen(index)}>
                                            <div style={{ flex: 1 }}>
                                                <Typography variant='subtitle2'>
                                                    {(shooters.find((s2) => s2.id === s.shooter) || {}).name}
                                                </Typography>
                                            </div>
                                            <div style={{ borderLeft: `1px solid ${grey[300]}`, padding: '0px 6px', textAlign: 'center' }}>
                                                <Typography variant='body2'>
                                                    {total}
                                                </Typography>
                                                <Typography variant='caption'>
                                                    {t('generic:result_total')}
                                                </Typography>
                                            </div>
                                            <div style={{ borderLeft: `1px solid ${grey[300]}`, padding: '0px 6px', textAlign: 'center' }}>
                                                <Typography variant='body2'>
                                                    {timeStr}
                                                </Typography>
                                                <Typography variant='caption'>
                                                    {t('generic:result_time')}
                                                </Typography>
                                            </div>
                                            <div style={{ borderLeft: `1px solid ${grey[300]}`, padding: '0px 24px 0px 6px', textAlign: 'center' }}>
                                                <Typography variant='body2'>
                                                    {factorStr}
                                                </Typography>
                                                <Typography variant='caption'>
                                                    {t('generic:result_factor')}
                                                </Typography>
                                            </div>
                                            <div>
                                                <i className='fas fa-chevron-right' style={{ fontSize: '18px' }} />
                                            </div>
                                        </ButtonBase>
                                    );
                                })}
                            </div>
                        )}
                        {otherStagesShooters.map((s) => (
                            <ButtonBase key={s.created} classes={{ root: classes.matchitem }} onClick={() => onAddScore(s)}>
                                <div style={{ flex: 1 }}>
                                    <Typography variant='subtitle2'>
                                        {s.name}
                                    </Typography>
                                </div>
                                <div style={{ marginRight: '24px' }}>
                                    <Typography variant='caption'>
                                        {t('noscore')}
                                    </Typography>
                                </div>
                                <div>
                                    <i className='fas fa-chevron-right' style={{ fontSize: '18px' }} />
                                </div>
                            </ButtonBase>
                        ))}
                        {((stage.scores || []).length > 0 || otherStagesShooters.length > 0) && (
                            <div className={classes.newmatch}>
                                <Fab color='primary' onClick={() => onAddScore()}>
                                    <AddIcon />
                                </Fab>
                                <Typography variant='caption' style={{ color: blue[500], marginTop: '6px' }}>
                                    {t('newscore')}
                                </Typography>
                            </div>
                        )}
                    </Paper>
                </div>
            )}
        </div>
    );
}

HomeAppQuickScoreStage.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    i18n: PropTypes.shape({
        dir: PropTypes.func.isRequired,
    }).isRequired,
    classes: PropTypes.shape({}).isRequired,
    t: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
        match: PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string,
            stages: PropTypes.arrayOf(PropTypes.shape({
                scores: PropTypes.arrayOf(PropTypes.shape({})),
            })),
            shooters: PropTypes.arrayOf(PropTypes.shape({
                name: PropTypes.string.isRequired,
            })),
        }).isRequired,
        auth: PropTypes.string.isRequired,
    })).isRequired,
    setData: PropTypes.func.isRequired,
    matchIndex: PropTypes.number.isRequired,
    stageIndex: PropTypes.number.isRequired,
};

const styles = () => ({
    topbar: {
        height: '45px',
        background: 'white',
        display: 'flex',
        alignItems: 'center',
    },
    popperwrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        paddingBottom: '12px',
    },
    matchitem: {
        padding: '24px 0px',
        margin: '0px 12px',
        textAlign: 'start',
        width: 'calc(100% - 24px)',
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${grey[300]}`,
    },
    newmatch: {
        textAlign: 'center',
        margin: '12px',
        padding: '12px',
    },
    removequestion: {
        textAlign: 'center',
        margin: '12px',
        padding: '12px',
        backgroundColor: red[500],
        color: 'white',
    },
});

export default withStyles(styles, { withTheme: true, flip: false })(withTranslation('homeappquickscore')(HomeAppQuickScoreStage));
