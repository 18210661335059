import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import validator from 'validator';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import { getAllDivisions } from './Utils';

class SettingsRegistration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            limitCheckbox: !!props.registrationLimitGSheet,
            prevRegistrationLimitGSheet: '',
            gsheetError: false,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        const {
            registration, roRegistration, registrationExtraFields, registrationPatchLockedFields, registrationLimitGSheet, registrationExcludeDivisions, registrationCode, icsEnabled,
        } = this.props;
        const { limitCheckbox, gsheetError } = this.state;
        if (nextProps.roRegistration !== roRegistration) return true;
        if (nextProps.registration !== registration) return true;
        if (nextProps.registrationExtraFields !== registrationExtraFields) return true;
        if (nextProps.registrationLimitGSheet !== registrationLimitGSheet) return true;
        if (!_.isEqual(nextProps.registrationExcludeDivisions, registrationExcludeDivisions)) return true;
        if (!_.isEqual(nextProps.registrationCode, registrationCode)) return true;
        if (!_.isEqual(nextProps.registrationPatchLockedFields, registrationPatchLockedFields)) return true;
        if (nextProps.icsEnabled !== icsEnabled) return true;
        if (nextState.limitCheckbox !== limitCheckbox) return true;
        if (nextState.gsheetError !== gsheetError) return true;

        return false;
    }

    onRegistrationChange = (e, value) => {
        const { onChange } = this.props;
        onChange({ registration: value });
    }

    onRoRegistrationChange = (e, value) => {
        const { onChange } = this.props;
        onChange({ roRegistration: value });
    }

    onRegistrationFieldChange = ({ field, value }) => {
        const { onChange, registrationExtraFields } = this.props;
        if (value) {
            onChange({ registrationExtraFields: [...registrationExtraFields, field] });
        } else {
            onChange({ registrationExtraFields: registrationExtraFields.filter((f) => f !== field) });
        }
    }

    onRegistrationPatchLockedFieldChange = ({ field, value }) => {
        const { onChange, registrationPatchLockedFields } = this.props;
        if (value) {
            onChange({ registrationPatchLockedFields: [...registrationPatchLockedFields, field] });
        } else {
            onChange({ registrationPatchLockedFields: registrationPatchLockedFields.filter((f) => f !== field) });
        }
    }

    onLimitKeyDown = () => {
        this.setState({ gsheetError: false });
    }

    onLimitBlur = (e) => {
        this.setState({ gsheetError: !validator.isURL(e.target.value, { protocols: ['https'] }) });
    }

    render() {
        const {
            classes, t, matchType, registration, roRegistration, registrationExtraFields, registrationPatchLockedFields,
            registrationLimitGSheet, registrationExcludeDivisions, registrationCode, icsEnabled, onChange,
        } = this.props;
        const {
            limitCheckbox, prevRegistrationLimitGSheet, gsheetError,
        } = this.state;
        return (
            <>
                <Card>
                    <CardHeader
                        classes={{ title: classes.cardtitle }}
                        title={t('registration')}
                        subheader={t('registration-subtitle')}
                    />
                    <CardContent style={{ paddingTop: '0px' }}>
                        <form className={classes.form}>
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <FormControl component='fieldset'>
                                        <RadioGroup
                                            aria-label={t('registration')}
                                            value={registration || 'closed'}
                                            onChange={this.onRegistrationChange}
                                        >
                                            <FormControlLabel value='closed' control={<Radio />} label={t('registration-closed')} />
                                            <FormControlLabel value='open' control={<Radio />} label={t('registration-open')} />
                                            <FormControlLabel
                                                value='open-code'
                                                style={{ alignItems: 'flex-start', padding: '12px 0px' }}
                                                control={<Radio style={{ padding: '0px 12px' }} />}
                                                label={(
                                                    <div>
                                                        <div>
                                                            {t('registration-open-code')}
                                                        </div>
                                                        <TextField
                                                            fullWidth
                                                            disabled={registration !== 'open-code'}
                                                            value={(registrationCode || {}).value}
                                                            onChange={(e) => {
                                                                if (e.target.value) {
                                                                    onChange({ registrationCode: { type: 'plain', value: e.target.value } });
                                                                } else {
                                                                    onChange({ registrationCode: null });
                                                                }
                                                            }}
                                                            style={{ direction: 'ltr' }}
                                                            placeholder={t('registration-open-code-placeholder')}
                                                            error={registration === 'open-code' && !(registrationCode || {}).value}
                                                        />
                                                    </div>
                                                )}
                                            />
                                            <FormControlLabel value='sold-out' control={<Radio />} label={t('registration-sold-out')} />
                                            <FormControlLabel value='period-over' control={<Radio />} label={t('registration-period-over')} />
                                            <FormControlLabel value='period-over-can-patch' control={<Radio />} label={t('registration-period-over-can-patch')} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {(registration === 'open' || registration === 'open-code') && (
                                    <Grid item xs={12} style={{ marginTop: '24px' }}>
                                        <FormLabel component='legend'>{t('registration-fields')}</FormLabel>
                                        <div>
                                            {
                                                ['shooter_id', 'shooter_name', 'shooter_club', 'shooter_email', 'shooter_division', 'shooter_category', 'shooter_power_factor'].map((field) => (
                                                    <FormControlLabel key={field} control={<Checkbox checked disabled />} label={t(`generic:${field}`)} />
                                                ))
                                            }
                                        </div>
                                        <div>
                                            {
                                                ['shooter_address', 'shooter_phone', 'shooter_social_number', 'shooter_gun_license', 'shooter_gun_license_exp', 'gun_make_and_model', 'gun_serial', 'covid', 'image'].map((field) => (
                                                    <FormControlLabel key={field} control={<Checkbox checked={registrationExtraFields.indexOf(field) > -1} onChange={(e, value) => this.onRegistrationFieldChange({ field, value })} />} label={t(`generic:${field}`)} />
                                                ))
                                            }
                                        </div>
                                        <div>
                                            {
                                                ['shooter_address_optional', 'shooter_phone_optional', 'shooter_social_number_optional', 'shooter_gun_license_optional', 'shooter_gun_license_exp_optional', 'gun_make_and_model_optional', 'gun_serial_optional', 'gun_serial_2_optional', 'covid_optional', 'image_optional'].map((field) => (
                                                    <FormControlLabel key={field} control={<Checkbox checked={registrationExtraFields.indexOf(field) > -1} onChange={(e, value) => this.onRegistrationFieldChange({ field, value })} />} label={`${t(`generic:${field.split('_optional')[0]}`)} (${t('generic:optional')})`} />
                                                ))
                                            }
                                        </div>
                                        {
                                            ['equipment'].map((field) => (
                                                <FormControlLabel key={field} control={<Checkbox checked={registrationExtraFields.indexOf(field) > -1} onChange={(e, value) => this.onRegistrationFieldChange({ field, value })} />} label={t(`field-${field}`)} />
                                            ))
                                        }
                                    </Grid>
                                )}
                                {(registration === 'open' || registration === 'open-code' || registration === 'period-over-can-patch') && (
                                    <Grid item xs={12} style={{ marginTop: '24px' }}>
                                        <FormLabel component='legend'>{t('registration-patch-locked')}</FormLabel>
                                        <div>
                                            {
                                                ['publicId'].map((field) => (
                                                    <FormControlLabel key={field} control={<Checkbox checked={registrationPatchLockedFields.indexOf(field) > -1} onChange={(e, value) => this.onRegistrationPatchLockedFieldChange({ field, value })} />} label={t(`generic:${field === 'publicId' ? 'shooter_id' : field}`)} />
                                                ))
                                            }
                                        </div>
                                    </Grid>
                                )}
                                <Grid item xs={12} style={{ marginTop: '24px' }}>
                                    <FormLabel component='legend'>{t('registration-ics')}</FormLabel>
                                    <FormControlLabel
                                        control={(
                                            <Checkbox
                                                checked={icsEnabled}
                                                onChange={() => {
                                                    onChange({ icsEnabled: !icsEnabled });
                                                }}
                                            />
                                        )}
                                        label={t('registration-ics-checkbox')}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: '24px' }}>
                                    <FormLabel component='legend'>{t('registration-include-divisions')}</FormLabel>
                                    <div>
                                        {
                                            getAllDivisions({ matchType }).map((field) => (
                                                <FormControlLabel
                                                    key={field}
                                                    control={(
                                                        <Checkbox
                                                            checked={!_.includes(registrationExcludeDivisions, field)}
                                                            onChange={() => {
                                                                if (_.includes(registrationExcludeDivisions, field)) {
                                                                    onChange({ registrationExcludeDivisions: _.without(registrationExcludeDivisions, field) });
                                                                } else {
                                                                    onChange({ registrationExcludeDivisions: [...registrationExcludeDivisions, field] });
                                                                }
                                                            }}
                                                        />
                                                      )}
                                                    label={t(`generic:division-${field}`)}
                                                />
                                            ))
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: '24px' }}>
                                    <FormLabel component='legend'>{t('registration-limit')}</FormLabel>
                                    <FormControlLabel
                                        control={(
                                            <Checkbox
                                                checked={limitCheckbox}
                                                onChange={() => {
                                                    this.setState({ limitCheckbox: !limitCheckbox });
                                                    onChange({ registrationLimitGSheet: limitCheckbox ? '' : prevRegistrationLimitGSheet });
                                                }}
                                            />
                                        )}
                                        label={t('registration-limit-checkbox')}
                                    />
                                    <TextField
                                        fullWidth
                                        disabled={!limitCheckbox}
                                        value={registrationLimitGSheet || ''}
                                        onChange={(e) => {
                                            this.setState({ prevRegistrationLimitGSheet: e.target.value });
                                            onChange({ registrationLimitGSheet: e.target.value });
                                        }}
                                        onKeyDown={this.onLimitKeyDown}
                                        onBlur={this.onLimitBlur}
                                        style={{ direction: 'ltr' }}
                                        placeholder={t('registration-limit-url')}
                                    />
                                    {!gsheetError && (
                                        <Typography variant='caption' style={{ marginTop: '6px' }}>
                                            {t('registration-limit-description')}
                                        </Typography>
                                    )}
                                    {gsheetError && (
                                        <Typography variant='caption' style={{ marginTop: '6px', color: red[500] }}>
                                            {t('registration-limit-url-error')}
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
                <br />
                <Card>
                    <CardHeader
                        classes={{ title: classes.cardtitle }}
                        title={t('roRegistration')}
                        subheader={t('roRegistration-subtitle')}
                    />
                    <CardContent style={{ paddingTop: '0px' }}>
                        <form className={classes.form}>
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <FormControl component='fieldset'>
                                        <RadioGroup
                                            aria-label={t('registration')}
                                            value={roRegistration || 'closed'}
                                            onChange={this.onRoRegistrationChange}
                                        >
                                            <FormControlLabel value='closed' control={<Radio />} label={t('registration-closed')} />
                                            <FormControlLabel value='open' control={<Radio />} label={t('registration-open')} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </>
        );
    }
}

SettingsRegistration.propTypes = {
    matchType: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    roRegistration: PropTypes.oneOf(['closed', 'open']).isRequired,
    registration: PropTypes.oneOf(['closed', 'open', 'sold-out', 'period-over', 'period-over-can-patch']).isRequired,
    registrationExtraFields: PropTypes.arrayOf(PropTypes.string).isRequired,
    registrationPatchLockedFields: PropTypes.arrayOf(PropTypes.string).isRequired,
    registrationExcludeDivisions: PropTypes.arrayOf(PropTypes.string).isRequired,
    registrationLimitGSheet: PropTypes.string,
    registrationCode: PropTypes.shape,
    icsEnabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

SettingsRegistration.defaultProps = {
    registrationLimitGSheet: null,
    registrationCode: null,
};

const styles = () => ({
    cardtitle: {
        fontWeight: 300,
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > div': {
            marginTop: '16px',
        },
        '& > div:first-child': {
            marginTop: '0px',
        },
    },
});

export default withStyles(styles, { withTheme: true })(withTranslation('settings')(SettingsRegistration));
