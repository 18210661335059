const lang = {
    en: {
        title: '{{- name}}',
        about: 'General Settings',
        'about-subtitle': 'Configure your match\'s title, description and images',
        'title-field': 'Match name',
        'type-field': 'Match type',
        'ipsc-handgun': 'IPSC Handgun',
        'ipsc-shotgun': 'IPSC Shotgun',
        'type-field-comment': 'Contact us to change (we support IPSC Handgun, IPSC PCC and IPSC Shotgun)',
        'description-field': 'Match description (short)',
        'location-field': 'Range location (to help find it on a map)',
        logo: 'Logo (square)',
        cover: 'Cover image (wide)',
        newphotohelper: 'Click to setup image',
        changephotohelper: 'click to change image',
        url: 'URL',
        'about-match': 'About the Match',
        'about-match-subtitle': 'Tell your visitors what\'s special about your match, and we will add it to the main page',
        showinsearch: 'Show in regional match listings',
        'start-date-field': 'Match\'s first day (including pre-match)',
        'end-date-field': 'Match\'s last day',
        ipsclevel: 'IPSC Level',
        ipsclevel0: 'Club Practice / Unapproved Match',
        ipsclevel1: 'Internal Club Match (Approved Level 1)',
        ipsclevel2: 'Cross-Club Match (Approved Level 2)',
        ipsclevel3: 'Regional Championship (Approved Level 3)',
        ipsclevel4: 'Continental Championships (Approved Level 4)',
        ipsclevel5: 'The World Shoots (Approved Level 5)',
    },
    he: {
        title: '{{- name}}',
        about: 'הגדרות כלליות',
        'about-subtitle': 'הגדירו את השם, התיאור, ולוגו התחרות',
        'title-field': 'שם התחרות',
        'description-field': 'תיאור קצר (לדוגמא, תאריך ומיקום)',
        'location-field': 'מיקום התחרות (לעזור במציאת המטווח)',
        logo: 'לוגו ריבועי',
        cover: 'תמונת רקע מלבנית',
        newphotohelper: 'לחצו לבחירת תמונה',
        changephotohelper: 'לחצו לשינוי התמונה',
        url: 'כתובת',
        'about-match': 'על התחרות',
        'about-match-subtitle': 'הוסיפו הקדמה קצרה לתחרות שתוצג באתר הבית שלכם',
        showinsearch: 'הצג ברשימת התחרויות האזוריות',
        'start-date-field': 'יום ראשון לתחרות (כולל פרי-מצ׳)',
        'end-date-field': 'יום אחרון לתחרות',
        ipsclevel: 'סוג תחרות:',
        ipsclevel0: 'אימון פנימי \\ תחרות לא מאושרת',
        ipsclevel1: 'תחרות מועדון פנימית (מאושרת ע״י ההתאחדות לרמה 1)',
        ipsclevel2: 'תחרות פתוחה (מאושרת ע״י ההתאחדות לרמה 2)',
        ipsclevel3: 'אליפות אזורית (מאושרת IPSC רמה 3)',
        ipsclevel4: 'אליפות יבשת (מאושרת IPSC רמה 4)',
        ipsclevel5: 'The World Shoots (מאושרת IPSC רמה 5)',
        'type-field': 'סוג התחרות',
        'ipsc-handgun': 'אקדח',
        'ipsc-shotgun': 'שוטגאן',
        'type-field-comment': 'צרו איתנו קשר לשינוי - אנו תומכים באקדח, קרבין, ושוטגאן.',
    },
};

lang.iw = {
    ...lang.he,
    ...{
        ipsclevel1: 'תחרות יחידה פנימית (מאושרת ע״י ההתאחדות לרמה 1)',
    },
};

export default lang;
