module.exports = {
    en: {
        title: 'Upgrade',
        pickpackage: 'Pick this',
        upgrade: 'Upgrade!',
        fullyupgraded: 'Yey! You\'re as premium as they get. All our features are open to you. Enjoy!',
    },
    he: {
        title: 'חבילות',
        pickpackage: 'שדרג לזה',
        upgrade: 'שדרג',
        fullyupgraded: 'יש! אתם הכי פרימיום שיש עבורנו. כל הפיצ׳רים שלנו נגישים לכם - תהנו!',
    },
};
