const lang = {
    en: {
        free: 'You are currently on the free package',
        'free-used-up': 'You have used up your plan (50 shooters). Upgrade now.',
        'free-about': 'You are about to use up your plan (50 shooters). Upgrade now.',
        'small-used-up': 'You have used up your plan (200 shooters). Upgrade now.',
        'small-about': 'You are about to use up your plan (200 shooters). Upgrade now.',
        upgrade: 'Upgrade',
    },
    he: {
        free: 'אתם כרגע רשומים בחבילה החינמית.',
        'free-used-up': 'הגעתם למכסת היורים שלכם (50). שדרגו עכשיו.',
        'free-about': 'אתם עומדים להגיע למכסת היורים שלכם (50). שדרגו עכשיו.',
        'small-used-up': 'הגעתם למכסת היורים שלכם (200). שדרגו עכשיו.',
        'small-about': 'אתם עומדים להגיע למכסת היורים שלכם (200). שדרגו עכשיו.',
        upgrade: 'שדרג',
    },
};

lang.iw = {
    ...lang.he,
    ...{
        'free-used-up': 'הגעתם למכסת הלוחמים שלכם (50). שדרגו עכשיו.',
        'free-about': 'אתם עומדים להגיע למכסת הלוחמים שלכם (50). שדרגו עכשיו.',
        'small-used-up': 'הגעתם למכסת הלוחמים שלכם (200). שדרגו עכשיו.',
        'small-about': 'אתם עומדים להגיע למכסת הלוחמים שלכם (200). שדרגו עכשיו.',
    },
};

export default lang;
