import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import validator from 'validator';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import api from '../Api';

class ContactUs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            feedbackName: '',
            email: '',
            message: '',
            errorName: false,
            errorEmail: false,
            errorMessage: false,
            sendingMessage: false,
            messageError: false,
            messageSuccess: false,
        };
    }

    onSend = async () => {
        const {
            feedbackName, email, message,
        } = this.state;

        const state = {};
        if (!feedbackName) {
            state.errorName = true;
        }
        if ((!email) || (!validator.isEmail(email))) {
            state.errorEmail = true;
        }
        if (!message) {
            state.errorMessage = true;
        }

        if (!_.isEmpty(state)) {
            this.setState(state);
            return;
        }

        await new Promise((res) => this.setState({ sendingMessage: true, messageSuccess: false, messageError: false }, res));

        try {
            await api.sendFeedback({ name: feedbackName, email, message });
            this.setState({ sendingMessage: false, messageSuccess: true, messageError: false });
        } catch (e) {
            this.setState({ sendingMessage: false, messageSuccess: false, messageError: true });
        }
    }

    render() {
        const { classes } = this.props;
        const {
            feedbackName, errorName, email, errorEmail, message, errorMessage, sendingMessage,
            messageError, messageSuccess,
        } = this.state;

        return (
            <Grid container classes={{ container: classes.wrapper }}>
                <Grid item xs={12} style={{ marginTop: '24px' }}>
                    <Typography variant='h4' classes={{ root: 'home-sectiontitle' }} style={{ marginBottom: '12px' }}>
                        Contact Us
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={24}
                        style={{
                            alignItems: 'center', display: 'flex', justifyContent: 'center', textAlign: 'center', marginBottom: '36px',
                        }}
                    >
                        <Grid item xs={12} md={6}>
                            <div>
                                <Paper style={{ padding: '24px' }}>
                                    <TextField label='Name' value={feedbackName || ''} onChange={(e) => this.setState({ feedbackName: e.target.value, errorName: false })} error={errorName} fullWidth style={{ marginBottom: '12px' }} />
                                    <TextField label='Email' value={email || ''} onChange={(e) => this.setState({ email: e.target.value, errorEmail: false })} error={errorEmail} fullWidth style={{ marginBottom: '12px' }} />
                                    <TextField label='Message' value={message || ''} onChange={(e) => this.setState({ message: e.target.value, errorMessage: false })} error={errorMessage} fullWidth style={{ marginBottom: '12px' }} />
                                    <Button fullWidth variant='contained' color='secondary' onClick={sendingMessage ? null : this.onSend}>
                                        {sendingMessage && <CircularProgress size={18} color='white' />}
                                        {!sendingMessage && 'Send'}
                                    </Button>
                                    {messageError && (
                                    <Typography variant='caption' style={{ color: red[500] }}>
                                        Error sending message. Please try again later.
                                    </Typography>
                                    )}
                                    {messageSuccess && (
                                    <Typography variant='caption' style={{ color: green[500] }}>
                                        We got your message, and will response as soon as possible.
                                    </Typography>
                                    )}
                                </Paper>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

const styles = {
    wrapper: {
        padding: '0px 12px',
        textAlign: 'center',
    },
    whiteText: {
        color: 'white',
    },
};

export default withStyles(styles, { withTheme: true, flip: false })(withTranslation('home')(ContactUs));

ContactUs.propTypes = {
    classes: PropTypes.shape({}).isRequired,
};
