const lang = {
    en: {
        title: 'Shooters',
        addshooter: 'New Shooter',
        addshooterback: 'Back',
        'num-active-shooters': 'Listed (Active)',
        'num-cancelled-shooters': 'Cancelled',
        'num-paying-shooters': 'Paid',
        'num-squad-shooters': 'Assigned Squads',
        verified: 'Verified',
        'tab-registration': 'Shooters Registrations',
        'tab-verification': 'Attendance & Verifications',
        'tab-registration-details': 'Click a row to edit shooter details',
        'tab-verification-details': 'Click a row to verify shooter registration detail and equipment',
        created: 'Registration Date',
        cancellationrequest: 'This shooter has requested to cancel participation',
        invalidro: 'This shooter is in an RO squad, but is not in the staff list.',
    },
    he: {
        title: 'יורים',
        addshooter: 'הוסף יורה',
        addshooterback: 'חזור',
        'num-active-shooters': 'רשומים',
        'num-cancelled-shooters': 'ביטלו',
        'num-paying-shooters': 'שילמו',
        'num-squad-shooters': 'אשרו סקווד',
        verified: 'ציוד אומת',
        'tab-registration': 'רישום יורים',
        'tab-verification': 'נוכחות ואימות ציוד',
        'tab-registration-details': 'לחצו על שורה לעריכת נתוני הרישום של היורה',
        'tab-verification-details': 'לחצו על שורה למסך אימות נתוני וציוד היורה',
        created: 'תאריך הרשמה',
        cancellationrequest: 'היורה ביקש\\ה לבטל את השתתפותו\\ה',
        invalidro: 'היורה רשום\\ה בסקווד שופטים ושופטות, אך לא רשום\\ה ברשימת ההרשאות.',
    },
};

lang.iw = {
    ...lang.he,
    ...{
        'num-squad-shooters': 'אשרו מקצה',
        title: 'לוחמים',
        addshooter: 'הוסף לוחם\\ת',
        'tab-registration': 'רישום לוחמים',
        'tab-registration-details': 'לחצו על שורה לעריכת נתוני הרישום של הלוחם\\ת',
        'tab-verification-details': 'לחצו על שורה למסך אימות נתוני וציוד הלוחם\\ת',
        cancellationrequest: 'הלוחם\\ת ביקש\\ה לבטל את השתתפותו\\ה',
    },
};

export default lang;
