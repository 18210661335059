import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

function ScoreSquad(props) {
    const {
        t, squads, onSquad, classes,
    } = props;

    return (
        <div className={classes.wrapper}>
            <Typography variant='h6'>
                {t('picksquad')}
            </Typography>
            {squads.map((squad) => (
                <Button classes={{ root: classes.button }} fullWidth key={squad.id} variant='contained' color='primary' onClick={() => onSquad(squad)}>
                    {squad.title}
                </Button>
            ))}
        </div>
    );
}

ScoreSquad.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    squads: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    })).isRequired,
    onSquad: PropTypes.func.isRequired,
};

const styles = () => ({
    wrapper: {
        textAlign: 'center',
        '& > h6': {
            margin: '12px 0px',
        },
    },
    button: {
        marginBottom: '12px',
    },
});

export default withStyles(styles, { withTheme: true })(withTranslation('score')(ScoreSquad));
