/* eslint-disable max-classes-per-file */
import './Squads.css';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Authentication from '../components/Authentication';
import api from '../Api';

export default class PrintSquadsScoreSheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            match: null,
            squads: [],
            stages: [],
            shooters: [],
        };
    }

    async UNSAFE_componentWillMount() {
        const { matchId, auth } = this.props;
        const promises = await Promise.all([
            api.getMatch({ matchId }),
            api.getShooters({ matchId, auth }),
        ]);

        const [match, shooters] = promises;

        const stages = await api.getStages({ stages: match.stages });

        this.setState({
            match,
            shooters,
            squads: match.squads,
            stages,
        });
    }

    render() {
        const { matchId } = this.props;
        return (
            <PrintSquadsScoreSheetInner {...this.props} {...this.state} matchId={matchId} />
        );
    }
}

PrintSquadsScoreSheet.propTypes = {
    matchId: PropTypes.string.isRequired,
    auth: PropTypes.string.isRequired,
};

class PrintSquadsScoreSheetInner_ extends Component {
    async UNSAFE_componentWillMount() {
        const { classes } = this.props;
        document.body.classList.add(classes.landscape);
    }

    renderSquad(squad, stage, stageIdx) {
        const { t, classes, shooters } = this.props;
        const { title = '' } = squad;

        let $shooters = shooters.filter((shooter) => shooter.squad === squad.id);
        $shooters = [...$shooters.slice(stageIdx), ...$shooters.slice(0, stageIdx)];

        return (
            <div className={classes.textAlignStart} key={`${squad.title}_${stageIdx}`} style={{ marginBottom: '24px' }}>
                <div className={classes.pageBreakBefore} />
                <Typography variant='h5'>
                    <div className={classes.titlerow}>
                        <div className={classes.titlefield}>
                            {t('squads:squad-name')}
                            :
                        </div>
                        <div className={classes.titlevalue}>
                            {title}
                        </div>
                        <div className={classes.titlefield}>
                            {t('generic:stage_number')}
                            :
                        </div>
                        <div className={classes.titlevalue}>
                            {stageIdx + 1}
                            .
                            {stage.title}
                        </div>
                    </div>
                </Typography>
                <Typography variant='body1' headlineMapping={{ body1: 'div' }}>
                    <table border='1' className={classes.table}>
                        <thead style={{ textAlign: 'center' }}>
                            <tr>
                                <th style={{ width: '20%' }}>
                                    {t('shooter-info')}
                                </th>
                                <th style={{ width: '10%' }}>
                                    {t('generic:result_time')}
                                </th>
                                {['poppers', 'alphas', 'charlies', 'deltas', 'misses', 'no_shoots', 'penalties'].map((field) => (
                                    <th key={field} style={{ width: '6%' }}>
                                        {t(`generic:result_${field}`)}
                                    </th>
                                ))}
                                <th style={{ width: '20%' }}>
                                    {t('signature')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {[...$shooters, {}, {}, {}, {}, {}].map((shooter) => (
                                <tr key={shooter.publicId}>
                                    <td>
                                        <table border='1' className={classes.table}>
                                            <tbody>
                                                <tr>
                                                    <td colSpan='3' className={classes.textAlign} style={{ height: '35px', padding: '0px 6px' }}>
                                                        {shooter.publicId ? `${shooter.publicId} / ${shooter.name}` : ''}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: 'center', fontSize: '9px' }}>
                                                        <div style={{ width: '100%', height: '20px', textAlign: 'center' }}>
                                                            {shooter.division ? t(`generic:division-${shooter.division}`) : ''}
                                                        </div>
                                                        {t('generic:shooter_division')}
                                                    </td>
                                                    <td style={{ textAlign: 'center', fontSize: '9px' }}>
                                                        <div style={{ width: '100%', height: '20px', textAlign: 'center' }}>
                                                            {shooter.category ? t(`generic:category-${shooter.category}`) : ''}
                                                        </div>
                                                        {t('generic:shooter_category')}
                                                    </td>
                                                    <td style={{ textAlign: 'center', fontSize: '9px' }}>
                                                        <div style={{ width: '100%', height: '20px', textAlign: 'center' }}>
                                                            {shooter['power-factor'] ? t(`generic:power-factor-${shooter['power-factor']}`) : ''}
                                                        </div>
                                                        {t('generic:shooter_power_factor')}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td />
                                    {['alpha', 'charlie', 'delta', 'popper', 'miss', 'noshoots', 'penalties'].map((field) => (
                                        <td key={field}>
                                            <table border='0' className={classes.table}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: '100%', height: '35px' }} />
                                                    </tr>
                                                    <tr style={{ borderTop: '1px solid rgba(0,0,0, 0.5)' }}>
                                                        <td style={{ textAlign: 'center', fontSize: '9px' }}>
                                                            <div style={{ width: '100%', height: '20px' }} />
                                                            {t('count')}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    ))}
                                    <td />
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Typography>
                <div className={classes.pageBreakAfter} />
            </div>
        );
    }

    render() {
        const {
            match, squads, stages,
        } = this.props;

        if (!match) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '36px' }}>
                    <CircularProgress size={72} thickness={1} />
                </div>
            );
        }

        return (
            <div style={{ padding: '12px' }}>
                {_.flatten(squads.map((squad) => stages.map((stage, stageIdx) => this.renderSquad(squad, stage, stageIdx))))}

                <Authentication match={match} onAuth={() => {}} />
            </div>
        );
    }
}

const styles = () => ({
    landscape: {
        size: 'letter landscape',
        background: 'white !important',
    },

    pageBreakAfter: {
        pageBreakAfter: 'always',
        display: 'block',
        position: 'relative',
    },

    pageBreakBefore: {
        pageBreakBefore: 'always',
        display: 'block',
        position: 'relative',
    },

    titlerow: {
        display: 'block',
        marginBottom: '24px',
    },

    titlefield: {
        display: 'inline-block',
        marginRight: '6px',
    },

    titlevalue: {
        display: 'inline-block',
        fontWeight: 'bolder',
        whiteSpace: 'nowrap',
        marginRight: '24px',
    },

    table: {
        width: '100%',
        borderCollapse: 'collapse',
        fontSize: '12px',
    },
    textAlignStart: {
        textAlign: 'left',
    },
});

PrintSquadsScoreSheetInner_.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    t: PropTypes.func.isRequired,
    shooters: PropTypes.arrayOf(PropTypes.shape({
        publicId: PropTypes.string,
        name: PropTypes.string,
        division: PropTypes.string,
        category: PropTypes.string,
        'power-factor': PropTypes.string,
        squad: PropTypes.string,
    })).isRequired,
    match: PropTypes.shape({}).isRequired,
    squads: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string,
    })).isRequired,
    stages: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
    })).isRequired,
};

const PrintSquadsScoreSheetInner = withStyles(styles, { withTheme: true })(withTranslation('printsquads')(PrintSquadsScoreSheetInner_));
/* eslint-enable max-classes-per-file */
