module.exports = {
    en: {
        send: 'Send',
        nocomments: 'No other ROs have given any comments about this shooter. Want to add a comment? add using the box below.',
    },
    he: {
        send: 'שמור',
        nocomments: 'ליורה אין הערות או אזהרות. רוצים להוסיף? השתמשו בקופסא למטה.',
    },
};
