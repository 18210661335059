import React from 'react';
import queryString from 'query-string';
import { JssProvider } from 'react-jss';
import { create } from 'jss';
import { I18nextProvider } from 'react-i18next';
import {
    MuiThemeProvider, createMuiTheme, jssPreset,
} from '@material-ui/core/styles';
import i18n from '../../i18n';

let jss = null;
let generateClassName = null;

const createGenerateId = () => {
    let counter = 0;
    return () => `h18w--${counter++}`;
};

export default function HomeI18nWrapper(WrappedComponent) {
    return (props) => {
        if (typeof (window) === 'undefined') {
            // No server side rendering
            return null;
        }

        const parsed = queryString.parse(window.location.search);

        const theme = createMuiTheme({
            palette: { primary: { main: '#2196f3' }, secondary: { main: '#f50057' } },
            typography: { useNextVariants: true },
        });

        jss = jss || create({ plugins: jssPreset().plugins, createGenerateId });
        generateClassName = generateClassName || createGenerateId();

        return (
            <MuiThemeProvider theme={theme}>
                <JssProvider jss={jss} generateClassName={generateClassName}>
                    <I18nextProvider i18n={i18n}>
                        <WrappedComponent {...parsed} {...props} />
                    </I18nextProvider>
                </JssProvider>
            </MuiThemeProvider>
        );
    };
}
