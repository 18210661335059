import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

function SettingsScoring(props) {
    const {
        t, classes, scoreFormDisplayMode, scoreMode, onChange,
    } = props;

    function $onChange(e, value) {
        onChange({ scoreFormDisplayMode: value });
    }

    function $onChangeScoreMode(e, value) {
        onChange({ scoreMode: value });
    }

    return (
        <div>
            <Card>
                <CardHeader
                    classes={{ title: classes.cardtitle }}
                    title={t('scoreformdisplaymode-title')}
                    subheader={t('scoreformdisplaymode-subtitle')}
                />
                <CardContent style={{ paddingTop: '0px' }}>
                    <form className={classes.form}>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <FormControl component='fieldset'>
                                    <RadioGroup
                                        style={{ padding: '0px 15px' }}
                                        value={scoreFormDisplayMode || 'normal'}
                                        onChange={$onChange}
                                    >
                                        <FormControlLabel value='normal' control={<Radio />} label={t('scoreformdisplaymode-normal')} />
                                        <FormControlLabel value='corona' control={<Radio />} label={t('scoreformdisplaymode-corona')} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
            <div style={{ marginTop: '12px' }} />
            <Card>
                <CardHeader
                    classes={{ title: classes.cardtitle }}
                    title={t('scoremode-title')}
                    subheader={t('scoremode-subtitle')}
                />
                <CardContent style={{ paddingTop: '0px' }}>
                    <form className={classes.form}>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <FormControl component='fieldset'>
                                    <RadioGroup
                                        style={{ padding: '0px 15px' }}
                                        value={scoreMode || 'comstock'}
                                        onChange={$onChangeScoreMode}
                                    >
                                        <FormControlLabel value='comstock' control={<Radio />} label={t('scoremode-comstock')} />
                                        <FormControlLabel value='steel-no-miss' control={<Radio />} label={t('scoremode-steel-no-miss')} />
                                        <FormControlLabel value='comstock-abd' control={<Radio />} label={t('scoremode-comstock-abd')} />
                                        <FormControlLabel value='il-army' control={<Radio />} label={t('scoremode-il-army')} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
}

const styles = () => ({
    cardtitle: {
        fontWeight: 300,
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > div': {
            marginTop: '16px',
        },
        '& > div:first-child': {
            marginTop: '0px',
        },
    },
});

SettingsScoring.propTypes = {
    scoreFormDisplayMode: PropTypes.string,
    scoreMode: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

SettingsScoring.defaultProps = {
    scoreFormDisplayMode: null,
    scoreMode: null,
};

export default withStyles(styles, { withTheme: true })(withTranslation('settings')(SettingsScoring));
