import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import Autosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { withTranslation } from 'react-i18next';

const GoogleMapsLoader = (() => {
    if (typeof (window) !== 'undefined') {
        /* eslint-disable global-require */
        const { Loader } = require('google-maps');
        return Loader;
        /* eslint-enable global-require */
    }
    return null;
})();

class AddressInput extends React.Component {
    handleSuggestionsFetchRequested = _.debounce(async ({ value }) => {
        this.lastValue = value;
        const types = ['geocode', 'establishment'];

        // Perform the actual request
        const request = { types, /* componentRestrictions: {country: countryCode}, */ input: value };
        const suggestions = await new Promise((res) => this.autocomplete.getPlacePredictions(request, (v) => res(v)));

        if (!suggestions) return;
        if (value !== this.lastValue) return;

        this.setState({ suggestions });
    }, 200)

    constructor(params) {
        super(params);

        this.state = {
            googleLoaded: false,
            suggestions: [],
            addressStr: params.address ? params.address.formatted : null,
        };

        if (typeof (GoogleMapsLoader) === 'undefined') return;

        const loader = new GoogleMapsLoader('AIzaSyDz2lJuhSfA3Vo5g3rcsYTl3BqpAwYsbqc', {
            language: 'iw',
            libraries: ['geometry', 'places'],
        });

        loader.load().then((google) => {
            this.autocomplete = new google.maps.places.AutocompleteService();
            this.geocoder = new google.maps.Geocoder();
            if (this.mounted) {
                this.setState({ googleLoaded: true });
            } else {
                this.state.googleLoaded = true;
            }
        });
    }

    componentDidMount() {
        this.mounted = true;
    }

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    }

    render() {
        const {
            t, address = {}, onChange, noMap = false, error = null, classes = {}, textFieldProps = {},
        } = this.props;
        const {
            suggestions, addressStr, mapHeightWidth, googleLoaded,
        } = this.state;

        const $onChange = async ({ field, value, values }) => {
            let v = values;
            if (!v) {
                v = [{ field, value }];
            }

            let a = address;
            v.forEach(($v) => {
                a = { ...a, [$v.field]: $v.value };
            });

            onChange({ address: a });
        };

        return (
            <div className={classes.root}>
                <div>
                    <Autosuggest
                        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                        getSuggestionValue={(suggestion) => {
                            this.geocoder.geocode({ placeId: suggestion.place_id }, (result) => {
                                const countryCode = (result[0].address_components.find((c) => (c.types || []).includes('country')) || {}).short_name;
                                $onChange({
                                    values: [{
                                        field: 'geocode',
                                        value: {
                                            lat: result[0].geometry.location.lat(),
                                            lng: result[0].geometry.location.lng(),
                                        },
                                    }, {
                                        field: 'types',
                                        value: result[0].types,
                                    }, {
                                        field: 'formatted',
                                        value: suggestion.description,
                                    }, {
                                        field: 'placeId',
                                        value: suggestion.place_id,
                                    }, {
                                        field: 'countryCode',
                                        value: countryCode,
                                    }],
                                });
                            });
                            return suggestion.description;
                        }}
                        renderSuggestion={renderSuggestion}
                        renderInputComponent={renderInputComponent}
                        suggestions={suggestions}
                        renderSuggestionsContainer={(options) => (
                            <Paper {...options.containerProps} classes={{ root: 'registration-no-ul' }} square>
                                {options.children}
                            </Paper>
                        )}
                        inputProps={{
                            placeholder: t('placeholder'),
                            value: addressStr || '',
                            onChange: (e, { newValue }) => this.setState({ addressStr: newValue }),
                            autoFocus: true,
                            error,
                            ...textFieldProps,
                        }}
                    />
                </div>
                {!noMap && (
                <div
                    className={classes.map}
                    ref={(e) => {
                        if (e && !mapHeightWidth) {
                            this.setState({ mapHeightWidth: { width: e.offsetWidth, height: e.offsetHeight } });
                        }
                    }}
                >
                    {googleLoaded && mapHeightWidth && (
                    <MyMapComponent
                        isMarkerShown={!!address.geocode}
                        zoom={address.geocode ? 12 : 6}
                        lat={(address.geocode || {}).lat || 32.1318247}
                        lng={(address.geocode || {}).lng || 34.846392700000024}
                        googleMapURL='https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places'
                        loadingElement={<div style={{ height: '100%' }} />}
                        containerElement={<div style={{ height: mapHeightWidth.height }} />}
                        mapElement={<div style={{ height: '100%' }} />}
                    />
                    )}
                </div>
                )}
            </div>
        );
    }
}

const MyMapComponent = withGoogleMap((props) => (
    <GoogleMap
        key={`${props.lat}-${props.lng}-${props.zoom}`}
        defaultZoom={props.zoom}
        defaultCenter={{ lat: props.lat, lng: props.lng }}
    >
        {props.isMarkerShown && <Marker position={{ lat: props.lat, lng: props.lng }} />}
    </GoogleMap>
));

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const text = suggestion.description;
    console.log('[renderSuggestion]', text);

    const matches = match(text, query);
    const parts = parse(text, matches);

    return (
        <MenuItem selected={isHighlighted} component='div'>
            <div>
                {parts.map((part, index) => (part.highlight ? (
                    <span key={String(index)} style={{ color: '#000', fontWeight: '500' }}>
                        {part.text}
                    </span>
                ) : (
                    <span key={String(index)} style={{ color: '#666' }}>
                        {part.text}
                    </span>
                )))}
            </div>
        </MenuItem>
    );
}

AddressInput.propTypes = {
    address: PropTypes.shape({}),
    textFieldProps: PropTypes.shape({}),
    onChange: PropTypes.func.isRequired,
    noMap: PropTypes.bool,
    error: PropTypes.string,
};

AddressInput.defaultProps = {
    textFieldProps: null,
    address: null,
    noMap: null,
    error: null,
};

function renderInputComponent(inputProps) {
    const {
        classes, inputRef = () => {}, ref, ...other
    } = inputProps;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputRef: (node) => {
                    ref(node);
                    inputRef(node);
                },
            }}
            {...other}
        />
    );
}

export default withTranslation('addressinput')(AddressInput);
