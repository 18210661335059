import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import LibPhoneNumber from 'google-libphonenumber';
import MomentUtils from '@date-io/moment';
import ReactFlagsSelect from 'react-flags-select';
import { EmailValidator } from 'commons-validator-js';
import { withTranslation } from 'react-i18next';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';

import SaveButton from '../components/SaveButton';
import Snackbar from '../components/Snackbar';
import { getFlagSrc } from '../matches/Utils';

import api from '../Api';

class Club extends Component {
    constructor(props) {
        super(props);

        const { region } = props;

        this.state = {
            shooters: null,
            sortBy: 'publicId',
            sortAscDesc: 'asc',
            countryCode: region.countryCode,
            publicId: '',
            name: '',
            phone: '',
            email: '',
            errors: {},
            registrationEndDate: moment().add(1, 'years'),
            deleteRequest: null,
            deleting: false,
        };

        this.$refs = {};
    }

    async UNSAFE_componentWillMount() {
        const { clubId, setTitle, auth } = this.props;

        (async () => {
            const club = await api.getClub({ clubId, auth });
            setTitle(club.title);
        })();

        (async () => {
            const shooters = await api.getClubShooters({ clubId, auth });
            this.setState({ shooters });
        })();
    }

    onChange = (e, field) => {
        const { errors } = this.state;

        this.setState({ [field]: e.target.value, errors: { ...errors, [field]: false } });
    }

    onKeyDown = (e, nextField) => {
        if (e.keyCode === 13) {
            if (nextField) {
                this.$refs[nextField].focus();
            }
        }
    }

    onAdd = async () => {
        const {
            t, region, clubId, auth,
        } = this.props;
        const {
            shooters, countryCode, publicId, name, email, phone, registrationEndDate,
        } = this.state;

        const errors = {
            publicId: !publicId,
            name: !name,
            email: !(new EmailValidator()).isValid(email),
        };

        const phoneUtil = LibPhoneNumber.PhoneNumberUtil.getInstance();
        try {
            const parsed = phoneUtil.parseAndKeepRawInput(_.trim(phone || ''), region.countryCode);
            if (!phoneUtil.isValidNumber(parsed, 'IL')) {
                errors.phone = true;
            }
        } catch (e) {
            errors.phone = true;
        }


        if (_.some(errors, (v) => !!v)) {
            this.setState({ errors });
            return false;
        }

        try {
            const shooter = await api.postClubShooter({
                clubId,
                auth,
                shooter: {
                    publicId: `${countryCode}|${publicId}`,
                    name,
                    email,
                    phone,
                    registrationEndDate,
                },
            });

            this.setState({
                shooters: [...shooters, shooter], countryCode: region.countryCode, publicId: '', name: '', phone: '', email: '', registrationEndDate: moment().add(1, 'years'),
            });

            return true;
        } catch (e) {
            if (_.get(e, 'response.data.status') === 409) {
                this.setState({ errors: { save: t('public_id_exists'), publicId: true } });
            }
            return false;
        }
    }

    onDelete = async () => {
        const { t, clubId, auth } = this.props;
        const { deleteRequest, shooters } = this.state;

        await new Promise((res) => this.setState({ deleting: true }, res));
        try {
            await api.deleteClubShooter({ clubId, shooterId: deleteRequest, auth });
            const $shooters = shooters.filter((s) => s.id !== deleteRequest);
            await new Promise((res) => this.setState({ deleting: false, deleteRequest: null, shooters: $shooters }, res));
        } catch (e) {
            this.setState({ errors: { save: t('generic:saveerror') }, deleteRequest: null, deleting: false });
        }
    }

    render() {
        const { t, classes, region } = this.props;
        const {
            sortBy, sortAscDesc, shooters,
            countryCode, publicId, name, phone, email,
            registrationEndDate, errors, deleteRequest, deleting,
        } = this.state;

        if (!shooters) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <CircularProgress size={75} />
                </div>
            );
        }

        return (
            <div>
                <Table style={{ marginBottom: '24px' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ padding: '4px 16px 4px 14px' }}>
                                <TableSortLabel active={sortBy === 'publicId'} direction={sortAscDesc} onClick={() => this.sortBy('publicId')}>
                                    {t('generic:shooter_id')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ padding: '4px 16px 4px 14px' }}>
                                <TableSortLabel active={sortBy === 'name'} direction={sortAscDesc} onClick={() => this.sortBy('name')}>
                                    {t('generic:shooter_name')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ padding: '4px 16px 4px 14px' }}>
                                <TableSortLabel active={sortBy === 'phone'} direction={sortAscDesc} onClick={() => this.sortBy('phone')}>
                                    {t('generic:shooter_phone')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ padding: '4px 16px 4px 14px' }}>
                                <TableSortLabel active={sortBy === 'email'} direction={sortAscDesc} onClick={() => this.sortBy('email')}>
                                    {t('generic:shooter_email')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ padding: '4px 16px 4px 14px' }}>
                                <TableSortLabel active={sortBy === 'registrationEndDate'} direction={sortAscDesc} onClick={() => this.sortBy('registrationEndDate')}>
                                    {t('registration_end_date')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.orderBy(shooters, (s) => [s[sortBy], s.name], [sortAscDesc, sortAscDesc]).map((s) => {
                            const { publicId: shooterPublicId } = s;
                            const [shooterCountryCode, rawPublicId] = (() => {
                                if (shooterPublicId.indexOf('|') === -1) return [region.countryCode, shooterPublicId];
                                return shooterPublicId.split('|');
                            })();
                            return (
                                <TableRow key={s.id} style={{ position: 'relative' }}>
                                    <TableCell style={{ padding: '4px 16px 4px 14px' }}>
                                        <div style={{ display: 'flex' }}>
                                            <img
                                                alt={s.publicId.split('|')[0]}
                                                src={getFlagSrc({ countryCode: shooterCountryCode, size: 16 })}
                                            />
                                            &nbsp;
                                            <div>
                                                {rawPublicId}
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell style={{ padding: '4px 16px 4px 14px' }}>{s.name}</TableCell>
                                    <TableCell style={{ padding: '4px 16px 4px 14px' }}>{s.phone}</TableCell>
                                    <TableCell style={{ padding: '4px 16px 4px 14px' }}>{s.email}</TableCell>
                                    <TableCell style={{ padding: '4px 16px 4px 14px' }}>{moment(s.registrationEndDate).format('LL')}</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        <IconButton color='secondary' onClick={() => this.setState({ deleteRequest: s.id })}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        <TableRow>
                            <TableCell
                                style={{
                                    padding: '4px 16px 4px 14px',
                                }}
                            >
                                <div style={{ display: 'flex' }}>
                                    <div className={classes.flagwrapper}>
                                        <ReactFlagsSelect defaultCountry={countryCode} onSelect={(v) => this.setState({ countryCode: v })} />
                                    </div>
                                    <div>
                                        <TextField
                                            placeholder={t('generic:shooter_id')}
                                            fullWidth
                                            value={publicId}
                                            inputRef={(e) => { this.$refs.publicId = e; }}
                                            onChange={(e) => this.onChange(e, 'publicId')}
                                            onKeyDown={(e) => this.onKeyDown(e, 'name')}
                                            error={errors.publicId}
                                        />
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell style={{ padding: '4px 16px 4px 14px' }}>
                                <TextField
                                    placeholder={t('generic:shooter_name')}
                                    fullWidth
                                    value={name}
                                    inputRef={(e) => { this.$refs.name = e; }}
                                    onChange={(e) => this.onChange(e, 'name')}
                                    onKeyDown={(e) => this.onKeyDown(e, 'phone')}
                                    error={errors.name}
                                />
                            </TableCell>
                            <TableCell style={{ width: '15%', padding: '4px 16px 4px 14px' }}>
                                <TextField
                                    placeholder={t('generic:shooter_phone')}
                                    fullWidth
                                    value={phone}
                                    inputRef={(e) => { this.$refs.phone = e; }}
                                    onChange={(e) => this.onChange(e, 'phone')}
                                    onKeyDown={(e) => this.onKeyDown(e, 'email')}
                                    error={errors.phone}
                                />
                            </TableCell>
                            <TableCell style={{ padding: '4px 16px 4px 14px' }}>
                                <TextField
                                    placeholder={t('generic:shooter_email')}
                                    fullWidth
                                    value={email}
                                    inputRef={(e) => { this.$refs.email = e; }}
                                    onChange={(e) => this.onChange(e, 'email')}
                                    onKeyDown={(e) => this.onKeyDown(e, 'registrationEndDate')}
                                    error={errors.email}
                                />
                            </TableCell>
                            <TableCell style={{ padding: '4px 16px 4px 14px' }}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DatePicker
                                        value={registrationEndDate}
                                        onChange={(value) => this.setState({ registrationEndDate: value })}
                                        animateYearScrolling
                                        format='LL'
                                        minDate={new Date()}
                                        TextFieldComponent={(props) => (
                                            <TextField
                                                placeholder={t('registration_end_date')}
                                                fullWidth
                                                inputRef={(e) => { this.$refs.registrationEndDate = e; }}
                                                {...props}
                                            />
                                        )}
                                    />
                                </MuiPickersUtilsProvider>
                            </TableCell>
                            <TableCell>
                                <SaveButton onClick={this.onAdd} disabled={!publicId || !name || !email || !phone} label={t('add')} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <Snackbar
                    open={!!errors.save}
                    onClose={() => this.setState({ errors: { ...errors, save: null } })}
                    variant='error'
                    message={errors.save}
                />
                <Snackbar
                    open={!!deleteRequest}
                    variant='warning'
                    message={(
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 1 }}>
                                {t('areyousure', { name: (shooters.find((s) => s.id === deleteRequest) || {}).name })}
                            </div>
                            <Button style={{ color: 'white', margin: '12px' }} onClick={this.onDelete}>
                                {deleting ? <CircularProgress size={12} color='white' /> : t('generic:yes')}
                            </Button>
                            <Button style={{ color: 'white' }} onClick={() => this.setState({ deleteRequest: null })}>
                                {t('generic:no')}
                            </Button>
                        </div>
)}
                />
            </div>
        );
    }
}

const styles = {
    flagwrapper: {
        position: 'relative',
        top: '-5px',
        width: '50px',
        minWidth: '50px',
        maxWidth: '50px',
        display: 'flex',
        flex: 1,
        textAlign: 'left',
        '& > div': {
            flex: 1,
        },
        '& .selected--flag--option  .country-label': {
            display: 'none',
        },
        '& .flag-select': {
            textAlign: 'left',
        },
    },
};

Club.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    region: PropTypes.shape({
        countryCode: PropTypes.string.isRequired,
    }).isRequired,
    clubId: PropTypes.string.isRequired,
    auth: PropTypes.string.isRequired,
    setTitle: PropTypes.func.isRequired,
};

export default withStyles(styles)(withTranslation('regionsclub')(Club));
