import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function WorkingButton(props) {
    const { working, children } = props;
    return (
        <Button {...{ ...props, working: undefined }} disabled={working}>
            {working && <CircularProgress size={18} />}
            {!working && children}
        </Button>
    );
}

WorkingButton.propTypes = {
    working: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

WorkingButton.defaultProps = {
    working: false,
};
