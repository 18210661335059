import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';
import blue from '@material-ui/core/colors/blue';
import { summarizeScore } from './Utils';

export default withTranslation('score')((props) => {
    const {
        t, i18n, title, ro, stage, scoreMode, stageIdx, shooter,
        poppers, tenPointsPoppers, dq, scorePerTarget, specialPenalty, penaltiesByType,
        noShoots, time, bonusField, signatureNode, formTime, scoreFormDisplayMode,
    } = props;
    const { targets } = stage;

    const [showDetail, setShowDetail] = useState(false);

    const {
        alphas, charlies, deltas, misses, bonusMisses, specialPenaltyValue, total, factorStr, timeStr,
    } = summarizeScore({
        score: {
            scorePerTarget: _.values(scorePerTarget), poppers, tenPointsPoppers, specialPenalty, penaltiesByType, 'no-shoots': noShoots, time, bonusField,
        },
        powerFactor: shooter['power-factor'],
        stage,
        mode: scoreMode,
    });

    const styles = {
        caption: {
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: '0.75rem',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: '400',
            lineHeight: '1.66',
            letterSpacing: '0.03333em',
        },
    };

    const borderStart = i18n.dir() === 'rtl' ? 'borderRight' : 'borderLeft';
    const borderEnd = i18n.dir() === 'ltr' ? 'borderRight' : 'borderLeft';

    if (scoreFormDisplayMode === 'corona' && !dq && !showDetail) {
        const numCount = alphas + charlies + deltas + misses + bonusMisses;

        return (
            <div style={{ margin: 'auto', marginBottom: '24px' }}>
                <Grid container spacing={8}>
                    {_.map([{
                        key: 'Shooter',
                        value: shooter.name,
                        background: 'transparent',
                        color: 'black',
                        mandatory: true,
                        grid: 6,
                        variant: 'h6',
                    }, {
                        key: 'Stage',
                        value: <div style={{ textAlign: 'end' }}>{`${t('generic:stage_number')}: ${stageIdx}`}</div>,
                        background: 'transparent',
                        color: 'black',
                        mandatory: true,
                        grid: 6,
                        variant: 'h6',
                    }, {
                        icon: 'M',
                        value: misses,
                        background: red[500],
                    }, {
                        icon: 'PT',
                        value: noShoots,
                        background: red[400],
                    }, {
                        key: 'PBT',
                        icon: <i className='fas fa-exclamation-triangle' />,
                        value: _.sum(_.values(penaltiesByType)),
                        background: red[300],
                    }, {
                        key: 'SPV',
                        icon: <i className='fab fa-accessible-icon' />,
                        value: specialPenaltyValue,
                        background: red[200],
                    }], (prop) => renderCoronaRow({ ...prop, isRtl: i18n.dir() === 'rtl' }))}
                    <Grid item xs={6}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: blue[500],
                                height: 'calc(100% - 10px)',
                                padding: '6px',
                                boxShadow: 'rgba(0, 0, 0, 0.2) 1px 1px 6px',
                            }}
                        >
                            <i className='far fa-clock' style={{ color: 'white', fontSize: '48px', paddingBottom: '6px' }} />
                            <Typography variant='h4' style={{ color: 'white' }}>
                                {timeStr}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={8}>
                            {_.map([{
                                icon: 'A+',
                                value: tenPointsPoppers,
                                background: green[500],
                                mandatory: ((stage.targets['pcc-ten-points-poppers'] || 0) + (stage.targets['pcc-ten-points-frangible'] || 0)) > 0,
                            },
                            {
                                icon: 'A',
                                value: alphas - tenPointsPoppers,
                                background: green[500],
                                mandatory: true,
                            }, {
                                icon: scoreMode === 'comstock-abd' ? 'B' : 'C',
                                value: charlies,
                                background: green[400],
                                mandatory: true,
                            }, {
                                icon: 'D',
                                value: deltas,
                                background: deltas === 0 ? green[300] : amber[300],
                                mandatory: true,
                            }, {
                                icon: '(M)',
                                value: bonusMisses,
                                background: amber[200],
                            }], (prop) => renderCoronaRow({ ...prop, isRtl: i18n.dir() === 'rtl' }))}
                        </Grid>
                    </Grid>
                    {_.map([{
                        key: 'Count',
                        icon: <i className='fas fa-bullseye' />,
                        value: `${numCount} / ${stage.rounds}`,
                        background: numCount === stage.rounds ? '#666' : red[500],
                        mandatory: true,
                        grid: 6,
                    }, {
                        key: 'F',
                        icon: i18n.dir() === 'rtl' ? <i className='far fa-hand-point-right' /> : <i className='far fa-hand-point-left' />,
                        value: `${total}/${timeStr} = ${factorStr}`,
                        background: '#666',
                        mandatory: true,
                        grid: 6,
                    }], (prop) => renderCoronaRow({ ...prop, isRtl: i18n.dir() === 'rtl' }))}
                </Grid>

                <Button variant='text' onClick={() => setShowDetail(true)} fullWidth color='primary'>
                    {t('scoreformdisplaymode-corona-showdetail')}
                </Button>

                <div style={{ marginTop: '12px', position: 'relative' }}>
                    {signatureNode}
                </div>
            </div>
        );
    }

    return (
        <div style={{
            minWidth: '410px', width: '434px', margin: 'auto', marginBottom: '24px',
        }}
        >
            <div style={{
                position: 'relative', background: 'white', boxShadow: '0px 0px 2px rgba(0,0,0,0.3)', padding: '12px', minWidth: '410px', width: '410px', margin: 'auto', marginTop: '12px',
            }}
            >
                {dq && (
                    <div style={{
                        position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center',
                    }}
                    >
                        <div style={{ transform: 'rotate(-50deg)', fontSize: '64px', textAlign: 'center' }}>
                            <div style={{ fontSize: '24px' }}>
                                {shooter.publicId}
                                {' '}
                                /
                                {' '}
                                {shooter.name}
                            </div>
                            <div>DQ</div>
                            <div>{dq}</div>
                        </div>
                    </div>
                )}
                <div style={{ display: 'flex', opacity: dq ? 0.5 : 1 }}>
                    <div>
                        <div style={{ display: 'flex' }}>
                            <div style={{
                                border: '1px solid white', display: 'block', textAlign: 'center', lineHeight: '20px', width: '40px', height: '20px', margin: '2px',
                            }}
                            />
                            <div style={{
                                border: '1px solid white', display: 'block', textAlign: 'center', lineHeight: '20px', width: '40px', height: '20px', margin: '2px',
                            }}
                            >
                                A
                            </div>
                            <div style={{
                                border: '1px solid white', display: 'block', textAlign: 'center', lineHeight: '20px', width: '40px', height: '20px', margin: '2px',
                            }}
                            >
                                {scoreMode === 'comstock-abd' ? 'B' : 'C'}
                            </div>
                            <div style={{
                                border: '1px solid white', display: 'block', textAlign: 'center', lineHeight: '20px', width: '40px', height: '20px', margin: '2px',
                            }}
                            >
                                D
                            </div>
                            <div style={{
                                border: '1px solid white', display: 'block', textAlign: 'center', lineHeight: '20px', width: '40px', height: '20px', margin: '2px',
                            }}
                            >
                                M
                            </div>
                            <div style={{
                                border: '1px solid white', display: 'block', textAlign: 'center', lineHeight: '20px', width: '40px', height: '20px', margin: '2px', fontStyle: 'italic',
                            }}
                            >
                                (M)
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{
                                border: 'white', display: 'block', textAlign: 'center', lineHeight: '40px', width: '40px', height: '40px', margin: '2px',
                            }}
                            />
                            <div style={{
                                border: '1px solid rgba(0,0,0,0.5)', display: 'block', textAlign: 'center', lineHeight: '40px', width: '40px', height: '40px', margin: '2px',
                            }}
                            >
                                {poppers || ''}
                            </div>
                            <div style={{
                                border: '1px solid white', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', lineHeight: 'normal', width: '82px', height: '36px', margin: '4px', fontSize: '12px',
                            }}
                            >
                                {t('generic:poppers')}
                            </div>
                            <div style={{
                                border: '1px solid rgba(0,0,0,0.5)', display: 'block', textAlign: 'center', lineHeight: '40px', width: '40px', height: '40px', margin: '2px',
                            }}
                            >
                                {scoreMode === 'steel-no-miss' ? '' : (!time ? '' : ((targets.poppers || 0) + (targets.minipoppers || 0) + (targets.plates || 0) + (targets['pcc-frangible'] || 0) - (poppers || 0)) || '')}
                            </div>
                            {scoreMode === 'steel-no-miss' && (
                                <div style={{
                                    border: '1px solid rgba(0,0,0,0.5)', display: 'block', textAlign: 'center', lineHeight: '40px', width: '40px', height: '40px', margin: '2px',
                                }}
                                >
                                    {!time ? '' : ((targets.poppers || 0) + (targets.minipoppers || 0) + (targets.plates || 0) + (targets['pcc-frangible'] || 0) - (poppers || 0)) || ''}
                                </div>
                            )}
                        </div>
                        {((targets['pcc-ten-points-poppers'] || 0) + (targets['pcc-ten-points-frangible'] || 0) > 0) && (
                            <div style={{ display: 'flex' }}>
                                <div style={{
                                    border: 'white', display: 'block', textAlign: 'center', lineHeight: '40px', width: '40px', height: '40px', margin: '2px',
                                }}
                                />
                                <div style={{
                                    border: '1px solid rgba(0,0,0,0.5)', display: 'block', textAlign: 'center', lineHeight: '40px', width: '40px', height: '40px', margin: '2px',
                                }}
                                >
                                    {tenPointsPoppers || ''}
                                </div>
                                <div style={{
                                    border: '1px solid white', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', lineHeight: 'normal', width: '82px', height: '36px', margin: '4px', fontSize: '12px',
                                }}
                                >
                                    {t('generic:tenpointspoppers')}
                                </div>
                                <div style={{
                                    border: '1px solid rgba(0,0,0,0.5)', display: 'block', textAlign: 'center', lineHeight: '40px', width: '40px', height: '40px', margin: '2px',
                                }}
                                >
                                    {scoreMode === 'steel-no-miss' ? '' : (!time ? '' : ((targets['pcc-ten-points-poppers'] || 0) + (targets['pcc-ten-points-frangible'] || 0) - (tenPointsPoppers || 0)) || '')}
                                </div>
                                {scoreMode === 'steel-no-miss' && (
                                    <div style={{
                                        border: '1px solid rgba(0,0,0,0.5)', display: 'block', textAlign: 'center', lineHeight: '40px', width: '40px', height: '40px', margin: '2px',
                                    }}
                                    >
                                        {!time ? '' : ((targets['pcc-ten-points-poppers'] || 0) + (targets['pcc-ten-points-frangible'] || 0) - (tenPointsPoppers || 0)) || ''}
                                    </div>
                                )}
                            </div>
                        )}
                        {_.range((targets.paper || 0) + (targets.minipaper || 0)).map((target) => {
                            const score = (scorePerTarget || {})[target] || '';
                            const numalphas = score.split('').filter((tar) => tar === 'A').length || 0;
                            const numcharlies = score.split('').filter((tar) => tar === 'C').length || 0;
                            const numdeltas = score.split('').filter((tar) => tar === 'D').length || 0;
                            const nummisses = score.split('').filter((tar) => tar === 'M').length || 0;
                            const numBonusMisses = score.split('').filter((tar) => tar === 'm').length || 0;
                            return (
                                <div key={target} style={{ display: 'flex' }}>
                                    <div style={{
                                        display: 'flex', alignItems: 'center', justifyContent: 'center', width: '40px', height: '40px', margin: '2px',
                                    }}
                                    >
                                        T
                                        {target + 1}
                                    </div>
                                    <div style={{
                                        border: '1px solid rgba(0,0,0,0.5)', display: 'block', textAlign: 'center', lineHeight: '40px', width: '40px', height: '40px', margin: '2px',
                                    }}
                                    >
                                        {numalphas || ''}
                                    </div>
                                    <div style={{
                                        border: '1px solid rgba(0,0,0,0.5)', display: 'block', textAlign: 'center', lineHeight: '40px', width: '40px', height: '40px', margin: '2px',
                                    }}
                                    >
                                        {numcharlies || ''}
                                    </div>
                                    <div style={{
                                        border: '1px solid rgba(0,0,0,0.5)', display: 'block', textAlign: 'center', lineHeight: '40px', width: '40px', height: '40px', margin: '2px',
                                    }}
                                    >
                                        {numdeltas || ''}
                                    </div>
                                    <div style={{
                                        border: '1px solid rgba(0,0,0,0.5)', display: 'block', textAlign: 'center', lineHeight: '40px', width: '40px', height: '40px', margin: '2px',
                                    }}
                                    >
                                        {nummisses || ''}
                                    </div>
                                    <div style={{
                                        border: '1px solid rgba(0,0,0,0.5)', display: 'block', textAlign: 'center', lineHeight: '40px', width: '40px', height: '40px', margin: '2px',
                                    }}
                                    >
                                        {numBonusMisses || ''}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div style={{ [i18n.dir() === 'rtl' ? 'marginRight' : 'marginLeft']: '30px' }}>
                        <div style={{ fontSize: '13px', fontWeight: '300', marginTop: '6px' }}>
                            {formTime || moment().format('L LT')}
                        </div>
                        <div style={{ ...styles.caption, marginTop: '12px' }}>
                            <u>{t('match')}</u>
                        </div>
                        <div style={{ fontSize: '13px', fontWeight: '900' }}>
                            {title}
                        </div>
                        <div style={{ ...styles.caption, marginTop: '12px' }}>
                            <u>
                                {t('generic:stage_number')}
                                :
                                {' '}
                                {stageIdx}
                            </u>
                        </div>
                        <div style={{ fontSize: '13px', fontWeight: '300' }}>
                            {t('ro')}
                            :
                            <b>{ro}</b>
                        </div>
                        <div style={{ ...styles.caption, marginTop: '12px' }}>
                            <u>{t('shooter')}</u>
                        </div>
                        <div style={{ fontSize: '13px', fontWeight: '300' }}>
                            {shooter.publicId}
                            {' '}
                            /
                            <b>{shooter.name}</b>
                        </div>
                        <div style={{ fontSize: '13px', fontWeight: '300' }}>
                            {[t(`generic:division-${shooter.division}`), t(`generic:category-${shooter.category}`), t(`generic:power-factor-${shooter['power-factor']}`)].filter((a) => !!a).join(' | ')}
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ opacity: dq ? 0.5 : 1 }}>
                        <div style={{ margin: '6px 45px', width: '229px', borderBottom: '2px solid black' }} />
                        <div style={{ [i18n.dir() === 'ltr' ? 'marginLeft' : 'marginRight']: '45px' }}>
                            <div style={{
                                display: 'table-cell', textAlign: 'center', lineHeight: '40px', width: '43px', height: '40px', [borderEnd]: '2px solid black', borderTop: '2px solid black', borderBottom: '2px solid black', [borderStart]: '2px solid black',
                            }}
                            >
                                {alphas || ''}
                            </div>
                            <div style={{
                                display: 'table-cell', textAlign: 'center', lineHeight: '40px', width: '43px', height: '40px', [borderEnd]: '2px solid black', borderTop: '2px solid black', borderBottom: '2px solid black',
                            }}
                            >
                                {charlies || ''}
                            </div>
                            <div style={{
                                display: 'table-cell', textAlign: 'center', lineHeight: '40px', width: '45px', height: '40px', [borderEnd]: '2px solid black', borderTop: '2px solid black', borderBottom: '2px solid black',
                            }}
                            >
                                {deltas || ''}
                            </div>
                            <div style={{
                                display: 'table-cell', textAlign: 'center', lineHeight: '40px', width: '43px', height: '40px', [borderEnd]: '2px solid black', borderTop: '2px solid black', borderBottom: '2px solid black',
                            }}
                            >
                                {misses || ''}
                            </div>
                            <div style={{
                                display: 'table-cell', textAlign: 'center', lineHeight: '40px', width: '43px', height: '40px', [borderEnd]: '2px solid black', borderTop: '2px solid black', borderBottom: '2px solid black',
                            }}
                            >
                                {bonusMisses || ''}
                            </div>
                            <div style={{
                                display: 'table-cell', textAlign: 'center', lineHeight: '40px', width: '43px', height: '40px', [borderEnd]: '2px solid black',
                            }}
                            />
                            <div style={{
                                display: 'table-cell', textAlign: 'center', lineHeight: '40px', width: '43px', height: '40px', [borderEnd]: '2px solid black', borderTop: '2px solid black', borderBottom: '2px solid black',
                            }}
                            >
                                {noShoots || ''}
                            </div>
                            <div style={{
                                display: 'table-cell', textAlign: 'center', lineHeight: '40px', width: '43px', height: '40px', [borderEnd]: '2px solid black', borderTop: '2px solid black', borderBottom: '2px solid black',
                            }}
                            >
                                {_.sum(_.values(penaltiesByType)) || ''}
                            </div>
                        </div>
                        <div>
                            <div style={{ display: 'flex' }}>
                                <div style={{
                                    border: '1px solid white', display: 'block', textAlign: 'center', lineHeight: '20px', width: '40px', height: '20px', margin: '2px',
                                }}
                                />
                                <div style={{
                                    border: '1px solid white', display: 'block', textAlign: 'center', lineHeight: '20px', width: '40px', height: '20px', margin: '2px',
                                }}
                                >
                                    <div style={styles.caption}>A</div>
                                </div>
                                <div style={{
                                    border: '1px solid white', display: 'block', textAlign: 'center', lineHeight: '20px', width: '40px', height: '20px', margin: '2px',
                                }}
                                >
                                    <div style={styles.caption}>
                                        {scoreMode === 'comstock-abd' ? 'B' : 'C'}
                                    </div>
                                </div>
                                <div style={{
                                    border: '1px solid white', display: 'block', textAlign: 'center', lineHeight: '20px', width: '40px', height: '20px', margin: '2px',
                                }}
                                >
                                    <div style={styles.caption}>D</div>
                                </div>
                                <div style={{
                                    border: '1px solid white', display: 'block', textAlign: 'center', lineHeight: '20px', width: '40px', height: '20px', margin: '2px',
                                }}
                                >
                                    <div style={styles.caption}>M</div>
                                </div>
                                <div style={{
                                    border: '1px solid white', display: 'block', textAlign: 'center', lineHeight: '20px', width: '40px', height: '20px', margin: '2px',
                                }}
                                >
                                    <div style={styles.caption}>(M)</div>
                                </div>
                                <div style={{
                                    border: '1px solid white', display: 'block', textAlign: 'center', lineHeight: '20px', width: '39px', height: '20px', margin: '2px',
                                }}
                                />
                                <div style={{
                                    border: '1px solid white', display: 'block', textAlign: 'center', lineHeight: '20px', width: '40px', height: '20px', margin: '2px',
                                }}
                                >
                                    <div style={styles.caption}>PT</div>
                                </div>
                                <div style={{
                                    border: '1px solid white', display: 'block', textAlign: 'center', lineHeight: '20px', width: '40px', height: '20px', margin: '2px',
                                }}
                                >
                                    <div style={styles.caption}>Proc</div>
                                </div>
                            </div>
                        </div>
                        {(_.sum(_.values(penaltiesByType)) > 0) && (
                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: '12px',
                                [i18n.dir() === 'ltr' ? 'marginLeft' : 'marginRight']: '45px',
                            }}
                            >
                                <div style={{ fontSize: '0.8em', fontWeight: '800' }}>
                                    {t('generic:penalties')}
                                    :&nbsp;
                                </div>
                                <div style={{ fontSize: '0.8em' }}>{Object.keys(penaltiesByType).filter((rule) => !!penaltiesByType[rule]).map((rule) => `${rule} (${penaltiesByType[rule]})`).join(' | ')}</div>
                            </div>
                        )}
                        {stage.bonusField && (
                            <div style={{
                                [i18n.dir() === 'ltr' ? 'marginLeft' : 'marginRight']: '45px',
                                marginTop: '24px',
                            }}
                            >
                                <div style={{
                                    display: 'block', textAlign: 'center', lineHeight: '40px', width: '88px', height: '40px', [borderEnd]: '2px solid black', borderTop: '2px solid black', borderBottom: '2px solid black', [borderStart]: '2px solid black', color: '#000',
                                }}
                                >
                                    {bonusField}
                                </div>
                                <div style={{
                                    ...styles.caption, display: 'block', textAlign: 'center', width: '88px', [borderEnd]: '2px solid white',
                                }}
                                >
                                    {stage.bonusField}
                                </div>
                            </div>
                        )}
                        <div style={{
                            [i18n.dir() === 'ltr' ? 'marginLeft' : 'marginRight']: '45px',
                            display: 'flex',
                            marginTop: '24px',
                        }}
                        >
                            <div style={{
                                display: 'block', textAlign: 'center', lineHeight: '40px', width: '88px', height: '40px', [borderEnd]: '2px solid black', borderTop: '2px solid black', borderBottom: '2px solid black', [borderStart]: '2px solid black', color: ((!dq) && (alphas + charlies + deltas + misses + bonusMisses)) !== stage.rounds ? '#f50057' : '#000',
                            }}
                            >
                                {dq ? '' : (!time ? 0 : alphas + charlies + deltas + misses + bonusMisses)}
                                {' '}
                                {dq ? '' : '/'}
                                {' '}
                                {dq ? '' : stage.rounds}
                            </div>
                            <div style={{
                                display: 'block', textAlign: 'center', lineHeight: '40px', width: '88px', height: '40px', [borderEnd]: '2px solid black', borderTop: '2px solid black', borderBottom: '2px solid black',
                            }}
                            >
                                {dq ? '' : timeStr}
                            </div>
                            {((specialPenalty || 0) > 0) && (
                                <div style={{
                                    display: 'block', textAlign: 'center', direction: 'ltr', lineHeight: '40px', width: '89px', height: '40px', [borderEnd]: '2px solid black', borderTop: '2px solid black', borderBottom: '2px solid black',
                                }}
                                >
                                    -
                                    {specialPenaltyValue}
                                </div>
                            )}
                            <div style={{
                                display: 'block', textAlign: 'center', lineHeight: '40px', width: '89px', height: '40px', [borderEnd]: '2px solid black', borderTop: '2px solid black', borderBottom: '2px solid black',
                            }}
                            >
                                {dq ? '' : total}
                            </div>
                            <div style={{
                                display: 'block', textAlign: 'center', lineHeight: '40px', width: '89px', height: '40px', [borderEnd]: '2px solid black', borderTop: '2px solid black', borderBottom: '2px solid black',
                            }}
                            >
                                {dq ? '' : factorStr}
                            </div>
                        </div>
                        <div style={{
                            [i18n.dir() === 'ltr' ? 'marginLeft' : 'marginRight']: '45px',
                            display: 'flex',
                        }}
                        >
                            <div style={{
                                ...styles.caption, display: 'block', textAlign: 'center', width: '88px', [borderEnd]: '2px solid white', [borderStart]: '2px solid white', color: ((!dq) && (alphas + charlies + deltas + misses + bonusMisses !== stage.rounds)) ? '#f50057' : '#000',
                            }}
                            >
                                {t('rounds')}
                            </div>
                            <div style={{
                                ...styles.caption, display: 'block', textAlign: 'center', width: '88px', [borderEnd]: '2px solid white',
                            }}
                            >
                                {t('time')}
                            </div>
                            {((specialPenalty || 0) > 0) && (
                                <div style={{
                                    ...styles.caption, display: 'block', textAlign: 'center', width: '88px', [borderEnd]: '2px solid white',
                                }}
                                >
                                    {t('specialpenalty', { specialPenalty })}
                                </div>
                            )}
                            <div style={{
                                ...styles.caption, display: 'block', textAlign: 'center', width: '89px', [borderEnd]: '2px solid white',
                            }}
                            >
                                {t('generic:result_total')}
                            </div>
                            <div style={{
                                ...styles.caption, display: 'block', textAlign: 'center', width: '91px',
                            }}
                            >
                                {t('generic:result_factor')}
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '12px', position: 'relative' }}>
                        {signatureNode}
                    </div>
                </div>
            </div>
        </div>
    );
});

function renderCoronaRow({
    key, icon, value, background, mandatory, grid, color, isRtl, variant,
}) {
    if (!value && !mandatory) return null;
    return (
        <Grid
            item
            key={key || icon}
            xs={grid || 12}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: background,
                    padding: '6px',
                    boxShadow: background !== 'transparent' ? 'rgba(0, 0, 0, 0.2) 1px 1px 6px' : '',
                }}
            >
                <Typography variant={variant || 'h5'} style={{ flex: 1, color: color || 'white' }}>
                    {value || 0}
                </Typography>
                {!!icon && (
                    <div
                        style={{
                            [isRtl ? 'borderRight' : 'borderLeft']: '1px solid white',
                            width: '50px',
                            textAlign: 'center',
                            [isRtl ? 'paddingRight' : 'paddingLeft']: '6px',
                            color: color || 'white',
                            fontSize: '24px',
                        }}
                    >
                        {icon}
                    </div>
                )}
            </div>
        </Grid>
    );
}

renderCoronaRow.propTypes = {
    key: PropTypes.string.isRequired,
    variant: PropTypes.string,
    background: PropTypes.string.isRequired,
    color: PropTypes.string,
    grid: PropTypes.number,
    mandatory: PropTypes.bool,
    isRtl: PropTypes.bool,
    icon: PropTypes.oneOf([PropTypes.node, PropTypes.string]),
    value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
};

renderCoronaRow.defaultProps = {
    icon: null,
    value: null,
    variant: null,
    mandatory: false,
    color: 'white',
    grid: 12,
    isRtl: false,
};
