import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import app from '../AppCommunication';

class GoogleSignIn extends Component {
    constructor(props) {
        super(props);

        if ((typeof (window) !== 'undefined') && (window.gapi)) {
            window.gapi.load('auth2', async () => {
                this.auth = await window.gapi.auth2.init();
                if (!props.noAuto) {
                    this.checkSignin();
                } else {
                    const user = this.auth.currentUser.get();
                    user.disconnect();
                }
            });
        }

        this.state = {
            id: null,
            name: null,
            imageUrl: null,
        };
    }

    async componentDidMount() {
        this.checkSignin();
    }

    onLogin = () => {
        const { mode } = this.props;
        if (mode === 'app') {
            app({ cmd: { src: 'google-login', type: 'on-login' } });
        }
    }

    logout = async () => {
        try {
            const { onLogout = () => {} } = this.props;
            const user = this.auth.currentUser.get();
            user.disconnect();
            const data = {
                id: null, name: null, givenName: null, familyName: null, imageUrl: null, email: null, authResponse: null,
            };
            this.lastLogin = false;
            onLogout();
            this.setState(data);
        } catch (e) {
            console.error(e);
        }
    }

    onLoginSuccess = async () => {
        setTimeout(() => { this.checkSignin(); }, 100);
    }

    checkSignin() {
        if (!this.auth) return;
        const { onLogin = () => {} } = this.props;
        const isSignedIn = this.auth.isSignedIn.get();
        if (isSignedIn) {
            const user = this.auth.currentUser.get();
            const profile = user.getBasicProfile();
            const data = {
                id: profile.getId(),
                name: profile.getName(),
                givenName: profile.getGivenName(),
                familyName: profile.getFamilyName(),
                imageUrl: profile.getImageUrl(),
                email: profile.getEmail(),
                authResponse: user.getAuthResponse(),
            };
            if (!this.lastLogin) {
                this.lastLogin = true;
                onLogin(data);
            }
            this.setState(data);
        }
    }

    render() {
        const { t, mode } = this.props;
        const { id, name, imageUrl } = this.state;
        if (id !== null) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{
                        position: 'relative', width: '100%', height: '50px', display: 'flex', alignItems: 'center',
                    }}
                    >
                        <img alt='profile' style={{ width: '36px', height: '36px', margin: '0px 6px' }} src={imageUrl} />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant='caption' style={{ color: 'inherit' }}>{t('authentication:connected-using-google')}</Typography>
                            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                <div>
                                    {name}
                                </div>
                                <Button
                                    color='primary'
                                    variant='text'
                                    style={{
                                        fontSize: '11px', padding: '0px 6px', minHeight: '0px', position: 'relative', top: '2px', textTransform: 'none', fontWeight: '100',
                                    }}
                                    onClick={this.logout}
                                >
                                    {t('authentication:sign-in-not-me')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div style={{ position: 'relative', width: '240px', height: '50px' }}>
                <Button
                    color='primary'
                    variant='contained'
                    style={{
                        width: '240px', height: '50px', textTransform: 'none', display: 'flex',
                    }}
                    onClick={this.onLogin}
                >
                    <Icon className='fab fa-google' />
                    <div style={{ flex: 1 }}>{t('authentication:sign-in-with-google')}</div>
                </Button>
                {(mode !== 'app') && (
                    <div
                        key='signin'
                        style={{
                            position: 'absolute', top: '0px', left: '0px', zIndex: 1, opacity: 0,
                        }}
                        ref={(e) => {
                            if (!e) return;
                            if (window.gapi) {
                                window.gapi.signin2.render(e, {
                                    scope: 'profile email',
                                    width: 240,
                                    height: 50,
                                    longtitle: false,
                                    theme: 'dark',
                                    onsuccess: this.onLoginSuccess,
                                    onfailure: this.onLoginFailure,
                                });
                            }
                        }}
                    />
                )}
            </div>
        );
    }
}

GoogleSignIn.propTypes = {
    t: PropTypes.func.isRequired,
    noAuto: PropTypes.bool,
    mode: PropTypes.string,
    onLogin: PropTypes.func,
    onLogout: PropTypes.func,
};

GoogleSignIn.defaultProps = {
    mode: null,
    noAuto: false,
    onLogin: () => {},
    onLogout: () => {},
};

export default withTranslation('')(GoogleSignIn);
