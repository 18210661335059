/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactRouterPropTypes from 'react-router-prop-types';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import IconSettings from '@material-ui/icons/Settings';
import { withStyles } from '@material-ui/core/styles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Refresh from '@material-ui/icons/Refresh';
import GenericI18nThemeWrapper from '../components/GenericI18nThemeWrapper';
import api from '../Api';

class ViewerWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            match: null,
        };
    }

    async UNSAFE_componentWillMount() {
        const { matchId, ssrMatch } = this.props;
        if (ssrMatch) return;

        const promises = await Promise.all([
            api.getMatch({ matchId }),
            api.getAnnouncements({ matchId }),
        ]);

        const match = promises[0];
        const announcements = promises[1];

        this.setState({ match, announcements });
    }

    render() {
        const { match, announcements } = this.state;
        const { ssrMatch, ssrAnnouncements, onUpdateThemeLocale } = this.props;

        return (
            <GenericI18nThemeWrapper match={match || ssrMatch} onUpdateThemeLocale={onUpdateThemeLocale}>
                <ViewerHomeInner {...this.props} {...this.state} match={match || ssrMatch} announcements={announcements || ssrAnnouncements} />
            </GenericI18nThemeWrapper>
        );
    }
}

export default ViewerWrapper;

ViewerWrapper.propTypes = {
    matchId: PropTypes.string,
    ssrMatch: PropTypes.shape({}),
    ssrAnnouncements: PropTypes.arrayOf(PropTypes.shape({})),
    onUpdateThemeLocale: PropTypes.func.isRequired,
};

ViewerWrapper.defaultProps = {
    matchId: null,
    ssrMatch: null,
    ssrAnnouncements: null,
};

class ViewerWrapperInner_ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subtitle: null,
        };
    }

    render() {
        const {
            match, announcements, t, component, componentProps, url, pathname, classes, src, mode, history,
        } = this.props;
        const { subtitle } = this.state;

        const Comp = component;

        const { squadsPublished, stagesPublished, scoresPublished } = match;

        const win = (() => {
            if (typeof (window) !== 'undefined') return window;
            return {
                location: {
                    search: '',
                    reload: () => {},
                },
            };
        })();

        const MainLink = React.forwardRef((props, ref) => <Link ref={ref} to={`/${match.alias}${win.location.search}`} className={classes.logo} {...props} />);
        const RegistrationLink = React.forwardRef((props, ref) => <Link ref={ref} to={`/${match.alias}/registration${win.location.search}`} {...props} />);
        const SquadLink = React.forwardRef((props, ref) => <Link ref={ref} to={`/${match.alias}/squads${win.location.search}`} {...props} />);
        const ShootersLink = React.forwardRef((props, ref) => <Link ref={ref} to={`/${match.alias}/shooters${win.location.search}`} {...props} />);
        const StagesLink = React.forwardRef((props, ref) => <Link ref={ref} to={`/${match.alias}/stages${win.location.search}`} {...props} />);
        const ScoreTableLink = React.forwardRef((props, ref) => <Link ref={ref} to={`/${match.alias}/results${win.location.search}`} {...props} />);
        const ManageLink = React.forwardRef((props, ref) => <Link ref={ref} to={`/${match.alias}/manage${win.location.search}`} {...props} />);

        return (
            <div>
                <div className='noprint'>
                    <AppBar>
                        {(mode === 'app') && (
                            <div className={classes.topbar}>
                                <Button color='primary' onClick={() => history.goBack()}><ChevronLeft /></Button>
                                <Typography
                                    variant='body2'
                                    style={{ textAlign: 'center', flex: 1 }}
                                    onClick={() => history.push('/')}
                                >
                                    Aria
                                </Typography>
                                <Button color='primary' onClick={() => { history.push('/__refresh__'); setTimeout(() => history.goBack(), 100); }}><Refresh /></Button>
                            </div>
                        )}
                        <Toolbar className={classnames({ [classes.margintop]: mode === 'app' })}>
                            <MainLink>
                                <div className={classes.logo}>
                                    {match.logo && (
                                        <div
                                            style={{
                                                background: `url('${match.logo}')`, backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', width: '100px', height: '100px',
                                            }}
                                        />
                                    )}

                                    <div>
                                        <Typography variant='h6' color='inherit' noWrap style={{ margin: '0px 12px' }}>
                                            {match.title}
                                        </Typography>
                                        {mode === 'app' && (
                                            <Typography variant='body2' color='inherit' noWrap style={{ margin: '0px 12px' }}>
                                                {subtitle}
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                            </MainLink>

                            <Hidden only={['xs']}>
                                <div style={{ flexGrow: 1 }} />
                                <Button style={{ textTransform: 'none' }} color='inherit' component={SquadLink} disabled={!squadsPublished}>{t('shooters-show-squads-title')}</Button>
                                <Button style={{ textTransform: 'none' }} color='inherit' component={ShootersLink} disabled={!squadsPublished}>{t('shooters-show-shooters-title')}</Button>
                                <Button style={{ textTransform: 'none' }} color='inherit' component={StagesLink} disabled={!stagesPublished}>{t('shooters-stages-title')}</Button>
                                {match.registration !== 'closed' && <Button style={{ textTransform: 'none' }} color='inherit' component={RegistrationLink} disabled={match.registration === 'closed'}>{t('shooters-registration-title')}</Button>}
                                <Button style={{ textTransform: 'none' }} color='inherit' component={ScoreTableLink} disabled={!scoresPublished}>{t('shooters-show-scores-title')}</Button>
                            </Hidden>

                        </Toolbar>
                    </AppBar>

                    <div style={{
                        marginTop: '100px', width: '100vw', height: 'calc(80vh - 100px)', backgroundImage: `url('${match.cover}')`, backgroundPosition: 'center', backgroundSize: 'cover', display: mode === 'app' ? 'none' : 'flex', alignItems: 'center', justifyContent: 'center',
                    }}
                    >

                        <Paper style={{
                            textAlign: 'center', padding: '24px 48px', background: 'rgba(255, 255, 255, 0.6)', maxWidth: '50%',
                        }}
                        >
                            <div>
                                <Hidden only={['xs']}>
                                    <div>
                                        <Typography variant='h3' color='inherit' style={{ whiteSpace: 'normal' }}>
                                            {match.title}
                                        </Typography>
                                        <Typography variant='h6' color='inherit' style={{ whiteSpace: 'normal' }}>
                                            {subtitle}
                                        </Typography>
                                    </div>
                                </Hidden>
                                <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                    <div>
                                        <Typography variant='h4' color='inherit' style={{ whiteSpace: 'normal' }}>
                                            {match.title}
                                        </Typography>
                                        <Typography variant='h6' color='inherit' style={{ whiteSpace: 'normal' }}>
                                            {subtitle}
                                        </Typography>
                                    </div>
                                </Hidden>
                            </div>
                        </Paper>

                    </div>
                    <div style={{ marginTop: '12px' }} />
                </div>

                <div style={{ marginTop: mode === 'app' ? '157px' : '0px' }} />

                <Comp match={match} announcements={announcements} matchId={match.id} url={url} pathname={pathname} setTitle={(title) => this.setState({ subtitle: title })} src={src} {...componentProps} mode={mode} />

                <div style={{ marginBottom: '24px' }} />
                <div
                    className='noprint'
                    style={{
                        display: 'flex', alignItems: 'center', padding: '0px 24px', borderTop: '1px solid rgba(0,0,0,0.2)',
                    }}
                >
                    <div style={{ flex: 1 }}>
                        <Typography variant='caption'>
                            Powered by
                            {' '}
                            <a href='/' style={{ color: 'inherit' }}>endofscoring.com</a>
                            {' | '}
                            <a target='_blank' rel='noopener noreferrer' href='https://docs.google.com/document/d/1Saeek_HSNjO4_yW6FvtkQHOAFplhmxEs1Ctd-UMYOto/edit?usp=sharing' style={{ color: 'inherit' }}>Terms of Service</a>
                            {' | '}
                            <a target='_blank' rel='noopener noreferrer' href='https://docs.google.com/document/d/1l7tK7PK0I3BYNuFC104ipvMhWIMETxahUi690oL_Wr0/edit?usp=sharing' style={{ color: 'inherit' }}>Privacy Policy</a>
                        </Typography>
                    </div>
                    <Button color='primary' component={ManageLink}>
                        <IconSettings fontSize='small' />
                        &nbsp;
                        <Typography style={{ color: 'inherit' }} variant='caption'>
                            {t('tomanage')}
                        </Typography>
                    </Button>
                </div>
            </div>
        );
    }
}

ViewerWrapperInner_.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    component: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.elementType,
        PropTypes.node,
        PropTypes.func,
    ]).isRequired,
    componentProps: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({
        id: PropTypes.string,
        alias: PropTypes.string,
        cover: PropTypes.string,
        squadsPublished: PropTypes.bool,
        stagesPublished: PropTypes.bool,
        scoresPublished: PropTypes.string,
        logo: PropTypes.string,
        title: PropTypes.string,
        registration: PropTypes.string,
    }).isRequired,
    url: PropTypes.string.isRequired,
    mode: PropTypes.string,
    src: PropTypes.string,
    pathname: PropTypes.string,
    announcements: PropTypes.arrayOf(PropTypes.shape({})),
};

ViewerWrapperInner_.defaultProps = {
    pathname: null,
    src: null,
    announcements: null,
    mode: null,
};

const styles = () => ({
    topbar: {
        direction: 'ltr',
        position: 'fixed',
        zIndex: 10,
        top: '0px',
        left: '0px',
        width: '100%',
        height: '45px',
        display: 'flex',
        alignItems: 'center',
        background: 'white',
    },
    margintop: {
        marginTop: '45px',
    },
    logo: {
        height: '100px',
        textDecoration: 'none',
        color: 'inherit',
        display: 'flex',
        alignItems: 'center',
        '& > h6': {
            textDecoration: 'none',
            color: 'inherit',
        },
    },
});

const ViewerHomeInner = withStyles(styles, { flip: false })(withTranslation('matchhome')(ViewerWrapperInner_));
/* eslint-enable max-classes-per-file */
