import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import moment from 'moment';
import uuidv4 from 'uuid/v4';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import GroupIcon from '@material-ui/icons/PeopleOutline';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import cyan from '@material-ui/core/colors/cyan';
import grey from '@material-ui/core/colors/grey';
import ShooterInfo from '../components/ShooterInfo';
import api from '../Api';

function ScoreStageSquadAttendance(props) {
    const {
        t, classes, auth, match,
        setTitle, setSaveButtonCallback, setBackButtonVisible, setExtraButtons, shooter,
        stageIdx, squadId, shooters, me, routeProps, equipmentCheck, onChange,
    } = props;

    const [shooterIdx, setShooterIdx] = useState(0);
    const [tempEquipmentCheck, setTempEquipmentCheck] = useState(equipmentCheck || {});
    const [showErrorButtons, setShowErrorButtons] = useState(false);
    const [sending, setSending] = useState(false);
    const [sendingError, setSendingError] = useState(null);

    // Set up title and stuff
    useEffect(() => {
        if (squadId) {
            setTitle(`${t('managewrapper:scores')} - ${t('stage')} ${stageIdx} - ${match.squads.find((s) => s.id === squadId.split(':')[0]).title}`);
            const cache = JSON.parse(window.localStorage.getItem('__equipmentcheck_cache') || '{}');
            if ((cache.squadId === squadId) && (cache.stageIdx === stageIdx)) {
                setShooterIdx(cache.shooterIdx);
                setTempEquipmentCheck(cache.tempEquipmentCheck);
            }
        } else {
            setTitle(`${t('managewrapper:scores')} - ${t('stage')} ${stageIdx} - ${shooter.name}`);
        }
        setExtraButtons(null);
        setSaveButtonCallback(null);
        setBackButtonVisible(true);

        document.getElementById('__mobile_scoring_wrapper').style.overflowX = 'hidden';

        return () => {
            document.getElementById('__mobile_scoring_wrapper').style.overflowX = '';
        };
    }, []);

    useEffect(() => {
        if (squadId) {
            window.localStorage.setItem('__equipmentcheck_cache', JSON.stringify({
                squadId, stageIdx, shooterIdx, tempEquipmentCheck,
            }));
        }
    }, [shooterIdx]);

    const squadShooters = (() => {
        if (!squadId) return [shooter];
        const s = shooters.filter(($s) => $s.squad === squadId.split(':')[0]);
        if (squadId.indexOf(':') === -1) return s;
        const part = parseInt((squadId.split(':')[1])[0], 10);
        const splits = parseInt((squadId.split(':')[1])[1], 10);
        const chunks = _.chunk(s, Math.ceil(s.length / splits));
        return chunks[part];
    })();

    async function onShooterOk(shooterId) {
        const temp = JSON.parse(JSON.stringify(tempEquipmentCheck));
        temp[shooterId] = 'ok';
        await setTempEquipmentCheck(temp);
        await new Promise((res) => setTimeout(res, 500));
        if (shooterIdx < squadShooters.length) {
            setShooterIdx(shooterIdx + 1);
        }
    }

    async function onShooterAbsent(shooterId) {
        const temp = JSON.parse(JSON.stringify(tempEquipmentCheck));
        temp[shooterId] = 'absent';
        await setTempEquipmentCheck(temp);
        await new Promise((res) => setTimeout(res, 500));
        if (shooterIdx < squadShooters.length) {
            setShooterIdx(shooterIdx + 1);
        }
    }

    function onToggleShooterEquipmentError() {
        setShowErrorButtons(!showErrorButtons);
    }

    async function onShooterEquipmentMoveOpen(shooterId) {
        const temp = JSON.parse(JSON.stringify(tempEquipmentCheck));
        temp[shooterId] = 'error-open';
        await Promise.all([setTempEquipmentCheck(temp), setShowErrorButtons(false)]);
        await new Promise((res) => setTimeout(res, 500));
        if (shooterIdx < squadShooters.length) {
            setShooterIdx(shooterIdx + 1);
        }
    }

    async function onShooterEquipmentMoveNoScore(shooterId) {
        const temp = JSON.parse(JSON.stringify(tempEquipmentCheck));
        temp[shooterId] = 'error-noscore';
        await Promise.all([setTempEquipmentCheck(temp), setShowErrorButtons(false)]);
        await new Promise((res) => setTimeout(res, 500));
        if (shooterIdx < squadShooters.length) {
            setShooterIdx(shooterIdx + 1);
        }
    }

    function onShooterEdit(shooterId) {
        const temp = JSON.parse(JSON.stringify(tempEquipmentCheck));
        delete temp[shooterId];
        setTempEquipmentCheck(temp);
    }

    function onEditShooter(shooterId) {
        const { history } = routeProps;
        history.push(`/${match.id}/manage/scores/edit-shooter/${shooterId}${history.location.search}`);
    }

    async function onSendToServer(ev, local) {
        await Promise.all([setSending(true), setSendingError(null)]);
        try {
            if (!_.isEqual(tempEquipmentCheck, equipmentCheck)) {
                /* eslint-disable no-param-reassign */
                let newShooters = null;
                if (local) {
                    const localCache = JSON.parse(window.localStorage.getItem('__offline_cache') || '[]');
                    localCache.push({
                        id: uuidv4(),
                        matchId: match.id,
                        type: 'attendance',
                        timestamp: new Date().getTime(),
                        data: {
                            matchId: match.id,
                            auth,
                            stageIdx,
                            checks: _.transform(tempEquipmentCheck, (r, v, k) => { r[k] = (v === 'absent-dq' || v === 'absent-not-paid') ? 'absent' : v; }, {}),
                        },
                    });
                    window.localStorage.setItem('__offline_cache', JSON.stringify(localCache));
                    newShooters = {};
                    _.each(tempEquipmentCheck, (v, k) => {
                        const $shooter = JSON.parse(JSON.stringify(shooters.find((s) => s.id === k)));
                        $shooter.equipmentChecks = $shooter.equipmentChecks || [];
                        while ($shooter.equipmentChecks.length < stageIdx) {
                            $shooter.equipmentChecks.push(null);
                        }
                        $shooter.equipmentChecks[stageIdx - 1] = {
                            check: (v === 'absent-dq' || v === 'absent-not-paid') ? 'absent' : v,
                            ro: me.name,
                            timestamp: new Date().getTime(),
                        };
                        newShooters[$shooter.id] = $shooter;
                    });
                } else {
                    newShooters = await api.postShootersEquipmentCheck({
                        matchId: match.id,
                        auth,
                        stageIdx,
                        checks: _.transform(tempEquipmentCheck, (r, v, k) => { r[k] = (v === 'absent-dq' || v === 'absent-not-paid') ? 'absent' : v; }, {}),
                    });
                }
                /* eslint-enable no-param-reassign */
                window.localStorage.removeItem('__equipmentcheck_cache');
                onChange(newShooters);
            } else {
                window.localStorage.removeItem('__equipmentcheck_cache');
                onChange({});
            }
        } catch (e) {
            if (!local) {
                if ((_.get(e, 'response.status') >= 400) && (_.get(e, 'response.status') < 500)) {
                    await setSendingError('generic');
                } else {
                    await setSendingError('save-local');
                }
            } else {
                await setSendingError('generic');
            }
        }
        await setSending(false);
    }

    function renderPrevNextButtons() {
        if (squadId) {
            return (
                <div style={{ display: 'flex' }}>
                    <Button
                        color='primary'
                        disabled={shooterIdx === 0}
                        onClick={() => setShooterIdx(shooterIdx - 1)}
                    >
                        {t('previous-shooter')}
                    </Button>
                    <div style={{ flex: '1' }} />
                    <Button
                        color='primary'
                        disabled={shooterIdx > squadShooters.length - 1}
                        onClick={() => setShooterIdx(shooterIdx + 1)}
                    >
                        {t('next-shooter')}
                    </Button>
                </div>
            );
        }
        return (
            <div style={{ display: 'flex' }}>
                <Button
                    classes={{ root: classes.doneButton }}
                    fullWidth
                    color='primary'
                    variant='contained'
                    disabled={sending}
                    onClick={onSendToServer}
                >
                    {sending && <CircularProgress size={24} />}
                    {!sending && t('equipmentcheckdonebutton')}
                </Button>
                {sendingError && (
                    <Typography className={classes.doneError}>
                        {t('generic:saveerror')}
                    </Typography>
                )}
                {sendingError === 'save-local' && (
                    <Button
                        classes={{ root: classes.doneError }}
                        style={{ padding: '0px', textTransform: 'none', textDecoration: 'underline' }}
                        fullWidth
                        color='secondary'
                        variant='flat'
                        onClick={(e) => onSendToServer(e, true)}
                    >
                        {t('savelocalsavelocal')}
                    </Button>
                )}
            </div>
        );
    }

    function renderDone() {
        return (
            <div className={classes.doneScreen}>
                <GroupIcon style={{ fontSize: '144px', opacity: 0.7 }} />
                <Typography variant='h5'>
                    {t('equipmentcheckdonetitle')}
                </Typography>
                {squadId && (
                    <Typography variant='body1'>
                        {t('equipmentcheckdonebody')}
                    </Typography>
                )}
                <Button
                    classes={{ root: classes.doneButton }}
                    fullWidth
                    color='primary'
                    variant='contained'
                    disabled={sending}
                    onClick={onSendToServer}
                >
                    {sending && <CircularProgress size={24} />}
                    {!sending && t('equipmentcheckdonebutton')}
                </Button>
                {sendingError && (
                    <Typography className={classes.doneError}>
                        {t('generic:saveerror')}
                    </Typography>
                )}
                {sendingError === 'save-local' && (
                    <Button
                        classes={{ root: classes.doneError }}
                        style={{ padding: '0px', textTransform: 'none', textDecoration: 'underline' }}
                        fullWidth
                        color='secondary'
                        variant='flat'
                        onClick={(e) => onSendToServer(e, true)}
                    >
                        {t('savelocalsavelocal')}
                    </Button>
                )}
                <Button
                    classes={{ root: classes.doneButton }}
                    fullWidth
                    color='secondary'
                    onClick={() => Promise.all([setSendingError(null), setShooterIdx(0)])}
                >
                    {t('equipmentcheckdoneredo')}
                </Button>
            </div>
        );
    }

    function onComment($shooter) {
        const { history } = routeProps;
        history.push(`/${match.alias}/manage/comments/${$shooter.id}`);
    }

    function renderComments($shooter) {
        return (
            <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='body2'>
                        {t('registration:comments')}
                    </Typography>
                    <Button variant='text' color='primary' onClick={() => onComment($shooter)} style={{ padding: '0px' }}>
                        {t('registration:tocomments')}
                    </Button>
                </div>
                {($shooter.comments || []).map((c) => (
                    <div key={c.timestamp}>
                        <Typography variant='caption' style={{ display: 'flex', alignItems: 'center' }}>
                            {c.comment}
                            &nbsp;
                            (
                            {c.name}
                            ,
                            &nbsp;
                            {moment(c.timestamp).format('L LT')}
                            )
                        </Typography>
                    </div>
                ))}
                {($shooter.comments || []).length === 0 && (
                    <Typography variant='caption'>
                        {t('registration:nocomments')}
                    </Typography>
                )}
            </div>
        );
    }

    function renderStamp($shooter) {
        return (
            <div className={classes.stampwrapper}>
                <div
                    className={classnames({
                        [classes.stamp]: true,
                        [classes.stampgreen]: tempEquipmentCheck[$shooter.id] === 'ok',
                        [classes.stampgrey]: tempEquipmentCheck[$shooter.id] === 'absent' || tempEquipmentCheck[$shooter.id] === 'absent-dq' || tempEquipmentCheck[$shooter.id] === 'absent-not-paid',
                        [classes.stampred]: tempEquipmentCheck[$shooter.id] === 'error-open' || tempEquipmentCheck[$shooter.id] === 'error-noscore',
                    })}
                >
                    <i
                        className={classnames({
                            'fas fa-check': tempEquipmentCheck[$shooter.id] === 'ok',
                            'fas fa-minus': tempEquipmentCheck[$shooter.id] === 'absent' || tempEquipmentCheck[$shooter.id] === 'absent-dq' || tempEquipmentCheck[$shooter.id] === 'absent-not-paid',
                            'fas fa-times': tempEquipmentCheck[$shooter.id] === 'error-open' || tempEquipmentCheck[$shooter.id] === 'error-noscore',
                        })}
                    />
                </div>
                <Button
                    disabled={tempEquipmentCheck[$shooter.id] === 'absent-dq' || tempEquipmentCheck[$shooter.id] === 'absent-not-paid'}
                    color='secondary'
                    style={(tempEquipmentCheck[$shooter.id] === 'absent-dq' || tempEquipmentCheck[$shooter.id] === 'absent-not-paid') ? {
                        padding: '6px 12px', background: 'white', color: 'black', marginTop: '6px', border: '2px solid #aaa',
                    } : {
                        padding: '6px 12px', background: 'black', color: 'white', marginTop: '6px',
                    }}
                    onClick={() => onShooterEdit($shooter.id)}
                >
                    {tempEquipmentCheck[$shooter.id] === 'absent-dq' ? 'DQ' : tempEquipmentCheck[$shooter.id] === 'absent-not-paid' ? t('unpaid') : t('generic:edit')}
                </Button>
            </div>
        );
    }

    function renderButtons($shooter, showSummary) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', visibility: showSummary ? 'hidden' : '' }}>
                    <div className={classes.actionwrapper}>
                        <Button
                            onClick={() => onShooterOk($shooter.id)}
                            variant='contained'
                            fullWidth
                            style={{ background: green[500], fontSize: '24px', padding: '12px 0px' }}
                        >
                            <i className='fas fa-user-check' style={{ color: 'white' }} />
                        </Button>
                        <Typography variant='caption' style={{ textAlign: 'center' }}>
                            {t('shooterhere', {
                                shooter: $shooter.name,
                                ro: me.name,
                            })}
                        </Typography>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex', marginTop: '12px', position: 'relative', visibility: showSummary ? 'hidden' : '',
                    }}
                >
                    <div className={classes.actionwrapper}>
                        <Button
                            onClick={() => onShooterAbsent($shooter.id)}
                            variant='contained'
                            fullWidth
                            style={{ background: grey[500], fontSize: '24px', padding: '12px 0px' }}
                        >
                            <i className='fas fa-user-minus' style={{ color: 'white' }} />
                        </Button>
                        <Typography variant='caption'>
                            {t('shooternothere')}
                        </Typography>
                    </div>
                    <div className={classes.actionwrapper} />
                    <div className={classes.actionwrapper}>
                        <Button
                            onClick={() => onEditShooter($shooter.id)}
                            variant='contained'
                            fullWidth
                            style={{ background: cyan[500], fontSize: '24px', padding: '12px 0px' }}
                        >
                            <i className='fas fa-user-edit' style={{ color: 'white' }} />
                        </Button>
                        <Typography variant='caption'>
                            {t('shooteredit')}
                        </Typography>
                    </div>
                    <div className={classes.actionwrapper} />
                    <div className={classes.actionwrapper}>
                        <Button
                            onClick={() => onToggleShooterEquipmentError()}
                            variant='contained'
                            fullWidth
                            style={{ background: red[500], fontSize: '24px', padding: '12px 0px' }}
                        >
                            <i className='fas fa-user-times' style={{ color: 'white' }} />
                        </Button>
                        <Typography variant='caption'>
                            {t('shooterequipmentfailed')}
                        </Typography>
                    </div>
                </div>
                {renderPenaltyOptions($shooter)}
            </div>
        );
    }

    function renderPenaltyOptions($shooter) {
        return (
            <div className={classes.penaltyoptions} style={{ pointerEvents: showErrorButtons ? '' : 'none', opacity: showErrorButtons ? 1 : 0 }}>
                <Button
                    onClick={() => onShooterEquipmentMoveOpen($shooter.id)}
                    variant='contained'
                    color='secondary'
                    style={{ padding: '24px' }}
                    fullWidth
                >
                    {t('shooterequipmentfailedmovetoopen')}
                </Button>
                <Button
                    onClick={() => onShooterEquipmentMoveNoScore($shooter.id)}
                    variant='contained'
                    color='secondary'
                    style={{ padding: '24px' }}
                    fullWidth
                >
                    {t('shooterequipmentfailedmovetonoscore')}
                </Button>
                <Button
                    onClick={() => onToggleShooterEquipmentError()}
                    fullWidth
                >
                    {t('generic:back')}
                </Button>
            </div>
        );
    }

    return (
        <div>
            <div style={{ position: 'relative' }}>
                {(shooterIdx === squadShooters.length) && renderDone()}
                {squadShooters.map((s, index) => {
                    const style = (() => {
                        if (index < squadShooters.length - 1 - shooterIdx) {
                            return {
                                position: 'absolute',
                                left: '0px',
                                top: '0px',
                                paddingLeft: `${2 * index}px`,
                                paddingTop: `${2 * index}px`,
                                width: '100%',
                            };
                        }
                        if (index === squadShooters.length - 1 - shooterIdx) {
                            return {
                                paddingLeft: `${2 * index}px`,
                                paddingTop: `${2 * index}px`,
                                width: '100%',
                                position: 'relative',
                                left: '0px',
                                top: '0px',
                                zIndex: '9',
                                transition: 'left 0.5s ease 0s, transform 0.7s ease 0s',
                            };
                        }
                        if (tempEquipmentCheck[s.id] === 'ok') {
                            return {
                                paddingLeft: `${2 * index}px`,
                                paddingTop: `${2 * index}px`,
                                position: 'absolute',
                                left: 'calc(200%)',
                                top: '0px',
                                width: '100%',
                                zIndex: '9',
                                transform: 'rotate(20deg)',
                                transition: 'left 0.5s ease 0s, transform 0.2s ease 0s',
                            };
                        }
                        return {
                            paddingLeft: `${2 * index}px`,
                            paddingTop: `${2 * index}px`,
                            position: 'absolute',
                            left: 'calc(-300%)',
                            top: '0px',
                            width: '100%',
                            zIndex: '9',
                            transform: 'rotate(20deg)',
                            transition: 'left 0.5s ease 0s, transform 0.2s ease 0s',
                        };
                    })();

                    const showSummary = typeof (tempEquipmentCheck[s.id]) !== 'undefined';

                    return (
                        <div style={style} key={s.id}>
                            <Card>
                                <CardContent>
                                    <ShooterInfo t={t} shooter={s} match={match} />
                                    {renderButtons(s, showSummary)}
                                    {showSummary && renderStamp(s)}
                                    {renderComments(s)}
                                </CardContent>
                            </Card>
                        </div>
                    );
                })}
            </div>
            {(shooterIdx < squadShooters.length) && renderPrevNextButtons()}
        </div>
    );
}

ScoreStageSquadAttendance.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    auth: PropTypes.string.isRequired,
    routeProps: PropTypes.shape({
        history: ReactRouterPropTypes.history.isRequired,
        match: ReactRouterPropTypes.match.isRequired,
    }).isRequired,
    setTitle: PropTypes.func.isRequired,
    setBackButtonVisible: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func.isRequired,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
        alias: PropTypes.string.isRequired,
        squads: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        })).isRequired,
    }).isRequired,
    shooters: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        publicId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        division: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        'power-factor': PropTypes.string.isRequired,
    })).isRequired,
    shooter: PropTypes.shape({
        id: PropTypes.string.isRequired,
        publicId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        division: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        'power-factor': PropTypes.string.isRequired,
    }),
    stageIdx: PropTypes.number.isRequired,
    squadId: PropTypes.string,
    me: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    equipmentCheck: PropTypes.objectOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
};

ScoreStageSquadAttendance.defaultProps = {
    equipmentCheck: {},
    squadId: null,
    shooter: null,
};

const styles = () => ({
    doneScreen: {
        position: 'relative',
        top: '24px',
        left: '0px',
        width: '100%',
        textAlign: 'center',
    },
    doneButton: {
        marginTop: '24px',
    },
    doneError: {
        color: red[500],
        padding: '12px',
    },
    stampwrapper: {
        position: 'absolute',
        top: '30px',
        right: '30px',
        textAlign: 'center',
    },
    stamp: {
        width: '100px',
        height: '100px',
        borderRadius: '50%',
        border: '12px solid',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '&> i': {
            fontSize: '36px',
        },
    },
    stampgreen: {
        borderColor: green[500],
        '&> i': {
            color: green[500],
        },
    },
    stampgrey: {
        borderColor: grey[500],
        '&> i': {
            color: grey[500],
        },
    },
    stampred: {
        borderColor: red[500],
        '&> i': {
            color: red[500],
        },
    },
    actionwrapper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    penaltyoptions: {
        position: 'absolute',
        background: 'rgba(255, 255, 255, 0.9)',
        flexDirection: 'column',
        justifyContent: 'space-around',
        padding: '24px',
        display: 'flex',
        top: '0px',
        left: '0px',
        width: 'calc(100% - 48px)',
        height: 'calc(100% - 48px)',
        transition: 'opacity 0.5s ease 0s',
    },
});

export default withStyles(styles, { withTheme: true })(ScoreStageSquadAttendance);
