module.exports = {
    en: {
        'loading-matches': 'Looking for all your matches...',
        'loading-stages': 'Found them! Loading their info...',
        'loading-scores': 'Great! Now lets get all the scores...',
        'loading-shooters': 'And finally, shooters information...',
        'loading-feed': 'Done! Are you ready? Standby.',
        lastweek: 'This week\'s matches',
        matches: 'Matches',
        past: 'History',
        future: 'Up & coming matches',
        'result-dq': 'You were DQ\'ed from the match.',
        'result-category': 'You ranked {{pos}} out of {{all}}, with {{points}} points ({{percentage}}%), and {{catPos}} ({{catPercentage}}%) in your category.',
        result: 'You ranked {{pos}} out of {{all}}, with {{points}} points ({{percentage}}%).',
        1: '1st',
        2: '2nd',
        3: '3rd',
        4: '4th',
        5: '5th',
        6: '6th',
        7: '7th',
        8: '8th',
        9: '9th',
        registration: 'Your Registration',
        'your-result': 'Your Result',
        scorestage: 'Score for Stage {{index}} - {{title}}',
        'no-result-for-stage': 'No information yet. Have you shot this stage?',
        'result-pos': 'Pos',
        'result-%': 'Percentage',
        tosite: 'Match Site',
        tositedescription: 'Match info, registration, live results, stages, and more.',
        tomanage: 'Staff Site',
        tomanagedescription: 'Scoring and match management.',
        tostats: 'Your Stats',
        tostatsdescription: 'The geeky-cool stuff, including \'what-if\'s.',
        refreshing: 'Refreshing...',
        'dq-rule': 'Rule',
        'dq-date': 'On',
        staff: 'STAFF',
        lastweeknomatches: 'You can rest, you don\'t have any matches planned.',
        lastweeknomatchesatall: 'Here you can see all the matches you have planned.',
        pastnomatches: 'You don\'t seem to have any past ARIA matches. Come here again in the future to see your history, and follow your progress.',
        pastnomatchesatall: 'Always be improving - check out your history of matches.',
        yourresult: 'Your result',
        yourscores: 'Your score',
        yourmedia: 'Videos & Photos',
        newimage: '+ Image',
        newvideo: '+ Video',
        takestime: 'Uploading might take a few minutes',
    },
    he: {
    },
};
